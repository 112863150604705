import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import "./badge.css";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import { connect } from "react-redux";
import { showSnackBar, showLoader } from "../../actions/index";
import { bindActionCreators } from "redux";
//import PhoneInput from "react-phone-input-2";
const AddcoOwnersModal = (props) => {
  const [show, setShow] = useState(false);
  // const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [validated, setValidated] = useState(false);
  //const [checked, setChecked] = useState(false);
  // const [phone, setPhone] = useState("");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    contact: "",
    userAccess: "",
    job: [],
  });
  const [vendorUserInfo, setVendorUserInfo] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    contact: "",
    userAccess: "",
  });

  const [corporateFormerrors, setCorporateFormError] = useState({
    userAccessError: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    contactError: "",
    //jobError: [],
    submitClikced: false,
  });

  const [vendorFormerrors, setVendorFormError] = useState({
    userAccessError: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    contactError: "",
    submitClikced: false,
  });
  useEffect(() => {
    loadCreateJobPanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userType = localStorage.getItem("ut");

  const removeEmptySpaces = (stringVal) => {
    var getValue = /\s/g.test(stringVal);

    if (getValue === true) {
      props.showSnackBar({
        message: "Please Remove Spacing.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
  };

  const [checkedValues, setCheckedValues] = useState([]);
  const handleCheck = (checkedId, e) => {
    var notif = { ...userInfo };

    notif.job = checkedValues?.includes(checkedId)
      ? checkedValues?.filter((_id) => _id !== checkedId)
      : [...(checkedValues ?? []), checkedId];

    setCheckedValues(notif.job);

    setUserInfo({ ...userInfo, job: notif.job });
    //return notif.job
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setShow(false);
  };

  const loadCreateJobPanel = () => {
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";
    if (userType === "1110" || userType === "1100") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 100,
            // cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          //onsole.log(data)

          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const handleInputChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.id]: e.target.value });
    removeEmptySpaces(e.target.value);
  };

  const handleVendorInputChange = (e) => {
    setVendorUserInfo({ ...vendorUserInfo, [e.target.id]: e.target.value });
    removeEmptySpaces(e.target.value);
  };

  const handleCheckValidation = (e) => {
    if (!vendorUserInfo.firstName) {
      vendorFormerrors.firstNameError = "Please Enter First Name";
    }
    if (!vendorUserInfo.lastName) {
      vendorFormerrors.lastNameError = "Please Enter Last Name";
    }
    if (!vendorUserInfo.emailId) {
      vendorFormerrors.emailError = "Please Enter Email ID";
    }

    if (!vendorUserInfo.contact || vendorUserInfo.contact === "") {
      vendorFormerrors.contactError = "Contact No. must be 10 digits";
    }

    if (!vendorUserInfo.userAccess) {
      vendorFormerrors.userAccessError =
        "Select user access by clicking on Admin or Recruiter.";
    }
  };
  const handleCheckCorporateValidation = (e) => {
    if (userInfo.firstName === "") {
      corporateFormerrors.firstNameError = "Please Enter First Name";
    }
    if (userInfo.lastName === "") {
      corporateFormerrors.lastNameError = "Please Enter Last Name";
    }
    if (userInfo.emailId === "") {
      corporateFormerrors.emailError = "Please Enter Email ID";
    }
    if (userInfo.contact === "" || !userInfo.contact) {
      corporateFormerrors.contactError = "Contact No. must be 10 digits";
    }

    if (!userInfo.userAccess) {
      corporateFormerrors.userAccessError =
        "Select user access by clicking on Admin or Recruiter.";
    }
  };
  var addUser;
  if (userType === "1000") {
    addUser = (event) => {
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      console.log("event", form.checkValidity());

      handleCheckValidation(event);
      setValidated(true);
      const dupUserInfo = { ...vendorUserInfo };
      const vendorCorporateId = localStorage.getItem("vci");
      axios
        .post(dEndPoints.addVendorUser(vendorCorporateId), dupUserInfo)
        .then((response) => {
          var data = response.data;
          if (data && data.code === 100028) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
          }
          handleClose();
          props.loadUser();
          setVendorUserInfo({
            firstName: "",
            lastName: "",
            emailId: "",
            contact: "",
            userAccess: "",
          });
          if (data && data.code === 100004) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error, "An error");
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100036
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    };
  } else {
    addUser = (event) => {
      const dupUserInfo = { ...userInfo };
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      // console.log("event", form.checkValidity());

      handleCheckCorporateValidation(event);
      setValidated(true);
      const corporateId = localStorage.getItem("ci");
      axios
        .post(dEndPoints.addUser(corporateId), dupUserInfo)
        .then((response) => {
          var data = response.data;

          if (data && data.code === 100028) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
          }
          handleClose();
          props.loadUser();
          setUserInfo({
            firstName: "",
            lastName: "",
            emailId: "",
            contact: "",
            userAccess: "",
            job: [],
          });
          handleCheckCorporateValidation(event);
          if (data && data.code === 100004) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
          if (data.code === 100008) {
            props.showSnackBar({
              message: `${data.message}. Please Login for next Process`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          console.log("An error", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100133
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100036
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    };
  }

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        {props.BtnName}
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="tq__modal add-co__owner text-center"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="border-0 justify-content-center" closeButton>
          <Modal.Title className="w-100 text-center">Add Co-Owners</Modal.Title>
        </Modal.Header>

        <Modal.Body className="tq-form text-left">
          {userType === "1100" || userType === "1110" ? (
            <>
              <Form noValidate validated={validated}>
                <Row>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      id="firstName"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.firstName}
                      placeholder="First Name"
                    />
                    <p className="text-danger">
                      {!userInfo.firstName
                        ? corporateFormerrors.firstNameError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      id="lastName"
                      type="text"
                      onChange={handleInputChange}
                      value={userInfo.lastName}
                      placeholder="Last Name"
                    />
                    <p className="text-danger">
                      {!userInfo.lastName
                        ? corporateFormerrors.lastNameError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      id="emailId"
                      type="email"
                      onChange={handleInputChange}
                      value={userInfo.emailId}
                      placeholder="example@domain.com"
                    />
                    <p className="text-danger">
                      {!userInfo.emailId ? corporateFormerrors.emailError : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>Contact</Form.Label>

                    <InputGroup className="mb-2">
                      <InputGroup.Text>+91</InputGroup.Text>
                      <Form.Control
                        // id="inlineFormInputGroup"
                        id="contact"
                        onChange={handleInputChange}
                        value={userInfo.contact}
                        type="text"
                        required
                        maxLength="10"
                        placeholder="Eg. 98XXXXXX00"
                      />
                    </InputGroup>
                    <p className="text-danger">
                      {!userInfo.contact
                        ? corporateFormerrors.contactError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group
                    className="mb-4 py-3 text-center bg-light"
                    as={Col}
                    xs={12}
                  >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={userInfo.userAccess === "Admin"}
                        id="userAccess"
                        value="Admin"
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" for="adminRdo">
                        Admin
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        checked={userInfo.userAccess === "Recruiter"}
                        className="form-check-input"
                        type="radio"
                        id="userAccess"
                        value="Recruiter"
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" for="recRdo">
                        Recruiter
                      </label>
                    </div>
                    <p className="text-danger">
                      {!userInfo.userAccess
                        ? corporateFormerrors.userAccessError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={12}>
                    <Form.Label>Choose Jobs</Form.Label>
                    <div className="table__data">
                      {/* show jobs to be assignment */}
                      <Table
                        striped
                        bordered
                        hover
                        style={{ overflow: "auto" }}
                      >
                        <thead>
                          <tr>
                            <th>Select Job</th>
                            <th>ReqId</th>
                            <th>Designation</th>
                            <th>Primary Skills</th>
                            <th>Location</th>
                            <th>Notice Period</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobs.map((details, index) =>
                            details.status === "Open" ? (
                              <tr>
                                <td key={index}>
                                  <input
                                    //tabIndex={-1}
                                    id="job"
                                    type="radio"
                                    key={index}
                                    value={
                                      userInfo && userInfo.job
                                        ? userInfo.job
                                        : []
                                    }
                                    checked={checkedValues.includes(
                                      details._id
                                    )}
                                    onChange={() => handleCheck(details._id)}
                                  />
                                </td>
                                <td>{details.reqId}</td>
                                <td>{details.desig}</td>
                                <td>{details.pSkills}</td>
                                <td>{details.city + ""}</td>
                                <td>{details.nPeriod}</td>
                              </tr>
                            ) : (
                              ""
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Form.Group>
                </Row>
              </Form>
            </>
          ) : (
            <>
              <Form noValidate validated={validated}>
                <Row>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      id="firstName"
                      type="text"
                      onChange={handleVendorInputChange}
                      value={vendorUserInfo.firstName}
                      placeholder="First Name"
                    />
                    <p className="text-danger">
                      {!vendorUserInfo.firstName
                        ? vendorFormerrors.firstNameError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      id="lastName"
                      type="text"
                      onChange={handleVendorInputChange}
                      value={vendorUserInfo.lastName}
                      placeholder="Last Name"
                    />
                    <p className="text-danger">
                      {!vendorUserInfo.lastName
                        ? vendorFormerrors.lastNameError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      id="emailId"
                      type="email"
                      onChange={handleVendorInputChange}
                      value={vendorUserInfo.emailId}
                      placeholder="example@domain.com"
                    />
                    <p className="text-danger">
                      {!vendorUserInfo.emailId
                        ? vendorFormerrors.emailError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group className="mb-4" as={Col} xs={6}>
                    <Form.Label>Contact</Form.Label>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>+91</InputGroup.Text>
                      <Form.Control
                        id="contact"
                        onChange={handleVendorInputChange}
                        value={vendorUserInfo.contact}
                        type="text"
                        required
                        maxLength="10"
                        placeholder="Eg. 98XXXXXX00"
                      />
                    </InputGroup>
                    <p className="text-danger">
                      {!vendorUserInfo.contact ||
                      vendorUserInfo.contact.length > 10
                        ? vendorFormerrors.contactError
                        : ""}
                    </p>
                  </Form.Group>
                  <Form.Group
                    className="mb-4 py-3 text-center bg-light"
                    as={Col}
                    xs={12}
                  >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={vendorUserInfo.userAccess === "Admin"}
                        id="userAccess"
                        value="Admin"
                        onChange={handleVendorInputChange}
                      />
                      <label className="form-check-label" for="adminRdo">
                        Admin
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        checked={vendorUserInfo.userAccess === "Recruiter"}
                        className="form-check-input"
                        type="radio"
                        id="userAccess"
                        value="Recruiter"
                        onChange={handleVendorInputChange}
                      />
                      <label className="form-check-label" for="recRdo">
                        Recruiter
                      </label>
                    </div>
                    <p className="text-danger">
                      {!vendorUserInfo.userAccess
                        ? vendorFormerrors.userAccessError
                        : ""}
                    </p>
                  </Form.Group>
                </Row>
              </Form>
            </>
          )}
          <Row>
            <Col md={12} className="d-flex justify-content-center mt-3">
              <ButtonGroup>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={(e) => addUser(e)}>
                  Add User
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>{" "}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AddcoOwnersModal);
