import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { dEndPoints } from "../../../config/end-point";
import "../../../components/badge/badge.css";
import BookSlotModal from "../../badge/BookSlotModal";
import { showSnackBar, showLoader } from "../../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useParams } from "react-router";
var styleObject = {
  mode: {
    float: "left",
    marginBottom: "10px",
  },
  interview: {
    margin: "10px",
    borderRadius: "10px",
    border: "1px solid #000",
    padding: "10px",
  },
};

const CandidateInterviews = (props) => {
  const [interviews, setInterviews] = useState([]);
  const [slotid, setSlotid] = useState("");
  const [slotId, setSlotId] = useState("");
  const [link, setLink] = useState("");
  // const [instruct, setInstruct] = useState("");
  const [backuprounds, setBackuprounds] = useState([]);
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [mode, setMode] = useState("");
  const [reason, setReason] = useState("");
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openSelected, setOpenSelected] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [openDeclined, setOpenDeclined] = useState(false);
  const [openSlotBooking, setOpenSlotBooking] = useState(false);
  const [rejectReson, setRejectReson] = useState("");
  // const [confirm, setConfirm] = useState(false);
  const [comment, setComment] = useState("");
  const [rounds, setRounds] = useState([]);
  const corporateId = localStorage.getItem("ci");
  //const jobid = props.jobId;
  let { jobId } = useParams();
  const candidateProfileId = props.candidate._id;
  const userType = localStorage.getItem("ut");
  const vendorCorporateId = localStorage.getItem("vci");

  //const candidateSlot = props.candidate.candidateSlots;
  const handlerejectChange = (event) => {
    setRejectReson(event.target.value);
  };
  useEffect(() => {
    getInterviewRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commentChange = (e) => {
    setComment(e.target.value);
  };
  // function to get the updated candidate interviews
  const getUpdatedInterviews = () => {
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      axios
        .get(
          dEndPoints.candidateInterviews(
            corporateId,
            jobId,
            candidateProfileId
          ),
          null
        )
        .then((res) => {
          props.showLoader({ show: false });
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          setInterviews(res.data.data);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      props.showLoader({ show: true });
      axios
        .get(
          dEndPoints.vendorCandidateInterviews(
            vendorCorporateId,
            jobId,
            candidateProfileId
          ),
          null
        )
        .then((res) => {
          props.showLoader({ show: false });
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          setInterviews(res.data.data);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  const getInterviewRounds = () => {
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.interviewRounds(corporateId, jobId))
        .then((res) => {
          setTimeout(() => {
            props.showLoader({ show: false });
          }, 2000);
          var intRounds = res.data;
          setRounds(intRounds.data.interview);
          setBackuprounds(intRounds.data.interview);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorInterviewRounds(vendorCorporateId, jobId))
        .then((res) => {
          setTimeout(() => {
            props.showLoader({ show: false });
          }, 2000);
          var intRounds = res.data;
          setRounds(intRounds.data.interview);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  useEffect(() => {
    setInterviews(props.interviews);
  }, [props.interviews]);

  const handleScheduleOpen = (id, interviewMOde, interviewStatus) => {
    setSlotid(id);
    setMode(interviewMOde);
    setStatus(interviewStatus);
    setOpenSchedule(true);
  };

  // const handleConfirm = () => {
  //   setConfirm(true);
  // };
  const handleScheduleClose = () => {
    setOpenSchedule(false);
    setSlotid("");
    setMode("");
    setStatus("");
    setLink("");
    setAddress("");
    setReason("");
    // setInstruct("");
  };
  const handleSelectedClose = () => {
    setOpenSelected(false);
    setSlotid("");
    setMode("");
    setStatus("");
    setLink("");
    // setInstruct("");
    setAddress("");
    setReason("");
  };
  const handleRejectedClose = () => {
    setOpenRejected(false);
    setSlotid("");
    setMode("");
    setStatus("");
    setLink("");
    // setInstruct("");
    setAddress("");
    setReason("");
  };
  const handleNoshow = (id) => {
    handleInterviewStatus(id, "No show");
  };
  const handleRoundSelect = (id) => {
    setSlotid(id);
    // setOpenSelected(true);
    handleInterviewStatus(id, "Selected");
  };
  const handleRoundReject = (id) => {
    setSlotid(id);
    setOpenRejected(true);
  };
  const handleSlotBooking = (sid, nextstatus) => {
    handleInterviewStatus(sid, nextstatus);
    //setOpenSlotBooking(true)
  };
  const handleOpenSlotBooking = (id) => {
    setSlotId(id);
    setOpenSlotBooking(true);
  };
  const handleCloseOpenSlot = () => {
    setOpenSlotBooking(false);
    getUpdatedInterviews();
  };
  const handleDeclinedSlotfromCorporate = (id) => {
    setSlotId(id);
    setOpenDeclined(true);
  };
  const handleCloseDeclined = () => {
    setOpenDeclined(false);
    getUpdatedInterviews();
  };
  const handleCloseSlotBooking = () => {
    setOpenSlotBooking(false);
    getUpdatedInterviews();
    // window.location.reload();
  };

  const handleInterviewStatus = (sid, nextstatus, reason) => {
    setOpenSchedule(false);
    setOpenSelected(false);
    setOpenRejected(false);
    let slot = sid;
    var newstatus = nextstatus;
    // if (mode === "Online" && nextstatus === "Scheduled") {
    //   // props.showSnackBar({
    //   //   message: `Please enter the link for online interview`,
    //   //   open: true,
    //   //   type: "error",
    //   //   vertical: "top",
    //   //   horizontal: "center",
    //   // });
    //   setOpenSchedule(true);
    //   return;
    // }
    // if (mode === "Offline" && nextstatus === "Scheduled") {
    //   // props.showSnackBar({
    //   //   message: `Please enter the address for offline interview`,
    //   //   open: true,
    //   //   type: "error",
    //   //   vertical: "top",
    //   //   horizontal: "center",
    //   // });
    //   setOpenSchedule(true);
    //   return;

    let data;
    if (mode === "Online") {
      data = {
        link: link,
        reason: reason,
      };
    } else if (mode === "Offline") {
      data = {
        address: address,
        reason: reason,
      };
    } else if (newstatus === "InProcess") {
      newstatus = "Rejected";
      data = {
        candidateStatus: nextstatus,
        reason: `${rejectReson} ( ${reason} )`,
      };
    } else if (newstatus === "Rejected") {
      data = {
        candidateStatus: newstatus,
        reason: `${rejectReson} ( ${reason} )`,
      };
    } else if (newstatus === "Declined") {
      sid = slotId;
      slot = sid;
      data = {
        reason: reason,
      };
    } else {
      data = null;
    }
    props.showLoader({ show: true });
    axios
      .patch(dEndPoints.updateInterview(corporateId, slot), data, {
        params: {
          status: newstatus,
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
        setLink("");
        setAddress("");
        setReason("");
        getUpdatedInterviews();
        if (newstatus === "Declined") {
          handleCloseDeclined();
          setComment("");
        }
        if (nextstatus === "Scheduled") {
          handleSelectedClose();
          setComment("");
        }
        // setInterviews([]);
        props.showSnackBar({
          message: res.data.data.message,
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        return;
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error");
        if (error.response.data.code === 100188) {
          props.showSnackbar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else {
          console.log("Error", error);
        }
      });
  };
  var updateProposed;
  if (userType === "1000") {
    updateProposed = (candidateProfileId, slotNew, status) => {
      let data = {
        proposedConfirm: status,
      };

      axios
        .patch(
          dEndPoints.updateProposedConfirm(
            vendorCorporateId,
            jobId,
            candidateProfileId,
            slotNew
          ),
          data
        )
        .then((res) => {
          props.showLoader({ show: false });

          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          props.showSnackBar({
            message: "Successfully Confirmed The Allocated Slot",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error");
          if (error.response.data.code === 100188) {
            props.showSnackbar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else {
            console.log("Error", error);
          }
        });
    };
  }

  const DeclinedSlots = (candidateProfileId, jobId, status, comment) => {
    if (comment) {
      var data = {
        status: status,
        declineReason: comment,
      };
    }
    if (userType === "1000") {
      axios
        .patch(
          dEndPoints.DeclinedSlotFromVendor(
            vendorCorporateId,
            slotId,
            jobId,
            candidateProfileId
          ),
          data
        )
        .then((res) => {
          props.showLoader({ show: false });

          setComment("");
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          // setLink("");
          handleCloseOpenSlot();
          props.showSnackBar({
            message: "Successfully Confirmed The Allocated Slot",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  };

  return (
    <>
      <Accordion
        className="schedule__interview-wrapper mt-4"
        defaultActiveKey="0"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Interviews</Accordion.Header>
          <Accordion.Body className="interview__round-body">
            <Container>
              {interviews.length > 0
                ? interviews.map((interv) => (
                    <>
                      {/* <div className="interview__status-badges_wrapper">
                        {interv.status ? (
                          <Tooltip title="Interview Status" placement="bottom">
                            <Badge
                              className={`candidate__status ml-0 bg-${interv.status}`}
                            >
                              {interv.status}
                            </Badge>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div> */}
                      <div className="interview__rounds-wrapper">
                        <Row>
                          <>
                            <Col md={4}>Round name: {interv.roundName}</Col>
                            <Col md={4}>Mode: {interv.roundMode}</Col>
                            <Col md={4}>
                              Date:{" "}
                              {new Date(interv.date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  day: "numeric",
                                  month: "short",
                                }
                              )}
                            </Col>
                            <Col md={4}>
                              Timing:{" "}
                              {
                                new Date(interv.sTime)
                                  .toTimeString()
                                  .split(":")[0]
                              }
                              :
                              {
                                new Date(interv.sTime)
                                  .toTimeString()
                                  .split(":")[1]
                              }{" "}
                              -
                              {
                                new Date(interv.eTime)
                                  .toTimeString()
                                  .split(":")[0]
                              }
                              :
                              {
                                new Date(interv.eTime)
                                  .toTimeString()
                                  .split(":")[1]
                              }
                            </Col>
                          </>

                          <Col md={12} className="d-flex justify-content-end">
                            {interv.status === "Proposed" ? (
                              userType === "1100" || userType === "1110" ? (
                                <div style={{ textAlign: "right" }}>
                                  {interv.proposedConfirm === true ? (
                                    <>
                                      <div
                                        // style={styleObject.stat}
                                        className="round__stat default"
                                      >
                                        Confirmed from Vendor
                                      </div>
                                      <ButtonGroup>
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            handleDeclinedSlotfromCorporate(
                                              interv._id
                                            )
                                          }
                                        >
                                          Decline Interview
                                        </Button>
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            handleScheduleOpen(
                                              interv._id,
                                              interv.roundMode,
                                              interv.status
                                            )
                                          }
                                        >
                                          Schedule Interview
                                        </Button>
                                      </ButtonGroup>
                                    </>
                                  ) : (
                                    <div
                                      // style={styleObject.stat}
                                      className="round__stat default"
                                    >
                                      Awaiting confirmation from Vendor
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="d-flex justify-content-end text-right">
                                  {interv.proposedConfirm === true &&
                                  userType === "1000" ? (
                                    <div
                                      // style={styleObject.stat}
                                      className="corporate__awaiting round__stat"
                                    >
                                      Awaiting Response From Corporate
                                    </div>
                                  ) : (
                                    <>
                                      <ButtonGroup>
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            handleOpenSlotBooking(interv._id)
                                          }
                                        >
                                          Decline Interview
                                        </Button>
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            updateProposed(
                                              interv.candidateId,
                                              interv._id,
                                              true
                                            )
                                          }
                                        >
                                          Confirm Interview
                                        </Button>
                                      </ButtonGroup>
                                    </>
                                  )}
                                </div>
                              )
                            ) : interv.status === "Declined" ? (
                              userType === "1100" || userType === "1110" ? (
                                <div className="d-flex justify-content-end">
                                  <>
                                    <div
                                      // style={styleObject.stat}
                                      className="round__stat vendor_decline"
                                    >
                                      Declined Slot
                                    </div>
                                    <BookSlotModal
                                      style={{ textAlign: "right" }}
                                      BtnName="Book Another slot"
                                      handleCloseSlotBooking={
                                        handleCloseSlotBooking
                                      }
                                      handleSelectedClose={handleSelectedClose}
                                      jobId={props.candidate.jobId}
                                      rounds={rounds}
                                      getInterviewRounds={getInterviewRounds}
                                      openSlotBooking={openSlotBooking}
                                      candidateId={props.candidate._id}
                                      onClick={() =>
                                        handleSlotBooking(slotid, "Proposed")
                                      }
                                      getUpdatedInterviews={
                                        getUpdatedInterviews
                                      }
                                      loadCandidatesDetails={
                                        props.loadCandidatesDetails
                                      }
                                    />
                                  </>

                                  {/* <div style={styleObject.stat}>
                                Awaiting confirmation from Candidate
                              </div> */}
                                </div>
                              ) : (
                                <div className="d-flex justify-content-end">
                                  <>
                                    <div
                                      // style={styleObject.stat}
                                      className="round__stat vendor_decline"
                                    >
                                      Declined Slot
                                    </div>
                                    {/* <BookSlotModal
                                      style={{ textAlign: "right" }}
                                      BtnName="Book Another slot"
                                      handleCloseSlotBooking={
                                        handleCloseSlotBooking
                                      }
                                      jobId={props.candidate.jobId}
                                      candidateId={props.candidate._id}
                                      rounds={rounds}
                                      getInterviewRounds={getInterviewRounds}
                                      openSlotBooking={openSlotBooking}
                                      onClick={() =>
                                        handleSlotBooking(slotid, "Proposed")
                                      }
                                      getUpdatedInterviews={
                                        getUpdatedInterviews
                                      }
                                      loadCandidatesDetails={
                                        props.loadCandidatesDetails
                                      }
                                    /> */}
                                  </>
                                </div>
                              )
                            ) : interv.status === "Scheduled" ? (
                              userType === "1100" || userType === "1110" ? (
                                <div className="text-right">
                                  <ButtonGroup className="text-right">
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        handleRoundReject(interv._id)
                                      }
                                    >
                                      Round reject
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() => handleNoshow(interv._id)}
                                    >
                                      No show
                                    </Button>
                                    <Button
                                      variant="success"
                                      onClick={() =>
                                        handleRoundSelect(interv._id)
                                      }
                                    >
                                      Round select
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              ) : (
                                <>
                                  <div
                                    // style={styleObject.stat}
                                    className="round__stat default"
                                  >
                                    {interv.status}
                                  </div>
                                  {/* <div>
                                  <BookSlotModal
                                    style={{ textAlign: "right" }}
                                    BtnName="Book slot"
                                    handleCloseSlotBooking={
                                      handleCloseSlotBooking
                                    }
                                    jobId={props.candidate.jobId}
                                    candidateId={props.candidate._id}
                                    rounds={rounds}
                                    getInterviewRounds={getInterviewRounds}
                                    openSlotBooking={openSlotBooking}
                                    onClick={() =>
                                      handleSlotBooking(slotid, "Proposed")
                                    }
                                    getUpdatedInterviews={getUpdatedInterviews}
                                    loadCandidatesDetails={
                                      props.loadCandidatesDetails
                                    }
                                  />
                                </div> */}
                                </>
                              )
                            ) : interv.status === "No show" ? (
                              <div className="text-right">
                                <div
                                  // style={styleObject.stat}
                                  className="round__stat default"
                                >
                                  {interv.status}
                                </div>
                                {/* <ButtonGroup>
                                <Button
                                  variant="danger"
                                  onClick={() => handleRoundReject(interv._id)}
                                >
                                  Round reject
                                </Button>
                                <Button
                                  variant="success"
                                  onClick={() => handleRoundSelect(interv._id)}
                                >
                                  Round select
                                </Button>
                              </ButtonGroup> */}
                              </div>
                            ) : interv.status === "Selected" ? (
                              userType === "1100" || userType === "1110" ? (
                                <>
                                  <div
                                    // style={styleObject.stat}
                                    className="round__stat default"
                                  >
                                    {interv.status}
                                  </div>
                                  <BookSlotModal
                                    style={{ textAlign: "right" }}
                                    BtnName="Book Another slot"
                                    handleCloseSlotBooking={
                                      handleCloseSlotBooking
                                    }
                                    jobId={props.candidate.jobId}
                                    candidateId={props.candidate._id}
                                    rounds={rounds}
                                    getInterviewRounds={getInterviewRounds}
                                    openSlotBooking={openSlotBooking}
                                    onClick={() =>
                                      handleSlotBooking(slotid, "Proposed")
                                    }
                                    getUpdatedInterviews={getUpdatedInterviews}
                                    loadCandidatesDetails={
                                      props.loadCandidatesDetails
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <div
                                    className="round__stat default"
                                    //  style={styleObject.stat}
                                  >
                                    {interv.status}
                                  </div>
                                  {/* <div>
                                  <Button
                                    variant="success"
                                    onClick={handleOpenSlotBooking}
                                    id
                                  >
                                    Book slot
                                  </Button>
                                </div> */}
                                </>
                              )
                            ) : interv.status === "Rejected" ? (
                              <div
                                // style={styleObject.stat}
                                className="round__stat default"
                              >
                                {interv.status}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                    </>
                  ))
                : "No interviews"}
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Modal to confirm interview schedule */}
      <Container>
        <Row>
          <Modal
            show={openSchedule}
            dialogClassName="tq__modal-default"
            onHide={handleScheduleClose}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title>Confirm interview schedule</Modal.Title>
            </Modal.Header>

            <Modal.Body className="tq-form">
              <Row className="my-2 mb-4 text-left">
                {mode === "Online" ? (
                  <>
                    <Col sm={12} className="text-left">
                      <span style={styleObject.mode}>
                        Interview mode:{" "}
                        <strong className="text-success">{mode}</strong>
                      </span>
                      <br />
                      <br />
                      <Form.Label>Enter interview link:</Form.Label>
                      <Form.Control
                        id="link"
                        label="Link"
                        placeholder="https://meetinglink.domain.com"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />
                    </Col>

                    <Form.Group as={Col} xs={12} className="mt-4">
                      <Form.Label>
                        Instruction/Comment For Candidate:
                      </Form.Label>
                      <Form.Control
                        id="comment"
                        placeholder="Instruction/comments"
                        // fullWidth={true}
                        value={comment}
                        onChange={commentChange}
                        handleInterviewStatus={handleInterviewStatus}
                      />
                    </Form.Group>
                  </>
                ) : mode === "Offline" ? (
                  <>
                    <Col sm={12} className="text-left">
                      <span style={styleObject.mode}>
                        Interview mode:{" "}
                        <strong className="text-secondary">{mode}</strong>
                      </span>
                      <br />
                      <br />
                      <Form.Label>Enter interview address:</Form.Label>
                      <Form.Control
                        id="address"
                        // label="Address"
                        placeholder="Interview Address here"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Col>
                    <Form.Group as={Col} xs={12} className="mt-4">
                      <Form.Label>
                        Instruction/Comment For Candidate:
                      </Form.Label>
                      <Form.Control
                        id="comment"
                        placeholder="Instruction/comments"
                        value={comment}
                        onChange={commentChange}
                        handleInterviewStatus={handleInterviewStatus}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
              </Row>
              <ButtonGroup>
                <Button variant="secondary" onClick={handleScheduleClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    handleInterviewStatus(slotid, "Scheduled", comment)
                  }
                >
                  Schedule
                </Button>
              </ButtonGroup>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>

      {/* Modal to select */}
      <Container>
        <Row>
          <Modal
            show={openSlotBooking}
            dialogClassName="tq__modal-default"
            onHide={handleCloseOpenSlot}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title>Are you sure to decline the slot</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Label>Please specify the reason.</Form.Label>
              <Form.Control
                id="comment"
                label="Comments"
                aria-label="Comments"
                value={comment}
                onChange={commentChange}
                DeclinedSlots={DeclinedSlots}
                placeholder="Please specify the reason."
              />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center">
              <Button onClick={handleCloseOpenSlot} variant="secondary">
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={() =>
                  DeclinedSlots(
                    props.candidate._id,
                    props.candidate.jobId,
                    "Declined",
                    comment
                  )
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>

      <Container>
        <Row>
          <Modal
            show={openDeclined}
            dialogClassName="tq__modal-default"
            onHide={handleCloseDeclined}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title>Are you sure to decline the slot</Modal.Title>
            </Modal.Header>

            <Modal.Body className="tq-form">
              <Form.Label>Please specify the reason.</Form.Label>
              <Form.Control
                id="comment"
                label="Comments"
                aria-label="Comments"
                value={comment}
                onChange={commentChange}
                handleInterviewStatus={handleInterviewStatus}
                placeholder="Please specify the reason."
              />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center">
              <Button onClick={handleCloseDeclined} variant="secondary">
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(sid) =>
                  handleInterviewStatus(sid, "Declined", comment)
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
      <Container>
        <Row>
          <Modal
            show={openSelected}
            dialogClassName="tq__modal-default"
            onHide={handleSelectedClose}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title>Do you want to schedule another round?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <ButtonGroup>
                <Button
                  variant="secondary"
                  onClick={() => handleInterviewStatus(slotid, "Selected")}
                >
                  No
                </Button>
                {/* Open slot booking modal */}
                <BookSlotModal
                  style={{ textAlign: "right" }}
                  BtnName="Schedule Interview"
                  handleCloseSlotBooking={handleCloseSlotBooking}
                  handleSelectedClose={handleSelectedClose}
                  jobId={props.candidate.jobId}
                  rounds={rounds}
                  getInterviewRounds={getInterviewRounds}
                  openSlotBooking={openSlotBooking}
                  candidateId={props.candidate._id}
                  onClick={() => handleSlotBooking(slotid, "Selected")}
                  getUpdatedInterviews={getUpdatedInterviews}
                  loadCandidatesDetails={props.loadCandidatesDetails}
                />
              </ButtonGroup>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
      <Container>
        <Row>
          <Modal
            show={openRejected}
            dialogClassName="tq__modal-default"
            onHide={handleRejectedClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Would you also want to reject the candidate?
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="tq-form">
              {
                <Row>
                  <Col md={12}>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Technical Round Reject"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Technical Round Reject"
                        for="inlineCheckbox1"
                      >
                        Technical Round Reject
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="HR Round reject"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="HR Round reject"
                        for="inlineCheckbox1"
                      >
                        HR Round reject
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Managerial Round Reject"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Managerial Round Reject"
                        for="inlineCheckbox1"
                      >
                        Managerial Round Reject
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Communication Skills"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Communication Skills"
                        for="inlineCheckbox1"
                      >
                        Communication Skills
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Notice Period"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Notice Period"
                        for="inlineCheckbox1"
                      >
                        Notice Period
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Salary Expectations"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Salary Expectations"
                        for="inlineCheckbox1"
                      >
                        Salary Expectations
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Location Criteria"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Location Criteria"
                        for="inlineCheckbox1"
                      >
                        Location Criteria
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Other Reasons"
                        onChange={(e) => handlerejectChange(e)}
                      />
                      <label
                        className="form-check-label"
                        value="Other Reasons"
                        for="inlineCheckbox1"
                      >
                        Other Reasons
                      </label>
                    </div>
                  </Col>
                  <Col xs={12} className="text-left my-4">
                    <Form.Label>
                      Please specify the reason.{" "}
                      <small className="required__form">*</small>
                    </Form.Label>
                    <Form.Control
                      id="comment"
                      label="Comments"
                      aria-label="Comments"
                      value={comment}
                      onChange={commentChange}
                      handleInterviewStatus={handleInterviewStatus}
                      placeholder="Please specify the reason."
                    />
                  </Col>
                </Row>
              }
              <Button
                variant="secondary"
                onClick={() =>
                  handleInterviewStatus(slotid, "InProcess", comment)
                }
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  handleInterviewStatus(slotid, "Rejected", comment)
                }
              >
                Yes
              </Button>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CandidateInterviews);
