import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import "../../assets/style/tqResponsive.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
// import InterviewDetails from "../interview/InterviewDetails";
// import BrowseCandidate from "./BrowseCandidates";
// import "./jobdetails.css";
// import { useHistory } from "react-router-dom";
// import UploadResumes from "./UploadResumes";
import { EditorState, ContentState } from "draft-js";
import axios from "axios";
import endPoints, { dEndPoints } from "../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Document, Page, pdfjs } from "react-pdf";
import { showSnackBar, showLoader } from "../../actions/index";

let htmlToDraft, Editor;
if (typeof window !== "undefined") {
  htmlToDraft = require("html-to-draftjs").default;
}
if (typeof window !== "undefined") {
  Editor = require("react-draft-wysiwyg").Editor;
}
// let FileViewer = null;
// if (typeof window !== "undefined") {
//   FileViewer = require("react-file-viewer");
// }

const JdHeader = (header) => {
  return (
    <div className="jd__header">
      <Row>
        <Col sm={9}>
          <h2 className="jd__title">
            {header.jdTitle} ({header.jdType})
          </h2>
          <small>
            {header.company} - {header.locations}
          </small>
        </Col>
        <Col sm={3} className="text-right">
          <p className="jd__postedon">
            <strong>Posted on :</strong>{" "}
            {header.postedOn
              ? new Date(header.postedOn).toLocaleDateString("en-US", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              : ""}{" "}
          </p>
        </Col>
      </Row>
    </div>
  );
};
// header component ends

const JdBody = (body) => {
  const [fileUrl, setFileUrl] = useState(null);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [jobDes, setJobDesc] = useState([]);
  var date = new Date();
  // if (!body.jdPreview || !body.jdPreview) {
  //   return null;
  // }
  const blocksFromHtml = htmlToDraft(body.jdPreview && body.jdPreview);

  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);

  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  var editorStyle = {
    width: "100%",
    height: "100%",
  };

  // if (body.jdFile.n_fpath) {
  var pathJd = body.jdFile ? body.jdFile.n_fpath : "";
  //}
  // if (body.jdFile.fname) {
  //   pathJd = body.jdFile ? body.jdFile.fname : "";
  // }
  //var newJd = pathJd.substring(12, 14);
  useEffect(() => {
    downloadJdFile(true);
  }, [pathJd]);
  const downloadJdFile = (preview = false) => {
    //const pathJd = body.jdFile ? body.jdFile.n_fpath : "";
    //props.showLoader({ show: true });

    axios
      .get(endPoints.uploadFile, {
        params: {
          fileType: "Jd",
          fileUrl: pathJd,
        },
        responseType: "blob",
      })
      .then((res) => {
        // props.showLoader({ show: false });
        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        const href = window.URL.createObjectURL(blob);
        if (preview) {
          setFileUrl(href);
        } else {
          var link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `${body.jdCname}-${date.toLocaleDateString()}`
          );
          link.click();
        }
      })
      .catch((error) => {
        // props.showLoader({ show: false });
        console.log("error in download file", error);
      });
  };
  const userType = localStorage.getItem("ut");
  var ext = pathJd ? pathJd.split(".") : "";
  var type = ext[ext.length - 1];

  var newJobDesc = [];
  if (!body.jdFile) {
    var htmlString = body.jdPreview;
    if (htmlString) {
      var plainString = htmlString.replace(/<[^>]+>/g, "");
    }
    newJobDesc.push(plainString);
  }
  const alignJd = () => {
    //  var cjob = { ...body };
    var data = {
      jobDesc: newJobDesc,
    };
    body.showSnackBar({
      message: "JD Copied",
      open: true,
      type: "success",
      vertical: "top",
      horizontal: "center",
    });
    axios
      .post(endPoints.alignJd, data)
      .then((res) => {
        var data = res.data;

        setJobDesc(data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <div className="Job__body">
      <Row>
        <Col sm={7}>
          <div className="jd__data">
            <strong>Req Id : </strong>
            <span>{body.rqId}</span>
          </div>
          <div className="jd__data">
            <strong>Qualification : </strong>
            <span>{body.Qualification}</span>
          </div>
          <div className="jd__data">
            <strong>Job Locations : </strong>
            <span>{body.Locations}</span>
          </div>
          <div className="jd__data">
            <strong>Work Experience : </strong>
            <span>{body.Experience}</span>
          </div>
        </Col>
        <Col sm={5}>
          <div className="jd__data">
            <strong>Positions : </strong>
            <span>{body.Openings}</span>
          </div>
          <div className="jd__data">
            <strong>Notice Period : </strong>
            <span>{body.Notice}</span>
          </div>
          <div className="jd__data">
            <strong>Budget : </strong>
            <span>{body.Budget}</span>
          </div>
          <div className="jd__data">
            <strong>Valid Till : </strong>
            <span>
              {body.Valid
                ? new Date(body.Valid).toLocaleDateString("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                : ""}
            </span>
          </div>
        </Col>
        <Col xs={12}>
          <div className="jd__data jd__skills">
            <strong>Primary Skills : </strong>
            <span>{body.SkillsP}</span>
          </div>
          <div className="jd__data  jd__skills">
            <strong>Secondary Skills : </strong>
            <span>{body.SkillsS}</span>
          </div>
          {body.jobtype === "Walkin" ? (
            <>
              {body.walkInDate ? (
                <>
                  <div className="jd__data  jd__skills">
                    <strong>Walkin Interview Date : </strong>
                    <span>
                      {new Date(body.walkInDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      })}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
              {body.walkInTime ? (
                <div className="jd__data  jd__skills">
                  <strong>Walkin Interview Timing : </strong>
                  <span>{body.walkInTime}</span>
                </div>
              ) : (
                ""
              )}
              {body.walkInVenue ? (
                <div className="jd__data  jd__skills">
                  <strong>Walkin Interview Venue : </strong>
                  <span>{body.walkInVenue}</span>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {body.extraDetails ? (
            <div className="jd__data  jd__skills">
              <strong>Additional details of JD : </strong>
              <span>{body.extraDetails}</span>
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col xs={12}>
          <div className="jd__preview-wrapper separator__tq">
            <Accordion defaultActiveKey="0" flush className="jd__accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="jd__data mb-0">
                  <h3 className="jd__doc-preview__heading ">
                    Job Description :
                  </h3>
                </Accordion.Header>
                <Accordion.Body className="position-relative">
                  {/* <div className="jd__data"></div> */}
                  {body.jdFile && body.jdFile.n_fpath ? (
                    <Button
                      variant="success rounded-0 downloadDoc download__resume-button"
                      onClick={() => downloadJdFile()}
                    >
                      {" "}
                      <i className="bx bx-file"></i>
                      <span>Download JD</span>
                    </Button>
                  ) : (
                    ""
                  )}
                  {/* {body.jdFile &&
                  body.jdFile.n_fpath &&
                  type.toLowerCase() === "docx" ? (
                    <FileViewer
                      key={fileUrl}
                      fileType={type.toLowerCase()}
                      filePath={fileUrl}
                    /> */}
                  {body.jdFile &&
                  body.jdFile.n_fpath &&
                  type &&
                  type.toLowerCase() === "pdf" ? (
                    <div>
                      <div>
                        <div className="pagec">
                          Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                          {numPages || "--"}
                        </div>
                        <div className="buttonc">
                          <Button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className="Pre"
                          >
                            Previous
                          </Button>
                          <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                      <Document
                        file={fileUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          pageNumber={pageNumber}
                          width={900}
                          height={100}
                          style={{ border: "2px" }}
                        />
                      </Document>
                    </div>
                  ) : body.jdPreview && body.jdPreview ? (
                    <>
                      {userType === "1110" || userType === "1000" ? (
                        <CopyToClipboard text={jobDes}>
                          <Button onClick={(e) => alignJd()}>Copy JD</Button>
                        </CopyToClipboard>
                      ) : (
                        ""
                      )}
                      <Editor
                        editorState={editorState}
                        // handlePastedText={() => false}
                        wrapperClassName="rich-editor demo-wrapper"
                        toolbarHidden
                        wrapperStyle={editorStyle}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <div className="jd__data">
              <h3 className="jd__doc-preview__heading">Job Description :</h3>
              {body.jdFile ? (
                <strong className="text-right downloadDoc">
                  <span onClick={() => downloadJdFile()}>Download JD</span>
                </strong>
              ) : (
                ""
              )}
            </div> */}
            {/* {body.jdFile && body.jdFile && type === "docx" ? (
              <FileViewer key={fileUrl} fileType={type} filePath={fileUrl} />
            ) : body.jdFile && body.jdFile && type === "pdf" ? (
              <div>
                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageNumber}
                    width={900}
                    height={100}
                    style={{ border: "2px" }}
                  />
                </Document>
                <div>
                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </div>
                  <div className="buttonc">
                    <button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className="Pre"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : body.jdPreview && body.jdPreview ? (
              <Editor
                editorState={editorState}
                wrapperClassName="rich-editor demo-wrapper"
                toolbarHidden
                wrapperStyle={editorStyle}
              />
            ) : (
              ""
            )} */}

            {/* <div className="jd__doc-preview">{body.jdPreview}</div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
const JdFooter = (props) => {
  const userType = localStorage.getItem("ut");

  const enrolled = props.linkjobIds.indexOf(props.jobId) > -1 ? true : false;

  return (
    <div className="jd__footer text-center">
      {userType === "1000" && enrolled === "false" ? (
        <button className="btn btn-primary">I Agree</button>
      ) : null}
    </div>
  );
};
const JobDetails = (props) => {
  // var jobId = props.jobId;
  // const [job, setJob] = useState({});
  // console.log("jobdetails", props.jobdetails);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  const [jobData, setJobData] = useState([]);
  const [linkedJobs, setLinkedJobs] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  var pageNum = 0;
  // const [startTime, handleStartTime] = useState(
  //   new Date("Sat Dec 18 2021 14:00:00 GMT+0530 (India Standard Time)")
  // );
  // const [endTime, handleEndTime] = useState(
  //   new Date("Sat Dec 18 2021 17:00:00 GMT+0530 (India Standard Time)")
  // );
  // const history = useHistory();
  //console.log(history);

  // const handleclick = () => {
  //   history.push("/alljobs");
  //   // history.push("/alljob", { fromPopup: true });
  //   // history.push({
  //   //   pathname: "/jobid145",
  //   //   search: "?id=5",
  //   //   hash: "#react",
  //   //   state: { fromPopup: true },
  //   // });
  // };

  /*To Prevent right click on screen*/
  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }

  // function changePage(offset) {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offset);
  // }

  // function previousPage() {
  //   changePage(-1);
  // }

  // function nextPage() {
  //   changePage(1);
  // }
  // var editorStyle = {
  //   width: "100%",
  //   height: "100%",
  // };
  // for  indivisual candidates in left side

  // //  render candidate analytics data
  // const getAnalyticsData = () => {
  //   const corporateId = localStorage.getItem("ci");
  //   //const corporateId = cacheData.ci;
  //   var jobId = props.match.params.jobId;
  //   axios
  //     .get(dEndPoints.candidateAnalytics(corporateId, jobId))
  //     .then((res) => {
  //       let data = res.data;
  //       if (data.code === 100129) {
  //         setCandidateAnalytics(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const activeCandidateAnalytics =
  //   candidateAnalytics.find((data) => data._id === candidateId) || {};

  //const userType = cacheData.ut;
  //var jobId = props.match.params.jobId;
  useEffect(() => {
    handleSearchJob("", pageNum);
  }, [pageNum]);
  const userType = localStorage.getItem("ut");
  const handleSearchJob = (serchInput, pageNum) => {
    const vendorCorporateId = localStorage.getItem("vci");
    const data = {
      keywords: serchInput,
    };
    if (userType === "1000") {
      props.showLoader({ show: true });
      axios
        .post(dEndPoints.findRequirement(vendorCorporateId), data, {
          params: {
            limit: 12,
            pageNumber: pageNum,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          if (res.data.data.jobs) {
            setJobData(res.data.data.jobs);
            setLinkedJobs(res.data.data.linkedJob);
            setJobCount(Math.floor(data.jobCount / 12) + 1);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  var linkjobIds = linkedJobs.map((job) => {
    return job.jobId;
  });
  //const path = props.jobdetails.jdFile ? props.jobdetails.jdFile.fname : "";

  // useEffect(() => {
  //   getInterviewRounds();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (!props.jobdetails || !props.jobdetails.jobDesc) {
    return null;
  }
  // const blocksFromHtml = htmlToDraft(
  //   props.jobdetails && props.jobdetails.jobDesc
  // );

  // const { contentBlocks, entityMap } = blocksFromHtml;
  // const contentState = ContentState.createFromBlockArray(
  //   contentBlocks,
  //   entityMap
  // );
  // const editorState = EditorState.createWithContent(contentState);

  // download Jd $$$$$$$$

  //const path = props.jobdetails.jdFile ? props.jobdetails.jdFile : "";

  //var ext = path.split(".");
  //var type = ext[ext.length - 1];

  // function to create interview round & slots

  return (
    <div className="Job__detail-page mb-5">
      <JdHeader
        jdTitle={props.jobdetails.desig}
        jdType={props.jobdetails.jobType}
        locations={props.jobdetails.city}
        postedOn={props.jobdetails.cOn}
        company={props.jobdetails.cName}
      />
      <JdBody
        jdCname={props.jobdetails.desig}
        rqId={props.jobdetails.reqId}
        Qualification={props.jobdetails.qual}
        Locations={
          props.jobdetails && props.jobdetails.city
            ? props.jobdetails.city.join()
            : ""
        }
        Experience={
          props.jobdetails.expFrom + " - " + props.jobdetails.expTo + " Years"
        }
        Openings={props.jobdetails.totpos}
        Notice={
          props.jobdetails && props.jobdetails.nPeriod
            ? props.jobdetails.nPeriod + "  Days "
            : "NA"
        }
        Budget={
          props.jobdetails.minBudget +
          " LPA" +
          "-" +
          props.jobdetails.maxBudget +
          " LPA"
        }
        Valid={props.jobdetails.validFor}
        SkillsP={
          props.jobdetails && props.jobdetails.pSkills
            ? props.jobdetails.pSkills.join()
            : ""
        }
        SkillsS={
          props.jobdetails && props.jobdetails.sSkills
            ? props.jobdetails.sSkills.join()
            : ""
        }
        extraDetails={props.jobdetails && props.jobdetails.extraDetails}
        jdPreview={props.jobdetails.jobDesc}
        jdFile={props.jobdetails.jdFile}
        walkInDate={
          props.jobdetails.walkinD ? props.jobdetails.walkinD.walkInDate : ""
        }
        walkInTime={
          props.jobdetails.walkinD ? props.jobdetails.walkinD.walkInTime : ""
        }
        walkInVenue={
          props.jobdetails.walkinD ? props.jobdetails.walkinD.walkInVenue : ""
        }
        jobtype={props.jobdetails.jobType}
        showSnackBar={props.showSnackBar}
      />
      <JdFooter linkjobIds={linkjobIds} jobId={props.jobId} />
      {/* <UploadResumes />
        <InterviewDetails />
        <BrowseCandidate /> */}
    </div>
  );
};

//export default JobDetails;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(JobDetails);
