import React from "react";
import { Route, Switch } from "react-router-dom";

import Employers from "./home/home/Employers";
import About from "./home/home/About";
import OurServices from "./home/home/OurServices";
import ContactUs from "./home/home/ContactUs";
import Login from "./common/register/Login";
import Registration from "./common/register/Registration";
import ForgotPassword from "./common/register/ForgotPassword";
import CustomBucketDetails from "./pages/CustomBucketDetails";
import Unsubscribe from "./common/register/Unsubscribe";
import HiringPartners from "./home/home/HiringPartners";
// import LayoutsView from "./components/dashboardLayouts/LayoutsView";
import ResetPassword from "./common/register/ResetPassword";
import Dashboard from "./pages/Dashboard";
import BucketDetail from "./pages/BucketDetail";
import Schedules from "./pages/Schedules";
import Settings from "./pages/Settings";
import JobsPage from "./pages/JobsPage";
// import endPoints from "./config/end-point";
// import JobDetails from "./components/jobdetailspage/JobDetails";
import UserProfile from "./pages/userprofile/UserProfile";
import CompanyInfo from "./pages/userprofile/CompanyInfo";
import NotificationPage from "./pages/notifications/NotificationPage";
import NotificationSetting from "./pages/notifications/NotificationSetting";
import CreateJob from "./components/jobdetailspage/createjob/CreateJob";
import BucketSettings from "./pages/buckets/BucketSettings";
import CreateBucket from "./pages/buckets/CreateBucket";
import "./utils/axios";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { PrivateRoute, PublicRoute } from "./utils/Router";
import { roles } from "./constant/common.constant";
import PageNotFound from "./common/PageNotFound/PageNotFound";
// import Tags from "./components/admin/Tags";
import DashboardRequirement from "./components/jobdetailspage/DashboardRequirement";
import FindJobs from "./components/jobdetailspage/FindJobs";
import BulkUploadResume from "./pages/userprofile/BulkUploadResume";
import UserActivation from "./components/admin/UserActivation";
import ApproveJob from "./components/admin/ApproveJob";
//import AnnotationResumes from "./components/admin/AnnotationResumes";
import AllUser from "./components/admin/AllUser";
import Communication from "./components/admin/Communication";
import TableViewPage from "./components/dashboardLayouts/TableViewPage";
import JobSetting from "./pages/userprofile/JobSetting";
import UserDetailsPage from "./components/admin/allusers/UserDetailsPage";
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
export const store = createStoreWithMiddleware(rootReducer);
function App() {
  return (
    <Switch>
      <PublicRoute
        path="/"
        exact={true}
        component={Employers}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PublicRoute
        path="/employers"
        exact={true}
        component={OurServices}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />

      <Route
        path="/about"
        exact={true}
        component={About}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <Route
        path="/contactus"
        exact={true}
        component={ContactUs}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />

      <Route
        path="/hiring-partner"
        exact={true}
        component={HiringPartners}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <Route
        path="/fp/:passwordResetCode/:emailId"
        component={ResetPassword}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <Route
        path="/unsubscribe/:emailId/:userId"
        component={Unsubscribe}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />

      <PublicRoute
        path="/login-signup"
        exact={true}
        component={Login}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PublicRoute
        path="/signup"
        exact={true}
        component={Registration}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PublicRoute
        path="/forgotpassword"
        exact={true}
        component={ForgotPassword}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/dashboard"
        exact={true}
        component={Dashboard}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/settings/job-settings"
        exact={true}
        component={JobSetting}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/alljobs"
        exact={true}
        component={JobsPage}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/bucketdetails"
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
        component={BucketDetail}
      />
      <PrivateRoute
        path="/custombucketdetails"
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
        component={CustomBucketDetails}
      />
      <PrivateRoute
        path="/Schedules"
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
        component={Schedules}
      />
      <PrivateRoute
        path="/settings"
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
        component={Settings}
      />
      <PrivateRoute
        path="/alljobs/:jobId"
        component={DashboardRequirement}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/alluser/:userId/:userType"
        exact={true}
        component={UserDetailsPage}
        allowedRoles={[roles.ADMIN]}
      />
      <PrivateRoute
        path="/createjob/:jobId"
        component={CreateJob}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.ADMIN]}
      />
      <PrivateRoute
        path="/settings/userprofile"
        exact={true}
        component={UserProfile}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/notifications"
        component={NotificationPage}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/settings/notification-setting"
        component={NotificationSetting}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/settings/companyprofile"
        component={CompanyInfo}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/createjob"
        component={CreateJob}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.ADMIN]}
      />
      <PrivateRoute
        path="/tabledetails"
        component={TableViewPage}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/settings/bucketSettings"
        component={BucketSettings}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/bucketSettings/create-bucket"
        component={CreateBucket}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/bucketSettings/update-bucket/:bucketId"
        component={CreateBucket}
        exact={true}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/find-jobs"
        exact={true}
        component={FindJobs}
        allowedRoles={[roles.VENDOR]}
      />
      <PrivateRoute
        path="/settings/uploadResumes"
        component={BulkUploadResume}
        exact={true}
        allowedRoles={[roles.VENDOR, roles.ADMIN]}
      />
      <PrivateRoute
        path="/activateuser"
        component={UserActivation}
        exact={true}
        allowedRoles={[roles.ADMIN]}
      />
      <PrivateRoute
        path="/approveJob"
        component={ApproveJob}
        exact={true}
        allowedRoles={[roles.ADMIN]}
      />
      <PrivateRoute
        path="/alluser"
        exact={true}
        component={AllUser}
        allowedRoles={[roles.ADMIN]}
      />
      <PrivateRoute
        path="/communication"
        exact={true}
        component={Communication}
        allowedRoles={[roles.ADMIN]}
      />
      {/* <PrivateRoute
        path="/tags/:date/:status"
        component={Tags}
        exact={true}
        allowedRoles={[roles.ADMIN]}
      /> */}
      {/* <PrivateRoute
        path="/annotation"
        component={AnnotationResumes}
        exact={true}
        allowedRoles={[roles.ADMIN]}
      /> */}
      <Route
        path="/404"
        component={PageNotFound}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
      <Route
        path="*"
        component={PageNotFound}
        allowedRoles={[roles.CORPORATE, roles.VENDOR, roles.ADMIN]}
      />
    </Switch>
  );
}

export default App;
