import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import axios from "axios";
import endPoints, { dEndPoints } from "../../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import { showSnackBar, showLoader } from "../../../actions/index";

import { useParams } from "react-router";
//import { ProgressBar } from "./ProgressBar";
const SkillsAndQualification = (props) => {
  let { jobId } = useParams();
  const [companyList, setCompanyList] = useState([]);
  const [progress, SetProgress] = useState({ percent: 0 });
  // const [coOwnerList, setCoOwnerList] = useState([]);
  const [closeJob, setCloseJob] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadCorporate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const userType = localStorage.getItem("ut");
  //var prevCount = usePrevious(cjob.cName);
  let history = new useHistory();
  // if (cjob.cName) {
  //   loadCompanyCoOwners();
  // }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangePref = (e) => {
    const cjob = { ...props.job };
    var pref = cjob.pref || {};

    pref[e.target.name] = parseFloat(e.target.value);
    cjob.pref = pref;
    props.setJob(cjob);
  };
  // const handleCandidateInfo = (e) => {
  //   // const cjob = { ...props.job };
  //   // var cInfo = cjob.cInfo || {};
  //   // cInfo[e.target.name] = e.target.checked;
  //   // cjob.cInfo = cInfo;
  //   // props.setJob(cjob);
  // };
  // const updateProgress = (field, val) => {
  //   SetProgress({ [field]: val });
  // };
  const handleCloseJob = () => {
    setCloseJob(false);
    var cjob = { ...props.job };
    cjob.closedComment = [];
    props.setJob({ ...props.job, ...cjob });
  };
  function handleInputChange(event) {
    var cjob = { ...props.job };

    /*if (event.target.name === "talenqPayRoll") {
      cjob[event.target.name] = event.target.value; 
    } else  */ if (event.target.name === "is_talenq_closed") {
      var closeJob = cjob && cjob.feedback ? cjob.feedback : {};
      closeJob[event.target.name] = event.target.value === "true";
      cjob.feedback = closeJob;
    } /*else if (event.target.name === "coOwners") {
      cjob[event.target.name] = event.target.value;
      // cjob.coOwners = event.target.value;
      setJob(cjob) */
    if (event.target.name === "closedComment") {
      cjob[event.target.name] = event.target.value;
    } else {
      cjob[event.target.id] = event.target.value;
    }

    props.setJob({ ...props.job, ...cjob });
  }
  const loadCorporate = () => {
    // const corporateId = localStorage.getItem("corporateId");
    var pageNum = 1;
    var sort_createdon = -1;
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllCorporateList, {
        params: {
          pageNumber: pageNum,
          cOn: sort_createdon,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;
        setCompanyList(data.data);
        //console.log("data", companyList);
      })
      .catch((error) => {
        props.showLoader({ show: false });
        //console.log("error while fetching data", error);
      });
  };
  const handleShowCloseJob = (e) => {
    e.preventDefault();
    var cjob = { ...props.job };
    var feedback = cjob && cjob.feedback ? cjob.feedback : {};
    cjob.feedback = feedback;
    props.setJob(cjob);
    setCloseJob(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteJob = (e, status) => {
    e.preventDefault();
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";
    //var jobId = props.match.params.jobId;
    //var jobId = props.job._id;
    props.showLoader({ show: true });
    axios
      .delete(dEndPoints.deleteJob(corporateId, jobId))
      .then((res) => {
        props.showLoader({ show: false });
        //var data = res.data;
        handleClose();
        props.showSnackBar({
          message: "job Deleted Successfully",
          open: true,
          type: "success",
        });

        history.push("/alljobs");
      })
      .catch((error) => {
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };
  const updateJob = (e, status) => {
    e.preventDefault();
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";
    //var jobId = props.match.params.jobId;
    //var jobId = props.job._id;
    var cjob = { ...props.job };

    cjob.status = status;
    if (cjob.jdFile) {
      cjob.jdFile = {
        fname: props.job.jdFile.fname,
        n_fpath: props.job.jdFile.n_fpath,
        n_ftype: props.job.jdFile.n_ftype,
        n_size: props.job.jdFile.n_size,
      };
    }
    if (cjob.extraFields.includes(undefined)) {
      cjob.extraFields = [];
    }
    /*if(userType === "Corporate") {
   delete cjob.rate;
    
  } */
    //cjob.city = city;

    /*if (cjob.city) {
      cjob.city.length = 0;
      cjob.city.push(cjob.city);
    } */
    if (cjob.validFor) {
      delete cjob.validFor;
    }
    props.showLoader({ show: true });

    axios
      .patch(dEndPoints.updateJob(corporateId, jobId), cjob)
      .then((res) => {
        props.showLoader({ show: false });
        //var data = res.data;
        editJob();
        props.showSnackBar({
          message: "job Updated Successfully",
          open: true,
          type: "success",
        });
        history.push("/alljobs");
      })
      .catch((error) => {
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };
  const editJob = () => {
    let cId = localStorage.getItem("ci");
    //let cId = cacheData ? cacheData.ci : "";
    //var jobId = props.match.params.jobId;
    //var jobId = props.job._id;
    let data = { ...props.job };
    //data.jobId = jobIDs;
    axios
      .put(dEndPoints.editJob(cId, jobId), data)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };
  const showActionBtnConditionally = () => {
    if (props.status === "New") {
      return (
        <div
          style={{
            marginTop: "3.5rem",
            marginBottom: "1rem",
            marginLeft: "1rem",
          }}
        >
          <Button
            type="button"
            // color="primary"
            className="tq-reset"
            onClick={() => history.push("/alljobs")}
          >
            Back to Dashboard
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => setjobStatus(event, "Draft")}
            // color="twitter"
            variant="primary"
            // className="tq-theme"
          >
            Save Job
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => setjobStatus(event, "Awaiting Approval")}
            // color="instagram"
            variant="success"
            // className="tq-success"
          >
            Post Job
          </Button>
        </div>
      );
    }
    if (props.status === "Open") {
      return (
        <div style={{ marginTop: "3.5rem", marginBottom: "1rem" }}>
          <Button
            type="button"
            // color="primary"
            className="tq-theme"
            onClick={() => history.push("/dashboard")}
          >
            Back to Dashboard
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => updateJob(event, "Inactive")}
            // className="tq-close"
            variant="danger"
            // color="twitter"
          >
            Inactive
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={handleShowCloseJob}
            // color="twitter"
            // className="tq-close"
            // className="tq-reset"
            variant="secondary"
          >
            Close
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => updateJob(event, "Open")}
            // color="instagram"
            // className="tq-success"
            variant="success"
          >
            Update
          </Button>
          {!props.resumeCount ? (
            <Button
              style={{ marginLeft: "0.5rem" }}
              // color="twitter"
              className="tq-close"
              onClick={handleClickOpen}
            >
              Delete
            </Button>
          ) : null}
        </div>
      );
    }
    if (props.status === "Awaiting Approval") {
      return (
        <div style={{ marginTop: "3.5rem", marginBottom: "1rem" }}>
          {/* {userType === "1110" ? (
            <Button
              type="button"
              // color="primary"
              className="tq-theme"
              onClick={() => props.history.push("/approveJob")}
            >
              Back to Approve Job
            </Button>
          ) : ( */}
          <Button
            type="button"
            // color="primary"
            className="tq-reset"
            onClick={() => history.push("/dashboard")}
          >
            Back to Dashboard
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => updateJob(event, "Inactive")}
            // color="twitter"
            // className="tq-inactive"
            variant="danger"
          >
            Inactive
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={handleShowCloseJob}
            // color="twitter"
            // className="tq-reset"
            variant="secondary"
          >
            Close
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => updateJob(event, "Awaiting Approval")}
            // color="instagram"
            // className="tq-theme"
            variant="primary"
          >
            Update
          </Button>
          {!props.resumeCount ? (
            <Button
              style={{ marginLeft: "0.5rem" }}
              // color="twitter"
              className="tq-close"
              onClick={handleClickOpen}
            >
              Delete
            </Button>
          ) : null}
        </div>
      );
    }

    if (props.status === "Draft") {
      return (
        <div style={{ marginTop: "3.5rem", marginBottom: "1rem" }}>
          <Button
            // color="primary"
            className="tq-reset"
            onClick={() => history.push("/dashboard")}
          >
            Back to Dashboard
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => updateJob(event, "Draft")}
            // color="twitter"
            className="tq-theme"
          >
            Update
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            onClick={(event) => updateJob(event, "Awaiting Approval")}
            // color="instagram"
            className="tq-success"
          >
            Post Job
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={handleShowCloseJob}
            // color="twitter"
            className="tq-reset"
          >
            Close
          </Button>
          {!props.resumeCount ? (
            <Button
              style={{ marginLeft: "0.5rem" }}
              onClick={handleClickOpen}
              // color="twitter"
              className="tq-close"
            >
              Delete
            </Button>
          ) : null}
        </div>
      );
    }

    if (props.status === "Inactive") {
      return (
        <div style={{ marginTop: "3.5rem", marginBottom: "1rem" }}>
          <Button
            type="button"
            value="Cancel"
            // color="primary"
            className="tq-back"
            onClick={() => history.push("/dashboard")}
          >
            Back to Dashboard
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={(event) => updateJob(event, "Open")}
            // color="instagram"
            // className="tq-activate"
            variant="success"
          >
            Activate
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            type="submit"
            onClick={handleShowCloseJob}
            // color="twitter"
            // className="tq-reset"
            variant="secondary"
          >
            Close
          </Button>
          {!props.resumeCount ? (
            <Button
              style={{ marginLeft: "0.5rem" }}
              onClick={handleClickOpen}
              // color="twitter"
              // className="tq-close"
              variant="danger"
            >
              Delete
            </Button>
          ) : null}
        </div>
      );
    }

    if (props.status === "Close") {
      return (
        <div style={{ marginTop: "3.5rem", marginBottom: "1rem" }}>
          <Button
            type="button"
            value="Cancel"
            // color="primary"
            className="tq-reset"
            onClick={() => history.push("/dashboard")}
          >
            Back to Dashboard
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            onClick={(event) => updateJob(event, "Open")}
            // color="instagram"
            // className="tq-success"
            variant="success"
          >
            Repost Job
          </Button>
          {!props.resumeCount ? (
            <Button
              style={{ marginLeft: "0.5rem" }}
              onClick={handleClickOpen}
              // color="twitter"
              // className="tq-close"
              variant="danger"
            >
              Delete
            </Button>
          ) : null}
        </div>
      );
    }
  };

  function setjobStatus(event, status) {
    event.preventDefault();
    const corporateId = localStorage.getItem("ci");
    var cjob = { ...props.job };
    cjob.status = status;
    if (props.job.jdFile) {
      cjob.jdFile = props.job.jdFile;
    }
    delete cjob.cooList;
    if (jobId) {
      delete cjob.validFor;
    }
    if (props.job.jobType !== "Contractor") {
      delete cjob.talenqPayRoll;
    }
    //    cjob.qual = cjob.qual.toString();
    cjob.conPer.phNum = parseInt(props.job.conPer.phNum);

    let cId = companyList.filter(function (corporateId) {
      return corporateId.companyName === cjob.cName;
    });

    props.showLoader({ show: true });
    for (var i = 0; i < cId.length; i++) {
      cjob.cId = cId[i].corporateId;
    }

    axios({
      method: "POST",
      url: dEndPoints.postJobCreation(corporateId),
      data: cjob,
    })
      .then((response) => {
        props.showLoader({ show: false });

        var data = response.data;
        if (data.code === 100000) {
          rankingAndRecommendationJd(data.data.jobId);
          if (cjob.jdFile) {
            fetchFileJd(data.data.jobId);
          }
        }
        props.showSnackBar({
          message: "job created successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        history.push("/alljobs");
        // }
        if (data.code === 100087) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  }

  const fetchFileJd = (jobId) => {
    var cjob = { ...props.job };
    var data = {
      fname: cjob.jdFile.fname,
    };

    props.showLoader({ show: true });
    axios
      .post(dEndPoints.fetchJdFile(jobId), data)
      .then((res) => {
        //  var data = res.data;

        //setJobDesc(data);
        props.showLoader({ show: false });
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("Error", error);
      });
  };

  const rankingAndRecommendationJd = (jobIDs) => {
    let cId = localStorage.getItem("ci");
    //let cId = cacheData ? cacheData.ci : "";
    let jobId = jobIDs;
    let data = { ...props.job };
    data.jobId = jobIDs;
    axios
      .post(dEndPoints.RecommendationJd(cId, jobId), data)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };
  return (
    <div className="Job__type_select skills__qualification-page tq-form mb-5">
      {" "}
      <div className="mb-5">
        <h2 className="pageHeadings"> Resume Preferences : </h2>
      </div>
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>Skills</Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          xs={8}
          className="mt-4"
          controlId="resumeAnalytics"
        >
          <div className="document__details ">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="skillsL"
                name="skills"
                checked={
                  props.job && props.job.pref && props.job.pref.skills === 0.5
                }
                value={0.5}
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="skillsL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="skillsM"
                name="skills"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.skills === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="skillsM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="skillsH"
                name="skills"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.skills === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="skillsH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {/* Experience */}
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>Experience</Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="mt-4">
          <div className="document__details ">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="exprL"
                name="expr"
                value={0.5}
                checked={
                  props.job && props.job.pref && props.job.pref.expr === 0.5
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="exprL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="exprM"
                name="expr"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.expr === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="exprM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="exprH"
                name="expr"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.expr === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="exprH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {/* Location */}
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>Location</Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="mt-4">
          <div className="document__details ">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="ctL"
                name="city"
                value={0.5}
                checked={
                  props.job && props.job.pref && props.job.pref.city === 0.5
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="ctL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="ctM"
                name="city"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.city === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="ctM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="ctH"
                name="city"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.city === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="ctH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {/* Company */}
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>Company</Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="mt-4">
          <div className="document__details">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="coL"
                name="company"
                value={0.5}
                checked={
                  props.job && props.job.pref && props.job.pref.company === 0.5
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="coL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="coM"
                name="company"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.company === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="coM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="coH"
                name="company"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.company === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="coH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {/* CTC */}
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>CTC</Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="mt-4">
          <div className="document__details">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="ctL"
                name="eCtc"
                value={0.5}
                checked={
                  props.job && props.job.pref && props.job.pref.eCtc === 0.5
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="ctL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="ctM"
                name="eCtc"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.eCtc === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="ctM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="ctH"
                name="eCtc"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.eCtc === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="ctH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {/* Designation */}
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>Designation</Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="mt-4">
          <div className="document__details ">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="deL"
                name="desig"
                value={0.5}
                checked={
                  props.job && props.job.pref && props.job.pref.desig === 0.5
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="deL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="deM"
                name="desig"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.desig === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="deM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="deH"
                name="desig"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.desig === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="deH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {/* Qualification */}
      <Row className="label__design-analyticsdata">
        <Form.Group as={Col} xs={3} className="mt-4">
          <Form.Label>Qualification</Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="mt-4">
          <div className="document__details">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input dnr"
                type="radio"
                id="quL"
                name="qual"
                value={0.5}
                checked={
                  props.job && props.job.pref && props.job.pref.qual === 0.5
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="quL">
                Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input wr"
                type="radio"
                id="quM"
                name="qual"
                value={0.75}
                checked={
                  props.job && props.job.pref && props.job.pref.qual === 0.75
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="quM">
                Mid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input suc"
                type="radio"
                id="quH"
                name="qual"
                value={1}
                checked={
                  props.job && props.job.pref && props.job.pref.qual === 1
                }
                onChange={handleChangePref}
              />
              <label className="form-check-label" for="quH">
                High
              </label>
            </div>
          </div>
        </Form.Group>
      </Row>
      {showActionBtnConditionally()}
      <Modal
        show={open}
        dialogClassName="tq__modal-default"
        onHide={handleClose}
      >
        <Modal.Header className="border-0 text-center" closeButton>
          <Modal.Title className="w-100">Delete Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="text__style m-0">
            By deleting this job, You will be removing all of the data related
            to this job . This action cannot be undone !
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button
            onClick={handleClose}
            // color="info"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            value="Delete"
            onClick={(event) => deleteJob(event, "Delete")}
            variant="primary"
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={closeJob}
        dialogClassName="tq__modal-default"
        onHide={handleCloseJob}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Close Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form ">
          <Form.Group>
            <Form.Label className="modal__heading mb-3">
              Did this position close through talenQ
            </Form.Label>
            <div className="form-control">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="yes"
                  name="is_talenq_closed"
                  value={true}
                  checked={
                    props.job.feedback &&
                    props.job.feedback.is_talenq_closed === true
                  }
                  onClick={handleInputChange}
                />
                <label className="form-check-label" for="pg">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="no"
                  value={false}
                  name="is_talenq_closed"
                  checked={
                    props.job.feedback &&
                    props.job.feedback.is_talenq_closed === false
                  }
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="pan">
                  No
                </label>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label className="modal__heading mb-3">
              All pending resume would be Rejected, Please enter any comment.
            </Form.Label>
            <FormControl
              value={props.job.closedComment}
              onChange={handleInputChange}
              aria-label="Small"
              aria-describedby=""
              placeholder="Your Comment Here...."
              name="closedComment"
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleCloseJob}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(event) => updateJob(event, "Close")}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
//export default SkillsAndQualification;
// function usePrevious(value) {
//   // The ref object is a generic container whose current property is mutable ...
//   // ... and can hold any value, similar to an instance property on a class
//   const ref = useRef();
//   // Store current value in ref
//   useEffect(() => {
//     ref.current = value;
//   }, [value]); // Only re-run if value changes
//   // Return previous value (happens before update in useEffect above)
//   return ref.current;
// }
function mapDispatchToProps(dispatch) {
  return {
    //setUserName: bindActionCreators(setUserName, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SkillsAndQualification);
