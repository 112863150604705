import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../../actions/index";
import "../../../components/badge/badge.css";
const RejectReasonModel = (props) => {
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("");
  const [rejectReson, setRejectReson] = useState("");

  const rejectCandidate = () => {
    var data;
    if (comment) {
      data = `${rejectReson} ( ${comment} )`;
    } else {
      data = rejectReson;
      props.showSnackBar({
        message: "Please Enter Rejection Reason",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    props.handleCandidateUpdate(
      props.candidate._id,
      props.candidate.jobId,
      "Rejected",
      data
    );
  };

  const handlerejectChange = (event) => {
    setRejectReson(event.target.value);
    console.log("event.target", event.target.value);
  };
  const commentChange = (e) => {
    setComment(e.target.value);
  };
  return (
    <>
      <div
        className="filter__btn flex__center border-0 bg-white"
        onClick={() => setShow(true)}
      >
        <i className={props.icon}></i>
        {props.BtnName}
      </div>
      {/* <Modal
      // show={show}
      // onHide={() => setShow(false)}
      // dialogClassName="modal__filter-wrapper"
      // aria-labelledby="example-custom-modal-styling-title"
      > */}
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="w-100 text-center">
          Reason for rejection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tq-form">
        <Container>
          {/* replicate the form-check div or map that div */}
          {props.candidate && props.candidate.stat === "Pending" ? (
            <>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="ect"
                  value="Does not meet Educational Criteria"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Educational Criteria"
                  for="ect"
                >
                  Does not meet Educational Criteria
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="expct"
                  value="Does not meet Work experience Criteria"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Work experience Criteria"
                  for="expct"
                >
                  Does not meet Work experience Criteria
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tecCt"
                  value="Does not meet Technical Criteria"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Technical Criteria"
                  for="tecCt"
                >
                  Does not meet Technical Criteria
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="loct"
                  value="Does not meet Location Criteria"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Location Criteria"
                  for="loct"
                >
                  Does not meet Location Criteria
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="perct"
                  value="Does not meet Notice period Criteria"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Notice period Criteria"
                  for="perct"
                >
                  Does not meet Notice period Criteria
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="comskill"
                  value="Does not meet Communication Skills"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Communication Skills"
                  for="comskill"
                >
                  Does not meet Communication Skills
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="metst"
                  value="Does not meet Stability"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Does not meet Stability"
                  for="metst"
                >
                  Does not meet Stability
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="duplicatpr"
                  value="Duplicate Profile"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Duplicate Profile"
                  for="duplicatpr"
                >
                  Duplicate Profile
                </label>
              </div>
            </>
          ) : (
            <>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="techRound"
                  value="Technical Round Reject"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Technical Round Reject"
                  for="techRound"
                >
                  Technical Round Reject
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="hrRound"
                  value="HR Round reject"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="HR Round reject"
                  for="hrRound"
                >
                  HR Round reject
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="manegRound"
                  value="Managerial Round Reject"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Managerial Round Reject"
                  for="manegRound"
                >
                  Managerial Round Reject
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="communiSkills"
                  value="Communication Skills"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Communication Skills"
                  for="communiSkills"
                >
                  Communication Skills
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="noticPd"
                  value="Notice Period"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Notice Period"
                  for="noticPd"
                >
                  Notice Period
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="salaryExp"
                  value="Salary Expectations"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Salary Expectations"
                  for="salaryExp"
                >
                  Salary Expectations
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="locationCtr"
                  value="Location Criteria"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Location Criteria"
                  for="locationCtr"
                >
                  Location Criteria
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="otherReason"
                  value="Other Reasons"
                  onChange={(e) => handlerejectChange(e)}
                />
                <label
                  className="form-check-label"
                  value="Other Reasons"
                  for="otherReason"
                >
                  Other Reasons
                </label>
              </div>
            </>
          )}
          <Row className="mt-4">
            <Form.Group as={Col} xs={12} sm={12}>
              <Form.Label>
                Comments <small className="required__form">*</small>
              </Form.Label>
              <Form.Control
                type="text"
                id="comment"
                placeholder="Comments"
                value={comment}
                onChange={commentChange}
              />
            </Form.Group>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center">
        <ButtonGroup>
          <Button variant="secondary" onClick={props.handleRejectClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={rejectCandidate}>
            Reject
          </Button>
        </ButtonGroup>
      </Modal.Footer>
      {/* </Modal> */}
    </>
  );
};
// export default RejectReasonModel;
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RejectReasonModel);
