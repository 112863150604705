import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../../actions/index";
import axios from "axios";
import endPoints from "../../config/end-point";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { Link } from "react-router-dom";
// import GoBackButton from "../../components/badge/GoBackButton";
const AllNotifications = (props) => {
  const [notifications, setNotifications] = useState([]);
  const userType = localStorage.getItem("ut");

  const [header, setHeader] = useState([
    {
      headerName: "Candidate Name",
      field: "Candidate",
      filter: true,
      width: 220,
      cellRendererFramework: function (params) {
        return <Link>{params.value}</Link>;
      },
    },

    {
      headerName: "JobId",
      field: "JobId",
      filter: true,
      width: 180,
      cellRendererFramework: function (params) {
        return <Link>{params.value}</Link>;
      },
    },
    { headerName: "Message  ", field: "Message", filter: true, width: 550 },
    { headerName: "Added Time", field: "time", width: 490 },
  ]);

  var users = notifications.map((user) => {
    var data = {
      candidateId: user && user.childId ? user.childId : "",
      Candidate: user && user.childName ? user.childName : "",
      JobId:
        user && user.notificationData.reqId ? user.notificationData.reqId : "",
      Message: user && user.action ? user.action : "",
      time: new Date(user.createdOn && user.createdOn).toLocaleString(),
      jobId: user && user.parentId,
    };
    return data;
  });

  useEffect(() => {
    loadAllNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadAllNotifications = () => {
    props.showLoader({ show: true });
    axios({
      url: endPoints.notifications + "?limit=50",
    })
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        if (data && data.code === 100000) {
          setNotifications(data.data);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });

        console.log("error while fetching data", error);
      });
  };
  var openJobDetails;
  if (userType === "1000") {
    openJobDetails = (data) => {
      const jobId = data.data.jobId;
      props.history.push(
        `/alljobs/${jobId}?candId=${data.data.candidateId}&enroll=${true}`
      );
    };
  } else {
    openJobDetails = (data) => {
      const jobId = data.data.jobId;
      props.history.push(`/alljobs/${jobId}?candId=${data.data.candidateId}`);
    };
  }
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <div className="userProfile-Page">
            <div style={{ marginTop: "25px" }}>
              <h3 className="mb-4">All Notifications</h3>
              <div
                className="ag-theme-balham"
                style={{
                  height: "550px",
                  width: "100%",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                <AgGridReact
                  className="table__notification"
                  pagination={true}
                  columnDefs={header}
                  rowData={users}
                  onCellClicked={openJobDetails}
                ></AgGridReact>
              </div>
            </div>
            {/* </div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
//export default UserProfile;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AllNotifications);
