import React, { useState, useEffect } from "react";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";

import PropTypes from "prop-types";
import { showLoader, showSnackBar } from "../../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { dEndPoints } from "../../../config/end-point";
import { statusDisplayMapping } from "../../../common/candidateStatusMapping/StatusMapping";
import CandidateInterviews from "./CandidateInterviews";
//import CandidateSlotDetails from "./CandidateSlotDetails";
import ActionOnCandidates from "./ActionOnCandidates";
import EditCandidate from "./EditCandidate";

//import SlotBooking from "./SlotBooking";
//import InterviewDetails from "../../interview/InterviewDetails";
const CandidateSummary = (props) => {
  // const [joined, setJoined] = useState({ BillableAmount: "" });
  //const [anchorEl, setAnchorEl] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [modalJob, setModalJob] = useState([])
  
  //const [openReject, setOpenReject] = useState(false);
  const [openMove, setopenMove] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [users, setUsers] = useState(props.candidate);
  const [editing, setEditing] = useState(false);
  const [jobModal, setJobModal] = useState([]);
  const [candidateJobs, setCandidateJobs] = useState([])
  const [findJob, setFindJob] = useState({ jobId: "" });
  //const [openHold, setopenHold] = useState(false);
  const [openBlacklist, setOpenBlacklist] = useState(false);
  // const [comment, setComment] = useState("");
  const [blacklist, setBlacklist] = useState();
  const [blacklistComment, setBlacklistComment] = useState("");
  // const [openNotResponding, setOpenNotResponding] = useState(false);
  // const [responding, setResponding] = useState();
  const [openSlotBooking, setOpenSlotBooking] = useState(false);
  const [interviews, setInterviews] = useState([]);
  const [editableUser, setEditableUser] = useState({});
  const initialFormState = {
    name: "",
    email: "",
    contact: "",
    college: "",
    city: "",
    cDesig: "",
    expr: "",
    ctc: "",
    ectc: "",
    cName: "",
    skills: [],
    qual: "",
    nPeriod: null,
    extraFields: [],
    lastworkday: "",
    tenPercent: "",
    twelvePercent: "",
    ugPercent: "",
    pgPercent: "",
    pan: "",
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const editRow = (user) => {
    setEditing(true);
    setCurrentUser({
      name: props.candidate.name,
      email: props.candidate.email,
      contact: props.candidate.contact,
      college: props.candidate.college,
      city: props.candidate.city,
      cDesig: props.candidate.cDesig,
      expr: props.candidate.expr,
      ctc: props.candidate.ctc,
      ectc: props.candidate.ectc,
      cName: props.candidate.cName,
      skills: props.candidate.skills,
      qual: props.candidate.qual,
      nPeriod: props.candidate.nPeriod,
      lastworkday: props.candidate.lastworkday,
      tenPercent: props.candidate.tenPercent,
      twelvePercent: props.candidate.twelvePercent,
      ugPercent: props.candidate.ugPercent,
      pgPercent: props.candidate.pgPercent,
      pan: props.candidate.pan,
      extraFields: props.candidate.extraFields,
    });
  };

  useEffect(() => {
    setInterviews(props.candidate.candidateSlots);
  }, [props.candidate.candidateSlots]);

  useEffect(() => {
    reloadJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  // const handleOpenSlotBooking = () => {
  //   setOpenSlotBooking(true);
  // };
  const handleCloseSlotBooking = () => {
    setOpenSlotBooking(false);
    window.location.reload();
  };

  // *** blacklist dialog *** //
  const handleBlacklistOpen = (blacklist) => {
    setBlacklist(blacklist);
    setOpenBlacklist(true);
  };
  const handleCloseBlacklist = () => {
    setBlacklistComment("");
    setOpenBlacklist(false);
  };
  const blacklistCommentChange = (e) => {
    setBlacklistComment(e.target.value);
  };
  // *** reject dailog *** //

  // const handleRejectClose = () => {
  //   setOpenReject(false);
  // };
  // *** on Hold Dailog *** //
  // const handleOnHold = () => {
  //   setopenHold(true);
  // };
  // const handleCloseOnHold = () => {
  //   setopenHold(false);
  // };
  // on set for interview round
  // const handleOpenSchedule = () => {
  //   setOpenformSchedule(true);
  // };
  // const handleCloseSchedule = () => {
  //   setOpenformSchedule(false);
  // };
  // const handleRespondingOpen = (responding) => {
  //   setResponding(responding);
  //   setOpenNotResponding(true);
  // };
  // const handleRespondingClose = () => {
  //   setComment("");
  //   setOpenNotResponding(false);
  // };

  // const commentChange = (e) => {
  //   setComment(e.target.value);
  // };

  //  ***** Candidate blacklist Update **** //
  const handleBlacklistUpdate = (
    e,
    jobId,
    candidateProfileId,
    candidateStatus,
    blacklistComment,
    blacklist
  ) => {
    //const corporateId = localStorage.getItem("ci");

    // check if user didn't enter the reason
    if (blacklistComment === "") {
      return;
    }
    handleCloseBlacklist();

    // if condition to check if candidate is rejected & still blacklist candidate
    if (blacklist === 1) {
      const data = {
        status: candidateStatus,
        reason: blacklistComment,
      };

      // axios to blacklist candidate
      axios
        .patch(dEndPoints.blacklist(jobId, candidateProfileId), data, {
          params: {
            blacklist: blacklist,
          },
        })
        .then((res) => {
          setBlacklistComment("");
          // props.showLoader({ show: false });
          var response = res.data;

          if (response.data && response.data.code === 100148) {
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
            return;
          }
        })
        .catch((error) => {
          // props.showLoader({ show: false });
          console.log(error);

          return;
        });
    }

    // else if to unblock candidate
    else if (blacklist === 0) {
      const data = {
        status: candidateStatus,
        reason: blacklistComment,
      };
      //props.showLoader({ show: true });
      // axios to unblock candidate
      axios
        .patch(dEndPoints.blacklist(jobId, candidateProfileId), data, {
          params: {
            blacklist: blacklist,
          },
        })
        .then((res) => {
          setBlacklistComment("");
          // props.showLoader({ show: false });
          var response = res.data;

          if (response.data && response.data.code === 100149) {
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
            return;
          }
        })
        .catch((error) => {
          //props.showLoader({ show: false });
          console.log(error);
          return;
        });
    }
  };
 
  // const handleCandidateUpdate = (id, jobId, status, comment, subStatus) => {
  //   //const corporateId = cacheData.ci;
  //   const corporateId = localStorage.getItem("ci");

  //   if (comment) {
  //     var data = {
  //       newStatus: status,
  //       comment: comment,
  //       subStatus: subStatus,
  //     };
  //   } else {
  //     var data = {
  //       newStatus: status,
  //       subStatus: subStatus,
  //     };
  //   }

  //   axios
  //     .put(dEndPoints.corpCandidateUpdate(corporateId, jobId, id), data)
  //     .then((res) => {
  //       var data = res.data;
  //       setComment("");
  //       if (data && data.code === 100000) {
  //         props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
  //       }
  //       if (status === "Onhold") {
  //         handleCloseOnHold();
  //       }
  //       if (status === "Rejected") {
  //         handleRejectClose();
  //       }
  //       if (status === "Screened") {
  //         handleRespondingClose();
  //       }
  //       if (status === "Pending") {
  //         handleRespondingClose();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setComment("");
  //       if (
  //         error.response &&
  //         error.response.data &&
  //         error.response.data.code === 100114
  //       ) {
  //         return;
  //       } else {
  //         return;
  //       }
  //     });
  // };

  const reloadJobs = (pageNum = 0) => {
    //const corporateId = cacheData.ci;
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      //setIsLoading(true)=
      var sort_createdon = -1;
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 100,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
            
            // if (data.data.stats) {
            //   setJobStatus(data.data.stats);
            // }
            // setJobCount(Math.ceil(data.data.total / 12));
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      var sort_createdon = -1;
      axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            limit: 100,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
            // if (data.data.stats) {
            //   setJobStatus(data.data.stats);
            // }
            // setJobCount(Math.ceil(data.data.total / 12));
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  //function of getting all jobs of a candidate
  const getAllJobsOfCandidate = (candId) =>{
    if(userType === "1110"){
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      axios
      .get(dEndPoints.checkDuplicateByAdmin(corporateId, candId))
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;
        if (data && data.code === 100000){ 
        setCandidateJobs(data.data.candidateJobs);
        }
        
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
      })
    }
    if(userType === "1000"){
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      axios
      .get(dEndPoints.checkDuplicateByVendor(vendorCorporateId, candId))
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;
        if (data && data.code === 100000){ 
        setCandidateJobs(data.data.candidateJobs);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
      })
    }
  }
  // Recommendation feedback
  const handleLike = (e, like, dislike) => {
    //const corporateId = cacheData.ci;
    const corporateId = localStorage.getItem("ci");

    var jobId = props.candidate.jobId;

    var candId = props.candidate.candId;

    //const userInfo = { ...recommendation };
    axios
      .post(dEndPoints.createRecommendation(corporateId, candId, jobId), {
        like: 1,
        dislike: 0,
      })
      .then((res) => {
        var data = res.data;

        if (data && data.code === 100128) {
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          props.loadCandidatesRecommendedDetails(
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            true
          );
        }
        if (data.code === 100137) {
          props.loadCandidatesRecommendedDetails(
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            true
          );
          return;
        }
        return;
      })
      .catch((error) => {
        console.log("error in candidate recommendation", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100137
        ) {
          return;
        }
      });
  };



  useEffect(() => {
    if(props.candidate._id){
      getAllJobsOfCandidate(props.candidate._id)
    }
    
  }, [props.candidate._id])

  useEffect(() => {
    modalFunc()
  }, [jobs])

  const handleMoveToAnotherJob = () => {
    filterJobFunc()
    setopenMove(true);
  };
  const handleCloseMoveToAnother = () => {
    setopenMove(false);
  };
  const modalFunc = () => {
    // for company name
    var name = jobs.map((data, i) => {
      return data.cName;
    });
    const ans = _.uniq(name);
    setJobModal(ans);
  };

  const filterJobFunc = () => {
    var candJobsArr = [];
    var filterjob = []
    candidateJobs && candidateJobs.map((data, i) => {
      return candJobsArr.push(data.jobId)
    })
    if(candJobsArr){
      jobs && 
      jobs.map((data, i) => {
        if (candJobsArr.includes(data._id) !== true) {
          return filterjob.push(data);
        }
      });
    }
    if(filterjob){
      setModalJob(filterjob)
    }
  }

  const updateUser = (name, updatedUser) => {
    setEditing(false);
    setUsers(users.map((user) => (user.name === name ? updatedUser : user)));
  };
  const handleCloseEditCandidate = () => {
    setOpenEditModal(false);
  };
  const handleSubmitMove = () => {
    setOpenSubmitModal(true)
  };

  const handleChangeNoticeP = (e) => {
    const info = { ...findJob };
    info.jobId = e.target.value;
    setFindJob(info);
  };
  const handleMovingToAnotherJobUpdate = (id, jobId) => {
    //const corporateId = cacheData.ci;

    if (userType === "1110") {
      const corporateId = localStorage.getItem("ci");
      var data = { jobId: findJob.jobId };
      // var candId;
      // if (comment) {
      //   var data = {
      //     newStatus: status,
      //     comment: comment,
      //     subStatus: subStatus,
      //   };
      // } else {
      //   var data = {
      //     newStatus: status,
      //     subStatus: subStatus,
      //   };
      // }
      props.showLoader({ show: true });
      axios
        .put(
          dEndPoints.moveCandidateToAnotherJobAdmin(corporateId, jobId, id),
          data
        )
        .then((res) => {
          var data = res.data;
          setopenMove(false);
          // candId = props.canId;
          props.showLoader({ show: false });
          setFindJob({ jobId: "" });
          if (data && data.code === 100000 && !data.data.code) {
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
          if (data && data.code === 100000 && data.data.code === 100194) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);

          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100190
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      var data = { jobId: findJob.jobId };
      props.showLoader({ show: true });
      axios
        .put(
          dEndPoints.moveCandidateToAnotherJobVendor(
            vendorCorporateId,
            jobId,
            id
          ),
          data
        )
        .then((res) => {
          var data = res.data;
          setopenMove(false);
          // candId = props.canId;
          props.showLoader({ show: false });
          if (data && data.code === 100000 && !data.data.code) {
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
          if (data && data.code === 100000 && data.data.code === 100194) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);

          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100190
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };
  // Recommendation feedback
  const handleDislike = (e, dislike, like) => {
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData.ci;
    var jobId = props.candidate.jobId;

    var candId = props.candidate.candId;

    // const userInfo = { ...recommendation };
    axios
      .post(dEndPoints.createRecommendation(corporateId, candId, jobId), {
        dislike: 1,
        like: 0,
      })
      .then((res) => {
        var data = res.data;

        if (data && data.code === 100128) {
          props.loadCandidatesRecommendedDetails(
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            true
          );
          return;
        }
      })
      .catch((error) => {
        console.log("error in candidate recommendation", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100137
        ) {
          return;
        }
      });
  };

  const userType = localStorage.getItem("ut");
  var whatsText = `Hello%2C%20%0AI%20am%20recruiting%20for%20the%20below%20position%20and%20found%20your%20candidature%20suitable%20for%20it%3A%20%0A%0A*Company%3A*%20${
    props.job.cName
  }%2C%0A*Role%3A*%20${props.job.desig}%2C%0A*Experience%3A*%20${
    props.job.expFrom + "-" + props.job.expTo
  }Years%2C%0A*Skills%3A*%20_${
    props.job.pSkills ? props.job.pSkills.join() : ""
  }_%2C%0A%2C%0A*Location%3A*%20${
    props.job.city ? props.job.city.join() : ""
  } %20%0A%0ALet%20me%20know%20if%20you%20are%20interested%20in%20this%20position.%20%0AIf%20yes%2C%20please%20send%20us%20your%20_CTC%2C%20Expected%20CTC%2C%20and%20Notice%20Period%20Details_%20with%20your%20*updated%20CV%20to%20recruiters%40talenq.com*%0A%0ARegards%2C%20%0A*Team%20TalenQ*`;
  return (
    <div className="CandidateSummary-Page">
      {props.candidate ? (
        <Row>
          <Col sm={4}>
            <div className="candidate__details">
              <strong>
                {" "}
                {props.candidate.name ? (
                  <span>
                    {props.candidate.name ? (
                      props.candidate.name.substring(0, 30)
                    ) : (
                      <></>
                    )}
                  </span>
                ) : (
                  <></>
                )}{" "}
              </strong>
              <small>
                {" "}
                {props.candidate.expr ? (
                  " " + "|" + " " + props.candidate.expr + " " + "Years"
                ) : (
                  <></>
                )}
                {<></>}
              </small>
              <small className="candidate__designation">
                {props.candidate.cDesig ? (
                  <span>
                    {props.candidate.cDesig} at {props.candidate.cName}
                  </span>
                ) : (
                  <></>
                )}
              </small>
              <span className="flex__center working__in">
                <p>
                  {" "}
                  {props.candidate.city ? (
                    <span>{props.candidate.city}</span>
                  ) : (
                    <></>
                  )}
                </p>
              </span>
            </div>
          </Col>
          <Col sm={4} >
            <div className="contact__details-candidate">
              <div className="contact__details flex__center">
                {props.candidate.email ? (
                  <i className="bx bx-envelope"></i>
                ) : (
                  ""
                )}
                <span>
                  {" "}
                  {props.candidate.email ? (
                    <span> {props.candidate ? props.candidate.email : ""}</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="contact__details flex__center">
                {props.candidate.contact ? <i className="bx bx-phone"></i> : ""}
                <span>
                  {" "}
                  {props.candidate.contact ? props.candidate.contact : ""}
                </span>
                {(userType === "1110" || userType === "1000") &&
                props.candidate.contact ? (
                  <a
                    className="d-flex align-items-center btn btn-success px-2"
                    href={`https://web.whatsapp.com/send?phone=${props.candidate.contact}&text=${whatsText}`}
                    target="_blank"
                    rel="noreferrer"
                    // onClick={(e) => handleSendWhatsApp(userSet, e)}
                  >
                    <i className="bx bxl-whatsapp  mx-0"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="status__candidate text-right">
              {props.candidate.properties &&
              props.candidate.properties[0] &&
              props.candidate.properties[0].backlisted === true ? (
                <Badge className="red">Blacklisted</Badge>
              ) : (
                ""
              )}
              <div className="profile__status">
                <Badge className={`bg-${props.candidate.stat}`}>
                  {statusDisplayMapping[props.candidate.stat]}
                </Badge>
              </div>
              <div className="profile__status">
                {props.candidate.responding === false &&
                props.candidate.stat === "Screened" ? (
                  <Badge className="red">Not Responding</Badge>
                ) : props.candidate.responding === false &&
                  props.candidate.stat === "Pending" ? (
                  <Badge className="red">Not Responding</Badge>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="move__action-button text-right">
              {" "}
              {(props.candidate.stat === "Pending" ||
                props.candidate.stat === "Rejected") &&
              (userType === "1110" || userType === "1000") ? (
                <Button
                  variant="warning"
                  key={props.candidate._id + "Pending"}
                  onClick={handleMoveToAnotherJob}
                >
                  <i className="bx bx-share"></i>&nbsp; Move to Another Job
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <h6 align="center" style={{ marginTop: "30px" }}>
            Data Not Found
          </h6>
        </div>
      )}
      <div className="Job__body candidate__details-professional px-0">
        <Row>
          <Col xs={6}>
            <div className="jd__data ">
              <strong>{props.candidate.skills ? "Skills :" : <></>}</strong>
              <span>
                {props.candidate.skills ? props.candidate.skills.join() : []}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data ">
              <strong>
                {props.candidate.qual ? "Qualification :" : <></>}{" "}
              </strong>
              <span> {props.candidate.qual ? props.candidate.qual : ""}</span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong> {props.candidate.ctc ? "Current CTC :" : <></>} </strong>
              {props.candidate.ctc ? (
                <span>
                  {" "}
                  <label> ₹ </label>
                  {" " + props.candidate.ctc + " LPA"}
                </span>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>{props.candidate.ectc ? "Expected CTC :" : ""} </strong>
              {props.candidate.ectc ? (
                <span>
                  <label> ₹ </label>
                  {" " + props.candidate.ectc + " LPA"}
                </span>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {props.candidate.nPeriod ? "Notice Period :" : ""}
              </strong>
              <span>
                {props.candidate.nPeriod
                  ? props.candidate.nPeriod + " Days"
                  : ""}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong> {props.candidate.cOn ? "Posted On :" : <></>}</strong>
              <span>
                {props.candidate.cOn ? (
                  <Tooltip title="Posted On" placement="bottom">
                    <span>
                      {new Date(props.candidate.cOn).toLocaleDateString()}
                    </span>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {" "}
                {props.candidate.tenPercent ? "10th Percentage :" : <></>}
              </strong>
              <span>
                {props.candidate.tenPercent ? props.candidate.tenPercent : ""}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {props.candidate.twelvePercent ? "12th Percentage :" : <></>}
              </strong>
              <span>
                {props.candidate && props.candidate.twelvePercent
                  ? props.candidate.twelvePercent
                  : ""}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {" "}
                {props.candidate.ugPercent ? "UG Percentage :" : <></>}
              </strong>
              <span>
                {props.candidate && props.candidate.ugPercent
                  ? props.candidate.ugPercent
                  : ""}{" "}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {" "}
                {props.candidate.pgPercent ? "UG Percentage :" : <span></span>}
              </strong>
              <span>
                {props.candidate && props.candidate.pgPercent
                  ? props.candidate.pgPercent
                  : ""}{" "}
              </span>
            </div>
          </Col>
          <Col sm={12}>
            <div className="jd__data jd__others">
              <strong> {props.candidate.college ? "College :" : ""} </strong>
              <span>
                {" "}
                {props.candidate.college ? props.candidate.college : ""}
              </span>
            </div>
          </Col>
          {props.candidate.stat ? (
            <Col sm={6}>
              <div className="jd__data">
                <strong>
                  {props.candidate.pan === " " ? <span></span> : "PanCard :"}
                </strong>
                <span>
                  {props.candidate.pan === undefined ? "" : props.candidate.pan}
                </span>
              </div>
            </Col>
          ) : (
            <span></span>
          )}
          {/* {console.log("Extra Fields", props.candidate.extraFields)} */}
          {props.candidate.extraFields &&
            props.candidate.extraFields.map((item) => (
              item.value !== "" ?
              <Col sm={12}>
                <div className="jd__data jd__others">
                  
                    <strong> {item.name ? item.name : <span></span>}</strong>
                    <span>{item.value ? item.value : ""} </span> 
                </div>
              </Col> : ""
            ))}

          {props.candidate._id ? (
            <>
              {props.candidate && props.candidate.candidateSlots ? (
                <Col sm={12}>
                  <div className="jd__data jd__skills">
                    {/* <CandidateSlots
                  interviews={
                    props.candidate.candidateSlots
                      ? props.candidate.candidateSlots
                      : ""
                  }
                /> */}
                  </div>
                </Col>
              ) : (
                "No interviews"
              )}
            </>
          ) : (
            ""
          )}
          {props.candidate && props.candidate.lastworkday ? (
            <Col sm={6}>
              <div className="jd__data">
                <strong>
                  {props.candidate.lastworkday ? (
                    "last work Day :"
                  ) : (
                    <span></span>
                  )}
                </strong>
                <span>
                  {props.candidate.lastworkday
                    ? new Date(props.candidate.lastworkday).toLocaleDateString()
                    : ""}
                </span>
              </div>
            </Col>
          ) : (
            ""
          )}
          <ButtonGroup className="buttons__group justify-content-center mt-3">
            {props.job && props.job.status === "Close" ? null : (
              <>
                {/* {statusButtons} */}
                <ActionOnCandidates
                  canId={props.canId ? props.canId : ""}
                  candidate={props.candidate}
                  loadCandidatesDetails={props.loadCandidatesDetails}
                  job={props.job}
                  interviews={interviews ? interviews : ""}
                  BlockStateButton={
                    userType === "1110" ? (
                      <>
                        {props.candidate.properties &&
                        props.candidate.properties[0] &&
                        props.candidate.properties[0].backlisted === true ? (
                          <Button
                            variant="success"
                            onClick={() => handleBlacklistOpen(0)}
                          >
                            Unblock
                          </Button>
                        ) : props.candidate.properties &&
                          props.candidate.properties[0] &&
                          props.candidate.properties[0].backlisted === false ? (
                          <Button
                            variant="block"
                            onClick={() => handleBlacklistOpen(1)}
                          >
                            Blacklist
                          </Button>
                        ) : (
                          <Button
                            variant="block"
                            onClick={() => handleBlacklistOpen(1)}
                          >
                            Blacklist
                          </Button>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  }
                  MoveToAnotherJob={
                    props.candidate.stat ? (
                      <Button
                        variant="warning"
                        key={props.candidate._id + "Pending"}
                        // color="info"
                        onClick={handleMoveToAnotherJob}
                      >
                        Move to Another Job
                      </Button>
                    ) : (
                      ""
                    )
                  }
                  LikeDislike={
                    props.candidate.candId ? (
                      <>
                        <Button
                          size="sm"
                          variant="danger"
                          value="dislike"
                          onClick={() => {
                            handleDislike();
                          }}
                        >
                          Dislike
                        </Button>
                        <Button
                          size="sm"
                          variant="success"
                          value="like"
                          onClick={() => {
                            handleLike();
                          }}
                        >
                          Like
                        </Button>
                      </>
                    ) : (
                      ""
                    )
                  }
                />
              </>
            )}
          </ButtonGroup>
          {props.candidate._id ? (
            <>
              {props.candidate && props.candidate.candidateSlots ? (
                <Col sm={12}>
                  <CandidateInterviews
                    candidate={props.candidate}
                    interviews={interviews ? interviews : ""}
                    jobId={props.candidate.jobId}
                    loadCandidatesDetails={props.loadCandidatesDetails}
                  />
                </Col>
              ) : (
                "No interviews"
              )}
            </>
          ) : (
            ""
          )}
          {/* ******Dialog for blacklisting candidate ******* */}

          <Modal
            open={openBlacklist}
            dialogClassName="tq__modal-default"
            onClose={handleCloseBlacklist}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title className="w-100 text-center">
                Please specify the reason.{" "}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Control
                id="comment"
                label="Reason"
                value={blacklistComment}
                onChange={blacklistCommentChange}
                placeholder="Reason for Blocking the candidate"
              />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center">
              <Button variant="secondary" onClick={handleCloseBlacklist}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={(event) =>
                  handleBlacklistUpdate(
                    event,
                    props.candidate.jobId,
                    props.candidate._id,
                    props.candidate.stat,
                    blacklistComment,
                    blacklist
                  )
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* ******Dialog for blacklisting candidate ******* */}

          <Modal
            show={openBlacklist}
            dialogClassName="tq__modal-default"
            onHide={handleCloseBlacklist}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title className="w-100 text-center">
                Please specify the reason.
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="tq-form">
              <Form.Control
                id="comment"
                label="Reason"
                value={blacklistComment}
                onChange={blacklistCommentChange}
                placeholder="Reason for Unblocking the candidate"
              />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center">
              <Button variant="secondary" onClick={handleCloseBlacklist}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={(event) =>
                  handleBlacklistUpdate(
                    event,
                    props.candidate.jobId,
                    props.candidate._id,
                    props.candidate.stat,
                    blacklistComment,
                    blacklist
                  )
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* ******Dialog for Moving candidate to another job ******* */}
          <Modal
            show={openMove}
            dialogClassName="tq__modal-default"
            onHide={handleCloseMoveToAnother}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title className="w-100 text-center">
                Select the company
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="tq-form">
              <select
                id="jobId"
                className="form-select"
                aria-label="noticePriod"
                multiple={false}
                required="required"
                value={findJob.jobId}
                onChange={handleChangeNoticeP}
              >
                <option>Choose Company</option>
                {jobModal &&
                  jobModal.map((details, index) =>
                    userType === "1110" || userType === "1000" ? (
                      <optgroup key={index} label={details}>
                        {modalJob.map((job, i) => {
                          return job.cName === details ? (
                            <option value={job._id} key={i}>
                              JobId: {job.reqId} | Designation: {job.desig}{" "}
                            </option>
                          ) : (
                            " "
                          );
                        })}
                      </optgroup>
                    ) : (
                      ""
                    )
                  )}
              </select>
            </Modal.Body>
            {userType === "1110" ? (
              <Modal.Footer className="border-0 justify-content-center">
                <Button onClick={handleCloseMoveToAnother} variant="secondary">
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  value="Pending"
                  onClick={() =>
                    handleMovingToAnotherJobUpdate(
                      props.candidate._id,
                      props.candidate.jobId,
                      "Pending"
                    )
                  }
                >
                  Submit
                </Button>
              </Modal.Footer>
            ) : userType === "1000" ? (
              <Modal.Footer className="border-0 justify-content-center">
                <Button onClick={handleCloseMoveToAnother} variant="secondary">
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  value="Pending"
                  onClick={() => {
                    setopenMove(false);
                    setOpenPopup(true);
                  }}
                >
                  Submit
                </Button>
              </Modal.Footer>
            ) : (
              " "
            )}
          </Modal>
          {/* ******Dialog for Moving candidate to another job ******* */}
          {userType === "1000" ? (
            <Modal
              show={openPopup}
              dialogClassName="tq__modal-default"
              onHide={() => setOpenPopup(false)}
            >
              <Modal.Header className="border-0" closeButton>
                <Modal.Title className="w-100 text-center">
                  Please confirm you have provided necessary information to candidate regarding new job.
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer className="border-0 justify-content-center">
                <Button
                  onClick={() => {
                    setOpenPopup(false);
                    const info = { ...findJob };
                    info.jobId = "";
                    setFindJob(info);
                  }}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  value="Pending"
                  onClick={() => {
                    setOpenPopup(false);
                    editRow();
                    setEditableUser(props.candidate);
                    setOpenEditModal(true);
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            " "
          )}
          {userType === "1000" ? (
            <Modal
              show={openEditModal}
              dialogClassName="tq__modal-default"
              onHide={() => setOpenEditModal(false)}
            >
              <Modal.Header className="border-0" closeButton>
                <Modal.Title className="w-100 text-center">
                  Edit Candidate
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0">
                <EditCandidate
                  editing={editing}
                  setEditing={setEditing}
                  currentUser={currentUser}
                  updateUser={updateUser}
                  openformSchedule={openEditModal}
                  isMove={true}
                  handleClose={handleCloseEditCandidate}
                  handleSubmitMove={handleSubmitMove}
                  editableUser={editableUser}
                  job={props.job}
                  loadCandidatesDetails={props.loadCandidatesDetails}
                />
              </Modal.Body>
            </Modal>
          ) : (
            " "
          )}
          {
            userType === "1000" ?
            <Modal
              show={openSubmitModal}
              dialogClassName="tq__modal-default"
              onHide={() => setOpenSubmitModal(false)}
            >
              <Modal.Header className="border-0" closeButton>
                <Modal.Title className="w-100 text-center">
                  Press 'confirm' to move the candidate 'cancel' to revert the action
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer className="border-0 justify-content-center">
                <Button
                  onClick={() => {
                    setOpenSubmitModal(false);
                    const info = { ...findJob };
                    info.jobId = "";
                    setFindJob(info);
                  }}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  value="Pending"
                  onClick={() => {
                    setOpenSubmitModal(false);
                    handleMovingToAnotherJobUpdate(
                    props.candidate._id,
                    props.candidate.jobId,
                    "Pending"
                  )
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
            : " "
            
          }
        </Row>
      </div>
    </div>
  );
};
CandidateSummary.propTypes = {
  classes: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CandidateSummary);
