import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const CandidateCards = (props) => {
  var newKey = [];
  //var newValues = [];
  props.facets.status &&
    props.facets.status.map((item) => {
      var newke = Object.keys(item);
      var newValue = Object.values(item);
      var newFinal = newke.toString();
      var newValueFinal = newValue.toString();
      newKey.push({ status: newFinal, count: newValueFinal });
    });

  return (
    <div className={`candidate__cards-wrapper ${props.className}`}>
      {newKey && newKey.length > 0 ? (
        <h5 className="mb-3">Candidate Status Count</h5>
      ) : (
        ""
      )}
      <Row>
        {newKey &&
          newKey.map((item) => (
            <Col sm={2}>
              <div className="status-card__info-candidate">
                <h4 className="card__Count">{item.count}</h4>
                <span className="card__title">{item.status}</span>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};
export default CandidateCards;
