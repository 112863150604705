import React from "react";
import "./allusers.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../../actions/index";

const CorporateUserCard = (props) => {
  return (
    <div className="admin__user-card cr-pointer" onClick={props.UrlGo}>
      <div className="user__card-header">
        <div className="profile__image">
          <img
            src="https://i.ibb.co/RYcNVTK/Group-1171.png"
            alt="profile-image"
            className="img-fluid"
          />
        </div>
        <div className="header__details">
          {props.Title || props.Industry ? (
            <>
              <h3 className="title">{props.Title}</h3>
              <span className="company__type">{props.Industry}</span>
            </>
          ) : (
            ""
          )}
          {props.Status ? (
            <small className="company__status">
              Status - <mark className={props.Status}>{props.Status}</mark>
            </small>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="user__card-content">
        {props.Ceo ? (
          <span className="text-row">
            <strong>Contact Person</strong>
            <span>{props.Ceo}</span>
          </span>
        ) : (
          ""
        )}
        {props.Email ? (
          <span className="text-row">
            <strong>Email</strong>
            <span>{props.Email}</span>
          </span>
        ) : (
          ""
        )}
        {props.Contact ? (
          <span className="text-row">
            <strong>Contact No.</strong>
            <span>+91 {props.Contact}</span>
          </span>
        ) : (
          ""
        )}
        <span className="text-row">
          <strong>Contract Date</strong>
          <span>25 July, 2022</span>
        </span>
        {props.gstin ? (
          <span className="text-row">
            <strong>GSTIN</strong>
            <span>{props.gstin}</span>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CorporateUserCard);
