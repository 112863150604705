import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../../actions/index";
import { showSnackBar } from "../../actions/index";
const NotificationSetting = (props) => {
  const [notification, setNotification] = useState({
    inApp: false,
    email: false,
  });
  const [accountInfo, setAccountInfo] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(true);
  useEffect(() => {
    loadSettingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userType = localStorage.getItem("ut");
  const loadSettingData = () => {
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");
    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    if (userType === "1100" || userType === "1110") {
      axios
        .get(dEndPoints.getSetting(corporateId, userId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setAccountInfo(data.data);
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      axios
        .get(dEndPoints.getUserSetting(vendorCorporateId, userId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setAccountInfo(data.data);
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    var cNotif = { ...notification };
    if (accountInfo && accountInfo.notify) {
      cNotif.inApp = accountInfo.notify ? accountInfo.notify.inApp : false;
      setNotification(cNotif);
    }
    if (accountInfo && accountInfo.notify) {
      cNotif.email = accountInfo.notify ? accountInfo.notify.email : false;
      setNotification(cNotif);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  const handleNotification = (e) => {
    var notif = { ...notification };
    notif[e.target.id] = e.target.checked;
    setNotification(notif);
  };
  const updateNotification = (e) => {
    e.preventDefault();
    const vendorCorporateId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    const userId = localStorage.getItem("i");

    props.showLoader({ show: true });
    if (userType === "1100" || userType === "1110") {
      axios
        .patch(
          dEndPoints.updateNotificationSetting(corporateId, userId),
          notification
        )
        .then((res) => {
          let data = res.data;
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
            });
          }
          //props.loadUser();
          props.showLoader({ show: false });
        })
        .catch((error) => {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    } else {
      axios
        .patch(
          dEndPoints.updateVendorNotificationSetting(vendorCorporateId, userId),
          notification
        )
        .then((res) => {
          let data = res.data;
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
            });
          }

          //props.loadUser();
          props.showLoader({ show: false });
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <GoBackButton />
          <div className="notification__setting-page text-center">
            <div className="tq__page-header justify-content-center mb-3">
              <h2 className="page-header">Notification Setting Page</h2>
            </div>
            {/* <h1>Notification Setting Page</h1> */}
            <Form className="tq-form pt-4">
              <Row className="mb-4">
                <Form.Group className="my-4 document__details">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="email"
                      checked={notification.email === true}
                      value={notification.email}
                      onClick={handleNotification}
                    />
                    <label className="form-check-label" for="hsc">
                      Email
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inApp"
                      checked={notification.inApp === true}
                      value={notification.inApp}
                      onClick={handleNotification}
                    />
                    <label className="form-check-label" for="ssc">
                      inApp
                    </label>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={updateNotification}
                  >
                    Update
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(NotificationSetting);
