import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Alert from "react-bootstrap/Alert";
import endPoints, { dEndPoints } from "../../config/end-point";
import axios from "axios";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";

// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { useDropzone } from "react-dropzone";

const ExtraMultiple = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const [resumeContent, setResumeContent] = useState(false);
  const ref = useRef();
  // const [candId, setCanId] = useState("");
  const [canInfo, setCanInfo] = useState({
    name: "",
    email: "",
    contact: "",
    college: "",
    city: "",
    cDesig: "",
    expr: "",
    ctc: "",
    notes: "",
    ectc: "",
    cName: "",
    skills: [],
    qual: "",
    nPeriod: null,
    lastworkday: null,
  });

  useEffect(() => {
    props.loadCandidates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInputChange = (e) => {
    const info = { ...canInfo };
    info[e.target.id] = e.target.value;
    setCanInfo(info);
  };

  const submitHandler = (e) => {
    e.preventDefault(); //prevent the form from submitting
    let formData = new FormData();

    if (selectedFiles.length === 0 || !selectedFiles) {
      props.showSnackBar({
        message: "Please Select Files",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      extractResume(selectedFiles[i]);
      formData.append("fileData", selectedFiles[i]);
      formData.append("size", selectedFiles[i].size);
      formData.append("fileType", selectedFiles[i].type);
    }
  };

  const reset = () => {
    ref.current.value = "";
  };
  // /////extraction Resume Data  //////////
  const extractResume = (file) => {
    setResumeContent(true);

    var form = new FormData();
    form.append("file[]", file);
    if (canInfo.notes === "" || !canInfo.notes) {
      props.showSnackBar({
        message: "Please Enter Notes",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    axios
      .post(
        endPoints.multipleResumeExtract + "?" + "notes=" + canInfo.notes,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      )
      .then((res) => {
        //setResumeContent(false);
        props.showSnackBar({
          message: "Resume uploaded successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        var data = res.data;
        reset();
        setSelectedFiles("");
        props.loadCandidates();
        setProgress("");
        setCanInfo({ notes: "" });
      })
      .catch((error) => {
        console.log("error file", error);
        setResumeContent(false);
        var reader = new FileReader();
        reader.onload = function (e) {};
        reader.readAsArrayBuffer(file);
      });
  };
  // extract Resume Text
  // const extractTextFromResume = (text) => {
  //   const vendorId = localStorage.getItem("i");
  //   const data = {
  //     content: text,
  //   };
  //   axios
  //     .post(dEndPoints.predictionResume(vendorId), data)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // };
  return (
    <Form
      action={endPoints.corporateUploadResume}
      method="post"
      encType="multipart/form-data"
      onSubmit={submitHandler}
      className="resume__upload h-100 w-100"
    >
      <Form.Group className="tq-form multipl__upload">
        <Form.Control
          type="file"
          className="custom-file-input p-0"
          id="fileInput"
          label="Select a File"
          name="file"
          ref={ref}
          multiple
          onChange={(e) => setSelectedFiles(e.target.files)}
        />
      </Form.Group>
      <Form.Group className="tq-form text-left">
        <Form.Label>
          Notes<small className="required__form">*</small>
        </Form.Label>
        <Form.Control
          id="notes"
          type="text"
          className="custom-file-label"
          onChange={handleInputChange}
          value={canInfo.notes}
          placeholder="Notes"
        />
      </Form.Group>
      <Form.Group className="mt-3 upload__instruction">
        <Button variant="info" type="submit">
          Upload Multiple Resumes
        </Button>{" "}
        <p>Select multiple files to upload.</p>
      </Form.Group>
      {error && <Alert variant="danger">{error}</Alert>}
      {!error && progress && (
        <ProgressBar now={progress} label={`${progress}%`} />
      )}
    </Form>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(ExtraMultiple);
