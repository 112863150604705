import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import PropTypes from "prop-types";
import endPoints, { dEndPoints } from "../../../config/end-point";
import skillSet from "../../../utils/skills";
import CreatableSelect from "react-select/creatable";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../../actions/index";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import loginPageStyle from "../../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
// import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
//import { useParams } from "react-router";

let FileViewer = null;
if (typeof window !== "undefined") {
  FileViewer = require("react-file-viewer");
}

if (typeof window !== "undefined") {
  require("react-draft-wysiwyg/dist/react-draft-wysiwyg.css");
}

let Editor, htmlToDraft;
if (typeof window !== "undefined") {
  htmlToDraft = require("html-to-draftjs").default;
}
if (typeof window !== "undefined") {
  Editor = require("react-draft-wysiwyg").Editor;
}
const RequirementDetails = (props) => {
  // let { jobId } = useParams();
  const { classes } = props;
  const [newField, setNewField] = useState([]);
  const [extraField, setExtraFields] = useState(props.extraField);
  const [skills, setSkills] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [coOwnerList, setCoOwnerList] = useState([]);
  const [finalUsers, setFinalUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState(null);
  //const [isLoading, setIsLoading] = useState(true);
  const [qualLookup, SetqualLookup] = useState([]);
  const [jdType, setjdType] = useState(null);
  const [jdFileType, setjdFileType] = useState("");
  const [jobDes, setJobDesc] = useState("");
  const path = props.job.jdFile ? props.job.jdFile.fname : "";
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    setExtraFields(props.extraField);
  }, [props.extraField]);
  // const blockStyleFn = (block) => {
  //   let alignment = "left";
  //   block.findStyleRanges((e) => {
  //     if (e.hasStyle("center")) {
  //       alignment = "center";
  //     }
  //     if (e.hasStyle("right")) {
  //       alignment = "right";
  //     }
  //   });
  //   return `editor-alignment-${alignment}`;
  // };
  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case "left":
        return "align-left";
      case "center":
        return "align-center";
      case "right":
        return "align-right";
      default:
        return null;
    }
  };
  useEffect(() => {
    loadDatabaseDetails();
    loadQualOptions();
    if (userType === "1110") {
      loadCorporate();
    }

    getCoOwnersData();

    if (props.job.jdFile) {
      downloadJdFile(true);
    }

    //uploadJdFileAfterExtract(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, props.job.jdFile]);

  var editorStyle = {
    border: "1px solid #e0e0e0",
    padding: "0px",
    borderRadius: "2px",
    maxHeight: "200px",
    width: "100%",
    minHeight: "100px",
  };

  const loadQualOptions = () => {
    axios.get(endPoints.getQualificationLookup + "?qual=").then((res) => {
      const data = res.data;
      SetqualLookup(data);
    });
  };
  const QualificationLookup = qualLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const handleChangeQuallookUp = (value) => {
    var info = { ...props.job };
    info.qual = value ? value.map((el) => el.value) : "";
    props.setJob(info);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const userType = localStorage.getItem("ut");
  const handleCandidateInfo = (e) => {
    const cjob = { ...props.job };
    var cInfo = cjob.cInfo || {};

    cInfo[e.target.name] = e.target.checked;
    cjob.cInfo = cInfo;
    props.setJob(cjob);
  };
  const loadCorporate = () => {
    // var pageNum = 1;
    var sort_createdon = -1;
    props.showLoader({ show: true });
    axios
      .get(endPoints.getCompanies, {
        params: {
          //pageNumber: pageNum,
          cOn: sort_createdon,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;
        setCompanyList(data.data);
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };
  var newJobDesc = [];
  if (!props.job.jdFile) {
    var htmlString = props.job.jobDesc;
    if (htmlString) {
      var plainString = htmlString.replace(/<[^>]+>/g, "");
    }
    newJobDesc.push(plainString);
  }
  const alignJd = () => {
    //   var cjob = { ...props.job };
    var data = {
      jobDesc: newJobDesc,
    };

    props.showLoader({ show: true });
    axios
      .post(endPoints.alignJd, data)
      .then((res) => {
        var data = res.data;

        setJobDesc(data);
        props.showLoader({ show: false });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  if (jobDes) {
    var cjob = { ...props.job };
    props.job.jobDesc = jobDes;
  }

  const loadCompanyCoOwners = () => {
    var cjob = { ...props.job };

    props.showLoader({ show: true });
    axios
      .get(endPoints.companyCoOwners, {
        params: {
          cName: cjob.cName,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        setCoOwnerList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };
  var cjob = { ...props.job };

  var prevCount = usePrevious(cjob.cName);

  if (cjob.cName !== "") {
    if (loading && userType === "1110") {
      loadCompanyCoOwners();
    }
  }
  var isLoading = true;
  if (prevCount !== cjob.cName) {
    if (isLoading && userType === "1110") {
      loadCompanyCoOwners();
    }
  }
  const loadDatabaseDetails = () => {
    const selectInput = {
      metaData: [
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "LOCATION",
        },

        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "SKILL",
        },
      ],
    };
    props.showLoader({ show: true });
    axios({
      url: endPoints.lookup,
      data: selectInput,
      method: "POST",
    }).then((res) => {
      props.showLoader({ show: false });
      const data = res.data;
      if (data && data.code === 100000) {
        const skill = data && data.data && data.data.SKILL;

        setSkills(skill);
        const location = data && data.data && data.data.LOCATION;

        setcityList(location);
      }
    });
  };

  const skill = skills.map((item) => {
    return { label: item.name, value: item.name };
  });

  const skillss = skillSet.map((item) => {
    return { label: item.name, value: item.name };
  });

  var pror = ["label", "value"];

  var skillNew = skill
    .filter(function (o1) {
      // filter out (!) items in result2
      return skillss.some(function (o2) {
        return o1.label === o2.label; // assumes unique id
      });
    })
    .map(function (o) {
      // use reduce to make objects with only the required properties
      // and map to apply this to the filtered array as a whole
      return pror.reduce(function (newo, value) {
        newo[value] = o[value];
        return newo;
      }, {});
    });

  const cityNew = cityList.map((item) => {
    return { label: item.name, value: item.name };
  });

  //console.log("props", props);
  //**** Select Co-Owners *****/

  //console.log("props.jobId", props.job._id);
  const getCoOwnersData = () => {
    // const userType = localStorage.getItem("ut");

    const corporateId = localStorage.getItem("ci");

    props.showLoader({ show: true });
    axios
      .get(dEndPoints.coOwner(corporateId))

      .then((res) => {
        props.showLoader({ show: false });
        const data = res.data;

        if (data && data.code === 100000) {
          setFinalUsers(data.data.coOwners);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };

  const downloadJdFile = (preview = false) => {
    const path =
      props.job && props.job.jdFile.fname ? props.job.jdFile.fname : "";
    const resumeComp = path.split("/");

    const fName = resumeComp[resumeComp.length - 1];
    props.showLoader({ show: true });
    axios
      .get(endPoints.uploadFile, {
        params: {
          fileType: "Jd",
          fileUrl: path,
        },
        responseType: "blob",
      })
      .then((res) => {
        props.showLoader({ show: false });
        var blob = new Blob([res.data], { type: res.headers["content-type"] });
        const href = window.URL.createObjectURL(blob);

        if (preview) {
          setFileUrl(href);
        } else {
          var link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fName);
          link.click();
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error in download file", error);
      });
  };

  const handleChangePrimarySkill = (value) => {
    var cjob = { ...props.job };

    cjob.pSkills = value ? value.map((el) => el.value) : [];
    props.setJob(cjob);
  };
  const handleChangeSecSkill = (value) => {
    var cjob = { ...props.job };
    cjob.sSkills = value ? value.map((el) => el.value) : [];
    props.setJob(cjob);
  };
  const handleChangeLocation = (value) => {
    var cjob = { ...props.job };
    cjob.city = value ? value.map((el) => el.value) : "";
    props.setJob(cjob);
  };
  // var newValue = [];

  var fName = props.job.jdFile ? props.job.jdFile.fname : "";
  var ext = fName ? fName.split(".") : "";
  var type = ext[ext.length - 1];

  function handleInputChange(event) {
    var cjob = { ...props.job };
    if (event.target.name === "jobType") {
      if (event.target.value === "Walkin") {
        cjob.walkin = true;
      } else {
        cjob.walkin = false;
      }
      cjob[event.target.name] = event.target.value;
    } else if (event.target.id === "reqId" || event.target.id === "desig") {
      var cleanText = event.target.value.replace(/<\/?[^>]+(>|$)/g, "");
      cleanText = cleanText.replace(/\r?\n|\r|\n|\t/g, " ");
      cjob[event.target.id] = cleanText;
    } else if (event.target.name === "city") {
      if (event.target.value.length <= 3) {
        cjob[event.target.name] = event.target.value;
      } else {
        cjob[event.target.name] = cjob.city;
      }
    } else if (event.target.name === "cName") {
      cjob[event.target.name] = event.target.value;
    } else if (
      event.target.name === "closedComment" ||
      event.target.name === "extraDetails"
    ) {
      cjob[event.target.name] = event.target.value;
    } else if (
      event.target.name === "payment" ||
      event.target.name === "validFor"
    ) {
      cjob[event.target.name] = Number(event.target.value);
    } else if (
      event.target.id === "expTo" ||
      event.target.id === "expFrom" ||
      event.target.id === "rate" ||
      event.target.id === "vRate" ||
      event.target.id === "minBudget" ||
      event.target.id === "maxBudget" ||
      event.target.id === "totpos" ||
      //event.target.id === "replacement" ||
      event.target.id === "nPeriod"
    ) {
      cjob[event.target.id] = Number(event.target.value);
    } else if (event.target.name === "coOwners") {
      cjob[event.target.name] = event.target.value;
      // cjob.coOwners = event.target.value;
      props.setJob(cjob);
    } else if (event.target.id === "name") {
      var conPer = cjob.conPer ? cjob.conPer : {};
      conPer[event.target.id] = event.target.value;
      cjob.conPer = conPer;
    } else if (event.target.id === "phNum") {
      var conPer = cjob.conPer ? cjob.conPer : {};
      conPer[event.target.id] = Number(event.target.value);
      cjob.conPer = conPer;
    } else if (event.target.name === "talenqPayRoll") {
      cjob[event.target.name] = event.target.value;
    } else if (event.target.name === "is_talenq_closed") {
      var closeJob = cjob && cjob.feedback ? cjob.feedback : {};
      closeJob[event.target.name] = event.target.value === "true";
      cjob.feedback = closeJob;
    } else {
      cjob[event.target.id] = event.target.value;
    }
    props.setJob({ ...props.job, ...cjob });
  }

  const addAddress = (e) => {
    const newFields = [...newField];
    newFields.push("");
    setNewField(newFields);
  };

  const handleNewField = (e, i) => {
    const dupAddress = [...newField];
    dupAddress[i] = e.target.value;
    setNewField(dupAddress);
  };
  const removeFields = (index) => {
    let data = [...newField];
    data.splice(index, 1);
    setNewField(data);
  };
  const removeAddedFields = (index) => {
    let data = [...extraField];
    data.splice(index, 1);
    setExtraFields(data);
  };

  const output = !newField.includes("")
    ? newField.map((name, i, value) => ({
        name,
        i: i + 1,
        value: "",
      }))
    : [];

  props.job.extraFields = output.concat(extraField);
  const handleChangeNewField = (e, i) => {
    const { value, name } = e.target;

    const newState = [...extraField];
    newState[i] = {
      ...newState[i],
      [name]: value,
    };

    setExtraFields(newState);
  };
  return (
    <div className="requirement__details tq-form">
      <div className="mb-5 mt-5">
        <h2 className="pageHeadings">Requirement Details </h2>
      </div>
      <Form noValidate validated={props.validated}>
        <Row className="mb-3 text-left">
          <Form.Group as={Col}>
            <Form.Label>
              Requirement Id <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="reqId"
              type="text"
              value={props.job.reqId}
              onChange={handleInputChange}
              required="required"
              placeholder="Requirement Id"
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.reqId ? props.formerrors.reqIdError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>
              Number Of Position
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="totpos"
              type="number"
              value={props.job.totpos}
              onChange={handleInputChange}
              required="required"
              placeholder="0"
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.totpos ? props.formerrors.totposError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>
              Designation
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="desig"
              type="text"
              value={props.job.desig}
              required="required"
              onChange={handleInputChange}
              placeholder="eg. Software Developer"
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.desig || props.job.desig === " "
                ? props.formerrors.desigError
                : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>
              Qualification
              <small className="required__form">*</small>
            </Form.Label>
            <CreatableSelect
              inputId="qual"
              TextFieldProps={{
                label: "qual",
                InputLabelProps: {
                  htmlFor: "qual",
                  shrink: true,
                },
              }}
              options={QualificationLookup}
              value={
                props.job.qual
                  ? props.job.qual.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeQuallookUp}
              isMulti
              required
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.qual || props.job.qual.length === 0
                ? props.formerrors.qualError
                : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mt-4 heading__Jd-uploaded">
            <Form.Label>
              Job Description
              <small className="required__form">*</small>
            </Form.Label>
            {props.job.jdFile && type.toLowerCase() === "docx" ? (
              <>
                <Button
                  onClick={() => downloadJdFile()}
                  title="download"
                  variant="success rounded-0 float__right "
                >
                  <i className="bx bx-file"></i> &nbsp; Download JD
                </Button>

                <div
                  style={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                    marginTop: "1rem",
                  }}
                >
                  <FileViewer
                    key={fileUrl}
                    fileType={type.toLowerCase()}
                    filePath={fileUrl}
                  />
                </div>
                <Form.Label>Additional details of JD</Form.Label>
                <Form.Control
                  name="extraDetails"
                  label=" Additional details of JD"
                  multiline
                  rowsMax={4}
                  value={props.job.extraDetails}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </>
            ) : props.job.jdFile && type.toLowerCase() === "pdf" ? (
              <>
                <Button
                  onClick={() => downloadJdFile()}
                  title="download"
                  variant="success rounded-0 float__right"
                >
                  <i className="bx bx-file"></i>&nbsp; Download JD
                </Button>
                <div>
                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </div>
                  <div className="buttonc">
                    <Button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className="Pre"
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <div>
                  <Document
                    file={fileUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      width={900}
                      height={100}
                      style={{ border: "2px" }}
                    />
                  </Document>
                </div>
                <Form.Label>Additional details of JD</Form.Label>
                <Form.Control
                  name="extraDetails"
                  label=" Additional details of JD"
                  multiline
                  rowsMax={4}
                  value={props.job.extraDetails}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </>
            ) : props.job.jdFile && jdFileType.toLowerCase() === "docx" ? (
              <>
                <Button
                  onClick={() => downloadJdFile()}
                  title="download"
                  variant="success float__right rounded-0"
                >
                  <i className="bx bx-file"></i>&nbsp; Download JD
                </Button>
                <div
                  style={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                    marginTop: "1rem",
                  }}
                >
                  <FileViewer
                    key={jdType}
                    fileType={jdFileType.toLowerCase()}
                    filePath={jdType}
                  />
                </div>
                <Form.Label>Additional details of JD</Form.Label>
                <Form.Control
                  name="extraDetails"
                  label=" Additional details of JD"
                  multiline
                  rowsMax={4}
                  value={props.job.extraDetails}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </>
            ) : props.job.jdFile && jdFileType.toLowerCase() === "pdf" ? (
              <div className="mb-2">
                <Button
                  onClick={() => downloadJdFile()}
                  title="download"
                  variant="success rounded-0 float__right"
                >
                  <i className="bx bx-file"></i>&nbsp; Download JD
                </Button>
                <div>
                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </div>
                  <div className="buttonc">
                    <Button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className="Pre"
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <Document file={jdType} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageNumber}
                    width={900}
                    height={100}
                    style={{ border: "2px" }}
                  />
                </Document>

                <div style={{ marginTop: "20px" }}>
                  <Form.Label>Additional details of JD</Form.Label>
                  <Form.Control
                    name="extraDetails"
                    label=" Additional details of JD"
                    multiline
                    rowsMax={4}
                    value={props.job.extraDetails}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </div>
              </div>
            ) : props.job.jdFile && jdFileType.toLowerCase() === "doc" ? (
              <div
                style={{
                  height: "28rem",
                  overflow: "hidden",
                  marginTop: "100px",
                  textAlign: "center",
                }}
              >
                .doc file format is not supported
              </div>
            ) : (
              <>
                <Editor
                  style={{ margin: "18px 0" }}
                  editorState={props.editorState}
                  blockStyleFn={getBlockStyle}
                  wrapperClassName="rich-editor demo-wrapper"
                  editorClassName="demo-editor mt-3 p-2 jdTextField"
                  onEditorStateChange={props.onEditorStateChange}
                  placeholder="Job Description*"
                  editorStyle={editorStyle}
                  toolbarClassName="toolbar"
                  handlePastedText={props.handlePastedText}
                />
                {props.job.jobDesc ? (
                  <Button onClick={alignJd}>Align Job Description</Button>
                ) : (
                  ""
                )}
              </>
            )}
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.jobDesc && !props.job.jdFile
                ? props.formerrors.jobDescError
                : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mt-4 customize__text-field">
            <Form.Label>
              Primary Skills
              <small className="required__form">*</small>
            </Form.Label>
            <CreatableSelect
              inputId="primary-skill"
              TextFieldProps={{
                label: "Primary Skills*",
                InputLabelProps: {
                  htmlFor: "primary-skill",
                  shrink: true,
                },
              }}
              options={skillNew}
              value={
                props.job && props.job.pSkills
                  ? props.job.pSkills.map((ele) => ({ label: ele, value: ele }))
                  : []
              }
              onChange={handleChangePrimarySkill}
              isMulti
              required
              isOpen={true}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.pSkills ||
              (props.job.pSkills && props.job.pSkills.length === 0)
                ? props.formerrors.primarySkillError
                : []}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mt-4 customize__text-field">
            <Form.Label>
              Secondary Skills
              <small className="required__form">*</small>
            </Form.Label>
            <CreatableSelect
              inputId="secondary-skill"
              TextFieldProps={{
                label: "secondary Skills*",
                InputLabelProps: {
                  htmlFor: "secondary-skill",
                  shrink: true,
                },
              }}
              options={skillNew}
              value={
                props.job && props.job.sSkills
                  ? props.job.sSkills.map((ele) => ({ label: ele, value: ele }))
                  : []
              }
              onChange={handleChangeSecSkill}
              isMulti
              required
              isOpen={true}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.sSkills ||
              (props.job.sSkills && props.job.sSkills.length === 0)
                ? props.formerrors.secondaryError
                : []}
            </p>
          </Form.Group>
        </Row>
        <Row className="mt-3 customize__text-field text-left">
          <Form.Group as={Col}>
            <Form.Label>
              Location (select multiple locations)
              <small className="required__form">*</small>
            </Form.Label>
            <CreatableSelect
              inputId="city"
              TextFieldProps={{
                label: "City*",
                InputLabelProps: {
                  htmlFor: "city",
                  shrink: true,
                },
              }}
              options={cityNew}
              value={
                props.job && props.job.city
                  ? props.job.city.map((ele) => ({ label: ele, value: ele }))
                  : []
              }
              onChange={handleChangeLocation}
              isMulti
              required
              isOpen={true}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.city ||
              (props.job.city && props.job.city.length === 0)
                ? props.formerrors.cityError
                : []}
            </p>
          </Form.Group>

          {userType === "1110" ? (
            <Form.Group
              as={Col}
              // xs={6}
              className="subHeadingsforPostRequirements postRequirementSeprator"
            >
              {" "}
              <Form.Label className="mb-2">
                {" "}
                Company Name
                <small className="required__form">*</small>
              </Form.Label>
              <FormControl fullWidth variant="outlined">
                <Select
                  className="form-control__mui px-3"
                  value={props.job.cName ? props.job.cName : ""}
                  onChange={handleInputChange}
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper },
                  }}
                  classes={{ select: classes.select }}
                  inputProps={{
                    name: "cName",
                    id: "select-multiple",
                  }}
                >
                  {companyList.map((data, i) => (
                    <MenuItem
                      key={i}
                      value={data.companyName}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      className="px-3"
                    >
                      {data.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {!props.job.cName ? props.formerrors.companyError : ""}
              </p>
            </Form.Group>
          ) : (
            <></>
          )}
        </Row>
        <Row className="mt-3 customize__text-field text-left">
          <Form.Group
            as={Col}
            xs={12}
            // className="mt-4"
          >
            <Form.Label>
              Requirement Owner (select multiple)
              <small className="required__form">*</small>
            </Form.Label>
            {userType === "1110" ? (
              <FormControl
                fullWidth
                variant="outlined"
                className="mui__form-select"
              >
                <Select
                  multiple
                  name="coOwners"
                  className="form-control__mui"
                  value={
                    props.job && props.job.coOwners ? props.job.coOwners : []
                  }
                  onChange={handleInputChange}
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper },
                  }}
                >
                  {coOwnerList.map((user, i) => (
                    <MenuItem
                      key={i}
                      value={user._id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                    >
                      {user.userName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                className="mui__form-select"
              >
                <Select
                  multiple
                  className="form-control__mui"
                  name="coOwners"
                  value={
                    props.job && props.job.coOwners ? props.job.coOwners : []
                  }
                  onChange={handleInputChange}
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper },
                  }}
                >
                  {finalUsers.map((user, i) => (
                    <MenuItem
                      key={i}
                      value={user._id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                    >
                      {user.userName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.coOwners ||
              (props.job.coOwners && props.job.coOwners.length === 0)
                ? props.formerrors.coOwnersError
                : []}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-4">
            <Form.Label>
              Min Exp.<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="expFrom"
              value={props.job.expFrom}
              type="number"
              placeholder="Exp. Counts in No of Years Eg: '1'"
              required="required"
              onChange={handleInputChange}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.expFrom ? props.formerrors.expFromError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-4">
            <Form.Label>
              Max Exp.
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="expTo"
              value={props.job.expTo}
              type="number"
              placeholder="Exp. Counts in No of Years Eg: '4'"
              required="required"
              onChange={handleInputChange}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.expTo ? props.formerrors.expToError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-4">
            <Form.Label>
              Notice Period
              <small className="required__form">*</small>
            </Form.Label>
            <select
              id="nPeriod"
              className="form-select"
              aria-label="noticePriod"
              multiple={false}
              required="required"
              value={props.job && props.job.nPeriod}
              onChange={handleInputChange}
            >
              <option selected> Select Notice Period</option>
              <option value={7}>1 Week</option>
              <option value={14}>2 Weeks</option>
              <option value={21}>3 Weeks</option>
              <option value={30}>30 Days</option>
              <option value={45}>45 Days</option>
              <option value={60}>60 Days</option>
              <option value={90}>90 Days</option>
            </select>
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.nPeriod ? props.formerrors.noticePeriodError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-4">
            <Form.Label>
              Min Budget
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="minBudget"
              value={props.job.minBudget}
              type="number"
              placeholder="5.8 for salary 5,80,000"
              required="required"
              onChange={handleInputChange}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.minBudget ? props.formerrors.minBudgetError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-4">
            <Form.Label>
              Max Budget
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="maxBudget"
              value={props.job.maxBudget}
              type="number"
              placeholder="6.8 for salary 6,80,000"
              required="required"
              onChange={handleInputChange}
            />
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.maxBudget ? props.formerrors.MaxBudgetError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-4">
            <Form.Label>Valid For</Form.Label>
            {props.status === "New" ? (
              <select
                name="validFor"
                className="form-select"
                aria-label="validFor"
                multiple={false}
                value={props.job && props.job.validFor}
                onChange={handleInputChange}
              >
                <option selected> Select Valid For</option>
                <option value={12}> 1 Year</option>
                <option value={6}>6 Months</option>
                <option value={3}> 3 Months</option>
                <option value={1}> 1 Month</option>
              </select>
            ) : (
              ""
            )}
            {props.status === "Open" ||
            props.status === "Draft" ||
            props.status === "Awaiting Approval" ? (
              <Form.Control
                name="validFor"
                value={
                  props.job && props.job.validFor
                    ? new Date(props.job.validFor).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    : ""
                }
                placeholder="Months"
              />
            ) : (
              ""
            )}
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.validFor ? props.formerrors.validForError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={12} className="my-4 ">
            <Form.Label>Documents Required</Form.Label>
            <div className="document__details">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenPercent"
                  name="tenPercent"
                  checked={
                    props.job &&
                    props.job.cInfo &&
                    props.job.cInfo["tenPercent"]
                  }
                  onClick={handleCandidateInfo}
                />
                <label className="form-check-label" for="ssc">
                  10th
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="twelvePercent"
                  name="twelvePercent"
                  checked={
                    props.job &&
                    props.job.cInfo &&
                    props.job.cInfo["twelvePercent"]
                  }
                  onClick={handleCandidateInfo}
                />
                <label className="form-check-label" for="hsc">
                  12th
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="ugPercent"
                  name="ugPercent"
                  checked={
                    props.job && props.job.cInfo && props.job.cInfo["ugPercent"]
                  }
                  onClick={handleCandidateInfo}
                />
                <label className="form-check-label" for="ug">
                  UG
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="pgPercent"
                  name="pgPercent"
                  checked={
                    props.job && props.job.cInfo && props.job.cInfo["pgPercent"]
                  }
                  onClick={handleCandidateInfo}
                />
                <label className="form-check-label" for="pg">
                  PG
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="pan"
                  name="pan"
                  checked={
                    props.job && props.job.cInfo && props.job.cInfo["pan"]
                  }
                  onClick={handleCandidateInfo}
                />
                <label className="form-check-label" for="pan">
                  PAN
                </label>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Button
              type="button"
              variant="secondary"
              onClick={(e) => addAddress(e)}
            >
              Add New field
            </Button>

            {newField &&
              newField.map((ele, i) => (
                <Form.Group as={Col}>
                  <Form.Label>New Field</Form.Label>
                  <Form.Control
                    type="text"
                    id="extraFields"
                    onChange={(e) => handleNewField(e, i)}
                    value={ele}
                    placeholder="New Field"
                  />
                  <Button onClick={() => removeFields(i)}>Remove</Button>
                </Form.Group>
              ))}
            {extraField && extraField.length > 0
              ? extraField &&
                extraField.map(({ name, value }, index) => (
                  <Form.Group as={Col} key={index}>
                    <Form.Label>New Field</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={(e) => handleChangeNewField(e, index)}
                      value={name}
                    />
                    <Button onClick={() => removeAddedFields(index)}>
                      Remove
                    </Button>
                  </Form.Group>
                ))
              : ""}
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
};
RequirementDetails.propTypes = {
  classes: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    xyz: state.main,
    pqr: state.maintab,
  };
}
//export default RequirementDetails;
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

//export default connect(null, mapDispatchToProps)(RequirementDetails);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(RequirementDetails));

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
