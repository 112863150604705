import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import endPoints, { dEndPoints } from "../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";
import UserCardAdmin from "./allusers/UserCardAdmin";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
const ActivateUser = (props) => {
  const [info, setInfo] = useState([]);
  // const [sort_createdon, setsort_createdon] = useState(-1);
  const [searchInputAll, setSearchInputAll] = useState("");
  // const [pageNum, setPageNum] = useState(1);
  const [header, setHeader] = useState([
    {
      headerName: "User ID",
      field: "UserName",
      filter: true,
      sortable: true,
      resizable: true,
      width: 280,
    },
    { headerName: "User Type", field: "UserType", width: 140, filter: true },
    {
      headerName: "User Access",
      field: "UserAccess",
      width: 180,
      filter: true,
    },
    { headerName: "First Name", field: "firstName", width: 180, filter: true },
    { headerName: "Last Name", field: "lastName", width: 180, filter: true },
    { headerName: "Contact", field: "Contact", width: 200 },
    { headerName: "Created On", field: "createOn", width: 180, filter: true },
    {
      headerName: "Delete User",
      field: "Deleteuser",
      width: 140,

      cellRendererFramework: function (params) {
        return (
          <Button
            variant="danger table-btn"
            onClick={() => handleClickDelete(params)}
          >
            {" "}
            Delete{" "}
          </Button>
        );
      },
    },
  ]);

  const handleClickDelete = (deletedata) => {
    console.log("deletedata", deletedata);
    let corporateId = deletedata.corporateId;
    let vendorCorporateId = deletedata.vendorCorporateId;
    let userID = deletedata._id;

    if (deletedata.userAccess !== "Admin") {
      props.showSnackBar({
        message: "Only Admin users can be deleted",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
    } else if (deletedata.status === "Deleted") {
      props.showSnackBar({
        message: "The user is already deleted",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
    }
    // else if to delete the corporate admin
    else if ((deletedata.userAccess === "Admin") & (corporateId != null)) {
      axios
        .post(dEndPoints.deleteCorporateAdminUser(corporateId), null, {
          params: {
            userId: userID,
          },
        })
        .then((res) => {
          var data = res.data;

          if (data && data.data.code === 100152) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            loadCorporate();
            return;
          }
        })
        .catch((error) => {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          props.showLoader({ show: false });
          return;
        });
    }
    // else if to delete the vendor admin
    else if (
      (deletedata.userAccess === "Admin") &
      (vendorCorporateId != null)
    ) {
      axios
        .post(dEndPoints.deleteVendorAdminUser(vendorCorporateId), null, {
          params: {
            userId: userID,
          },
        })
        .then((res) => {
          var data = res.data;

          if (data && data.data.code === 100152) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            loadCorporate();
            return;
          }
        })
        .catch((error) => {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          props.showLoader({ show: false });
          return;
        });
    }
  };

  var users = info.map((user) => {
    var data = {
      UserName: user.userName || "",
      UserType: user.userType || "",
      UserAccess: user.userAccess || "",
      Status: user.status || "",
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      Contact: user.contact || "",
      createOn: user.createdOn || "",
      Updateuser: user._id || "",
      Deleteuser: user,
    };

    return data;
  });

  useEffect(() => {
    loadCorporate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadCorporate = () => {
    var sort_createdon = -1;
    var pageNum = 1;
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllUser, {
        params: {
          pageNumber: pageNum,
          cOn: sort_createdon,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (response.data.code === 100130) {
          const resumes = data.data;
          setInfo(resumes);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };
  return (
    <div style={{ marginTop: "25px" }}>
      <div className="tq__page-header d-block text-center mb-5">
        <h3 className="page-header">
          <span className="text-success">Active</span>&nbsp;
          <span className="text-muted">Users</span>
        </h3>
      </div>
      <Row>
        <Col xs={12}>
          <div className="tq-cards py-4 mb-5">
            <Row className="tq-form justify-content-around">
              <Col xs={12}>
                <Form.Control
                  type="text"
                  placeholder="Search jobs here...."
                  onChange={(e) => {
                    setSearchInputAll(e.target.value);
                  }}
                />
                <small className="mt-2 d-block text-center">
                  Search Jobs by Typing -{" "}
                  <mark className="bg-info">User Name</mark> or{" "}
                  <mark className="bg-info">User Type</mark>{" "}
                </small>
              </Col>
            </Row>
          </div>
        </Col>
        {info &&
          info
            .filter((allUser) => {
              if (searchInputAll === "") {
                return allUser;
              } else if (
                allUser.userName
                  ?.toLowerCase()
                  .includes(searchInputAll?.toLowerCase())
              ) {
                return allUser;
              } else if (
                allUser.userType
                  ?.toLowerCase()
                  .includes(searchInputAll?.toLowerCase())
              ) {
                return allUser;
              }
            })
            .map((item, index) => (
              <Col xs={4} key={index} className="mb-5">
                <UserCardAdmin
                  titleName={item.userName}
                  autor={item.firstName}
                  UserAccess={item.userType}
                  accountStatus={item.status}
                  cOn={moment(new Date(item.createdOn)).fromNow()}
                  contact={item.contact}
                  createdOn={item.createdOn}
                  // deleteUser={() => handleClickDelete(item)}
                />
              </Col>
            ))}
      </Row>
      <div
        className="ag-theme-balham"
        style={{
          height: "550px",
          width: "100%",
          fontSize: "13px",
        }}
      >
        <AgGridReact
          pagination={true}
          columnDefs={header}
          rowData={users}
        ></AgGridReact>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ActivateUser);
