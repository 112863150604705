const collegeSet = [
  {
    _id: "5f96607069fccf0d2f873693",
    name: " ASHOK INSTITUTE OF ENGINEERING AND TECHNOLOGY",
  },
  {
    _id: "5f96607069fccf0d2f872d61",
    name: " BNM INSTITUTE OF TECHNOLOGY",
  },
  {
    _id: "5f96607069fccf0d2f873488",
    name: " BRAHMA VALLEY COLLEGE OF ENGINEERING AND REASERACH INSTITUTE",
  },
  {
    _id: "5f96607069fccf0d2f8730e0",
    name: " CENTRAL INSTITUTE OF PLASTICS ENGINEERING AND TECHNOLOGY",
  },
  {
    _id: "5f96607069fccf0d2f8739e9",
    name: " COLLEGE OF PHARMACY",
  },
  {
    _id: "5f96607069fccf0d2f873cb4",
    name: " DEPARTMENT OF MASTER OF COMPUTER APPLICATIONS INSTITUTE OF ENGINEERING AND TECHNOLOGY - BHADDAL",
  },
  {
    _id: "5f96607069fccf0d2f87357d",
    name: " DEPARTMENT OF TECHNOLOGY, SHIVAJI UNIVERSITY, KOLHAPUR",
  },
  {
    _id: "5f96607069fccf0d2f873898",
    name: " DHARMARAJ SHAIKSHNIK PRATISHTHANS COLLEGE OF PHARMACY",
  },
  {
    _id: "5f96607069fccf0d2f8747f6",
    name: " GOVERNMENT POLYTECHNIC SHAHBAD, RAMPUR",
  },
  {
    _id: "5f96607069fccf0d2f873d28",
    name: " GOVT. MAHILA POLYTECHNIC COLLEGE,BIKANER",
  },
  {
    _id: "5f96607069fccf0d2f8735bb",
    name: " GUNAI SHIKSHAN PRASARAK MANDAL'S RAJIV GANDHI POLYTECHNIC",
  },
  {
    _id: "5f96607069fccf0d2f874aa4",
    name: " I. D. MEMORIAL COLLEGE OF PHARMACY",
  },
  {
    _id: "5f96607069fccf0d2f87324a",
    name: " IES INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
  },
  {
    _id: "5f96607069fccf0d2f87475e",
    name: " KANPUR INSTITUTE OF TECHNICAL EDUCATION",
  },
  {
    _id: "5f96607069fccf0d2f87452f",
    name: " KARUNA P.G.COLLEGE OF BUSINESS MANAGEMENT",
  },
  {
    _id: "5f96607069fccf0d2f873e99",
    name: " KUCHAMAN PHARMACY COLLEGE",
  },
  {
    _id: "5f96607069fccf0d2f874ca1",
    name: " RAMASHRAY SINGH INSTITUTE OF PHARMACY",
  },
  {
    _id: "5f96607069fccf0d2f8735c6",
    name: " SHREE SIDHIVINAYAK POLYTECHNIC",
  },
  {
    _id: "5f96607069fccf0d2f873fb5",
    name: " SONA COLLEGE OF TECHNOLOGY",
    address:
      "SONA NAGAR, THIAGARAJAR POLYTECHNIC COLLEGE ROAD, SURAMANGALAM (PO), SALEM DISTRICT, TAMILNADU",
  },
  {
    _id: "5f96607069fccf0d2f873f0d",
    name: " V.S.B. ENGINEERING COLLEGE",
    address:
      "N.H.67 COVAI ROAD,KARUDAYAMPALAYAM (VILLAGE & POST),ARAVAKURICHI (TK)KARUR - 639 111.TAMILNADU.",
  },
  {
    _id: "5f96607069fccf0d2f873642",
    name: " YASHWANTRAO CHAVAN INSTITUTE OF POLYTECHNIC",
    address: "SURVEY NO. 107, 109 WASANWADI TAL. BEED",
  },
  {
    _id: "5f96607069fccf0d2f8744fb",
    name: "1 EME CENTRE",
    address: "1 EME CENTRE, SECUNDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f8740d9",
    name: "122,GOVERNMENT POLYTECHNIC COLLEGE,DHARMAPURI",
    address:
      "TEMPORARY SITE:COMMUNITY HALL, KERAKODAHALLI VILLAGE,KARIMANGALM POST ,PALACODE TALUK,DHARMAPURI DISTRICT -635111.PERMANENT SITE:SURVEY NO 211/1 BOOMANDHALLI VILLAGE, PALACODE TALUK,DHARMAPURI DISTRICT.",
  },
  {
    _id: "5f96607069fccf0d2f8741aa",
    name: "126-GOVERNMENT POLYTECHNIC COLLEGE",
    address:
      "THENI - KAMBUM MAIN ROAD, SAMUTHAYA KOODAM, KOTTUR-625534, THENI DISTRICT.",
  },
  {
    _id: "5f96607069fccf0d2f872f13",
    name: "168-GOVT POLYTECHNIC HOSADURGA",
    address:
      "GOVT POLYTECHNIC, GANDHI CIRCLE,OLD HOSPITAL BUILDING, HOSADURGA-577527, CHITRADURGA(D)",
  },
  {
    _id: "5f96607069fccf0d2f873271",
    name: "3 EME CENTRE",
    address: "BAIRAGARH, BHOPAL",
  },
  {
    _id: "5f96607069fccf0d2f874150",
    name: "370 PALLAVAN POLYTECHNIC COLLEGE",
    address: "KOLIVAKKAM,IYYENGARKULAM,KANCHIPURAM DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f8741cd",
    name: "816 SHREE VENKATESHWARA HI TECH POLYTECHNIC COLLEGE",
    address:
      "ERODE-GOBI MAIN ROAD,SRIKALAIVANI NAGAR,OTHAKUTHIRAI,K.METTUPALAYAM-PO,GOBI-TK,ERODE-DT",
  },
  {
    _id: "5f96607069fccf0d2f873cb3",
    name: "A & M INSTITUTE OF COMPUTER & TECHNOLOGY",
    address:
      "VILLAGE MAMOON,OPPOSITE - CANADA PALACE, BYE-PASS, PATHANKOT, PUNJAB.",
  },
  {
    _id: "5f96607069fccf0d2f873c8e",
    name: "A & M INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    address:
      "OPPOSITE CANADA PALACE, VILLAGE - MAMOON, TEHSIL - PATHANKOT DISTT.- GURDASPUR PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f872532",
    name: "A AND E COLLEGE OF PHARMACY",
    address: "AT BALUAHI, P.O MOHIUDDINNAGAR,",
  },
  {
    _id: "5f96607069fccf0d2f874bce",
    name: "A B SINGH COLLEGE OF PHARMACY",
    address: "AAGAMPUR, SHAHABAD, HARDOI-241124",
  },
  {
    _id: "5f96607069fccf0d2f872fb7",
    name: "A M C . ENGINEERING COLLEGE",
    address: "18KM, BANNERAGHATTA ROAD, KALKERE, BANGALORE -560083",
  },
  {
    _id: "5f96607069fccf0d2f8740e2",
    name: "A M K TECHNOLOGICAL POLYTECHNIC COLLEGE",
    address: "CHENNAI-BANGALARU HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f874be7",
    name: "A P COLLEGE OF PHARMACY",
    address:
      "PLOT NO 642 VILLAGE UNCHAGAON NEAR PASHU PATH ,OPPOSITE- PUNJAB NATIONAL BANK BULANDSHAHAR",
  },
  {
    _id: "5f96607069fccf0d2f872df1",
    name: "A P S COLLEGE OF ENGINEERING",
    address: "26 KM KANAKAPURA ROAD BANGALORE 82",
  },
  {
    _id: "5f96607069fccf0d2f874b25",
    name: "A P S COLLEGE OF PHARMACY",
    address:
      "PLOT NO 40 VILLAGE JULEDHA SARDHANA BINAULI ROAD NEAR BHOONI CHOWK MEERUT",
  },
  {
    _id: "5f96607069fccf0d2f8748da",
    name: "A R INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address:
      "VILLAGE SALARPUR JALALPUROPP. TRANSLAM ACADEMY INTERNATIONALMAWANA ROAD, TEHSIL MEERUTDISTT. MEERUT (UP)",
  },
  {
    _id: "5f96607069fccf0d2f872833",
    name: "A S N PHARMACY COLLEGE",
    address: "NELAPADUBURRIPALEM ROAD.TENALIGUNTUR DIST.,",
  },
  {
    _id: "5f96607069fccf0d2f874594",
    name: "A V COLLEGE OF ARTS,SCIENCE & COMMERCE",
    address: "GAGANMAHALDOMALGUDAHYDERABAD - 500029ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874082",
    name: "A V S COLLEGE OF TECHNOLOGY",
    address:
      "ATTUR MAIN ROAD , NEAR AVS COLLEGE OF ARTS & SCIENCE, CHINNAGOUNDAPURAM, SALEM - 636 106.",
  },
  {
    _id: "5f96607069fccf0d2f872aa7",
    name: "A-ONE PHARMACY COLLEGE",
    address: "VIL:ANASAN,TAL: DASKROI,DIS:AHMEDABAD",
  },
  {
    _id: "5f96607069fccf0d2f872d9d",
    name: "A. J. INSTITUTE OF ENGINEERING AND TECHNOLOGY MANGALURU",
    address: "KOTTARA CHOWKI BOLOOR VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f872f87",
    name: "A. J. INSTITUTE OF MANAGEMENT",
    address:
      "KOTTARA CHOWKI, BYPASS ROAD, ASHOK NAGAR POST, MANGALORE - 575006.",
  },
  {
    _id: "5f96607069fccf0d2f873557",
    name: "A. P. SHAH INSTITUTE OF TECHNOLOGY",
    address:
      "SURVEY NO. 12, OPP. HYPERCITY MALL, KASARVADAVALI, GHODBUNDER ROAD, THANE (W) - 400615",
  },
  {
    _id: "5f96607069fccf0d2f874a0c",
    name: "A. R. INSTITUTE OF PHARMACY",
    address: "TAJPUR, TEHSIL CHANDPUR, DISTT - BIJNOR",
  },
  {
    _id: "5f96607069fccf0d2f872a2a",
    name: "A. V. PAREKH TECHNICAL INSTITUTE",
    address: "DR. YAGNIK ROADOPP. HEMU GADHAVI HALL",
  },
  {
    _id: "5f96607069fccf0d2f8729d1",
    name: "A. Y. DADABHAI TECHNICAL INSTITUTE",
    address: "KOSAMBA- MAHUVEJ ROAD, NEAR OLD MARIYAMBAI HOSPITAL",
  },
  {
    _id: "5f96607069fccf0d2f872702",
    name: "A.A.N.M. & V.V.R.S.R. POLYTECHNIC",
    address: "SESHADRI RAO KNOWLEDGE VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f874096",
    name: "A.C.T COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "BUKKATHURAI-UTHIRAMERUR HIGH ROAD, NELVOY VILLAGE, MADHURANTHAGAM TALUK, KANCHEEPURAM DISTRICTPIN CODE:603 107",
  },
  {
    _id: "5f96607069fccf0d2f874232",
    name: "A.D.J.DHARMAMBAL POLYTECHNIC COLLEGE",
    address: "NAGORE MAIN ROAD, KADAMBADI,NAGAPATTINAM",
  },
  {
    _id: "5f96607069fccf0d2f872985",
    name: "A.D.PATEL INSTITUTE OF TECHNOLOGY",
    address:
      "P.O.BOX NO.:-52, BEHIND 4TH PHASE, GIDCVITTHAL UDYOGNAGAR,NEW VALLABH VIDYA NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874863",
    name: "A.D.R.S. INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address: "G.T. ROAD, TEH.-GABHANA,ALIGARH",
  },
  {
    _id: "5f96607069fccf0d2f872d71",
    name: "A.G.M RURAL COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "NH-4, P.B. ROAD OPP VRL HEAD OFFICE VARUR.",
  },
  {
    _id: "5f96607069fccf0d2f872e24",
    name: "A.G.M.RURAL POLYTECHNIC",
    address: "OPP VRL HEAD OFFICE, NH-4,BANGALORE ROAD NAVAGRAH TEERTH VARUR,",
  },
  {
    _id: "5f96607069fccf0d2f87359e",
    name: "A.G.PATIL POLYTECHNIC INSTITUTE",
    address: "18/2/2A PRATAP NAGAR, OPP. SRP CAMP, VIJAPUR ROAD, SOLAPUR",
  },
  {
    _id: "5f96607069fccf0d2f87361c",
    name: "A.I.'S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC",
    address: "8, SABOO SIDDIK POLYTECHNIC ROAD,BYCULLA",
  },
  {
    _id: "5f96607069fccf0d2f8736f7",
    name: "A.J.M.V.P.S., INSTITUTE OF HOTEL MANAGEMENT AND CATERING TECHNOLOGY.",
    address:
      "NEAR RESIDENTIAL HIGH SCHOOL.LAL TAKI ROAD.AHMEDNAGAR 414001MAHARASHTRA",
  },
  {
    _id: "5f96607069fccf0d2f8727d2",
    name: "A.K.R.G COLLEGE OF PHARMACY",
    address: "NALLAJERLA",
  },
  {
    _id: "5f96607069fccf0d2f8725d3",
    name: "A.K.R.G. COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "NALLAJERLAWEST GODAVARI DISTANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f87425e",
    name: "A.K.T. MEMORIALPOLYTECHNIC COLLEGE",
    address: "A.K.T.NAGARNEELAMANGALAM VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f873f3a",
    name: "A.K.T.MEMORIAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "NEELAMANGALAM (POST),KALLAKURICHI-TKVILLUPURAM-DISTPIN-606 202TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f872d6f",
    name: "A.M.C. ENGINEERING COLLEGE",
    address: "18KM,BANNERAGHATTA ROAD,KALKERE,BANGALORE-560083",
  },
  {
    _id: "5f96607069fccf0d2f8725f9",
    name: "A.M.REDDY MEMORIAL COLLEGE OF ENGINEERING& TECHNOLOGY",
    address: "PETLURIVARIPALEMNARASARAOPETGUNTUR(D.T)A.P",
  },
  {
    _id: "5f96607069fccf0d2f87281a",
    name: "A.M.REDDY MEMORIAL COLLEGE OF PHARMACY",
    address: "PETLURIVARIPALEM",
  },
  {
    _id: "5f96607069fccf0d2f874675",
    name: "A.N.A COLLEGE OF ENGINEERING AND MANAGEMENT STUDIES",
    address:
      "VILLAGE KURTARA , AGRAS ROAD, BEHIND RUBBER FACTORYP.O. BHITAURA BAREILLY - 243501U.P.",
  },
  {
    _id: "5f96607069fccf0d2f8748db",
    name: "A.N.A COLLEGE OF MANAGEMENT STUDIES",
    address: "AGRAS ROAD BEHIND RUBER FACTORY",
  },
  {
    _id: "5f96607069fccf0d2f8744b4",
    name: "A.P.GOVERNMENT INSTITUTE OF LEATHER TECHNOLOGY",
    address: "HUSSAIN SHAWALI DARGAGOLCONDA POSTHYDERABAD500 008",
  },
  {
    _id: "5f96607069fccf0d2f872ad4",
    name: "A.P.M.C. COLLEGE OF PHARMACEUTICAL EDUCATION AND RESEARCH",
    address: "COLLEGE CAMPUS, MOTIPURA",
  },
  {
    _id: "5f96607069fccf0d2f87478b",
    name: "A.P.S.COLLEGE OF EDUCATION & TECHNOLOGY",
    address: "ROHTA ROAD, VILL-KHIWAI NEAR-HARRA MORE,SARDHANA (MEERUT)",
  },
  {
    _id: "5f96607069fccf0d2f874029",
    name: "A.R COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "KADAYAM-ALANGULAM ROAD,NEAR RAILWAY STATION,KADAYAM.",
  },
  {
    _id: "5f96607069fccf0d2f872ac7",
    name: "A.R.COLLEGE OF PHARMACY & G.H.PATEL INSTITUTE OF PHARMACY",
    address: "POST BOX NO- 19MOTA BAZARVALLABH VIDYANGAR",
  },
  {
    _id: "5f96607069fccf0d2f874013",
    name: "A.R.ENGINEERING COLLEGE",
    address:
      "VADAKUCHIPALAYAM, KAPPIYAMPULIYUR POST,VILLUPURAM-605 601,TAMIL NADU,INDIA.",
  },
  {
    _id: "5f96607069fccf0d2f874094",
    name: "A.R.J COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "THIRUMAKKOTTAI MAIN ROADEDAYARNATHAM VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f8742fc",
    name: "A.R.J INSTITUTE OF MANAGEMENT STUDIES",
    address:
      "THIRUMAKKOTTAI MAIN ROAD,EDAYARANATHAM VILLAGE,SUNDARAKKOTTAI,MANNARGUDI",
  },
  {
    _id: "5f96607069fccf0d2f8741b3",
    name: "A.R.J POLYTECHNIC COLLEGE",
    address:
      "EDAYARNATHAMTHIRUMAKKOTTAI MAIN ROADSERANGULAM POSTMANNARGUDI TKTHIRUVARUR DT.",
  },
  {
    _id: "5f96607069fccf0d2f873c9d",
    name: "A.S.GROUP OF INSTITUTIONS",
    address:
      "A.S.GROUP OF INSTITUTIONSVILL- KALAL MAJRA(KHANNA)TEH. SAMRALADISTT. LUDHIANAPUNJAB-141401",
  },
  {
    _id: "5f96607069fccf0d2f8747ce",
    name: "A.S.M.POLYTECHNIC",
    address: "BSA ENGINEERING COLLEGE ROADMATHURA (UP)",
  },
  {
    _id: "5f96607069fccf0d2f872567",
    name: "A.S.N.WOMEN S ENGINEERING COLLEGE",
    address: "BURRRIPALAM ROAD,NELAPADU,TENALI.522201,GUNTUR (DT), A.P.",
  },
  {
    _id: "5f96607069fccf0d2f872812",
    name: "A.U.COLLEGE OF PHARMACEUTICAL SCIENCES",
    address: "ANDHRA UNIVERSITY, VISAKHAPATNAM",
  },
  {
    _id: "5f96607069fccf0d2f873fe3",
    name: "A.V.C.COLLEGE OF ENGINEERING",
    address: "MAYILADUTHURAIMANNAMPANDAL",
  },
  {
    _id: "5f96607069fccf0d2f87268b",
    name: "A.V.N.POLYTECHNIC",
    address: "ANNAVARAM ROAD",
  },
  {
    _id: "5f96607069fccf0d2f8742ac",
    name: "A.V.V.M SRI PUSHPAM COLLEGE(AUTOMOUS)",
    address: "A.V.V.M SRI PUSHPAMCOLLEGE(AUTONOMOUS),POONDI-613503,THANJAVUR.",
  },
  {
    _id: "5f96607069fccf0d2f8725d5",
    name: "A1 GLOBAL INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address: "MARKAPUR, PRAKASAM DISTRICT, ANDHRA PRADESH.",
  },
  {
    _id: "5f96607069fccf0d2f874011",
    name: "AAA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "AMATHUR VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f8735a2",
    name: "AABASAHEB SHIVAJIRAO SITARAM PATIL INSTITUTE OF POLYTECHNIC",
    address: "AT. CHINCHPURA PO. PIMPRI TAL. DHARANGAON DIST. JALGAON",
  },
  {
    _id: "5f96607069fccf0d2f8732c5",
    name: "AADARSH MAHAVIDHYALAYA",
    address:
      "KHASRA NO. 235/1/2 ,JIRAPUR CHAPEDA ROAD, GRAM KHEJADIYA TEH.JIRAPUR.",
  },
  {
    _id: "5f96607069fccf0d2f8743b6",
    name: "AADHI BHAGAWAN COLLEGE OF PHARMACY",
    address:
      "RANTHAM KOOT ROAD,RANTHAM,CHEYYAR (T.K)THIRUVANNAMALAI DISTRICT,604407.",
  },
  {
    _id: "5f96607069fccf0d2f872ca5",
    name: "AAKASH INSTITUTE OF MEDICAL SCIENCES",
    address: "OPP RADHA SWAMI BHAWAN, PINJORE ROAD, NALAGARH, SOLAN, H.P",
  },
  {
    _id: "5f96607069fccf0d2f873ed0",
    name: "AALIM MUHAMMED SALEGH COLLEGE OF ENGINEERING",
    address: "NIZARA EDUCATIONAL CAMPUS, MUTHAPUDUPET, AVADI-IAF,",
  },
  {
    _id: "5f96607069fccf0d2f87416e",
    name: "AALIM MUHAMMED SALEGH POLYTECHNIC COLLEGE",
    address: "NIZARA EDUCATIONAL CAMPUS MUTHAPUDUPET AVADI IAF",
  },
  {
    _id: "5f96607069fccf0d2f8736b0",
    name: "AAMDAR KASHINATHJI MENGAL POLYTECHNIC.",
    address: "AT-LAXMINAGAR,POST-MUNDEGAON,TAL-IGATPURI,DIST-NASHIK 422403",
  },
  {
    _id: "5f96607069fccf0d2f8743e3",
    name: "AAR MAHAVEER ENGINEERING COLLEGE",
    address: "VYASAPURI,BANDLAGUDAPOST: KESAVAGIRIHYDERABAD-500005",
  },
  {
    _id: "5f96607069fccf0d2f8740d5",
    name: "AAROORAN POLYTECHNIC COLLEGE",
    address:
      "MAYILADUTHURAI MAIN ROAD,SORAKKUDI,KANGALANCHERI (PO)NANNILAM(TK) THIRUVARUR",
  },
  {
    _id: "5f96607069fccf0d2f873fbe",
    name: "AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY",
    address:
      "CONSTITUENT COLLEGE OF VINAYAKA MISSION'S RESEARCH FOUNDATION- DEEMED TO BE UNIVERSITY VINAYAKA NAGAR RAJIV GANDHI SALAI (OMR),PAIYANOOR",
  },
  {
    _id: "5f96607069fccf0d2f872958",
    name: "AARYA-VEER COLLEGE OF ENGINEERING & TECHNOLOGY,RAJKOT",
    address:
      "AT POST:KUVADVA,NH-8,KUVADVA-SARDHAR ROAD,KUVADVADIST.:RAJKOTRAJKOT-360003",
  },
  {
    _id: "5f96607069fccf0d2f873d1a",
    name: "AASHIRWAD PHARMACY COLLEGE",
    address: "VPO MIDDA",
  },
  {
    _id: "5f96607069fccf0d2f8749bd",
    name: "AASHLAR BUSINESS SCHOOL",
    address:
      "AGRA-DELHI HIGHWAY (NH-2), VILL - MAHUAN, NEAR TOLL PLAZA, PO FARAH",
  },
  {
    _id: "5f96607069fccf0d2f874dbf",
    name: "ABACUS INSTITUTE OF ENGINEERING AND MANAGEMENT",
    address: "VILL- NUTANGRAM; P.O- MOGRA; DIST- HOOGHLY",
  },
  {
    _id: "5f96607069fccf0d2f873776",
    name: "ABASAHEB GARWARE INSTITUTE OF MANAGEMENT STUDIES, SANGLI",
    address: "C/O. WOMEN'S EDUCATION SOCIETY, S.T.STAND ROAD, KHANBHAG,SANGLI",
  },
  {
    _id: "5f96607069fccf0d2f8738fb",
    name: "ABASAHEB KAKADE COLLEGE OF B PHARMACY",
    address: "A/P BODHEGAON",
  },
  {
    _id: "5f96607069fccf0d2f873c11",
    name: "ABBNOOR POLYTECHNIC COLLEGE",
    address: "POST BOX NO-17FEROZEPUR ROAD, FARIDKOT-151203(PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f872f2c",
    name: "ABBS SCHOOL OF MANAGEMENT",
    address: "#3, LINGADHEERANAHALLI VILLAGE, MAGADI ROAD, BANGALORE",
  },
  {
    _id: "5f96607069fccf0d2f8735ec",
    name: "ABDUL RAZZAK KALSEKAR POLYTECHNIC",
    address: "PLOT NO. 3, SECTOR 16, KHANDAGAON, THANA NAKA, NEW PANVEL",
  },
  {
    _id: "5f96607069fccf0d2f874418",
    name: "ABDULKALAM INSTITUTE OF TECHNOLOGICAL SCIENCES",
    address:
      "ABDULKALAM INSTITUTE OF TECHNOLOGICAL SCIENCES,VEPALAGADDA,KOTHAGUDEM,KHAMMAM DTANDRAPRADESH",
  },
  {
    _id: "5f96607069fccf0d2f8746d2",
    name: "ABES ENGINEERING COLLEGE",
    address:
      "CAMPUS -1, 19TH KM STONE DELHI HAPUR BY PASS ROADN.H.-24 VIJAY NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f87469f",
    name: "ABES INSTITUTE OF TECHNOLOGY",
    address:
      "CAMPUS - 2, 19TH KM STONE, NATIONAL HIGHWAY - 24, VIJAY NAGAR, GHAZIABAD (UP)",
  },
  {
    _id: "5f96607069fccf0d2f874a1c",
    name: "ABESIT COLLEGE OF PHARMACY",
    address: "19 KM STONE NH-24 VIJAY NAGAR , GHAZIABAD",
  },
  {
    _id: "5f96607069fccf0d2f8734af",
    name: "ABHA GAIKWAD-PATIL COLLEGE OF ENGINEERING, NAGPUR",
    address: "KH. NO. 8/1, (P.S.K. 74), MOHGAON, WARDHA ROAD, NAGPUR",
  },
  {
    _id: "5f96607069fccf0d2f874791",
    name: "ABHAY MEMORIAL TRUST GROUP OF INSTITUTIONS",
    address: "366 AKBARPUR, SALLAPUR",
  },
  {
    _id: "5f96607069fccf0d2f8735ee",
    name: "ABHAYSINHRAJE BHONSLE INSTITUTE OF TECHNOLOGY (POLYTECHNIC)",
    address: "A/P-SHAHUNAGAR-SHENDRETAL & DIST-SATARA",
  },
  {
    _id: "5f96607069fccf0d2f8732ca",
    name: "ABHILASHA COLLEGE OF MANAGEMENT",
    address:
      "KHASRA NUMBER 2/1/2. PH. NO. 32. VILLAGE - CHAPRI. RATIBAD. BHOPAL",
  },
  {
    _id: "5f96607069fccf0d2f872c92",
    name: "ABHILASHI COLLEGE OF PHARMACY",
    address: "NER CHOWK, TEHSIL BALH, DISTT MANDI (H.P.) 175008",
  },
  {
    _id: "5f96607069fccf0d2f8734eb",
    name: "ABHINAV EDUCATION SOCIETY'S COLLEGE OF ENGINEERING AND TECHNOLOGY (DEGREE),WADWADI",
    address: "S. NO 209AT- WADWADITAL KHANDALADIST SATARASTATE MAHARASHTRA",
  },
  {
    _id: "5f96607069fccf0d2f873908",
    name: "ABHINAV EDUCATION SOCIETY'S COLLEGE OF PHARMACY",
    address: "S. NO. 23/3/3, AT NARHE, TAL HAVELI, DIST PUNE 411041",
  },
  {
    _id: "5f96607069fccf0d2f8739cf",
    name: "ABHINAV EDUCATION SOCIETY'S COLLEGE OF PHARMACY, (D.PHARM)",
    address: "S.NO.23/3/2, AT. NARHE, TAL. HAVELI",
  },
  {
    _id: "5f96607069fccf0d2f873760",
    name: "ABHINAV EDUCATION SOCIETY'S INSTITTUTE OF MANAGEMENT & BUSINESS ADMINISTRATION, AKOLE",
    address: "DHAMANGAON AWARI ROAD, AKOLETAL.: AKOLE DIST.: AHMEDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f873604",
    name: "ABHINAV EDUCATION SOCIETYS COLLEGE OF ENGINEERING AND TECHNOLOGY (POLYTECHNIC)",
    address:
      "SR. NO.-209,AT-WADWADIPOST- WINGTAL-KHANDALADIST-SATARAPIN-412801",
  },
  {
    _id: "5f96607069fccf0d2f87448c",
    name: "ABHINAV HI-TECH COLLEGE OF ENGINEERING",
    address: "GANDIPET HIMAYATNAGAR,MOINABAD,",
  },
  {
    _id: "5f96607069fccf0d2f872776",
    name: "ABHINAV INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address: "SOMARAJUPALLI,SINGARAYAKONDAPRAKASAM DISTRICTA.P. - 523 101",
  },
  {
    _id: "5f96607069fccf0d2f873969",
    name: "ABHINAV INSTITUTE OF PHARMACY",
    address: "4/3 B CHINCHPADA,, TALUKA AMBERNATH, DISTRICT THANE",
  },
  {
    _id: "5f96607069fccf0d2f874cc3",
    name: "ABHISHEK PHARMACY COLLEGE",
    address: "WARD NO 5, LOKMANYA TILAK NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f873c28",
    name: "ABHISHEK POLYTECHNIC COLLEGE",
    address: "P.O.- KHUIAN SARVAR, VILLAGE - DAULAT PURA, SRI GANGANAGAR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f873cfd",
    name: "ABOHAR PHARMACY COLLEGE",
    address: "VPO-TOOTWALA, TEH.-ABOHAR, DISTT. FAZILKA, ABOHAR (PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f873c69",
    name: "ABOHAR POLYTECHNIC COLLEGE",
    address: "VPO KALA TIBBA ABOHAR",
  },
  {
    _id: "5f96607069fccf0d2f872559",
    name: "ABR COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "CHINA IRLAPADU, KANDUKUR ROAD,KANIGIRI,PRAKASAM DT, PIN 523230.",
  },
  {
    _id: "5f96607069fccf0d2f874e80",
    name: "ABS ACADEMY OF POLYTECHNIC",
    address:
      "J.P AVENUE, SAGARBHANGA, DURGAPUR 713211 DISTRICT: BURDWAN WEST BENGAL",
  },
  {
    _id: "5f96607069fccf0d2f874eb9",
    name: "ABS ACADEMY OF SCIENCE,TECHNOLOGY & MANAGEMENT",
    address:
      "J.P AVENUE, SAGARBHANGA.DURGAPUR:713211DISTRICT:BURDWANWEST BENGAL",
  },
  {
    _id: "5f96607069fccf0d2f87465e",
    name: "ABSS INSTITUTE OF TECHNOLOGY",
    address: "SALARPUR, MAWANA ROAD, MEERUT,U.P",
  },
  {
    _id: "5f96607069fccf0d2f873e14",
    name: "ABUROAD POLYTECHNIC COLLEGE",
    address: "PALANPUR-ABU ROAD-HIGHWAY,RIICO COLONY,ABU ROAD",
  },
  {
    _id: "5f96607069fccf0d2f873b74",
    name: "ACADEMY OF BUSINESS ADMINISTRATION",
    address:
      "CORPORATE OFFICE S1/25, INDUSTRIAL ESTATE,BALASORE-756001CAMPUS : HARIDA, KURUDA, BALASORE -756056",
  },
  {
    _id: "5f96607069fccf0d2f873b77",
    name: "ACADEMY OF MANAGEMENT & INFORMATION TECHNOLOGY",
    address: "67, IID CENTER,",
  },
  {
    _id: "5f96607069fccf0d2f87330c",
    name: "ACADEMY OF MANAGMENT",
    address:
      "AMRAVAD KHURD, NEAR BDA COLONY, AFTER KALIBADI, AWADHPURI,PIPLANI, BHEL",
  },
  {
    _id: "5f96607069fccf0d2f873fdd",
    name: "ACADEMY OF MARITIME EDUCATION AND TRAINING DEEMED TO BE UNIVERSITY",
    address: "NO.135, EAST COAST ROAD, KANATHUR, REDDYKUPPAM, CHENNAI",
  },
  {
    _id: "5f96607069fccf0d2f87317a",
    name: "ACADEMY OF PHARMACEUTICAL SCIENCES, PARIYARAM",
    address: "P.O. PARIYARAM MEDICAL COLLEGE,KANNUR,KERALA,INDIA",
  },
  {
    _id: "5f96607069fccf0d2f874dc8",
    name: "ACADEMY OF TECHNOLOGY",
    address: "G. T. ROAD, ADISAPTAGRAMP.O:AEDCONAGARDIST-HOOGHLYWEST BENGAL",
  },
  {
    _id: "5f96607069fccf0d2f8748ac",
    name: "ACADEMY OF TECHNOLOGY MANAGEMENT AND SCIENCE",
    address: "ACCHEJA, NH-24, 49TH MILESTONE, HAPUR BYPASS,",
  },
  {
    _id: "5f96607069fccf0d2f87490b",
    name: "ACCMAN BUSINESS SCHOOL",
    address: "46 A/2, KNOWLEDGE PARK III",
  },
  {
    _id: "5f96607069fccf0d2f8748e9",
    name: "ACCMAN INSTITUTE OF MANAGEMENT",
    address:
      "46-A/2, KNOWLEDGE PARK IIIGREATER NOIDA 201 308DISTT. G.B. NAGARUTTAR PRADESH.",
  },
  {
    _id: "5f96607069fccf0d2f8727a3",
    name: "ACCORD BUSINESS SCHOOL",
    address: "CHIGURUWADA NORTH, TIRUPATI RURAL,",
  },
  {
    _id: "5f96607069fccf0d2f874a0e",
    name: "ACCURATE COLLEGE OF PHARMACY",
    address: "PLOT NO.- 49, KNOWLEDGE PARK-III, GREATER NOIDA,",
  },
  {
    _id: "5f96607069fccf0d2f874916",
    name: "ACCURATE INSTITUTE OF ADVANCED MANAGEMENT",
    address: "PLOT NO. 49, KNOWLEDGE PARK-III, GREATER NOIDA, UP",
  },
  {
    _id: "5f96607069fccf0d2f87468f",
    name: "ACCURATE INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address: "PLOT NO. 49, KNOWLEDGE PARK-III",
  },
  {
    _id: "5f96607069fccf0d2f8748f6",
    name: "ACCURATE INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address: "PLOT NO. 49, KNOWLEDGE PARK-III, GREATER NOIDA",
  },
  {
    _id: "5f96607069fccf0d2f874861",
    name: "ACCURATE INSTITUTE OF POLYTECHNIC",
    address: "PLOT NO 49KNOWLEDGE PARK-IIIGREATER NOIDA",
  },
  {
    _id: "5f96607069fccf0d2f87305c",
    name: "ACE COLLEGE OF ENGINEERING",
    address:
      "PLAPPATTIVILA,KARINKADAMUGAL, THIRUVALLAM, THIRUVANANTHAPURAM 695027",
  },
  {
    _id: "5f96607069fccf0d2f874732",
    name: "ACE COLLEGE OF ENGINEERING AND MANAGEMENT",
    address:
      "17 KM MILE STONE, AGRA KANPUR ROAD, NH-2, PLOT NO-455, 456, 457VILLEGE-SUREHRA,",
  },
  {
    _id: "5f96607069fccf0d2f87447e",
    name: "ACE ENGINEERING COLLEGE",
    address: "SY.NO: 175& 181,ANKUSHAPUR(V),GHATKESAR(M),RANGA REDDY (DT).",
  },
  {
    _id: "5f96607069fccf0d2f873b92",
    name: "ACHARIYA COLLEGE OF ENGINEERING TECHNOLOGY",
    address:
      "URUVAIYARU,ACHARIYAPURAM VILLIANOOR PUDUCHERRY-PUDUCHERRY- 605 110",
  },
  {
    _id: "5f96607069fccf0d2f8747c5",
    name: "ACHARY BALDEV POLYTECHNIC COLLEGE",
    address: "VILLAGE- KOPA, POST- PATARAHI, GHAZIPUR",
  },
  {
    _id: "5f96607069fccf0d2f873016",
    name: "ACHARYA & B M REDDY COLLEGE OF PHARMACY",
    address: "SOLDEVANAHALLIHESARGHATTA MAIN ROADBANGALORE - 560 090.",
  },
  {
    _id: "5f96607069fccf0d2f874bf4",
    name: "ACHARYA CHANKAYA MAHAVIDYALAYA FACULTY OF PHARMACY",
    address: "MAHMOODPUR SEMARI, SULTANPUR",
  },
  {
    _id: "5f96607069fccf0d2f87262d",
    name: "ACHARYA COLLEGE OF ENGINEERING",
    address:
      "D-AGRAHARAM VILLAGEREKALAKUNTA, BRAMHAMGARI MATAM MANDAL,NEAR BADVEL, ON BADVEL-MYDUKUR HIGHWAYKADAPA 516501",
  },
  {
    _id: "5f96607069fccf0d2f872f22",
    name: "ACHARYA INSTITUTE OF MANAGEMENT AND SCIENCES",
    address: "1ST STAGE, 1ST CROSSPEENYA INDUSTRIAL AREABANGALORE",
  },
  {
    _id: "5f96607069fccf0d2f872d6d",
    name: "ACHARYA INSTITUTE OF TECHNOLOGY",
    address: "SOLDEVANAHALLI, HESARAGHATTA MAIN ROAD, CHIKKABANAVARA POST,",
  },
  {
    _id: "5f96607069fccf0d2f874e7c",
    name: "ACHARYA JAGADISH CHANDRA BOSE POLYTECHNIC",
    address: "BERACHAMPA,P.O.-DEVALAYA,P.S.-DEGANGA",
  },
  {
    _id: "5f96607069fccf0d2f872a9b",
    name: "ACHARYA MOTIBHAI PATEL INSTITUTE OF COMPUTER STUDIES",
    address:
      "GANPAT UNIVERSITY,GANPAT VIDYANAGAR,MEHSANA GOZARIA HIGH WAY,CITY: KHERVA, TEHSIL: MEHSANA,DISTRICT: MEHSANA, STATE: GUJARAT",
  },
  {
    _id: "5f96607069fccf0d2f8727e9",
    name: "ACHARYA NAGARJUNA UNIVERSITY COLLEGE OF PHARMACEUTICAL SCIENCES",
    address: "NAGARJUNA NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874add",
    name: "ACHARYA NARENDRA DEO COLLEGE OF PHARMACY",
    address: "KARANPUR, BABHNAN, GONDA, U.P.",
  },
  {
    _id: "5f96607069fccf0d2f872eff",
    name: "ACHARYA PATASHALA POLYTECHNIC",
    address: "26 TH KM,KANAKAPURA MAIN ROAD,SOMANAHALLI.",
  },
  {
    _id: "5f96607069fccf0d2f872e27",
    name: "ACHARYA POLYTECHINC",
    address:
      "SOLDEVANAHALLI,HESARAGHATTA MAIN ROAD BANGALORE -90KARNATKAKA, INDIA",
  },
  {
    _id: "5f96607069fccf0d2f87416c",
    name: "ACHARYA POLYTECHNIC COLLEGE",
    address:
      "MEIKKAL REDDIYUR, VELLARI VELLI (PO),POOLAMPATTI MAIN ROAD,EDAPPADI (TK),SALEM(DT),TAMILNADU - 637101",
  },
  {
    _id: "5f96607069fccf0d2f872f30",
    name: "ACHARYA SCHOOL OF MANAGEMENT",
    address: "SOLDEVANAHALLIHESARAGHATTA MAIN ROADBANGALORE 560090",
  },
  {
    _id: "5f96607069fccf0d2f873e4c",
    name: "ACHARYA SHREE NANESH SAMTA MAHAVIDYALAYA",
    address:
      "VILLAGE:-NANESH NAGAR,DANTATEHSIL:-KAPASANDISTRICT:-CHITTORGARHSTATE:-RAJASTHANPIN CODE:-312202",
  },
  {
    _id: "5f96607069fccf0d2f8735af",
    name: "ACHARYA SHRIMANANRAYAN POLYTECHNIC",
    address: "ARVI ROAD, PIPRI,WARDHA.",
  },
  {
    _id: "5f96607069fccf0d2f872cbf",
    name: "ACHARYA SRI CHANDER TECHNICAL INSTITUTE",
    address: "GHOU MANHASAN",
  },
  {
    _id: "5f96607069fccf0d2f872f5c",
    name: "ACHARYA'S BANGALORE B-SCHOOL",
    address: "#3, LINGADHEERANAHALLI VILLAGE, MAGADI ROAD, BANGALORE-560091",
  },
  {
    _id: "5f96607069fccf0d2f874e6b",
    name: "ACHARYYA PRAFULLA CHANDRA RAY POLYTECHNIC",
    address: "188, RAJA S.C. MALLICK ROAD,P.O.: JADAVPUR UNIVERSITY,KOLKATA-32",
  },
  {
    _id: "5f96607069fccf0d2f872da5",
    name: "ACHUTHA INSTITUTE OF TECHNOLOGY",
    address: "GOPALAPURA, BAGALURU(P),BANGALURU-562149",
  },
  {
    _id: "5f96607069fccf0d2f874a5e",
    name: "ACME INSTITUTE OF PHARMACY",
    address: "NH-24 VILL - MEGI NAGLA, POST OFFICE - RAJAU, TEHSIL - FARIDPUR",
  },
  {
    _id: "5f96607069fccf0d2f87468c",
    name: "ACN COLLEGE OF ENGG. & MANAGMENT STUDIES",
    address: "CHHERUTKASHIMPUR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f87476e",
    name: "ACN COLLEGE OF POLYTECHNIC",
    address: "ACN CITY, CHHERAT, KASIMPUR POWER HOUSE ROAD, ALIGARH",
  },
  {
    _id: "5f96607069fccf0d2f8732f6",
    name: "ACROPOLIS FACULTY OF MANAGEMENT & RESEARCH",
    address:
      "MANGLIA SADAK, SANWER, INDORE BYPASS, NEAR MANGLIA SQUARE, INDORE",
  },
  {
    _id: "5f96607069fccf0d2f87337a",
    name: "ACROPOLIS INSTITUTE OF PHARMACEUTICAL EDUCATION AND RESEARCH",
    address: "INDORE BYPASS ROAD, MANGLIA SQUAREINDORE",
  },
  {
    _id: "5f96607069fccf0d2f8731be",
    name: "ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH",
    address:
      "ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH MANGLIYA SQUARE ,BYPASS ROAD INDORE 453771",
  },
  {
    _id: "5f96607069fccf0d2f8731cc",
    name: "ACROPOLIS TECHNICAL CAMPUS",
    address: "RALAMANDAL NEAR TILLORE KHURD, INDORE",
  },
  {
    _id: "5f96607069fccf0d2f872d81",
    name: "ACS COLLEGE OF ENGINEERING",
    address: "207,KAMBIPURA, MYSORE ROAD, KENGERI HOBLI,BANGALORE - 560074.",
  },
  {
    _id: "5f96607069fccf0d2f874cc1",
    name: "ACS COLLEGE OF PHARMACY",
    address:
      "GRAM- KILAUNI, PO.- PATLAONI, TEHSIL- MAHAVAN, DISTT.- MATHURA ,(U.P)",
  },
  {
    _id: "5f96607069fccf0d2f8742ff",
    name: "ADAIKALAMATHA INSTITUTE OF MANAGEMENT",
    address: "ADAIKALAMATHA INSTITUTE OF MANAGEMENT,ARUN NAGAR,VALLAM",
  },
  {
    _id: "5f96607069fccf0d2f874dbc",
    name: "ADAMAS UNIVERSITY TECHNICAL CAMPUS",
    address:
      "ADAMAS KNOWLEDGE CITY, BARASAT - BARRACKPORE ROAD, P.O.JAGANNATHPUR, P.S. DUTTAPUKUR, DISTRICT. NORTH 24 PARGANAS, PIN - 700126, WEST BENGAL",
  },
  {
    _id: "5f96607069fccf0d2f8729ae",
    name: "ADANI INSTITUTE OF INFRASTRUCTURE ENGINEERING",
    address: "SHANTIGRAM TOWNSHIP, NEAR VAISHNODEVI CIRCLE, SG HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f872a42",
    name: "ADANI INSTITUTE OF INFRASTRUCTURE MANAGEMENT",
    address: "SHANTIGRAM TOWNSHIP, NEAR VAISHNODEVI CIRCLE, SG HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f87280c",
    name: "ADARSA COLLEGE OF PHARMACY",
    address:
      "KAMA RAJU PETG.KOTHAPALLI - 533285EAST GODAVARI DISTRICTANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f8725fb",
    name: "ADARSH COLLEGE OF ENGINEERING",
    address:
      "NH-214CHEBROLUGOLLAPROLU MANDALEAST GODAVARI DISTRICTANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f873904",
    name: "ADARSH COLLEGE OF PHARMACY",
    address: "BHAVANINAGAR,KUNDAL ROAD,VITA TAL-KHANAPUR DIST-SANGLI",
  },
  {
    _id: "5f96607069fccf0d2f872f80",
    name: "ADARSH INSTITUTE OF MANAGEMENT AND INFORMATION TECHNOLOGY",
    address:
      "REGD. OFFICE AT NO.75, 5TH MAIN ROAD, DEVANATHACHAR STREET, CHAMARAJPET, BANGALORE - 560 018",
  },
  {
    _id: "5f96607069fccf0d2f873a35",
    name: "ADARSH INSTITUTE OF PHARMACY (D.PHARM.)",
    address: "A/P. KUNDAL ROAD, BHAVANINAGAR, VITA, TAL.KHANAPUR, DIST. SANGLI",
  },
  {
    _id: "5f96607069fccf0d2f8734a2",
    name: "ADARSH INSTITUTE OF TECHNOLOGY & RESERCH CENTRE.",
    address:
      "A/P:- KHAMBALE(BHA),VITA. GAT NO. 421, TAL - KHANAPUR, DIST- SANGLI, PIN- 415311",
  },
  {
    _id: "5f96607069fccf0d2f872b66",
    name: "ADARSH POLYTECHNIC",
    address: "VILLAGE BRAHMANWAS P.O. JULANA",
  },
  {
    _id: "5f96607069fccf0d2f873c50",
    name: "ADARSH POLYTECHNIC COLLEGE",
    address:
      "PATIALA - SAMANA ROAD, VILLAGE - DHANTAHAL,TEHSIL-SAMANA, DISTRICT-PATIALA (PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f873677",
    name: "ADARSH POLYTECHNIC,DHULE",
    address: "155,NAKANE,NAKANE ROAD DEOPUR,DHULE (MS)",
  },
  {
    _id: "5f96607069fccf0d2f8745c1",
    name: "ADARSH POST GRADUATE COLLEGE OF COMPUTER SCIENCES",
    address: "H.NO:7-5-51/CVENKATESHWARA COLONYMAHABUBNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f8739ee",
    name: "ADARSH SHIKSHAN PRASARAK MANAL'S D. PHARMACY INSTITUTE, OSMANABAD",
    address: "P. B. NO.39, KESHAV NAGAR,GHATANGRI ROAD,OSMANABAD",
  },
  {
    _id: "5f96607069fccf0d2f873761",
    name: "ADARSH SHIKSHAN PRASARAK MANDAL'S K.T.PATIL COLLEGE OF MBA, OSMANABAD.",
    address:
      "ADARSH SHIKSHAN PRASARAK MANDAL'S K.T.PATIL COLLEGE OF MBA, SIDDHARTH NAGAR, BARSHI ROAD,OSMANABAD. (M.S) 413501",
  },
  {
    _id: "5f96607069fccf0d2f8739fa",
    name: "ADARSH SHIKSHAN SANSTHA'S COLLEGE OF PHARMACY, BEED.",
    address: "POST BOX NO. 38 NAGAR ROAD BEED.",
  },
  {
    _id: "5f96607069fccf0d2f873ab6",
    name: "ADARSHA COLLEGE OF ENGINEERING",
    address: "AT-SARADHAPUR, PO-KUMURISINGHA,VIA/DIST-ANGUL, PIN-759122ORISSA",
  },
  {
    _id: "5f96607069fccf0d2f873afe",
    name: "ADARSHA SCHOOL OF ENGINEERING & INTERNATIONAL POLYTECHNIC",
    address: "AT-SARADHAPURPO-KUMURISINGHA,VIA/DIST-ANGULODISHA759122",
  },
  {
    _id: "5f96607069fccf0d2f87275c",
    name: "ADDANKI INSTITUTE OF MANAGEMENT AND SCIENCES",
    address:
      "ADDANKI INSTITUTE OF MANAGEMENT AND SCIENCESNEAR SINGARA KONDANRT ROADNORTH ADDANKIADDANKI MANDALPRAKASAM DISTRICT 523201",
  },
  {
    _id: "5f96607069fccf0d2f874a1e",
    name: "ADESH COLLEGE OF PHARMACY",
    address: "VILLAGE NARAYANPUR BISALPUR DISTRICT PILIBHIT UP",
  },
  {
    _id: "5f96607069fccf0d2f873bd9",
    name: "ADESH INSTITUTE OF ENGINEERING & TECHNOLOGY, FARIDKOT",
    address:
      "ADESH INSTITUTE OF ENGINEERING & TECHNOLOGYPOST BOX NO. 11SADIQ ROAD, FARIDKOT 151203PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f873bf9",
    name: "ADESH INSTITUTE OF TECHNOLOGY",
    address: " LUDHIANA ROADVILL : GHARUANTEH : KHARARDISTT : S.A. S. NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f873c17",
    name: "ADESH POLYTECHNIC COLLEGE, MUKTSAR",
    address: "VILLAGE MARH MALLUFEROZEPUR ROADMUKTSAR",
  },
  {
    _id: "5f96607069fccf0d2f8737bd",
    name: "ADHALRAO PATIL INSTITUTE OF MANAGEMENT AND RESEARCH",
    address: "A/P LANDEWADI (CHINCHODI), TAL - AMBEGAON, DIST- PUNE -410503",
  },
  {
    _id: "5f96607069fccf0d2f874c83",
    name: "ADHARSHILA COLLEGE OF PHARMACY",
    address: "VILLAGE-ATURA BUJURG,TEHSIL-SADAR,RAEBARELI",
  },
  {
    _id: "5f96607069fccf0d2f873fba",
    name: "ADHI COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "6, MUNU ADHI NAGAR, SANKARAPURAM, PULIAMBAKKAM POST, NEAR WALLAJABAD, KANCHEEPURAM",
  },
  {
    _id: "5f96607069fccf0d2f874364",
    name: "ADHIPARASAKTHI COLLEGE OF ARTS AND SCIENCE",
    address: "G.B.NAGAR,KALAVAI,TAMILNADU-632 506",
  },
  {
    _id: "5f96607069fccf0d2f873f7d",
    name: "ADHIPARASAKTHI COLLEGE OF ENGINEERING",
    address:
      "G.B. NAGARKALAVAI ARCOT TALUKVELLORE DISTRICTTAMILNADUPIN - 632 506.",
  },
  {
    _id: "5f96607069fccf0d2f8743b3",
    name: "ADHIPARASAKTHI COLLEGE OF PHARMACY",
    address: "MELMARUVATHUR-603 319,KANCHIPURAM DISTRICT,TAMILNADU.",
  },
  {
    _id: "5f96607069fccf0d2f873f4b",
    name: "ADHIPARASAKTHI ENGINEERING COLLEGE",
    address:
      "MELMARUVATHUR,CHEYYUR TALUK,KANCHEEPURAM DISTRICT,TAMILNADU,INDIA",
  },
  {
    _id: "5f96607069fccf0d2f874329",
    name: "ADHIPARASAKTHI ENGINEERING COLLEGE (MBA)",
    address: "MELMARUVATHUR,CHEYYUR TALUK,TAMILNADU.",
  },
  {
    _id: "5f96607069fccf0d2f874361",
    name: "ADHIPARASAKTHI ENGINEERING COLLEGE (MCA)",
    address: "NH-45, MELMARUVATHUR,CHEYYUR, KANCHIPURAM DT,603319.",
  },
  {
    _id: "5f96607069fccf0d2f874163",
    name: "ADHIPARASAKTHI POLYTECHNIC COLLEGE",
    address:
      "ADHIPARASAKTHI POLYTECHNIC COLLEGE, MELMARUVATHUR & POST, CHEYYUR TALUK",
  },
  {
    _id: "5f96607069fccf0d2f873f55",
    name: "ADHIYAMAAN COLLEGE OF ENGINEERING (ENGINEERING & TECHNOLOGY)",
    address:
      "(ENGINEERING & TECHNOLOGY)AERI CAMPUSDR.M.G.R.NAGAR,HOSUR-635 109KRISHNAGIRI DISTRICTTAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f87429e",
    name: "ADHIYAMAAN COLLEGE OF ENGINEERING (MBA PROGRAMME)",
    address: "DR.M.G.R.NAGAR,THORAPALLI AGRAHARAM",
  },
  {
    _id: "5f96607069fccf0d2f874351",
    name: "ADHIYAMAAN COLLEGE OF ENGINEERING (MCA PROGRAMME)",
    address:
      "AERI CAMPUSDR.M.G.R.NAGARHOSUR-635109KRISHNAGIRI DISTRICTTAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f874228",
    name: "ADHIYAMAAN POLYTECHNIC COLLEGE",
    address: "DR.M.G.R.NAGAR,CHENNATHUR",
  },
  {
    _id: "5f96607069fccf0d2f874743",
    name: "ADHUNIK COLLEGE OF ENGINEERING",
    address: "9TH MILE STONE, DELHI MEERUT ROAD DUHAI, GHAZIABAD",
  },
  {
    _id: "5f96607069fccf0d2f8748df",
    name: "ADHUNIK INSTITUTE OF PRODUCTIVITY MANAGEMENT & RESEARCH",
    address: "9TH MILE STONE DELHI MEERUT ROAD DUHAI",
  },
  {
    _id: "5f96607069fccf0d2f874b4b",
    name: "ADHYAYAN COLLEGE OF PHARMACY",
    address: "JALALPUR AMBEDKAR NAGAR UTTER PRADESH 224149",
  },
  {
    _id: "5f96607069fccf0d2f87305a",
    name: "ADI SHANKARA INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address: "SANKAR NAGAR,MATTOOR,KALADY,ERNAKULAM,KERALA",
  },
  {
    _id: "5f96607069fccf0d2f872de6",
    name: "ADICHUNCHANAGIRI INSTITUTE OF TECHNOLOGY",
    address:
      "ADICHUNCHANAGIRI EXTENTION,JYOTHINAGAR,CHIKMAGALUR - 577102KARNATAKAINDIA",
  },
  {
    _id: "5f96607069fccf0d2f872ee9",
    name: "ADICHUNCHANAGIRI POLYTECHNIC",
    address:
      "ADICHUNCHANAGIRI EXTENSION, JYOTHINAGAR POST, CHIKMAGALUR TALUK, CHIKMAGALUR DIST.",
  },
  {
    _id: "5f96607069fccf0d2f87338f",
    name: "ADINA COLLEGE OF PHARMACY SAGAR",
    address: "NH-86, BAMHORI RENGUA, BHOPAL ROAD SAGAR 470002 (M.P)",
  },
  {
    _id: "5f96607069fccf0d2f873380",
    name: "ADINA INSTITUTE OF PHARMACEUTICAL SCIENCES, SAGAR",
    address: "OPPOSITE,BAMHORI SEED FARM, NH-86, BHOPAL ROAD,SAGAR MP.",
  },
  {
    _id: "5f96607069fccf0d2f8731b3",
    name: "ADINA INSTITUTE OF SCIENCE AND TECHNOLOGY, SAGAR, MP",
    address: "OPPOSITE BAMHORI SEED FARMBHOPAL ROADSAGAR M.P.",
  },
  {
    _id: "5f96607069fccf0d2f874182",
    name: "ADIPARASAKTHI POLYTECHNIC COLLEGE",
    address:
      "ERUMIYAMPATTI-VILLAGEKOKKARAPATTI-POPAPPIREDDIPATTI-TK,DHARMAPURI-DT",
  },
  {
    _id: "5f96607069fccf0d2f872738",
    name: "ADITHE SATYANARAYANA PG COLLEGE (ASN)",
    address:
      "RAJYA LAKSHMI GARDENS,BHUPAYYA AGRAHARAM,AMALAPURAM, EAST GODAVARI DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f874002",
    name: "ADITHYA INSTITUTE OF TECHNOLOGY",
    address:
      "SF NO. 348/1, 349/1,KURUMBAPALAYAM VILLAGE,SS KULUM POST,COIMBATORE",
  },
  {
    _id: "5f96607069fccf0d2f873013",
    name: "ADITYA BANGALORE INSTITUTE OF PHARMACY EDUCATION & RESEARCH",
    address:
      "NO.12, KOGILU MAIN ROAD, BEHIND ANNAPURNESHWARI TEMPLE, YELAHANKA",
  },
  {
    _id: "5f96607069fccf0d2f872595",
    name: "ADITYA COLLEGE OF ENGINEERING",
    address: "ADITYA NAGAR,ADB ROAD,SURAMPALEM,VIA PEDDAPURAM.",
  },
  {
    _id: "5f96607069fccf0d2f8725d0",
    name: "ADITYA COLLEGE OF ENGINEERING",
    address: "VALASAPALLI POST,MADANAPALLE,CHITTOOR DIST,ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f872584",
    name: "ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "ADITYA NAGARADB ROADSURAMPALEMGANDEPALLI MANDALE.G.DT.533437",
  },
  {
    _id: "5f96607069fccf0d2f87373f",
    name: "ADITYA COLLEGE OF MBA, BEED",
    address: "NALWANDI ROAD BEED",
  },
  {
    _id: "5f96607069fccf0d2f8727d9",
    name: "ADITYA COLLEGE OF PHARMACY",
    address:
      "ADITYA NAGAR,SURAMPALEMA D B ROAD, PEDDAPURAMGANDEAPALLI MANDALEAST GODAVARI DISTRICTANDHRA PRADESHPIN:533 437",
  },
  {
    _id: "5f96607069fccf0d2f8733ee",
    name: "ADITYA COLLEGE OF PHARMACY",
    address: "95, MAHDEVA ROAD (RING ROAD), SHERGANJ ,SATNA (M.P.)",
  },
  {
    _id: "5f96607069fccf0d2f8731af",
    name: "ADITYA COLLEGE OF TECHNOLOGY & SCIENCE",
    address: "95, MAHDEVA ROAD, SHERGANJ, SATNA (M.P.) 485001",
  },
  {
    _id: "5f96607069fccf0d2f873a3e",
    name: "ADITYA DIPLOMA INSTITUTE OF PHARMACY",
    address: "SUY NO. 121, SARDA ESTATE, NALWANDI ROAD, BEED.",
  },
  {
    _id: "5f96607069fccf0d2f87254e",
    name: "ADITYA ENGINEERING COLLEGE",
    address:
      "ADITYA ENGINEERING COLLEGEADITYA NAGAR, ADB ROAD, SURAMPALEM,GANDEPALLI MANDAL, EAST GODAVARI DISTRICT, PIN - 533 437, ANDHRA PRADESH.",
  },
  {
    _id: "5f96607069fccf0d2f873526",
    name: "ADITYA ENGINEERING COLLEGE",
    address: "TELGAON ROAD BEED",
  },
  {
    _id: "5f96607069fccf0d2f8727a6",
    name: "ADITYA GLOBAL BUSINESS SCHOOL",
    address:
      "ADITYA NAGAR, ADB ROAD, SURAMPALEM, GANDEPALLI MANDAL, EAST GODAVARI DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f87379d",
    name: "ADITYA INSTITUTE OF MANAGEMENT STUDIES & RESEARCH",
    address:
      "F.P NO. 661+663+667/B, TPS III, NEAR KALPANA CHAWLA CHOWK, RAMNAGAR, BORIVALI(W)",
  },
  {
    _id: "5f96607069fccf0d2f872f74",
    name: "ADITYA INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH",
    address: "SURVEY NO.12, KOGILU MAIN ROAD, YELAHANKA",
  },
  {
    _id: "5f96607069fccf0d2f872721",
    name: "ADITYA INSTITUTE OF PG STUDIES",
    address:
      "ADITYA NAGAR, ADB ROAD, SURAMPALEM, EAST GODAVARI DISTRICT, ANDHRA PRADESH,PIN - 533437",
  },
  {
    _id: "5f96607069fccf0d2f873954",
    name: "ADITYA INSTITUTE OF PHARMACEUTICAL",
    address: "SUY. NO. 123 SARDA ESTATE NALWANDI ROAD, BEED",
  },
  {
    _id: "5f96607069fccf0d2f872907",
    name: "ADITYA INSTITUTE OF TECHNOLOGY",
    address: "107/9, KISHAN GARH, VASANT KUNJ, NEW DELHI",
  },
  {
    _id: "5f96607069fccf0d2f872597",
    name: "ADITYA INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "K.KOTTURU (VILL)TEKKALI - 532 201SRIKAKULAM (DIST)ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f8727dc",
    name: "ADITYA PHARMACY COLLEGE",
    address:
      "\\ ADITYA NAGAR, ADB ROAD, SURAMPALEM, EAST GODAVARI DISTRICT, ANDHRA PRADESH, PIN - 533 437,",
  },
  {
    _id: "5f96607069fccf0d2f873910",
    name: "ADITYA PHARMACY COLLEGE",
    address: "SARDA ESTATENALWANDI ROADBEED",
  },
  {
    _id: "5f96607069fccf0d2f8735b5",
    name: "ADITYA POLYTECHNIC",
    address:
      "GAT NO. 493, AT AND POST TALAWADE, COLLEGE ROAD, NEAR ADAVALI RAILWAY STATION, TALUKA LANJA, DIST. RATNAGIRI, MAHARASHTRA",
  },
  {
    _id: "5f96607069fccf0d2f87368f",
    name: "ADITYA POLYTECHNIC BEED",
    address: "TELGOAN ROAD",
  },
  {
    _id: "5f96607069fccf0d2f87299f",
    name: "ADITYA SILVER OAK INSTITUTE OF TECHNOLOGY WITHIN AHMEDABAD MUNICIPAL CORPORATION LIMIT",
    address:
      "352, 353A, NEAR BHAVIK PUBLICATION, OPP. BHAGWAT VIDHYAPITH, GOTA CROSS ROAD, S. G. HIGHWAY, AHMEDABAD",
  },
  {
    _id: "5f96607069fccf0d2f872537",
    name: "ADITYA TATHAGAT INSTITUTE OF PHARMACY",
    address: "AT-MAKANPUR, P.O-BRARAH",
  },
  {
    _id: "5f96607069fccf0d2f872fc7",
    name: "ADMINISTRATIVE MANAGEMENT COLLEGE",
    address: "18KM, BANNERGHATTA ROAD, KALKERE",
  },
  {
    _id: "5f96607069fccf0d2f872f81",
    name: "ADMINISTRATIVE MANAGMENT COLLEGE",
    address: "18KM, BANNERGHATTA ROAD, KALKERE, BANGALORE - 560 083",
  },
  {
    _id: "5f96607069fccf0d2f874538",
    name: "ADMINISTRATIVE STAFF COLLEGE OF INDIA",
    address:
      "CENTER FOR HEALTHCARE MANAGEMENT,COLLEGE PARK CAMPUS,BANJARA HILLS ROAD NO.3,HYDERABAD-500034.",
  },
  {
    _id: "5f96607069fccf0d2f873521",
    name: "ADSUL'S TECHNICAL CAMPUS",
    address: "AT-CHAS, CHAS-NIMBLAK BYPASS ROAD, AHMEDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f87449e",
    name: "ADUSUMILLI VIJAYA INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE",
    address:
      "MYSIREDDYPALLY(V), HAZIPUR(SO), BIBINAGAR(BO), BOMMALARAMARAM(M), NALGONDA (DIST).",
  },
  {
    _id: "5f96607069fccf0d2f874a0b",
    name: "ADVANCE COLLEGE OF EDUCATION",
    address: "355- NARAMAU KACHHAR, G.T. ROAD, KANPUR-209217",
  },
  {
    _id: "5f96607069fccf0d2f874b1a",
    name: "ADVANCE INSTITUTE OF BIOTECH & PARAMEDICAL SCIENCES",
    address: "366, NARAMAU, NARAMAU KACHHAR, G.T.ROAD, KANPUR",
  },
  {
    _id: "5f96607069fccf0d2f872c1d",
    name: "ADVANCED INSTITUTE OF PHARMACY",
    address:
      "70 K.M., DELHI MATHURA ROAD, V.P.O AURANGABAD, TEHSIL HODAL, DISTT PALWAL (HARYANA)",
  },
  {
    _id: "5f96607069fccf0d2f872b15",
    name: "ADVANCED INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address:
      "70 KM , DELHI - MATHURA ROADV.P.O : AURANGABAD TEHSIL : HODALDISTRICT : PALWAL (HARYANA)",
  },
  {
    _id: "5f96607069fccf0d2f873263",
    name: "ADVANCED PLASTICS PRODUCT SIMULATION AND EVALUATION CENTER",
    address: "PLASTICS PARK VILLAGE TAMOT TAHSIL GOHARGANJ",
  },
  {
    _id: "5f96607069fccf0d2f873eac",
    name: "ADVANCED TECHNICAL TRAINING CENTRE",
    address: "NH 31A,BARDANG",
  },
  {
    _id: "5f96607069fccf0d2f8741a9",
    name: "ADVANCED TOOLING & PLASTICS PRODUCT DEVELOPMENT CENTRE",
    address:
      "NO.35/1,1ST & 2ND FLOORS,TAMILNADU STATE AGRICULTURAL MARKETING BOARD,PADDY & FLOWER MARKET COMPLEX,MATTUTHAVANI.",
  },
  {
    _id: "5f96607069fccf0d2f8724b7",
    name: "ADWAITA MISSION INSTITUTE OF TECHNOLOGY",
    address: "AT-SHIVDHAM,PO-MANIYARPURVIA-BOUNSI,DIST-BANKA,BIHAR-813104",
  },
  {
    _id: "5f96607069fccf0d2f874e44",
    name: "ADYAPEATH ANNADA POLYTECHNIC COLLEGE",
    address:
      "UNDER (DAKSHINESWAR RAMKRISHNA SANGHA ADYAPEATH) 50 D. D. MONDAL GHAT ROAD,",
  },
  {
    _id: "5f96607069fccf0d2f8748a8",
    name: "AERONAUTICAL TRAINING INSTITUTE UP",
    address: "LUCKNOW AIRPORT LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f872b7e",
    name: "AERP INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address: " VILLAGE SONDH, TEH. HODAL, NUH ROAD, DISTT. PALWAL, (HARYANA)",
  },
  {
    _id: "5f96607069fccf0d2f873b4c",
    name: "AFFINITY BUSINESS SCHOOL",
    address: "AT/PO-BARATOTA, VIA-JATNI, DIST- KHURDA,PIN-752050,STATE-ORISSA",
  },
  {
    _id: "5f96607069fccf0d2f872fe9",
    name: "AGM COLLEGE OF PHARMARCY",
    address: "NAVAGRAHA TEERTHA, NH-4 ROAD, VARUR",
  },
  {
    _id: "5f96607069fccf0d2f873619",
    name: "AGNEL CHARITIES AGNEL POLYTECHNIC",
    address: "SECTOR-9A, VASHI,NAVI MUMBAIDIST.THANE.",
  },
  {
    _id: "5f96607069fccf0d2f872949",
    name: "AGNEL INSTITUTE OF FOOD CRAFTS AND CULINARY SCIENCES",
    address: "AGNEL TECHNICAL EDUCATION COMPLEXAGNEL ASHRAMVERNA",
  },
  {
    _id: "5f96607069fccf0d2f872941",
    name: "AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN",
    address: "ASSAGAO,BARDEZ,GOA.",
  },
  {
    _id: "5f96607069fccf0d2f872945",
    name: "AGNEL POLYTECHNIC",
    address: "AGNEL TECHNICAL EDUCATION COMPLEX",
  },
  {
    _id: "5f96607069fccf0d2f8736b4",
    name: "AGNEL TECHNICAL COLLEGE (POLYTECHNIC)",
    address: "FR. AGNEL ASHRAM,BANDSTAND,BANDRA(W)",
  },
  {
    _id: "5f96607069fccf0d2f873f2a",
    name: "AGNI COLLEGE OF TECHNOLOGY",
    address:
      "THALAMBUROFF. RAJIV GANDHI SALAICHENGALPATTU TALUKKANCHEEPURAM DISTRICTCHENNAIPIN CODE : 603 103",
  },
  {
    _id: "5f96607069fccf0d2f8742d4",
    name: "AGNI SCHOOL OF BUSINESS EXCELLENCE",
    address:
      "NH-45, MOREPATTI, VADAMADURAI VIAVEDASANDUR TALUK, DINDIGUL-624802",
  },
  {
    _id: "5f96607069fccf0d2f8734d8",
    name: "AGNIHOTRI COLLEGE OF ENGINEERING",
    address: "NEAR BYPASS HIGHWAYNAGTHANA ROAD SINDI (MEGHE)WARDHA",
  },
  {
    _id: "5f96607069fccf0d2f873881",
    name: "AGNIHOTRI COLLEGE OF PHARMACY",
    address: "AGNIHOTRI COLLEGE CAMPUSSINDI (MEGHE)WARDHA",
  },
  {
    _id: "5f96607069fccf0d2f873947",
    name: "AGNIHOTRI INSTITUTE OF PHARMACY",
    address: "BAPUJI WADI SINDI MEGHE, WARDHA -",
  },
  {
    _id: "5f96607069fccf0d2f8735e1",
    name: "AGNIHOTRI POLYTECHNIC",
    address: "NAGTHANA ROAD, NEAR BYPASS HIGHWAY, SINDI (MEGHE), WARDHA",
  },
  {
    _id: "5f96607069fccf0d2f8735d0",
    name: "AGNIHOTRI SCHOOL OF TECHNOLOGY",
    address: "AGNIHOTRI COLLEGE CAMPUS, BAPUJI WADI , RAMNAGAR, WARDHA",
  },
  {
    _id: "5f96607069fccf0d2f873220",
    name: "AGNOS COLLEGE OF TECHNOLOGY",
    address:
      "NEAR RGPV CAMPUS, AIRPORT BYPASS ROAD, GONDERMAU, GANDHINAGAR, BHOPAL",
  },
  {
    _id: "5f96607069fccf0d2f87498c",
    name: "AGRA PUBLIC COLLEGE OF TECH.& MANAGEMENT",
    address: "HIRA LAL KI PIYAU, ARTONI,NH-2, AGRA",
  },
  {
    _id: "5f96607069fccf0d2f874cc2",
    name: "AGRA PUBLIC PHARMACY COLLEGE OF DIPLOMA",
    address: "NH-2 HEERA LAL KI PYAU AKBARA ROAD AGRA",
  },
  {
    _id: "5f96607069fccf0d2f872890",
    name: "AGRASEN POLYTECHNIC COLLEGE",
    address: "VILLAGE AND POST-DAGANIA,TEHSIL-GUNDERDEHI,DIST-BALOD C.G.",
  },
  {
    _id: "5f96607069fccf0d2f8740b4",
    name: "AGRICULTURAL ENGINEERING COLLEGE AND RESEARCH INSTITUTE",
    address:
      "TAMIL NADU AGRICULTURAL UNIVERSITY, LAWLEY ROAD, P.O., COIMBATORE - 641 003. TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f872f6c",
    name: "AGRICULTURE AND FOOD MANAGEMENT INSTITUTE",
    address:
      "MIT, BEHIND K.R MILL, BELAVADI VILLAGE,SRIRANGAPATNA TQ, MANDYA DIST.PIN- 571438,KARNATAKA.",
  },
  {
    _id: "5f96607069fccf0d2f87307d",
    name: "AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY",
    address: "KOZHIPPARA(PO)PALAKKAD",
  },
  {
    _id: "5f96607069fccf0d2f873160",
    name: "AHALIA SCHOOL OF MANAGEMENT",
    address: "KOZHIPPARA P.O;PALAKKAD",
  },
  {
    _id: "5f96607069fccf0d2f87318c",
    name: "AHALIA SCHOOL OF PHARAMCY",
    address: "KOZHIPPARA POSTPALAKKAD",
  },
  {
    _id: "5f96607069fccf0d2f873927",
    name: "AHINSA INSTITUTE OF PHARMACY",
    address: "DHULE ROAD, DONDAICHA",
  },
  {
    _id: "5f96607069fccf0d2f8729c0",
    name: "AHMEDABAD INSTITUTE OF TECHNOLOGY",
    address: "BESIDE VASANTNAGAR TOWNSHIP GOTA OGNAJ ROADOFF GOTA CROSS ROAD",
  },
  {
    _id: "5f96607069fccf0d2f874c85",
    name: "AHSAN PHARMACY COLLEGE",
    address:
      "14. KM.MILESTONE MORADABAD DELHI HIGHWAY (N.H. 24) CHAUDHARPUR, P.O. PAKBARA, DISTT.. AMROHA",
  },
  {
    _id: "5f96607069fccf0d2f873fec",
    name: "AISHWARYA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "ERRATTAIKARAD,PARUVACHI(PO),ANTHIYURBHAVANI(TK),ERODE(DT)",
  },
  {
    _id: "5f96607069fccf0d2f873e44",
    name: "AISHWARYA COLLEGE OF MANAGEMENT & RESEARCH CENTRE",
    address: "A-9 EXT - 1KAMLA NEHRU NAGARJODHPURRAJASTHAN",
  },
  {
    _id: "5f96607069fccf0d2f873e2f",
    name: "AISHWARYA INSTITUTE OF MANAGEMENT & IT",
    address: "1-4D BLOCK, ADARSH NAGAR, UNIVERSITY ROAD",
  },
  {
    _id: "5f96607069fccf0d2f87417c",
    name: "AISHWARYA POLYTECHNIC COLLEGE",
    address: "ERRATTAIKARADU, PARUVACH(PO), BHAVANI(TK), ERODE(DT)",
  },
  {
    _id: "5f96607069fccf0d2f873891",
    name: "AISSMS COLLEGE OF PHARMACY",
    address: "KENNEDY ROAD, NEAR R.T.O., PUNE",
  },
  {
    _id: "5f96607069fccf0d2f87370f",
    name: "AISSMS INSTITUTE OF MANAGEMENT",
    address: "KENNEDY ROAD, NEAR R.T.O., PUNE- 411 001",
  },
  {
    _id: "5f96607069fccf0d2f872c3d",
    name: "AIT KANINA",
    address: "GAHARA ROAD KANINA",
  },
  {
    _id: "5f96607069fccf0d2f873a33",
    name: "AIT'S INSTITUTE OF PHARMACY, MALEGAON NASIK",
    address: "POST BOX 123, SURVEY 100, PHARMACY NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f87448f",
    name: "AIZZA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "MULKALLAH (V)MANCHERIALADILABAD (A.P) 504209",
  },
  {
    _id: "5f96607069fccf0d2f873a87",
    name: "AJAY BINAY INSTITUTE OF TECHNOLOGY , CUTTACK",
    address: "SECTOR-I,CDA , CUTTACK-753014",
  },
  {
    _id: "5f96607069fccf0d2f8746a5",
    name: "AJAY KUMAR GARG ENGINEERING COLLEGE",
    address:
      "27 KM STONE, DELHI-HAPUR BYPASS ROAD,ADHYATMIK NAGAR,GHAZIABAD, U.P",
  },
  {
    _id: "5f96607069fccf0d2f8749d5",
    name: "AJAY KUMAR GARG ENGINEERING COLLEGE -MCA",
    address:
      "27 KM STONE, DELHI-HAPUR BYPASS ROAD,ADHYATMIK NAGAR,GHAZIABAD,U.P",
  },
  {
    _id: "5f96607069fccf0d2f874933",
    name: "AJAY KUMAR GARG INSTITUTE OF MANAGEMENT",
    address:
      "AJAY KUMAR GARG INSTITUTE OF MANAGEMENT27 KM STONE, NH 24, DELHI-HAPUR BYPASS ROAD,P.O. ADHYATMIK NAGAR, GHAZIABAD-201009",
  },
  {
    _id: "5f96607069fccf0d2f87360d",
    name: "AJITDADA PAWAR COLLEGE OF POLYTECHNIC",
    address:
      "SURVEY NO. 3&6/2, SHRIRAMPUR - NEWASA ROAD, A/P: WADALA MAHADEV;TAL: SHRIRAMPUR;DIST: AHMEDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874317",
    name: "AJK INSTITUTE OF MANAGEMENT",
    address: "PALAKAD MAIN ROAD,NAVAKKARAI",
  },
  {
    _id: "5f96607069fccf0d2f87380c",
    name: "AJMVP'S HKCCSM INSTITUTE OF MANAGEMENT RESEARCH AND RURAL DEVELOPMENT, AHMEDNAGAR.",
    address: "LAL TAKI ROAD, AHMEDNAGAR. DIST - AHMEDNAGAR. PIN - 414001.",
  },
  {
    _id: "5f96607069fccf0d2f873cc0",
    name: "AKAL COLLEGE OF PHARMACY AND TECHNICAL EDUCATION",
    address: "MASTUANA SAHIBTEHSIL SANGRURDISTT. SANGRURPIN CODE 148001PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f872baf",
    name: "AKASH INSTITUTE OF TECHNOLOGY",
    address: "VPO. KANINA, DISTT. MOHINDERGARH, HARYANA, PIN-123027",
  },
  {
    _id: "5f96607069fccf0d2f873e95",
    name: "AKASHDEEP COLLEGE OF PHARMACY",
    address: "SECTOR-11, AGARWAL, FARM, MANSAROVAR, JAIPUR",
  },
  {
    _id: "5f96607069fccf0d2f8737a8",
    name: "AKEMI BUSINESS SCHOOL",
    address:
      "SR. NO. 30/3/1, 30/4/A,30/4/B,BUCHADE VASTI, VILLAGE - MARUNJI, TAL - MULSHI, DIST - PUNE",
  },
  {
    _id: "5f96607069fccf0d2f87338d",
    name: "AKHIL BHARTI COLLEGE OF PHARMACY",
    address: "VILLEGE KHARPA RATIBAD BHOPAL",
  },
  {
    _id: "5f96607069fccf0d2f874b8a",
    name: "AKHILESH YADAV PHARMACY COLLEGE",
    address: "YAKOOTPUR ,POST-AKKA DILARI,MORADABAD",
  },
  {
    _id: "5f96607069fccf0d2f872b33",
    name: "AKIDO COLLEGE OF ENGINEERING",
    address: "SAHPUR- BUPANIA ROAD, VILLAGE - LOWAKHURD",
  },
  {
    _id: "5f96607069fccf0d2f872753",
    name: "AKKINENI NAGESWARA RAO COLLEGE - M.B.A. PROGRAMME",
    address: "POST BOX NO.20,GUDIVADA,KRISHNA DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f8727c9",
    name: "AKKINENI NAGESWARA RAO COLLEGE - M.C.A. PROGRAMME",
    address: "POST BOX NO.20,GUDIVADA,KRISHNA DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f873bff",
    name: "AKLIA EDUCATIONAL AND RESEARCH SOCIETY GROUP OF INSTITUTIONS",
    address: "VILLAGE AKLIA KALAN, P. O. GONIANA MANDI, BATHINDA",
  },
  {
    _id: "5f96607069fccf0d2f873c3b",
    name: "AKLIA POLYTECHNIC COLLEGE",
    address: "VILLAGE AKLIA KALAN, MANDI GONIANA,",
  },
  {
    _id: "5f96607069fccf0d2f8730f5",
    name: "AKNM GOVERNMENT POLYTECHNIC COLLEGE THIRURANGADI",
    address: "TIRURANGADIVELIMUKKU P.OCHELARIMALAPPURAM DTKERALAINDIA",
  },
  {
    _id: "5f96607069fccf0d2f87365e",
    name: "AKOLE TALUKA EDUCATION SOCIETY'S FACULTY OF POLYTECHNIC",
    address: "K G ROAD, AKOLE",
  },
  {
    _id: "5f96607069fccf0d2f87379a",
    name: "AKOLE TALUKA EDUCATION SOCIETY'S TECHNICAL CAMPUS",
    address: "AT POST AKOLE,TALUKA AKOLE,KOLHAT GHOTI ROAD,AKOLE",
  },
  {
    _id: "5f96607069fccf0d2f8749b7",
    name: "AKS MANAGEMENT COLLEGE",
    address:
      "AKS KNOWLEDGE PARK, DEVRAI KALAN,CHANDRIKA DEVI ROAD,BAKSHI-KA-TALAB,LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f874cba",
    name: "AKSH COLLEGE OF PHARMACY",
    address:
      "VILL - TAJPUR, PARGANA - BOORHPUR, TEHSIL CHANDPUR, DISTT - BIJNOR",
  },
  {
    _id: "5f96607069fccf0d2f872acc",
    name: "AKSHAR PREET INSTITUTE OF PHARMACY",
    address:
      "ADI SHANKARACHARYA SHIKSHAN VIKAS SANKUL,LAKHABAVAL ROAD,JAMNAGAR-OKHA STATE HIGHWAY,JAMNAGAR-361006",
  },
  {
    _id: "5f96607069fccf0d2f872712",
    name: "AKSHARA INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    address:
      "199/3,PENUBALA NAGAR,OPP.RAJIV GRUHA KALPA HOUSING COLONY,KOTRAMANGALAM,TIRUCHANOOR POST,TIRUPATI-517503",
  },
  {
    _id: "5f96607069fccf0d2f8744f2",
    name: "AKSHARA POLYTECHNIC COLLEGE",
    address: "PALEANNARAM VILLAGE, CHILUKURU (MD), KODADA, NALGONDA DT",
  },
  {
    _id: "5f96607069fccf0d2f8726a8",
    name: "AKSHARAA POLYTECHNIC",
    address:
      "SAI KRISHNA POLYTECHNIC, BONDAPALLI(VILLAGE AND MANDALAM), VIZIANAGARAM DISTRICT, ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f873f80",
    name: "AKSHAYA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "SF NO.112/1CPT,114/1,115/1,116/2C,BAGAVATHIPALAYAM ROAD, KINATHUKADAVU, COIMBATORE",
  },
  {
    _id: "5f96607069fccf0d2f8742c5",
    name: "AKSHAYA INSTITUTE OF MANAGEMENT STUDIES",
    address:
      "S.F.NO.209/1A, MYLERIPALAYAM,OTHAKKALMANDAPAM POST, COIMBATORE-641032",
  },
  {
    _id: "5f96607069fccf0d2f87301f",
    name: "AKSHAYA INSTITUTE OF PHARMACY",
    address:
      "OBLAPURA POST, LINGAPURA,KORATAGERE ROAD, TUMKUR KORATAGERE ROAD TUMKUR TALUK & DISTRICT ",
  },
  {
    _id: "5f96607069fccf0d2f872d42",
    name: "AKSHAYA INSTITUTE OF TECHNOLOGY",
    address:
      "LINGAPURAOBLAPURA POSTTUMKUR KORATAGERE ROADTUMKUR TALUK & DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f874ce3",
    name: "AKSHITA COLLEGE OF PHARMACY",
    address: "VILLAGE-ETMADNAGAR ALIPUR,TEHSIL-SARDHANA,MEERUT",
  },
  {
    _id: "5f96607069fccf0d2f872632",
    name: "AKULA SREERAMULU COLLEGE OF ENGINEERING",
    address: "NH-5, TETALI-534218, TANUKU, W.G.DIST, A.P.",
  },
  {
    _id: "5f96607069fccf0d2f8740f8",
    name: "AKY POLYTECHNIC COLLEGE",
    address:
      "4/254,TENKASI MAIN ROAD, OPPOSITE TO VALLAVANKOTTAI BUS STAND, TIRUNELVELI -627012",
  },
  {
    _id: "5f96607069fccf0d2f873002",
    name: "AL AMEEN COLLEGE OF PHARMACY",
    address: "NEAR LALBAGH MAIN GATE, HOSUR RO'BANGALORE -560027",
  },
  {
    _id: "5f96607069fccf0d2f8741ef",
    name: "AL AMEEN POLYTECHNIC COLLEGE",
    address: "KARUNDEVANPALAYAM,NANJAI UTHUKKULI (PO),ERODE.",
  },
  {
    _id: "5f96607069fccf0d2f873044",
    name: "AL AZHAR COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "PERUMPILLICHIRA P.O,THODUPUZHA,PIN-685605,IDUKKI (DT), KERALA STATE",
  },
  {
    _id: "5f96607069fccf0d2f8731a2",
    name: "AL AZHAR COLLEGE OF PHARMACY",
    address: "AL AZHAR CAMPUS PERUMPILLICHIRA PO, THODUPUZHA",
  },
  {
    _id: "5f96607069fccf0d2f873182",
    name: "AL SHIFA COLLEGE OF PHARMACY",
    address:
      "POONTHAVANAM (PO)KIZHATTUR PERINTALMANNAMALAPPURAM DTKERALA-679325",
  },
  {
    _id: "5f96607069fccf0d2f872afb",
    name: "AL- FALAH SCHOOL OF ENGINEERING & TECHNOLOGY",
    address: "VILLAGE DHAUJ, (FARIDABAD), HARYANA",
  },
  {
    _id: "5f96607069fccf0d2f8726a6",
    name: "AL- HUDA POLYTECHNIC",
    address:
      "YALAMAVARIDINNEVIA CHILDERNS PARK ROADNEAR CHINTHAREDDY PALEMNELLORE",
  },
  {
    _id: "5f96607069fccf0d2f87309c",
    name: "AL-AMEEN COLLEGE, KULAPPULLY, SHORANUR-2",
    address: "KULAPPULLY,SHORANUR-2PIN: 679122PALAKKAD DISTRICTKERALA",
  },
  {
    _id: "5f96607069fccf0d2f873468",
    name: "AL-AMEEN EDUCATIONAL & MEDICAL FOUNDATION'S COLLEGE OF ENGINEERING & MANAGEMENT STUDIES",
    address: "PIMPLE JAGTAP ROAD,SHARAD CAMPUS",
  },
  {
    _id: "5f96607069fccf0d2f873f02",
    name: "AL-AMEEN ENGINEERING COLLEGE",
    address:
      "KARUNDEVANPALAYAM,NANJAI UTHUKKULI (POST)ERODE,ERODE DISTRICT,PINCODE-638104TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f872f44",
    name: "AL-AMEEN INSTITUTE OF MANAGEMENT STUDIES",
    address: "HOSUR ROAD, NEAR LALBAGH MAIN GATEBANGALORE",
  },
  {
    _id: "5f96607069fccf0d2f87310c",
    name: "AL-AZHAR POLYTECHNIC COLLEGE",
    address:
      "AL-AZHAR CAMPUS,PERUMPILLICHIRA P OPIN-685605THODUPUZHAIDUKKI DTKERALA - 685605",
  },
  {
    _id: "5f96607069fccf0d2f874961",
    name: "AL-BARKAAT INSTITUTE OF MANAGEMENT STUDIES",
    address: "MANJOORGARHI, ANOOPSHAHR ROAD, ALIGARH",
  },
  {
    _id: "5f96607069fccf0d2f872cfa",
    name: "AL-KABIR POLYTECHNIC",
    address: "KABIRNAGAR, P.O. KOPALI, VIA MANGO",
  },
  {
    _id: "5f96607069fccf0d2f872e13",
    name: "AL-KHATEEB GOVERNMENT AIDED POLYTECHNIC",
    address: "BHOOPASANDRARMV II STAGEBENGALURU",
  },
  {
    _id: "5f96607069fccf0d2f87459b",
    name: "AL-QURMOSHI INSTITUTE OF BUSINESS MANAGEMENT",
    address: "# 18-11-26/7JAMAL BANDABARKASHYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f87402d",
    name: "ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "KARAIKUDI SIVAGANGA DISTRICT PIN-630004 TAMILNADU",
  },
  {
    _id: "5f96607069fccf0d2f87422a",
    name: "ALAGAPPA POLYTECHNIC COLLEGE, KARAIKUDI",
    address: "PRINCIPAL,ALAGAPPA POLYTECHNIC COLLEGE",
  },
  {
    _id: "5f96607069fccf0d2f873442",
    name: "ALARD COLLEGE OF ENGINEERING & MANAGEMENT",
    address:
      "SURVAY NO. 50, NEAR RAJEEV GANDHI INFOTECH PARK, PHASE II(HINJEWADI), MARUNJE, PUNE - 411057",
  },
  {
    _id: "5f96607069fccf0d2f87390b",
    name: "ALARD COLLEGE OF PHARMACY",
    address:
      "S.NO. 50,NEAR RAJIV GANDHI INFOTECH PARK,VILLAGE MARUNJE,TAEHSIL MULSHI,DISTRICT PUNE",
  },
  {
    _id: "5f96607069fccf0d2f8737e5",
    name: "ALARD INSTITUTE OF MANAGEMENT SCIENCES",
    address: "S.NO.50, MARUNJE,RAJIV GANDHI INFOECH PARK,PUNE 411 057, INDIA",
  },
  {
    _id: "5f96607069fccf0d2f873135",
    name: "ALBERTIAN INSTITUTE OF MANAGEMENT",
    address: "BANERJI ROADERNAKULAM",
  },
  {
    _id: "5f96607069fccf0d2f87306b",
    name: "ALBERTIAN INSTITUTE OF SCIENCE AND TECHNOLOGY (AISAT)- TECHNICAL CAMPUS",
    address:
      "ARCHBISHOP ANGEL MARY NAGAR,COCHIN UNIVERSITY P. O.,KALAMASSERY - KOCHI-682022,KERALA.",
  },
  {
    _id: "5f96607069fccf0d2f8738d4",
    name: "ALI-ALLANA COLLLEGE OF PHARMACY",
    address: "MOLGI RAOD AKKALKUWA DIST. NANDUBAR-425415",
  },
  {
    _id: "5f96607069fccf0d2f8732d3",
    name: "ALIA INSTITUTE OF MANAGEMENT",
    address: "KHASRA NO-134,VILL-SIKANDRABAD,TEH-HUZUR,POST-RATIBAD",
  },
  {
    _id: "5f96607069fccf0d2f874df1",
    name: "ALIAH UNIVERSITY",
    address: "IIA/27, NEW TOWN",
  },
  {
    _id: "5f96607069fccf0d2f8746ce",
    name: "ALIGARH COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "3 KM FROM SASNI GATE,AT ALIGARH-MATHURA ROAD,ALIGARH.",
  },
  {
    _id: "5f96607069fccf0d2f874aea",
    name: "ALIGARH COLLEGE OF PHARMACY",
    address: "3 KM FROM SASNI GATE,AT ALIGARH-MATHURA ROAD,ALIGARH.",
  },
  {
    _id: "5f96607069fccf0d2f873750",
    name: "ALKESH DINESH MODY INSTITUTE FOR FINANCIAL AND MANAGEMENT STUDIES",
    address:
      "JORAVAR BHAVAN,OPP. NEW BOYS HOSTEL,MUMBAI UNIVERSITY,KALINA VIDYANAGARI CAMPUS,SANTACRUZ(E)",
  },
  {
    _id: "5f96607069fccf0d2f873513",
    name: "ALL INDIA SHRI SHIVAJI MEMORIAL SOCIETY'S COLLEGE OF ENGINEERING, PUNE-1",
    address: "KENNEDY ROAD, NEAR RTO, PUNE-411001.",
  },
  {
    _id: "5f96607069fccf0d2f873529",
    name: "ALL INDIA SHRI SHIVAJI MEMORIAL SOCIETY'S INSTITUTE OF INFORMATION TECHNOLOGY",
    address: "KENNEDY ROAD, NEAR R.T.O, PUNE- 411 001",
  },
  {
    _id: "5f96607069fccf0d2f8736ee",
    name: "ALL INDIA SHRI SHIVAJI MEMORIAL SOCIETY'S, COLLEGE OF HOTEL MANAGEMENT & CATERING TECHNOLOGY",
    address: "55-56, SHIVAJINAGAR",
  },
  {
    _id: "5f96607069fccf0d2f8735cb",
    name: "ALL INDIA SHRI SHIVAJI MEMORIAL SOCIETYS POLYTECHNIC",
    address: "KENNEDY ROAD, NEAR RTO,PUNE-411001",
  },
  {
    _id: "5f96607069fccf0d2f87279c",
    name: "ALL SAINTS P.G. COLLEGE",
    address: "SINGANNABANDAMAHARAJUPETA JN.BHEEMUNIPATNAM MANDALVISAKHAPATNAM",
  },
  {
    _id: "5f96607069fccf0d2f87326a",
    name: "ALL SAINTS' COLLEGE OF ENGINEERING",
    address: "NEW PIPALNAIR, NEARGANDHI NAGAR-462031",
  },
  {
    _id: "5f96607069fccf0d2f87320f",
    name: "ALL SAINTS' COLLEGE OF TECHNOLOGY",
    address: "NH 12 GANDHI NAGAR BHOPAL",
  },
  {
    _id: "5f96607069fccf0d2f872795",
    name: "ALLAGADDA INSTITUTE OF MANAGEMENT SCIENCE",
    address: "KANDUKURIMETTA,ALLAGADDA,KURNOOL(DIST),ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874860",
    name: "ALLAHABAD COLLEGE OF ENGINEERING AND MANAGEMENT",
    address:
      "G.T ROAD (123 K.M FROM KANPUR/77 K.M FROM ALLAHABAD) PURIEN MOD. KHAGA",
  },
  {
    _id: "5f96607069fccf0d2f8737c9",
    name: "ALLANA INSTITUTE OF MANAGEMENT SCIENCES, PUNE",
    address: "2390 B, KB HIDAYTULLAH ROAD, AZAM CAMPUS, CAMP,",
  },
  {
    _id: "5f96607069fccf0d2f87472b",
    name: "ALLENHOUSE INSTITUTE OF TECHNOLOGY",
    address: "PLOT NO. 176, ROOMA,KULGAON ROAD,KANPUR",
  },
  {
    _id: "5f96607069fccf0d2f872f5b",
    name: "ALLUM KARIBASAPPA INSTITUTE OF MANAGEMENT",
    address:
      "V.V SANGHA'S VIJAYANAGAR INSTITUTE OF MANAGEMENT, V.S. CAMPUS, AIRPORT ROAD, CANTONMENT, BELLARY-583104",
  },
  {
    _id: "5f96607069fccf0d2f874546",
    name: "ALLURI INSTITUTE OF MANAGEMENT SCIENCES",
    address: "# 23-6-79/5, HUNTER ROAD, HANAMKONDA, WARANGAL",
  },
  {
    _id: "5f96607069fccf0d2f872d64",
    name: "ALPHA COLLEGE OF ENGINEERING",
    address: "DODDAGUBBI POST,HENNUR-BAGALUR ROAD,BANGALORE-560077.",
  },
  {
    _id: "5f96607069fccf0d2f873f7b",
    name: "ALPHA COLLEGE OF ENGINEERING",
    address: "NO.34, UDAYAVAR KOIL STREET,THIRUMAZHISAI,CHENNAI - 600124.",
  },
  {
    _id: "5f96607069fccf0d2f8729ab",
    name: "ALPHA COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "B/S. LINCON POLYMERS, VIA. VADSAR, VILLAGE - KHATRAJ, TALUKA - KALOL, GANDHINAGAR - 382721",
  },
  {
    _id: "5f96607069fccf0d2f873b9a",
    name: "ALPHA COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "KANNIAKOIL TO BAHOUR MAIN ROADBAHOUR - 607 402",
  },
  {
    _id: "5f96607069fccf0d2f874d45",
    name: "ALPINE COLLEGE OF MANAGEMENT &TECHNOLOGY",
    address: "NANDA KI CHOWKI ,PREMNAGAR,DEHRADUN-248007",
  },
  {
    _id: "5f96607069fccf0d2f87323f",
    name: "ALPINE INSTITUTE OF TECHNOLOGY",
    address: "GRAM: CHANDESARA, DEWAS ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872dce",
    name: "ALVA'S INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address:
      "SHOBHAVANA CAMPUS,MIJARMOODBIDRI - 574225MANGALORE TALUKDHAKSHINA KANNADA DISTRICTKARNATAKA STATE",
  },
  {
    _id: "5f96607069fccf0d2f873e6d",
    name: "ALWAR PHARMACY COLLEGE",
    address: "NORTH EXTENSION, M.I.A., ALWAR (RAJASTHAN) 301030",
  },
  {
    _id: "5f96607069fccf0d2f8727a7",
    name: "ALWAR SCHOOL OF BUSINESS AND COMPUTERS",
    address: "HIMACHAL NAGAR, MULAGADA, GAJUWAKA, VISAKHAPATNAM - 530026",
  },
  {
    _id: "5f96607069fccf0d2f8726a7",
    name: "ALWARDAS POLYTECHNIC",
    address: "124 PART, VEPAGUNTA, RRVPURAM, GOPALAPATNAM, VISAKHAPATNAM",
  },
  {
    _id: "5f96607069fccf0d2f8730a4",
    name: "AMAL JYOTHI COLLEGE OF ENGINEERING",
    address: "KOOVAPPALLY P.OKOTTAYAM DISTRICTKERALA - 686 518",
  },
  {
    _id: "5f96607069fccf0d2f8725b3",
    name: "AMALAPURAM INSTITUTE OF MANAGEMENT SCIENCES & COLLEGE OF ENGINEERING.",
    address:
      "NH-214 ROAD,MUMMIDIVARAM,EAST GODAVARI DIST,ANDHRA PRADESH,PIN-533216.",
  },
  {
    _id: "5f96607069fccf0d2f873ced",
    name: "AMAN BHALLA COLLEGE OF PHARMACY",
    address: "KOTLI,PATHANKOT-AMRITSAR HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f873bab",
    name: "AMAN BHALLA INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address:
      "JAMMU AMRITSAR HIGHWAY, CANAL SIDEKOTLI, PO: JHAKOLARITEHSIL: PATHANKOT-145025",
  },
  {
    _id: "5f96607069fccf0d2f873c2f",
    name: "AMAN BHALLA POLYTECHNIC COLLEGE",
    address:
      "JAMMU-AMRITSAR HIGHWAY, NEAR CANAL SIDE, KOTLI PO: JHAKOLARI, PATHANKOT",
  },
  {
    _id: "5f96607069fccf0d2f873c88",
    name: "AMAN BHALLAINSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    address: "KOTLI,JAMMU-AMRITSAR HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f873e69",
    name: "AMAN PHARMACY COLLEGE",
    address: "DHOLAKHEDA",
  },
  {
    _id: "5f96607069fccf0d2f874713",
    name: "AMANI GROUP OF INSTITUTIONS",
    address: "BIJNOR ROAD AMROHA",
  },
  {
    _id: "5f96607069fccf0d2f873cc5",
    name: "AMAR SHAHEED BABA AJIT SINGH JUJHAR SINGH MEMORIAL COLLEGE OF PHARMACY",
    address: "V.P.O BELA",
  },
  {
    _id: "5f96607069fccf0d2f873d00",
    name: "AMAR SHAHEED BABA AJIT SINGH JUJHAR SINGH MEMORIAL DIPLOMA COLLEGE OF PHARMACY",
    address: "VPO-BELATEH- SRI CHAMKAUR SAHIB",
  },
  {
    _id: "5f96607069fccf0d2f874bba",
    name: "AMAR SHAHID KANCHAN SINGH MAHAVIDYALYA",
    address: "VILL-SHIVPURI POST-KHAKHRERU DISST.-FATEHPUR",
  },
  {
    _id: "5f96607069fccf0d2f8746f1",
    name: "AMARDEEP COLLEGE OF ENGINEERING & MANAGEMENT",
    address: "7TH KM. STONE JALESAR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f874a32",
    name: "AMARDEEP COLLEGE OF PHARMACY",
    address: "JALESAR ROAD , FIROZABAD, DISTT- FIROZABAD. PIN-283203",
  },
  {
    _id: "5f96607069fccf0d2f874874",
    name: "AMARDEEP POLYTECHNIC COLLEGE",
    address: "VILL-KAKRAU, JALESAR ROAD, POST- FIROZABAD",
  },
  {
    _id: "5f96607069fccf0d2f87397b",
    name: "AMBADASRAO WARPUDKAR INSTITUTE OF PHARMACY",
    address: "AT WARPUD POST MIRKHEL TQ.PARBHANI DIST.PARBHANI431401",
  },
  {
    _id: "5f96607069fccf0d2f874038",
    name: "AMBAL PROFESSIONAL GROUP OF INSTITUTIONS",
    address: "SFNO 166 , 167 PROFESSIONAL NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872aed",
    name: "AMBALA COLLEGE OF ENGINEERING & APPLIED RESEARCH",
    address: "DEVSTHALI (NEAR MITHAPUR), PO SAMBHALKHA, AMBALA",
  },
  {
    _id: "5f96607069fccf0d2f8746b0",
    name: "AMBALIKA INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    address: "GRAM TIKRA, MAURAWAN ROAD,MOHANLALGANJ . LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f874875",
    name: "AMBALIKA INSTITUTE OF PROFESSIONAL STUDIES",
    address: "GRAM TIKRA, MAURAWAN ROAD, MOHANLALGANJ,LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f872901",
    name: "AMBEDKAR INSTITUTE OF ADVANCED COMMUNICATION TECHNOLOGIES AND RESEARCH",
    address: "GEETA COLONY, DELHI - 110 031",
  },
  {
    _id: "5f96607069fccf0d2f874cf1",
    name: "AMBEDKAR INSTITUTE OF PHARMACY",
    address: "VILLAGE ETAWAN RIWA ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872912",
    name: "AMBEDKAR INSTITUTE OF TECHNOLOGY",
    address: "OPP MADHUBANSHAKARPURDELHI-110092",
  },
  {
    _id: "5f96607069fccf0d2f874a6e",
    name: "AMBEKESHWAR INSTITUTE OF PHARMACEUTICAL SCIENCES",
    address: "CHANDRIKA DEVI ROAD, KATHWARA, BAKSI KA TALAB, LUCKNOW (U. P.)",
  },
  {
    _id: "5f96607069fccf0d2f8747a2",
    name: "AMBEKESHWAR INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "VILL-KATHWARA, POST-BAKSHI KA TALAB, DISTT.-LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f8733f8",
    name: "AMBIKA INSTITUTE OF PHARMACY",
    address: "A.B.ROAD NEAR KATTHAMILL NOHRI SHIVPURI PIN 473551",
  },
  {
    _id: "5f96607069fccf0d2f87481d",
    name: "AMBITION INSTITUTE OF TECHNOLOGY",
    address: "G.T. ROAD, PARAO,NEAR PUNEET AUTOMOBILES",
  },
  {
    _id: "5f96607069fccf0d2f872f1e",
    name: "AMC COLLEGE (ADMINISTRATIVE MANAGEMENT COLLEGE)",
    address: "18KM, BANNERGHATTA ROAD, KALKERE",
  },
  {
    _id: "5f96607069fccf0d2f872f3b",
    name: "AMC ENGINEERING COLLEGE",
    address: "18KM, BANNERAGHATTA ROAD,KALKERE, BANGALORE -560 083.",
  },
  {
    _id: "5f96607069fccf0d2f874ba9",
    name: "AMEER HASAN COLLEGE OF PHARMACY",
    address: "NANPARA DEHATI, IMAM GANJ ROAD, BAHRAICH",
  },
  {
    _id: "5f96607069fccf0d2f874434",
    name: "AMINA INSTITUTE OF TECHNOLOGY",
    address: "GHOUSE NAGAR, BABGUDA,ROAD SHAMIRPET, RR. DIST. A.P",
  },
  {
    _id: "5f96607069fccf0d2f872973",
    name: "AMIRAJ COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "AT VILLAGE: KHORAJ NR. TATA-NENO PLANT, SARKHEJ-VIRAMGAM HIGHWAY TA.: SANAND DIST. : AHMEDABAD PINCODE:382170",
  },
  {
    _id: "5f96607069fccf0d2f8746db",
    name: "AMITY SCHOOL OF ENGINEERING & TECHNOLOGY",
    address: "PLOT NO. 4, SECTOR 125, NOIDA., U.P.",
  },
  {
    _id: "5f96607069fccf0d2f874505",
    name: "AMJAD ALI KHAN COLLEGE OF BUSINESS ADMINISTRATION",
    address: "8-2-249, \\MOUNT PLEASANT\\ROAD NO.3, BANJARA HILLSHYDERABAD.",
  },
  {
    _id: "5f96607069fccf0d2f8741d0",
    name: "AMMAIAPPAR POLYTECHNIC COLLEGE",
    address: "PUTHUR- MEENATCHIYAPURAM ROAD,MEENATCHIYAPURAM",
  },
  {
    _id: "5f96607069fccf0d2f873028",
    name: "AMMINI COLLEGE OF ENGINEERING",
    address: "KANNAMPARIYARAMPOST MANKARA",
  },
  {
    _id: "5f96607069fccf0d2f873402",
    name: "AMPLE DREAMS INSTITUTE OF EDUCATION PHARMACY",
    address: "VILLAGE UDPURA, SEHORE",
  },
  {
    _id: "5f96607069fccf0d2f874d70",
    name: "AMRAPALI INSTITUTE OF HOTEL MANAGEMENT",
    address: "SKIKSHA NAGAR,LAMACHAUR,HALDWANI,NAINITALUTTARAKHAND263139",
  },
  {
    _id: "5f96607069fccf0d2f874d8e",
    name: "AMRAPALI INSTITUTE OF PHARMACY AND SCIENCES",
    address: "SHIKSHA NAGAR, LAMACHAUR",
  },
  {
    _id: "5f96607069fccf0d2f874cf6",
    name: "AMRAPALI INSTITUTE OF TECHNOLOGY AND SCIENCES",
    address: "SIKSHA NAGAR, LAMACHAUR",
  },
  {
    _id: "5f96607069fccf0d2f873b33",
    name: "AMRAVATI POLYTECHNICH",
    address: "AT/PO/PS-RAIRANG PUR",
  },
  {
    _id: "5f96607069fccf0d2f873f61",
    name: "AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "SUN NAGAR,ERACHAKULAM POST,NAGERCOIL,PIN-629902",
  },
  {
    _id: "5f96607069fccf0d2f874171",
    name: "AMRITA POLYTECHNIC COLLEGE",
    address: "SUN NAGARERACHAKULAM",
  },
  {
    _id: "5f96607069fccf0d2f87263c",
    name: "AMRITA SAI INSTITUTE OF SCIENCE AND TECHNOLOGY",
    address:
      "AMRITA SAI NAGAR, PARITALA POST,KANCHIKACHERLA MANDAL,KRISHNA DIST,A.P. - 521180, INDIA.",
  },
  {
    _id: "5f96607069fccf0d2f873fdf",
    name: "AMRITA SCHOOL OF ENGINEERING",
    address: "337/1A, VENGAL VILLAGE THIRUVALLUR TALUK ",
  },
  {
    _id: "5f96607069fccf0d2f873026",
    name: "AMRITA VISHWA VIDYAPEETHAM AMRITAPURI CAMPUS",
    address: "CLAPPANA P O, KARUNAGAPALLY, KOLLAM, KERALA. INDIA",
  },
  {
    _id: "5f96607069fccf0d2f872d3b",
    name: "AMRITA VISHWA VIDYAPEETHAM BENGALURU CAMPUS",
    address: "KASAVANAHALLI, CARMELARAM POST, OFF SARJAPUR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f873f8e",
    name: "AMRITA VISHWA VIDYAPEETHAM COIMBATORE CAMPUS",
    address: "AMRITA NAGAR POST, ETTIMADAI COIMBATORE, TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f872fb9",
    name: "AMRITA VISHWA VIDYAPEETHAM MYSURU CAMPUS",
    address: "114 7TH CROSS BOGADI 2ND STAGE MYSURU, KARNATAKA. INDIA",
  },
  {
    _id: "5f96607069fccf0d2f8730bf",
    name: "AMRITA VISHWA VIDYAPEETHAM. KOCHI CAMPUS",
    address:
      "AMRITA VISHWA VIDYAPEETHAM, AIMS PONEKKARA PO KOCHI 682041 KERALA",
  },
  {
    _id: "5f96607069fccf0d2f873c08",
    name: "AMRITSAR COLLEGE OF ENGINEERING & TECHNOLOGY, AMRITSAR",
    address: "12KM STONE, AMRITSAR JALANDHAR GT ROAD, ROAD, AMRITSAR",
  },
  {
    _id: "5f96607069fccf0d2f873cdb",
    name: "AMRITSAR PHARMACY COLLEGE",
    address:
      "C/O AMRITSAR COLLEGE OF ENGINEERING AND TECHNOLOGY, 12KM STONE AMRITSAR JALANDHAR GT ROAD",
  },
  {
    _id: "5f96607069fccf0d2f8729da",
    name: "AMRUT INSTITUTE JUNAGADH",
    address: "KHADILYA - BILKHA ROAD,TA./DIST. JUNAGADH,",
  },
  {
    _id: "5f96607069fccf0d2f872da4",
    name: "AMRUTA INSTITUTE OF ENGINEERING AND MANAGEMENT SCIENCES",
    address:
      "BIDADI INDUSTRIAL AREA, OFF MYSORE ROAD, NEAR TOYOTA KIRLOSKAR MOTORS, BMRDA REGION, BANGALORE",
  },
  {
    _id: "5f96607069fccf0d2f8734bf",
    name: "AMRUTVAHINI COLLEGE OF ENGINEERING, SANGAMNER",
    address: "AMRUTNAGAR, GHULWADI, SANGAMNER , DIST - AHMEDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f873861",
    name: "AMRUTVAHINI COLLEGE OF PHARMACY, SANGAMNER",
    address: "AMRUTNAGAR, P.O.SANGAMNER S.K.",
  },
  {
    _id: "5f96607069fccf0d2f8737e4",
    name: "AMRUTVAHINI INSTITUTE OF MANAGEMENT & BUSINESS ADMINSTRAION",
    address: "AMRUTNAGAR,",
  },
  {
    _id: "5f96607069fccf0d2f873a32",
    name: "AMRUTVAHINI INSTITUTE OF PHARMACY",
    address:
      "AT/POST-SANGAMNER (S.K.)GHULEWADI,TAL-SANGAMNER,DIST-AHMEDNAGAR,PIN-4226008",
  },
  {
    _id: "5f96607069fccf0d2f87361e",
    name: "AMRUTVAHINI POLYTECHNIC",
    address: "AMRUTNAGAR,P.O. SANGAMNER S.K.TAL: SANGAMNERDIST: AHMEDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874e25",
    name: "AMS COLLEGE OF POLYTECHNIC",
    address:
      "CAMPUS - RANGAPUR, P.O. - NILGUNJ BAZAR, BARASAT, KOLKATA - 700121",
  },
  {
    _id: "5f96607069fccf0d2f874a9c",
    name: "ANA INSTITUTE OF PHARMACEUTICAL SCIENCES AND RESEARCH",
    address: "13.5 KM BAREILLY DELHI HIGHWAY ,NEAR RUBBER FACTORY ,AGRAS ROAD",
  },
  {
    _id: "5f96607069fccf0d2f87254c",
    name: "ANAKAPALLE MERCHANTS ASSOCIATION KONATHALA SUBRAHMANYAM & IMMEDISETTY KANAKARAJU COLLEGE OF TECHNOLOGY AND MANAGEMENT",
    address:
      "KOTHURU VILLAGEANAKAPALLE MANDALANAKAPALLEVISAKHA DIST.ANDHRA PRADESH531001",
  },
  {
    _id: "5f96607069fccf0d2f87360f",
    name: "ANAND CHARITABLE SANSTA'S DIPLOMA IN ENGINEERING & TECHNOLOGY",
    address: "GANGAI NAGAR, MURSHADPUR, TQ-ASHTI,DIST-BEED.",
  },
  {
    _id: "5f96607069fccf0d2f873935",
    name: "ANAND CHARITABLE SANSTHA'S COLLEGE OF PHARMACEUTICAL SCIENCE &RESEARCH ,ASHTI",
    address: "A/POST:GANGAI NAGAR MURSADPUR,ASHTI TAL-ASHTI DIST-BEED 414203",
  },
  {
    _id: "5f96607069fccf0d2f8739bf",
    name: "ANAND CHARITABLE SANSTHA'S GANGAI PHARMACY COLLEGE ,KADA",
    address:
      "A/P:NEAR ANANDRAO DHONDE MAHAVIDALYA ,KADA TQ-ASHTI DIST;BEED 414202",
  },
  {
    _id: "5f96607069fccf0d2f8739c6",
    name: "ANAND CHARITABLE SANTHA'S COLLEGE OF PHARMACY, ASHTI",
    address: "GANGAI NAGAR,MAGRUL ROAD, MURSHADPUR-ASHTI TQ- ASHTI DIST-BEED",
  },
  {
    _id: "5f96607069fccf0d2f874edc",
    name: "ANAND COLLEGE OF EDUCATION",
    address: "KABILPUR, DEBRA, PASCHIM MEDINIPUR, WEST BENGAL,721126",
  },
  {
    _id: "5f96607069fccf0d2f873bd7",
    name: "ANAND COLLEGE OF ENGINEERING & MANAGEMENT",
    address: "OPPOSITE RAIL COACH FACTORY, SULTANPUR ROAD, KAPURTHALA",
  },
  {
    _id: "5f96607069fccf0d2f874ad5",
    name: "ANAND COLLEGE OF PHARMACY",
    address: "KEETHAM AGRA",
  },
  {
    _id: "5f96607069fccf0d2f874710",
    name: "ANAND ENGINEERING COLLEGE",
    address: "N. H. 2, AGRA - MATHURA HIGHWAY, KEETHAM",
  },
  {
    _id: "5f96607069fccf0d2f872a93",
    name: "ANAND INSITUTE OF INFORMATION SCIENCE",
    address: "SHRI RAMKRISHNA SEVA MANDAL CAMPUS,OPP. TOWN HALL",
  },
  {
    _id: "5f96607069fccf0d2f873f72",
    name: "ANAND INSTITUTE OF HIGHER TECHNOLOGY",
    address:
      "KALASALINGAM NAGAR, OLD MAHABALIPURAM ROAD,KAZHIPATTUR VILLAGE, CHENGALPATTU TALUKKANCHEEPURAM DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f872a76",
    name: "ANAND INSTITUTE OF MANAGAMENT",
    address: "SHRI RKSM CAMPUS, OPP. TOWN HALL,",
  },
  {
    _id: "5f96607069fccf0d2f873311",
    name: "ANAND INSTITUTE OF MANAGEMENT",
    address: "BARKHEDA NATHU, NEELBAD, BHOPAL",
  },
  {
    _id: "5f96607069fccf0d2f873d99",
    name: "ANAND INTERNATIONAL COLLEGE OF ENGINEERING",
    address: "814, KANOTA, AGRA ROAD,",
  },
  {
    _id: "5f96607069fccf0d2f872aaf",
    name: "ANAND PHARMACY COLLEGE",
    address: "OPP. TOWN HALLANAND.388001GUJARAT INDIA",
  },
  {
    _id: "5f96607069fccf0d2f8738df",
    name: "ANANDI PHARMACY COLLEGE, KALAMBE TARF KALE",
    address: "AT- KALAMBE TARF KALE POST- KOPARDE TAL- KARVEER DIST- KOLHAPUR",
  },
  {
    _id: "5f96607069fccf0d2f8732ee",
    name: "ANANT INSTITUTE OF BUSINESS STUDIES",
    address: "VILLAGE RATIKHEDA",
  },
  {
    _id: "5f96607069fccf0d2f8725d9",
    name: "ANANTHA LAKSHMI INSTITUTE OF TECHNOLOGY & SCIENCES",
    address: "ITIKAKAPALLI NEAR SK UNIVERSITYITIKALAPALLI,ANANTAPUR",
  },
  {
    _id: "5f96607069fccf0d2f87352e",
    name: "ANANTRAO PAWAR COLLEGE OF ENGINEERING & RESEARCH",
    address: "S.NO. 103 PARVATI RAMANA, SHAHU COLLEGE ROAD, PUNE 411009",
  },
  {
    _id: "5f96607069fccf0d2f87256b",
    name: "ANDHRA ENGINEERING COLLEGE",
    address: "NELLOREPALEM-VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f87277b",
    name: "ANDHRA LOYOLA COLLEGE",
    address: "GOVT.POLYTECHNIC POST OFFICEBYE PASS ROADVIJAYAWADA",
  },
  {
    _id: "5f96607069fccf0d2f8725da",
    name: "ANDHRA LOYOLA INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address:
      "GOVERNMENT POLYTECHNIC POST OFFICE,VIJAYAWADA-520008,KRISHNA DISTRICT,ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f87452d",
    name: "ANDHRA MAHILA SABHA SCHOOL OF INFORMATICS",
    address:
      "DURGABAI DESHMUKH ACADEMIC CAMPUS, OSMANIA UNIVERSITY ROAD,HYDERABAD-500007. ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f8726cb",
    name: "ANDHRA POLYTECHNIC",
    address: "YANAM ROADJAGANNAICK PUR",
  },
  {
    _id: "5f96607069fccf0d2f872542",
    name: "ANDHRA UNIVERSITY COLLEGE OF ENGINEERING",
    address: "ANDHRA UNIVERSITY, WALTAIR",
  },
  {
    _id: "5f96607069fccf0d2f87375a",
    name: "ANEKANT INSTITITE OF MANAGEMENT STUDIES",
    address: "ANEKANT EDUCATION SOCIETY CAMPUS, BARAMATI, DIST.PUNE",
  },
  {
    _id: "5f96607069fccf0d2f873cf9",
    name: "ANGAD PHARMACY COLLEGE",
    address: "VPO MAUJGARH TEHSIL ABOHAR DISTT. FAZILKA PUNJAB 152132",
  },
  {
    _id: "5f96607069fccf0d2f872dad",
    name: "ANGADI INSTITTUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "SAVAGAON ROAD BELGAUM",
  },
  {
    _id: "5f96607069fccf0d2f873f68",
    name: "ANGEL COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "ANGEL COLLEGE OF ENGINEERING AND TECHNOLOGYP.K. PALAYAM, UGAYANUR VILLAGE, DHARAPURAM MAIN ROAD, TIRUPUR",
  },
  {
    _id: "5f96607069fccf0d2f87426f",
    name: "ANGEL POLYTECHNIC COLLEGE",
    address: "R.S.NO.570TIRUNELVELI ROADSOUTH VENGANALLUR RAJAPALAYAM TALUK",
  },
  {
    _id: "5f96607069fccf0d2f8725d7",
    name: "ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES",
    address:
      "SANGIVALASABHEEMUNIPATNAM MANDALVISAKHAPATANAM 531 162TEL: 08933-225083, 84 & 85FAX: 08933-226395WEBSITE: WWW.ANITS.EDU.IN E-MAIL: PRINCIPAL@ANITS.COM",
  },
  {
    _id: "5f96607069fccf0d2f873f39",
    name: "ANJALAI AMMAL MAHALINGAM ENGINEERING COLLEGE",
    address: "ANJALAI AMMAL MAHALINGAM ENGG COLLEGE KOVILVENNI",
  },
  {
    _id: "5f96607069fccf0d2f874369",
    name: "ANJALAI AMMAL-MAHALINGAM ENGINEERING COLLEGE KOVILVENNI",
    address:
      "ANJALAI AMMAL-MAHALINGAM ENGINEERING COLLEGEKOVILVENNI-614 403THIRUVARUR DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f874b01",
    name: "ANJALI COLLEGE OF PHARMACY & SCIENCE",
    address: "AGRA-FIROZABAD ROAD, N.H.-2 ETEMADPUR,AGRA",
  },
  {
    _id: "5f96607069fccf0d2f8747a9",
    name: "ANJANA INSTITUTE OF TECHNICAL EDUCATION",
    address: "VILL-SANKROULI,MADUPUR POST-AJGARA,PRATAPGARH",
  },
  {
    _id: "5f96607069fccf0d2f87366a",
    name: "ANJANYA POLYTECHNIC COLLEGE",
    address:
      "KHASARA NO 165/2 , 165/2/1,165/3/1 MAUZA KHAIRLANGI TASHIL. TUSAMAR DISTRIC : BHANDARA",
  },
  {
    _id: "5f96607069fccf0d2f874bfe",
    name: "ANJUMAN A ASHRAF PHARMACY COLLEGE",
    address:
      "VILL- BASI SAHSOLI POST - SIHALI JAGIR TEHSIL- HASANPUR DISTT - AMROHA ",
  },
  {
    _id: "5f96607069fccf0d2f87355b",
    name: "ANJUMAN COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "MANGALWARI BAZAR,SADAR, NAGPUR",
  },
  {
    _id: "5f96607069fccf0d2f872d4d",
    name: "ANJUMAN INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "ANJUMANABAD,BHATKAL(U K DIST), KARNATAKAPIN 581320",
  },
  {
    _id: "5f96607069fccf0d2f872e4d",
    name: "ANJUMAN POLYTECHNIC",
    address: "IDGAH MAIDAN,AYODHYA NAGAROLD HUBLI",
  },
  {
    _id: "5f96607069fccf0d2f873601",
    name: "ANJUMAN POLYTECHNIC",
    address: "MANGALWARI BAZAR ROAD, SADAR, NAGPUR.",
  },
  {
    _id: "5f96607069fccf0d2f872e55",
    name: "ANJUMAN-E-ISLAM POLYTECHNIC GADAG",
    address: "BANGALORE ROAD,NEAR MALLASAMUDRA",
  },
  {
    _id: "5f96607069fccf0d2f873566",
    name: "ANJUMAN-I-ISLAM'S KALSEKAR TECHNICAL CAMPUS",
    address: "PLOT NOS. 2 & 3, SECTOR 16, KHANDAGAON, NEAR THANA NAKA",
  },
  {
    _id: "5f96607069fccf0d2f8734ea",
    name: "ANJUMAN-I-ISLAM'S M. H. SABOO SIDDIK COLLEGE OF ENGINEERING",
    address: "8, SABOO SIDDIK POLYTECHNIC ROAD, BYCULLA, MUMBAI",
  },
  {
    _id: "5f96607069fccf0d2f8737e7",
    name: "ANJUMAN-I-ISLAM'S, ALLANA INSTITUTE OF MANAGEMENT STUDIES",
    address:
      "BADRUDDIN TYABJI MARG,OFF.92, DR. D.N. ROAD, OPP. CST STN, MUMBAI- 400 001.",
  },
  {
    _id: "5f96607069fccf0d2f8749fd",
    name: "ANKERITE COLLEGE OF PHARMACY",
    address:
      "VILLAGE-SHUSHEEL KHERA, PARVAR PURAB,TEHSIL: MOHANLALGANJ, DIST: LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f873fc6",
    name: "ANNA UNIVERSITY",
    address: "SARDAR PATEL ROAD CHENNAI-25 TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f873eb2",
    name: "ANNA UNIVERSITY REGIONAL CAMPUS COIMBATORE",
    address: "NAVAVOOR, MARUDHAMALAI ROAD, SOMAYAMPALAYAM, COIMBATORE-641046",
  },
  {
    _id: "5f96607069fccf0d2f873ebc",
    name: "ANNA UNIVERSITY REGIONAL CAMPUS MADURAI",
    address: "KANIYAKUMARI NATIONAL HIGHWAYS, KEELAKUYILKUDI , MADURAI",
  },
  {
    _id: "5f96607069fccf0d2f8742a8",
    name: "ANNAI COLLEGE OF ARTS & SCIENCE",
    address: "ANAKUDI ROAD, KOVILACHERI, KUMBAKONAM,612503",
  },
  {
    _id: "5f96607069fccf0d2f874338",
    name: "ANNAI COLLEGE OF ARTS AND SCIENCE",
    address: "KOVILACHERY, KUMBAKONAM - 612503,THANJAVUR DISTRICT,TAMIL NADU.",
  },
  {
    _id: "5f96607069fccf0d2f874015",
    name: "ANNAI COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "ANAKUDI ROAD,KOVILACHERI,KUMBAKONAM,THANJAVUR - DISTRICT,TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f8741c5",
    name: "ANNAI COLLEGE OF POLYTECHNIC,",
    address: "ANAKUDI ROAD,KOVILACHERI, KUMBAKONAM",
  },
  {
    _id: "5f96607069fccf0d2f874102",
    name: "ANNAI J.K.K.SAMPOORANI AMMAL POLYTECHNIC COLLEGE",
    address: "THOOKKANAICKENPALAYAM POST, GOBICHETTIPALAYAM TALUK.",
  },
  {
    _id: "5f96607069fccf0d2f874248",
    name: "ANNAI MADHA POLYTECHNIC COLLEGE",
    address: "EDAICHERUVAI,AKKANUR(PO),TITTAGUDI(TK)",
  },
  {
    _id: "5f96607069fccf0d2f873fb6",
    name: "ANNAI MATHAMMAL SHEELA ENGINEERING COLLEGE",
    address: "ERUMAPATTY POSTNAMAKKAL DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f874078",
    name: "ANNAI MIRA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "ARAPPAKKAM VILLAGE, ARCOT ROAD, WALAJA TALUK, VELLORE DIST.",
  },
  {
    _id: "5f96607069fccf0d2f873f35",
    name: "ANNAI TERESA COLLEGE OF ENGINEERING",
    address: "THIRUNAVALUR, ULUNDURPET TALUK",
  },
  {
    _id: "5f96607069fccf0d2f873ff6",
    name: "ANNAI VAILANKANNI COLLEGE OF ENGINEERING",
    address:
      "AVK NAGAR, POTHAIYADISALAIPOTTALKULAMAZHAGAPPAPURAM.P.OKANYA KUMARI DISTRICT - 629401.TAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f8743a6",
    name: "ANNAI VEILANKANNI'S PHARMACY COLLEGE",
    address: "81 VGP SALAISAIDAPET",
  },
  {
    _id: "5f96607069fccf0d2f874026",
    name: "ANNAI VEILANKANNIS COLLEGE OF ENGINEERING",
    address: "33 GANDHI ROAD,NEDUNGUNDRAM",
  },
  {
    _id: "5f96607069fccf0d2f874169",
    name: "ANNAI VELANKANNI POLYTECHNIC COLLEGE",
    address:
      "NO.8 SALEM MAIN ROAD,ANGUCHETTYPALAYAM,SIRUVATHUR POST,PANRUTI TALUKCUDDALORE DISTRICTTAMILNADU",
  },
  {
    _id: "5f96607069fccf0d2f8727cf",
    name: "ANNAMACHARYA COLLEGE OF PHARMACY",
    address:
      "NEW BOYANAPALLI (POST)THALLAPAKA PANCHAYATRAJAMPET (MANDAL)KADAPA (DISTRICT)ANDHRA PRADESHPIN: 516 126",
  },
  {
    _id: "5f96607069fccf0d2f872842",
    name: "ANNAMACHARYA COLLEGE OF PHARMACY",
    address: "NEW BOYANAPALLI (POST) THALLAPAKA PANCHAYATRAJAMPET (MANDAL)",
  },
  {
    _id: "5f96607069fccf0d2f874480",
    name: "ANNAMACHARYA INSTITUE OF TECHNOLOGY & SCIENCES",
    address:
      "PIGLIPUR,BATASINGARAM(PANCHAYAT),HAYATHNAGAR(MANDAL),RANGA REDDY(DIST),HYDERABAD,AP, PIN-501512",
  },
  {
    _id: "5f96607069fccf0d2f87258d",
    name: "ANNAMACHARYA INSTITUTE OF TECHNOLOGY & SCIENCES",
    address:
      "NEW BOYANAPALLI, THALLAPAKA PANCHAYATH,RAJAMPET MANDALYSR DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f8725f5",
    name: "ANNAMACHARYA INSTITUTE OF TECHNOLOGY AND SCIENCES",
    address:
      "VENKATAPURAM (VILLAGE), MANGALAM ROAD, KARAKAMBADI(POST), RENIGUNTA(MANDAL),TIRUPATI-517520, CHITTOOR DIST",
  },
  {
    _id: "5f96607069fccf0d2f872604",
    name: "ANNAMACHARYA INSTITUTE OF TECHNOLOGY AND SCIENCES",
    address:
      "BACK SIDE OF RTO OFFICEMULAVANKARAYACHOTY ROADCHINTAKOMMA DINNE VILLAGE & POSTKADAPA - 516 003",
  },
  {
    _id: "5f96607069fccf0d2f87270d",
    name: "ANNAMACHARYA PG COLLEGE OF COMPUTER STUDIES",
    address: "NEW BOYANAPALLITHALLAPAKA PANCHAYATHRAJAMPET MANDALYSR DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f8740d7",
    name: "ANNAMALAI POLYTECHNIC COLLEGE",
    address: "CHETTINAD - 630 102",
  },
  {
    _id: "5f96607069fccf0d2f8740a7",
    name: "ANNAMALAIAR COLLEGE OF ENGINEERING",
    address: "CHETPET-POLUR ROADPOLUR T.KTHIRUVANNAMALAI DIST",
  },
  {
    _id: "5f96607069fccf0d2f874057",
    name: "ANNAPOORANA ENGINEERING COLLEGE",
    address: "SANKARI MAIN ROAD, NH-47, PERIASEERAGAPADI",
  },
  {
    _id: "5f96607069fccf0d2f872f2e",
    name: "ANNAPOORNA INSTITUTE OF MANAGEMENT RESEARCH",
    address:
      "PLOT NO.22A/2, S.D.V.S. CAMPUS OLD POONA BANGALORE ROAD,SANKESHWAR",
  },
  {
    _id: "5f96607069fccf0d2f873934",
    name: "ANNASAHEB DANGE COLLEGE OF B PHARMACY",
    address: "A/P - ASHTA, TAL - WALWA, DIST - SANGLI",
  },
  {
    _id: "5f96607069fccf0d2f873a31",
    name: "ANNASAHEB DANGE COLLEGE OF D PHARMACY",
    address: "A/P - ASHTA, TAL - WALWA, DIST - SANGLI",
  },
  {
    _id: "5f96607069fccf0d2f873492",
    name: "ANNASAHEB DANGE COLLEGE OF ENGINEERING AND TECHNOLOGY, ASHTA",
    address: "NEAR OLD AIRPORT, A/P - ASHTA, TAL - WALWA, DIST - SANGLI",
  },
  {
    _id: "5f96607069fccf0d2f874105",
    name: "ANNDAVAR POLYTECHNIC COLLEGE",
    address: "GOBI - SATHY MAIN ROAD,KOVAI PIRIVU,ALUKULLI POST,",
  },
  {
    _id: "5f96607069fccf0d2f8731c3",
    name: "ANNIE INSTITUTE OF TECHNOLOGY AND RSEARCH CENTRE",
    address: "KHAIRWARA ROAD, VILLAGE THUNIA BHAND, NEAR AIRSTRIP, CHHINDWARA",
  },
  {
    _id: "5f96607069fccf0d2f87338c",
    name: "ANSH COLLEGE OF PHARMACY",
    address:
      "ARYAN,01 KM STONE,NEAR NAI DUNIYA PRESS,SHIVPURI LINK PIPROLI ROAD,KEDARPUR",
  },
  {
    _id: "5f96607069fccf0d2f872649",
    name: "ANU COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "NAGARJUNA NAGAR,GUNTUR-522510",
  },
  {
    _id: "5f96607069fccf0d2f874492",
    name: "ANUBOSE INSTITUTE OF TECHNOLOGY",
    address: "KSPROADPALONCHA KHAMMAM DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f872513",
    name: "ANUGRAH NARAYAN COLLEGE, PATNA",
    address: "PO-PATLIPUTRA, BORING ROAD, PATNA",
  },
  {
    _id: "5f96607069fccf0d2f8738f9",
    name: "ANURADHA COLLEGE OF PHARMACY, CHIKHLI",
    address:
      "ANURADHA NAGAR, SAKEGAON ROAD, CHIKHLI- 443201, DIST. BULDANA (MAHARASHTRA)",
  },
  {
    _id: "5f96607069fccf0d2f87390d",
    name: "ANURADHA COLLEGE OF PHARMACY, D.PHARM, CHIKHLI, DIST. BULDANA",
    address:
      "ANURADHA NAGAR, SAKEGAON ROAD, CHIKHLI, DIST. BULDHANA, PIN 443201",
  },
  {
    _id: "5f96607069fccf0d2f87343d",
    name: "ANURADHA ENGINEERING COLLEGE, CHIKHLI",
    address:
      "ANURADHA NAGAR, SAKEGAON ROAD, CHIKHLI-443201, DIST BULDANA (MAHARASHTRA STATE)",
  },
  {
    _id: "5f96607069fccf0d2f873683",
    name: "ANURADHA POLYTECHNIC",
    address: "ANURADHA NAGAR,SAKEGAON ROAD,CHIKHLI",
  },
  {
    _id: "5f96607069fccf0d2f874490",
    name: "ANURAG COLLEGE OF ENGINEERING",
    address: "AUSHAPUR (VILLAGE)GHATKESAR (MANDAL)RANGA REDDY (DIST) - 501301",
  },
  {
    _id: "5f96607069fccf0d2f873948",
    name: "ANURAG COLLEGE OF PHARMACY",
    address:
      "NERA RAILWAY STATION, BHANDRA ROAD,WARTHI, TAH: MOHADI, DISTT:BHANDARA",
  },
  {
    _id: "5f96607069fccf0d2f874455",
    name: "ANURAG ENGINEERING COLLEGE",
    address: "ANANTHAGIRI (VILLAGE)",
  },
  {
    _id: "5f96607069fccf0d2f87441a",
    name: "ANURAG GROUP OF INSTITUTIONS",
    address: "VENKATAPUR (VILLAGE)",
  },
  {
    _id: "5f96607069fccf0d2f8745f6",
    name: "ANURAG PHARMACY COLLEGE",
    address:
      "ANANTHAGIRI (VILLAGE)KODAD (MANDAL)NALGONDA (DIST) 508 206ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f873a45",
    name: "ANUSAYA INSTITUTE OF PHARMACY",
    address:
      "GAT NO.541/2,OFF PUNE-SOLAPUR HIGHWAY,SWAMI-CHINCHOLI,TAL-DAUND,DIST-PUNEPIN-413130",
  },
  {
    _id: "5f96607069fccf0d2f8743f0",
    name: "ANWAR UL ULOOM COLLEGE OF ENGINEERING & TECHOLOGY",
    address: "YENNEPALLY,VIKARABAD, RANGA REDDY. A.P",
  },
  {
    _id: "5f96607069fccf0d2f874511",
    name: "ANWARUL ULOOM COLLEGE FOR COMPUTER STUDIES",
    address: "D.NO 11-3-918 NEW MALLAPALLY HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f874507",
    name: "ANWARUL ULOOM COLLEGE OF BUSINESS MANAGEMENT",
    address: "D.NO 11-3-918 NEW MALLAPALLY HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f8745ec",
    name: "ANWARUL ULOOM COLLEGE OF PHARMACY",
    address: "D.NO 11-3-918 NEW MALLAPALLY,ASIFNAGAR, HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f873be5",
    name: "APEEJAY INSTITUTE OF MANAGEMENT AND ENGINEERING TECHNICAL CAMPUS",
    address:
      "APEEJAY INSTITUTE OF MANAGEMENTRAMA MANDI-HOSHIARPUR ROADJALANDHAR-144007",
  },
  {
    _id: "5f96607069fccf0d2f87497d",
    name: "APEEJAY INSTITUTE OF TECHNOLOGY: SCHOOL OF MANAGEMENT",
    address:
      "1, INSTITUTIONAL AREA, KNOWLEDGE PARK IGAMMA SECTOR, SURAJPUR-KASNA ROADP. O. ALPHA,GREATER NOIDAGAUTAM BUDH NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872926",
    name: "APEEJAY SCHOOL OF MANAGEMENT",
    address: "SECTOR-8, INSTITUTIONAL AREA,DWARKA",
  },
  {
    _id: "5f96607069fccf0d2f873e79",
    name: "APEX COLLEGE OF PHARMACY",
    address: "VILLAGE- FATEHPUR, NEAR NAGLA DHOR SH-21, TEHSIL-ROOPWAS",
  },
  {
    _id: "5f96607069fccf0d2f874c22",
    name: "APEX COLLEGE OF PHARMACY",
    address: "VILL + PO KAUSHALGANJ TEHSIL BILASPUR DISTT. RAMPUR UP",
  },
  {
    _id: "5f96607069fccf0d2f873d3e",
    name: "APEX INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address: "ISI-5, RIICO INSTITUTIONAL AREA, SITAPURA",
  },
  {
    _id: "5f96607069fccf0d2f873320",
    name: "APEX INSTITUTE OF MANAGEMENT & RESEARCH",
    address: "GRAM HASA KHEDI , INDORE",
  },
  {
    _id: "5f96607069fccf0d2f873e3b",
    name: "APEX INSTITUTE OF MANAGEMENT & SCIENCE",
    address: "V.T.ROAD, MAN SAROVAR",
  },
  {
    _id: "5f96607069fccf0d2f873e47",
    name: "APEX INSTITUTE OF MANAGEMENT & SCIENCE",
    address: "V.T.ROAD, MANSAROVAR",
  },
  {
    _id: "5f96607069fccf0d2f872b4f",
    name: "APEX INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address: "VILLAGE GORGHARH, TEHSIL INDRI, DISTRICT KARNAL",
  },
  {
    _id: "5f96607069fccf0d2f8746cb",
    name: "APEX INSTITUTE OF TECHNOLOGY",
    address:
      "VILL. & PO KAUSHALGANJ, DISTT.- RAMPUR (U.P.),6 KM AWAY FROM RUDRAPUR, PIN CODE -244923",
  },
  {
    _id: "5f96607069fccf0d2f874ad4",
    name: "APEX PARAMEDICAL INSTITUTE- APEX INSTITUTE OF PHARMACY",
    address:
      "N.H. -07, MIRZAPUR - VARANASI ROAD, SAMASPUR, CHUNAR, MIRZAPUR- UP",
  },
  {
    _id: "5f96607069fccf0d2f872b83",
    name: "APEX POLY & ENGG COLLEGE",
    address: "BIDHAIKHERA,TOHANA.DISTRICT :FATEHABAD,HARYANA.",
  },
  {
    _id: "5f96607069fccf0d2f873dbd",
    name: "APEX POLYTECHNIC INSTITUTE",
    address: "ISI-7, INSTITUTIONAL AREA, SITAPURA, JAIPUR",
  },
  {
    _id: "5f96607069fccf0d2f8728d1",
    name: "APOLLO COLLEGE",
    address: "IN FRONT OF VETERINARY COLLEGE,ANJORA",
  },
  {
    _id: "5f96607069fccf0d2f8743a9",
    name: "APOLLO COLLEGE OF PHARMACY",
    address:
      "MEVALOORKUPAM, SRIPERUMBUDUR TALUK, KANCHIPURAM DISTRICT, TAMILNADU - 602105",
  },
  {
    _id: "5f96607069fccf0d2f874042",
    name: "APOLLO ENGINEERING COLLEGE",
    address:
      "MEVALOORKUPPAM, VALARPURAM POST, SRIPERUMBUDUR TALUK, KANCHIPURAM DISTRICT, TAMILNADU.",
  },
  {
    _id: "5f96607069fccf0d2f8729a1",
    name: "APOLLO INSTITUTE OF ENGGINEERING & TECHNOLOGY",
    address:
      "C/O SNME CAMPUSAT: ANASAN, NARODA-DEHGAM ROAD,TA: DASKROI, AHMEDABAD-382330 GUJARAT",
  },
  {
    _id: "5f96607069fccf0d2f874503",
    name: "APOLLO INSTITUTE OF HOSPITAL ADMINISTRATION",
    address: "ACADEMIC BLOCK,APOLLO HEALTH CITY,JUBILEE HILLS",
  },
  {
    _id: "5f96607069fccf0d2f874745",
    name: "APOLLO INSTITUTE OF TECHNOLOGY",
    address: "VILL. SUNDHELA, BLOCK SARSAUL, KANPUR",
  },
  {
    _id: "5f96607069fccf0d2f8741e4",
    name: "APOLLO POLYTECHNIC COLLEGE",
    address:
      "PANRUTTI VILLAGE NEAR ORAGADAM KOOT ROAD, THENNERI VIA, VANDALORE, KANCHIPURAM-631 604",
  },
  {
    _id: "5f96607069fccf0d2f8745ab",
    name: "APOORVA INSTITUTE OF MANAGEMENT AND SCIENCES",
    address: "VILL: BHUPATHIPURMANDAL: SULTHANABADKARIMNAGAR505186",
  },
  {
    _id: "5f96607069fccf0d2f874825",
    name: "APOORVA INSTITUTE OF TECHNOLOGY",
    address: "VILLAGE AND POST MIRZAPUR POLE,THASIL BEHAT",
  },
  {
    _id: "5f96607069fccf0d2f873901",
    name: "APPASAHEB BIRNALE COLLEGE OF PHARMACY, SANGLI",
    address: "SANGLI-MIRAJ ROAD, SOUTH SHIVAJINAGAR,SANGLI-416416MAHARASHTRA",
  },
  {
    _id: "5f96607069fccf0d2f8748f3",
    name: "APS COLLEGE OF EDUCATION",
    address:
      "VILLAGE JULHEDA,BHOONI CHOWK, SARDHANA-BINAULI ROAD, TEHSIL-SARDHANA,MEERUT(U.P.)",
  },
  {
    _id: "5f96607069fccf0d2f873658",
    name: "APURVA POLYTECHNIC",
    address:
      "GAT NO. 88/02,RAWALGAON ROAD,SELU-431503,DIST-PARBHANI,MAHARASHTRA",
  },
  {
    _id: "5f96607069fccf0d2f87270c",
    name: "AQJ CENTRE FOR POST GRADUATE STUDIES",
    address: "GUDILOVA, ANANDAPURAM",
  },
  {
    _id: "5f96607069fccf0d2f874306",
    name: "AR SCHOOL OF BUSINESS",
    address:
      "MADURAI ROAD (NH-7),J. OOTHUPATTY, JAMBUDURAIKOTTAI VILLAGE,DINDIGUL - 624206. TAMIL NADU, INDIA.",
  },
  {
    _id: "5f96607069fccf0d2f8745ba",
    name: "ARADHANA SCHOOL OF BUSINESS MANAGEMENT",
    address: "KISMATPUR VILLAGE, RAJENDRA NAGAR MANDAL",
  },
  {
    _id: "5f96607069fccf0d2f874e94",
    name: "ARAMBAGH GOVERNMENT POLYTECHNIC",
    address: "BURDWAN ROAD, CHANDUR BHATA MORE, DOULATPUR, ARAMBAGH, HOOGHLY",
  },
  {
    _id: "5f96607069fccf0d2f874149",
    name: "ARASAN GANESAN POLYTECHNIC COLLEGE",
    address: "VIRUDHUNAGAR MAIN ROAD, ANAIKUTTAM (PO)",
  },
  {
    _id: "5f96607069fccf0d2f874075",
    name: "ARASU ENGINEERING COLLEGE",
    address:
      "CHENNAI MAIN ROADTHIRUVISANALLUR - VILLAGE, KUMBAKONAM-612501,THIRUVIDAIMARUTHUR - TALUK",
  },
  {
    _id: "5f96607069fccf0d2f8740ee",
    name: "ARASU POLYTECHNIC COLLEGE",
    address:
      "CHENNAI MAIN ROAD, THIRUVISANALLUR VILLAGE, THIRUVIDAIMARUTHUR TALUK, KUMBAKONAM - 612501, THANJAVUR DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f872b1f",
    name: "ARAVALI COLLEGE OF ENGINEERING & MANAGEMENT",
    address: "JASANA- TIGAON ROAD,VILLAGE JASANA,FARIDABAD",
  },
  {
    _id: "5f96607069fccf0d2f873d5d",
    name: "ARAVALI INSTITUTE OF TECHNICAL STUDIES",
    address: "UMARDAUDAIPURRAJASTHAN",
  },
  {
    _id: "5f96607069fccf0d2f874184",
    name: "ARAVINDHAR POLYTECHNIC COLLEGE",
    address: "POLUR TO CHENGAM ROADKANGAINUR POST & VILLAGE",
  },
  {
    _id: "5f96607069fccf0d2f8742b1",
    name: "ARCOT SRI MAHALAKSHMI WOMEN'S INSTITUTE OF MANAGEMENT AND COMPUTER APPLICATIONS",
    address: "ARCOT - ARNI MAIN ROAD, VILLAPAKKAM",
  },
  {
    _id: "5f96607069fccf0d2f8726fa",
    name: "ARIES POLYTECHNIC COLLEGE",
    address: "1-123 B.N.R.PETACHITTOOR- TIRUTTANI ROAD",
  },
  {
    _id: "5f96607069fccf0d2f87311a",
    name: "ARIES POLYTECHNIC COLLEGE",
    address: "CHINNATHADAGAM- MANNARKAD ROAD,MATTATHGUDKOTTATHARA",
  },
  {
    _id: "5f96607069fccf0d2f87412f",
    name: "ARIES POLYTECHNIC COLLEGE",
    address: "KARUNGUZHI",
  },
  {
    _id: "5f96607069fccf0d2f873fe8",
    name: "ARIFA INSTITUTE OF TECHNOLOGY",
    address: "ESANOOR,KEELAIYUR-POTHIRUKKUVALAI-TKNAGAPATTINAM-DT",
  },
  {
    _id: "5f96607069fccf0d2f874325",
    name: "ARIGNAR ANNA INSTITUTE OF MANAGEMENT STUDIES & COMPUTER APPLICATIONS",
    address:
      "PENNALUR, SRIPERUMBUDUR TALUK, KANCHEEPURAM DISTRICT, PIN - 602105TAMILNADU",
  },
  {
    _id: "5f96607069fccf0d2f874365",
    name: "ARIGNAR ANNA INSTITUTE OF MANAGEMENT STUDIES AND COMPUTER APPLICATIONS",
    address: "PENNALUR SRIPERUMBUDUR602 105KANCHEEPURAM DISTRICTTAMIL NADU",
  },
  {
    _id: "5f96607069fccf0d2f874049",
    name: "ARIGNAR ANNA INSTITUTE OF SCIENCE AND TECHNOLOGY",
    address: "PENNALUR,SRIPERUMBUDUR - 602 105,KANCHEEPURAM DIST,TAMILNADU.",
  },
  {
    _id: "5f96607069fccf0d2f8738a5",
    name: "ARIHANT COLLEGE OF PHARMACY",
    address: "S. N 343/5 SONEWADI ROAD, KEDGAON AHMEDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874dac",
    name: "ARIHANT COLLEGE OF PHARMACY",
    address: "VILL.- BADHEDI-SHANTERSHAH POST-DAULATPUR",
  },
  {
    _id: "5f96607069fccf0d2f873793",
    name: "ARIHANT INSTITUTE OF BUSINESS MANAGEMENT",
    address:
      "S.NO. 276/1, 276/2, 277/1, 277/2, 278/2, NEAR CRYSTAL HONDA SHOWROOM, NEW MUMBAI-PUNE HIGHWAY, BAWDHAN (BK.),",
  },
  {
    _id: "5f96607069fccf0d2f873313",
    name: "ARIHANT INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    address:
      "453, KHANDWA ROAD, OPP. RADHA SWAMI SATSANG, INDORE(M.P.)PIN CODE : 452001 (M.P.)",
  },
  {
    _id: "5f96607069fccf0d2f8732d6",
    name: "ARIHANT INSTITUTE OF PROFESSIONAL STUDIES",
    address: "453 KHANDWA ROAD, INDORE",
  },
  {
    _id: "5f96607069fccf0d2f872aaa",
    name: "ARIHANT SCHOOL OF PHARMACY AND BIO-RESEARCH INSTITUTE",
    address: "S.G. HIGHWAY, UVARSAD SQUARE,POST : ADALAJDIST. GANDHINAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874514",
    name: "ARISTOTLE P.G COLLEGE",
    address: "CHILKUR (VILL)MOINABAD (MDL)RANGA REDDY (DIST)501504",
  },
  {
    _id: "5f96607069fccf0d2f874043",
    name: "ARIYALUR ENGINNERING COLLEGE",
    address: "TRICHY - CHIDAMBARAM MAIN ROAD, KARUPPUR - SENAPATHY (PO)",
  },
  {
    _id: "5f96607069fccf0d2f873fea",
    name: "ARJUN COLLEGE OF TECHNOLOGY",
    address: "310/1B, CHETTIYAKKAPALAYAM, KINATHUKADAVU, COIMBATORE",
  },
  {
    _id: "5f96607069fccf0d2f874436",
    name: "ARJUN COLLEGE OF TECHNOLOGY AND SCIENCE",
    address:
      "SYS NO 367/7,BATASINGARAM (V) HAYATHNAGAR (M) R.R.DIST , A.P. PIN: 501 512",
  },
  {
    _id: "5f96607069fccf0d2f874a3d",
    name: "ARK COLLEGE OF PHARMACY",
    address:
      "VILLAGE- RAKSARAI, POST- SARAI AKIL, TEH- MANJHANPUR, DISTRICT- KAUSHAMBI",
  },
  {
    _id: "5f96607069fccf0d2f872cdc",
    name: "ARKA JAIN UNIVERSITY JHARKHAND",
    address:
      "ARKA JAIN UNIVERSITY CAMPUS , VILL. MOHANPUR , OPPOSITE TO KERALA PUBLIC SCHOOL , GAMHARIA ,",
  },
  {
    _id: "5f96607069fccf0d2f874421",
    name: "ARKAY COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "SIVAR ACHANPALLYBODHANDIST.NIZAMABADANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874073",
    name: "ARM COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "SATTAMANGALAM,MARAIMALAI NAGAR,CHENGALPET TK.KANCHIPURAM DIST.",
  },
  {
    _id: "5f96607069fccf0d2f872f1c",
    name: "ARMY INSTITUTE OF HOTEL MANAGEMETN & CATERING TECHNOLOGY",
    address: "NAGARESHWARA NAGENAHALLI, KOTHANUR POSTHENNUR BAGALUR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f874eb5",
    name: "ARMY INSTITUTE OF MANAGEMENT",
    address:
      "JUDGES COURT ROADOPPOSITE ALIPORE TELEPHONE EXCHANGEALIPOREKOLKATA-700027",
  },
  {
    _id: "5f96607069fccf0d2f87499f",
    name: "ARMY INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address: "PLOT M-1, POCKET P-5,GREATER NOIDA",
  },
  {
    _id: "5f96607069fccf0d2f87351d",
    name: "ARMY INSTITUTE OF TECHNOLOGY",
    address: "PUNE-ALANDI ROAD, DIGHI HILLS, PUNE 411015",
  },
  {
    _id: "5f96607069fccf0d2f874da5",
    name: "AROMA COLLEGE ROORKEE",
    address:
      " VILL. SANTERSHAH POST PATANJALI YOGPITH ROORKEE DISTT. HARIDWAR PIN 249405 UTAARAKHAND",
  },
  {
    _id: "5f96607069fccf0d2f8729db",
    name: "ARPIT INSTITUTE OF ENGINEERING AND TECHNOLOGY (DIPLOMA)",
    address: "RAJKOT-MORBI NATIONAL HIGHWAY,HADALA-RAJKOT",
  },
  {
    _id: "5f96607069fccf0d2f872950",
    name: "ARRDEKTA INSTITUTE OF TECHNOLOGY",
    address:
      "AHMEDABAD-AMBAJI STATE HIGHWAY NO. 09,OPP. EKTA INSTITUTE.,AT: RADHIWAD, PO.: NAVI METRALTAL. KHEDBRAHMA, DIST. SABARKANTHA, GUJARAT- 383270",
  },
  {
    _id: "5f96607069fccf0d2f87403f",
    name: "ARS COLLEGE OF ENGINEERING",
    address: "SATTTAMANGALAM, MARAIMALAI NAGAR, CHENGALPATTU TALK.",
  },
  {
    _id: "5f96607069fccf0d2f87435e",
    name: "ARUL ANANDAR COLLEGE",
    address: "ARUL ANANDAR COLLEGE(AUTONOMOUS), KARUMATHUR",
  },
  {
    _id: "5f96607069fccf0d2f8743c5",
    name: "ARULMIGU KALASALINGAM COLLEGE OF PHARMACY",
    address:
      "ANAND NAGAR KRISHNANKOIL-626 190SRIVILLIPUTTUR TALUKVIRUDHUNAGAR DISTRICT.",
  },
  {
    _id: "5f96607069fccf0d2f874138",
    name: "ARULMIGU KALASALINGAM POLYTECHNIC COLLEGE",
    address: "ANAND NAGAR,KRISHNANKOIL",
  },
  {
    _id: "5f96607069fccf0d2f87403e",
    name: "ARULMIGU MEENAKSHI AMMAN COLLEGE OF ENGINEERING",
    address:
      "ARULMIGU MEENAKSHI AMMAN COLLEGE OF ENGINEERINGVADAMAVANDAL VILLAGE, NAMANDI POST,(NEAR KANCHIPURAM),",
  },
  {
    _id: "5f96607069fccf0d2f874119",
    name: "ARULMIGU PALANIANDAVAR POLYTECHNIC COLLEGE",
    address: "DINDUGAL ROADPALANI - 624601TAMILNADU",
  },
  {
    _id: "5f96607069fccf0d2f8741a2",
    name: "ARULMIGU SENTHILANDAVAR POLYTECHNIC COLLEGE",
    address: "AYIKUDI (VIA)TENKASI-627811",
  },
  {
    _id: "5f96607069fccf0d2f8740e8",
    name: "ARULMIGU THIRUPURASUNDARI AMMAN POLYTECHNIC COLLEGE",
    address: "EGAI VILLAGEPULIYUR POSTTHIRUKKALUKUNDARAM",
  },
  {
    _id: "5f96607069fccf0d2f874017",
    name: "ARULMURUGAN COLLEGE OF ENGINEERING",
    address: "KARVAZHI ROADTHENNILAIARAVAKURICHY TALUKKARUR-639206",
  },
  {
    _id: "5f96607069fccf0d2f8741d5",
    name: "ARULMURUGAN POLYTECHNIC COLLEGE",
    address: "KARVAZHI ROADTHENNILAIARAVAKURICHY-TKKARUR-DT",
  },
  {
    _id: "5f96607069fccf0d2f87299e",
    name: "ARUN MUCHHALA ENGINEERING COLLEGE",
    address:
      "AT.KHOKRA MAHADEV DHARI SARSIYA ROAD,TAL.DHARI,DIST.AMRELI,GUJRAT STATE",
  },
  {
    _id: "5f96607069fccf0d2f8737d1",
    name: "ARUNA MANHARLAL SHAH INSTITUTE OF MANAGEMENT & RESEARCH",
    address:
      "R.B. KADAM MARG(JIVDAYA LANE) NEAR MTNL EXCHANGE, OFF LBS MARG, GHATKOPAR (W), MUMBAI-400086",
  },
  {
    _id: "5f96607069fccf0d2f873fa4",
    name: "ARUNACHALA COLLEGE OF ENGINEERING FOR WOMEN",
    address:
      "'THANKA GARDENS'MANAVILAI, VELLICHANTHAI,NAGERCOILL,KANYAKUMARI DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f873f6e",
    name: "ARUNAI COLLEGE OF ENGINEERING",
    address: "VELU NAGARMATHURTIRUVANNAMALAI",
  },
  {
    _id: "5f96607069fccf0d2f874392",
    name: "ARUNAI COLLEGE OF PHARMACY",
    address: "VELU NAGAR, THENMATHUR, TIRUVANNAMALAI - 606603",
  },
  {
    _id: "5f96607069fccf0d2f874370",
    name: "ARUNAI ENGIEERING COLLEGE",
    address: "CHITOOR-CUDDALORE ROAD,MATHUR,TIRUVANNAMALAI",
  },
  {
    _id: "5f96607069fccf0d2f873f53",
    name: "ARUNAI ENGINEERING COLLEGE",
    address: "CHITOOR - CUDDALORE ROAD,MATHUR,TIRUVANNAMALAI",
  },
  {
    _id: "5f96607069fccf0d2f87354a",
    name: "ARVIND GAVALI COLLEGE OF ENGINEERING",
    address: "GAT NO. 247, PANMALEWADI, POST - VARYE,",
  },
  {
    _id: "5f96607069fccf0d2f8738f7",
    name: "ARVIND GAVALI COLLEGE OF PHARMACY",
    address:
      "SURVEY NO. 261,JAITAPUR,SATARA RAHIMATPUR ROAD,TAL. & DIST.SATARA 415004 (MAHARASHTRA)",
  },
  {
    _id: "5f96607069fccf0d2f873d66",
    name: "ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY",
    address: "SP- 42 KUKAS, RIICO,JAIPURRAJASTHAN302028",
  },
  {
    _id: "5f96607069fccf0d2f873d67",
    name: "ARYA COLLEGE OF ENGINEERING AND RESEARCH CENTRE",
    address: "SP-40 RIICO INDUSTRIAL AREAKUKASJAIPUR(RAJASTHAN)",
  },
  {
    _id: "5f96607069fccf0d2f873e5f",
    name: "ARYA COLLEGE OF PHARMACY",
    address: "SP-40, RIICO INDUSTRIAL AREA, DELHI ROAD, KUKAS, JAIPUR",
  },
  {
    _id: "5f96607069fccf0d2f874637",
    name: "ARYA COLLEGE OF PHARMACY",
    address: "KANDI VILLEGE,SANGAREDDY MANDALDIST:MEDAK,PINCODE:502285.",
  },
  {
    _id: "5f96607069fccf0d2f873d35",
    name: "ARYA INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address: "SP-40, RIICO INDUSTRIAL AREA, DELHI ROAD, KUKASJAIPUR - 302028",
  },
  {
    _id: "5f96607069fccf0d2f873d6c",
    name: "ARYA INSTITUTE OF ENGINEERING TECHNOLOGY & MANAGEMENT",
    address:
      "F-29,OMAXE CITY PHASE-II,JAIPUR AJMER EXPRESS HIGHWAY,JAIPUR302026",
  },
  {
    _id: "5f96607069fccf0d2f874a3e",
    name: "ARYA INSTITUTE OF PHARMACY",
    address: "HARDUA KIFAYATULLA NAWAB GANJ BAREILLY",
  },
  {
    _id: "5f96607069fccf0d2f872d39",
    name: "ARYABHARATHI POLYTECHNIC",
    address: "SRI H M GANGADHARAIAH ROAD, SARASWATHIPURAM,",
  },
  {
    _id: "5f96607069fccf0d2f873e38",
    name: "ARYABHATT COLLEGE OF MANAGEMENT",
    address:
      "ARYABHATT COLLEGE OF MANAGEMENTBEHIND MAHILA MANDALCHACHIAWAS -AJMER",
  },
  {
    _id: "5f96607069fccf0d2f872913",
    name: "ARYABHATT INSTITUTE OF TECHNOLOGY",
    address: "OPPOSITE SHAKTI NAGAR TELEPHONE EXCHANGE, G. T. K. ROAD",
  },
  {
    _id: "5f96607069fccf0d2f8724cf",
    name: "ARYABHATT POLYTECHNIC",
    address:
      "VILLAGE - HARIHARPUR, POST - AMWAN, P.S. - BODH GAYA, DISTRICT - GAYA",
  },
  {
    _id: "5f96607069fccf0d2f873d59",
    name: "ARYABHATTA COLLEGE OF ENGINEERING AND RESEARCH CENTER",
    address: "BEHIND MAHILA KALYAN MANDALCHACHIAWAS CHATARI ROADAJMER",
  },
  {
    _id: "5f96607069fccf0d2f873beb",
    name: "ARYABHATTA GROUP OF INSTITUTES,BARNALA",
    address: "NH-71, BARNALA-BAJAKHANA ROAD, CHEEMA-JODHPUR",
  },
  {
    _id: "5f96607069fccf0d2f873cfb",
    name: "ARYABHATTA INSTITUTE OF PHARMACY",
    address: "VILL. KHOKAR, SUNAM ROAD, LEHRAGAGA DISTT. SANGRUR",
  },
  {
    _id: "5f96607069fccf0d2f8726a9",
    name: "ARYABHATTA POLYTECHNIC",
    address: "LAGISETTIPALEM SABBAVARAM,",
  },
  {
    _id: "5f96607069fccf0d2f874937",
    name: "ARYAKUL COLLEGE OF MANAGEMENT",
    address:
      "VILL: NATKUR, PO: CHANDRAWAL, ARYAKUL COLLEGE ROAD, ADJ CRPF BASE CAMP, LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f874ae6",
    name: "ARYAKUL COLLEGE OF PHARMACY & RESEARCH",
    address:
      "VILLAGE: NATKUR, PO: CHANDRAWAL, GAURI- BIJNAUR ROAD, ADJ CRPF BASE CAMP, LUCKNOW - 226002",
  },
  {
    _id: "5f96607069fccf0d2f874a5b",
    name: "ARYAKUL COLLEGE OF PHARMACY AND RESEARCH RAEBARELI",
    address:
      "VILL SEHGO PANCHIM, PARGANA KUMHRAWA, TEHSIL MAGARAJGANJ DISTRICT RAIBARELLY",
  },
  {
    _id: "5f96607069fccf0d2f874a5c",
    name: "ARYAKUL COLLEGE OF PHARMACY AND RESEARCH SITAPURI",
    address: "VILL JAJJAPUR POST MANWA BLOCK SIDHAULI",
  },
  {
    _id: "5f96607069fccf0d2f873aab",
    name: "ARYAN INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address:
      "AT: BARAKUDAPO: PANCHAGAONDIST: KHURDABHUBANESWARPIN-752050ORISSA",
  },
  {
    _id: "5f96607069fccf0d2f873b30",
    name: "ARYAN INSTITUTE OF ENGINEERING & TECHNOLOGY (POLYTECHNIC)",
    address: "VIDYA-VIHAR, SUNDARGRAM, CUTTACK",
  },
  {
    _id: "5f96607069fccf0d2f8746fd",
    name: "ARYAN INSTITUTE OF TECHNOLOGY, GHAZIABAD",
    address: "13 KM STONE, NH-24, JINDAL NAGAR, GHAZIABAD",
  },
  {
    _id: "5f96607069fccf0d2f873e0d",
    name: "ARYAN MUKUL POLYTECHNIC COLLEGE",
    address: "CISF LINK ROAD PATEL NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f873dae",
    name: "ARYAN POLYTECHNIC COLLEGE",
    address: "BEHIND MAHILA MANDAL, CHACHIYAWAS,AJMER",
  },
  {
    _id: "5f96607069fccf0d2f873de6",
    name: "ARYAN POLYTECHNIC COLLEGE",
    address: "OPP. EMMANUEL HOSTEL, KAITHUN ROAD, RAIPURA, KOTA",
  },
  {
    _id: "5f96607069fccf0d2f873b45",
    name: "ARYAN SCHOOL OF BUSINESS MANAGEMENT",
    address: "AT-BARAKUDA, PO- PANCHAGAON, DIST KHORDHA",
  },
  {
    _id: "5f96607069fccf0d2f873b3d",
    name: "ARYAN SCHOOL OF ENGINEERING AND TECHNOLOGY",
    address: "AT-BARAKUDA, PO- PANCHAGAON, DIST-KHORDHA",
  },
  {
    _id: "5f96607069fccf0d2f873ca9",
    name: "ARYANS BUSINESS SCHOOL (ABS)",
    address:
      "VILL. NEPRACHANDIGARH-PATIALA HIGHWAYNEAR CHANDIGARH,TEH. RAJPURADIST. PATIALAPUNJAB140401",
  },
  {
    _id: "5f96607069fccf0d2f873bcb",
    name: "ARYANS COLLEGE OF ENGINEERING (ACE)",
    address:
      "VILL. THUHA (BANUR)CHANDIGARH-PATIALA HIGHWAY, NEAR CHANDIGARH,TEHSIL RAJPURA, DIST. PATIALA (PB)",
  },
  {
    _id: "5f96607069fccf0d2f873cdc",
    name: "ARYANS COLLEGE OF PHARMACY",
    address:
      "VILLAGE NEPRA/THUHA, NEAR CHANDIGARH, CHANDIGARH-PATIALA HIGHWAY, PO CHAMARU, TEHSIL RAJPURA, DISTRICT PATIALA, STATE PUNJAB 140401",
  },
  {
    _id: "5f96607069fccf0d2f873cf3",
    name: "ARYANS PHARMACY COLLEGE",
    address:
      "VILL. THUHA, CHANDIGARH-PATIALA ROAD, PO CHAMARU, TEH. RAJPURA, DISTT. PATIALA (PB)",
  },
  {
    _id: "5f96607069fccf0d2f874798",
    name: "ARYAVART INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address:
      "ARYAVART INSTITUTE OF TECHNOLOGY & MANAGEMENT, OPPOSITE-AMBEDKAR UNIVERSITY, SHAHEED PATH, RAEBARELI ROAD, LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f874816",
    name: "ARYIKA GYANMATI GOVERNMENT GIRLS POLYTECHNIC, FAIZABAD",
    address: "BANBEERPUR ROAD, POST - MUMTAZNAGAR,DISTT.- FAIZABAD",
  },
  {
    _id: "5f96607069fccf0d2f873f62",
    name: "AS-SALAM COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "THIRUMANGALAKUDI - ADUTHURAITHIRUVIDAIMARUTHUR - 612 102THANJAVUR DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f8742d8",
    name: "ASAN MEMORIAL COLLEGE OF ARTS & SCIENCE - INSTITUTE OF MANAGEMENT",
    address: "VELACHERY - TAMABARAM ROAD, JALADAMPET, CHENNAI 600100",
  },
  {
    _id: "5f96607069fccf0d2f874375",
    name: "ASAN MEMORIAL COLLEGE OF ARTS & SCIENCE - INSTITUTE OF MANAGEMENT",
    address: "VELACHERY-TAMBARAM ROAD, JALADAMPET,CHENNAI - 600 100",
  },
  {
    _id: "5f96607069fccf0d2f874e15",
    name: "ASANSOL ENGINEERING COLLEGE",
    address: "VIVEKANANDA SARANI,KANYAPUR",
  },
  {
    _id: "5f96607069fccf0d2f874e4e",
    name: "ASANSOL INSTITUTE OF ENGINEERING AND MANAGEMENT - POLYTECHNIC",
    address: "BAGBANDI ROAD, NEAR RADHYASWAMI SATSANG",
  },
  {
    _id: "5f96607069fccf0d2f874e99",
    name: "ASANSOL POLYTECHNIC",
    address: "SOUTH DHADKA",
  },
  {
    _id: "5f96607069fccf0d2f874ca9",
    name: "ASBD COLLEGE OF PHARMACY",
    address: "VPO NAYAGAON, TEHSIL-BILGRAM, DISTT HARDOI, UP 241305",
  },
  {
    _id: "5f96607069fccf0d2f873dc9",
    name: "ASHA DEVI POLYTECHNIC COLLEGE",
    address: "KHEMANA ROAD,SADULPUR,DISTT-CHURU",
  },
  {
    _id: "5f96607069fccf0d2f8749f1",
    name: "ASHA PHARMACY COLLEGE",
    address: "VILL-KUSMURA, POST-BARAGOAN, DIST-VARANASI-221204",
  },
  {
    _id: "5f96607069fccf0d2f874bd3",
    name: "ASHOK SINGH PHARMACY COLLEGE",
    address: "MAHROOPUR, JAUNPUR UTTAR PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f87372f",
    name: "ASHOKA BUSINESS SCHOOL",
    address:
      "ASHOKA BUSINESS SCHOOL,URJAYANT PLAZA, NEXT TO SUMAN PETROL PUMP, MUMBAI - AGRA HIGHWAY, CIDCO, NASIK - 9",
  },
  {
    _id: "5f96607069fccf0d2f874583",
    name: "ASHOKA BUSINESS SCHOOL",
    address: "MALKAPUR (V), CHOUTUPPAL (M)",
  },
  {
    _id: "5f96607069fccf0d2f8743fa",
    name: "ASHOKA INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address: "MALKAPUR VILLAGE,CHOUTUPPAL MANDAL,NALGONDA DIST.",
  },
  {
    _id: "5f96607069fccf0d2f872877",
    name: "ASHOKA INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address: "GRAM- TORANKATTA, POST- SOMNI, G.E. ROAD, RAJNANDGAON (C.G)",
  },
  {
    _id: "5f96607069fccf0d2f8746d0",
    name: "ASHOKA INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "AKTHA,SARNATH,VARANASI.",
  },
  {
    _id: "5f96607069fccf0d2f872eb6",
    name: "ASHOKA POLYTECHNIC",
    address:
      "SY NO.42, KAMMAGONDANAHALLI, 100FT ROAD(FOREST ROAD), JALAHALLI WEST",
  },
  {
    _id: "5f96607069fccf0d2f87390e",
    name: "ASHOKRAO MANE COLLEGE OF PHARMACY, PETH VADGAON",
    address: "VATHAR-VADGAON ROAD,",
  },
  {
    _id: "5f96607069fccf0d2f8739b8",
    name: "ASHOKRAO MANE INSTITUTE OF DIPLOMA IN PHARMACY",
    address: "A/P- PETH VADGAON, TAL- HATKANANGLE, DIST- KOLHAPUR.",
  },
  {
    _id: "5f96607069fccf0d2f873956",
    name: "ASHOKRAO MANE INSTITUTE OF PHARMACEUTICAL SCIENCES AND RESEARCH, SAVE",
    address: "GAT. NO.878, A/P-SAVE, TAL-SHAHUWADI, DIST-KOLHAPUR",
  },
  {
    _id: "5f96607069fccf0d2f87387d",
    name: "ASHOKRAO MANE INSTITUTE OF PHARMACY AMBAP",
    address: "AP/ AMBAP TAL HATKANANGLE DIST KOLHAPUR",
  },
  {
    _id: "5f96607069fccf0d2f87364d",
    name: "ASHOKRAO MANE POLYTECHNIC",
    address: "GAT NO.113 A, NH4, PUNE-BENGALURU HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f872b87",
    name: "ASHTVINAYAK INSTITUTE OF TECHNOLOGY",
    address: "VILLAGE- KHARKALI.POST OFFICE - BAKALI VIA LADWA.",
  },
  {
    _id: "5f96607069fccf0d2f87384b",
    name: "ASHVIN COLLEGE OF PHARAMACY",
    address: "AT. MANCHI HILL, PO. ASHVI BK, TAL- SANGAMNER",
  },
  {
    _id: "5f96607069fccf0d2f872a3d",
    name: "ASIA PACIFIC INSTITUTE OF HOTEL MANAGEMENT",
    address: "VILLAGE BHOYANI",
  },
  {
    _id: "5f96607069fccf0d2f872b51",
    name: "ASIA PACIFIC INSTITUTE OF INFORMATION TECHNOLOGY SD INDIA PANIPAT",
    address: "FARIDPUR ROAD, G.T. ROAD, KARNAL SIDE,PANIPAT-132103",
  },
  {
    _id: "5f96607069fccf0d2f872922",
    name: "ASIA-PACIFIC INSTITUTE OF MANAGEMENT",
    address: "3,4 INSTITUTIONAL AREA, JASOLA, NEW DELHI-110025",
  },
  {
    _id: "5f96607069fccf0d2f87495c",
    name: "ASIAN BUSINESS SCHOOL",
    address: "A-2, SECTOR-125",
  },
  {
    _id: "5f96607069fccf0d2f87405b",
    name: "ASIAN COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "294/2, ASIAN COLLEGE ROAD,KONDAYAMPALAYAM,NEAR SARAVANAMPATTI,COIMBATORE - 641 110",
  },
  {
    _id: "5f96607069fccf0d2f873e8b",
    name: "ASIAN COLLEGE OF PHARMACY",
    address: "NEAR POLICE ANVESHAN BHAWAN, BADI MAIN ROAD",
  },
  {
    _id: "5f96607069fccf0d2f8732cc",
    name: "ASIAN INSTITUTE OF PROFESSIONAL STUDIES",
    address: "VILLAGE AAKVI, TEHSIL MHOW, DISTT. INDORE 453441",
  },
  {
    _id: "5f96607069fccf0d2f8747a0",
    name: "ASIAN INSTITUTE OF RURAL TECHNOLOGY",
    address: "VILLAGE-SEKHUPUR,POST-BAGHPUR,KANPUR DEHAT",
  },
  {
    _id: "5f96607069fccf0d2f874c75",
    name: "ASIAN INSTITUTE OF SCIENCE AND TECH.",
    address: "VILLAGE- BAGHPUR , KANPUR DEHAT",
  },
  {
    _id: "5f96607069fccf0d2f872a28",
    name: "ASIAN INSTITUTE OF TECHNOLGY",
    address:
      "AT & POST : VADALI, NEAR RAILWAY CROSSING, IDAR-KHEDBRAHMA ROAD, TA : VADALI, DIST : SABARKANTHA",
  },
  {
    _id: "5f96607069fccf0d2f87313d",
    name: "ASIAN SCHOOL OF BUSINESS",
    address: "TECHNOCITY, PALLIPURAM, TRIVANDRUM",
  },
  {
    _id: "5f96607069fccf0d2f873b63",
    name: "ASIAN SCHOOL OF BUSINESS MANAGEMENT",
    address: "SHISKHA VIHAR,BHOLA,CHANDAKA,BHUBANESWAR - 754012",
  },
  {
    _id: "5f96607069fccf0d2f873af8",
    name: "ASIAN SCHOOL OF TECHNOLOGY",
    address: "AT-BANTALAPO-MUKTAPURDIST-KHURDA",
  },
  {
    _id: "5f96607069fccf0d2f873d4f",
    name: "ASIANS INSTITUTE OF TECHNOLOGY",
    address: "POST: LUHARA, TAHSIL: NEEWAI, DISTRICT: TONK",
  },
  {
    _id: "5f96607069fccf0d2f872a2c",
    name: "ASIATIC INSTITUTE OF SCIENCE & TECHNOLOGY",
    address: "AT. JAMVADITA. GONDALDIST. RAJKOT",
  },
  {
    _id: "5f96607069fccf0d2f873785",
    name: "ASM'S INSTITUTE OF BUSINESS MANAGEMENT & RESEARCH (PGDM), PUNE",
    address: "PLOT NO.133, MIDC, BLOCK C', CHINCHWAD, PUNE 411019",
  },
  {
    _id: "5f96607069fccf0d2f8737c3",
    name: "ASM'S INSTITUTE OF BUSINESS MANAGEMENT & RESEARCH MBA INSTITUTE",
    address: "MIDC, BLOCK 'C', CHINCHWAD, PUNE-411019",
  },
  {
    _id: "5f96607069fccf0d2f87370a",
    name: "ASM'S INSTITUTE OF INTERNATIONAL BUSINESS & RESEARCH",
    address:
      "SURVEY NO.29/1+2A, 'C' WING, CTS NO.4695, OLD MUMBAI-PUNE HIGHWAY, BEHIND STERLING HONDA SHOWROOM, PIMPRI-PUNE - 411018",
  },
  {
    _id: "5f96607069fccf0d2f8738b3",
    name: "ASPM COLLEGE OF PHARMACY",
    address: "SANGULWADI, TQ. VAIBHAVWADI, DIST. SINDHUDURG",
  },
  {
    _id: "5f96607069fccf0d2f8738d9",
    name: "ASPM'S K. T. PATIL COLLEGE OF PHARMACY, OSMANABAD.",
    address: "SIDDHARTH NAGAR, BARSHI ROAD, OSMANABAD.413501(M.S)",
  },
  {
    _id: "5f96607069fccf0d2f873baa",
    name: "ASRA COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "PATIALA SANGRUR HIGHWAY, NH 64VILLAGE RAJPURA (NEAR CHANNO)TEHSIL BHAWANIGARHDIST. SANGRUR (PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f873ca0",
    name: "ASRA INSTITUTE OF ADVANCED STUDIES (MBA)",
    address:
      "PATIALA SANGRUR HIGHWAY, NH 64VILLAGE RAJPURA (NEAR CHANNO)TEHSIL BHAWANIGARHDIST. SANGRUR (PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f873cb7",
    name: "ASRA INSTITUTE OF ADVANCED STUDIES (MCA)",
    address:
      "PATIALA SANGRUR HIGHWAY, NH 64VILLAGE RAJPURA (NEAR CHANNO)TEHSIL BHAWANIGARHDIST. SANGRUR (PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f872467",
    name: "ASSAM DOWN TOWN UNIVERSITY",
    address: "SANKAR MADHAB PATH, GANDHINAGAR,PANIKHAITI",
  },
  {
    _id: "5f96607069fccf0d2f87245f",
    name: "ASSAM ENGINEERING COLLEGE",
    address: "P.O.:- JALUKBARIDIST:- KAMRUP(METRO)ASSAM",
  },
  {
    _id: "5f96607069fccf0d2f87246e",
    name: "ASSAM ENGINEERING INSTITUTE",
    address: "M.D. ROAD, CHANDMARI, GUWAHATI, ASSAM -781003",
  },
  {
    _id: "5f96607069fccf0d2f872489",
    name: "ASSAM INSTITUTE OF MANAGEMENT",
    address: "VIGYAN PATH, PACHIM BORAGAON, GUWAHATI. PIN-781035",
  },
  {
    _id: "5f96607069fccf0d2f872472",
    name: "ASSAM TEXTILE INSTITUTE",
    address: "GNB ROAD.AMBARI.",
  },
  {
    _id: "5f96607069fccf0d2f873b64",
    name: "ASTHA SCHOOL OF MANAGEMENT",
    address: "261,ATALA,BALIANTABHUBANESWAR,DIST-KHURDAORISSAPIN-752101",
  },
  {
    _id: "5f96607069fccf0d2f873b6c",
    name: "ASTRAL INSTITUTE OF MANAGEMENT STUDIES",
    address: "AT-SISHU VIHAR,P.O-KIIT,INFOCITY ROAD,PATIA",
  },
  {
    _id: "5f96607069fccf0d2f873215",
    name: "ASTRAL INSTITUTE OF TECHNOLOGY AND RESEARCH",
    address: "KAILOD KARTAL BYPASS ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872c82",
    name: "ATAL BIHARI VAJPAYEE GOVERNMENT INSTITUTE OF ENGINEERING & TECHNOLOGY (DIPLOMA PROGRAMME)",
    address: "PRAGATINAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872c6f",
    name: "ATAL BIHARI VAJPAYEE GOVT. INSTITUTE OF ENGINEERING & TECHNOLOGY (DEGREE PROGRAMME)",
    address:
      "ATAL BIHARI VAJPAYEE GOVT. INSTITUTE OF ENGINEERING & TECHNOLOGY (DEGREE PROGRAMME), PRAGATINAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872c30",
    name: "ATAM INSTITUTE OF PHARMACY",
    address: "NH-09, VILLAGE GARHI, TEHSIL HANSI, DISTT HISAR",
  },
  {
    _id: "5f96607069fccf0d2f873563",
    name: "ATHARVA COLLEGE OF ENGINEERING",
    address: "PLOT 8-12, CHARKOP NAKA, MALAD MARVE ROAD,MALAD WEST",
  },
  {
    _id: "5f96607069fccf0d2f873769",
    name: "ATHARVA INSTITUTE OF MANAGEMENT STUDIES",
    address: "MALAD MARVE ROAD CHARKOP NAKA MALAD (WEST) MUMBAI 400 095",
  },
  {
    _id: "5f96607069fccf0d2f872f41",
    name: "ATMA COLLEGE",
    address:
      "NO.235/E1, BOMMASANDRA INDUSTRIAL AREA, 3RD PHASE, BENGALURU - 560099",
  },
  {
    _id: "5f96607069fccf0d2f872db0",
    name: "ATME COLLEGE OF ENGINEERING",
    address: "SY.NO.1002, HAROHALLI (MELLAHALLI)",
  },
  {
    _id: "5f96607069fccf0d2f872ab2",
    name: "ATMIYA INSTITUTE OF PHARMACY",
    address: "\\YOGIDHAM GURUKUL\\KALAWAD ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872962",
    name: "ATMIYA INSTITUTE OF TECHNOLOGY & SCIENCE (ENGINEERING)",
    address: "\\YOGIDHAM GURUKUL\\, KALAWAD ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872a47",
    name: "ATMIYA INSTITUTE OF TECHNOLOGY & SCIENCE (MCA)",
    address: "\\YOGIDHAM GURUKUL\\, KALAWAD ROAD",
  },
  {
    _id: "5f96607069fccf0d2f8729d9",
    name: "ATMIYA INSTITUTE OF TECHNOLOGY & SCIENCE FOR DIPLOMA STUDIES,",
    address: "\\YOGIDHAM',KALAVAD ROAD.",
  },
  {
    _id: "5f96607069fccf0d2f872dae",
    name: "ATRIA INSTITUTE OF TECHNOLOGY",
    address: "ASKB CAMPUS, AG'S COLONY, 1ST MAIN, ANANDNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872a0c",
    name: "ATUL POLYTECHNIC",
    address:
      "AT& POST:KHADATMAHUDI ROAD,NEAR PSL FACTORY,TA:MANSADIST:GANDHINAGAR",
  },
  {
    _id: "5f96607069fccf0d2f8725ed",
    name: "AUDISANKARA COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "NH-5,BY-PASS ROAD,GUDUR,NELLORE DISTRICT,ANDHRA PRADESH,PIN:524101",
  },
  {
    _id: "5f96607069fccf0d2f872617",
    name: "AUDISANKARA COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address:
      "NORTHRAJUPALEM(VILL) RAMANNAPALEM ROAD,KODAVALUR(MD) NELLORE DT.,A.P - 524366",
  },
  {
    _id: "5f96607069fccf0d2f872645",
    name: "AUDISANKARA COLLEGE OF ENGINEERING FOR WOMEN",
    address: "NH-5, BYPASS ROAD,GUDUR,NELLORE DISTRICTA.P.",
  },
  {
    _id: "5f96607069fccf0d2f87265b",
    name: "AUDISANKARA INSTITUTE OF TECHNOLOGY",
    address: "NH-5,BY-PASS ROAD,GUDUR,NELLORE DISTRICTANDHRA PRADESHPIN:524101",
  },
  {
    _id: "5f96607069fccf0d2f8737a7",
    name: "AUDYOGIK SHIKSHAN MANDAL'S INSTITUTE OF PROFESSIONAL STUDIES",
    address:
      "S.NO.29/1+2A, CTS NO.4695, 'B' WING, BEHIND STERLING HONDA SHOWROOM, OLD MUMBAI-PUNE HIGHWAY, PIMPRI WAGHERE, PIMPRI , PUNE-411018",
  },
  {
    _id: "5f96607069fccf0d2f873ad1",
    name: "AUM SAI INSTITUTE OF TECHNICAL EDUCATION",
    address: "NARAYANAPURGOPALPUR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f873527",
    name: "AURANGABAD COLLEGE OF ENGINEERING",
    address: "GAT NO 52, TULJAPUR SHIVAR,",
  },
  {
    _id: "5f96607069fccf0d2f873963",
    name: "AURANGABAD PHARMACY COLLEGE",
    address: "GUT NO. 113, MITMITA, AURANGABAD",
  },
  {
    _id: "5f96607069fccf0d2f874526",
    name: "AUROBINDO COLLEGE OF BUSINESS MANAGEMENT",
    address: "(VILL). CHINTAPALLYGUDA, IBRAHIMPATNAM, RANGAREDDY(D)PIN-501510",
  },
  {
    _id: "5f96607069fccf0d2f8745e4",
    name: "AUROBINDO COLLEGE OF PHARMACEUTICAL SCIENCES",
    address:
      "GANGADEVIPALLY(VILLAGE), MACHAPUR(POST), GEESUGONDA(MANDAL), WARANGAL(DISTRICT), ANDHRA PRADESH. PIN - 506330",
  },
  {
    _id: "5f96607069fccf0d2f874522",
    name: "AUROBINDO INSTITUTE OF COMPUTER SCIENCES",
    address: "(VILL) CHINTAPALLYGUDA, (M) IBRAHIMPATNAM(D) RANGA REDDY",
  },
  {
    _id: "5f96607069fccf0d2f874573",
    name: "AURORA S PG COLLEGE (MBA)",
    address: "1-118/10 AND 1-118/10/1, UPPAL, PEERZADIGUDA, HYDERABAD.",
  },
  {
    _id: "5f96607069fccf0d2f874513",
    name: "AURORA'S BUSINESS SCHOOL",
    address: "6-3-456/18, 6-3-456/19, DWARAKAPURI COLONY, HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f874402",
    name: "AURORA'S ENGINEERING COLLEGE",
    address: "4-3-596, TILAK ROAD, ABIDS, HYDERABAD, 500001",
  },
  {
    _id: "5f96607069fccf0d2f87450b",
    name: "AURORA'S PG COLLEGE(MBA)",
    address: "1-12-125/1GANESH NAGARRAMTHAPURHYDERABAD-500013ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874579",
    name: "AURORA'S PG COLLEGE(MBA)",
    address: "6-3-524, PANJAGUTTA, HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f87450d",
    name: "AURORA'S PG COLLEGE(MCA)",
    address: "12-1-125/1GANESH NAGARRAMANTHAPURHYDERABAD500013",
  },
  {
    _id: "5f96607069fccf0d2f8745bd",
    name: "AURORA'S PG COLLEGE(MCA)",
    address: "5-8-660, NAMPALLY, HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f87440d",
    name: "AURORA'S SCIENTIFIC & TECHNOLOGICAL INSTITUTE",
    address:
      "AUSHAPUR (V)GHATKESAR(M),RANGA REDDY DISTRICT(APPLICATION MADE TO AICTE FOR CHANGE OF LOCATION TO AUSHAPUR(V),GHATAKESAR(M),RANGA REDDY DISTRICT, A.P.NOC FROM JNTUH AND STATE GOVT HAS BEEN OBTAINED),",
  },
  {
    _id: "5f96607069fccf0d2f8743fd",
    name: "AURORA'S SCIENTIFIC, TECHNOLOGICAL&RESEARCH ACADEMY",
    address: "16-11-210, 16-11-210/1, MOOSAMRAMBAGH, HYDERABAD 500036",
  },
  {
    _id: "5f96607069fccf0d2f87445b",
    name: "AURORA'S TECHNOLOGICAL & MANAGEMENT ACADEMY",
    address: "42-735/10, SP NAGAR MOULALI, MEDCHAL-MALKAJGIRI DIST.",
  },
  {
    _id: "5f96607069fccf0d2f874401",
    name: "AURORA'S TECHNOLOGICAL AND RESEARCH INSTIUTE",
    address: "PARVATHAPUR,UPPAL,HYDERABAD-500039",
  },
  {
    _id: "5f96607069fccf0d2f8743cf",
    name: "AURORA'S TECHNOLOGICAL INSTITUTE",
    address: "PLOT NO. 1, INDUSTRIAL DEVELOPMENT PARK, UPPAL, HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f8743d0",
    name: "AURORA'S TECHNOLOGICAL INSTITUTE",
    address: "1-8-557, CHIKKADPALLY, HYDERABAD",
  },
  {
    _id: "5f96607069fccf0d2f874930",
    name: "AUROUS INSTITUTE OF MANAGEMENT",
    address:
      "LUCKNOW- FAIZABAD ROADKHASRA NO- 180,VILLAGE MOHAMMADPUR CHOWKIPOST OFFICE SAFEDABADDISTT BARABANKI- 225003",
  },
  {
    _id: "5f96607069fccf0d2f8729a5",
    name: "AURUM INSTITUTE OF TECHNOLOGY",
    address: "ISHWARIYA VILLAGE,OPP. HILL VIEW CLUB, KALAWAD ROAD,RAJKOT.",
  },
  {
    _id: "5f96607069fccf0d2f87430b",
    name: "AUXILIUM COLLEGE (AUTONOMOUS)",
    address:
      "NO.1, 17TH EAST MAIN ROAD,GANDHI NAGAR,VELLORE VELLORE DISTRICT632006",
  },
  {
    _id: "5f96607069fccf0d2f874a89",
    name: "AVADH COLLEGE OF PHARMACY",
    address: "VILL. NINDURA, TEHSIL-FATEHPUR, DISTT. BARABANKI, U.P. 225302",
  },
  {
    _id: "5f96607069fccf0d2f874b35",
    name: "AVADH INSTITUTE OF MEDICAL TECHNOLOGY AND HOSPITAL",
    address: "VILL- PAIKRAMAU BEHTA, CURSE ROAD, DISTT- LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f874cd5",
    name: "AVADH INSTITUTE OF PHARMACY",
    address: "VILLAGE CHITRAKOT DISTT",
  },
  {
    _id: "5f96607069fccf0d2f8745a1",
    name: "AVANTHI DEGREE & P.G. COLLEGE",
    address:
      "# 16-11-741/B/1/A, MOOSARAMBAGH,DILSUKHNAGAR,HYDERABAD,ANDHRA PRADESH.",
  },
  {
    _id: "5f96607069fccf0d2f8745c4",
    name: "AVANTHI DEGREE & PG COLLEGE",
    address:
      "# 16-11-741/B/1/A, MOOSARAMBAGH,DILSUKHNAGAR,HYDERABAD,ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f872564",
    name: "AVANTHI INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address:
      "TAMARAM VILLAGE,MAKAVARAPALEM MANDAL,NARSIPATNAM REVENUE DIVISION,VISAKHAPATNAM DIST.",
  },
  {
    _id: "5f96607069fccf0d2f874443",
    name: "AVANTHI INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address:
      "GUNTHAPALLY VILLAGE,HAYATH NAGAR MANDAL, RANGA REDDY DIST.HYDEARBAD,ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f87258e",
    name: "AVANTHI INSTITUTE OF ENGINERRING AND TECHNOLOGY",
    address:
      "CHERUKUPALLI VIILAGE, CHITTIVALASA SO,BHOGAPURAM MANDAL, VIZIANAGRAM DIST",
  },
  {
    _id: "5f96607069fccf0d2f8727d7",
    name: "AVANTHI INSTITUTE OF PHARMACEUTICAL SCIENCES",
    address:
      "CHITTIVALASA SO,CHERUKUPALLY VILLAGE,BHOGAPURAM MANDAL,VIZIANAGARAM DIST.",
  },
  {
    _id: "5f96607069fccf0d2f874627",
    name: "AVANTHI INSTITUTE OF PHARMACEUTICAL SCIENCES",
    address:
      "GUNTHAPALLY (V),HAYATHNAGAR MANDAL,NEAR RAMOJI FILM CITY,R.R.DIST.,HYDERABAD.",
  },
  {
    _id: "5f96607069fccf0d2f87267e",
    name: "AVANTHI POLYTECHNIC COLLEGE",
    address: "KAVULAVADA VILLAGEBHOGAPURAM MANDALVIZIANAGARAM DIST.",
  },
  {
    _id: "5f96607069fccf0d2f8745ac",
    name: "AVANTHI POST GRADUATE & RESEARCH ACADEMY",
    address:
      "GUNTHAPALLY VILLAGE,HAYATH NAGAR MANDAL,RANGA REDDY DIST,HYDERABAD, ANDHRA PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f872550",
    name: "AVANTHI S ST.THERESSA INSTITUTE OF ENGINEERING AND TECHNOLOGY,GARIVIDI",
    address: "GARBHAM ROAD,GARIVIDI (CHEEPURUPALLI),VIZIANAGARAM DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f87442b",
    name: "AVANTHI SCIENTIFIC TECHNOLOGICAL & RESEARCH ACADEMY",
    address:
      "GUNTHAPALLY VILLAGE,HAYATH NAGAR MANDALNEAR RAMOJI FILM CITY,R.R.DIST.,HYDERABAD,ANDHRA PRADESH.",
  },
  {
    _id: "5f96607069fccf0d2f87259a",
    name: "AVANTHI'S RESEARCH & TECHNOLOGICAL ACADEMY",
    address:
      "AVANTHI'S RESEARCH & TECHNOLOGICAL ACADEMYBASAVAPALEM VILLAGE,BHOGAPURAM MANDALAM,VIZIANAGARAM DISTRICT.",
  },
  {
    _id: "5f96607069fccf0d2f87433c",
    name: "AVINASHILINGAM INSTITUTE FOR HOME SCIENCE AND HIGHER EDUCATION FOR WOMEN",
    address:
      "BHARATHI PARK ROAD (BESIDE FOREST COLLEGE CAMPUS), COIMBATORE -641043",
  },
  {
    _id: "5f96607069fccf0d2f873f42",
    name: "AVINASHILINGAM INSTITUTE FOR HOME SCIENCE AND HIGHER EDUCATION FOR WOMEN DEEMED TO BE UNIVERSITY",
    address:
      "FACULTY OF ENGINEERING,AVINASHILINGAM UNIVERSITY FOR WOMEN,COIMBATORE - 641108",
  },
  {
    _id: "5f96607069fccf0d2f87431b",
    name: "AVINASHILINGAM UNIVERSITY FOR WOMEN (DEEMED)",
    address:
      "AVINASHILINGAM SCHOOL OF MANAGEMENT TECHNOLOGY,BHARATHI PARK ROAD - 1, COIMBATORE - 641043",
  },
  {
    _id: "5f96607069fccf0d2f872fa3",
    name: "AVK INSTITUTE OF MANAGEMENT",
    address:
      "SY.NO.69,PIPE LINE ROAD,BWSSB COLONY,CHIKKAGOLLARAHATTI,MAGADI ROAD,VISHWANEEDAM POST,BANGALORE-560091.",
  },
  {
    _id: "5f96607069fccf0d2f87441f",
    name: "AVN INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address:
      "KOHEDA ROAD, M.P. PATELGUDA, RAGANNAGUDA POST, IBRAHIMPATNAM (M) RANGA REDDY DISTT - 501 510",
  },
  {
    _id: "5f96607069fccf0d2f8742e4",
    name: "AVS COLLEGE OF SCIENCE(MBA)",
    address: "RAMALINGAPURAM,ATTUR MAIN ROAD,SALEM- 636 106",
  },
  {
    _id: "5f96607069fccf0d2f874357",
    name: "AVS COLLEGE OF SCIENCE(MCA)",
    address: "RAMALINGA PURAMATTUR MAIN ROADSALEM -636106",
  },
  {
    _id: "5f96607069fccf0d2f873ef5",
    name: "AVS ENGINEERING COLLEGE",
    address: "MILITARY ROAD,AMMAPET,SALEM",
  },
  {
    _id: "5f96607069fccf0d2f874cca",
    name: "AWADH PARAMEDICAL COLLEGE & INSTITUTE",
    address: "346, NYANSAND, SAREMU, SADAR, JAUNPUR",
  },
  {
    _id: "5f96607069fccf0d2f8733f0",
    name: "AWADHMADHAV COLLEGE OF PHARMACY",
    address: "BARUA NOORABAD, RAIRU BYPASS GWALIOR",
  },
  {
    _id: "5f96607069fccf0d2f87303a",
    name: "AWH ENGINEERING COLLEGE",
    address:
      "PATTAYILKUNNU KUTTIKKATTOOR- POKOZHIKODE -DTKERALA STATE673008 - PIN",
  },
  {
    _id: "5f96607069fccf0d2f8730fd",
    name: "AWH POLYTECHNIC COLLEGE",
    address: "PATTAYAD KUNNU, KUTTIKKATTOOR.",
  },
  {
    _id: "5f96607069fccf0d2f87498a",
    name: "AXIS BUSINESS SCHOOL",
    address: "VILL- HATHIPUR",
  },
  {
    _id: "5f96607069fccf0d2f87304b",
    name: "AXIS COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "NO. 5/303, AMBANOLY,MURIKKINGAL P.O.THRISSURKERALA",
  },
  {
    _id: "5f96607069fccf0d2f8747b1",
    name: "AXIS INSTITUTE OF DIPLOMA ENGINEERING",
    address: "VILL-SALEMPUR POS-MAHARAJPUR TEHSIL-NARWAL DIST-KANPUR",
  },
  {
    _id: "5f96607069fccf0d2f874652",
    name: "AXIS INSTITUTE OF FASHION TECHNOLOGY",
    address: "VILL-HATHIPUR TEHSIL KANPUR SADAR",
  },
  {
    _id: "5f96607069fccf0d2f874919",
    name: "AXIS INSTITUTE OF PLANNING AND MANAGEMENT",
    address: "VILL- HATHIPUR TEHSIL- KANPUR SADAR",
  },
  {
    _id: "5f96607069fccf0d2f8746df",
    name: "AXIS INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "VILL-HATHIPUR,KANPUR SADAR",
  },
  {
    _id: "5f96607069fccf0d2f874ba1",
    name: "AYODHYA VIDYAPEETH COLLEGE OF PHARMACY",
    address:
      "VILLAGE KARAMDANDA, POST BARUN, TEH. MILKIPUR, DISTT. FAIZABAD, UTTAR PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f8728e8",
    name: "AYUSH COLLEGE OF PHARMACY MARWAHI",
    address: "VILL- LOHARI,TALUKA-MARWAHI,POST- PENDRA ROAD, DIST- BILASPUR",
  },
  {
    _id: "5f96607069fccf0d2f8728df",
    name: "AYUSH COLLEGE OF PHARMACY MEDUKA PENDRA ROAD",
    address: "VILLAGE MEDUKA, PO- DARRI, TEH- PENDRA ROAD DIST- BILASPUR, CG",
  },
  {
    _id: "5f96607069fccf0d2f8728a5",
    name: "AYUSH COLLEGE OF POLYTECHNIC",
    address: "GRAM - MEDUKA, PO- DARRITAHSIL- PENDRA ROADBILASPUR",
  },
  {
    _id: "5f96607069fccf0d2f874c9a",
    name: "AYUSHI COLLEGE OF PHARMACY",
    address: "JASHRATHPUR,POST OFFICE-SAKOTI,NH- 58,MEERUT",
  },
  {
    _id: "5f96607069fccf0d2f874344",
    name: "AYYA NADAR JANAKI AMMAL COLLEGE",
    address: "SRIVILLIPUTTUR ROADSIVAKASI (WEST)SIVAKASI",
  },
  {
    _id: "5f96607069fccf0d2f87414d",
    name: "AYYANADAR JANAKIAMMAL POLYTECHNIC COLLEGE",
    address: "CHINNAKKAMANPATTI",
  },
  {
    _id: "5f96607069fccf0d2f8740fe",
    name: "AYYAPPA POLYTECHNIC COLLEGE",
    address: "AIVADHUGUDI,ERANJI POST,VIRUDHACHALAM TALUK,CUDDALORE DISTRICT.",
  },
  {
    _id: "5f96607069fccf0d2f874b62",
    name: "AZAD COLLEGE OF EDUCATION",
    address:
      "VIL- SHAKA JANVARA, PO- MUSANAGAR, TEHSIL-GHATAMPUR, KANPUR NAGAR, UTTAR PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874486",
    name: "AZAD COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "MOINABADRANGA REDDY",
  },
  {
    _id: "5f96607069fccf0d2f8745cb",
    name: "AZAD COLLEGE OF PHARMACY",
    address: "MOINABADRANGA REDDY DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f874b5d",
    name: "AZAD COLLEGE OF PHARMACY",
    address: "VILLAGE- MIYAPUR BASDEVA, POST- BHARTIPUR",
  },
  {
    _id: "5f96607069fccf0d2f8746a7",
    name: "AZAD INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address: "AZADPURAM, ADJACENT CRPF CAMP, POST CHANDRAWAL, BIJNAUR",
  },
  {
    _id: "5f96607069fccf0d2f874504",
    name: "AZAD INSTITUTE OF MANAGEMENT",
    address: "MOINABADRANGA REDDY DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f874a56",
    name: "AZAD INSTITUTE OF PHARMACY & RESEARCH",
    address: "AZADPURAM, ADJACENT CRPF CAMP, POST CHANDRAWAL,",
  },
  {
    _id: "5f96607069fccf0d2f8747cf",
    name: "AZAD LUCKNOW POLYTECHNIC",
    address: "AZADPURAM, ADJACENT CRPF CAMP, PO: CHANDRAWAL, BIJNOUR, LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f8748b0",
    name: "AZAD POLYTECHNIC, BHARTHIPUR",
    address: "BHARTHIPUR, PALHNA, LALGANJ, AZAMGARH",
  },
  {
    _id: "5f96607069fccf0d2f8724d7",
    name: "AZMET COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "AT-SALKI TENGARMARI, PO-GACHHPARA DIST-KISHANGANJ",
  },
  {
    _id: "5f96607069fccf0d2f8724ab",
    name: "AZMET INSTITUTE OF TECHNOLOGY",
    address: "ZAFARNAGAR, ANARKALI KISHANGUNJ",
  },
  {
    _id: "5f96607069fccf0d2f872527",
    name: "B D COLLEGE",
    address: "NEAR GAURIAMATH , MITHAPURPATNA , POST- GPO , PIN- 800001",
  },
  {
    _id: "5f96607069fccf0d2f874c08",
    name: "B D S COLLEGE OF PHARMACY",
    address: "384, GT ROAD, VIRAMPUR, ETAH (U.P.)- 207001",
  },
  {
    _id: "5f96607069fccf0d2f872ebe",
    name: "B E S INSTITUTE OF TECHNOLOGY",
    address: "BHARATH EDUCATION SOCIETY, 16TH MAIN, 4TH BLOCK, JAYANAGAR,",
  },
  {
    _id: "5f96607069fccf0d2f872e39",
    name: "B E T POLYTECHNIC",
    address: "BHARATHINAGARAK M DODDIMADDUR TALUKMANDYA DIST KARNATAKA -571422",
  },
  {
    _id: "5f96607069fccf0d2f872cef",
    name: "B I T SINDRI",
    address: "PO -SINDRI INSTITUTE",
  },
  {
    _id: "5f96607069fccf0d2f873978",
    name: "B K PATIL INSTITUTE OF PHARMACY",
    address: "585, SHANKAR MANDIR , TALOJA",
  },
  {
    _id: "5f96607069fccf0d2f87486c",
    name: "B K POLYTECHNIC",
    address: "NEEMGAON ROAD, BADHON RAYA, MATHURA",
  },
  {
    _id: "5f96607069fccf0d2f872b1e",
    name: "B M GROUP OF INSTITUTIONS",
    address: "VILLAGE HARI NAGAR (DUMHA), FARUKHNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f874b23",
    name: "B N COLLEGE OF PHARMACY",
    address: "NH-24 SITAPUR ROAD BAKSHI KA TALAB LUCKNOW UTTAR PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874a1b",
    name: "B R COLLEGE OF PHARMACY",
    address:
      "VILLAGE : RAJEYPUR, TEHSIL: BILGRAM, DISTRICT: HARDOI, UTTAR PRADESH",
  },
  {
    _id: "5f96607069fccf0d2f874b3f",
    name: "B R COLLEGE OF PHARMACY",
    address:
      "PAKHNA CHAURAHA, BHOORNAGRIA, MOHAMMADABAD, FARRUKHABAD, U.P. 209652",
  },
  {
    _id: "5f96607069fccf0d2f874862",
    name: "B R GAUTAM POLYTECHNIC INSTITUTE",
    address: "RAJAPUR",
  },
  {
    _id: "5f96607069fccf0d2f873fc9",
    name: "B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY",
    address: "GST ROAD VANDALUR CHENNAI - 600048",
  },
  {
    _id: "5f96607069fccf0d2f8725a1",
    name: "B V C COLLEGE OF ENGINEERING",
    address:
      "PALACHERLA VILLAGE,RAJANAGARAM MANDAL,EAST GODAVARI DISTRICTANDHRA PRADESH533104",
  },
  {
    _id: "5f96607069fccf0d2f874413",
    name: "B V RAJU INSTITUTE OF TECHNOLOGY",
    address: "VISHNUPUR, NARSAPUR, MEDAK DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f872df0",
    name: "B V V SANGHA'S BASAVESHWAR ENGINEERING COLLEGE BAGALKOT",
    address: "NIJALINGAPPA ROADBAGALKOT 587102",
  },
  {
    _id: "5f96607069fccf0d2f872f58",
    name: "B V V SANGHA'S INSTITUTE OF MANAGEMENT STUDIES",
    address: "NEAR V BLOCK HOSTEL,VIDYGIRIBAGALKOT-587102",
  },
  {
    _id: "5f96607069fccf0d2f87251c",
    name: "B. D. COLLEGE",
    address: "MOH.- MITHAPUR, P.O.- G.P.O.",
  },
  {
    _id: "5f96607069fccf0d2f87295d",
    name: "B. H. GARDI COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "OPP. SHIV SHAKTI HOTEL,RAJKOT - KALAWAD HIGHWAY,VILLAGE : ANANDPARTALUKA : KALAWADDIST : JAMNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872ae4",
    name: "B. M. INSTITUTE OF ENGINEERING AND TECHNOLOGY, SONEPAT",
    address:
      "BAHALGARH ROAD, OPPPOSITE FAZILPUR POWER SUBSTATION, SONEPAT, 131001",
  },
  {
    _id: "5f96607069fccf0d2f874bfd",
    name: "B. M. S. COLLEGE OF PHARMACY",
    address: "VILLAGE- NASRATPUR TILOI, POST & TEHSIL- TILOI, DISTRICT- AMETHI",
  },
  {
    _id: "5f96607069fccf0d2f874aa5",
    name: "B. N. PHARMACY COLLEGE",
    address: "RAMPUR, JAHANAGANJ, AZAMGARH U. P. 276131",
  },
  {
    _id: "5f96607069fccf0d2f8724bf",
    name: "B. P. MANDAL COLLEGE OF ENGINEERING, MADHEPURA",
    address: "S. N. P. M. MODEL HIGH SCHOOL CAMPUS MADHEPURA",
  },
  {
    _id: "5f96607069fccf0d2f874e0c",
    name: "B. P. PODDAR INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    address: "137, V.I.P. ROADKOLKATA - 700052",
  },
  {
    _id: "5f96607069fccf0d2f872abf",
    name: "B. PHARMACY COLLEGE, NAVALGADH",
    address: "AT&PO: NAVALGADHTA: DHANGADRADIS: SURENDRANAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872aa2",
    name: "B. PHARMACY COLLEGE, RAMPURA-KAKANPUR",
    address:
      "AT.RAMPURA, POST. KAKANPURTA. GODHRA,DIST. PANCHMAHALSGUJARAT. PINCODE-389713",
  },
  {
    _id: "5f96607069fccf0d2f8733fa",
    name: "B. R. COLLEGE",
    address: "SURVEY NO. 695/2, BANMORE DIST. MORENA M.P.",
  },
  {
    _id: "5f96607069fccf0d2f873420",
    name: "B. S. BANDEKAR COLLEGE OF FINE ART,(APPLIED ART).",
    address:
      "SURVE NO.54/01, NEAR FOREST BHAVAN,SALAIWADA,AT/PO.SAWANTWADI,DIST:-SINDHUDURGA.416510",
  },
  {
    _id: "5f96607069fccf0d2f8748be",
    name: "B. S. M. COLLEGE OF TECHNOLOGY AND MANAGEMENT",
    address: "VILL-BHADARPOST-SONDISTT-MATHURA",
  },
  {
    _id: "5f96607069fccf0d2f8729df",
    name: "B. S. PATEL POLYTECHNIC",
    address: "GANPAT VIDYANAGAR, MEHSANA GOZARIA HIGHWAY,",
  },
  {
    _id: "5f96607069fccf0d2f872eeb",
    name: "B. V. V. S. POLYTECHNIC,BAGALKOT.",
    address: "B V V SANGHA CAMPUS, BELGAUM - RAICHUR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872ce9",
    name: "B.A.COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "VILLAGE : GHUTIA, TALUK : GHATSHILA, BARAKHURSI, VIA - MAHULIADISTRICT : EAST SINGHBHUM",
  },
  {
    _id: "5f96607069fccf0d2f873d06",
    name: "B.B. COLLEGE OF PHARMACY",
    address: "HANUMANGARH ROAD, ABOHAR.TEHSIL - ABOHAR",
  },
  {
    _id: "5f96607069fccf0d2f874676",
    name: "B.B.S. COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "GADDOPURPHAPHAMAUALLAHABAD",
  },
  {
    _id: "5f96607069fccf0d2f874b0f",
    name: "B.B.S. INSTITUTE OF PHARMACEUTICAL & ALLIED SCIENCES",
    address: "PLOT NO. - 33,KNOWLEDGE PARK-III,GREATER NOIDA (UP)",
  },
  {
    _id: "5f96607069fccf0d2f874c87",
    name: "B.D.M COLLEGE OF PHARMACY",
    address: "VPO-PAIGAON CHHATA (MATHURA) PIN CODE-281401",
  },
  {
    _id: "5f96607069fccf0d2f872dd1",
    name: "B.G.S INSTITUTE OF TECHNOLOGY",
    address: "B.G.NAGARA ,NAGAMANGALA TALUKMANDYA DISTRICT 571448",
  },
  {
    _id: "5f96607069fccf0d2f872e45",
    name: "B.G.S POLYTECHNIC",
    address: "BB ROAD NH-7 SJCIT(CAMPUS) CHIKKABALLAPURA",
  },
  {
    _id: "5f96607069fccf0d2f87364e",
    name: "B.H.S.S.TRUST'S POLYTECHNIC",
    address: "BHAYGAON ROAD ,MALEGAON CAMP",
  },
  {
    _id: "5f96607069fccf0d2f873d39",
    name: "B.K. BIRLA INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address: "BKBIET,CEERI ROADPILANI",
  },
  {
    _id: "5f96607069fccf0d2f874c26",
    name: "B.K. INSTITUTE OF PHARMACY",
    address: "VILL - MAUJA BINDUBULAKI, TEHSIL - MAANT, DISTT - MATHURA",
  },
  {
    _id: "5f96607069fccf0d2f872ab0",
    name: "B.K.MODY GOVT.PHARMACY COLLEGE",
    address: "POLYTECHNIC CAMPUS,NEAR AJI DAMBHAVNAGAR ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872e19",
    name: "B.L.D.E ASSOCIATION'S SHREE SANGANABASAVA MAHASWAMIJI POLYTECHNIC, VIJAYAPUR",
    address: "ASHRAM ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872d69",
    name: "B.L.D.E.A'S V.P DR P.G.HALAKATTI COLLEGE OF ENGG. & TECH., BIJAPUR-03",
    address: "ASHRAM ROAD,BIJAPUR - 586103",
  },
  {
    _id: "5f96607069fccf0d2f873621",
    name: "B.L.PATIL POLYTECHNIC",
    address: "NEAR OLD POLICE STATION KHOPOLI TAL KHALAPUR",
  },
  {
    _id: "5f96607069fccf0d2f872d7d",
    name: "B.M.S.COLLEGE OF ENGINEERING",
    address: "POST BOX NO 1908, BULL TEMPLE ROAD",
  },
  {
    _id: "5f96607069fccf0d2f8746e6",
    name: "B.N. COLLEGE OF ENGINEERING & TECHNOLOGY(BNCET)",
    address: "N.H. - 24SITAPUR ROAD, BAKSHI KA TALAABLUCKNOW226021",
  },
  {
    _id: "5f96607069fccf0d2f872f68",
    name: "B.N.BAHADUR INSTITUTE OF MANAGEMENT SCIENCES",
    address:
      "B.N.BAHADUR INSTITUTE OF MANAGEMENT SCIENCES, (DOS IN BUSSINESS ADMINISTRATION)UNIVERSITY OF MYSORE, MANASAGANGOTRI,HUNSUR ROAD,MYSORE.",
  },
  {
    _id: "5f96607069fccf0d2f874a8a",
    name: "B.P.S. EDUCATIONAL INSTITUTION OF PHARMACY",
    address: "VIDYA VIHAR, G.T. ROAD, ETAH",
  },
  {
    _id: "5f96607069fccf0d2f872c50",
    name: "B.R COLLEGE OF PHARMACY",
    address: "VILL-BAGPUR P.O MOHAN TEHSIL",
  },
  {
    _id: "5f96607069fccf0d2f87347e",
    name: "B.R. HARNE COLLEGE OF ENGINEERING & TECHNOLOGY",
    address:
      "SURVEY NO. 110/1A, 111/1,, 111/2B, 126/2, VILLAGE - KARAV, NEAR VANGANI STATION, TAH. AMBARNATH, DIST. THANE.",
  },
  {
    _id: "5f96607069fccf0d2f873dfc",
    name: "B.R. POLYTECHNIC COLLEGE",
    address: "B.R. POLYTECHNIC COLLEGE REENGUS SIKAR",
  },
  {
    _id: "5f96607069fccf0d2f874c9c",
    name: "B.R.D. PHARMACY MEDICAL INSTITUTE",
    address: "VILLAGE & POST- NAISARA,",
  },
  {
    _id: "5f96607069fccf0d2f87289b",
    name: "B.R.P. GOVT. POLYTECHNIC DHAMTARI",
    address: "RUDRI DHAMTARI",
  },
  {
    _id: "5f96607069fccf0d2f874a5f",
    name: "B.S. COLLEGE OF PHARMACY",
    address: "PLOT NO. 203 FATEHULLAHPUR",
  },
  {
    _id: "5f96607069fccf0d2f872c0a",
    name: "B.S.ANANGPURIA INSTITUTE OF PHARMACY",
    address: "ALAMPUR, BALLABGARH-SOHNA ROAD, FRAIDABAD",
  },
  {
    _id: "5f96607069fccf0d2f872b18",
    name: "B.S.ANANGPURIA INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address: "VILLAGE: ALAMPUR, BALLABGARH-SOHNA ROAD, FARIDABAD, HARYANA",
  },
  {
    _id: "5f96607069fccf0d2f87252b",
    name: "B.S.COLLEGE",
    address: "PO-DIGHA (DANAPUR), PATNA-12",
  },
  {
    _id: "5f96607069fccf0d2f874b8b",
    name: "B.S.R. COLLEGE OF PHARMACY",
    address: "VILLAGE- KHORRAMPUR, POST- BELAUN",
  },
  {
    _id: "5f96607069fccf0d2f872e68",
    name: "B.T.PATIL AND SONS POLYTECHNIC",
    address: "GAVANI-STAVANIDHI, TALUKA: CHIKKODI",
  },
  {
    _id: "5f96607069fccf0d2f8727c5",
    name: "B.V. RAJU COLLEGE",
    address:
      "VISHNUPURKOVVADA VILLAGEBHIMAVARAM - 534 202WEST GODAVARI DIST.AP",
  },
  {
    _id: "5f96607069fccf0d2f8744ca",
    name: "B.V.KRISHNA POLYTECHNIC",
    address:
      "T.N.BABU HOSPITAL STREET,OPPOSIT SRINIVASA THEATRE,SRPT ROAD,KODAD,NALGONDA DIST-508206.ANDHRA PRADESH.",
  },
  {
    _id: "5f96607069fccf0d2f872ed4",
    name: "B.V.PEETH'S J.V.MANDAL'S POLYTECHNIC",
    address: "GURUKUL CAMPUS",
  },
  {
    _id: "5f96607069fccf0d2f872ecc",
    name: "B.V.V.SANGHA S S.R.VASTRAD RURAL POLYTECHNIC",
    address: "TOGUNASHI CROSS, BADAMI ROAD",
  },
  {
    _id: "5f96607069fccf0d2f87280e",
    name: "BA&KR COLLEGE OF PHARMACY",
    address: "DODDAVARAPADU,NH-5.",
  },
  {
    _id: "5f96607069fccf0d2f872779",
    name: "BA&KR MCA COLLEGE",
    address: "DODDAVARAPADU,NH-5",
  },
  {
    _id: "5f96607069fccf0d2f874cd3",
    name: "BABA BAIJNATH COLLEGE OF PHARMACY",
    address: "VILLAGE- AMNAWAY BANGAWN, MARTINGANJ, AZAMGARH",
  },
  {
    _id: "5f96607069fccf0d2f873bac",
    name: "BABA BANDA SINGH BAHADUR ENGINEERING COLLEGE",
    address: "FATEHGARH SAHIB-140407, PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f873c40",
    name: "BABA BANDA SINGH BAHADUR POLYTECHNIC COLLEGE",
    address: "BABA BANDA SINGH BAHADUR POLYTECHNIC COLLEGE",
  },
  {
    _id: "5f96607069fccf0d2f8747a5",
    name: "BABA BINDESHWARI SINGH INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "MURDAHA, SINDHORA ROAD",
  },
  {
    _id: "5f96607069fccf0d2f873c64",
    name: "BABA DEEP SINGH POLYTECHNIC COLLEGE",
    address: "V.P.O PANNIWALA FATTA TEH.MALOUT DISTT.MUKTSAR PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f873cb2",
    name: "BABA FARID COLLEGE",
    address: "BATHINDA-MUKTSAR ROAD, DEON., BATHINDA",
  },
  {
    _id: "5f96607069fccf0d2f873be1",
    name: "BABA FARID COLLEGE OF ENGINEERING & TECHNOLOGY",
    address: "VILLAGE DEON, MUKTSAR ROAD, BATHINDA",
  },
  {
    _id: "5f96607069fccf0d2f873ca8",
    name: "BABA FARID COLLEGE OF MANAGEMENT & TECHNOLOGY",
    address: "VILLAGE- DEON, MUKTSAR ROAD, BATHINDA.",
  },
  {
    _id: "5f96607069fccf0d2f873cd8",
    name: "BABA FARID COLLEGE OF PHARMACY",
    address: "MORKARIMA, MULLANPUR DISTT. LUDHIANA",
  },
  {
    _id: "5f96607069fccf0d2f872910",
    name: "BABA HARIDASS COLLEGE OF PHARMACY & TECHNOLOGY",
    address:
      "BABA HARIDASS ENCLAVE OPP.JHARODA KALAN HIGH SCHOOL NEW DELHI-110072",
  },
  {
    _id: "5f96607069fccf0d2f873c78",
    name: "BABA HAZARA SINGH POLYTECHNIC",
    address:
      "NEAR AUJLA BYPASS VILL-THANEWAL, P.O BABEHALI, TECH & DISTRICT GURDASPUR.",
  },
  {
    _id: "5f96607069fccf0d2f873c61",
    name: "BABA HIRA SINGH BHATTAL INSTITUTE OF ENGG. & TECHNOLOGY",
    address: "BHSBIET, SUNAM-JAKHAL ROAD, LEHRAGAGA, 148031 (SANGRUR) PB.",
  },
  {
    _id: "5f96607069fccf0d2f873bb3",
    name: "BABA HIRA SINGH BHATTAL INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address: "SUNAM-JAKHAL ROAD, LEHRAGAGA-148031, DISTRICT SANGRUR, PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f8749e1",
    name: "BABA INSTITUTE OF PHARMACY",
    address: "BHAMAWADI, BARLA - BHASEDA ROAD, DISTT- MUZFFARNAGAR",
  },
  {
    _id: "5f96607069fccf0d2f872614",
    name: "BABA INSTITUTE OF TECHNOLOGY AND SCIENCES",
    address: "P M PALEM, MADHURAWADA",
  },
  {
    _id: "5f96607069fccf0d2f873cdf",
    name: "BABA ISHER SINGH COLLEGE OF PHARMACY",
    address: "VPO GAGRA, MOGA, PUNJAB - 142043.",
  },
  {
    _id: "5f96607069fccf0d2f873c15",
    name: "BABA ISHER SINGH POLYTECHNIC COLLEGE",
    address: "DHARAMKOT ROAD",
  },
  {
    _id: "5f96607069fccf0d2f872bc3",
    name: "BABA KHETA NATH GOVT POLYTECHNIC",
    address: "BABA KHETA NATH GOVT POLYTECHNICPURANI MANDI NARNAUL",
  },
  {
    _id: "5f96607069fccf0d2f873bd2",
    name: "BABA KUMA SINGH JI ENGG COLLEGE,AMRITSAR",
    address: "HOSHIAR NAGARATTARI ROADNEAR WAGHA BORDERAMRITSAR-143107",
  },
  {
    _id: "5f96607069fccf0d2f873d1e",
    name: "BABA KUNDAN COLLEGE OF PHARMACY",
    address: "BABA KUNDAN COLLEGE OF PHARMACY KULLIAWAL JAMALPUR LUDHIANA",
  },
  {
    _id: "5f96607069fccf0d2f8733ba",
    name: "BABA LOKNATH INSTITUTE OF PHARMACY SCIENCE AND RESEARCH CENTRE",
    address: "BAMHORI DUDHAR, NAYA KHEDA, POST:- SIDHGUAN",
  },
  {
    _id: "5f96607069fccf0d2f874a15",
    name: "BABA RAMDAL SURAJDEV PHARMACY COLLEGE",
    address: "VILL - PATNA, POST - KANSO, TEHSIL - RASRA, DISTT - BALLIA",
  },
  {
    _id: "5f96607069fccf0d2f8747b8",
    name: "BABA RAMDAL SURAJDEV POLYTECHNIC COLLEGE",
    address: "VILL - PATNA, POST - KANSO, TEHSIL - RASRA, DISTT - BALLIA",
  },
  {
    _id: "5f96607069fccf0d2f874b5f",
    name: "BABA RAMNATH UTKARSH PHARMACY COLLEGE",
    address:
      "VILLAGE- BALPUR KHARAILA, POST- SARAIPALTU, TEHSIL- LALGANJ, DISTRICT- AZAMGARH",
  },
  {
    _id: "5f96607069fccf0d2f87484b",
    name: "BABA SAHEB AMBEDKAR POLYTECHNIC",
    address: "VILL- NAGLA JHINGA POST- MAGORRA MATHURA",
  },
  {
    _id: "5f96607069fccf0d2f874832",
    name: "BABA SAHEB DR. BHIMRAO AMBEDKAR GOVERNMENT POLYTECHNIC HAIDARPUR AURAIYA",
    address: "HAIDARPUR AURAIYA",
  },
  {
    _id: "5f96607069fccf0d2f873d17",
    name: "BABA SHEIKH FARID PHARMACY COLLEGE",
    address: "MUKTSAR ROAD,NEAR KOTHE BER WALE,KOTKAPURA (PUNJAB)",
  },
  {
    _id: "5f96607069fccf0d2f874c01",
    name: "BABA VISHWANATH COLLEGE OF PHARMACY",
    address: "LUTFABAD, BACCHULI, TEHSIL BIKAPUR, DISTT. AYODHYA 224204 (UP)",
  },
  {
    _id: "5f96607069fccf0d2f8748c2",
    name: "BABA VISHWANATH INSTITUTE OF TECHNOLOGY",
    address: "AMNAVE, POST- MAHUJA, BLOCK MARTIN GANJ, TEHSIL- PHOOLPUR,",
  },
  {
    _id: "5f96607069fccf0d2f872ab3",
    name: "BABARIA INSTITUTE OF PHARMACY",
    address: "VADODARA MUMBAI NH#8VARNAMA",
  },
  {
    _id: "5f96607069fccf0d2f872984",
    name: "BABARIA INSTITUTE OF TECHNOLOGY",
    address: "VADODARA MUMBAI NH#8VARNAMA",
  },
  {
    _id: "5f96607069fccf0d2f874e82",
    name: "BABASAHEB AMBEDKAR GOVERNMENT POLYTECHNIC",
    address:
      "SADHU BAZAR, PS-TEHATTA, BETAI, DIST-NADIA, WEST BENGAL, PIN-741163",
  },
  {
    _id: "5f96607069fccf0d2f87375d",
    name: "BABASAHEB GAWDE INSTITUTE OF MANAGEMENT STUDIES",
    address: "DR. A. B. NAIR ROAD, MUMBAI CENTRAL, OPP NAIR DENTAL COLLEGE",
  },
  {
    _id: "5f96607069fccf0d2f87346d",
    name: "BABASAHEB NAIK COLLEGE OF ENGINEERING",
    address: "KAKADATI,KARLA ROAD, PUSADDIST.YAVATMAL (MS)",
  },
  {
    _id: "5f96607069fccf0d2f8735fa",
    name: "BABASAHEB PHADTARE POLYTECHNIC",
    address:
      "SR.NO.40/1KALAMB-WALCHANDNAGAR, TALUKA - INDAPUR, DISTRICT - PUNE",
  },
  {
    _id: "5f96607069fccf0d2f874b28",
    name: "BABU ANGAD SINGH COLLEGE OF PHARMACY",
    address:
      "VILLAGE & POST- KANNUPUR, TEHSIL- JALALPUR, DISTRICT- AMBEDKAR NAGAR",
  },
  {
    _id: "5f96607069fccf0d2f8746b5",
    name: "BABU BANARASI DAS ENGINEERING COLLEGE",
    address: "SECTOR-I, DR. AKHILESH DAS NAGAR, FAIZABAD ROAD",
  },
  {
    _id: "5f96607069fccf0d2f874964",
    name: "BABU BANARASI DAS NATIONAL INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "SECTOR-I, DR. AKHILESH DAS NAGAR, FAIZABAD ROAD,",
  },
  {
    _id: "5f96607069fccf0d2f8746c1",
    name: "BABU BANARASI DAS NORTHERN INDIA INSTITUTE OF TECHNOLOGY",
    address: "SECTOR-II, DR. AKHILESH DAS NAGAR, FAIZABAD ROAD,CHINHAT",
  },
  {
    _id: "5f96607069fccf0d2f874963",
    name: "BABU BANARASI DAS NORTHERN INDIA INSTITUTE OF TECHNOLOGY",
    address: "SECTOR-II, DR. AKHILESH DAS NAGAR,FAIZABAD ROAD ,CHINHAT",
  },
  {
    _id: "5f96607069fccf0d2f874a58",
    name: "BABU BANARASI DAS NORTHERN INDIA INSTITUTE OF TECHNOLOGY",
    address: "SECTOR- II, DR. AKHILESH DAS NAGAR , FAIZABAD ROAD, CHINHAT",
  },
  {
    _id: "5f96607069fccf0d2f874661",
    name: "BABU BANARSI DAS INSTITUTE OF ENGINEERING TECHNOLOGY & RESEARCH CENTRE",
    address:
      "25TH KM, ON BULANDSHAHR ANOOPSHAHR ROAD, NEAR JAHANGIRABAD, BULANDSHAHR, (NATIONAL CAPITAL REGION) 202391",
  },
  {
    _id: "5f96607069fccf0d2f87467a",
    name: "BABU BANARSI DAS INSTITUTE OF TECHNOLOGY",
    address:
      "7TH K.M. FROM GHAZIABAD ON N.H. 58, DELHI-MEERUT ROAD, DUHAI, GHAZIABAD (U.P.)",
  },
  {
    _id: "5f96607069fccf0d2f874660",
    name: "BABU BANRASI DAS NATIONAL INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    address: "SECTOR-I, DR. AKHILESH DAS NAGAR, FAIZABAD ROAD, LUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f874c3b",
    name: "BABU RAM CHANDRA SINGH COLLEGE OF PHARMACY",
    address: "LOHTO SARAIYA RUDAULI FAIZABAD",
  },
  {
    _id: "5f96607069fccf0d2f8747e4",
    name: "BABU SANT BUX SHIVMURTI SINGH ENGINEERING AND MEDICO INSTITUTE",
    address:
      "VILL. SANDAWA DUBANPOST. SAHABGANJDISTT. PRATAPGARHU.P.230137PIN 230137",
  },
  {
    _id: "5f96607069fccf0d2f874a2e",
    name: "BABU SATIRAM JAIKARAN SMARAK PHARMACY COLLEGE",
    address: "KAZIPUR,CHIRAIYAKOT, AZAMGARH, DISTT- AZAMGARH",
  },
  {
    _id: "5f96607069fccf0d2f874737",
    name: "BABU SUNDER SINGH INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address: "VILL. SHERPUR LAWAL NH-24B,RAEBARELLY ROAD,NIGOHANLUCKNOW",
  },
  {
    _id: "5f96607069fccf0d2f8747a3",
    name: "BABU SUSHIL SINGH INSTITUTE OF POLYTECHNIC",
    address: "NIBI BELISA, POST- SADAR",
  },
  {
    _id: "5f96607069fccf0d2f874a25",
    name: "BABU YUGRAJ SINGH PHARMACY COLLEGE",
    address: "GATA NO= 49 BABHAMAU, GOMTI NAGAR EXTENSTION SECTOR-6",
  },
  {
    _id: "5f96607069fccf0d2f873394",
    name: "BABULAL TARABAI INSTITUTE OF PHARMACEUTICAL SCIENCE",
    address: "NH 26 NARSINGHPUR ROAD, SIRONJA SAGAR",
  },
  {
    _id: "5f96607069fccf0d2f87322c",
    name: "BABULAL TARABAI INSTITUTE OF RESEARCH & TECHNOLOGY",
    address: "N.H. 26, NARSINGHPUR ROAD, GRAM-SIRONJA",
  },
  {
    _id: "5f96607069fccf0d2f8724f6",
    name: "BADDIUZAMA KHAN POLYTECHNIC INSTITUTE",
    address:
      "BADDIUZAMA KHAN POLYTECHNIC INSTITUTEAT+PO-JANIPUR,PS-NANPUR,PUPRI,DIST-SITAMARHI,BIHAR,PIN-843333",
  },
  {
    _id: "5f96607069fccf0d2f873b16",
    name: "BADRIPRASAD INSTITUTE OF TECHNOLOGY",
    address: "AT-DEBAIPALI,POST-SASON,DIST-SAMBALPUR",
  },
  {
    _id: "5f96607069fccf0d2f8745b1",
    name: "BADRUKA COLLEGE PG CENTRE",
    address: "3-2-847,STATION ROAD,KACHIGUDA,",
  },
  {
    _id: "5f96607069fccf0d2f874b94",
    name: "BADULI PHARMACY COLLEGE",
    address: "VILL - BADULI, POST - KOTA, DISTT - SAHARANPUR (UTTAR PRADESH)",
  },
  {
    _id: "5f96607069fccf0d2f874e39",
    name: "BAGHMUNDI GOVERNMENT POLYTECHNIC",
    address: "P.O. PATHARDIHP.S. BAGHMUNDIDIST. PURULIAW.B. - 723152",
  },
  {
    _id: "5f96607069fccf0d2f873218",
    name: "BAGULA MUKHI COLLEGE OF TECHNOLOGY",
    address:
      "VILLAGE MUGALIYA KOT, NEAR SUKHI SEVANIYA, VIDISHA ROAD, BHOPAL (M.P.)",
  },
  {
    _id: "5f96607069fccf0d2f8746d5",
    name: "BAHAB DR. BHIM RAM AMBEDKAR COLLEGE OF AGRIL. ENGG. & TECHNOLOGY, ETAWAH (CSAUA&T, KANPUR)",
    address:
      "BABA SAHAB DR. BHIM RAO AMBEDKAR COLLEGE OF AGRIL. ENGG. & TECHNOLOGY ETAWAH NEAR GOVT. HOSPITAL AGRA ROAD ETAWAH",
  },
  {
    _id: "5f96607069fccf0d2f873c07",
    name: "BAHRA GROUP OF INSTITUTION",
    address:
      "PATIALA-SANGRUR NATIONAL HIGHWAY,VILLAGE BHEDPURA,TEHSIL & DISTRICT PATIALA,PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f872b0b",
    name: "BAHRA INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    address: "NH-71 A, VILL- CHIDANA, TEH- GOHANA, DISTT- SONEPAT",
  },
  {
    _id: "5f96607069fccf0d2f873cd6",
    name: "BAHRA INSTITUTE OF PHARMACY",
    address:
      "PATIALA- SANGRUR NATIONAL HIGHWAY, VILLAGE BHEDPURA, TECHSIL & DISTRICT PATIALA,PUNJAB",
  },
  {
    _id: "5f96607069fccf0d2f873c54",
    name: "BAHRA POLYTECHNIC COLLEGE",
    address: "PATIALA-SANGRUR NATIONAL HIGHWAY",
  },
  {
    _id: "5f96607069fccf0d2f872dc3",
    name: "BAHUBALI COLLEGE OF ENGINEERING",
    address: "GOMMATANAGARASHRAVANABELAGOLA - 573135HASSAN DISTRICTKARNATAKA",
  },
  {
    _id: "5f96607069fccf0d2f874869",
    name: "BAIJNATH RAMNARESH COLLEGE OF TECHNOLOGY",
    address: "VILL- TELHUA CHAKWALLI KAZIPUR, POST- CHIRRIAYAKOT",
  },
  {
    _id: "5f96607069fccf0d2f8735cc",
    name: "BAJAJ CHANDRAPUR POLYTECHNIC CHANDRAPUR",
    address: "BALAJI WARD,NEAR FISH MARKET,",
  },
  {
    _id: "5f96607069fccf0d2f87352c",
    name: "BAJAJ INSTITUTE OF TECHNOLOGY,",
    address:
      "ARVI ROAD, IN FRONT OF RAMKRISHNA BAJAJ AGRICULTURE COLLEGE, PIPRI - WARDHA",
  },
  {
    _id: "5f96607069fccf0d2f87393c",
    name: "BAJIRAOJI KARANJEKAR COLLEGE OF PHARMACY",
    address: "JAMNAPUR, NAGZIRA ROAD, SAKOLIDIST-BHANDARA",
  },
  {
    _id: "5f96607069fccf0d2f873a18",
    name: "BAJIRAOJI KARANJEKAR COLLEGE OF PHARMACY SAKOLI",
    address: "NAGZIRA ROAD SAKOLI",
  },
  {
    _id: "5f96607069fccf0d2f873b35",
    name: "BAJIROUT INSTITUTE OF ENGINEERING AND TECHNOLOGY (POLYTECHNIC)",
    address: "AT. TALABARKOTE, NEAR SPINNING MILLPO. GOVINDPUR",
  },
  {
    _id: "5f96607069fccf0d2f874792",
    name: "BAKHSHI POLYTECHNIC",
    address: "MUJAR MACHHLI SHAHAR JAUNPUR, U.P.",
  },
  {
    _id: "5f96607069fccf0d2f8724c3",
    name: "BAKHTIYARPUR COLLEGE OF ENGINEERING",
    address: "OLD- IIT CAMPUSNEW GOVERNMENT POLYTECHNICPATLIPUTRA, PATNA",
  },
  {
    _id: "5f96607069fccf0d2f872475",
    name: "BAKSA POLYTECHNIC",
    address: "BELGURI PATHARBAKSA",
  },
  {
    _id: "5f96607069fccf0d2f873d55",
    name: "BAL KRISHNA INSTITUTE OF TECHNOLOGY",
    address: "IPC-15, RIICO INSTITUTIONAL AREA, RANPUR, KOTA",
  },
  {
    _id: "5f96607069fccf0d2f873685",
    name: "BALAGHAT POLYTECHNIC,RUDDHA-AHMEDPUR",
    address: "NANDED-LATUR HIGHWAY ROAD, RUDDHA-AHMEDPUR",
  },
  {
    _id: "5f96607069fccf0d2f8726aa",
    name: "BALAJEE POLYTECHNIC",
    address: "MARUPALLI VILLGAJAPATHI NAGARAM MANDALVIZIANAGARAM DIST",
  },
  {
    _id: "5f96607069fccf0d2f872538",
    name: "BALAJI COLLAGE OF PHARMACY",
    address: "BARPAILA, GUTHANI, SIWAN, BIHAR",
  },
  {
    _id: "5f96607069fccf0d2f8727fb",
    name: "BALAJI COLLEGE OF PHARMACY",
    address: "RUDRMPETA, ALAMURU-POST ANANTAPUR-515001",
  },
  {
    _id: "5f96607069fccf0d2f872c40",
    name: "BALAJI COLLEGE OF PHARMACY",
    address: "ADARSH NAGAR, BALLABHGARH, FARIDABAD, HARYANA",
  },
  {
    _id: "5f96607069fccf0d2f874cb6",
    name: "BALAJI COLLEGE OF PHARMACY",
    address: "OPP GGIC BADHLA ROAD KILA PARIKSHIT GARH MEERUT",
  },
  {
    _id: "5f96607069fccf0d2f87297a",
    name: "BALAJI ENGINEERING COLLEGE",
    address: "AT MAKHIYALA JUNAGADH-DHORAJI HIGHWAYJUNAGADH",
  },
  {
    _id: "5f96607069fccf0d2f8729e5",
    name: "BALAJI INSTITUTE OF ENGINEERING & TECHNOLOGY",
    address: "JUNAGADH-DHORAJI HIGH WAY",
  },
  {
    _id: "5f96607069fccf0d2f873f43",
    name: "BALAJI INSTITUTE OF ENGINEERING AND TECHNOLOGY",
    address:
      "THANDALAM VILLAGE,THIRUPORUR,CHENGALPATTU TALUKKANCHEEPURAM DISTRICT",
  },
  {
    _id: "5f96607069fccf0d2f8737a6",
    name: "BALAJI INSTITUTE OF INTERNATIONAL BUSINESS (BIIB)",
    address: "S.NO.-55/2-7, VILLAGE TATHAWADE",
  },
  {
    _id: "5f96607069fccf0d2f872709",
    name: "BALAJI INSTITUTE OF IT AND MANAGEMENT",
    address:
      "OPP:79/51-1NARAPAREDDY VILLASRAMACHANDRAPURAM(V)RAMARAJUPALLI(P)KADAPA-516003",
  },
  {
    _id: "5f96607069fccf0d2f87377b",
    name: "BALAJI INSTITUTE OF MANAGEMENT AND HUMAN RESOURCE DEVELOPMENT (BIMHRD)",
    address: "SURVEY NO. 55/2-7, TATHAWADE, PUNE-411033",
  },
  {
    _id: "5f96607069fccf0d2f874569",
    name: "BALAJI INSTITUTE OF MANAGEMENT SCIENCES",
    address: "LAKNEPALLY (V), NARSAMPET (M), DIST: WARANGAL - 506331",
  },
  {
    _id: "5f96607069fccf0d2f8737e1",
    name: "BALAJI INSTITUTE OF MODERN MANAGEMENT(BIMM)",
    address: "S.NO 55/2-7 TATHAWADE,PUNE 411033",
  },
  {
    _id: "5f96607069fccf0d2f87463b",
    name: "BALAJI INSTITUTE OF PHARMACEUTICAL SCIENCES",
    address: "LAKNEPALLY (V), NARSAMPET (M), DIST: WARANGAL - 506331 (AP)",
  },
  {
    _id: "5f96607069fccf0d2f874643",
    name: "BALAJI INSTITUTE OF PHARMACY",
    address: "LAKNEPALLY (V), NARSAMPET, DIST: WARANGAL - 506331 (A.P.)",
  },
  {
    _id: "5f96607069fccf0d2f8743d9",
    name: "BALAJI INSTITUTE OF TECHNOLOGY & SCIENCE",
    address: "LAKNEPALLY (V), NARSAMPET (M), DIST: WARANGAL - 506331 (AP)",
  },
  {
    _id: "5f96607069fccf0d2f873ac9",
    name: "BALAJI INSTITUTE OF TECHNOLOGY & SCIENCE (POLYTECHNIC)",
    address:
      "AT-KNOWLEDGE CENTRE, VILLAGE/PO-KHARLINGIPS/TALUK: GUNUPURDISTRICT: RAYAGADAPIN: 765022ORISSA",
  },
  {
    _id: "5f96607069fccf0d2f873791",
    name: "BALAJI INSTITUTE OF TELECOM & MANAGEMENT",
    address: "S.NO.55/2-7,TATHAWADE",
  },
  {
    _id: "5f96607069fccf0d2f872eb8",
    name: "BALAJI POLYTECHNIC",
    address: "BEHIND P&T QUARTERS ,OLD JEWARGI ROAD,GULBARGA.",
  },
  {
    _id: "5f96607069fccf0d2f873e04",
    name: "BALAJI POLYTECHNIC COLLEGE",
    address: "AIRPORT ROAD PRATAPNAGAR, NEAR TRANSPORT NAGAR, UDAIPUR(RAJ.)",
  },
  {
    _id: "5f96607069fccf0d2f873648",
    name: "BALASAHEB MHATRE POLYTECHNIC",
    address: "BADLAPUR(W), AT KANHOR , TALUKA AMBERNATH DIST THANE",
  },
  {
    _id: "5f96607069fccf0d2f8729ec",
    name: "BALASINOR COLLEGE OF POLYTECHNIC",
    address:
      "BAIDAP ROAD,AT. PO. BALASINOR,DIST - KHEDA,GUJARAT STATE(INDIA), PIN-388255.",
  },
  {
    _id: "5f96607069fccf0d2f873aba",
    name: "BALASORE COLLEGE OF ENGINEERING AND TECHNOLOGY",
    address: "SERGARHBALASOREORISSAPIN-756060",
  },
  {
    _id: "5f96607069fccf0d2f873b43",
    name: "BALASORE SCHOOL OF ENGINEERING",
    address: "AT-SRIKANTHAPUR(NEAR RANIPATNA)PO/DIST-BALASORE",
  },
  {
    _id: "5f96607069fccf0d2f87389b",
    name: "BALAWANTRAO CHAVAN COLLEGE OF PHARMACY",
    address: "AT POST NAIGAON (BZ) LALWANDI ROAD TQ NAIGAON (KH) DIST. NANDED",
  },
  {
    _id: "5f96607069fccf0d2f873d78",
    name: "BALDEV RAM MIRDHA INSTITUTE OF TECHNOLOGY (EAST CAMPUS)",
    address: "ITS-3, IT-PARK, EPIP, SITAPURA, JAIPUR-302022",
  },
  {
    _id: "5f96607069fccf0d2f872d78",
    name: "BALLARI INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    address:
      "FORMERLY BELLARY ENGINEERING COLLEGE,\\JNANA GANGOTRI\\ CAMPUS, #873/2 OF KOLAGAL VILLAGE,BELLARY-HOSPET ROAD, ALLIPUR",
  },
  {
    _id: "5f96607069fccf0d2f873587",
    name: "BALLARPUR INSTITUTE OF TECHNOLOGY",
    address: "MAUJA BAHMNI,RAJURA ROAD, TEHSIL BALLARPUR, DISTICT CHANDRAPUR.",
  },
  {
    _id: "5f96607069fccf0d2f8736bd",
    name: "BALLARPUR SEWASAMITI'S JAINUDDIN ZAWERI POLYTECHNIC",
    address: "AT. RAMPUR TUKUM, TAH: MUL, DIST: CHANDRAPUR",
  },
  {
    _id: "5f96607069fccf0d2f874cc6",
    name: "BALRAM SINGH INSTITUTE OF SCIENCE AND TECHNOLGY",
    address: "2.K.M STONE GWALIOR ROAD DHOOMAN PURA ETAWAH",
  },
  {
    _id: "5f96607069fccf0d2f874d08",
    name: "BALWANT SINGH MUKHIYA (BSM) COLLEGE OF ENGINEERING",
    address: "2 KM. MILESTONEROORKEE LAKSAR ROAD VILL- NAGLA IMARTI, ROORKEE",
  },
  {
    _id: "5f96607069fccf0d2f874d4d",
    name: "BALWANT SINGH MUKHIYA(BSM) COLLEGE OF POLYTECHNIC",
    address: "2 KM. MILESTONE ROORKEE LAKSAR ROAD VILL- NAGLA IMARTI ,ROORKEE",
  },
  {
    _id: "5f96607069fccf0d2f874b64",
    name: "BANARAS INSTITUTE OF PHARMACY",
    address: "NIBAH, BABATPUR, VARANASI 221",
  },
  {
    _id: "5f96607069fccf0d2f87478d",
    name: "BANARAS INSTITUTEOF POLYTECHNIC & ENGINEERING",
    address: "VILLAGE GAJOKHAR POST PARSARA PHOOLPUR",
  },
  {
    _id: "5f96607069fccf0d2f872914",
    name: "BANARSIDAS CHANDIWALA INSTITUTE OF HOTEL MANAGAMENT & CATERING TECHNOLOGY",
    address: "CHANDIWALA ESTATE MAA ANANDMAI MARG, KALKAJI NEW DELHI. (110019)",
  },
  {
    _id: "5f96607069fccf0d2f872937",
    name: "BANARSIDAS CHANDIWALA INSTITUTE OF INFORMATION TECHNOLOGY",
    address: "CHANDIWALA ESTATE MAA ANAND MAI MARG KALKAJINEW DELHI",
  },
  {
    _id: "5f96607069fccf0d2f87291f",
    name: "BANARSIDAS CHANDIWALA INSTITUTE OF PROFESSIONAL STUDIES",
    address: "PLOT NO 9, SECTOR 11,DWARKA, NEW DELHI 110075",
  },
];

export default collegeSet;
