import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
// import { useParams } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import endPoints, { dEndPoints } from "../../config/end-point";
import CreatableSelect from "react-select/creatable";
//import skillSet from "../../utils/skills";
import { showLoader, showSnackBar } from "../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";
const CreateBucket = (props) => {
  //let { bucketId } = useParams();
  const [value, setValue] = useState("Permanent");
  const [query, setQuery] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [getBucket, setBucket] = useState({});
  const [bucketDetails, setBucketDetails] = useState({
    bucketName: "",
    bucketType: "",
    userGroup: "",
    isActive: true,
    status: [],
    selectQuery: [],
    cName: [],
    skills: [],
    query: [],
  });
  var bucketId = props.match.params.bucketId;
  useEffect(() => {
    loadQueryData();
    if (bucketId) {
      getBucketById();
    }
    loadCorporate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketId]);

  const userType = localStorage.getItem("ut");
  const loadCorporate = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    var pageNum = 1;
    var sort_createdon = -1;
    if (userType === "1110") {
      props.showLoader({ show: true });
      axios
        .get(endPoints.getAllCorporateList, {
          params: {
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          setCompanyList(data.data);
          //console.log("data", companyList);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          //console.log("error while fetching data", error);
        });
    }
    if (userType === "1000") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.getVendorAllCorporateList(vendorCorporateId), {
          params: {
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          setCompanyList(data.data);
          //console.log("data", companyList);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          //console.log("error while fetching data", error);
        });
    }
  };

  const loadQueryData = () => {
    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    axios
      .get(dEndPoints.loadBucketQuery(userId))
      .then((res) => {
        props.showLoader({ show: false });
        let data = res.data;

        if (data && data.code === 100185) {
          setQuery(data.data);
          //setDataLoaded(true);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };
  const updateBucket = () => {
    /// var bucketId;
    var bucket = { ...bucketDetails };
    const userId = localStorage.getItem("i");
    delete bucket.cName;
    delete bucket.selectQuery;
    delete bucket.skills;
    delete bucket.status;
    if (userType === "1110" || "1100") {
      props.showLoader({ show: true });
      const corporateId = localStorage.getItem("ci");
      axios
        .patch(
          dEndPoints.updateBucketCorporate(corporateId, userId, bucketId),
          bucket
        )
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;

          if (data && data.code === 100179) {
            props.showSnackBar({
              message: "Successfully Updated Job Bucket",
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            props.history.push("/settings/bucketSettings");
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      props.showLoader({ show: true });
      const vendorCorporateId = localStorage.getItem("vci");
      axios
        .patch(
          dEndPoints.updateBucketVendor(vendorCorporateId, userId, bucketId),
          bucket
        )
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100179) {
            props.showSnackBar({
              message: "Successfully Updated Job Bucket",
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });

            props.history.push("/settings/bucketSettings");
          }
          if (data && data.code === 100185) {
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  const deleteBucket = () => {
    var bucketId = props.match.params.bucketId;
    props.showLoader({ show: true });
    if (props.match.params.bucketId) {
      axios
        .delete(dEndPoints.deleteBucket(bucketId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          // if (data && data.code === 100179) {
          props.showSnackBar({
            message: "Successfully Deleted Bucket",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });

          props.history.push("/settings/bucketSettings");
          // }
          if (data && data.code === 100185) {
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  const getBucketById = () => {
    var bucketId = props.match.params.bucketId;
    props.showLoader({ show: true });
    if (props.match.params.bucketId) {
      axios
        .get(dEndPoints.gebucketId(bucketId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100178) {
            setBucket(data.data);
            setBucketDetails(data.data);
            //setDataLoaded(true);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  const createBucket = () => {
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");
    const userId = localStorage.getItem("i");
    var bucket = { ...bucketDetails };
    //delete bucket.selectQuery;

    if (bucket.status.length > 0) {
      bucket.query.push({ status: bucket.status });
    }
    if (bucket.skills.length > 0) {
      bucket.query.push({ skills: bucket.skills });
    }
    if (bucket.cName.length > 0) {
      bucket.query.push({ cName: bucket.cName });
    }

    if (userType === "1100") {
      bucket.userGroup = "Corporate";
      bucket.isActive = true;
      bucket.bucketType = "User";
      props.showLoader({ show: true });
      axios({
        method: "POST",
        url: dEndPoints.createBucket(corporateId, userId),
        data: bucket,
      })
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;

          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          props.history.push("/dashboard");
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else if (userType === "1000") {
      bucket.userGroup = "Vendor";
      bucket.isActive = true;
      bucket.bucketType = "User";
      props.showLoader({ show: true });
      axios({
        method: "POST",
        url: dEndPoints.createVendorBucket(vendorCorporateId, userId),
        data: bucket,
      })
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;

          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          props.history.push("/dashboard");
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else if (userType === "1110") {
      props.showLoader({ show: true });
      axios({
        method: "POST",
        url: dEndPoints.createBucket(corporateId, userId),
        data: bucket,
      })
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;

          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          props.history.push("/dashboard");
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  const skillss = query.map((item) => {
    //console.log("item", item);
    return item;
  });

  // const getQuery = skillss.map((item, i) => {
  //   return item.query;
  // });
  // const getNewQuery = skillss[0].query;

  const getQueries = [];
  var closeStatus, openStatus, inActivestatus, draftStatus, allJobs;
  var skillJob, getSkills;
  for (var i = 0; i < skillss.length; i++) {
    for (var j = 0; j < skillss[i].query.length; j++) {
      var newDay = skillss[i].query.filter((e) => e);

      let closeJob = newDay.some((job) => job["status"] === "Close");
      let openJobs = newDay.some((job) => job["status"] === "Open");
      let inActiveJobs = newDay.some((job) => job["status"] === "Inactive");
      let draftJobs = newDay.some((job) => job["status"] === "Draft");
      let skillsJobs = newDay.filter((job) => job.skills);
      let newSkillsJobs = newDay.some((job) => job["skills"]);

      if (closeJob === true) {
        closeStatus = { label: newDay[0].status, value: newDay[0].status };
        getQueries.push(closeStatus);
      }
      if (newSkillsJobs === true && skillsJobs[0].skills.length > 29) {
        skillJob = skillsJobs[0].skills;

        getSkills = skillJob.map((item, i) => {
          return { label: item, value: item };
        });
      }
      if (openJobs === true) {
        openStatus = { label: newDay[0].status, value: newDay[0].status };
        //getQueries.push(openStatus);
      }
      if (inActiveJobs === true) {
        inActivestatus = { label: newDay[0].status, value: newDay[0].status };
        getQueries.push(inActivestatus);
      }
      if (draftJobs === true) {
        draftStatus = { label: newDay[0].status, value: newDay[0].status };
        getQueries.push(draftStatus);
      }

      const getJob = Array.isArray(newDay[0].status);
      if (getJob === true) {
        allJobs = {
          label: newDay[0].status.toString(),
          value: newDay[0].status.toString(),
        };
      }
    }
  }

  getQueries.push(openStatus);

  const handleChangePrimarySkill = (value) => {
    var cbucket = { ...bucketDetails };
    cbucket.selectQuery = value ? value.map((el) => el.value) : [];
    setBucketDetails(cbucket);
  };

  const handleChangeQueryBucket = (value) => {
    var cbucket = { ...bucketDetails };
    cbucket.query = value ? value.map((el) => el.value) : [];
    setBucketDetails(cbucket);
  };

  function handleInputChange(event) {
    var cjob = { ...bucketDetails };

    if (event.target.name === "bucketName") {
      cjob[event.target.name] = event.target.value;
    } else if (
      event.target.name === "bucketType" ||
      event.target.name === "userGroup"
    ) {
      cjob[event.target.name] = event.target.value;
    } else if (event.target.name === "isActive") {
      cjob[event.target.name] = Boolean(event.target.value);
    } else if (event.target.name === "query") {
      cjob[event.target.name] = event.target.value;
    }
    setBucketDetails({ ...bucketDetails, ...cjob });
  }
  // const newValue = [];
  const handleChangeCompany = (value) => {
    var cjob = { ...bucketDetails };

    cjob.cName = value ? value.map((el) => el.value) : [];
    setBucketDetails(cjob);
  };
  const handleChangeStat = (value) => {
    var cjob = { ...bucketDetails };

    cjob.status = value ? value.map((el) => el.value) : [];
    setBucketDetails(cjob);
  };
  const handleChangeStatQuery = (value) => {
    var cjob = { ...bucketDetails };

    cjob.query = value ? value.map((el) => el.value) : [];
    setBucketDetails(cjob);
  };
  const handleChangeSkills = (value) => {
    var cjob = { ...bucketDetails };

    cjob.skills = value ? value.map((el) => el.value) : [];
    setBucketDetails(cjob);
  };
  const handleChange = (val) => {
    var cjob = { ...bucketDetails };
    cjob.bucketType = val;
    setValue(val);
    setBucketDetails({ ...bucketDetails, ...cjob });
  };
  const handleChangeGroup = (val) => {
    var cjob = { ...bucketDetails };
    cjob.userGroup = val;
    setValue(val);
    setBucketDetails({ ...bucketDetails, ...cjob });
  };
  const handleChangeStatus = (val) => {
    var cjob = { ...bucketDetails };
    cjob.isActive = val;
    setValue(val);
    setBucketDetails({ ...bucketDetails, ...cjob });
  };

  const getCompany = companyList.map((item, i) => {
    return { label: item.companyName, value: item.companyName };
  });

  const selectBucket = [
    { label: "skills", value: "skills" },
    { label: "company", value: "company" },
  ];
  var selectCorporateBucket;
  if (userType === "1100") {
    selectCorporateBucket = [
      { label: "skills", value: "skills" },
      { label: "status", value: "status" },
    ];
  }
  if (userType === "1110") {
    selectCorporateBucket = [
      { label: "skills", value: "skills" },
      { label: "status", value: "status" },
      { label: "company", value: "company" },
    ];
  }
  const statusBucket = [
    { label: "Open", value: "Open" },
    { label: "Draft", value: "Draft" },
    { label: "Awaiting Approval", value: "Awaiting Approval" },
    { label: "Close", value: "Close" },
    { label: "Inactive", value: "Inactive" },
  ];
  if (bucketId) {
    bucketDetails.bucketName = bucketDetails.bucketName;
    bucketDetails.bucketType = bucketDetails.bucketType;
    bucketDetails.isActive = bucketDetails.isActive;
    bucketDetails.query = bucketDetails.query;
    bucketDetails.userGroup = bucketDetails.userGroup;
  }

  var getQue;
  if (bucketDetails.query && bucketId) {
    getQue = bucketDetails.query.map((item) => Object.keys(item)[0]);
  }

  var bucketId = props.match.params.bucketId;
  // var newSkill;

  var newFetch =
    bucketDetails && bucketDetails.query
      ? bucketDetails.query.map((ele) => ({
          newSkill: Object.values(ele)[0],
          newArray: Object.keys(ele)[0],
        }))
      : "";

  var finalStatus = [];
  var finalSkills = [];
  var finalCompany = [];

  for (let i = 0; i < newFetch.length; i++) {
    if (newFetch[i].newArray === "status") {
      finalStatus.push(newFetch[i].newSkill);
    }
    if (newFetch[i].newArray === "skills") {
      finalSkills.push(newFetch[i].newSkill);
    }
    if (newFetch[i].newArray === "cName") {
      finalCompany.push(newFetch[i].newSkill);
    }
  }

  var firstArrayStatus = finalStatus[0];

  var firstArraySkills = finalSkills[0];

  var firstArraycName = finalCompany[0];

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <GoBackButton />
          <div className="create__bucket-component card__view-tq text-center my-5">
            {bucketId ? (
              <h2 className="pageHeadings">Update Bucket</h2>
            ) : (
              <h2 className="pageHeadings">Create Bucket</h2>
            )}
            {userType === "1110" || userType === "1100" ? (
              <small> (Corporate)</small>
            ) : userType === "1000" ? (
              <small> (Vendor)</small>
            ) : (
              ""
            )}
            <Form className="tq-form pt-4 text-left">
              <Row className="mb-5">
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="custom__label">
                    Bucket Name
                    <Tooltip title="Name your job bucket">
                      <span className="info__button">
                        <i className="bx bx-info-circle"></i>
                      </span>
                    </Tooltip>
                  </Form.Label>
                  <Form.Control
                    name="bucketName"
                    type="text"
                    value={bucketDetails.bucketName}
                    onChange={handleInputChange}
                    placeholder="Bucket Name eg. 'Close Job'"
                  />
                </Form.Group>
              </Row>

              <Row className="mb-5 ">
                {userType === "1110" ? (
                  <Form.Group as={Col}>
                    <Form.Label className="custom__label">
                      Bucket Type
                      <Tooltip title="Select Bucket Type">
                        <span className="info__button">
                          <i className="bx bx-info-circle"></i>
                        </span>
                      </Tooltip>
                    </Form.Label>
                    <ToggleButtonGroup
                      className="d-block"
                      type="radio"
                      value={bucketDetails.bucketType}
                      name="bucketType"
                      defaultValue="System"
                      onChange={handleChange}
                    >
                      <ToggleButton
                        variant="userGroup"
                        id="tbg-system"
                        value="System"
                      >
                        System
                      </ToggleButton>
                      <ToggleButton
                        variant="userGroup"
                        id="tbg-user"
                        value="User"
                      >
                        User
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                ) : (
                  ""
                )}
                {userType === "1110" ? (
                  <Form.Group as={Col} xs={5}>
                    <Form.Label className="custom__label">
                      User Group
                      <Tooltip title="Select User Group Bucket">
                        <span className="info__button">
                          <i className="bx bx-info-circle"></i>
                        </span>
                      </Tooltip>
                    </Form.Label>
                    <ToggleButtonGroup
                      className="d-block"
                      type="radio"
                      value={bucketDetails.userGroup}
                      name="userGroup"
                      defaultValue="Corporate"
                      onChange={handleChangeGroup}
                    >
                      <ToggleButton
                        variant="userGroup"
                        id="tbg-radio-1"
                        value="Admin"
                      >
                        Admin
                      </ToggleButton>
                      <ToggleButton
                        variant="userGroup"
                        id="tbg-radio-2"
                        value="Corporate"
                      >
                        Corporate
                      </ToggleButton>
                      <ToggleButton
                        variant="userGroup"
                        id="tbg-radio-3"
                        value="Vendor"
                      >
                        Vendor
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                ) : (
                  ""
                )}
                {bucketId || userType === "1110" ? (
                  <Form.Group as={Col} xs={4}>
                    <Form.Label className="custom__label">
                      Bucket Status
                      <Tooltip title="Change Bucket status to enable or disable job bucket  ">
                        <span className="info__button">
                          <i className="bx bx-info-circle"></i>
                        </span>
                      </Tooltip>
                    </Form.Label>
                    <ToggleButtonGroup
                      className="d-block"
                      type="radio"
                      name="isActive"
                      defaultValue={true}
                      value={bucketDetails.isActive}
                      onChange={handleChangeStatus}
                    >
                      <ToggleButton
                        variant="userGroup inactive"
                        id="tbg-false"
                        value={false}
                      >
                        In-Active
                      </ToggleButton>
                      <ToggleButton
                        variant="userGroup"
                        id="tbg-true"
                        value={true}
                      >
                        Active
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                ) : (
                  ""
                )}
              </Row>

              <Row className="mb-5">
                {getBucket.query ? (
                  <Form.Group as={Col} xs={12}>
                    <Form.Label className="custom__label">
                      Bucket Query
                      <Tooltip title="Select query for your job bucket like skills based job bucket, company based">
                        <span className="info__button">
                          <i className="bx bx-info-circle"></i>
                        </span>
                      </Tooltip>
                    </Form.Label>
                    <CreatableSelect
                      inputId="selectQuery"
                      TextFieldProps={{
                        label: "selectQuery*",
                        InputLabelProps: {
                          htmlFor: "selectQuery",
                          shrink: true,
                        },
                      }}
                      placeholder="Bucket Query eg. 'Close Job'"
                      options={selectBucket}
                      value={
                        bucketDetails && bucketDetails.query
                          ? bucketDetails.query.map((ele) => ({
                              label: Object.keys(ele)[0],
                              value: Object.keys(ele)[0],
                            }))
                          : []
                      }
                      onChange={handleChangeQueryBucket}
                      required
                      isOpen={true}
                      isMulti
                    />
                  </Form.Group>
                ) : userType === "1000" ? (
                  <Form.Group as={Col} xs={12}>
                    <Form.Label className="custom__label">
                      Bucket Query
                      <Tooltip title="Create Bucket">
                        <span className="info__button">
                          <i className="bx bx-info-circle"></i>
                        </span>
                      </Tooltip>
                    </Form.Label>
                    <CreatableSelect
                      inputId="selectQuery"
                      TextFieldProps={{
                        label: "selectQuery*",
                        InputLabelProps: {
                          htmlFor: "selectQuery",
                          shrink: true,
                        },
                      }}
                      placeholder="Bucket Query eg. 'Close Job'"
                      options={selectBucket}
                      value={
                        bucketDetails && bucketDetails.selectQuery
                          ? bucketDetails.selectQuery.map((ele) => ({
                              label: ele,
                              value: ele,
                            }))
                          : []
                      }
                      onChange={handleChangePrimarySkill}
                      required
                      isOpen={true}
                      isMulti
                    />
                  </Form.Group>
                ) : userType === "1110" ? (
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Bucket Query</Form.Label>
                    <CreatableSelect
                      inputId="selectQuery"
                      TextFieldProps={{
                        label: "selectQuery*",
                        InputLabelProps: {
                          htmlFor: "selectQuery",
                          shrink: true,
                        },
                      }}
                      placeholder="Bucket Query eg. 'Close Job'"
                      options={selectCorporateBucket}
                      value={
                        bucketDetails && bucketDetails.selectQuery
                          ? bucketDetails.selectQuery.map((ele) => ({
                              label: ele,
                              value: ele,
                            }))
                          : []
                      }
                      onChange={handleChangePrimarySkill}
                      required
                      isOpen={true}
                      isMulti
                    />
                  </Form.Group>
                ) : userType === "1100" ? (
                  <Form.Group as={Col} xs={12}>
                    <Form.Label className="custom__label">
                      Bucket Query
                      <Tooltip title="Select query for your job bucket like skills based job bucket, status based">
                        <span className="info__button">
                          <i className="bx bx-info-circle"></i>
                        </span>
                      </Tooltip>
                    </Form.Label>
                    <CreatableSelect
                      inputId="selectQuery"
                      TextFieldProps={{
                        label: "selectQuery*",
                        InputLabelProps: {
                          htmlFor: "selectQuery",
                          shrink: true,
                        },
                      }}
                      placeholder="Bucket Query eg. 'Close Job'"
                      options={selectCorporateBucket}
                      value={
                        bucketDetails && bucketDetails.selectQuery
                          ? bucketDetails.selectQuery.map((ele) => ({
                              label: ele,
                              value: ele,
                            }))
                          : []
                      }
                      onChange={handleChangePrimarySkill}
                      required
                      isOpen={true}
                      isMulti
                    />
                  </Form.Group>
                ) : (
                  ""
                )}
              </Row>

              <Row className="mb-5">
                {bucketDetails.selectQuery &&
                bucketDetails.selectQuery.length > 0 &&
                bucketDetails.selectQuery.includes("skills") ? (
                  <Form.Group as={Col}>
                    <Form.Label>Skills</Form.Label>
                    <CreatableSelect
                      inputId="skills"
                      TextFieldProps={{
                        label: "skills*",
                        InputLabelProps: {
                          htmlFor: "skills",
                          shrink: true,
                        },
                      }}
                      //placeholder="Status eg. 'Close Job'"
                      options={getSkills}
                      value={
                        bucketDetails && bucketDetails.skills
                          ? bucketDetails.skills.map((ele) => ({
                              label: ele,
                              value: ele,
                            }))
                          : []
                      }
                      onChange={handleChangeSkills}
                      isMulti
                      required
                      isOpen={true}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group as={Col}>
                    {getQue && bucketId && getQue.includes("skills") ? (
                      <>
                        <Form.Label>Skills</Form.Label>
                        <CreatableSelect
                          inputId="skills"
                          TextFieldProps={{
                            label: "skills*",
                            InputLabelProps: {
                              htmlFor: "skills",
                              shrink: true,
                            },
                          }}
                          options={getSkills}
                          value={
                            firstArraySkills && firstArraySkills
                              ? firstArraySkills.map((ele) => ({
                                  label: ele,
                                  value: ele,
                                }))
                              : []
                          }
                          onChange={handleChangeSkills}
                          isMulti
                          required
                          isOpen={true}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
                {bucketDetails.selectQuery &&
                bucketDetails.selectQuery.length > 0 &&
                bucketDetails.selectQuery.includes("company") ? (
                  <Form.Group as={Col}>
                    <Form.Label>Company</Form.Label>

                    <CreatableSelect
                      inputId="cName"
                      TextFieldProps={{
                        label: "cName*",
                        InputLabelProps: {
                          htmlFor: "cName",
                          shrink: true,
                        },
                      }}
                      options={getCompany}
                      value={
                        bucketDetails && bucketDetails.cName
                          ? bucketDetails.cName.map((ele) => ({
                              label: ele,
                              value: ele,
                            }))
                          : []
                      }
                      onChange={handleChangeCompany}
                      isMulti
                      required
                      isOpen={true}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group as={Col}>
                    {getQue && bucketId && getQue.includes("cName") ? (
                      <>
                        <Form.Label>Company</Form.Label>

                        <CreatableSelect
                          inputId="cName"
                          TextFieldProps={{
                            label: "cName*",
                            InputLabelProps: {
                              htmlFor: "cName",
                              shrink: true,
                            },
                          }}
                          //placeholder="Status eg. 'Close Job'"
                          options={getCompany}
                          value={
                            firstArraycName && firstArraycName
                              ? firstArraycName.map((ele) => ({
                                  label: ele,
                                  value: ele,
                                }))
                              : []
                          }
                          onChange={handleChangeCompany}
                          isMulti
                          required
                          isOpen={true}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
                {bucketDetails.selectQuery &&
                bucketDetails.selectQuery.length > 0 &&
                bucketDetails.selectQuery.includes("status") ? (
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <CreatableSelect
                      inputId="status"
                      TextFieldProps={{
                        label: "status*",
                        InputLabelProps: {
                          htmlFor: "status",
                          shrink: true,
                        },
                      }}
                      options={statusBucket}
                      value={
                        bucketDetails && bucketDetails.status
                          ? bucketDetails.status.map((ele) => ({
                              label: ele,
                              value: ele,
                            }))
                          : []
                      }
                      onChange={handleChangeStat}
                      isMulti
                      required
                      isOpen={true}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group as={Col}>
                    {getQue && bucketId && getQue.includes("status") ? (
                      <>
                        <Form.Label>Status</Form.Label>
                        <CreatableSelect
                          inputId="status"
                          TextFieldProps={{
                            label: "status*",
                            InputLabelProps: {
                              htmlFor: "status",
                              shrink: true,
                            },
                          }}
                          options={getQueries}
                          value={
                            firstArrayStatus && firstArrayStatus
                              ? firstArrayStatus.map((ele) => ({
                                  label: ele,
                                  value: ele,
                                }))
                              : []
                          }
                          onChange={handleChangeStatQuery}
                          isMulti
                          required
                          isOpen={true}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
              </Row>

              <Row>
                <Col md={12} className="d-flex justify-content-center">
                  <ButtonGroup>
                    {bucketId && userType === "1110" ? (
                      <Button
                        className="mx-2 button__radius"
                        variant="danger"
                        onClick={deleteBucket}
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button className="mx-2 button__radius" variant="secondary">
                      Reset
                    </Button>
                    {!bucketId ? (
                      <Button
                        className="mx-2 button__radius"
                        variant="primary"
                        onClick={createBucket}
                      >
                        Create
                      </Button>
                    ) : (
                      ""
                    )}
                    {bucketId ? (
                      <Button
                        className="mx-2 button__radius"
                        variant="primary"
                        onClick={updateBucket}
                      >
                        Update
                      </Button>
                    ) : (
                      ""
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CreateBucket);
