import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import { showSnackBar, showLoader } from "../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";

const CompanyInfo = (props) => {
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    description: "",
  });
  const [address, setAddress] = useState([]);
  const [logo, setLogo] = useState(null);
  const [icon, setIcon] = useState("");
  const [companyInfo, setCompanyInfo] = useState([]);

  useEffect(() => {
    loadCompanyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userType = localStorage.getItem("ut");
  const loadCompanyInfo = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";
    props.showLoader({ show: true });
    if (userType === "1100" || userType === "1110") {
      axios
        .get(dEndPoints.getCompanyInfo(corporateId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setCompanyInfo(data.data && data.data.responseData);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      axios
        .get(dEndPoints.getVendorCompanyInfo(vendorCorporateId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setCompanyInfo(data.data && data.data.responseData);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  // const userType = localStorage.getItem("ut");
  const logoCompanynameDescription = (event) => {
    if (event.target.name === "filename") {
      if (event.target.files[0]) {
        if (event.target.files[0].size > 1000000) {
          props.showSnackBar({
            message: "Image Max Allowed size is less than 1 MB",
            open: true,
            type: "error",
          });
          return;
        }
        var reader = new FileReader();
        var img = event.target.files[0];

        reader.onloadend = () => {
          setLogo(img);
          setIcon(reader.result);
        };
        reader.readAsDataURL(img);
      }
    }
  };
  const handleInputChange = (e) => {
    setCompanyDetails({ ...companyDetails, [e.target.id]: e.target.value });
  };
  const handleAddress = (e, i) => {
    const dupAddress = [...address];
    dupAddress[i] = e.target.value;
    setAddress(dupAddress);
  };
  const addAddress = (e) => {
    const newAddress = [...address];
    newAddress.push("");
    setAddress(newAddress);
  };

  useEffect(() => {
    var setObject = {
      companyName: companyInfo.companyName,
      description: companyInfo.description,
    };
    setCompanyDetails(setObject);
    setIcon(companyInfo.icon);
    setAddress(companyInfo.officeAddress);
  }, [companyInfo]);

  const submitCompanyDetails = (e) => {
    e.preventDefault();
    const userType = localStorage.getItem("ut");
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");
    //const corporateId = cacheData ? cacheData.ci : "";
    var isValidAddress = false;
    var isValidNameDesc = false;
    var filtered = address.filter(Boolean);

    filtered.length < 1 ? (isValidAddress = false) : (isValidAddress = true);
    var addressObject = [];
    for (var i = 0; i < filtered.length; i++) {
      addressObject.push(address);
    }
    setAddress(filtered);

    var form = new FormData();
    if (logo) {
      form.append("file", logo);
    }
    form.append("companyName", companyDetails.companyName);
    form.append("description", companyDetails.description);
    form.append("officeAddress", address);
    console.log("form-data", form);
    if (
      companyDetails.companyName
        ? companyDetails.companyName.length < 2
        : "" || companyDetails.description
        ? companyDetails.description.length < 2
        : ""
    ) {
      isValidNameDesc = false;
    } else {
      isValidNameDesc = true;
    }
    if (isValidNameDesc && isValidAddress) {
      if (userType === "1000") {
        axios
          .put(
            dEndPoints.updateVendorCompanyInfo(vendorCorporateId) +
              "?fileType=Icon",
            form
          )
          .then((response) => {
            var data = response.data;
            if (data.message === "Success") {
              props.showSnackBar({
                message: "Information submitted successfully",
                open: true,
                type: "success",
              });
            } else {
              props.showSnackBar({
                message: "Try Again Later",
                open: true,
                type: "error",
              });
            }
          })
          .catch((error) => {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          });
      } else {
        axios
          .put(
            dEndPoints.updateCompanyInfo(corporateId) + "?fileType=Icon",
            form
          )
          .then((response) => {
            var data = response.data;
            if (data.message === "Success") {
              props.showSnackBar({
                message: "Information submitted successfully",
                open: true,
                type: "success",
              });
            } else {
              props.showSnackBar({
                message: "Try Again Later",
                open: true,
                type: "error",
              });
            }
          })
          .catch((error) => {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          });
      }
    } else {
      props.showSnackBar({
        message: "Please fill all the mandatory fields correctly",
        open: true,
        type: "error",
      });
    }
  };
  var acceptedImage =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif";

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav icon={icon} />
          <GoBackButton />
          <div className="companyInfo-Page mb-5">
            <h1 className="pageHeadings">Company Profiles</h1>
            <small> {companyInfo.gstIn}</small>
            <Form className="tq-form pt-4">
              <Row className="mb-4">
                <Form.Group
                  as={Col}
                  md={4}
                  className="browser__Btn"
                  controlId="formGridFirstName"
                >
                  <p>Upload Company Logo </p>
                  {/* <div className="company__thumb-here">
                    <i className="bx bx-image"></i>
                  </div> */}
                  {icon ? (
                    <div className="company__thumb-here">
                      <img
                        src={icon}
                        alt="Logo"
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <Form.Label className="fileUpload">Upload Logo</Form.Label>
                  <Form.Control
                    type="file"
                    name="filename"
                    onChange={(e) => logoCompanynameDescription(e)}
                    accept={acceptedImage}
                    //value={icon}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={8}
                  className="flex__vertical-center"
                  controlId="formGridFirstName"
                >
                  <span className="w-100">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="companyName"
                      onChange={handleInputChange}
                      value={companyDetails.companyName}
                      placeholder="Example : TalenQ Pvt. Ltd"
                      disabled
                    />
                  </span>
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label>GSTIN</Form.Label>
                  <Form.Control
                    type="text"
                    id="GSTIN"
                    value={companyInfo.gstIn}
                    placeholder="GSTIN 0000"
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>PAN</Form.Label>
                  <Form.Control
                    type="text"
                    id="pan"
                    value={companyInfo.pan}
                    placeholder="PAN"
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="mb-4">
                {address &&
                  address.map((ele, i) => (
                    <Form.Group as={Col}>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        id="officeAddress"
                        onChange={(e) => handleAddress(e, i)}
                        value={ele}
                        placeholder="Address"
                      />
                    </Form.Group>
                  ))}
                <Form.Group as={Col}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    onChange={handleInputChange}
                    value={companyDetails.description}
                    placeholder="Description"
                  />
                </Form.Group>
              </Row>
              <Button
                type="button"
                variant="secondary"
                onClick={(e) => addAddress(e)}
              >
                Add address
              </Button>
              <Button
                variant="primary mx-2 "
                type="submit"
                onClick={submitCompanyDetails}
              >
                Update
              </Button>
              <div className="text-center mt-4">
                <strong>Note: </strong>
                <small>
                  To update Company Name, GSTIN and PAN please contact &nbsp;
                  <strong>
                    <a className="link" href="mailto:support@talenq.com">
                      support@talenq.com
                    </a>
                  </strong>
                </small>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
//export default CompanyInfo;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CompanyInfo);
