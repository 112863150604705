import * as React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = (props) => {
  const { component: Component, allowedRoles, ...rest } = props;
  const userType = localStorage.getItem("ut");
  const extraProps = {};
  if (typeof document === "undefined") {
    return (
      <Route
        {...rest}
        render={(rprops) => <Component {...rprops} {...extraProps} />}
      />
    );
  }

  let cookies;
  var value = "; " + document.cookie;

  var parts = value.split("; " + "info" + "=");

  var parts1 = value.split("; " + "infoOne" + "=");

  if (parts.length === 2 || parts1.length === 2) {
    cookies = parts.pop().split(";").shift();

    if (cookies === "undefined") {
      cookies = parts1.pop().split(";").shift();
    }
  }

  if (!Component) {
    throw new Error(`Component can't be undefined`);
  }
  const allowed = allowedRoles.includes(userType);
  return (
    <Route
      {...rest}
      render={(rprops) => {
        return cookies ? (
          allowed ? (
            <Component {...rprops} {...extraProps} />
          ) : (
            <Redirect
              to={{ pathname: "/404", state: { from: rprops.location } }}
            />
          )
        ) : (
          <Redirect to={{ pathname: "/", state: { from: rprops.location } }} />
        );
      }}
    />
  );
};

export const PublicRoute = (props) => {
  const extraProps = {};
  const { component: Component, ...rest } = props;
  if (typeof document === "undefined") {
    return (
      <Route
        {...rest}
        render={(rprops) => <Component {...rprops} {...extraProps} />}
      />
    );
  }
  let cookies;
  var value = "; " + document.cookie;
  //  console.log("value", value);
  var parts = value.split("; " + "info" + "=");
  //console.log("parts", parts);
  var parts1 = value.split("; " + "infoOne" + "=");
  //console.log("parts1", parts1);
  if (parts.length === 2 || parts1.length === 2) {
    cookies = parts.pop().split(";").shift();
    console.log("cookies", cookies);
    if (cookies === "undefined") {
      cookies = parts1.pop().split(";").shift();
    }
  }

  if (!Component) {
    throw new Error(`Component can't be undefined`);
  }
  const userType = localStorage.getItem("ut");

  return (
    <Route
      {...rest}
      render={(rprops) =>
        cookies ? (
          <Redirect
            to={
              userType === "1100" || userType === "1110"
                ? "/dashboard"
                : "/dashboard"
            }
          />
        ) : (
          <Component {...rprops} {...extraProps} />
        )
      }
    />
  );
};
