import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import axios from "axios";
// import Card from "../../common/mataerialKit/components/Card/Card.jsx";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
// import Vendor from "./AllVendorUser";
import PropTypes from "prop-types";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../../actions/index";
import endPoints from "../../config/end-point";
import Button from "../../common/mataerialKit/components/CustomButtons/Button.jsx";
import ChangeColumn from "./ChangeColumn";
import Dialog from "@material-ui/core/Dialog";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import CorporateUserCard from "./allusers/CorporateUserCard.js";
// import UserDetailsPage from "./allusers/UserDetailsPage.js";
//import DragNDrop from "./DragAndDrop.js";
const AdminDashboard = (props) => {
  // const { classes } = props;

  const [info, setInfo] = useState([]);
  const [vendorInfo, setVendorInfo] = useState([]);
  const [showHiring, setshowHiring] = useState(false);
  const [searchInputAll, setSearchInputAll] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showCorp, setshowCorp] = useState(true);
  const [openformSchedule, setOpenformSchedule] = useState(false);
  const [userId, setUserId] = useState({});
  const [column, setColumn] = useState([]);
  const [customColumns, setCustomColumn] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editableUser, setEditableUser] = useState({});

  useEffect(() => {
    getLookupColumn();
    loadCorporate();
    loadVendor();
    loadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetails = () => {
    const selectInput = {
      metaData: [
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "RATE",
        },
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "REPLACEMENT",
        },
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "LOCATION",
        },

        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "STATE",
        },

        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "SKILL",
        },
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "COLUMNNAME",
        },
      ],
    };
    axios({
      url: endPoints.lookup,
      data: selectInput,
      method: "POST",
    }).then((res) => {
      const data = res.data;

      if (data && data.code === 100000) {
        const customColumn = data && data.data && data.data.COLUMNNAME;
        setCustomColumn(customColumn);
      }
    });
  };

  const getLookupColumn = () => {
    props.showLoader({ show: true });
    axios
      .get(endPoints.getLookup)
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (data && data.code === 100000) {
          if (data.data) {
            setColumn(data.data);
          }
        }
      })
      .catch(function (error) {
        props.showLoader({ show: false });
        console.log(error);
      });
  };

  let lookupColumn = customColumns.map((item) => {
    return { name: item.name, alternateName: item.alternateName };
  });

  const defaultData = [
    {
      title: "CSV column",
      items: [],
    },
    {
      title: "Master Column",
      items: lookupColumn,
    },
  ];

  let usersOne = defaultData[1].items;

  const initialFormState = {
    cOn: "",
    name: "",
    email: "",
    cName: "",
    cDesig: "",
    city: "",
    expr: "",
    nPeriod: "",
    comment1: "",
    contact: "",
    ctc: "",
    ectc: "",
    stat: "",
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);

  const updateUser = (updatedUser) => {
    setEditing(false);
    let setUsersOne;
    setUsersOne(
      usersOne.map((user) => (user[0] === "Date Added" ? updatedUser : user))
    );
  };
  const editRow = (user) => {
    setEditing(true);
    setCurrentUser({
      cOn: column.dateAdded,
      name: column.candidateName,
      email: column.candidateEmail,
      cName: column.currentCompany,
      cDesig: column.currentDesignation,
      city: column.currentLocation,
      expr: column.totalExp,
      nPeriod: column.noticePeriod,
      comment1: column.comment,
      contact: column.contactNumber,
      ctc: column.currentCTC,
      ectc: column.expectedCTC,
      stat: column.status,
    });
  };

  const [header, setHeader] = useState([
    {
      headerName: "User ID",
      field: "UserName",
      filter: true,
      sortable: true,
      width: 250,
    },
    { headerName: "User Type", field: "UserType", width: 120, filter: true },
    { headerName: "Status", field: "Status", width: 120, filter: true },
    { headerName: "SEO Name", field: "SEO" },
    { headerName: "Contact", field: "Contact", width: 120 },
    { headerName: "Company Name", field: "company", width: 170, filter: true },
    { headerName: "GSTIN", field: "GSTIN", width: 150 },
    { headerName: "Industry Type", field: "Industry", width: 120 },
    {
      headerName: "CSV Column",
      field: "User",
      width: 150,
      cellRenderer: function (params) {
        var userSet = params.value;
        setUserId(params.value);
        return (
          <Button
            simple
            size="sm"
            color="info"
            onClick={handleOpenSchedule(userSet)}
          >
            Set Column
          </Button>
        );
      },
    },
  ]);
  const handleOpenSchedule = (value) => () => {
    editRow();
    setEditableUser(defaultData[1].items);
    setUserId(value);
    setOpenformSchedule(true);
  };
  const handleCloseSchedule = () => {
    setOpenformSchedule(false);
  };
  var users = info.map((user) => {
    var Userdata = {
      UserName: user.userName || "",
      UserType: user.userType || "",
      Status: user.status || "",
      Contact: user.contact || "",
      SEO: user.seoName || "",
      company: user.companyName || "",
      GSTIN: user.gstIn || "",
      Industry: user.industryType || "",
      User: user.corporateId || "",
    };
    return Userdata;
  });
  const loadCorporate = () => {
    var sort_createdon = -1;
    var pageNum = 1;
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllCorporateList, {
        params: {
          pageNumber: pageNum,
          cOn: sort_createdon,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (response.data.code === 100124) {
          const resumes = data.data;
          setInfo(resumes);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
      });
  };

  const loadVendor = () => {
    var sort_createdon = -1;
    var pageNum = 1;
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllVendorList, {
        params: {
          pageNumber: pageNum,
          cOn: sort_createdon,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (response.data.code === 100125) {
          const resumes = data.data;
          setVendorInfo(resumes);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };

  const clickUserDetails = (userId, userType) => {
    props.history.push(`/alluser/${userId}/${userType}`);
  };
  const clickUserDetailsVendor = (userId, userType) => {
    props.history.push(`/alluser/${userId}/${userType}`);
  };

  const checkCorporate = (e) => {
    setshowCorp(e.target.checked);
    setshowHiring(false);
  };
  const checkHiring = (e) => {
    setshowHiring(e.target.checked);
    setshowCorp(false);
  };

  return (
    <Container fluid>
      <div className="tq-JobsPage">
        <Row>
          <Col md={2}>
            <SideNav {...props} />
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />

            <div className="tq__page-header mb-4">
              <h2 className="page-header">All Users Info</h2>
            </div>
            <Row className="mb-5">
              <Col sm={3}>
                <label
                  className={`status-card ${showCorp === true ? "active" : ""}`}
                  htmlFor="showCorp"
                >
                  <input
                    type="radio"
                    checked={showCorp}
                    onChange={checkCorporate}
                    name="jobListing"
                    id="showCorp"
                    className="opacity-0"
                  />
                  <span className="status-card__info">
                    <h3>{info.length}</h3>
                    <span className="f-600">Corporates</span>
                  </span>
                </label>
              </Col>
              <Col sm={3}>
                <label
                  className={`status-card ${
                    showHiring === true ? "active" : ""
                  }`}
                  htmlFor="showHiring"
                >
                  <input
                    type="radio"
                    checked={showHiring}
                    onChange={checkHiring}
                    name="jobListing"
                    id="showHiring"
                    className="opacity-0"
                  />
                  <span className="status-card__info">
                    <h3>{vendorInfo.length}</h3>
                    <span className="f-600">Hiring Partner</span>
                  </span>
                </label>
              </Col>
            </Row>
            {showCorp === true ? (
              <div className="listof__users-wrapper mt-4">
                <div className="tq__page-header d-block text-center mb-5">
                  <h3 className="page-header">Corporates</h3>
                </div>
                <Row>
                  <Col xs={12}>
                    <div className="tq-cards py-4 mb-5">
                      <Row className="tq-form justify-content-around">
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            placeholder="Search jobs here...."
                            onChange={(e) => {
                              setSearchInput(e.target.value);
                            }}
                          />
                          <small className="mt-2 d-block text-center">
                            Search Jobs by Typing -{" "}
                            <mark className="bg-info">Company Name</mark> or{" "}
                            <mark className="bg-info">Email</mark>{" "}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {info
                    .filter((val) => {
                      if (searchInput === "") {
                        return val;
                      } else if (
                        val.userName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.companyName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((item, index) => (
                      <Col sm={4} xs={12} className="mb-5" key={index}>
                        <CorporateUserCard
                          UrlGo={() =>
                            clickUserDetails(item._id, item.userType)
                          }
                          Title={item.companyName}
                          Industry={item.industryType}
                          Status={item.status}
                          Ceo={item.authorizedContactPerson}
                          Email={item.userName}
                          Contact={item.contact}
                          CardDetails={item}
                        />
                      </Col>
                    ))}
                </Row>
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "550px",
                    width: "100%",
                    fontSize: "13px",
                  }}
                >
                  <AgGridReact
                    pagination={true}
                    columnDefs={header}
                    rowData={users}
                  ></AgGridReact>
                </div>
              </div>
            ) : (
              ""
            )}
            {showHiring === true ? (
              <>
                <div className="tq__page-header d-block text-center mb-5">
                  <h3 className="page-header">Hiring Partners</h3>
                </div>
                <Row>
                  <Col xs={12}>
                    <div className="tq-cards py-4 mb-5">
                      <Row className="tq-form justify-content-around">
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            placeholder="Search jobs here...."
                            onChange={(e) => {
                              setSearchInputAll(e.target.value);
                            }}
                          />
                          <small className="mt-2 d-block text-center">
                            Search Jobs by Typing -{" "}
                            <mark className="bg-info">Company Name</mark> or{" "}
                            <mark className="bg-info">User Name</mark>{" "}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {vendorInfo
                    .filter((allUser) => {
                      if (searchInputAll === "") {
                        return allUser;
                      } else if (
                        allUser.companyName
                          ?.toLowerCase()
                          .includes(searchInputAll?.toLowerCase())
                      ) {
                        return allUser;
                      } else if (
                        allUser.userName
                          ?.toLowerCase()
                          .includes(searchInputAll?.toLowerCase())
                      ) {
                        return allUser;
                      }
                    })
                    .map((item, index) => (
                      <Col sm={4} xs={12} className="mb-5" key={index}>
                        <CorporateUserCard
                          UrlGo={() =>
                            clickUserDetailsVendor(item._id, item.userType)
                          }
                          Title={item.companyName}
                          Industry={item.industryType}
                          Status={item.status}
                          Ceo={item.authorizedContactPerson}
                          Email={item.userName}
                          Contact={item.contact}
                          CardDetails={item}
                          gstin={props.gstIn}
                          ShowVendor={showHiring}
                        />
                      </Col>
                    ))}
                </Row>
              </>
            ) : (
              ""
            )}
            <div>
              <Dialog
                open={openformSchedule}
                onClose={handleCloseSchedule}
                maxWidth="md"
              >
                <>
                  <ChangeColumn
                    data={defaultData}
                    updateUser={updateUser}
                    editing={editing}
                    setEditing={setEditing}
                    currentUser={currentUser}
                    editableUser={editableUser}
                    userId={userId}
                    handleCloseSchedule={handleCloseSchedule}
                  />
                </>
              </Dialog>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
AdminDashboard.propTypes = {
  classes: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(AdminDashboard));
