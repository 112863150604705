import React, { useState, useRef } from "react";
import Button from "../../common/mataerialKit/components/CustomButtons/Button.jsx";
import { showSnackBar, showLoader } from "../../actions/index";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import { withStyles } from "@material-ui/core/styles";
//import PropTypes from "prop-types";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import AgGridCheckbox from "./CheckBoxRenderer";
const ChangeColumn = (props) => {
  // const { classes } = props;

  const [editName, setEditName] = useState(props.data[1].items);
  const [editing, setEditing] = useState("");

  const [header, setHeader] = useState([
    {
      headerName: "Choose Column",
      field: "columnName",
      width: 120,
      editable: false,
      cellRendererFramework: AgGridCheckbox,
    },
    {
      headerName: "DB Column",
      field: "dbfield",
      rowDrag: true,
      filter: true,
      width: 150,
      // editable: true,
    },
    {
      headerName: "CSV column (Editable)",
      field: "csvcol",
      width: 300,
      filter: true,
      editable: true,
    },
    {
      headerName: "Sorting(Enter the number)",
      field: "sort",
      editable: true,
      cellEditorSelector: function (params) {
        setEditing(params.data.columnName);
        if (params.value === true) {
          {
            for (let i = 0; i <= 20; i++) {
              return {
                editable: i,
              };
            }
          }
        }
      },
      width: 100,
    },
    {
      defaultColDef: {
        flex: 1,
      },
    },
  ]);
  let gridApi = useRef();
  let gridColumnApi = useRef();

  const onGridReady = (params) => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
  };
  const onRemoveSelected = () => {
    var selectedData = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedData }); //applyTransaction({ remove: selectedData });
    printResult(res);
  };
  // const onBtDelete = () => {
  //   var api = gridApi;
  //   var selectedRows = api.getSelectedRows();

  //   if (!selectedRows || selectedRows.length === 0) {
  //     console.log("No rows selected!");
  //     return;
  //   }
  //   timeOperation("Delete", function () {
  //     api.applyTransaction({ remove: selectedRows });
  //   });
  // };
  // var aggCallCount;
  // var compareCallCount;
  // var filterCallCount;
  // function timeOperation(name, operation) {
  //   aggCallCount = 0;
  //   compareCallCount = 0;
  //   filterCallCount = 0;
  //   var start = new Date().getTime();
  //   operation();
  //   var end = new Date().getTime();
  //   console.log(
  //     name +
  //       " finished in " +
  //       (end - start) +
  //       "ms, aggCallCount = " +
  //       aggCallCount +
  //       ", compareCallCount = " +
  //       compareCallCount +
  //       ", filterCallCount = " +
  //       filterCallCount
  //   );
  // }
  function printResult(res) {
    console.log("---------------------------------------");
    // console.log("res", res);
    if (res.remove) {
      res.remove.forEach(function (rowNode) {
        console.log("Removed Row Node", rowNode);
      });
    }
  }
  var CSVData = editName.map((item) => {
    var columnData = {
      columnName: "",
      dbfield: item.name || "",
      csvcol: item.alternateName || "",
      sort: "",
    };
    //console.log("columnData", columnData);
    return columnData;
  });

  const onAddRow = (e) => {
    let add = { columnName: "", dbfield: "", csvcol: "", sort: "" };
    for (let i = 20; i <= 30; i++) {
      CSVData[i] = { columnName: "", dbfield: "", csvcol: "", sort: "" };
    }
    CSVData.concat(add);

    gridApi.updateRowData({
      add: [
        {
          columnName: "",
          dbfield: "",
          csvcol: "",
          sort: "",
        },
      ],
    });
    //console.log("value", e);
  };
  //console.log("onAddRow", gridApi);

  // useEffect(() => {}, []);
  const addUser = (e) => {
    // let dbfield = {};
    let allListed = [];

    let selectedValue = CSVData.map((item, index) => {
      if (item.columnName === true) {
        return {
          dbfield: item.dbfield,
          csvcol: item.csvcol,
          sort: item.sort,
        };
      } else {
        return { dbfield: "", csvcol: "" };
      }
    });
    //console.log("selectedValue", selectedValue);
    selectedValue.map((item, index) => {
      if (item.sort === "1") {
        allListed[0] = item;
      } else if (item.sort === "2") {
        allListed[1] = item;
      } else if (item.sort === "3") {
        allListed[2] = item;
      } else if (item.sort === "4") {
        allListed[3] = item;
      } else if (item.sort === "5") {
        allListed[4] = item;
      } else if (item.sort === "6") {
        allListed[5] = item;
      } else if (item.sort === "7") {
        allListed[6] = item;
      } else if (item.sort === "8") {
        allListed[7] = item;
      } else if (item.sort === "9") {
        allListed[8] = item;
      } else if (item.sort === "10") {
        allListed[9] = item;
      } else if (item.sort === "11") {
        allListed[10] = item;
      } else if (item.sort === "12") {
        allListed[11] = item;
      } else if (item.sort === "13") {
        allListed[12] = item;
      } else if (item.sort === "14") {
        allListed[13] = item;
      } else if (item.sort === "15") {
        allListed[14] = item;
      } else if (item.sort === "16") {
        allListed[15] = item;
      } else if (item.sort === "17") {
        allListed[16] = item;
      } else if (item.sort === "18") {
        allListed[17] = item;
      } else if (item.sort === "19") {
        allListed[18] = item;
      } else if (item.sort === "20") {
        allListed[19] = item;
      } else {
      }
    });
    if (selectedValue[1].dbfield === "") {
      allListed = CSVData;
    }

    const corporateId = props.userId;
    axios
      .put(dEndPoints.createtrackercolumn(corporateId), allListed)
      .then((response) => {
        var data = response.data;
        // console.log("data", data);
        if (data && data.code === 100000) {
          props.handleCloseSchedule();
          props.showSnackBar({
            message: "Corporate Columns added successfully",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
        }

        if (data && data.code === 100140) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error, "An error");
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100036
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  return (
    <div style={{ width: "580px" }}>
      <div>
        <Button color="instagram" size="sm" onClick={(e) => onAddRow(e)}>
          Add Row
        </Button>
        <Button color="instagram" size="sm" onClick={() => onRemoveSelected()}>
          Delete
        </Button>
        <div
          className="ag-theme-balham"
          style={{
            height: "600px",
            width: "100%",
            fontSize: "13px",
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={header}
            rowData={CSVData}
            defaultColDef={{ flex: 1 }}
            rowSelection={"multiple"}
            animateRows={true}
          ></AgGridReact>
        </div>
        <div
          style={{
            marginLeft: "400px",
          }}
        >
          <Button color="instagram" size="sm" onClick={() => addUser()}>
            Add
          </Button>
          <Button color="info" size="sm" onClick={props.handleCloseSchedule}>
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

// ChangeColumn.propTypes = {
//   classes: PropTypes.object,
// };

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(ChangeColumn));
