import React, { useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { dEndPoints } from "../../config/end-point";
import { showLoader, showSnackBar } from "../../actions/index";
import { connect } from "react-redux";
// import CreatableSelect from "react-select/creatable";
import { bindActionCreators } from "redux";

const SendEmailFunctionality = (props) => {
  const [emailDetails, setEmailDetails] = useState({
    email: [],
    to: "recruiters@talenq.com",
    message: "",
  });
  const [findJob, setFindJob] = useState({ jobId: "" });

  const onNameChange = (e) => {
    const info = { ...emailDetails };
    info[e.target.id] = e.target.value;
    setEmailDetails(info);
  };

  const onMessageChange = (e) => {
    const info = { ...emailDetails };
    info[e.target.id] = e.target.value;
    setEmailDetails(info);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var jdFile = props.jobOne.jdFile ? props.jobOne.jdFile.fname : "";
    const info = { ...emailDetails };
    const userId = localStorage.getItem("i");
    if (jdFile === "" || !jdFile) {
      props.showSnackBar({
        message: "Please Select Job",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (emailDetails.message === "" || !emailDetails.message) {
      props.showSnackBar({
        message: "Please Enter Message",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    info.email = getFinal;

    const split_string = info.to.split(",");
    info.to = split_string;
    props.showLoader({ show: true });
    axios
      .post(
        dEndPoints.sendEmailToCandidate(userId) + "? fileUrl=" + jdFile,
        info
      )
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        setEmailDetails({ to: "", email: [], message: "" });
        setFindJob({ jobId: "" });
        props.showSnackBar({
          message: data.data.message,
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while loadig data v", error);
      });
  };

  var getFinal = [];
  const candidateNew =
    props.getcandidates &&
    props.getcandidates.map((item) => {
      getFinal.push(item.Email);
      return { label: item.Email, value: item.Email };
    });

  return (
    <Container>
      <div
        style={{
          marginTop: "40px",
          paddingTop: "40px",
          borderTop: "2.5px dashed #333",
        }}
      >
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="name">
              To (comma Seperated)
            </label>
            <input
              type="text"
              className="form-control"
              id="to"
              defaultValue={"recruiters@talenq.com"}
              value={emailDetails.to}
              onChange={onNameChange}
            />
          </div>
          <div className="form-group mb-3">
            <Form.Label htmlFor="name">
              Candidate's email that goes to BCC (comma Seperated)
              <small className="required__form">*</small>
            </Form.Label>
            {/* {console.log("emailDetails.email", emailDetails.email.length)} */}

            {/* {emailDetails.email.length !== 0 ? ( */}
            <div className="candidate__email-wrapper">
              {candidateNew.map((email, index) => (
                <span
                  // //type="email"
                  className="email__list"
                  id="email"
                  name={"" + index}
                  key={index}
                  value={emailDetails.email}
                  // onClick={onEmailChange}
                >
                  {" "}
                  {email.label + ","}
                </span>
              ))}
            </div>
            {/* ) : (
              ""
            )} */}

            {emailDetails.email.length === 0 ? (
              <small className="d-block">
                You have to select candidates from the below table
              </small>
            ) : (
              ""
            )}
          </div>
          <div className="form-group mb-4">
            <Form.Label htmlFor="name">
              Message<small className="required__form">*</small>
            </Form.Label>
            <textarea
              className="form-control"
              rows="5"
              id="message"
              value={emailDetails.message}
              onChange={onMessageChange}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          {props.cancelBtn}
        </form>
      </div>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SendEmailFunctionality);
