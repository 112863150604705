import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import JobsCard from "../components/jobcards/JobsCard";
import StatusCard from "../components/status-card/StatusCard";
import Badge from "../components/badge/Badge";
import { Link } from "react-router-dom";
import { dEndPoints } from "../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserName, setjobId } from "../actions/index";
import { showLoader, showSnackBar } from "../actions/index";
import axios from "axios";
import Chart from "./Chart";
import trackEvent from "../utils/analytics-manager";
import TopNav from "../components/topnav/TopNav";
import SideNav from "../components/sidenav/SideNav";
import Pagination from "@material-ui/lab/Pagination";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const JobsPage = (props) => {
  const [jobs, setJobs] = useState([]);

  const [facets, setfacets] = useState({});
  const [jobStatus, setJobStatus] = useState({});
  const [locationsearch, setLocationSearch] = useState([]);
  const [companyNamesearch, setCompanyNameSearch] = useState([]);
  const [statusSearch, setStatusSearch] = useState([]);
  const [designationsearch, setDesignationSearch] = useState([]);
  const [usersearch, setUsersSearch] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [jobCount, setJobCount] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortBySeach, setSortBySeach] = useState([]);
  const [closeJob, setCloseJob] = useState(false);
  const [vendorJobs, setVendorJobs] = useState([]);
  let userID = localStorage.getItem("i");
  const [defaultBucket, setDefaultBucket] = useState([]);
  const [customBucket, setCustomBucket] = useState([]);
  const [job, setJob] = useState({
    closedComment: [],
    statHis: [],
    coOwners: [],
  });
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  useEffect(() => {
    reloadJobs();
    landingPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreVendorJobs = () => {
    setPageNum(pageNum + 1);
    reloadJobs(pageNum + 1);
  };
  const openFilterdiv = () => {
    setFilterOpen(!filterOpen);
  };
  const sortBy = [
    { label: "Latest First", value: -1 },
    { label: "Oldest First", value: 1 },
  ];
  const userType = localStorage.getItem("ut");
  const editJob = (jobId, data) => {
    let cId = localStorage.getItem("ci");
    axios
      .put(dEndPoints.editJob(cId, jobId), data)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };

  const clickDashboardRequirement = (jobId) => {
    props.history.push(`/alljobs/${jobId}`);
  };

  const clickDashboardRequirementVendor = (jobId) => {
    props.history.push(`/alljobs/${jobId}?enroll=${true}`);
  };
  function handleViewRequirement(jobId) {
    props.setUserName({ userName: jobId });
    props.history.push(`/createjob/${jobId}`);
    trackEvent({
      type: "EDIT_JOB",
      data: {
        jobId: jobId,
      },
    });
  }

  var landingPage = (pageNum = 0) => {
    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    //setIsLoading(true);
    if (userType === "1000") {
      axios
        .get(dEndPoints.vendorLandingPage(userId), {})
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100185) {
            if (data.data.defaultBucket) {
              setDefaultBucket(data.data.defaultBucket);
            }
            if (data.corporateCompanies) {
              setCustomBucket(data.data.corporateCompanies);
            }
            setLoading(false);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      axios
        .get(dEndPoints.corporateLandingPage(userId), {})
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100185) {
            if (data.data.defaultBucket) {
              setDefaultBucket(data.data.defaultBucket);
            }
            if (data.corporateCompanies) {
              setCustomBucket(data.data.corporateCompanies);
            }
            setLoading(false);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  const reloadJobs = (pageNum = 0) => {
    var sort_createdon = -1;
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          if (data && data.code === 100000) {
            if (data.data.facets) {
              setfacets(data.data.facets);
            }
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
            setJobCount(Math.ceil(data.data.total / 12));
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while loadig data v", error);
        });
    } else {
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      //setIsLoading(true);
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.facets) {
              setfacets(data.data.facets);
            }
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
            if (data.data.stats) {
              setJobStatus(data.data.stats);
            }
            setJobCount(Math.ceil(data.data.total / 12));
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  if (facets.location) {
    let locationList = facets.location.sort((a, b) =>
      Object.keys(a)[0].localeCompare(Object.keys(b)[0])
    );
    var locations = locationList.map((data, i) => {
      let location = { value: "", label: "" };
      return (location[data] = {
        value: Object.keys(data)[0],
        label: `${Object.keys(data)[0]}-${Object.values(data)[0]}`,
      });
    });
  }

  if (facets.designation) {
    let desigList = facets.designation.sort((a, b) =>
      Object.keys(a)[0].localeCompare(Object.keys(b)[0])
    );
    var designations = desigList.map((data, i) => {
      let designation = { value: "", label: "" };
      return (designation[data] = {
        value: Object.keys(data)[0],
        label: `${Object.keys(data)[0]}-${Object.values(data)[0]}`,
      });
    });
  }
  if (facets.users) {
    let usersList = facets.users.sort((a, b) =>
      a.userName.localeCompare(b.userName)
    );
    var users = usersList.map((data, i) => {
      let user = { value: "", label: "" };
      return (user[data] = {
        value: data.userName,
        label: data.userName,
      });
    });
  }
  if (facets.companyName) {
    let compList = facets.companyName.sort((a, b) =>
      Object.keys(a)[0].localeCompare(Object.keys(b)[0])
    );
    var companyNames = compList.map((data, i) => {
      let jobstat = { value: "", label: "" };
      return (jobstat[data] = {
        value: Object.keys(data)[0],
        label: `${Object.keys(data)[0]}-${Object.values(data)[0]}`,
      });
    });
  }
  if (facets.status) {
    let statusList = facets.status.sort((a, b) =>
      Object.keys(a)[0].localeCompare(Object.keys(b)[0])
    );
    var status = statusList.map((data, i) => {
      let jobstat = { value: "", label: "" };
      return (jobstat[data] = {
        value: Object.keys(data)[0],
        label: `${Object.keys(data)[0]}-${Object.values(data)[0]}`,
      });
    });
  }

  const loadMoreJobs = (event, value) => {
    // setPageNum(pageNum + 1);
    // reloadJobs(pageNum + 1);
    setPageNum(value);
    reloadJobs(value - 1);
    // handleSearchClick(value - 1);
  };

  // const loadPrevJobs = (event, value) => {
  //   if (pageNum === 1) {
  //     setPageNum((pageNum = 1));
  //     reloadJobs((pageNum = 1));
  //   } else {
  //     setPageNum(pageNum - 1);
  //     reloadJobs(pageNum - 1);
  //   }
  // };

  const updateJob = (e, status, jobId, statHis, coOwners, closedComment) => {
    e.preventDefault();
    //const corporateId = cacheData.ci;
    const corporateId = localStorage.getItem("ci");

    // jobId = props.match.params.jobId;
    var cjob = { ...job, statHis, coOwners, closedComment };

    cjob.status = status;

    props.showLoader({ show: true });

    axios
      .patch(dEndPoints.updateJob(corporateId, jobId), cjob)
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        editJob(jobId, data);
        handleCloseJob();
        reloadJobs();
        landingPage();
        props.showSnackBar({
          message: "job Updated Successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        //props.history.push("/alljobs");
      })
      .catch((error) => {
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };
  const handleShowCloseJob = (e, jobId) => {
    e.preventDefault();
    var cjob = { ...job, jobId };
    var feedback = cjob && cjob.feedback ? cjob.feedback : {};
    cjob.feedback = feedback;
    setJob(cjob);
    setCloseJob(true);
  };
  const handleCloseJob = () => {
    setCloseJob(false);
    var cjob = { ...job };
    cjob.closedComment = [];
    setJob({ ...job, ...cjob });
  };

  //  *** Search And Refresh Filters *** //
  const handleSearchClick = (e, pageNum) => {
    //const corporateId = cacheData.ci; //? cacheData.ci : "";
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");

    // setIsLoading(true);
    if (userType === "1110" || userType === "1100") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            location:
              locationsearch &&
              locationsearch.map((loc) => loc.value).toString(),
            status:
              statusSearch && statusSearch.map((stat) => stat.value).toString(),
            designation:
              designationsearch &&
              designationsearch.map((desig) => desig.value).toString(),
            users:
              usersearch && usersearch.map((user) => user.value).toString(),
            companyName:
              companyNamesearch &&
              companyNamesearch.map((cName) => cName.value).toString(),
            cOn:
              sortBySeach && sortBySeach.map((sort) => sort.value).toString(),
            limit: 12,
            pageNumber: pageNum,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          setShow(false);
          var data = response.data;

          if (data && data.code === 100000) {
            if (data.data.facets) {
              setfacets(data.data.facets);
            }
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
            if (data.data.stats) {
              setJobStatus(data.data.stats);
            }
            setJobCount(Math.ceil(data.data.total / 12));
          }
          setShow(false);
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          //  props.showLoader({ show: false });
        });
    } else {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            pageNumber: pageNum,
            limit: 12,
            location:
              locationsearch &&
              locationsearch.map((loc) => loc.value).toString(),
            companyName:
              companyNamesearch &&
              companyNamesearch.map((cName) => cName.value).toString(),
            status:
              statusSearch && statusSearch.map((stat) => stat.value).toString(),
            users:
              usersearch && usersearch.map((user) => user.value).toString(),
            cOn:
              sortBySeach && sortBySeach.map((sort) => sort.value).toString(),
          },
        })
        .then((response) => {
          props.showLoader({ show: false });

          var data = response.data;

          if (data && data.code === 100000) {
            if (data.data.facets) {
              setfacets(data.data.facets);
            }
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
            if (data.data.stats) {
              setJobStatus(data.data.stats);
            }
            setJobCount(Math.ceil(data.data.total / 12));
          }
          setShow(false);
        })
        .catch(function (error) {
          props.showLoader({ show: false });
        });
    }
  };

  const handleResetClick = (e) => {
    setDesignationSearch([]);
    setStatusSearch([]);
    setCompanyNameSearch([]);
    setLocationSearch([]);
    setUsersSearch([]);
    reloadJobs(0);
    handleSearchClick(0);
  };

  function handleInputChange(event) {
    var cjob = { ...job };
    if (event.target.name === "is_talenq_closed") {
      var closeJob = cjob && cjob.feedback ? cjob.feedback : {};
      closeJob[event.target.name] = event.target.value === "true";
      cjob.feedback = closeJob;
    }
    if (event.target.name === "closedComment") {
      cjob[event.target.name] = event.target.value;
    } else {
      cjob[event.target.id] = event.target.value;
    }

    setJob({ ...job, ...cjob });
  }
  // Load More Jobs ////
  const loadMoreFilterJobs = (event, value) => {
    setPageNum(value);
    handleSearchClick(event, value - 1);
    //reloadJobs(value - 1);
  };

  const favouriteJob = (jobId, favjob) => {
    //const corporateId = cacheData.ci;
    let vendorCorpId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    if (userType === "1110" || userType === "1100") {
      axios
        .patch(dEndPoints.corporatefavouriteJob(corporateId, jobId), null, {
          params: {
            fav: favjob,
          },
        })
        .then((res) => {
          var data = res.data;
          if (data && data.code === 100141) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          } else if (data && data.code === 100173) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100142
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    } else {
      axios
        .patch(dEndPoints.vendorfavouriteJob(vendorCorpId, jobId), null, {
          params: {
            fav: favjob,
          },
        })
        .then((res) => {
          var data = res.data;
          if (data && data.code === 100141) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          } else if (data && data.code === 100173) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100142
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  return (
    <Container fluid>
      <div className="tq-JobsPage">
        <Row>
          <Col md={2}>
            <SideNav {...props} />
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />
            <div className="tq__page-header">
              {" "}
              {userType === "1110" || userType === "1100" ? (
                <h2 className="page-header">All Jobs</h2>
              ) : (
                <h2 className="page-header">Enrolled Jobs</h2>
              )}
              <div
                className="filter__wrapper filter__btn flex__center border-0 bg-white"
                onClick={openFilterdiv}
              >
                <i className="bx bx-slider"></i>&nbsp;Filter
              </div>
            </div>
            {userType === "1110" || userType === "1100" ? (
              <div className="row countFilters">
                {defaultBucket.map((item, index) =>
                  item.counts > 0 ? (
                    <Col xs={6} md={3} key={index} className="mb-4">
                      <Link to={`/bucketdetails?${item.query}`}>
                        <StatusCard
                          icon={item.icon}
                          count={item.counts}
                          title={item.title}
                        />
                      </Link>
                    </Col>
                  ) : (
                    ""
                  )
                )}
              </div>
            ) : (
              ""
            )}
            {userType === "1000" ? (
              <div className="row countFilters">
                {defaultBucket.map((item, index) =>
                  item.jobCounts > 0 ? (
                    <Col xs={6} md={3} key={index} className="mb-3">
                      <Link to={`/bucketdetails?${item.query}`}>
                        {item.jobCounts > 0 ? (
                          <StatusCard
                            icon={item.icon}
                            count={item.jobCounts}
                            title={item.title}
                          />
                        ) : (
                          ""
                        )}
                      </Link>
                    </Col>
                  ) : (
                    ""
                  )
                )}
              </div>
            ) : (
              ""
            )}

            {userType === "1000" ? (
              <>
                <div className="Jobs__listing-wrapper">
                  {filterOpen ? (
                    <Row className="tq-form">
                      <>
                        <Form.Group as={Col} sm={3} xs={6}>
                          <Form.Label>Location</Form.Label>

                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={locations || []}
                            value={locationsearch}
                            onChange={setLocationSearch}
                            labelledBy={"Select"}
                            components={{
                              Option,
                            }}
                            hasSelectAll={false}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={3} xs={6}>
                          <Form.Label>Users</Form.Label>

                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={users || []}
                            value={usersearch}
                            onChange={setUsersSearch}
                            labelledBy={"Select"}
                            hasSelectAll={false}
                            components={{
                              Option,
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={3} xs={6}>
                          <Form.Label>Company</Form.Label>

                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={companyNames || []}
                            value={companyNamesearch}
                            // defaultValue={[status[4], status[0], status[2]]}
                            onChange={setCompanyNameSearch}
                            labelledBy={"Select"}
                            components={{
                              Option,
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={3} xs={6}>
                          <Form.Label>Sort By</Form.Label>
                          <ReactSelect
                            className="dropdown1"
                            isMulti
                            options={sortBy || []}
                            value={sortBy.label}
                            defaultValue={sortBy[0]}
                            onChange={setSortBySeach}
                            labelledBy={"Select"}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm={3}
                          xs={6}
                          className="mt-4 d-flex"
                        >
                          <ButtonGroup className="align-self-end">
                            <Button
                              variant="secondary"
                              onClick={handleResetClick}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleSearchClick}
                            >
                              Apply
                            </Button>
                          </ButtonGroup>
                        </Form.Group>
                      </>
                      {/* </Row> */}
                    </Row>
                  ) : null}
                  <Row>
                    {jobs.map((item, index) => (
                      <Col md={4} className="mt-5" key={index}>
                        <JobsCard
                          _id={item._id}
                          ChartDatas={
                            !item.rCtInfo ||
                            (item.rCtInfo &&
                              !item.rCtInfo.Pending &&
                              !item.rCtInfo.Screened &&
                              !item.rCtInfo.OfferRejected &&
                              !item.rCtInfo.InProcess &&
                              !item.rCtInfo.Onhold &&
                              !item.rCtInfo.RoundClear &&
                              !item.rCtInfo.Scheduled &&
                              !item.rCtInfo.RoundCompleted &&
                              !item.rCtInfo.OfferRevoked &&
                              !item.rCtInfo.Offered &&
                              !item.rCtInfo.Proposed &&
                              !item.rCtInfo.Dropped &&
                              !item.rCtInfo.Declined &&
                              !item.rCtInfo.Rejected &&
                              !item.rCtInfo.OfferAccepted &&
                              !item.rCtInfo.Joined) ? (
                              <div align="center">
                                <span>No resume yet!</span>
                                <p>You can work on it.</p>
                              </div>
                            ) : (
                              <div align="center">
                                <Chart
                                  jobId={item._id}
                                  resumeCount={item.rCtInfo ? item.rCtInfo : {}}
                                />
                              </div>
                            )
                          }
                          badge={
                            <Badge type={item.status} content={item.status} />
                          }
                          jobLink={() =>
                            clickDashboardRequirementVendor(item._id)
                          }
                          enrolled={item.enrolled}
                          jobOfTheDay={item.jobOfTheDay}
                          rate={item.rate}
                          properties={item.properties}
                          notice={item.nPeriod}
                          jodstatus={item.jodstatus}
                          jobType={item.jobType}
                          reqId={item.reqId}
                          Heading={item.desig}
                          posted={new Date(item.cOn).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                          company={item.cName}
                          skills={item.pSkills.toString()}
                          location={item.city + "    "}
                          FavIcon={
                            <div className="buttonsGroup">
                              {item.status === "Open" ? (
                                <>
                                  <div>
                                    {item.favourite &&
                                    item.favourite.filter(
                                      (item) => item === userID
                                    )[0] ? (
                                      <button
                                        title="Remove favourite"
                                        className="jobAction"
                                      >
                                        <i
                                          className="bx bxs-heart"
                                          style={{ color: "red" }}
                                          onClick={() => {
                                            favouriteJob(item._id, "false");
                                          }}
                                        ></i>
                                      </button>
                                    ) : (
                                      <button
                                        title="Favourite"
                                        className="jobAction"
                                      >
                                        <i
                                          className="bx bx-heart"
                                          onClick={() => {
                                            favouriteJob(item._id, "true");
                                          }}
                                        ></i>
                                      </button>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          }
                        />
                      </Col>
                    ))}

                    <Col md={12} className="mt-3 justify-content-center">
                      {vendorJobs.length === 18 ? (
                        <Pagination
                          count={jobCount}
                          page={pageNum}
                          onChange={loadMoreVendorJobs}
                          variant="outlined"
                          color="primary"
                        />
                      ) : (
                        <Pagination
                          count={jobCount}
                          page={pageNum}
                          onChange={loadMoreFilterJobs}
                          variant="outlined"
                          color="primary"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className="Jobs__listing-wrapper">
                {filterOpen ? (
                  <Row className="tq-form">
                    <>
                      <Form.Group as={Col} sm={3} xs={6}>
                        <Form.Label>Location</Form.Label>
                        <ReactSelect
                          styles={selectStyles}
                          isMulti
                          className="dropdown"
                          options={locations || []}
                          value={locationsearch}
                          onChange={setLocationSearch}
                          labelledBy={"Select"}
                          components={{
                            Option,
                          }}
                          hasSelectAll={false}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={3} xs={6}>
                        <Form.Label>Job Status</Form.Label>

                        {userType === "1110" ? (
                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={status || []}
                            value={status && status.label}
                            // defaultValue={[status[0], status[4]]}
                            onChange={setStatusSearch}
                            labelledBy={"Select"}
                            components={{
                              Option,
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        ) : (
                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={status || []}
                            value={status && status.label}
                            onChange={setStatusSearch}
                            labelledBy={"Select"}
                            components={{
                              Option,
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        )}
                      </Form.Group>
                      <Form.Group as={Col} sm={3} xs={6}>
                        <Form.Label>Designation</Form.Label>

                        <ReactSelect
                          styles={selectStyles}
                          isMulti
                          className="dropdown"
                          options={designations || []}
                          value={designationsearch}
                          onChange={setDesignationSearch}
                          labelledBy={"Select"}
                          hasSelectAll={false}
                          components={{
                            Option,
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </Form.Group>
                      {userType === "1110" ? (
                        <Form.Group as={Col} sm={3} xs={6}>
                          <Form.Label>Company</Form.Label>
                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={companyNames || []}
                            value={companyNamesearch}
                            onChange={setCompanyNameSearch}
                            labelledBy={"Select"}
                            hasSelectAll={false}
                            components={{
                              Option,
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        </Form.Group>
                      ) : (
                        ""
                      )}
                      {userType === "1100" ? (
                        <Form.Group as={Col} sm={3} xs={6}>
                          <Form.Label>Users</Form.Label>
                          <ReactSelect
                            styles={selectStyles}
                            isMulti
                            className="dropdown"
                            options={users || []}
                            value={usersearch}
                            onChange={setUsersSearch}
                            labelledBy={"Select"}
                            hasSelectAll={false}
                            components={{
                              Option,
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                          />
                        </Form.Group>
                      ) : (
                        ""
                      )}
                      <Form.Group as={Col} sm={3} xs={6} className="mt-4">
                        <Form.Label>Sort By</Form.Label>
                        <ReactSelect
                          styles={selectStyles}
                          className="shortByFilterData"
                          isMulti
                          options={sortBy || []}
                          value={sortBy.label}
                          // defaultValue={sortBy[0]}
                          onChange={setSortBySeach}
                          components={{
                            Option,
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        xs={6}
                        className="mt-4 d-flex"
                      >
                        <ButtonGroup className="align-self-end">
                          <Button
                            variant="secondary"
                            onClick={handleResetClick}
                          >
                            Reset
                          </Button>
                          <Button variant="primary" onClick={handleSearchClick}>
                            Apply
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </>
                    {/* </Row> */}
                  </Row>
                ) : null}
                <Row>
                  {jobs.map((item, index) => (
                    <Col md={4} className="mt-5" key={index}>
                      <JobsCard
                        badge={
                          <Badge type={item.status} content={item.status} />
                        }
                        ChartDatas={
                          !item.rCt ||
                          (item.rCt &&
                            !item.rCt.Pending &&
                            !item.rCt.Screened &&
                            !item.rCt.OfferRejected &&
                            !item.rCt.InProcess &&
                            !item.rCt.Onhold &&
                            !item.rCt.RoundClear &&
                            !item.rCt.Scheduled &&
                            !item.rCt.RoundCompleted &&
                            !item.rCt.OfferRevoked &&
                            !item.rCt.Offered &&
                            !item.rCt.Proposed &&
                            !item.rCt.Dropped &&
                            !item.rCt.Declined &&
                            !item.rCt.Rejected &&
                            !item.rCt.OfferAccepted &&
                            !item.rCt.Joined) ? (
                            <div align="center">
                              <span>No resume yet!</span>
                            </div>
                          ) : (
                            <div align="center">
                              <Chart
                                jobId={item._id}
                                resumeCount={item.rCt ? item.rCt : {}}
                              />
                            </div>
                          )
                        }
                        jobLink={() => clickDashboardRequirement(item._id)}
                        CardValue={item._id}
                        jodstatus={item.jobOfTheDay}
                        jobType={item.jobType}
                        reqId={item.reqId}
                        Heading={item.desig}
                        properties={item.properties}
                        posted={new Date(item.cOn).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                        company={item.cName}
                        skills={item.pSkills.toString()}
                        location={item.city + "    "}
                        FavIcon={
                          <div className="buttonsGroup">
                            {item.status === "Open" ? (
                              <>
                                <div>
                                  {item.favourite &&
                                  item.favourite.filter(
                                    (item) => item === userID
                                  )[0] ? (
                                    <button
                                      title="Remove favourite"
                                      className="jobAction"
                                    >
                                      <i
                                        className="bx bxs-heart"
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          favouriteJob(item._id, "false");
                                        }}
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      title="Favourite"
                                      className="jobAction"
                                    >
                                      <i
                                        className="bx bx-heart"
                                        onClick={() => {
                                          favouriteJob(item._id, "true");
                                        }}
                                      ></i>
                                    </button>
                                  )}
                                </div>
                                <div align="center" title="Edit Job">
                                  <button
                                    className="jobAction"
                                    onClick={(e) => {
                                      handleViewRequirement(item._id);
                                      e.stopPropagation();
                                      e.nativeEvent.stopImmediatePropagation();
                                    }}
                                    value={item._id}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                </div>
                                <div title="Inactive Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(event) =>
                                      updateJob(
                                        event,
                                        "Inactive",
                                        item._id,
                                        item.statHis,
                                        item.coOwners
                                      )
                                    }
                                  >
                                    <i className="bx bx-hide"></i>
                                  </button>
                                </div>
                                <div align="center" title="Close Job">
                                  <button
                                    className="jobAction"
                                    justIcon
                                    onClick={(e) =>
                                      handleShowCloseJob(e, item._id)
                                    }
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>
                              </>
                            ) : item.status === "Awaiting Approval" ? (
                              <>
                                <div align="center" title="Edit Job">
                                  <button
                                    className="jobAction"
                                    onClick={(e) => {
                                      handleViewRequirement(item._id);
                                      e.stopPropagation();
                                      e.nativeEvent.stopImmediatePropagation();
                                    }}
                                    value={item._id}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                </div>
                                <div align="center" title="Close Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(e) =>
                                      handleShowCloseJob(e, item._id)
                                    }
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>
                              </>
                            ) : item.status === "Draft" ? (
                              <>
                                <div align="center" title="Edit Job">
                                  <button
                                    className="jobAction"
                                    onClick={(e) => {
                                      handleViewRequirement(item._id);
                                      e.stopPropagation();
                                      e.nativeEvent.stopImmediatePropagation();
                                    }}
                                    value={item._id}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                </div>
                                <div title="Post Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(event) =>
                                      updateJob(
                                        event,
                                        "Awaiting Approval",
                                        item._id,
                                        item.statHis,
                                        item.coOwners
                                      )
                                    }
                                  >
                                    <i className="bx bx-check-square"></i>
                                  </button>
                                </div>
                                <div align="center" title="Close Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(e) =>
                                      handleShowCloseJob(e, item._id)
                                    }
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>
                              </>
                            ) : item.status === "Close" ? (
                              <>
                                <div title="Repost Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(event) =>
                                      updateJob(
                                        event,
                                        "Open",
                                        item._id,
                                        item.statHis,
                                        item.coOwners
                                      )
                                    }
                                  >
                                    <i className="bx bx-repost"></i>
                                  </button>
                                </div>
                              </>
                            ) : item.status === "Inactive" ? (
                              <>
                                <div align="center" title="Edit Job">
                                  <button
                                    className="jobAction"
                                    onClick={(e) => {
                                      handleViewRequirement(item._id);
                                      e.stopPropagation();
                                      e.nativeEvent.stopImmediatePropagation();
                                    }}
                                    value={item._id}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                </div>
                                <div title="Activate Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(event) =>
                                      updateJob(
                                        event,
                                        "Open",
                                        item._id,
                                        item.statHis,
                                        item.coOwners
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-eye"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                  </button>
                                </div>
                                <div align="center" title="Close Job">
                                  <button
                                    className="jobAction"
                                    type="submit"
                                    onClick={(e) =>
                                      handleShowCloseJob(e, item._id)
                                    }
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        }
                      />
                    </Col>
                  ))}

                  <Col md={12} className="mt-3 justify-content-center">
                    {jobs.length === 18 ? (
                      <Pagination
                        count={jobCount}
                        page={pageNum}
                        onChange={loadMoreJobs}
                        variant="outlined"
                        color="primary"
                      />
                    ) : (
                      <Pagination
                        count={jobCount}
                        page={pageNum}
                        onChange={loadMoreFilterJobs}
                        variant="outlined"
                        color="primary"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        show={closeJob}
        dialogClassName="tq__modal-default"
        onHide={handleCloseJob}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Close Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form ">
          <Form.Group>
            <Form.Label className="modal__heading mb-3">
              Did this position close through talenQ
            </Form.Label>
            <div className="form-control">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="yes"
                  name="is_talenq_closed"
                  value={true}
                  checked={
                    job.feedback && job.feedback.is_talenq_closed === true
                  }
                  onClick={handleInputChange}
                />
                <label className="form-check-label" for="pg">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="no"
                  value={false}
                  name="is_talenq_closed"
                  checked={
                    job.feedback && job.feedback.is_talenq_closed === false
                  }
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="pan">
                  No
                </label>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label className="modal__heading mb-3">
              All pending resume would be Rejected, Please enter any comment.
            </Form.Label>
            <FormControl
              value={job.closedComment}
              onChange={handleInputChange}
              aria-label="Small"
              aria-describedby=""
              placeholder="Your Comment Here...."
              name="closedComment"
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleCloseJob}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(event) =>
              updateJob(
                event,
                "Close",
                job.jobId,
                job.statHis,
                job.coOwners,
                job.closedComment
              )
            }
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    xyz: state.main,
    jobId: state.mainjobid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setjobId: bindActionCreators(setjobId, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsPage);
