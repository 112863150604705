import React, { useState, useEffect } from "react";
// import moment from "moment";
// import PropTypes from "prop-types";
//import "../../assets/style/tqResponsive.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { EditorState, ContentState } from "draft-js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import endPoints, { dEndPoints } from "../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Document, Page, pdfjs } from "react-pdf";
import { showSnackBar, showLoader } from "../../actions/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import queryString from "query-string";
import { Link } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
let htmlToDraft, Editor;
if (typeof window !== "undefined") {
  htmlToDraft = require("html-to-draftjs").default;
}
if (typeof window !== "undefined") {
  Editor = require("react-draft-wysiwyg").Editor;
}
// let FileViewer = null;
// if (typeof window !== "undefined") {
//   FileViewer = require("react-file-viewer");
// }

const JdHeader = (header) => {
  return (
    <div className="jd__header">
      <Row>
        <Col sm={9}>
          <h2 className="jd__title">
            {header.jdTitle} ({header.jdType})
          </h2>
          <small>
            {header.company} - {header.locations}
          </small>
        </Col>
        <Col sm={3} className="text-right">
          <p className="jd__postedon">
            <strong>Posted on :</strong>{" "}
            {header.postedOn
              ? new Date(header.postedOn).toLocaleDateString("en-US", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              : ""}{" "}
          </p>
        </Col>
      </Row>
    </div>
  );
};
// header component ends

const JdBody = (body) => {
  const [fileUrl, setFileUrl] = useState(null);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [jobDes, setJobDesc] = useState([]);

  // if (!body.jdPreview || !body.jdPreview) {
  //   return null;
  // }
  const blocksFromHtml = htmlToDraft(body.jdPreview && body.jdPreview);

  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);

  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  var date = new Date();
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  var editorStyle = {
    width: "100%",
    height: "100%",
  };
  const path = body.jdFile ? body.jdFile.n_fpath : "";
  const downloadJdFile = (preview = false) => {
    const path = body.jdFile ? body.jdFile.n_fpath : "";
    //props.showLoader({ show: true });
    axios
      .get(endPoints.uploadFile, {
        params: {
          fileType: "Jd",
          fileUrl: path,
        },
        responseType: "blob",
      })
      .then((res) => {
        // props.showLoader({ show: false });
        var blob = new Blob([res.data], { type: res.headers["content-type"] });
        const href = window.URL.createObjectURL(blob);
        if (preview) {
          setFileUrl(href);
        } else {
          var link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `${body.jdCname}-${date.toLocaleDateString()}`
          );
          link.click();
        }
      })
      .catch((error) => {
        // props.showLoader({ show: false });
        console.log("error in download file", error);
      });
  };
  useEffect(() => {
    downloadJdFile(true);
    //getInterviewRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  const userType = localStorage.getItem("ut");
  var ext = path ? path.split(".") : "";
  var type = ext[ext.length - 1];

  var newJobDesc = [];
  if (!body.jdFile) {
    var htmlString = body.jdPreview;
    if (htmlString) {
      var plainString = htmlString.replace(/<[^>]+>/g, "");
    }
    newJobDesc.push(plainString);
  }
  const alignJd = () => {
    //  var cjob = { ...body };
    var data = {
      jobDesc: newJobDesc,
    };
    body.showSnackBar({
      message: "JD Copied",
      open: true,
      type: "success",
      vertical: "top",
      horizontal: "center",
    });
    axios
      .post(endPoints.alignJd, data)
      .then((res) => {
        var data = res.data;

        setJobDesc(data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  return (
    <div className="Job__body">
      <Row>
        <Col sm={7}>
          <div className="jd__data">
            <strong>Req Id : </strong>
            <span>{body.rqId}</span>
          </div>
          <div className="jd__data">
            <strong>Qualification : </strong>
            <span>{body.Qualification}</span>
          </div>
          <div className="jd__data">
            <strong>Job Locations : </strong>
            <span>{body.Locations}</span>
          </div>
          <div className="jd__data">
            <strong>Work Experience : </strong>
            <span>{body.Experience}</span>
          </div>
        </Col>
        <Col sm={5}>
          <div className="jd__data">
            <strong>Positions : </strong>
            <span>{body.Openings}</span>
          </div>
          <div className="jd__data">
            <strong>Notice Period : </strong>
            <span>{body.Notice}</span>
          </div>
          <div className="jd__data">
            <strong>Budget : </strong>
            <span>{body.Budget}</span>
          </div>
          <div className="jd__data">
            <strong>Valid Till : </strong>
            <span>
              {body.Valid
                ? new Date(body.Valid).toLocaleDateString("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                : ""}
            </span>
          </div>
        </Col>
        <Col xs={12}>
          <div className="jd__data jd__skills">
            <strong>Primary Skills : </strong>
            <span>{body.SkillsP}</span>
          </div>
          <div className="jd__data  jd__skills">
            <strong>Secondary Skills : </strong>
            <span>{body.SkillsS}</span>
          </div>
          {body.jobtype === "Walkin" ? (
            <>
              {body.walkInDate ? (
                <>
                  <div className="jd__data  jd__skills">
                    <strong>Walkin Interview Date : </strong>
                    <span>
                      {new Date(body.walkInDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      })}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
              {body.walkInTime ? (
                <div className="jd__data  jd__skills">
                  <strong>Walkin Interview Timing : </strong>
                  <span>{body.walkInTime}</span>
                </div>
              ) : (
                ""
              )}
              {body.walkInVenue ? (
                <div className="jd__data  jd__skills">
                  <strong>Walkin Interview Venue : </strong>
                  <span>{body.walkInVenue}</span>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {body.extraDetails ? (
            <div className="jd__data  jd__skills">
              <strong>Additional details of JD : </strong>
              <span>{body.extraDetails}</span>
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col xs={12}>
          <div className="jd__preview-wrapper separator__tq">
            <div className="jd__data">
              <h3 className="jd__doc-preview__heading">Job Description :</h3>
              {body.jdFile && body.jdFile.n_fpath ? (
                <strong className="text-right position-relative">
                  <Button
                    variant="success downloadDoc rounded-0"
                    onClick={() => downloadJdFile()}
                  >
                    <i className="bx bx-file"></i>
                    Download JD
                  </Button>
                </strong>
              ) : (
                ""
              )}
            </div>
            {/* {body.jdFile &&
            body.jdFile.fname &&
            type.toLowerCase() === "docx" ? (
              <FileViewer
                key={fileUrl}
                fileType={type.toLowerCase()}
                filePath={fileUrl}
              /> */}
            {body.jdFile &&
            body.jdFile.n_fpath &&
            type.toLowerCase() === "pdf" ? (
              <div>
                <div>
                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </div>
                  <div className="buttonc">
                    <Button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className="Pre"
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageNumber}
                    width={900}
                    height={100}
                    style={{ border: "2px" }}
                  />
                </Document>
              </div>
            ) : body.jdPreview && body.jdPreview ? (
              <>
                {userType === "1110" || userType === "1000" ? (
                  <CopyToClipboard text={jobDes}>
                    <Button onClick={alignJd}>Copy JD</Button>
                  </CopyToClipboard>
                ) : (
                  ""
                )}
                <Editor
                  editorState={editorState}
                  wrapperClassName="rich-editor demo-wrapper"
                  toolbarHidden
                  wrapperStyle={editorStyle}
                />
              </>
            ) : (
              ""
            )}

            {/* <div className="jd__doc-preview">{body.jdPreview}</div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
const JdFooter = (props) => {
  //   console.log("linkjobIds", props.linkjobIds);
  //   const enrolled = props.linkjobIds.indexOf(props.jobId) > -1 ? true : false;
  let history = new useHistory();
  const associateJobEnroll = () => {
    showLoader({ show: true });
    const vendorCorporateId = localStorage.getItem("vci");
    //const vendorCorporateId = cacheData ? cacheData.vci : "";
    const jobId = props.jobdetails._id;
    const cId = props.jobdetails.cId;
    const data = {
      jobId: jobId,
      corporateId: cId,
      vendorCompanyId: vendorCorporateId,
    };
    axios
      .post(dEndPoints.enrollJob(vendorCorporateId), data)
      .then((res) => {
        showLoader({ show: false });
        var data = res.data;
        if (data && data.code === 100000) {
          history.push(`/alljobs/${jobId}?enroll=${true}`);
          props.showUploadResume();
          props.showSnackBar({
            message: "Job Enrolled Successfully .",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        showLoader({ show: false });
        showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };

  return (
    <div className="jd__footer text-center">
      {/* {userType === "1000" && enrolled === "false" ? (
        <button className="btn btn-primary">I Agree</button>
      ) : null} */}
      {props.hidebutton ? (
        <Button className="btn btn-primary" onClick={props.showTermsCondition}>
          I am Interested
        </Button>
      ) : (
        ""
      )}
      {props.showTerms ? (
        <div className="job__enroll-terms">
          <h4>Terms of Engagement:</h4>
          <Row className="terms__of-enroll justify-content-center py-4">
            {props.jobdetails.vRate ? (
              <Form.Group as={Col} xs={12}>
                <span className="text-left">
                  <strong>Rate :</strong> {props.jobdetails.vRate} %
                </span>
              </Form.Group>
            ) : (
              <></>
            )}
            <Form.Group as={Col} xs={12}>
              <span className="text-left">
                <strong>Replacement :</strong> {props.jobdetails.replacement}{" "}
                Month
              </span>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <span className="text-left">
                <strong>Payment :</strong> {props.jobdetails.paymentTerm} Month
              </span>
            </Form.Group>
          </Row>
          <ButtonGroup>
            <Button className="btn btn-primary" onClick={associateJobEnroll}>
              I Agree
            </Button>
            <Button
              variant="secondary"
              //  className="btn btn-primary"
            >
              <Link to="/find-jobs">Cancel</Link>
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
const AssociateJobVendor = (props) => {
  // var jobId = props.jobId;
  const [showTerms, setShowTerms] = useState(false);
  const [hidebutton, setHidebutton] = useState(true);

  const showTermsCondition = () => {
    setHidebutton(false);
    setShowTerms(true);
  };
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  //const [pageNumber, setPageNumber] = useState(1);

  // const history = useHistory();
  //console.log(history);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }

  // function changePage(offset) {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offset);
  // }

  // function previousPage() {
  //   changePage(-1);
  // }

  // function nextPage() {
  //   changePage(1);
  // }
  // var editorStyle = {
  //   width: "100%",
  //   height: "100%",
  // };

  if (!props.jobdetails || !props.jobdetails.jobDesc) {
    return null;
  }
  // const blocksFromHtml = htmlToDraft(
  //   props.jobdetails && props.jobdetails.jobDesc
  // );

  // const { contentBlocks, entityMap } = blocksFromHtml;
  // const contentState = ContentState.createFromBlockArray(
  //   contentBlocks,
  //   entityMap
  // );
  // const editorState = EditorState.createWithContent(contentState);

  return (
    <div className="Job__detail-page mb-5">
      <JdHeader
        jdTitle={props.jobdetails.desig}
        jdType={props.jobdetails.jobType}
        locations={props.jobdetails.city}
        postedOn={props.jobdetails.cOn}
        company={props.jobdetails.cName}
      />
      <JdBody
        jdCname={props.jobdetails.desig}
        rqId={props.jobdetails.reqId}
        Qualification={props.jobdetails.qual}
        Locations={
          props.jobdetails && props.jobdetails.city
            ? props.jobdetails.city.join()
            : ""
        }
        Experience={
          props.jobdetails.expFrom + " - " + props.jobdetails.expTo + " Years"
        }
        Openings={props.jobdetails.totpos}
        Notice={
          props.jobdetails && props.jobdetails.nPeriod
            ? props.jobdetails.nPeriod + "  Days "
            : "NA"
        }
        Budget={
          props.jobdetails.minBudget +
          " LPA" +
          "-" +
          props.jobdetails.maxBudget +
          " LPA"
        }
        Valid={props.jobdetails.validFor}
        SkillsP={
          props.jobdetails && props.jobdetails.pSkills
            ? props.jobdetails.pSkills.join()
            : ""
        }
        SkillsS={
          props.jobdetails && props.jobdetails.sSkills
            ? props.jobdetails.sSkills.join()
            : ""
        }
        extraDetails={props.jobdetails && props.jobdetails.extraDetails}
        jdPreview={props.jobdetails.jobDesc}
        jdFile={props.jobdetails.jdFile}
        walkInDate={
          props.jobdetails.walkinD ? props.jobdetails.walkinD.walkInDate : ""
        }
        walkInTime={
          props.jobdetails.walkinD ? props.jobdetails.walkinD.walkInTime : ""
        }
        walkInVenue={
          props.jobdetails.walkinD ? props.jobdetails.walkinD.walkInVenue : ""
        }
        jobtype={props.jobdetails.jobType}
        showSnackBar={props.showSnackBar}
      />
      <JdFooter
        // linkjobIds={linkjobIds}
        jobdetails={props.jobdetails}
        jobId={props.jobId}
        hidebutton={hidebutton}
        showTerms={showTerms}
        showTermsCondition={showTermsCondition}
        showUploadResume={props.showUploadResume}
        showLoader={props.showLoader}
        showSnackBar={props.showSnackBar}
      />
    </div>
  );
};

//export default JobDetails;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AssociateJobVendor);
