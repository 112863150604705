import React, { useCallback, useState, useEffect } from "react";
// import AsyncSelect from "react-select/async";
import skillSet from "../../utils/skills";
import collegeSet from "../../utils/college";
import axios from "axios";
import endPoints, { dEndPoints } from "../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { showLoader, showSnackBar } from "../../actions/index";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { useDropzone } from "react-dropzone";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
//context api
import { useGlobalContext } from "../../context/appContext";
// import Container from "react-bootstrap/Container";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CreatableSelect from "react-select/creatable";
// import components from "../../components/jobdetailspage/createjob/MultiSelectAutoComplete";
import { useParams } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// import Autocomplete, {
//   createFilterOptions,
// } from "@material-ui/lab/Autocomplete";

function gettext(pdfUrl) {
  var pdf = window.pdfjsLib.getDocument(pdfUrl);
  return pdf.promise.then(function (pdf) {
    // get all pages text
    var maxPages = pdf._pdfInfo.numPages;
    var countPromises = []; // collecting all page promises
    for (var j = 1; j <= maxPages; j++) {
      var page = pdf.getPage(j);

      //var txt = "";
      countPromises.push(
        page.then(function (page) {
          // add page promisetextContent
          var textContent = page.getTextContent();
          return textContent.then(function (text) {
            // return content promise
            return text.items
              .map(function (s) {
                return s.str;
              })
              .join(""); // value page text
          });
        })
      );
    }
    // Wait for all pages and join text
    return Promise.all(countPromises).then(function (texts) {
      return texts.join("");
    });
  });
}

const colors = {
  divider: "#d8d8d8",
  error: "#d43f21",
};

export const customStyles = {
  control: (base, state) => {
    let statusColor = colors.divider;

    if (state.selectProps.error) {
      // "state.selectProps" references the component props
      statusColor = colors.error;
    }

    return {
      ...base,
      boxShadow: `0 0 0 2px ${statusColor}`,
      transition: " 0.25s linear",
      transitionProperty: "box-shadow",
    };
  },
};

const UploadResumes = (props, { setsList = props.job.extraFields }) => {
  let { jobId } = useParams();
  const [open, setOpen] = useState(false);
  const [showCanDetails, setShowCanDetails] = useState(false);
  const [candId, setCandId] = useState("");
  const [skills, setSkills] = useState([]);
  const [Field, setFields] = useState([]);
  const [locations, setLocation] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [fileName, setFileName] = useState("");
  const [newFileName, setNewFileName] = useState("");
  // const [getFileData, setGetFileData] = useState();
  const [collegeLookup, SetcollegeLookup] = useState([]);

  const [companyLookup, SetcompanyLookup] = useState([]);
  //const [locationList, setLocationList] = useState([]);
  //const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  let { getAnalyticsData } = useGlobalContext();
  // const [selectedCompanyValue, setSelectedCompanyValue] = useState("");
  // const [validated, setValidated] = useState(false);
  const [canInfo, setCanInfo] = useState({
    name: "",
    email: "",
    contact: "",
    college: [],
    city: [],
    cDesig: [],
    expr: "",
    ctc: "",
    ectc: "",
    cName: [],
    skills: [],
    qual: [],
    nPeriod: null,
    lastworkday: null,
    comment: "",
    pan: "",
    extraFields: [],
    total: null,
    norm_sddc: "",
    exp_val: "",
    sal_val: "",
    dist_val: "",
    cQual: "",
  });

  const [inNotice, setInNotice] = useState(false);
  const [resumeContent, setResumeContent] = useState(false);
  var validated = false;
  useEffect(() => {
    loadDatabaseDetails();
    loadOptions();
    loadDesignationOptions();
    loadCompanyOptions();
    loadQualOptions();
    setFields(props.job.extraFields);
  }, [props.job.extraFields]);

  const handleClickOpen = (event) => {
    if (canInfo.name === "" || !canInfo.name) {
      props.showSnackBar({
        message: "name is empty",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.contact === "" || !canInfo.contact) {
      props.showSnackBar({
        message: " Contact No. is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.contact.length < 10) {
      props.showSnackBar({
        message: "Contact number at least 10 digits",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.email === " " || !canInfo.email) {
      props.showSnackBar({
        message: " Email is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (!canInfo.city || canInfo.city.length === 0) {
      props.showSnackBar({
        message: " City is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.cName.length === 0 || !canInfo.cName) {
      props.showSnackBar({
        message: " Company name is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.cDesig.length === 0 || !canInfo.cDesig) {
      props.showSnackBar({
        message: " Designation is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (!canInfo.expr) {
      props.showSnackBar({
        message: " experience is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (!canInfo.nPeriod) {
      props.showSnackBar({
        message: " Notice Period is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (!canInfo.ctc) {
      props.showSnackBar({
        message: " Current ctc is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (!canInfo.ectc) {
      props.showSnackBar({
        message: " Expected ctc is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.qual.length === 0 || !canInfo.qual) {
      props.showSnackBar({
        message: " Qualification is empty.",
        open: true,
        type: "error",
      });
      return;
    } else if (canInfo.skills.length === 0) {
      props.showSnackBar({
        message: " Skills is empty.",
        open: true,
        type: "error",
      });
      return;
    }
    if (props.job.cInfo && props.job.cInfo["tenPercent"]) {
      if (!canInfo["tenPercent"]) {
        props.showSnackBar({
          message: "10th Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["twelvePercent"]) {
      if (!canInfo["twelvePercent"]) {
        props.showSnackBar({
          message: "12th Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["ugPercent"]) {
      if (!canInfo["ugPercent"]) {
        props.showSnackBar({
          message: "UG Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["pgPercent"]) {
      if (!canInfo["pgPercent"]) {
        props.showSnackBar({
          message: "PG Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["pan"]) {
      if (canInfo["pan"] === " " || !canInfo["pan"]) {
        props.showSnackBar({
          message: "PAN Card detail is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setShowCanDetails(false);
  };
  // const handleInput = (value) => {
  //   var setValue = value;
  // };
  // const handleInputCompany = (value) => {
  //   var setValue = value;
  // };
  // const handleInputCollege = (value) => {
  //   var setValue = value;
  // };
  // const handleInputQual = (value) => {
  //   var setValue = value;
  // };
  // const handleChange = (value) => {
  //   const info = { ...canInfo };
  //   setSelectedValue(value);
  //   info.city = value.name;
  //   setCanInfo(info);
  // };

  // const handleChangeCompany = (value) => {
  //   const info = { ...canInfo };
  //   setSelectedCompanyValue(value);
  //   info.cName = value.name;
  //   setCanInfo(info);
  // };

  const loadOptions = () => {
    axios.get(endPoints.getLocationLookup + "?location=").then((res) => {
      const data = res.data;
      setLocation(data);
    });
  };
  const loadCompanyOptions = () => {
    axios.get(endPoints.getCompanyLookup + "?company=TATA").then((res) => {
      const data = res.data;
      SetcompanyLookup(data);
    });
  };

  const loadDesignationOptions = () => {
    axios
      .get(endPoints.getDesignationLookup + "?desig=software")
      .then((res) => {
        const data = res.data;

        setDesignation(data);
      });
  };

  // const loadCollegeOptions = () => {
  //   axios.get(endPoints.getCollegeLookup + "?college=").then((res) => {
  //     const data = res.data;
  //     console.log("loadCollegeOptions", data);
  //     SetQualLookup(data);
  //   });
  // };

  const loadQualOptions = () => {
    axios.get(endPoints.getQualificationLookup + "?qual=").then((res) => {
      const data = res.data;
      SetcollegeLookup(data);
    });
  };
  const QualificationLookup = collegeLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CompanyLookup = companyLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CollegeLookup = collegeSet.map((item) => {
    return { label: item.name, value: item.name };
  });
  const locationLookup = locations.map((item) => {
    return { label: item.name, value: item.name };
  });

  const designationLookup = designation.map((item) => {
    return { label: item.name, value: item.name };
  });

  const onDrop = useCallback((acceptedFiles) => {
    uploadFile(acceptedFiles);
    setFileName(acceptedFiles[0].name);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    // isDragReject,
  } = useDropzone({
    onDrop,
  });

  const uploadFile = async (acceptedFiles) => {
    var form = new FormData();

    form.append("fileData", acceptedFiles[0]);
    form.append("size", acceptedFiles[0].size);
    form.append("fileType", acceptedFiles[0].type);
    setCanInfo({
      name: "",
      email: "",
      contact: "",
      college: [],
      city: [],
      cDesig: [],
      expr: "",
      ctc: "",
      ectc: "",
      cName: [],
      skills: [],
      qual: [],
      extraFields: [],
      nPeriod: null,
      lastworkday: null,
      tenPercent: "",
      twelvePercent: "",
      ugPercent: "",
      pgPercent: "",
      pan: "",
      comment: "",
    });

    setInNotice(false);
    props.showLoader({ show: true });

    await axios
      .post(endPoints.uploadResume, form, {
        params: { fileType: "Resume" },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;

        if (data && data.code === 100000) {
          setNewFileName(data.data.rd.fname);
          setShowCanDetails(true);
          const candidateId = data && data.data._id ? data.data._id : "";
          setCandId(candidateId);
          extractResume(acceptedFiles[0], candidateId);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
        console.log("error in upload file", error);
      });
  };

  const loadDatabaseDetails = () => {
    const selectInput = {
      metaData: [
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "LOCATION",
        },

        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "SKILL",
        },
      ],
    };
    props.showLoader({ show: true });
    axios({
      url: endPoints.lookup,
      data: selectInput,
      method: "POST",
    }).then((res) => {
      props.showLoader({ show: false });
      const data = res.data;

      if (data && data.code === 100000) {
        const skill = data && data.data && data.data.SKILL;
        setSkills(skill);
        // const location = data && data.data && data.data.LOCATION;
      }
    });
  };
  // const loadDetails = () => {
  //   props.showLoader({ show: true });
  //   axios
  //     .get(endPoints.redisLookup + "?lookup=SKILL&lookup=LOCATION")
  //     .then((res) => {
  //       props.showLoader({ show: false });
  //       const data = res.data;

  //       if (data && data.code === 100000) {
  //         const skill = data && data.data && data.data.SKILL;
  //         setSkills(skill);
  //         const location = data && data.data && data.data.LOCATION;
  //         //setcityList(location);
  //       }
  //     })
  //     .catch((error) => {});
  // };
  const skillNew =
    skills &&
    skills.map((item) => {
      return { label: item.name, value: item.name };
    });
  const skillss =
    skillSet &&
    skillSet.map((item) => {
      return { label: item.name, value: item.name };
    });

  var pror = ["label", "value"];

  var skill =
    skillNew &&
    skillNew
      .filter(function (o1) {
        return skillss.some(function (o2) {
          return o1.label === o2.label; // assumes unique id
        });
      })
      .map(function (o) {
        return pror.reduce(function (newo, value) {
          newo[value] = o[value];
          return newo;
        }, {});
      });

  const recommendationResume = (cmId) => {
    const vendorId = localStorage.getItem("i");
    var data = { ...canInfo };
    data.candId = candId;
    data.vId = vendorId;
    data.cId = props.job.cId;
    data.cmId = cmId;
    data.fname = newFileName;

    axios
      .post(
        dEndPoints.RankingAndRecommendationResume(jobId, vendorId, candId),
        data
      )
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };

  // /////extraction Resume Data  //////////

  const extractResume = (file, candId) => {
    setResumeContent(true);
    const vendorId = localStorage.getItem("i");
    var form = new FormData();
    form.append("file[]", file);

    axios
      .post(dEndPoints.extractResume(jobId, vendorId, candId), form)
      .then((res) => {
        setResumeContent(false);

        var data = res.data;
        // const replaceRegex = /' '/g;
        // const replaceSQuoteRegex = /'/g;
        // data.skills = data.skills;
        setCanInfo(data);
      })
      .catch((error) => {
        console.log("error file", error);
        setResumeContent(false);
        var reader = new FileReader();
        reader.onload = function (e) {
          gettext(e.target.result).then(
            function (text) {
              extractTextFromResume(text);
            },
            function (reason) {
              console.error(reason);
            }
          );
        };
        reader.readAsArrayBuffer(file);
      });
  };

  // extract Resume Text
  const extractTextFromResume = (text) => {
    // const jobID = props.job._id;
    const vendorId = localStorage.getItem("i");
    //const vendorId = cacheData ? cacheData.i : "";
    const data = {
      content: text,
    };
    axios
      .post(dEndPoints.predictionResume(jobId, vendorId), data)
      .then((res) => {})
      .catch((error) => {
        console.log("error", error);
      });
  };
  // input Change
  const handleInputChange = (e) => {
    const info = { ...canInfo };
    if (
      e.target.id === "ctc" ||
      e.target.id === "ectc" ||
      e.target.id === "expr"
    ) {
      info[e.target.id] = Number(e.target.value);
    } else {
      info[e.target.id] = e.target.value;
    }

    setCanInfo(info);
  };
  const handleChangeSkills = (value) => {
    var info = { ...canInfo };
    info.skills = value ? value.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeQuallookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.qual = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };

  const handleChangeLocationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.city = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeDesinationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.cDesig = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeCompanylookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.cName = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeCollegelookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.college = newQual ? newQual.map((el) => el.value) : "";

    setCanInfo(info);
  };
  const handleChangeNoticeP = (e) => {
    const info = { ...canInfo };
    info.nPeriod = e.target.value;
    setCanInfo(info);
  };
  const changeNoticeCheck = (e) => {
    setInNotice(e.target.checked);
  };
  const handleDateChange = (date, id) => {
    const info = { ...canInfo };
    info[id] = date;
    setCanInfo(info);
  };

  const submitCanDetails = (cmId) => {
    const vendorCorporateId = localStorage.getItem("vci");
    const jobId = props.job._id;
    const info = { ...canInfo };
    if (info._links) {
      delete info._links;
    }
    if (info._softSkills) {
      delete info._softSkills;
    }
    if (info.a_all_loc) {
      delete info.a_all_loc;
    }
    if (info.a_dates) {
      delete info.a_dates;
    }
    if (info.address) {
      delete info.address;
    }
    if (info.all_cName) {
      delete info.all_cName;
    }
    if (info.all_exp) {
      delete info.all_exp;
    }
    if (info.certific) {
      delete info.certific;
    }
    if (info.content) {
      delete info.content;
    }
    if (info.driveLsc) {
      delete info.driveLsc;
    }
    if (info.passport) {
      delete info.passport;
    }
    if (info.percentage) {
      delete info.percentage;
    }
    if (info.pinCode) {
      delete info.pinCode;
    }
    if (info.prm_sk) {
      delete info.prm_sk;
    }
    if (info.relFld) {
      delete info.relFld;
    }
    if (info.univ) {
      delete info.univ;
    }

    props.showLoader({ show: true });
    axios
      .put(dEndPoints.createCanProfile(vendorCorporateId, jobId, candId), info)
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100000) {
          recommendationResume(cmId);
        }

        handleClose();
        setCanInfo({});
        handleCancel();
        setFileName("");
        setSelectedValue("");
        setTimeout(() => {
          props.showLoader({ show: true });
          props.showSnackBar({
            message: "Resume is loading, Please wait",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
        }, 200);
        setTimeout(() => {
          props.showLoader({ show: true });
          props.loadCandidatesDetails();
          getAnalyticsData(jobId);
        }, 7000);

        props.showLoader({ show: false });
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };
  const checkDuplicacyResumes = () => {
    const vendorId = localStorage.getItem("i");
    const info = { ...canInfo };
    info.cId = props.job.cId;
    props.showLoader({ show: true });

    axios
      .post(dEndPoints.checkDuplicateResume(jobId, vendorId, candId), info)
      .then((res) => {
        handleClose();
        if (res.status === 204) {
          props.showLoader({ show: false });
          handleCancel();
          setFileName("");
          props.showSnackBar({
            message: `Duplicate candidate. Please upload a different profile.`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else {
          handleClose();
          submitCanDetails(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        props.showLoader({ show: false });
        submitCanDetails();
      });
  };
  const handleChangeNewField = (e, i) => {
    const { value, name } = e.target;

    const newState = [...Field];
    newState[i] = {
      ...newState[i],
      [name]: value,
    };

    setFields(newState);
  };

  canInfo.extraFields = Field;

  return (
    <section className="browse__resume">
      {props.job.disRes === true ? (
        <p className="tq__default-text red-text text-center">
          Uploading new resumes is disabled by <strong>TalenQ Admin</strong>.
          Please work on other priority requirements.
        </p>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <div className="text-center resume__upload">
                <Button justIcon className="tq-gradient">
                  <CloudUploadIcon />
                </Button>
                <div
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {fileName ? (
                    <span
                      style={{ color: "green", fontWeight: "600" }}
                      className="mt-2"
                    >
                      {fileName} uploaded successfully{" "}
                    </span>
                  ) : (
                    <>
                      Click Here to Upload Resume.
                      <p>You can upload .pdf, .docx file formats here</p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {showCanDetails ? (
        <>
          <div className="text-center">
            {resumeContent ? (
              <span>
                automatically extracting content from Resume{" "}
                <CircularProgress size={35} color="primary" />
              </span>
            ) : (
              ""
            )}
          </div>

          <Form className="tq-form mt-4 pt-4" noValidate validated={validated}>
            <div className="tq__page-header text-center mb-4">
              <h1 className="pageHeadings">Resume Details</h1>
            </div>
            <Row className="text-left mb-4">
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  Candidate Name <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  placeholder="eg : Ravi"
                  required="required"
                  onChange={handleInputChange}
                  value={canInfo.name}
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  Contact No <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="contact"
                  type="text"
                  placeholder="eg : 98668XXX00 "
                  value={canInfo.contact}
                  required="required"
                  maxLength="12"
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  Email <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  placeholder="eg. example@domain.com"
                  value={canInfo.email}
                  required="required"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>
            <Row className="text-left customize__text-field mb-4">
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  City <small className="required__form">*</small>
                </Form.Label>
                {/* <Form.Control
                  type="text"
                  placeholder="eg. Mumbai, Pune"
                  id="city"
                  value={canInfo.city}
                  required="required"
                  onChange={handleInputChange}
                /> */}

                {/* <AsyncSelect
                cacheOptions
                defaultOptions
                // id="city"
                value={selectedValue}
                inputProps={{
                  name: "city",
                  id: "city",
                }}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.name}
                loadOptions={loadOptions}
                onInputChange={handleInput}
                onChange={handleChange}
              /> */}

                <CreatableSelect
                  inputId="city"
                  TextFieldProps={{
                    label: "city",
                    InputLabelProps: {
                      htmlFor: "city",
                      shrink: true,
                    },
                  }}
                  options={locationLookup}
                  // components={components}
                  value={
                    canInfo.city
                      ? canInfo.city.map((ele) => ({
                          label: ele,
                          value: ele,
                        }))
                      : []
                  }
                  onChange={handleChangeLocationlookUp}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  Current Company <small className="required__form">*</small>
                </Form.Label>
                {/* <Form.Control
                id="cName"
                type="text"
                placeholder="eg. TCS, Infosys"
                value={canInfo.cName}
                required="required"
                onChange={handleInputChange}
              /> */}

                <CreatableSelect
                  inputId="cName"
                  TextFieldProps={{
                    label: "cName",
                    InputLabelProps: {
                      htmlFor: "cName",
                      shrink: true,
                    },
                  }}
                  options={CompanyLookup}
                  // components={components}
                  value={
                    canInfo.cName
                      ? canInfo.cName.map((ele) => ({
                          label: ele,
                          value: ele,
                        }))
                      : []
                  }
                  onChange={handleChangeCompanylookUp}
                  required
                />
                {/* <AsyncSelect
                cacheOptions
                defaultOptions
                // id="city"
                value={selectedCompanyValue}
                inputProps={{
                  name: "cName",
                  id: "cName",
                }}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.name}
                loadOptions={loadCompanyOpt}
                onInputChange={handleInputCompany}
                onChange={handleChangeCompany}
              /> */}
              </Form.Group>
            </Row>
            <Row className="text-left customize__text-field mb-4">
              <Form.Group as={Col}>
                <Form.Label>
                  Skills <small className="required__form">*</small>
                </Form.Label>
                <CreatableSelect
                  {...props}
                  inputId="skills"
                  TextFieldProps={{
                    label: "Skills",
                    InputLabelProps: {
                      htmlFor: "skills",
                      shrink: true,
                    },
                  }}
                  options={skill}
                  value={
                    canInfo.skills
                      ? canInfo.skills.map((ele) => ({
                          label: ele,
                          value: ele,
                        }))
                      : []
                  }
                  onChange={handleChangeSkills}
                  isMulti
                  required
                />
              </Form.Group>
            </Row>
            <Row className="text-left mb-4">
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  Designation <small className="required__form">*</small>
                </Form.Label>
                {/* <Form.Control
                id="cDesig"
                type="text"
                required="required"
                placeholder="eg. Software Engg."
                value={canInfo.cDesig}
                onChange={handleInputChange}
              /> */}
                <CreatableSelect
                  {...props}
                  inputId="cDesig"
                  TextFieldProps={{
                    label: "cDesig",
                    InputLabelProps: {
                      htmlFor: "cDesig",
                      shrink: true,
                    },
                  }}
                  options={designationLookup}
                  value={
                    canInfo.cDesig
                      ? canInfo.cDesig.map((ele) => ({
                          label: ele,
                          value: ele,
                        }))
                      : []
                  }
                  onChange={handleChangeDesinationlookUp}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <Form.Label>
                  Degree/Qualification{" "}
                  <small className="required__form">*</small>
                </Form.Label>
                {/* <Form.Control
                  id="qual"
                  type="text"
                  required="required"
                  placeholder="eg : MBA"
                  value={canInfo.qual}
                  onChange={handleInputChange}
                /> */}
                {/* <AsyncSelect
                cacheOptions
                defaultOptions
                // id="city"
                value={selectedQualValue}
                inputProps={{
                  name: "qual",
                  id: "qual",
                }}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.name}
                loadOptions={loadQualOptions}
                onInputChange={handleInputQual}
                onChange={handleChangeQual}
              /> */}
                <CreatableSelect
                  inputId="qual"
                  TextFieldProps={{
                    label: "qual",
                    InputLabelProps: {
                      htmlFor: "qual",
                      shrink: true,
                    },
                  }}
                  options={QualificationLookup}
                  // components={components}
                  value={
                    canInfo.qual
                      ? canInfo.qual.map((ele) => ({
                          label: ele,
                          value: ele,
                        }))
                      : []
                  }
                  onChange={handleChangeQuallookUp}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <Form.Label>College/University</Form.Label>
                {/* <Form.Control
                  id="college"
                  type="text"
                  //required="required"
                  value={canInfo.college}
                  placeholder="eg : REVA University"
                  onChange={handleInputChange}
                /> */}
                {/* <AsyncSelect
                cacheOptions
                defaultOptions
                // id="city"
                value={selectedCollegeValue}
                inputProps={{
                  name: "college",
                  id: "college",
                }}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.name}
                loadOptions={loadCollegeOptions}
                onInputChange={handleInputCollege}
                onChange={handleChangeCollege}
              /> */}
                <CreatableSelect
                  inputId="college"
                  TextFieldProps={{
                    label: "college",
                    InputLabelProps: {
                      htmlFor: "college",
                      shrink: true,
                    },
                  }}
                  options={CollegeLookup}
                  // components={components}
                  value={
                    canInfo.college
                      ? canInfo.college.map((ele) => ({
                          label: ele,
                          value: ele,
                        }))
                      : []
                  }
                  onChange={handleChangeCollegelookUp}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="text-left mb-4">
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  CTC <small>(In LPA)</small>
                  <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="ctc"
                  type="number"
                  placeholder="5.8 for salary 5,80,000"
                  required="required"
                  value={canInfo.ctc}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  Expected CTC<small>(In LPA)</small>
                  <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="ectc"
                  type="number"
                  placeholder="6.8 for salary 6,80,000"
                  required="required"
                  value={canInfo.ectc}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  Experience <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="expr"
                  type="number"
                  required="required"
                  placeholder="Exp. Counts in No of Years Eg: '1'"
                  value={canInfo.expr}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  Notice Period <small className="required__form">*</small>
                </Form.Label>
                <select
                  id="nPeriod"
                  className="form-select"
                  aria-label="noticePriod"
                  multiple={false}
                  required="required"
                  value={canInfo.nPeriod}
                  onChange={handleChangeNoticeP}
                >
                  <option selected> Select Notice Period</option>
                  <option value={1}>Immediate Joiner</option>
                  <option value={7}>1 Week</option>
                  <option value={14}>2 Weeks</option>
                  <option value={21}>3 Weeks</option>
                  <option value={30}>30 Days</option>
                  <option value={45}>45 Days</option>
                  <option value={60}>60 Days</option>
                  <option value={90}>90 Days</option>
                </select>
              </Form.Group>
            </Row>
            <Row className="text-left mb-4">
              <Form.Group as={Col} xs={3}>
                <Form.Label>Serving Notice</Form.Label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inNotice"
                  name="tenPercent"
                  checked={inNotice}
                  onClick={changeNoticeCheck}
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                {inNotice ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="lastworkday"
                      label="Last Working Day"
                      value={canInfo.lastworkday}
                      onChange={(date) => handleDateChange(date, "lastworkday")}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>
            <Row className="text-left mb-4">
              {props.job.cInfo && props.job.cInfo["tenPercent"] ? (
                <Form.Group as={Col} xs={3}>
                  <Form.Label>
                    10th Percent <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Control
                    id="tenPercent"
                    type="text"
                    placeholder="10Th Percent"
                    onChange={handleInputChange}
                    required="required"
                    value={canInfo["tenPercent"]}
                  />
                </Form.Group>
              ) : null}
              {props.job.cInfo && props.job.cInfo["twelvePercent"] ? (
                <Form.Group as={Col} xs={3}>
                  <Form.Label>
                    12th Percent <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Control
                    id="twelvePercent"
                    type="text"
                    placeholder="12Th Percent"
                    onChange={handleInputChange}
                    required="required"
                    value={canInfo["twelvePercent"]}
                  />
                </Form.Group>
              ) : null}
              {props.job.cInfo && props.job.cInfo["ugPercent"] ? (
                <Form.Group as={Col} xs={3}>
                  <Form.Label>
                    UG Percent <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Control
                    id="ugPercent"
                    placeholder="UG Percent"
                    onChange={handleInputChange}
                    required="required"
                    value={canInfo["ugPercent"]}
                  />
                </Form.Group>
              ) : null}
              {props.job.cInfo && props.job.cInfo["pgPercent"] ? (
                <Form.Group as={Col} xs={3}>
                  <Form.Label>
                    PG Percent <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Control
                    id="pgPercent"
                    placeholder="PG Percent"
                    onChange={handleInputChange}
                    required="required"
                    value={canInfo["pgPercent"]}
                  />
                </Form.Group>
              ) : null}

              {props.job.cInfo && props.job.cInfo["pan"] ? (
                <Form.Group as={Col} xs={3}>
                  <Form.Label>
                    PAN <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Control
                    id="pan"
                    placeholder="PAN No"
                    onChange={handleInputChange}
                    required="required"
                    value={canInfo["pan"]}
                  />
                </Form.Group>
              ) : null}
              {/* {props.job.extraFields ? (
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  Additional <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="extraFields"
                  placeholder="PAN No"
                  onChange={handleInputChange}
                  required="required"
                  value={canInfo["extraFields"]}
                />
              </Form.Group>
            ) : null} */}
              {Field && Field.length > 0
                ? Field &&
                  Field.map(({ name, value }, index) => (
                    <Form.Group as={Col} key={index}>
                      <Form.Label>{name}</Form.Label>
                      <Form.Control
                        type="text"
                        name="value"
                        onChange={(e) => handleChangeNewField(e, index)}
                        value={value}
                      />
                    </Form.Group>
                  ))
                : ""}
              <Form.Group as={Col} xs={12} className="mt-4">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  id="comment"
                  as="textarea"
                  rows={3}
                  placeholder="Comments"
                  value={canInfo.comment}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>
            <ButtonGroup>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              {/* <Button variant="primary" onClick={(e) => handleSubmit(e)}> */}
              <Button variant="primary" onClick={handleClickOpen}>
                Submit
              </Button>
            </ButtonGroup>
          </Form>
        </>
      ) : (
        ""
      )}
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="tq__modal-default"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            We are about to submit " {canInfo.name} " resume for "
            {props.job ? props.job.desig : "Profile"}"
            <p>Click confirm to submit</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <ButtonGroup>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={checkDuplicacyResumes}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

//export default UploadResumes;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(UploadResumes));
