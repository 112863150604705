import React, { useEffect } from "react";

const AnalyticsChartMeter = (props) => {
  const width = props.width || "180px";
  const height = props.height || "135px";
  const fillColor = props.fillColor || "#2D93AD";
  const maxScore = props.maxScore || 80;
  const stroke = props.stroke || "#2D93AD";
  useEffect(() => {
    if (typeof window === "undefined") {
      return null;
    }
    var chart = window.am4core.create(props.id, window.am4charts.GaugeChart);

    // Create axis
    var axis = chart.xAxes.push(new window.am4charts.ValueAxis());
    axis.min = 0;
    axis.max = maxScore;
    //axis.strictMinMax = true;

    // Set inner radius
    chart.innerRadius = -20;

    // Add ranges
    var range = axis.axisRanges.create();
    range.value = 0;
    range.endValue = maxScore / 3;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = window.am4core.color("#DE8F6E");
    range.axisFill.zIndex = -1;

    var range2 = axis.axisRanges.create();
    range2.value = maxScore / 3;
    range2.endValue = (maxScore / 3) * 2;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = window.am4core.color("#DBD56E");
    range2.axisFill.zIndex = -1;

    var range3 = axis.axisRanges.create();
    range3.value = (maxScore / 3) * 2;
    range3.endValue = maxScore;
    range3.axisFill.fillOpacity = 1;
    range3.axisFill.fill = window.am4core.color("#88AB75");
    range3.axisFill.zIndex = -1;
    range3.axisFill.zIndex = -1;

    // Add hand
    var hand = chart.hands.push(new window.am4charts.ClockHand());
    hand.value = props.analyticsPropValue;
    hand.pin.disabled = true;
    hand.fill = window.am4core.color(fillColor);
    hand.stroke = window.am4core.color(stroke);
    hand.innerRadius = window.am4core.percent(20);
    hand.radius = window.am4core.percent(60);
    hand.startWidth = 10;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.analyticsPropValue, maxScore]);
  return <div id={props.id} style={{ width, height }}></div>;
};
export default AnalyticsChartMeter;
