import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const JobCardAdmin = (props) => {
  return (
    <div className="job__Admin-card position-relative h-100 tq-cards">
      {props.cOn ? (
        <span
          className="arrival__time d-flex align-items-center"
          title="Created On"
        >
          <i className="bx bx-time-five"></i>&nbsp;{props.cOn}
        </span>
      ) : (
        ""
      )}
      {/* ---- created by time ---- */}
      {props.status === "Awaiting Approval" ? (
        <div className="status yellow" title="Awaiting Approval Job">
          <i className="bx bx-alarm-exclamation"></i>
        </div>
      ) : props.jod === true && props.disabledResume !== true ? (
        <>
          <div className="status jod" title="Job of the Day">
            <i className="bx bxs-calendar-event"></i>
          </div>
          <div className="status green" title="Resume Upload Enabled">
            <i className="bx bxs-check-circle"></i>
          </div>
        </>
      ) : props.disabledResume === true ? (
        <div className="status red" title="Resume Upload Disabled">
          <i className="bx bxs-x-circle"></i>
        </div>
      ) : props.disabledResume === false ? (
        <div className="status green" title="Resume Upload Disabled">
          <i className="bx bxs-check-circle"></i>
        </div>
      ) : (
        <div className="status green" title="Resume Upload Enabled">
          <i className="bx bxs-check-circle"></i>
        </div>
      )}
      {/* ---- Icon area ---- */}

      {props.Title || props.req || props.JobType || props.City ? (
        <div className="content">
          {props.Title || props.Company ? (
            <div className="header__details pt-3 mb-3">
              <h3 className="title">{props.Title}</h3>
              {props.Company ? (
                <small className="company__status">
                  Company - <mark className="t-capital">{props.Company}</mark>
                </small>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {props.req ? (
            <span className="text-row">
              <strong>Req. Id</strong>
              <span>{props.req}</span>
            </span>
          ) : (
            ""
          )}
          {props.JobType ? (
            <span className="text-row">
              <strong>Job Type</strong>
              <span>{props.JobType}</span>
            </span>
          ) : (
            ""
          )}
          {props.City ? (
            <span className="text-row">
              <strong>City</strong>
              <span>{props.City}</span>
            </span>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* ---- Content area ---- */}

      {props.reject || props.edit || props.approve ? (
        <div className="actions text-center">
          <ButtonGroup>
            <Button variant="danger" onClick={props.reject}>
              Reject
            </Button>
            <Button variant="primary" onClick={props.edit}>
              Edit
            </Button>
            <Button
              variant="success"
              onClick={props.approve}
              title="Please check the payment term and vendor rate added to the job before approval"
            >
              Approve
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        ""
      )}

      {/* ---- Accept Reject Action Buttons Ends here ---- */}

      {props.enableResume ||
      props.disableResume ||
      props.unsetJod ||
      props.setJod ? (
        <div className="actions text-center">
          <ButtonGroup>
            {props.disabledResume === true ? (
              <Button
                variant="success"
                title="Enable Resume Upload On This Job"
                onClick={props.enableResume}
              >
                Enable Resume Upload
              </Button>
            ) : (
              <Button
                variant="danger"
                title="Disable Resume Upload On This Job"
                onClick={props.disableResume}
              >
                Disable Resume Upload
              </Button>
            )}
            {props.jod === true ? (
              <Button
                variant="warning"
                title="Remove From Job Of the Day List"
                onClick={props.unsetJod}
              >
                UnSet JOD
              </Button>
            ) : (
              <Button
                variant="info"
                title="Add to Job of the Day List"
                onClick={props.setJod}
              >
                Set JOD
              </Button>
            )}
          </ButtonGroup>
        </div>
      ) : (
        ""
      )}

      {/* ---- All Jobs Action Ends Here ---- */}
    </div>
  );
};
export default JobCardAdmin;
