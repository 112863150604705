import React, { useState, useEffect } from "react";
import BrowseCandidate from "./BrowseCandidates";
import "./jobdetails.css";
import UploadResumes from "./UploadResumes";
import JobDetails from "./JobDetails";
import PropTypes from "prop-types";
import queryString from "query-string";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { dEndPoints } from "../../config/end-point";
import axios from "axios";

//context api
import { useGlobalContext } from "../../context/appContext";

import GoBackButton from "../badge/GoBackButton";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showLoader,
  showSnackBar,
  setjob_candidate,
} from "../../actions/index";
import AssociateJobVendor from "./AssociateJobVendor";
const DashboardRequirement = (props) => {
  const [job, setJob] = useState({});
  const [resumeCount, setResumeCount] = useState({});
  const [candidateInfo, setCandidateInfo] = useState([]);
  const [candidateRecommendInfo, setCandidateRecommendInfo] = useState([]);
  const [facets, setFacets] = useState([]);
  const [enrollJob, setEnrollJob] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [recomCandidateId, setrecomCandidateId] = useState("");
  //const [candidateRecommId, setcandidateRecommId] = useState("");

  const [vendorUserList, setVendorUserList] = useState([]);
  const [newMsgForAnyVendor, setNewMsgForAnyVendor] = useState(false);
  const [canCount, setCanCount] = useState(0);
  const [recommendCount, setRecommendCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalvendorCount, setTotalVendorCount] = useState(0);

  //const history = useHistory();
  const [newMsg, setNewMsg] = useState(false);
  // const [state, setState] = React.useState({
  //   checkedGrid: false,
  // });

  let { candidateAnalytics, setCandidateAnalytics } = useGlobalContext(); // context AP

  var corpJobId = props.match.params.jobId;
  let candidate = queryString.parse(props.location.search);
  let candID = candidate.candId;
  const vendorCorporateId = localStorage.getItem("vci");
  const userType = localStorage.getItem("ut");
  useEffect(() => {
    loadJobAndCandidateDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corpJobId]);
  useEffect(() => {
    if (userType === "1000") {
      // loadVendorJobDetails();
      const enrollStatus = queryString.parse(props.location.search);
      setEnrollJob(enrollStatus.enroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (candID) {
      loadJobAndCandidateDetails();
      //loadCandidatesRecommendedDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candID]);
  // const handleBack = () => {
  //   history.push("/alljobs");
  // };
  const loadJobAndCandidateDetails = () => {
    if (userType === "1110" || userType === "1100") {
      loadCorporateJobDetails();
      getvendorList();

      loadCandidatesRecommendedDetails();
    }
    loadCandidatesDetails();
    getAnalyticsData();
    if (userType === "1000") {
      loadVendorJobDetails();
      loadCandidatesRecommendedDetails();
      vendorCandidatesDetails();
    }
  };

  const loadCorporateJobDetails = () => {
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData.ci;
    var jobId = props.match.params.jobId;

    props.showLoader({ show: true });
    axios
      .get(dEndPoints.corporateJobDetails(corporateId, jobId))
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        if (data && data.code === 100000) {
          if (data.data && data.data.jobData) {
            setJob(data.data.jobData);
            setResumeCount(data.data.jobData.rCt);
          }
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
      });
  };
  var loadCandidatesDetails;

  if (userType === "1100" || userType === "1110") {
    loadCandidatesDetails = (
      status,
      location,
      cName,
      score,
      interviewStatus,
      clear,
      pageNum = 0,
      isStatusChanged
    ) => {
      //const vendorCorporateId = localStorage.getItem("vci");
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      var jobId = props.match.params.jobId;
      props.showLoader({ show: true });

      if (clear || status || cName || location || score) {
        props.history.push(props.location.pathname);
        candID = null;
      }
      const params = {};
      if (status) {
        params.status = status; //&& status.map((stat) => stat.value).toString();
      }
      if (location && location.length) {
        params.location = location; //&& location.map((stat) => stat.value).toString();
      }
      if (cName && cName.length) {
        params.companyName = cName; //&& cName.map((stat) => stat.value).toString();
      }
      if (score && score.length) {
        params.score = score; //&& score.map((canScore) => canScore.value).toString();
      }
      if (interviewStatus && interviewStatus.length) {
        params.interviewStatus = interviewStatus;
        //interviewStatus.map((intStatus) => intStatus.value).toString();
      }
      axios
        .get(dEndPoints.corporateCandidatesDetails(corporateId, jobId), {
          params: {
            getFacets: true,
            pageNumber: pageNum,
            ...params,
            candidateId: candID,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;

          if (data && data.code === 100000) {
            if (data.data && data.data.candidates.candidates) {
              setCandidateInfo(data.data.candidates.candidates);
              setCanCount(Math.ceil(data.data.candidates.total / 20));
              setTotalVendorCount(data.data.candidates.total);
              setFacets(data.data.facets);
              if (!isStatusChanged) {
                const firstCandidateId = data.data.candidates.candidates[0]._id;
                setCandidateId(firstCandidateId);
              }
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          //console.log(error);
        });
    };
  } else {
    loadCandidatesDetails = (
      status,
      location,
      cName,
      score,
      interviewStatus,
      users,
      clear,
      pageNum = 0,
      isStatusChanged
    ) => {
      if (clear || status || cName || location || score || users) {
        props.history.push(props.location.pathname);
        candID = null;
      }

      const params = {};
      if (status && status.length) {
        params.status = status; //&& status.map((stat) => stat.value).toString();
      }
      if (location && location.length) {
        params.location = location; //&& location.map((stat) => stat.value).toString();
      }
      if (cName && cName.length) {
        params.companyName = cName; //&& cName.map((stat) => stat.value).toString();
      }
      if (score && score.length) {
        params.score = score; //&& score.map((canScore) => canScore.value).toString();
      }
      if (interviewStatus && interviewStatus.length) {
        params.interviewStatus = interviewStatus;
        //interviewStatus.map((intStatus) => intStatus.value).toString();
      }
      if (users && users.length) {
        params.users = users; //&& users.map((user) => user.value).toString();
      }

      axios
        .get(dEndPoints.vendorCandidatesDetails(vendorCorporateId, jobId), {
          params: {
            getFacets: true,
            pageNumber: 0,
            ...params,
            candidateId: candID,
            /*status: status && status.map((stat) => stat.value).toString(),
            location: location && location.map((stat) => stat.value).toString(),
            companyName: cName && cName.map((stat) => stat.value).toString(),
            score: score && score.map((canScore) => canScore.value).toString(),
            interviewStatus:
              interviewStatus &&
              interviewStatus.map((intStatus) => intStatus.value).toString(),
            users: users && users.map((user) => user.value).toString(), 
            candidateId: candID, */
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          if (data && data.code === 100000) {
            if (data.data && data.data.candidates.candidates) {
              setCandidateInfo(data.data.candidates.candidates);
              setFacets(data.data.facets);
              setCanCount(Math.ceil(data.data.candidates.total / 20));
              if (!isStatusChanged) {
                const firstCandidateId = data.data.candidates.candidates[0]._id;

                setCandidateId(firstCandidateId);
              }
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("errorr in job details while loading data", error);
        });
    };
  }
  const showUploadResume = () => {
    setEnrollJob("true");
  };
  // chat section for vendorListing
  const getvendorList = () => {
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData.ci;
    var jobId = props.match.params.jobId;
    props.showLoader({ show: true });
    axios
      .get(dEndPoints.vendorChatList(corporateId, jobId))
      .then((res) => {
        props.showLoader({ show: false });
        let data = res.data;

        if (data && data.code === 100000) {
          setVendorUserList(data.data.vendors);
          setNewMsgForAnyVendor(data.data.isNewMsgForAnyVendor);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const loadCandidatesRecommendedDetails = () => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      var jobId = props.match.params.jobId;
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.getCandidateRecommendation(corporateId, jobId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100134) {
            setCandidateRecommendInfo(data.data);
            setTotalCount(data.data.length);
            setRecommendCount(Math.ceil(data.data.length / 20));
            const firstCandidateId = data.data[0].candId;

            setrecomCandidateId(firstCandidateId);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      //const corporateId = cacheData.ci;
      var jobId = props.match.params.jobId;
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorCandidateRecommendation(vendorCorporateId, jobId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100134) {
            setCandidateRecommendInfo(data.data);
            setTotalCount(data.data.length);
            setRecommendCount(Math.ceil(data.data.length / 20));
            const firstCandidateId = data.data[0].candId;
            setrecomCandidateId(firstCandidateId);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  // const unSetNewMsg = (vendorId) => {
  //   const cloneVendorUserList = [...vendorUserList];
  //   let vendorList = cloneVendorUserList.map((vendor) => {
  //     if (vendor.vendorId === vendorId) {
  //       return (vendor.isMsgNew = false);
  //     }
  //     setVendorUserList(vendorList);
  //   });
  // };
  const handlecandidateID = (cId) => {
    setCandidateId(cId);
    setrecomCandidateId(cId);
    //setcandidateRecommId(cId);
  };
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const getAnalyticsData = () => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      var jobId = props.match.params.jobId;
      axios
        .get(dEndPoints.candidateAnalytics(corporateId, jobId))
        .then((res) => {
          let data = res.data;
          if (data.code === 100129) {
            setCandidateAnalytics(data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      //const corporateId = cacheData.ci;
      var jobId = props.match.params.jobId;
      axios
        .get(dEndPoints.vendorCandidateAnalytics(vendorCorporateId, jobId))
        .then((res) => {
          let data = res.data;
          if (data.code === 100129) {
            loadCandidatesDetails();
            setCandidateAnalytics(data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const vendorCandidatesDetails = () => {
    props.showLoader({ show: true });
    axios
      .get(dEndPoints.vendorCandidatesDetails(vendorCorporateId, corpJobId))
      .then((res) => {
        props.showLoader({ show: false });
        setCandidateInfo(res.data.data.candidates.candidates);
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
      });
  };
  const loadVendorJobDetails = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    //const vendorCorporateId = cacheData ? cacheData.vci : "";
    var jobId = props.match.params.jobId;
    props.showLoader({ show: true });
    axios
      .get(dEndPoints.vendorJobDetails(vendorCorporateId, jobId), {
        params: {
          getFacets: true,
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        if (data && data.code === 100000) {
          setNewMsg(data.data.isNewMsg);
          if (data.data && data.data.jobData) {
            setJob(data.data.jobData);
          }
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("errorr in job details while loading data", error);
      });
  };

  const activeCandidateAnalytics =
    candidateAnalytics.find((data) => data._id === candidateId) || {};
  var jobId = props.match.params.jobId;

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <div className="Job__detail-ContainerPage mb-5">
            {/* <span onClick={handleBack}>
        <i className="bx bx-arrow-back"></i> Back
      </span> */}
            <GoBackButton />
            {userType === "1110" || userType === "1100" ? (
              <>
                <JobDetails jobdetails={job ? job : ""} jobId={jobId} />
                <div className="px-3">
                  {/* <InterviewDetails
                    jobId={jobId}
                    loadCandidatesDetails={loadCandidatesDetails}
                  /> */}
                  <BrowseCandidate
                    childfacets={facets}
                    activeCandidateId={candidateId}
                    activeRecomCandidateId={recomCandidateId}
                    handlecandidateID={handlecandidateID}
                    loadCandidatesDetails={loadCandidatesDetails}
                    canCount={canCount}
                    candidates={candidateInfo ? candidateInfo : null}
                    activeCandidateAnalytics={
                      activeCandidateAnalytics ? activeCandidateAnalytics : {}
                    }
                    Recommcandidates={
                      candidateRecommendInfo ? candidateRecommendInfo : null
                    }
                    loadCandidatesRecommendedDetails={
                      loadCandidatesRecommendedDetails
                    }
                    job={job ? job : {}}
                  />
                </div>
              </>
            ) : (
              <>
                {enrollJob === "true" ? (
                  <>
                    <JobDetails jobdetails={job ? job : ""} jobId={jobId} />
                    <UploadResumes
                      job={job ? job : {}}
                      loadCandidatesDetails={loadCandidatesDetails}
                    />
                    <BrowseCandidate
                      childfacets={facets}
                      activeCandidateId={candidateId}
                      activeRecomCandidateId={recomCandidateId}
                      handlecandidateID={handlecandidateID}
                      loadCandidatesDetails={loadCandidatesDetails}
                      recommendCount={recommendCount}
                      candidates={candidateInfo ? candidateInfo : null}
                      activeCandidateAnalytics={
                        activeCandidateAnalytics ? activeCandidateAnalytics : {}
                      }
                      Recommcandidates={
                        candidateRecommendInfo ? candidateRecommendInfo : null
                      }
                      loadCandidatesRecommendedDetails={
                        loadCandidatesRecommendedDetails
                      }
                      job={job ? job : {}}
                    />
                  </>
                ) : (
                  <>
                    <AssociateJobVendor
                      jobdetails={job ? job : ""}
                      history={props.history}
                      showUploadResume={showUploadResume}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

DashboardRequirement.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    jobId: state.mainjobid,
    job_candidate: state.job,
  };
}
//export default DashboardRequirement;
function mapDispatchToProps(dispatch) {
  return {
    setjob_candidate: bindActionCreators(setjob_candidate, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardRequirement);
