import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";

const JobSetting = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <GoBackButton />
          <div className="job__setting-page tq__panel">
            <div className="tq__page-header mb-2">
              <h2 className="page-header">Job Setting</h2>
            </div>
            <Row className="tq-form">
              <Form.Group as={Col} xs={12} className="mt-5">
                <div className="form-check form-switch">
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckChecked"
                  >
                    Set Default Filter For Job Page
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    //   checked
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} xs={12} className="mt-5">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Set Preference</option>
                  <option value="1">Open</option>
                  <option value="2">Zero Resumes</option>
                  <option value="3">Enrolled</option>
                </select>
              </Form.Group>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default JobSetting;
