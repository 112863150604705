import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import GridItem from "../../common/mataerialKit/components/Grid/GridItem.jsx";
import GridContainer from "../../common/mataerialKit/components/Grid/GridContainer.jsx";

import errorPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/errorPageStyles.jsx";

const PageNotFound = (props) => {
  const { classes } = props;
  return (
    <div>
      {/* <Header /> */}
      <div style={{ width: "100%", height: "100vh", backgroundColor: "black" }}>
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>Page not found</h2>
              <h4 className={classes.description}>
                Oops! Looks like you got lost.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(errorPageStyle)(PageNotFound);
