import React, { useState } from "react";
function AgGridCheckbox(props) {
  const boolValue = props.value && props.value.toString() === "true";
  const [isChecked, setIsChecked] = useState(boolValue);
  const onChanged = () => {
    props.setValue(!isChecked);
    setIsChecked(!isChecked);
  };
  return (
    <div>
      <input type="checkbox" checked={isChecked} onChange={onChanged} />
    </div>
  );
}

export default AgGridCheckbox;
