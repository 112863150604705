import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// import moment from "moment";
import "./badge.css";
import { dEndPoints } from "../../config/end-point";
import { showSnackBar, showLoader } from "../../actions/index";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import CreatableSelect from "react-select/creatable";
import AddDuration from "./AddDuration";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ScheduledModal = (props) => {
  // const { classes } = props;
  const [show, setShow] = useState(false);
  const [roundType, setRoundType] = useState("");
  const [roundName, setRoundName] = useState("");
  const [roundMode, setRoundMode] = useState("");
  const [state, setState] = useState("start");
  const [duration, setDuration] = useState("00:00");
  const [inConfirm, setInConfirm] = useState(false);
  //const [customDuration, setCustomDuration] = useState("00:00");
  // const [maxDailyLimit, setMaxDailyLimit] = useState(null);
  // const [exclusionDays, setExclusionDays] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(
  //   new Date(Date.now() + 3600 * 1000 * 24)
  // );
  const [startTime, setStartTime] = useState(new Date(Date.now()));
  const [endTime, setEndTime] = useState(
    new Date(Date.now() + 3600 * 1000 * 1)
  );
  // const [openCustomDuration, setOpenCustomDuration] = useState(false);
  var iType = ["Plan", "Fixed"];
  var iName = ["Technical", "Manager", "HR", "Coding"];
  var iMode = ["Online", "Offline"];
  // var offDays = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];
  // const skillNew =
  //   offDays &&
  //   offDays.map((item) => {
  //     return { label: item, value: item };
  //   });
  // function to create interview round & slots
  const submitRound = () => {
    if (!roundType) {
      props.showSnackBar({
        message: "Round type cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (!roundName) {
      props.showSnackBar({
        message: "Round name cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (!roundMode) {
      props.showSnackBar({
        message: "Round mode cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (startTime === null) {
      props.showSnackBar({
        message: "Start time cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (startDate === null) {
      props.showSnackBar({
        message: "Start date cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (duration.split(":")[0] === "00" && duration.split(":")[1] === "00") {
      props.showSnackBar({
        message: "Duration cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const corporateId = localStorage.getItem("ci");
    const roundBody = {
      roundType: roundType,
      roundName: roundName,
      roundMode: roundMode,
      startDate: startDate,
      startTime: startTime,
      endTime: endTime,
      duration: duration,
      isScheduled: inConfirm,
    };

    //    var durationHour = roundBody.duration.split(":")[0] * 60;
    //  var durationMin = roundBody.duration.split(":")[1] * 1;
    // var totalDuration = durationHour + durationMin;
    props.showLoader({ show: true });
    axios
      .post(
        dEndPoints.instantBookSlot(
          corporateId,
          props.jobId,
          props.candidateProfileId
        ),
        roundBody
      )
      .then((res) => {
        props.showLoader({ show: false });
        props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
        var apiResponse = res.data;

        props.getInterviewRounds();
        setRoundMode("");
        setRoundName("");
        setRoundType("");
        setDuration("");
        if (props.setShow()) {
          props.setShow(false);
        }
        if (apiResponse.data.code === 100193) {
          props.showSnackBar({
            message:
              "Unable to create a new slot for this candidate because the same slot date and time is already scheduled",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (apiResponse.data.code === 100171) {
          setShow(false);

          props.showSnackBar({
            message: apiResponse.data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
        if (error.code === 100168) {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: error.response.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else if (error.response.data.code === 100172) {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };
  const OpenModal = (props) => {
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="roundType">
            {" "}
            Custom Duration (HH:MM format)
          </Form.Label>
          <Form.Control
            type="time"
            format="HH:mm"
            value={duration}
            defaultValue="04:20"
            onChange={(e) => setDuration(e.target.value)}
            minutesStep="5"
          />
        </Form.Group>
      </>
    );
  };

  // const handleChangeSkills = (value) => {
  //   var info = { ...exclusionDays };
  //   info = value ? value.map((el) => el.value) : [];
  //   setExclusionDays(info);
  // };
  const changeNoticeCheck = (e) => {
    setInConfirm(e.target.checked);
  };

  return (
    <>
      <div
        className="filter__btn flex__center border-0"
        onClick={() => setShow(true)}
      >
        <i className={props.icon}></i>
        {props.BtnName}
      </div>

      <Row className="text-left mb-4 tq-form">
        <Col md={4}>
          <div className="modal__fileds">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roundType">
                Round Type <small className="required__form">*</small>
              </Form.Label>
              <Form.Select
                id="roundType"
                value={roundType}
                onChange={(e) => setRoundType(e.target.value)}
              >
                <option value="0">Select Round</option>
                {iType.length > 0
                  ? iType.map((rType) => <option value={rType}>{rType}</option>)
                  : null}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
        <Col md={4}>
          <div className="modal__fileds">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roundType">
                Round Name <small className="required__form">*</small>
              </Form.Label>
              <Form.Select
                id="roundType"
                value={roundName}
                onChange={(e) => setRoundName(e.target.value)}
              >
                <option value="0">Select Round Name</option>
                {iName.length > 0
                  ? iName.map((rData) => <option value={rData}>{rData}</option>)
                  : null}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
        <Col md={4}>
          <div className="modal__fileds">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roundType">
                Round Mode<small className="required__form">*</small>
              </Form.Label>
              <Form.Select
                id="roundType"
                value={roundMode}
                onChange={(e) => setRoundMode(e.target.value)}
              >
                <option value="0">Select Round Mode</option>
                {iMode.length > 0
                  ? iMode.map((rModeData) => (
                      <option value={rModeData}>{rModeData}</option>
                    ))
                  : null}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
        <Col md={4}>
          <div className="modal__fileds">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roundType">
                Start Date<small className="required__form">*</small>
              </Form.Label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disablePast={true}
                  required="required"
                  className="txtField form-control p-0"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={startDate}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => setStartDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
          </div>
        </Col>
        <Col md={4}>
          <div className="modal__fileds">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roundType">
                Start Time (HH:MM format)
                <small className="required__form">*</small>
              </Form.Label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  required="required"
                  className="txtField form-control  p-0"
                  inputVariant="outlined"
                  mask="__:__ _M"
                  minutesStep={5}
                  InputAdornmentProps={{ position: "end" }}
                  value={startTime}
                  onChange={(date) => setStartTime(date)}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
          </div>
        </Col>

        <Col md={4}>
          <div className="modal__fileds">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="roundType">
                Duration <small className="required__form">*</small>
              </Form.Label>
              <Form.Select
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              >
                <option selected> Select Duration</option>
                <option value="00:15">15 Minutes</option>
                <option value="00:30">30 Minutes</option>
                <option value="00:45">45 Minutes</option>
                <option value="01:00">1 Hour</option>
                <option value="02:00">2 Hours</option>
                <option value="03:00">3 Hours</option>
                <option value="04:00">4 Hours</option>
                <option value="05:00">5 Hours</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
        <Col md={6} className="align-self-center">
          <div className="modal__fileds">
            {state === "start" && (
              <AddDuration addTrip={() => setState("add-trip")} />
            )}

            {state === "add-trip" && <OpenModal />}
          </div>
        </Col>
        <Col md={12} className="mt-5 text-center">
          <input
            className="form-check-input"
            type="checkbox"
            id="inNotice"
            name="isScheduled"
            checked={inConfirm}
            onClick={changeNoticeCheck}
          />
          <Form.Label htmlFor="inNotice">
            Is this round confirmed from candidate?
          </Form.Label>
          <small className="mt-1 d-block">
            <strong>Note :</strong> Our vendors will not contact candidate if
            you check the this option.
          </small>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="d-flex justify-content-center mt-5">
          <ButtonGroup>
            <Button variant="secondary" onClick={() => props.setShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={submitRound}>
              Schedule
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(ScheduledModal);
