import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "../../common/mataerialKit/components/Card/Card.jsx";
import CardBody from "../../common/mataerialKit/components/Card/CardBody.jsx";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { showSnackBar } from "../../actions/index";
import { showLoader } from "../../actions/index";
import { bindActionCreators } from "redux";
import axios from "axios";
import endPoints from "../../config/end-point";
import GridContainer from "../../common/mataerialKit/components/Grid/GridContainer.jsx";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffbb00;",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ffbb00",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#ffbb00",
      },
    },
  },
})(TextField);

const JobOfTheDay = (props) => {
  const [jod, setJod] = useState([]);

  const [jod1, setJod1] = useState("");
  const [comment1, setComment1] = useState("");
  const [order1, setOrder1] = useState(null);
  const [jod2, setJod2] = useState("");
  const [comment2, setComment2] = useState("");
  const [order2, setOrder2] = useState(null);
  const [jod3, setJod3] = useState("");
  const [comment3, setComment3] = useState("");
  const [order3, setOrder3] = useState(null);
  const [jod4, setJod4] = useState("");
  const [comment4, setComment4] = useState("");
  const [order4, setOrder4] = useState(null);
  const [jod5, setJod5] = useState("");
  const [comment5, setComment5] = useState("");
  const [order5, setOrder5] = useState(null);
  const [jod6, setJod6] = useState("");
  const [comment6, setComment6] = useState("");
  const [order6, setOrder6] = useState(null);
  const [jod7, setJod7] = useState("");
  const [comment7, setComment7] = useState("");
  const [order7, setOrder7] = useState(null);
  const [jod8, setJod8] = useState("");
  const [comment8, setComment8] = useState("");
  const [order8, setOrder8] = useState(null);
  const [jod9, setJod9] = useState("");
  const [comment9, setComment9] = useState("");
  const [order9, setOrder9] = useState(null);
  const [jod10, setJod10] = useState("");
  const [comment10, setComment10] = useState("");
  const [order10, setOrder10] = useState(null);

  useEffect(() => {
    geJodData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const geJodData = () => {
    axios
      .patch(endPoints.jod, null, {
        params: {
          option: "",
          id: "",
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        setJod(data.data);
        for (var i = 0; i < data.data.length; i++) {
          if (data.data[0]) {
            setComment1(data.data[0].jodComment);
            setOrder1(data.data[0].jodOrder);
            setJod1(data.data[0]._id);
          }
          if (data.data[1]) {
            setComment2(data.data[1].jodComment);
            setOrder2(data.data[1].jodOrder);
            setJod2(data.data[1]._id);
          }
          if (data.data[2]) {
            setComment3(data.data[2].jodComment);
            setOrder3(data.data[2].jodOrder);
            setJod3(data.data[2]._id);
          }
          if (data.data[3]) {
            setComment4(data.data[3].jodComment);
            setOrder4(data.data[3].jodOrder);
            setJod4(data.data[3]._id);
          }
          if (data.data[4]) {
            setComment5(data.data[4].jodComment);
            setOrder5(data.data[4].jodOrder);
            setJod5(data.data[4]._id);
          }
          if (data.data[5]) {
            setComment6(data.data[5].jodComment);
            setOrder6(data.data[5].jodOrder);
            setJod6(data.data[5]._id);
          }
          if (data.data[6]) {
            setComment7(data.data[6].jodComment);
            setOrder7(data.data[6].jodOrder);
            setJod7(data.data[6]._id);
          }
          if (data.data[7]) {
            setComment8(data.data[7].jodComment);
            setOrder8(data.data[7].jodOrder);
            setJod8(data.data[7]._id);
          }
          if (data.data[8]) {
            setComment9(data.data[8].jodComment);
            setOrder9(data.data[8].jodOrder);
            setJod9(data.data[8]._id);
          }
          if (data.data[9]) {
            setComment10(data.data[9].jodComment);
            setOrder10(data.data[9].jodOrder);
            setJod10(data.data[9]._id);
          }
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log(error);
      });
  };
  const pagereload = () => {
    window.location.reload();
  };

  const submitJod = (comment, order, jobId) => {
    if (order === null || order === undefined) {
      props.showSnackBar({
        message: "Please set priorities for all the jobs",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    let data = {
      jodComment: comment,
      jodOrder: order,
    };
    let jodId = jobId;
    axios
      .patch(endPoints.jod, data, {
        params: {
          option: "save",
          id: jodId,
        },
      })
      .then((res) => {
        var data = res.data;

        if (data && data.data.code === 100161) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });

          setTimeout(geJodData, 1000);
          setTimeout(pagereload, 1000);
          return;
        } else if (data && data.data.code === 100165) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else if (data && data.data.code === 100166) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        // transaction failed error
        if (error.response && error.response.data.code === 100163) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
        }
        console.log(error, "error");
      });
  };

  // save jod function
  const saveJod = (comment, order, id) => {
    let data = {
      jodComment: comment,
      jodOrder: order,
    };
    let jodId = id;
    axios
      .patch(endPoints.jod, data, {
        params: {
          option: "save",
          id: jodId,
        },
      })
      .then((res) => {
        // reload the saved jobs
        geJodData();
      })
      .catch((error) => {
        // transaction failed error
        if (error.response && error.response.data.code === 100163) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
        }
        console.log(error, "error");
      });
  };
  const sendJod = () => {
    if (
      (jod1 !== "" &&
        (order1 <= 0 ||
          order1 === null ||
          order1 === undefined ||
          order1 > 10)) ||
      (jod2 !== "" &&
        (order2 <= 0 ||
          order2 === null ||
          order2 === undefined ||
          order2 > 10)) ||
      (jod3 !== "" &&
        (order3 <= 0 ||
          order3 === null ||
          order3 === undefined ||
          order3 > 10)) ||
      (jod4 !== "" &&
        (order4 <= 0 ||
          order4 === null ||
          order4 === undefined ||
          order4 > 10)) ||
      (jod5 !== "" &&
        (order5 <= 0 ||
          order5 === null ||
          order5 === undefined ||
          order5 > 10)) ||
      (jod6 !== "" &&
        (order6 <= 0 ||
          order6 === null ||
          order6 === undefined ||
          order6 > 10)) ||
      (jod7 !== "" &&
        (order7 <= 0 ||
          order7 === null ||
          order7 === undefined ||
          order7 > 10)) ||
      (jod8 !== "" &&
        (order8 <= 0 ||
          order8 === null ||
          order8 === undefined ||
          order8 > 10)) ||
      (jod9 !== "" &&
        (order9 <= 0 ||
          order9 === null ||
          order9 === undefined ||
          order9 > 10)) ||
      (jod10 !== "" &&
        (order10 <= 0 ||
          order10 === null ||
          order10 === undefined ||
          order10 > 10))
    ) {
      props.showSnackBar({
        message:
          "Priorities cannot be blank, negative, zero or duplicate or set it one by one. And range should be 1 to 10",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    // save all job of the day comment & order
    else {
      if (jod1) {
        setTimeout(() => {
          saveJod(comment1, order1, jod1);
        }, 1000);
      }
      if (jod2) {
        setTimeout(() => {
          saveJod(comment2, order2, jod2);
        }, 1000);
      }
      if (jod3) {
        setTimeout(() => {
          saveJod(comment3, order3, jod3);
        }, 1000);
      }
      if (jod4) {
        setTimeout(() => {
          saveJod(comment4, order4, jod4);
        }, 1000);
      }
      if (jod5) {
        setTimeout(() => {
          saveJod(comment5, order5, jod5);
        }, 1000);
      }
      if (jod6) {
        setTimeout(() => {
          saveJod(comment6, order6, jod6);
        }, 1000);
      }
      if (jod7) {
        setTimeout(() => {
          saveJod(comment7, order7, jod7);
        }, 1000);
      }
      if (jod8) {
        setTimeout(() => {
          saveJod(comment8, order8, jod8);
        }, 1000);
      }
      if (jod9) {
        setTimeout(() => {
          saveJod(comment9, order9, jod9);
        }, 1000);
      }
      if (jod10) {
        setTimeout(() => {
          saveJod(comment10, order10, jod10);
        }, 1000);
      }
    }

    props.showLoader({ show: true });
    // after saving all jobs send emails
    setTimeout(sendMails, 1500);
  };
  const sendMails = () => {
    // send emails to all the vendors
    axios
      .patch(endPoints.jod, null, {
        params: {
          option: "send",
          id: "",
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        if (data && data.data.code === 100162) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          geJodData();
          return;
        } else if (data && data.data.code === 100164) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        // bad request error
        if (error.response && error.response.data.code === 100145) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
        }
        console.log(error, "error");
        props.showLoader({ show: false });
      });
  };

  return (
    <div className="jodPageUser">
      {jod.length > 0
        ? jod.map((data, index) => (
            <Card
              style={{ boxShadow: "unset", backgroundColor: "" }}
              key={index}
            >
              <CardBody>
                <form>
                  <GridContainer spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        component="span"
                        variant="body1"
                        color="textSecondary"
                        display="inline"
                      >
                        Job title:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        display="inline"
                      >
                        {data.reqId} - Role ({data.cName})
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography
                        component="span"
                        variant="body1"
                        color="textSecondary"
                        display="inline"
                      >
                        Positions:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        display="inline"
                      >
                        {data.totpos}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="inline"
                      >
                        Budget:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        display="inline"
                      >
                        {data.minBudget} - {data.maxBudget} Lpa
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="inline"
                      >
                        Primary skills:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        display="inline"
                      >
                        {data.pSkills}
                      </Typography>
                    </Grid>
                  </GridContainer>
                </form>
                {jod1 === data._id ? (
                  <GridContainer spacing={2} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        placeholder="Comment"
                        id="comment1"
                        className="txtField"
                        value={comment1}
                        onChange={(e) => setComment1(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        className="txtField"
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        variant="outlined"
                        placeholder="Priority"
                        id="order1"
                        value={order1}
                        onChange={(e) => setOrder1(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment1, order1, jod1)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod2 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        className="txtField"
                        variant="outlined"
                        placeholder="Comment"
                        id="comment2"
                        value={comment2}
                        onChange={(e) => setComment2(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        className="txtField"
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        variant="outlined"
                        placeholder="Priority"
                        id="order2"
                        value={order2}
                        onChange={(e) => setOrder2(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment2, order2, jod2)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod3 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        className="txtField"
                        label="Comment"
                        variant="outlined"
                        placeholder="Comment"
                        id="comment3"
                        value={comment3}
                        onChange={(e) => setComment3(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        className="txtField"
                        variant="outlined"
                        placeholder="Priority"
                        id="order3"
                        value={order3}
                        onChange={(e) => setOrder3(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "1px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment3, order3, jod3)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod4 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        className="txtField"
                        placeholder="Comment"
                        id="comment4"
                        value={comment4}
                        onChange={(e) => setComment4(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        variant="outlined"
                        className="txtField"
                        placeholder="Priority"
                        id="order4"
                        value={order4}
                        onChange={(e) => setOrder4(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment4, order4, jod4)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod5 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        className="txtField"
                        placeholder="Comment"
                        id="comment5"
                        value={comment5}
                        onChange={(e) => setComment5(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        className="txtField"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        variant="outlined"
                        placeholder="Priority"
                        id="order5"
                        value={order5}
                        onChange={(e) => setOrder5(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment5, order5, jod5)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod6 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        placeholder="Comment"
                        className="txtField"
                        id="comment6"
                        value={comment6}
                        onChange={(e) => setComment6(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        className="txtField"
                        variant="outlined"
                        placeholder="Priority"
                        id="order6"
                        value={order6}
                        onChange={(e) => setOrder6(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment6, order6, jod6)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod7 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        className="txtField"
                        variant="outlined"
                        placeholder="Comment"
                        id="comment7"
                        value={comment7}
                        onChange={(e) => setComment7(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        className="txtField"
                        variant="outlined"
                        placeholder="Priority"
                        id="order7"
                        value={order7}
                        onChange={(e) => setOrder7(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment7, order7, jod7)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod8 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        className="txtField"
                        placeholder="Comment"
                        id="comment8"
                        value={comment8}
                        onChange={(e) => setComment8(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        className="txtField"
                        variant="outlined"
                        placeholder="Priority"
                        id="order8"
                        value={order8}
                        onChange={(e) => setOrder8(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment8, order8, jod8)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod9 === data._id ? (
                  <GridContainer spacing={3} className="my-3">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        className="txtField"
                        placeholder="Comment"
                        id="comment9"
                        value={comment9}
                        onChange={(e) => setComment9(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        className="txtField"
                        variant="outlined"
                        placeholder="Priority"
                        id="order9"
                        value={order9}
                        onChange={(e) => setOrder9(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment9, order9, jod9)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : jod10 === data._id ? (
                  <GridContainer spacing={3} className="my-3seprator">
                    <Grid item xs={12} sm={9}>
                      <CssTextField
                        fullWidth
                        type="text"
                        label="Comment"
                        variant="outlined"
                        className="txtField"
                        placeholder="Comment"
                        id="comment10"
                        value={comment10}
                        onChange={(e) => setComment10(e.target.value)}
                        aria-labelledby="Comment"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CssTextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 1, max: 10 }}
                        label="Priority"
                        className="txtField"
                        variant="outlined"
                        placeholder="Priority"
                        id="order10"
                        value={order10}
                        onChange={(e) => setOrder10(e.target.value)}
                        aria-labelledby="Priority"
                      />
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      style={{ marginBottom: "5px", marginTop: "18px" }}
                    >
                      <Button
                        variant="contained"
                        value="save"
                        onClick={(e) => submitJod(comment10, order10, jod10)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          ))
        : // "Loading jobs ..."
          props.showLoader({ show: true })}
      <AppBar position="static" className="sendJobFloat">
        <div className="text-center bg-light">
          <Button
            style={{ margin: "5px 0" }}
            variant="contained"
            onClick={sendJod}
          >
            Save & Send emails
          </Button>
        </div>
      </AppBar>
    </div>
  );
};
JobOfTheDay.propTypes = {
  classes: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(JobOfTheDay));
