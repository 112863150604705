import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import endPoints, { dEndPoints } from "../../config/end-point";
import axios from "axios";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import skillSet from "../../utils/skills";
import collegeSet from "../../utils/college";

import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";
import PropTypes from "prop-types";

const EditOtherCandidate = (props) => {
  const [canInfo, setCanInfo] = useState({
    name: "",
    email: "",
    contact: "",
    college: "",
    city: "",
    cDesig: "",
    expr: "",
    ctc: "",
    ectc: "",
    cName: "",
    skills: [],
    qual: "",
    nPeriod: null,
    // lastworkday: null,
    comment: "",
  });
  const [selectedValue, setSelectedValue] = useState([]);
  const [skills, setSkills] = useState([]);
  const [users, setUsers] = useState(props.candidateOne);
  const [open, setOpen] = useState(false);
  const [locations, setLocation] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [collegeLookup, SetcollegeLookup] = useState([]);
  const [companyLookup, SetcompanyLookup] = useState([]);

  // const handleChange = (value) => {
  //   const info = { ...canInfo };
  //   setSelectedValue(value);
  //   //;

  //   info.city = value.name;
  //   setCanInfo(info);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  useEffect(() => {
    // setTimeout(() => {
    loadDatabaseDetails();
    //}, 500);
    setUsers(props.candidateOne);
    loadOptions();
    loadDesignationOptions();
    //  loadCollegeOptions();
    loadCompanyOptions();
    loadQualOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  // var setValue;
  // const handleInput = (value) => {
  //   setValue(value);
  // };

  const loadDatabaseDetails = () => {
    const selectInput = {
      metaData: [
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "SKILL",
        },
      ],
    };
    props.showLoader({ show: true });
    axios({
      url: endPoints.lookup,
      data: selectInput,
      method: "POST",
    }).then((res) => {
      props.showLoader({ show: false });
      const data = res.data;

      if (data && data.code === 100000) {
        const skill = data && data.data && data.data.SKILL;
        setSkills(skill);
      }
    });
  };
  const skill =
    skills &&
    skills.map((item) => {
      return { label: item.name, value: item.name };
    });
  const skillss =
    skillSet &&
    skillSet.map((item) => {
      return { label: item.name, value: item.name };
    });
  var pror = ["label", "value"];
  var skillNew =
    skill &&
    skill
      .filter(function (o1) {
        return skillss.some(function (o2) {
          return o1.label === o2.label; // assumes unique id
        });
      })
      .map(function (o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        return pror.reduce(function (newo, value) {
          newo[value] = o[value];
          return newo;
        }, {});
      });
  const loadOptions = () => {
    axios.get(endPoints.getLocationLookup + "?location=").then((res) => {
      const data = res.data;
      setLocation(data);
    });
  };
  const loadCompanyOptions = () => {
    axios.get(endPoints.getCompanyLookup + "?company=ACC").then((res) => {
      const data = res.data;

      SetcompanyLookup(data);
    });
  };
  const loadDesignationOptions = () => {
    axios
      .get(endPoints.getDesignationLookup + "?desig=software")
      .then((res) => {
        const data = res.data;

        setDesignation(data);
      });
  };

  const loadQualOptions = () => {
    axios.get(endPoints.getQualificationLookup + "?qual=").then((res) => {
      const data = res.data;
      SetcollegeLookup(data);
    });
  };

  const QualificationLookup = collegeLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CompanyLookup = companyLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CollegeLookup = collegeSet.map((item) => {
    return { label: item.name, value: item.name };
  });
  const locationLookup = locations.map((item) => {
    return { label: item.name, value: item.name };
  });

  const designationLookup = designation.map((item) => {
    return { label: item.name, value: item.name };
  });

  const handleChangeQuallookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...users };
    info.qual = newQual ? newQual.map((el) => el.value) : [];
    setUsers(info);
  };

  const handleChangeLocationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);

    var info = { ...users };
    info.city = newQual ? newQual.map((el) => el.value) : [];
    setUsers(info);
  };
  const handleChangeDesinationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...users };
    info.cDesig = newQual ? newQual.map((el) => el.value) : [];
    setUsers(info);
  };
  const handleChangeCompanylookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...users };
    info.cName = newQual ? newQual.map((el) => el.value) : [];
    setUsers(info);
  };
  const handleChangeCollegelookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...users };
    info.college = newQual ? newQual.map((el) => el.value) : "";

    setUsers(info);
  };
  const handleChangeNoticeP = (e) => {
    const info = { ...users };
    info.nPeriod = e.target.value;
    setUsers(info);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUsers({ ...users, [name]: value });
  };
  const handleChangeSkills = (value) => {
    var info = { ...users };
    info.skills = value ? value.map((el) => el.value) : [];
    setUsers(info);
  };
  const submitCanDetails = (e) => {
    e.preventDefault();
    const info = { ...users };
    if (users.name === "" || !users.name) {
      props.showSnackBar({
        message: "name is empty",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (users.contact === "" || !users.contact) {
      props.showSnackBar({
        message: " Contact No. is empty.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (users.contact.length < 10) {
      props.showSnackBar({
        message: "Contact number at least 10 digits",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (users.email === " " || !users.email) {
      props.showSnackBar({
        message: " Email is empty.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    setUsers({
      name: "",
      email: "",
      contact: "",
      college: "",
      city: "",
      cDesig: "",
      expr: "",
      ctc: "",
      ectc: "",
      cName: "",
      skills: "",
      qual: "",
      nPeriod: null,
      comment: "",
    });
    if (info._links) {
      delete info._links;
    }
    if (info._softSkills) {
      delete info._softSkills;
    }
    if (info.a_all_loc) {
      delete info.a_all_loc;
    }
    if (info.a_dates) {
      delete info.a_dates;
    }
    if (info.address) {
      delete info.address;
    }
    if (info.all_cName) {
      delete info.all_cName;
    }
    if (info.all_exp) {
      delete info.all_exp;
    }
    if (info.certific) {
      delete info.certific;
    }
    if (info.content) {
      delete info.content;
    }
    if (info.driveLsc) {
      delete info.driveLsc;
    }
    if (info.passport) {
      delete info.passport;
    }
    if (info.percentage) {
      delete info.percentage;
    }
    if (info.pinCode) {
      delete info.pinCode;
    }
    if (info.prm_sk) {
      delete info.prm_sk;
    }
    if (info.relFld) {
      delete info.relFld;
    }
    if (info.univ) {
      delete info.univ;
    }

    if (props.userId) {
      axios
        .patch(dEndPoints.otherCandidate(props.userId), info)
        .then((res) => {
          var data = res.data;

          if (data && data.code === 100000) {
            props.handleCloseSchedule();
            props.showSnackBar({
              message: "Resume uploaded successfully",
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            props.loadCandidates();
            return;
          }
        })
        .catch((error) => {
          console.log("error file", error);
          if (error.response.data.code === 100159) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else {
            props.showSnackBar({
              message: "Error while uploading resume",
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
          }
        });
    }
  };
  return (
    <>
      <Form>
        <Row className="text-left mb-4">
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Candidate Name <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder="Candidate Name"
              onChange={handleInputChange}
              value={users.name}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Contact No <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              name="contact"
              type="text"
              maxLength="12"
              placeholder="eg : 98668XXX00 "
              value={users.contact}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Email <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder="Email eg. example@domain.com"
              value={users.email}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>
        <Row className="text-left mb-4">
          <Form.Group as={Col} xs={4}>
            <Form.Label>Current Company</Form.Label>
            <CreatableSelect
              inputId="cName"
              TextFieldProps={{
                label: "cName",
                InputLabelProps: {
                  htmlFor: "cName",
                  shrink: true,
                },
              }}
              options={CompanyLookup}
              // components={components}
              value={
                users.cName
                  ? users.cName.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeCompanylookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>Designation</Form.Label>
            <CreatableSelect
              {...props}
              inputId="cDesig"
              TextFieldProps={{
                label: "cDesig",
                InputLabelProps: {
                  htmlFor: "cDesig",
                  shrink: true,
                },
              }}
              options={designationLookup}
              // components={components}
              value={
                users.cDesig
                  ? users.cDesig.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : []
              }
              onChange={handleChangeDesinationlookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>City</Form.Label>
            <CreatableSelect
              inputId="city"
              TextFieldProps={{
                label: "city",
                InputLabelProps: {
                  htmlFor: "city",
                  shrink: true,
                },
              }}
              options={locationLookup}
              // components={components}
              value={
                users.city
                  ? users.city.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : []
              }
              onChange={handleChangeLocationlookUp}
              required
            />
          </Form.Group>
        </Row>
        <Row className="text-left mb-4">
          <Form.Group as={Col} xs={4}>
            <Form.Label>Degree/Qualification</Form.Label>
            <CreatableSelect
              inputId="qual"
              TextFieldProps={{
                label: "qual",
                InputLabelProps: {
                  htmlFor: "qual",
                  shrink: true,
                },
              }}
              options={QualificationLookup}
              // components={components}
              value={
                users.qual
                  ? users.qual.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeQuallookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={5}>
            <Form.Label>College/University</Form.Label>
            <CreatableSelect
              inputId="college"
              TextFieldProps={{
                label: "college",
                InputLabelProps: {
                  htmlFor: "college",
                  shrink: true,
                },
              }}
              options={CollegeLookup}
              // components={components}
              value={
                users.college
                  ? users.college.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeCollegelookUp}
              required
            />
          </Form.Group>
        </Row>
        <Row className="text-left mb-4">
          <Form.Group as={Col} xs={3}>
            <Form.Label>
              CTC <small>(In LPA)</small>
            </Form.Label>
            <Form.Control
              name="ctc"
              type="number"
              placeholder="0"
              value={users.ctc}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={3}>
            <Form.Label>
              Expected CTC<small>(In LPA)</small>
            </Form.Label>
            <Form.Control
              name="ectc"
              type="number"
              placeholder="0"
              value={users.ectc}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={3}>
            <Form.Label>Experience</Form.Label>
            <Form.Control
              name="expr"
              type="number"
              placeholder="eg : 2 or 2.5 "
              value={users.expr}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={3}>
            <Form.Label>Notice Period</Form.Label>
            <select
              name="nPeriod"
              className="form-select"
              aria-label="noticePriod"
              multiple={false}
              value={users.nPeriod}
              onChange={handleChangeNoticeP}
            >
              <option value={7}>1 Week</option>
              <option value={14}>2 Weeks</option>
              <option value={21}>3 Weeks</option>
              <option value={30}>30 Days</option>
              <option value={45}>45 Days</option>
              <option value={60}>60 Days</option>
              <option value={90}>90 Days</option>
            </select>
          </Form.Group>
        </Row>
        <Row className="text-left mb-4">
          <Form.Group as={Col}>
            <Form.Label>Skills</Form.Label>
            <CreatableSelect
              inputId="skills"
              TextFieldProps={{
                label: "Skills",
                InputLabelProps: {
                  htmlFor: "skills",
                  shrink: true,
                },
              }}
              options={skillNew}
              value={
                users.skills
                  ? users.skills.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : []
              }
              onChange={handleChangeSkills}
              isMulti
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mt-4">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              name="comment"
              as="textarea"
              rows={3}
              placeholder="Comments"
              value={users.comment}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>
        {/* </Form> */}
        <ButtonGroup>
          <Button variant="secondary" onClick={props.handleCloseSchedule}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitCanDetails}>
            Submit
          </Button>
        </ButtonGroup>
      </Form>
    </>
  );
};
EditOtherCandidate.propTypes = {
  classes: PropTypes.object,
};
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(EditOtherCandidate);
