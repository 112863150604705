import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "../../material-kit-pro-react.jsx";

const navPillsStyle = theme => ({
  // root: {
  //   marginTop: "0px",
  //   paddingLeft: "0",
  //   marginBottom: "0",
  //   overflow: "visible !important"
  // },
  // flexContainer: {
  //   [theme.breakpoints.down("xs")]: {
  //     display: "flex",
  //     flexWrap: "wrap"
  //   }
  // },
  displayNone: {
    display: "none !important"
  },
  // fixed: {
  //   overflow: "visible !important"
  // },
  // horizontalDisplay: {
  //   display: "inline"
  // },
  pills: {
    //float: "left",
    position: "relative",
    display: "block",
    borderRadius: "3px",
    minWidth: "25%",
    textAlign: "center",
    transition: "all .2s",
    padding: "10px 7px",
    color: grayColor[0],
    height: "48px",
    opacity: "1",
    maxWidth: "100%",
    margin: "0 0 0 0",
    minHeight: "unset",
    // lineHeight: "1px",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "500"
  },
  // pillsWithIcons: {
  //   borderRadius: "2px"
  // },
  // tabIcon: {
  //   width: "10px",
  //   height: "10px",
  //   display: "block",
  //   margin: "5px 0 !important"
  // },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "0px 0"
    }
  },
  contentWrapper: {
    margin: "0px 0px",
    // "& .react-swipeable-view-container > div > div": {
    //   paddingLeft: "2px",
    //   paddingRight: "5px"
    // }
  },
  white: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.4)"
    }
  },
  info: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.4)",
        maxWidth: "none",
        width: "300px",
       
        
    }
  },
  specialinfo: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.4)",
        maxWidth: "none",
        width: "160px",
        height:"50px"
        
      
    }
  },
  success: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.4)"
    }
  },
  // alignCenter: {
  //   alignItems: "center",
  //   justifyContent: "center"
  // },
  // tabLabelContainer: {
  //   padding: "unset !important"
  // }
});

export default navPillsStyle;
