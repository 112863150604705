import React, { useState, useEffect } from "react";
import GoBackButton from "../../../components/badge/GoBackButton";
import { ToggleButtonGroup, ToggleButton, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNav from "../../topnav/TopNav";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SideNav from "../../sidenav/SideNav";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";
import endPoints, { dEndPoints } from "../../../config/end-point";
import { showSnackBar, showLoader } from "../../../actions/index";

const UserDetailsPage = (props) => {
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const [jobCount, setJobCount] = useState([]);
  const [userInfo, setUserInfo] = useState({
    contact: "",
    authorizedContactPerson: "",
    userName: "",
    address: "",
    pan: "",
    industryType: "",
    // address: "",
    description: "",
    companyName: "",
    gstIn: "",
    seoName: "",
    contractDetails: {
      startDate: null,
      endDate: null,
    },
  });
  const [showContract, setShowContract] = useState(false);
  const [showLegal, setshowLegal] = useState(false);
  const [showGenPass, setshowGenPass] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseContract = () => setShowContract(false);
  const handleCloseLegal = () => setshowLegal(false);
  const handleCloseGenPass = () => setshowGenPass(false);
  const handleGenPass = () => setshowGenPass(true);
  const handleEditProfile = () => setShow(true);
  const handleContract = () => setShowContract(true);
  const handleLegalDetails = () => setshowLegal(true);
  let { userId, userType } = useParams();
  // }

  useEffect(() => {
    if (userType === "Corporate") {
      loadCorporate();
    } else {
      loadVendor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadVendor = () => {
    props.showLoader({ show: true });
    // var sort_createdon = -1;
    // var pageNum = 1;
    axios
      .get(dEndPoints.getVendorUserAdmin(userId))
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;
        const resumes = data.data;
        loadVendorJobCounts(resumes.vendorCorporateId._id);
        // setVendorInfo(resumes);
        setUser(resumes);
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };
  const loadCorporate = () => {
    props.showLoader({ show: true });
    // var sort_createdon = -1;
    // var pageNum = 1;
    axios
      .get(dEndPoints.getCorporateUserAdmin(userId))
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;
        const resumes = data.data;

        loadJobCounts(resumes.corporateId._id);
        //setInfo(resumes);
        setUser(resumes);
      })
      .catch((error) => {
        props.showLoader({ show: false });
      });
  };

  const loadJobCounts = (corporateId) => {
    props.showLoader({ show: true });
    // var sort_createdon = -1;
    // var pageNum = 1;
    //   const corporateId = user.corporateId ? user.corporateId._id : "";
    if (userType === "Corporate") {
      axios
        .get(dEndPoints.getCorporateJobCount(corporateId))
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          setJobCount(data.data.defaultBucket);
          // console.log("data", data);
          // if (response.data.code === 100125) {
          // const resumes = data.data;
          // setVendorInfo(resumes);
          //setUser(resumes);
          //}
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while fetching data", error);
        });
    }
  };

  const loadVendorJobCounts = (vendorCorporateId) => {
    props.showLoader({ show: true });
    // var sort_createdon = -1;
    // var pageNum = 1;
    //   const corporateId = user.corporateId ? user.corporateId._id : "";
    if (userType === "Vendor") {
      axios
        .get(dEndPoints.getVendorJobCount(vendorCorporateId))
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          setJobCount(data.data.getJob);
          // console.log("data", data);
          // if (response.data.code === 100125) {
          // const resumes = data.data;
          // setVendorInfo(resumes);
          //setUser(resumes);
          //}
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while fetching data", error);
        });
    }
  };

  useEffect(() => {
    var info = { ...userInfo };

    if (user && user.contact) {
      info.contact = user.contact;
      setUserInfo(info);
    }
    if (user && user.userName) {
      info.userName = user.userName;
      setUserInfo(info);
    }
    if (userType === "Corporate") {
      if (user.corporateId && user.corporateId.authorizedContactPerson) {
        info.authorizedContactPerson = user.corporateId.authorizedContactPerson;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.pan) {
        info.pan = user.corporateId.pan;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.gstIn) {
        info.gstIn = user.corporateId.gstIn;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.address) {
        info.address = user.corporateId.address;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.seoName) {
        info.seoName = user.corporateId.seoName;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.description) {
        info.description = user.corporateId.description;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.contractDetails) {
        info.contractDetails = user.corporateId.contractDetails;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.companyName) {
        info.companyName = user.corporateId.companyName;
        setUserInfo(info);
      }
      if (user.corporateId && user.corporateId.industryType) {
        info.industryType = user.corporateId.industryType;
        setUserInfo(info);
      }
    } else {
      if (userType === "Vendor") {
        if (
          user.vendorCorporateId &&
          user.vendorCorporateId.authorizedContactPerson
        ) {
          info.authorizedContactPerson =
            user.vendorCorporateId.authorizedContactPerson;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.pan) {
          info.pan = user.vendorCorporateId.pan;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.gstIn) {
          info.gstIn = user.vendorCorporateId.gstIn;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.address) {
          info.address = user.vendorCorporateId.address;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.seoName) {
          info.seoName = user.vendorCorporateId.seoName;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.description) {
          info.description = user.vendorCorporateId.description;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.contractDetails) {
          info.contractDetails = user.vendorCorporateId.contractDetails;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.companyName) {
          info.companyName = user.vendorCorporateId.companyName;
          setUserInfo(info);
        }
        if (user.vendorCorporateId && user.vendorCorporateId.industryType) {
          info.industryType = user.vendorCorporateId.industryType;
          setUserInfo(info);
        }
      }
    }
  }, [user]);

  const updateUser = (e) => {
    e.preventDefault();

    if (userType === "Corporate") {
      axios
        .patch(dEndPoints.updateCorporate(userId), userInfo)
        .then((res) => {
          //  let data = res.data;
          handleClose();
          handleCloseContract();
          handleCloseLegal();
          props.showSnackBar({
            message: "User details updated Successfully!",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      axios
        .patch(dEndPoints.updateVendor(userId), userInfo)
        .then((res) => {
          let data = res.data;
          handleClose();
          handleCloseContract();
          handleCloseLegal();
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const generatePassword = () => {
    axios
      .patch(endPoints.generatePasswprd + "?userId=" + userId)
      .then((res) => {
        props.showLoader({ show: false });
        let data = res.data;
        setPassword(data.data.newPassword);
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };

  const handleInputChange = (e) => {
    var cInfo = { ...userInfo };
    cInfo[e.target.name] = e.target.value;
    setUserInfo(cInfo);
  };

  return (
    <Container fluid>
      <div className="tq-adminUserDetail-page pb-5">
        <Row>
          <Col md={2}>
            <SideNav {...props} />
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />
            <div className="user__detals-page">
              <div className="tq__page-header mb-5">
                <GoBackButton classNameMargin="mb-0" />
                {userInfo.companyName ? (
                  <h2 className="page-header t-capital text-pop">
                    Details of{" "}
                    <span className="text-diabled t-capital text-pop">
                      {userInfo.companyName}
                    </span>
                  </h2>
                ) : userInfo.companyName ? (
                  <h2 className="page-header t-capital text-pop">
                    Details of{" "}
                    <span className="text-diabled t-capital text-pop">
                      {userInfo.companyName}
                    </span>
                  </h2>
                ) : (
                  ""
                )}
                {user.status ? (
                  <ToggleButtonGroup
                    className="d-block"
                    type="radio"
                    name="isActive"
                    value={user.status}
                    defaultValue="Active"
                  >
                    <ToggleButton
                      variant="userGroup inactive"
                      id="tbg-false"
                      value="Inactive"
                    >
                      In-Active
                    </ToggleButton>
                    <ToggleButton
                      variant="userGroup"
                      id="tbg-true"
                      value="Active"
                    >
                      Active
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : user.status ? (
                  <ToggleButtonGroup
                    className="d-block"
                    type="radio"
                    name="isActive"
                    value={user.status}
                    defaultValue="Active"
                  >
                    <ToggleButton
                      variant="userGroup inactive"
                      id="tbg-false"
                      value="Inactive"
                    >
                      In-Active
                    </ToggleButton>
                    <ToggleButton
                      variant="userGroup"
                      id="tbg-true"
                      value="Active"
                    >
                      Active
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  ""
                )}
              </div>
              {/*---header ends -*/}

              <div className="page__boday-wrapper">
                <Row>
                  <Col sm={7}>
                    <div className="profile__card h-auto cr-auto">
                      <div className="card__header">
                        <div className="profile__image">
                          <img
                            src="https://i.ibb.co/RYcNVTK/Group-1171.png"
                            alt={userInfo.companyName || userInfo.companyName}
                            className="img-fluid"
                          />
                        </div>
                        <div className="header__details">
                          <h3 className="title text-pop">
                            {userInfo.companyName || userInfo.companyName}
                          </h3>
                          {user.industryType || user.industryType ? (
                            <span className="company__type">
                              {userInfo.industryType || userInfo.industryType}
                            </span>
                          ) : (
                            ""
                          )}
                          {user.officeAddress || user.officeAddress ? (
                            <span className="location d-flex align-items-center">
                              <i className="bx bxs-map"></i>&nbsp;
                              {userInfo.officeAddress || userInfo.officeAddress}
                            </span>
                          ) : (
                            ""
                          )}
                          <Button
                            variant="primary mt-3"
                            onClick={handleEditProfile}
                          >
                            Edit Profile{" "}
                          </Button>
                        </div>
                      </div>
                      <div className="card__body text-pop">
                        {userInfo.authorizedContactPerson ||
                        userInfo.authorizedContactPerson ? (
                          <span className="text-row">
                            <strong>Contact Person</strong>
                            <span className="text-pop t-capital">
                              {userInfo.authorizedContactPerson ||
                                userInfo.authorizedContactPerson}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        {userInfo.userName || userInfo.userName ? (
                          <span className="text-row">
                            <strong>Email</strong>
                            <span className="text-pop">
                              {userInfo.userName || userInfo.userName}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}

                        {userInfo.contact || userInfo.contact ? (
                          <span className="text-row">
                            <strong>Contact Number</strong>
                            <span className="text-pop">
                              {userInfo.contact || userInfo.contact}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}

                        {userInfo.industryType ? (
                          <span className="text-row">
                            <strong>Industry Type</strong>
                            <span className="text-pop">
                              {" "}
                              {userInfo.industryType}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        {userInfo.description || userInfo.description ? (
                          <span className="text-row">
                            <strong>Description</strong>
                            <span>
                              {userInfo.description || userInfo.description}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* ------ legal detail card ------ */}
                    <div className="legal__cards tq-cards mt-5">
                      <div className="card__heading text-row">
                        <h3 className="title">Legal Details</h3>
                        <span
                          className="edit__details"
                          onClick={handleLegalDetails}
                        >
                          <i className="bx bxs-edit-alt"></i>
                        </span>
                      </div>
                      {userInfo.pan || userInfo.pan ? (
                        <span className="text-row text-pop">
                          <strong>PAN</strong>
                          <span className="text-pop">
                            {" "}
                            {userInfo.pan || userInfo.pan}
                          </span>
                        </span>
                      ) : (
                        ""
                      )}

                      {userInfo.gstIn || userInfo.gstIn ? (
                        <span className="text-row text-pop">
                          <strong>GST</strong>
                          <span className="text-pop">
                            {" "}
                            {userInfo.gstIn || userInfo.gstIn}
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="text-row text-pop">
                        <strong>Other Details</strong>
                        <span className="text-pop">Lorem Dollor</span>
                      </span>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div className="contract__details tq-cards">
                      <div className="card__heading text-row">
                        <h3 className="title">Contract Details</h3>
                        <span
                          className="edit__details cr-pointer"
                          onClick={handleContract}
                        >
                          <i className="bx bxs-edit-alt"></i>
                        </span>
                      </div>
                      {userInfo.contractDetails.startDate &&
                      userInfo.contractDetails.endDate ? (
                        <div className="d-flex">
                          <div className="content__details">
                            <span className="d-block">
                              <small className="label">Start Date</small>
                              <p className="txt-color">
                                {userInfo.contractDetails &&
                                  new Date(
                                    userInfo.contractDetails.startDate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "short",
                                  })}
                              </p>
                            </span>
                            <span className="d-block mt-3">
                              <small className="label">End Date</small>
                              <p className="txt-color">
                                {" "}
                                {userInfo.contractDetails &&
                                  new Date(
                                    userInfo.contractDetails.endDate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "short",
                                  })}
                              </p>
                            </span>
                          </div>
                          <div className="donut_chart"></div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/*-------- Contract Details Ends Here ---------- */}
                    {jobCount &&
                      jobCount.map((item) => (
                        <Row>
                          <Col xs={6} className="mt-4">
                            <div className="status-card h-auto">
                              <div className="status-card__info">
                                <h3>
                                  {item.counts ? item.counts : item.jobCounts}
                                </h3>
                                <span className="mt-3 f-500">{item.title}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    {/* <Col xs={6} className="mt-4">
                        <div className="status-card green text-white h-auto">
                          <div className="status-card__info">
                            <h3>24</h3>
                            <span className="mt-3 f-700">Jobs In Progress</span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="mt-4">
                        <div className="status-card h-auto">
                          <div className="status-card__info">
                            <h3>24</h3>
                            <span className="mt-3 f-500">
                              Total Posted Jobs
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="mt-4">
                        <div className="status-card red text-white h-auto">
                          <div className="status-card__info">
                            <h3>24</h3>
                            <span className="mt-3 f-700">Rejected Jobs</span>
                          </div>
                        </div>
                      </Col>
                    </Row>  */}
                    <Row className="mt-4">
                      <Col xs={12}>
                        <div className="tq-cards">
                          <div className="card__heading d-block text-row">
                            <h3 className="title">Generate Password</h3>
                          </div>
                          {/* <span className="text-row text-pop mt-3">
                            <strong>Temp Login Pin</strong>
                            <span className="text-pop">KGYKI245</span>
                          </span> */}
                          <div className="text-row mt-4 justify-content-center">
                            <Button variant="info" onClick={handleGenPass}>
                              Generate Pass
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* --------Modal-----------*/}
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="tq__modal-default w-80"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="tq-form">
              <Form.Group xs={12} as={Col} className="mb-3">
                <Form.Label htmlFor="company">
                  Organization/Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Company Name"
                  value={userInfo.companyName}
                  onChange={handleInputChange}
                  name="companyName"
                />
              </Form.Group>
              <Form.Group xs={12} as={Col} className="mb-3">
                <Form.Label htmlFor="company">Location</Form.Label>
                <Form.Control
                  type="text"
                  value={userInfo.officeAddress}
                  placeholder="office Address"
                  onChange={handleInputChange}
                  name="officeAddress"
                />
              </Form.Group>
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="company">Contact Person</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="contact Person"
                  value={userInfo.authorizedContactPerson}
                  onChange={handleInputChange}
                  name="authorizedContactPerson"
                />
              </Form.Group>
              {userType === "Corporate" ? (
                <Form.Group xs={6} as={Col} className="mb-3">
                  <Form.Label htmlFor="company">Industry Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="industry Type"
                    value={userInfo.industryType}
                    onChange={handleInputChange}
                    name="industryType"
                  />
                </Form.Group>
              ) : (
                ""
              )}
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="company">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={userInfo.userName}
                  onChange={handleInputChange}
                  name="userName"
                />
              </Form.Group>
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="company">Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="contact"
                  value={userInfo.contact}
                  onChange={handleInputChange}
                  name="contact"
                />
              </Form.Group>
              <Form.Group xs={12} as={Col} className="mb-3">
                <Form.Label htmlFor="company">Other Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="description"
                  value={userInfo.description}
                  onChange={handleInputChange}
                  name="description"
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => updateUser(e)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------Modal Legal-----------*/}
      <Modal
        show={showContract}
        onHide={handleCloseContract}
        dialogClassName="tq__modal-default w-80"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Contract Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="tq-form">
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="dates">Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="date Name"
                  onChange={handleInputChange}
                  value={
                    userInfo.contractDetails &&
                    userInfo.contractDetails.startDate
                  }
                  name="contractDetails"
                />
              </Form.Group>
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="edate">End Date</Form.Label>
                <Form.Control type="date" placeholder="End Date" name="edate" />
              </Form.Group>
              <Form.Group xs={12} as={Col} className="mb-3">
                <Form.Label htmlFor="condes">Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Contract Description"
                  onChange={handleInputChange}
                  value={
                    userInfo.contractDetails && userInfo.contractDetails.endDate
                  }
                  name="contractDetails"
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => updateUser(e)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------Modal Legal-----------*/}
      <Modal
        show={showLegal}
        onHide={handleCloseLegal}
        dialogClassName="tq__modal-default w-80"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">Legal Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="tq-form">
              {/* <Form.Group xs={12} as={Col} className="mb-3"></Form.Group> */}
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="pan">PAN</Form.Label>
                <Form.Control
                  type="text"
                  value={userInfo.pan}
                  onChange={handleInputChange}
                  placeholder="Pan Number"
                  name="pan"
                />
              </Form.Group>
              <Form.Group xs={6} as={Col} className="mb-3">
                <Form.Label htmlFor="gstin">GSTIN</Form.Label>
                <Form.Control
                  type="text"
                  value={userInfo.gstIn}
                  onChange={handleInputChange}
                  placeholder="GSTIN Number"
                  name="gstIn"
                />
              </Form.Group>
              <Form.Group xs={12} as={Col} className="mb-3">
                <Form.Label htmlFor="oldts">Other Legal Details</Form.Label>
                <Form.Control
                  type="text"
                  value={userInfo.seoName}
                  onChange={handleInputChange}
                  placeholder="Other Legal Details"
                  name="seoName"
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleCloseLegal}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => updateUser(e)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------Modal Generate Password-----------*/}
      <Modal
        show={showGenPass}
        onHide={handleCloseGenPass}
        dialogClassName="tq__modal-default w-80"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Generate Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Form.Control type="text" value={password} />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleCloseGenPass}>
            Close
          </Button>
          <Button variant="primary" onClick={generatePassword}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
// export default UserDetailsPage;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(UserDetailsPage);
