export const statusDisplayMapping = {
  Pending: "Pending",
  Onhold: "Onhold",
  Screened: "Screened",
  Rejected: "Rejected",
  InProcess: "InProcess",
  Offered: "Offered",
  OfferAccepted: "Offer Accepted",
  OfferRejected: "Offer Rejected",
  Joined: "Joined",
  OfferRevoked: "Offer Revoked",
  Dropped: "Dropped",
  Proposed: "Proposed",
  Scheduled: "Scheduled",
  Declined: "Declined",
  RoundCompleted: "Round Completed",
  RoundClear: "Round Clear",
};
