import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import "./jobscard.css";

const UnderSubscribedCard = (props) => {
  const history = useHistory();
  const clickDashboardRequirement = (jobId) => {
    history.push(`/alljobs/${jobId}?enroll=${true}`);
  };
  const clickVendorRequirement = (jobId) => {
    //const enrollStatus = linkjobIds.indexOf(jobId) > -1 ? true : false;
    history.push(`/alljobs/${jobId}?enroll=${false}`);
  };
  return (
    <div className="undersubscribe__card my-3">
      <div className="cardHeader">
        <div className="cardBody">
          <div className="cardPriority">
            <Link
            // onClick={props.jobLink}
            >
              <h2 className="cardTitle">{props.Heading}</h2>
            </Link>
            <div className="cardRow">
              <strong>Company :</strong>
              <p>{props.company}</p>
            </div>
            <div className="cardRow">
              <strong>Skills :</strong>
              <p>{props.skills}</p>
            </div>
          </div>
          <div className="cardPriority otherDetails">
            <div className="cardRow">
              <strong>Locations :</strong>
              <p>{props.location}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cardFooter">
        <Row>
          <Col xs={5} className="align-self-center">
            <div className="posted__on">
              <p className="m-0">
                {new Date(props.posted).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </p>
            </div>
          </Col>
          <Col xs={7}>
            <div className="buttonsGroup text-right">
              {props.enrolled === true ? (
                <Button
                  variant="success"
                  className={`${props.enrolled}`}
                  onClick={() => clickDashboardRequirement(props.jobLink)}
                >
                  Enrolled
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={`${props.enrolled}`}
                  onClick={() => clickVendorRequirement(props.jobLink)}
                >
                  View/Enroll
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UnderSubscribedCard;
