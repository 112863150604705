import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { dEndPoints } from "../../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../../actions/index";
const TermsAndContact = (props) => {
  // const checkRateCard = () => {
  //   const corporateId = localStorage.getItem("ci");
  //   //const corporateId = cacheData ? cacheData.ci : "";
  //   const jobData = { ...props.job };
  //   const data = {
  //     jobType: jobData.jobType,
  //     minBudget: jobData.minBudget * 100000,
  //     maxBudget: jobData.maxBudget * 100000,
  //     expFrom: jobData.expFrom,
  //     expTo: jobData.expTo,
  //   };
  //   axios
  //     .post(dEndPoints.rateCard(corporateId), data)
  //     .then((res) => {
  //       if (res.data && res.data.code === 100100) {
  //         const rateCard = res.data.data;
  //         props.showSnackBar({
  //           message: `Payment : ${rateCard.payment}, Rate : ${rateCard.rate} and Replacement : ${rateCard.replacement}`,
  //           open: true,
  //           type: "success",
  //           vertical: "top",
  //           horizontal: "center",
  //         });
  //       }
  //       if (res.data && res.data.code === 100099) {
  //         props.showSnackBar({
  //           message: res.data.message,
  //           open: true,
  //           type: "success",
  //           vertical: "top",
  //           horizontal: "center",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };
  function handleInputChange(event) {
    var cjob = { ...props.job };
    if (event.target.name === "jobType") {
      if (event.target.value === "Walkin") {
        cjob.walkin = true;
      } else {
        cjob.walkin = false;
      }
      cjob[event.target.name] = event.target.value;
      // } else if (
      //   cjob.walkin &&
      //   (event.target.id === "walkInVenue" ||
      //     event.target.id === "walkinContactPerson")
      // ) {
      //   if (cjob.walkinD) {
      //     cjob.walkinD[event.target.id] = event.target.value;
      //   } else {
      //     cjob.walkinD = {};
      //     cjob.walkinD[event.target.id] = event.target.value;
      //   }
    } else if (event.target.id === "reqId" || event.target.id === "desig") {
      //removing whitespaces
      var cleanText = event.target.value.replace(/<\/?[^>]+(>|$)/g, "");
      cleanText = cleanText.replace(/\r?\n|\r|\n|\t/g, " ");
      cjob[event.target.id] = cleanText;
    } else if (event.target.name === "city") {
      if (event.target.value.length <= 3) {
        cjob[event.target.name] = event.target.value;
      } else {
        cjob[event.target.name] = cjob.city;
      }
    } else if (event.target.name === "cName") {
      cjob[event.target.name] = event.target.value;
    } else if (
      event.target.name === "expTo" ||
      event.target.name === "expFrom" ||
      event.target.name === "closedComment" ||
      event.target.name === "extraDetails"
    ) {
      cjob[event.target.name] = event.target.value;
    } else if (event.target.id === "rate") {
      cjob[event.target.id] = event.target.value;
    } else if (event.target.id === "vRate") {
      cjob[event.target.id] = event.target.value;
    } else if (
      event.target.name === "nPeriod" ||
      event.target.name === "payment" ||
      event.target.name === "paymentTerm"
    ) {
      cjob[event.target.name] = Number(event.target.value);
    } else if (
      event.target.id === "minBudget" ||
      event.target.id === "maxBudget" ||
      event.target.id === "replacement" ||
      event.target.id === "totpos"
    ) {
      cjob[event.target.id] = Number(event.target.value);
    } else if (event.target.id === "name") {
      var conPer = cjob.conPer ? cjob.conPer : {};
      conPer[event.target.id] = event.target.value;
      cjob.conPer = conPer;
    } else if (event.target.id === "phNum") {
      var conPer = cjob.conPer ? cjob.conPer : {};
      conPer[event.target.id] = Number(event.target.value);
      cjob.conPer = conPer;
    } else if (event.target.name === "talenqPayRoll") {
      cjob[event.target.name] = event.target.value;
    } else if (event.target.name === "is_talenq_closed") {
      var closeJob = cjob && cjob.feedback ? cjob.feedback : {};
      closeJob[event.target.name] = event.target.value === "true";
      cjob.feedback = closeJob;
    } else {
      cjob[event.target.id] = event.target.value;
    }
    props.setJob({ ...props.job, ...cjob });
  }
  const userType = localStorage.getItem("ut");
  return (
    <div className="tq-form terms__contact-from">
      <div className="mb-5">
        <h2 className="pageHeadings">Terms of Engagement & Contact Person</h2>
      </div>
      <Form noValidate validated={props.validated}>
        <Row className="mb-4 text-left">
          <Form.Group as={Col} xs={6} className="mb-3">
            <Form.Label>
              Name
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="name"
              type="text"
              required="required"
              value={props.job && props.job.conPer ? props.job.conPer.name : ""}
              onChange={handleInputChange}
              placeholder="Name"
            />
            <Form.Control.Feedback type="invalid">
              Please provide Contact Person Name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mb-3">
            <Form.Label>
              Contact No.
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="phNum"
              type="text"
              minLength="10"
              maxLength="10"
              required="required"
              value={
                props.job && props.job.conPer ? props.job.conPer.phNum : null
              }
              onChange={handleInputChange}
              placeholder="Phone Number"
            />
            <Form.Control.Feedback type="invalid">
              Please provide 10 digits Contact Number.
            </Form.Control.Feedback>
          </Form.Group>
          {userType === "1110" ? (
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label>Vendor Rate :</Form.Label>
              <Form.Control
                id="vRate"
                type="text"
                value={props.job && props.job.vRate}
                onChange={handleInputChange}
                placeholder="Vendor Rate"
              />
              <Form.Control.Feedback type="invalid">
                Please Provide Vendor Rate.
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ""
          )}
          <Form.Group as={Col} xs={6} className="mb-3">
            <Form.Label>
              Replacement <small className="required__form">*</small>
            </Form.Label>
            {props.job.jobType === "Executive" ? (
              <select
                id="replacement"
                className="form-select"
                aria-label="replacement"
                multiple={false}
                value={props.job && props.job.replacement}
                onChange={handleInputChange}
              >
                <option selected> Select Replacement Period</option>
                <option value={3}> 3 Months</option>
                <option value={6}> 6 Months</option>
                <option value={3}> 2 Months</option>
                <option value={1}> 1 Month</option>
              </select>
            ) : (
              <select
                id="replacement"
                className="form-select"
                aria-label="replacement"
                multiple={false}
                value={props.job && props.job.replacement}
                onChange={handleInputChange}
              >
                <option selected> Select Replacement Period</option>
                <option value={1}> 1 Month</option>
                <option value={2}> 2 Months</option>
                <option value={3}> 3 Months</option>
              </select>
            )}
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.replacement ? props.formerrors.replacementError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mb-3">
            <Form.Label>
              Rate
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="rate"
              value={props.job && props.job.rate}
              onChange={handleInputChange}
              required="required"
              type="text"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              placeholder="Rate"
            />
            {/* <Form.Control.Feedback type="invalid">
              Please Provide Rate.
            </Form.Control.Feedback> */}
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.rate ? props.formerrors.rateError : ""}
            </p>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mb-4">
            <Form.Label>
              Payments
              <small className="required__form">*</small>
            </Form.Label>
            <select
              name="payment"
              className="form-select"
              aria-label="payments"
              //required="required"
              multiple={false}
              value={props.job && props.job.payment}
              onChange={handleInputChange}
            >
              <option> Select Payments Period</option>
              <option value={1}> 1 Month</option>
              <option value={2}> 2 Months</option>
              <option value={3}> 3 Months</option>
            </select>
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.payment ? props.formerrors.paymentError : ""}
            </p>
          </Form.Group>
          {userType === "1110" ? (
            <Form.Group as={Col} xs={6} className="mb-4">
              <Form.Label>Payments Term (Vendor)</Form.Label>
              <select
                name="paymentTerm"
                className="form-select"
                aria-label="payments"
                multiple={false}
                value={props.job && props.job.paymentTerm}
                onChange={handleInputChange}
              >
                <option selected> Select Payments Term</option>
                <option value={1}> 1 Month</option>
                <option value={2}> 2 Months</option>
                <option value={3}> 3 Months</option>
              </select>
            </Form.Group>
          ) : (
            ""
          )}
        </Row>
      </Form>
    </div>
  );
};
//export default TermsAndContact;

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(TermsAndContact);
