import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { dEndPoints } from "../../../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../../actions/index";
import RejectReasonModal from "./RejectReasonModal";
// import { useHistory } from "react-router";
import BookSlotModal from "../../badge/BookSlotModal";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import EditCandidate from "./EditCandidate";

import { useParams } from "react-router";

const ActionOnCandidates = (props) => {
  let { jobId } = useParams();

  const [joined, setJoined] = useState({ BillableAmount: "" });
  //const [anchorEl, setAnchorEl] = useState(null);
  const [rounds, setRounds] = useState([]);

  // const [jobs, setJobs] = useState([]);
  const [backuprounds, setBackuprounds] = useState([]);
  const [openReject, setOpenReject] = useState(false);
  const [openHold, setopenHold] = useState(false);
  //const [openMove, setopenMove] = useState(false);
  const [dropHold, setDropHold] = useState(false);
  // const [openBlacklist, setOpenBlacklist] = useState(false);
  const [comment, setComment] = useState("");
  // const [findJob, setFindJob] = useState({ jobId: "" });
  // const [blacklist, setBlacklist] = useState();
  // const [blacklistComment, setBlacklistComment] = useState("");
  const [openformSchedule, setOpenformSchedule] = useState(false);
  const [openNotResponding, setOpenNotResponding] = useState(false);
  const [responding, setResponding] = useState();
  const [openSlotBooking, setOpenSlotBooking] = useState(false);
  const [users, setUsers] = useState(props.candidate);
  const [editing, setEditing] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [editableUser, setEditableUser] = useState({});
  // const [openCandidateEdit, setOpenCandidateEdit] = useState(false);
  const initialFormState = {
    name: "",
    email: "",
    contact: "",
    college: "",
    city: "",
    cDesig: "",
    expr: "",
    ctc: "",
    ectc: "",
    cName: "",
    skills: [],
    qual: "",
    nPeriod: null,
    extraFields: [],
    lastworkday: "",
    tenPercent: "",
    twelvePercent: "",
    ugPercent: "",
    pgPercent: "",
    pan: "",
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const handleOpenSlotBooking = () => {
    // setTimeout(() => {
    //   props.showLoader({ show: true });
    // }, 4000);
    // props.showLoader({ show: false });
    //  getInterviewRounds();
    setOpenSlotBooking(true);
  };

  useEffect(() => {
    getInterviewRounds();
    //reloadJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseSlotBooking = () => {
    setOpenSlotBooking(false);
    //window.location.reload();
  };
  const corporateId = localStorage.getItem("ci");
  //const jobid = props.jobId;
  const candidateProfileId = props.candidate._id;
  const userType = localStorage.getItem("ut");
  const vendorCorporateId = localStorage.getItem("vci");
  // *** blacklist dialog *** //

  const handleOfferDateChange = (date, localDate) => {
    var cjoined = { ...joined };
    cjoined.OfferDate = localDate;
    setJoined(cjoined);
  };
  const handleJoiningDateChange = (date, localDate) => {
    var cjoined = { ...joined };
    cjoined.JoiningDate = localDate;
    setJoined(cjoined);
  };
  const handleAmountInputChange = (e) => {
    var cjoined = { ...joined };
    cjoined.BillableAmount = e.target.value;
    setJoined(cjoined);
  };
  // *** reject dailog *** //
  const handleRejectOpen = () => {
    setOpenReject(true);
  };
  const handleCloseEditCandidate = () => {
    setUserEdit(false);
  };

  const handleRejectClose = () => {
    setOpenReject(false);
  };
  // *** on Hold Dailog *** //
  const handleOnHold = () => {
    setopenHold(true);
  };
  // const handleMoveToAnotherJob = () => {
  //   setopenMove(true);
  // };
  // const handleCloseMoveToAnother = () => {
  //   setopenMove(false);
  // };

  const handleDropped = () => {
    setDropHold(true);
  };
  const handleCloseOnHold = () => {
    setopenHold(false);
  };
  const handleCloseDropped = () => {
    setDropHold(false);
  };
  // on set for interview round
  // const handleOpenSchedule = () => {
  //   setOpenformSchedule(true);
  // };
  // const handleCloseSchedule = () => {
  //   setOpenformSchedule(false);
  // };
  const handleRespondingOpen = (responding) => {
    setResponding(responding);
    setOpenNotResponding(true);
  };
  const handleRespondingClose = () => {
    setComment("");
    setOpenNotResponding(false);
  };
  // const handleChangeNoticeP = (e) => {
  //   const info = { ...findJob };
  //   info.jobId = e.target.value;
  //   setFindJob(info);
  // };
  const commentChange = (e) => {
    setComment(e.target.value);
  };
  const updateUser = (name, updatedUser) => {
    setEditing(false);
    setUsers(users.map((user) => (user.name === name ? updatedUser : user)));
  };
  const editRow = (user) => {
    setEditing(true);
    setCurrentUser({
      name: props.candidate.name,
      email: props.candidate.email,
      contact: props.candidate.contact,
      college: props.candidate.college,
      city: props.candidate.city,
      cDesig: props.candidate.cDesig,
      expr: props.candidate.expr,
      ctc: props.candidate.ctc,
      ectc: props.candidate.ectc,
      cName: props.candidate.cName,
      skills: props.candidate.skills,
      qual: props.candidate.qual,
      nPeriod: props.candidate.nPeriod,
      lastworkday: props.candidate.lastworkday,
      tenPercent: props.candidate.tenPercent,
      twelvePercent: props.candidate.twelvePercent,
      ugPercent: props.candidate.ugPercent,
      pgPercent: props.candidate.pgPercent,
      pan: props.candidate.pan,
      extraFields: props.candidate.extraFields,
    });
  };
  const handleEdit = () => {
    //props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
    editRow();
    setEditableUser(props.candidate);
    setUserEdit(true);
  };
  const handleCandidateUpdate = (id, jobId, status, comment, subStatus) => {
    //const corporateId = cacheData.ci;

    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");

      if (comment) {
        var data = {
          newStatus: status,
          comment: comment,
          subStatus: subStatus,
        };
      } else {
        var data = {
          newStatus: status,
          subStatus: subStatus,
        };
      }
      props.showLoader({ show: true });
      axios
        .put(dEndPoints.corpCandidateUpdate(corporateId, jobId, id), data)
        .then((res) => {
          var data = res.data;
          setComment("");
          if (data && data.code === 100000) {
            props.showLoader({ show: false });
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          }
          if (status === "Onhold") {
            handleCloseOnHold();
          }

          if (status === "Rejected") {
            handleRejectClose();
          }
          if (status === "Screened") {
            handleRespondingClose();
          }
          if (status === "Pending") {
            handleRespondingClose();
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
          setComment("");
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100114
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");

      if (comment) {
        var data = {
          newStatus: status,
          comment: comment,
          cId: props.candidate.cId,
          //subStatus: subStatus,
        };
      } else {
        var data = {
          newStatus: status,
          cId: props.candidate.cId,
          //subStatus: subStatus,
        };
      }
      axios
        .put(
          dEndPoints.vendorCandidateUpdate(vendorCorporateId, jobId, id),
          data
        )
        .then((res) => {
          var data = res.data;
          setComment("");
          if (data && data.code === 100000) {
            props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          }
          if (status === "Dropped") {
            handleCloseDropped();
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  };

  // const handleMovingToAnotherJobUpdate = (id, jobId) => {
  //   //const corporateId = cacheData.ci;

  //   if (userType === "1110" || userType === "1100") {
  //     const corporateId = localStorage.getItem("ci");
  //     var data = { jobId: findJob.jobId };
  //     var candId;
  //     // if (comment) {
  //     //   var data = {
  //     //     newStatus: status,
  //     //     comment: comment,
  //     //     subStatus: subStatus,
  //     //   };
  //     // } else {
  //     //   var data = {
  //     //     newStatus: status,
  //     //     subStatus: subStatus,
  //     //   };
  //     // }
  //     props.showLoader({ show: true });
  //     axios
  //       .put(dEndPoints.moveCandidateToAnotherJob(corporateId, jobId, id), data)
  //       .then((res) => {
  //         var data = res.data;
  //         setopenMove(false);
  //         candId = props.canId;
  //         props.showLoader({ show: false });
  //         setFindJob({ jobId: "" });
  //         if (data && data.code === 100000) {
  //           props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
  //           props.showSnackBar({
  //             message: data.message,
  //             open: true,
  //             type: "success",
  //             vertical: "top",
  //             horizontal: "center",
  //           });
  //           return;
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);

  //         if (
  //           error.response &&
  //           error.response.data &&
  //           error.response.data.code === 100190
  //         ) {
  //           props.showSnackBar({
  //             message: error.response.data.message,
  //             open: true,
  //             type: "error",
  //             vertical: "top",
  //             horizontal: "center",
  //           });
  //           return;
  //         } else {
  //           props.showSnackBar({
  //             message: `Something went wrong. Please contact support team`,
  //             open: true,
  //             type: "error",
  //             vertical: "top",
  //             horizontal: "center",
  //           });
  //           return;
  //         }
  //       });
  //   }
  // };

  const getInterviewRounds = async () => {
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      await axios
        .get(dEndPoints.interviewRounds(corporateId, jobId))
        .then((res) => {
          setTimeout(() => {
            props.showLoader({ show: false });
          }, 2000);
          var intRounds = res.data;

          setRounds(intRounds.data.interview);
          setBackuprounds(intRounds.data.interview);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      props.showLoader({ show: true });
      await axios
        .get(dEndPoints.vendorInterviewRounds(vendorCorporateId, jobId))
        .then((res) => {
          setTimeout(() => {
            props.showLoader({ show: false });
          }, 2000);
          var intRounds = res.data;

          setRounds(intRounds.data.interview);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const getUpdatedInterviews = async () => {
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      await axios
        .get(
          dEndPoints.candidateInterviews(
            corporateId,
            jobId,
            candidateProfileId
          ),
          null
        )
        .then((res) => {
          props.showLoader({ show: false });
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          //setInterviews(res.data.data);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      props.showLoader({ show: true });
      await axios
        .get(
          dEndPoints.vendorCandidateInterviews(
            vendorCorporateId,
            jobId,
            candidateProfileId
          ),
          null
        )
        .then((res) => {
          props.showLoader({ show: false });
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          //setInterviews(res.data.data);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  var statusButtons = [];
  if (props.candidate.stat !== "Closed") {
    if (
      props.candidate.stat === "Screened" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <Button
          variant="success"
          key={props.candidate._id + "inprocess"}
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "InProcess"
            )
          }
        >
          In Process
        </Button>
      );
      statusButtons.push(
        <Button
          variant="danger"
          key={props.candidate._id + "reject"}
          onClick={handleRejectOpen}
        >
          Reject
        </Button>
      );
      if (props.candidate.responding === false) {
        statusButtons.push(
          <Button
            // color="info"
            variant="primary"
            onClick={() => handleRespondingOpen(true)}
          >
            Responding
          </Button>
        );
      } else if (
        !props.candidate.responding ||
        props.candidate.responding === true
      ) {
        statusButtons.push(
          <Button
            // color="info"
            variant="danger"
            key={props.candidate._id}
            onClick={() => handleRespondingOpen(false)}
          >
            Not responding
          </Button>
        );
      }
    } else if (
      props.candidate.stat === "Pending" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <Button
          variant="success"
          key={props.candidate._id + "screened"}
          // color="info"
          className="tq-success"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "Screened"
            )
          }
        >
          Screen Select
        </Button>
      );
      statusButtons.push(
        <Button
          variant="warning"
          key={props.candidate._id + "onhold"}
          // color="info"
          onClick={handleOnHold}
        >
          On Hold
        </Button>
      );
      // statusButtons.push(
      //   <Button
      //     variant="warning"
      //     key={props.candidate._id + "Pending"}
      //     // color="info"
      //     onClick={handleMoveToAnotherJob}
      //   >
      //     Move to Another Job
      //   </Button>
      // );
      statusButtons.push(
        <Button
          variant="danger"
          key={props.candidate._id + "rejected"}
          // color="info"
          className="tq-close"
          onClick={handleRejectOpen}
        >
          Reject
        </Button>
      );
      if (props.candidate.responding === false) {
        statusButtons.push(
          <Button
            // color="info"
            variant="primary"
            key={props.candidate._id}
            onClick={() => handleRespondingOpen(true)}
          >
            Responding
          </Button>
        );
      } else if (
        !props.candidate.responding ||
        props.candidate.responding === true
      ) {
        statusButtons.push(
          <Button
            variant="danger"
            key={props.candidate._id}
            onClick={() => handleRespondingOpen(false)}
          >
            Not responding
          </Button>
        );
      }
    } else if (
      props.candidate.stat === "Rejected" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <Button
          key={props.candidate._id + "InProcess"}
          // color="info"
          variant="success"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "InProcess"
            )
          }
        >
          Re Process
        </Button>
      );
      statusButtons.push(
        <Button
          variant="warning"
          key={props.candidate._id + "onhold"}
          // color="info"
          className="tq-theme"
          onClick={handleOnHold}
        >
          On Hold
        </Button>
      );
    } else if (
      props.candidate.stat === "Onhold" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <Button
          variant="success"
          key={props.candidate._id + "reprocessed"}
          // color="info"
          //className="tq-theme"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "InProcess"
            )
          }
        >
          Re Process
        </Button>
      );
      statusButtons.push(
        <Button
          key={props.candidate._id + "rejected"}
          // color="info"
          variant="danger"
          //className="tq-close"
          onClick={handleRejectOpen}
        >
          Reject
        </Button>
      );
    } else if (
      props.candidate.stat === "Offered" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <Button
          key={props.candidate._id + "OfferAccepted"}
          // color="info"
          variant="success"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "OfferAccepted"
            )
          }
        >
          Offer Accepted
        </Button>
      );
      statusButtons.push(
        <Button
          key={props.candidate._id + "dropped"}
          // color="info"
          //className="tq-close"
          variant="danger"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "OfferRejected"
            )
          }
        >
          Offer Rejected
        </Button>
      );
    } else if (
      props.candidate.stat === "OfferAccepted" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <Button
          key={props.candidate._id + "Joined"}
          // color="info"
          // className="tq-success"
          variant="success"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "Joined"
            )
          }
        >
          Joined
        </Button>
      );

      statusButtons.push(
        <Button
          key={props.candidate._id + "OfferRevoked"}
          variant="primary"
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "OfferRevoked"
            )
          }
        >
          Offer Revoked
        </Button>
      );
    } else if (
      props.candidate.stat === "InProcess" &&
      (userType === "1110" || userType === "1100")
    ) {
      statusButtons.push(
        <BookSlotModal
          BtnName="Schedule Interview"
          getInterviewRounds={getInterviewRounds}
          handleCloseSlotBooking={handleCloseSlotBooking}
          jobId={props.candidate.jobId}
          handleOpenSlotBooking={handleOpenSlotBooking}
          candidateId={props.candidate._id}
          interviews={props.interviews ? props.interviews : ""}
          getUpdatedInterviews={getUpdatedInterviews}
          rounds={rounds}
          openSlotBooking={openSlotBooking}
          backuprounds={backuprounds}
          loadCandidatesDetails={props.loadCandidatesDetails}
        />
      );
      statusButtons.push(
        <Button
          // color="info"
          variant="offered"
          //className="tq-inactive"
          key={props.candidate._id + "offered"}
          onClick={() =>
            handleCandidateUpdate(
              props.candidate._id,
              props.candidate.jobId,
              "Offered"
            )
          }
        >
          Offered
        </Button>
      );
      statusButtons.push(
        <Button
          // color="info"
          //className="tq-theme"
          variant="warning"
          key={props.candidate._id + "onhold"}
          onClick={handleOnHold}
        >
          On Hold
        </Button>
      );
      statusButtons.push(
        <Button
          // color="info"
          //className="tq-close"
          variant="danger"
          key={props.candidate._id + "rejected"}
          onClick={handleRejectOpen}
        >
          Reject
        </Button>
      );
    }
  }

  if (props.candidate.stat === "Screened" && userType === "1000") {
    statusButtons.push(
      <Button
        variant="block"
        size="sm"
        key={props.candidate._id + "dropped"}
        onClick={handleDropped}
      >
        Drop
      </Button>
    );
  } else if (props.candidate.stat === "Pending" && userType === "1000") {
    statusButtons.push(
      <Button
        key={props.candidate._id + "dropped"}
        // color="red"
        variant="block"
        onClick={handleDropped}
      >
        Drop
      </Button>
    );
  } else if (props.candidate.stat === "Onhold" && userType === "1000") {
    statusButtons.push(
      <Button
        key={props.candidate._id + "dropped"}
        variant="block"
        onClick={handleDropped}
      >
        Drop
      </Button>
    );
  } else if (props.candidate.stat === "Offered" && userType === "1000") {
    statusButtons.push(
      <Button
        key={props.candidate._id + "dropped"}
        variant="block"
        // onClick={() =>
        //   handleCandidateUpdate(
        //     props.candidate._id,
        //     props.candidate.jobId,
        //     "Dropped"
        //   )
        // }
        onClick={handleDropped}
      >
        Drop
      </Button>
    );
    statusButtons.push(
      <Button
        key={props.candidate._id + "OfferAccepted"}
        variant="success"
        onClick={() =>
          handleCandidateUpdate(
            props.candidate._id,
            props.candidate.jobId,
            "OfferAccepted"
          )
        }
      >
        Offer Accepted
      </Button>
    );
    statusButtons.push(
      <Button
        key={props.candidate._id + "OfferRejected"}
        variant="danger"
        onClick={() =>
          handleCandidateUpdate(
            props.candidate._id,
            props.candidate.jobId,
            "OfferRejected"
          )
        }
      >
        Offer Rejected
      </Button>
    );
  } else if (props.candidate.stat === "InProcess" && userType === "1000") {
    statusButtons.push(
      <Button
        variant="block"
        key={props.candidate._id + "dropped"}
        onClick={handleDropped}
      >
        Drop
      </Button>
    );
  } else if (props.candidate.stat === "Dropped" && userType === "1000") {
    statusButtons.push(
      <Button
        variant="primary"
        key={props.candidate._id + "Pending"}
        onClick={() =>
          handleCandidateUpdate(
            props.candidate._id,
            props.candidate.jobId,
            "Pending"
          )
        }
      >
        ReProcess
      </Button>
    );
  }

  return (
    <div className="action__buttons-wrapper">
      <ButtonGroup>
        {props.LikeDislike}
        {props.candidate.stat === "Pending" && userType === "1000" ? (
          <Button variant="primary" onClick={handleEdit}>
            Edit
          </Button>
        ) : null}
        {statusButtons}
        {props.BlockStateButton}
      </ButtonGroup>
      {/* ******Dialog for onHold candidate ******* */}

      <Modal
        show={openHold}
        dialogClassName="tq__modal-default"
        onHide={handleCloseOnHold}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Are You Sure You Want to Do ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form">
          <Form.Label>
            Please specify the reason.{" "}
            <small className="required__form">*</small>
          </Form.Label>
          <Form.Control
            id="comment"
            label="Comments"
            aria-label="Comments"
            value={comment}
            onChange={commentChange}
            handleCandidateUpdate={handleCandidateUpdate}
            placeholder="Please specify the reason."
          />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button onClick={handleCloseOnHold} variant="secondary">
            No
          </Button>
          <Button
            variant="primary"
            type="submit"
            value="Onhold"
            onClick={() =>
              handleCandidateUpdate(
                props.candidate._id,
                props.candidate.jobId,
                "Onhold",
                comment
              )
            }
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ******Dialog for Moving candidate to another job ******* */}

      {/* <Modal
        show={openMove}
        dialogClassName="tq__modal-default"
        onHide={handleCloseMoveToAnother}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Select Job Requirement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form">
          <select
            id="jobId"
            className="form-select"
            aria-label="noticePriod"
            multiple={false}
            required="required"
            value={findJob.jobId}
            onChange={handleChangeNoticeP}
          >
            {" "}
            <option>Choose JobId</option>
            {jobs.map((details, index) =>
              details.status === "Open" && userType === "1100" ? (
                <option value={details._id}>
                  JobId: {details.reqId}, Designation: {details.desig}
                </option>
              ) : details.status === "Open" && userType === "1110" ? (
                <option value={details._id}>
                  JobId: {details.reqId}, Designation: {details.desig} ,Company
                  Name: {details.cName}
                </option>
              ) : (
                ""
              )
            )}
          </select>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button onClick={handleCloseMoveToAnother} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            value="Pending"
            onClick={() =>
              handleMovingToAnotherJobUpdate(
                props.candidate._id,
                props.candidate.jobId,
                "Pending"
              )
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* ******Dialog for onHold candidate ******* */}

      <Modal
        show={dropHold}
        dialogClassName="tq__modal-default"
        onHide={handleCloseDropped}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Are You Sure You Want to Do ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form">
          <Form.Label>
            Please specify the reason.{" "}
            <small className="required__form">*</small>
          </Form.Label>
          <Form.Control
            id="comment"
            label="Comments"
            aria-label="Comments"
            value={comment}
            onChange={commentChange}
            handleCandidateUpdate={handleCandidateUpdate}
            placeholder="Please specify the reason."
          />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button onClick={handleCloseDropped} variant="secondary">
            No
          </Button>
          <Button
            variant="primary"
            type="submit"
            value="Dropped"
            onClick={() =>
              handleCandidateUpdate(
                props.candidate._id,
                props.candidate.jobId,
                "Dropped",
                comment
              )
            }
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ******Dialog for not responding candidate ******* */}

      <Modal
        show={openNotResponding}
        dialogClassName="tq__modal-default"
        onHide={handleRespondingClose}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Not Responding Reason
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form">
          <Form.Label>
            Comment.<small className="required__form">*</small>
          </Form.Label>
          <Form.Control
            id="comment"
            value={comment}
            onChange={commentChange}
            handleCandidateUpdate={handleCandidateUpdate}
            placeholder="Reason specification"
          />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleRespondingClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() =>
              handleCandidateUpdate(
                props.candidate._id,
                props.candidate.jobId,
                props.candidate.stat,
                comment,
                responding
              )
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal dialogClassName="tq__modal-default" onHide={props.handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Joining Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                id="offerDate"
                label="Offer Date"
                format="MM/dd/yyyy"
                disablePast={true}
                placeholder="mm/dd/yyyy"
                value={
                  joined && joined.OfferDate ? joined.OfferDate : new Date()
                }
                InputAdornmentProps={{ position: "end", color: "info" }}
                onChange={handleOfferDateChange}
              />
            </MuiPickersUtilsProvider>
          </>
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                id="joiningDate"
                label="Joining Date"
                format="MM/dd/yyyy"
                disablePast={true}
                placeholder="mm/dd/yyyy"
                value={
                  joined && joined.JoiningDate ? joined.JoiningDate : new Date()
                }
                InputAdornmentProps={{ position: "end", color: "info" }}
                onChange={handleJoiningDateChange}
              />
            </MuiPickersUtilsProvider>
          </>

          <FormControl
            id="BillableAmount"
            className="jodtxtFld"
            labelText="Billable Amount (Offered CTC)"
            formControlProps={{
              fullWidth: true,
              required: true,
              inputProps: { min: 6 },
            }}
            InputProps={{ inputProps: { min: 6 } }}
            inputProps={{
              value:
                joined && joined.BillableAmount ? joined.BillableAmount : "",
              onChange: handleAmountInputChange,
            }}
          />

          <Button onClick={props.handleClose}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            value="Joined"
            onClick={() =>
              handleCandidateUpdate(props.candidate._id, "Joined", joined)
            }
          >
            Create Invoice
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={openReject}
        dialogClassName="tq__modal-default rejectReson"
        onHide={handleRejectClose}
      >
        <RejectReasonModal
          handleCandidateUpdate={handleCandidateUpdate}
          handleRejectClose={handleRejectClose}
          candidate={props.candidate}
        />
      </Modal>
      <Modal
        show={userEdit}
        dialogClassName="tq__modal-default w-80"
        onHide={handleCloseEditCandidate}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Edit Candidate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <EditCandidate
            editing={editing}
            setEditing={setEditing}
            currentUser={currentUser}
            updateUser={updateUser}
            openformSchedule={userEdit}
            handleClose={handleCloseEditCandidate}
            editableUser={editableUser}
            job={props.job}
            loadCandidatesDetails={props.loadCandidatesDetails}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
//export default ActionOnCandidates;

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ActionOnCandidates);
