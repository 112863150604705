import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import UnderSubscribedCard from "./UnderSubscribedCard";

// const options = {
//   items: 3,
//   nav: true,
//   rewind: true,
//   margin: 10,
//   autoWidth: true,
//   autoplay: true,
//   navText: [
//     '<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/></svg>',
//     '<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/></svg>',
//   ],
// };
const UnderSubscribedJobs = (props) => {
  return (
    <div className={`under__subscribed-jobs ${props.className}`}>
      <Container>
        <div className="tq__page-header mb-3">
          <h2 className="page-header">Under Subscribed Jobs</h2>
        </div>
        <Row>
          {props.sliderData.map((item, index) => (
            <Col sm={4} className="mb-4">
              <UnderSubscribedCard
                jobLink={item._id}
                Heading={item.desig}
                company={item.cName}
                skills={item.pSkills.join()}
                posted={item.cOn}
                location={item.city[0]}
                enrolled={
                  props.linkjobIds && props.linkjobIds.indexOf(item._id) > -1
                    ? true
                    : false
                }
              />
            </Col>
          ))}
        </Row>
        {/* <>
          <OwlCarousel
            id="owl-carousel"
            options={options}
            className="owl-carousel owl-theme"
            key={index}
          >
            
          </OwlCarousel>
        </> */}
      </Container>
    </div>
  );
};
export default UnderSubscribedJobs;
