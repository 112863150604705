import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
const UserCardAdmin = (props) => {
  console.log("UserCardAdmin", props);
  return (
    <div className="user__card-admin position-relative h-100 tq-cards">
      {props.cOn ? (
        <span
          className="arrival__time d-flex align-items-center"
          title="Created On"
        >
          <i className="bx bx-time-five"></i>&nbsp;{props.cOn}
        </span>
      ) : (
        ""
      )}
      {props.accountStatus === "Active" ? (
        <div className="status green" title="Active Account">
          <i className="bx bxs-check-circle"></i>
        </div>
      ) : props.accountStatus === "Verified" ? (
        <div className="status yellow" title="Account Verified">
          <i className="bx bx-alarm-exclamation"></i>
        </div>
      ) : props.accountStatus === "Profile Complete" ? (
        <div className="status yellow" title="Account Verified">
          <i className="bx bx-alarm-exclamation"></i>
        </div>
      ) : props.accountStatus === "Initiated" ? (
        <div className="status yellow" title="Account Verified">
          <i className="bx bx-alarm-exclamation"></i>
        </div>
      ) : (
        ""
      )}
      <div className="content">
        <div className="header__details pt-3 mb-3">
          <h3 className="title">{props.titleName}</h3>
          {props.UserAccess ? (
            <small className="company__status">
              User Access Type -{" "}
              <mark className="t-capital">{props.UserAccess}</mark>
            </small>
          ) : (
            ""
          )}
          {props.userType ? (
            <small className="company__status">
              User Type -
              {props.userType === "Corporate" ? (
                <mark className="t-capital">
                  <i className="bx bxs-buildings"></i>&nbsp;{props.userType}
                </mark>
              ) : props.userType === "Vendor" ? (
                <mark className="t-capital">
                  <i className="bx bxs-group"></i>&nbsp;{props.userType}
                </mark>
              ) : (
                ""
              )}
            </small>
          ) : (
            ""
          )}
        </div>
        {props.author ? (
          <span className="text-row">
            <strong>Account Authority</strong>
            <span>{props.author}</span>
          </span>
        ) : (
          ""
        )}
        {props.contact ? (
          <span className="text-row">
            <strong>Contact</strong>
            <span>{props.contact}</span>
          </span>
        ) : (
          ""
        )}
        {props.accountStatus ? (
          <span className="text-row">
            <strong>Account Status</strong>
            <span
              className={`admin ${
                props.accountStatus === "Active"
                  ? "adgreen"
                  : props.accountStatus === "Profile Complete"
                  ? "pcomplete"
                  : props.accountStatus === "Deleted"
                  ? "dred"
                  : props.accountStatus === "Rejected"
                  ? "dred"
                  : props.accountStatus === "Initiated"
                  ? "yellow"
                  : props.accountStatus === "Inactive"
                  ? "inactive"
                  : props.accountStatus === "Registered"
                  ? "Registered"
                  : props.accountStatus === "Verified"
                  ? "Verified"
                  : ""
              }`}
            >
              {props.accountStatus}
            </span>
          </span>
        ) : (
          ""
        )}
        {props.motp || props.eotp ? (
          <>
            <span className="text-row">
              <strong>Mobile OTP</strong>
              <span>{props.motp}</span>
            </span>
            <span className="text-row">
              <strong>Email OTP</strong>
              <span>{props.eotp}</span>
            </span>{" "}
          </>
        ) : (
          ""
        )}
      </div>
      {(props.reject && props.accountStatus === "Profile Complete") ||
      (props.approve && props.accountStatus === "Profile Complete") ? (
        <div className="actions text-center">
          <ButtonGroup
            title={
              props.accountStatus === "Profile Complete"
                ? "Can't Take Any Action Acount is Verified"
                : ""
            }
          >
            <Button
              variant="danger"
              onClick={props.reject}
              title="Reject User Account"
            >
              Reject
            </Button>

            <Button
              variant="success"
              onClick={props.approve}
              title="Verify User Account"
            >
              Activate
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        ""
      )}
      {props.deleteUser ? (
        <div className="actions text-center">
          {/* <Button
            variant="danger"
            onClick={props.deleteUse}
            title="Delete User Account"
          >
            Delete
          </Button> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserCardAdmin;
