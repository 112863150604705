import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { dEndPoints } from "../../config/end-point";
import { showSnackBar, showLoader } from "../../actions/index";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./badge.css";
import { useParams } from "react-router";
// import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
// import RefreshIcon from "@material-ui/icons/Refresh";
// import Tooltip from "@material-ui/core/Tooltip";
import ScheduledModal from "./ScheduledModal";
import InstantSlot from "./InstantSlot";

const BookSlotModal = (props) => {
  const [show, setShow] = useState(false);
  const [rounds, setRounds] = useState([]);
  const [roundId, setRoundId] = useState("");
  const [slots, setSlots] = useState([]);
  const [inConfirm, setInConfirm] = useState(false);
  const [instant, setInstant] = useState(false);
  const [availableSlot, setAvailableSlot] = useState(false);
  const [backuprounds, setBackuprounds] = useState([]);
  const [limit, setLimit] = useState(null);
  // const [hightlightRoundId, setHightlightRoundId] = useState("");
  const [rName, setRName] = useState("");
  const [rMode, setRMode] = useState("");
  // const [slotType, setSlotType] = useState("");
  let { jobId } = useParams();
  const candidateProfileId = props.candidateId;
  const corporateId = localStorage.getItem("ci");
  const vendorCorporateId = localStorage.getItem("vci");
  const userType = localStorage.getItem("ut");
  //let history = new useHistory();
  // const closeDialog = () => {
  //   history.push(`/alljobs/${jobId}`);
  // };

  useEffect(() => {
    getInterviewRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInterviewRounds = () => {
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.interviewRounds(corporateId, jobId))
        .then((res) => {
          setTimeout(() => {
            props.showLoader({ show: false });
          }, 2000);
          var intRounds = res.data;

          setRounds(intRounds.data.interview);
          setBackuprounds(intRounds.data.interview);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorInterviewRounds(vendorCorporateId, jobId))
        .then((res) => {
          props.showLoader({ show: false });
          var intRounds = res.data;

          setRounds(intRounds.data.interview);
          setBackuprounds(intRounds.data.interview);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  const getSlots = async (rId, maxDailyLimit, roundName, mode) => {
    if (maxDailyLimit == null || maxDailyLimit === 0) {
      setLimit("no limit");
    } else {
      setLimit(maxDailyLimit);
    }
    setRName(roundName);
    setRMode(mode);
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      await axios
        .get(dEndPoints.interviewSlots(corporateId, rId))
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;

          var slotData = data.data;

          setSlots(slotData);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      props.showLoader({ show: true });
      await axios
        .get(dEndPoints.vendorInterviewSlots(vendorCorporateId, rId))
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;

          var slotData = data.data;
          setSlots(slotData);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const selectRound = (id) => {
    setRoundId(id);
    let maxDailyLimit;
    let roundName;
    let roundMode;

    let filterslots = backuprounds.filter((item) => item._id === id);

    filterslots.map((item) => {
      maxDailyLimit = item.maxDailyLimit;

      roundName = item.roundName;

      roundMode = item.roundMode;
    });
    getSlots(id, maxDailyLimit, roundName, roundMode);
  };

  // book slot new slot
  const bookslot = (slotId, roundId, date) => {
    var data = { isScheduled: inConfirm };
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      axios
        .patch(
          dEndPoints.slotBooking(
            corporateId,
            slotId,
            roundId,
            candidateProfileId
          ),
          data,
          {
            params: {
              date: date,
              maxlimit: limit,
            },
          }
        )
        .then((res) => {
          var bookingResponse = res.data;
          // after slot booking display the updated rounds data handleroundID
          props.showLoader({ show: false });
          props.getUpdatedInterviews();
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          getSlots(roundId, limit);
          setShow(false);
          setInConfirm(false);
          props.getInterviewRounds();
          getInterviewRounds();
          if (props.handleSelectedClose()) {
            props.handleSelectedClose();
          }
          if (bookingResponse.data.code === 100171) {
            props.showSnackBar({
              message: bookingResponse.data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          props.showLoader({ show: false });
          if (error.response.data.code === 100167) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else if (error.response.data.code === 100174) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    } else {
      const candidateProfileId = props.candidateId;
      axios
        .patch(
          dEndPoints.vendorSlotBooking(
            vendorCorporateId,
            slotId,
            roundId,
            candidateProfileId
          ),
          null,
          {
            params: {
              date: date,
              maxlimit: limit,
            },
          }
        )
        .then((res) => {
          var bookingResponse = res.data;
          // after slot booking display the updated rounds data handleroundID
          getSlots(roundId, limit);
          props.getUpdatedInterviews();
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          props.handleCloseSlotBooking();
          if (props.handleSelectedClose()) {
            props.handleSelectedClose();
          }
          getInterviewRounds();
          if (bookingResponse.data.code === 100171) {
            props.showSnackBar({
              message: bookingResponse.data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          props.showLoader({ show: false });
          if (error.response.data.code === 100167) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else if (error.response.data.code === 100174) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };
  // const RefreshCandidates = () => {
  //   props.getInterviewRounds();
  // };
  const changeInstantCheck = (e) => {
    setInstant(e.target.checked);
    setAvailableSlot(false);
  };

  const changeAvailableCheck = (e) => {
    setAvailableSlot(e.target.checked);
    props.getInterviewRounds();
    setInstant(false);
  };
  // const handleChange = (event) => {
  //   setSlotType(event.target.value);
  // };
  const changeNoticeCheck = (e) => {
    setInConfirm(e.target.checked);
  };

  return (
    <>
      <Button variant="success" onClick={() => setShow(true)}>
        {props.BtnName}
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal__filter-wrapper schadule__modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="border-0 text-center" closeButton>
          <Modal.Title className="w-100">Schedule Interview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="selectSlot__type-wrapper mt-3 mb-5 text-center">
              {/* ------------------------- */}
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="slotsBooking"
                  id="availableSlots"
                  checked={availableSlot}
                  onClick={changeAvailableCheck}
                />
                <label className="form-check-label" htmlFor="availableSlots">
                  Book From Available Slots
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="slotsBooking"
                  id="instantBookSlot"
                  checked={instant}
                  onChange={changeInstantCheck}
                />
                <label className="form-check-label" htmlFor="instantBookSlot">
                  Instant Schedule
                </label>
              </div>
            </div>
            {availableSlot === true ? (
              <Row className="mt-4">
                <Col md={12}>
                  {userType === "1100" || userType === "1110" ? (
                    <div className="modal__fileds">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="roundType">
                          Select Slots
                        </Form.Label>

                        <Form.Select
                          id="roundType"
                          value={roundId}
                          onChange={(e) => selectRound(e.target.value)}
                        >
                          <option>Select Slots</option>
                          {rounds && rounds.length > 0
                            ? rounds.map((interv) => (
                                <option key={interv._id} value={interv._id}>
                                  {" Name: "}
                                  {interv.roundName}
                                  {" Mode: "}
                                  {interv.roundMode}
                                  {" Type: "}
                                  {interv.roundType}
                                  {" Dates "}
                                  {new Date(
                                    interv.startDate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "short",
                                  })}{" "}
                                  -{" "}
                                  {new Date(interv.endDate).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      day: "numeric",
                                      month: "short",
                                    }
                                  )}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="text-center"></Form.Group>
                    </div>
                  ) : (
                    <div className="modal__fileds">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="roundType">
                          Select Slots
                        </Form.Label>

                        <Form.Select
                          id="roundType"
                          value={roundId}
                          onChange={(e) => selectRound(e.target.value)}
                        >
                          <option>Select Slots</option>
                          {rounds
                            ? rounds.map((interv) => (
                                <option key={interv._id} value={interv._id}>
                                  {" Name: "}
                                  {interv.roundName}
                                  {" Mode: "}
                                  {interv.roundMode}
                                  {" Type: "}
                                  {interv.roundType}
                                  {" Dates "}
                                  {new Date(
                                    interv.startDate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "short",
                                  })}{" "}
                                  -{" "}
                                  {new Date(interv.endDate).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      day: "numeric",
                                      month: "short",
                                    }
                                  )}
                                </option>
                              ))
                            : "no"}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )}
                </Col>
                {limit ? (
                  <Col md={12} className="text-center separator__tq">
                    <h3 className="bookingLimit">
                      Maximum booking limit per day : <strong> {limit}</strong>
                    </h3>
                  </Col>
                ) : (
                  <></>
                )}

                <Col xs={12} className="mt-4">
                  {slots && slots.length > 0 ? (
                    slots &&
                    slots.map((slot) => (
                      <div className="maxSlotLists">
                        <small className="my-4 d-block text-center">
                          <strong>Note :</strong> Please book the new interview
                          slot only after candidate’s availability confirmation.
                        </small>

                        <Form.Group xs={12} className="tq-cards mb-3">
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inConfirms"
                            name="tenPercent"
                            checked={inConfirm}
                            onClick={changeNoticeCheck}
                          />
                          <Form.Label htmlFor="inConfirms">
                            Is this round confirmed from candidate?
                          </Form.Label>
                          <small className="mt-2 d-block">
                            <strong>Note :</strong> Our vendors will not contact
                            candidate if you choose this option.
                          </small>
                        </Form.Group>
                        {Object.entries(slot).map(([key, value]) => (
                          <div className="slots__list-wrapper">
                            <div className="datesAvl">
                              {new Date(key).toLocaleDateString("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "short",
                              })}
                            </div>
                            <div className="row slots__listing">
                              {value.map((slotdata) => (
                                <span className="col-2 p-0 mb-2 d-block available__slots-card">
                                  {slotdata.maxSlot &&
                                  slotdata.maxSlot === limit &&
                                  userType === "1000" ? (
                                    <>
                                      {slotdata.date ? (
                                        <div className="available__slot-row text-center">
                                          {
                                            new Date(slotdata.sTime)
                                              .toTimeString()
                                              .split(":")[0]
                                          }
                                          :
                                          {
                                            new Date(slotdata.sTime)
                                              .toTimeString()
                                              .split(":")[1]
                                          }{" "}
                                          -
                                          {
                                            new Date(slotdata.eTime)
                                              .toTimeString()
                                              .split(":")[0]
                                          }
                                          :
                                          {
                                            new Date(slotdata.eTime)
                                              .toTimeString()
                                              .split(":")[1]
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {slotdata.date ? (
                                        <div className="available__slot-row">
                                          {slotdata.status ? (
                                            <span
                                            // style={stylingObject.status}
                                            >
                                              Status:{" "}
                                              <mark>{slotdata.status}</mark>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {slotdata.date ? (
                                        <div className="available__slot-row p-0">
                                          {slotdata.Candidate &&
                                          slotdata.Candidate.length > 0 ? (
                                            slotdata.Candidate.map(
                                              (candidate) => (
                                                <div className="slot__booked-for">
                                                  {candidate.name}
                                                </div>
                                              )
                                            )
                                          ) : slotdata.Candidate &&
                                            slotdata.Candidate.length === 0 ? (
                                            <span
                                              className="availability true"
                                              disabled={false}
                                              onClick={() =>
                                                bookslot(
                                                  slotdata._id,
                                                  slotdata.roundId,
                                                  slotdata.date
                                                )
                                              }
                                            >
                                              Schedule
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {slotdata.date ? (
                                        <div className="available__slot-row text-center">
                                          {
                                            new Date(slotdata.sTime)
                                              .toTimeString()
                                              .split(":")[0]
                                          }
                                          :
                                          {
                                            new Date(slotdata.sTime)
                                              .toTimeString()
                                              .split(":")[1]
                                          }{" "}
                                          -
                                          {
                                            new Date(slotdata.eTime)
                                              .toTimeString()
                                              .split(":")[0]
                                          }
                                          :
                                          {
                                            new Date(slotdata.eTime)
                                              .toTimeString()
                                              .split(":")[1]
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {slotdata.date ? (
                                        <div className="available__slot-row">
                                          {slotdata.status ? (
                                            <span>
                                              Status:{" "}
                                              <mark>{slotdata.status}</mark>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {slotdata.date ? (
                                        <div className="available__slot-row p-0">
                                          {slotdata.Candidate &&
                                          slotdata.Candidate.length > 0 ? (
                                            slotdata.Candidate.map(
                                              (candidate) => (
                                                <div className="slot__booked-for">
                                                  {candidate.name}
                                                </div>
                                              )
                                            )
                                          ) : slotdata.Candidate &&
                                            slotdata.Candidate.length === 0 ? (
                                            <>
                                              <span
                                                className="availability true"
                                                disabled={false}
                                                onClick={() =>
                                                  bookslot(
                                                    slotdata._id,
                                                    slotdata.roundId,
                                                    slotdata.date
                                                  )
                                                }
                                              >
                                                Schedule
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </span>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="text-center">No interview slots</div>
                  )}
                </Col>
                <Col md={12} className="d-flex justify-content-center mt-5">
                  <ButtonGroup>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                      Close
                    </Button>
                    <Button variant="primary">
                      <ScheduledModal
                        icon="bx bx-book-add"
                        BtnName="Add Interview Round and Slot"
                        jobId={jobId}
                        setShow={setShow}
                        getInterviewRounds={getInterviewRounds}
                        loadCandidatesDetails={props.loadCandidatesDetails}
                      />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            ) : instant === true ? (
              <InstantSlot
                jobId={jobId}
                setShow={setShow}
                getInterviewRounds={getInterviewRounds}
                loadCandidatesDetails={props.loadCandidatesDetails}
                candidateProfileId={props.candidateId}
              />
            ) : (
              ""
            )}
            {instant === false ? (
              <small className="mt-4 d-block text-center">
                <strong>Note :</strong> If Slots/Required Round are not
                available then you can create one by clicking on above button or
                choose Instant Schedule.
              </small>
            ) : (
              ""
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(BookSlotModal);
