import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";
import endPoints from "../../config/end-point";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import moment from "moment";
import TopNav from "../topnav/TopNav";
import SideNav from "../sidenav/SideNav";
import ActivateUser from "./ActivateUser";
import UserCardAdmin from "./allusers/UserCardAdmin";
const UserActivation = (props) => {
  //   const { classes } = props;
  const [info, setInfo] = useState([]);
  const [allInfo, setallInfo] = useState([]);
  const [tempUserList, settempUserList] = useState(true);
  const [userVerify, setuserVerify] = useState(false);
  const [active, setActive] = useState("");
  const [header, setHeader] = useState([
    {
      headerName: "User ID",
      field: "userName",
      filter: true,
      sortable: true,
      resizable: true,
      width: 250,
    },
    { headerName: "User Type", field: "UserType", width: 120, filter: true },
    { headerName: "Contact", field: "Contact", width: 180 },
    { headerName: "Status", field: "Status", filter: true, width: 150 },
    { headerName: "CreatedOn", field: "CreatedOn", width: 180 },
    { headerName: "Mobile Otp", field: "mobileOtp", width: 180 },
    { headerName: "Email Otp", field: "emailOtp", width: 180 },
    {
      headerName: "Activate  User",
      field: "User",
      width: 120,
      cellRendererFramework: function (params) {
        return (
          <Button
            variant="success table-btn "
            onClick={() => handleClick(params)}
          >
            {" "}
            Activate{" "}
          </Button>
        );
      },
    },

    {
      headerName: "Reject  User",
      field: "UserReject",
      width: 150,
      cellRendererFramework: function (params) {
        return (
          <Button
            variant="danger table-btn"
            onClick={() => handleClickUser(params)}
          >
            {" "}
            Reject{" "}
          </Button>
        );
      },
    },
  ]);

  const handleClick = (e, corporateConfig) => {
    const path = e.value ? e.value : "";
    props.showLoader({ show: true });
    axios
      .post(endPoints.activateUser, corporateConfig, {
        params: { userId: path },
      })
      .then((res) => {
        props.showLoader({ show: false });
        const handleName = () => {
          setActive(true);
        };
        var data = res.data;

        if (data.code === 100015) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          loadCorporate();
          return;
        }

        if (data.code === 100020) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        console.log("error in activate user", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100049
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100021
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100020
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };
  const handleClickUser = (e, userId) => {
    axios
      .get(endPoints.blockUser(userId))
      .then((res) => {
        var data = res.data;
        if (data.code === 100016) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          loadCorporate();
          return;
        }
      })
      .catch((error) => {
        console.log("error in Reject user", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100019
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  var users = info.map((user) => {
    var data = {
      userName: user.userName || "",
      UserType: user.userType || "",
      Contact: user.contact || "",
      Status: user.status || "",
      CreatedOn: user.createdOn || "",
      mobileOtp: user.mobileOtp || "",
      emailOtp: user.emailOtp || "",
      User: user._id ? user._id : {},
      UserReject: user._id ? user._id : {},
    };

    return data;
  });

  useEffect(() => {
    loadCorporate();
    loadAllusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadCorporate = () => {
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllTempUser)
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (response.data.code === 100126) {
          const resumes = data.data;
          setInfo(resumes);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };
  const loadAllusers = () => {
    var sort_createdon = -1;
    var pageNum = 1;
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllUser, {
        params: {
          pageNumber: pageNum,
          cOn: sort_createdon,
        },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (response.data.code === 100130) {
          const resumes = data.data;
          setallInfo(resumes);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };

  const tempUser = (e) => {
    settempUserList(e.target.checked);
    setuserVerify(false);
  };
  const changeVeryfy = (e) => {
    setuserVerify(e.target.checked);
    settempUserList(false);
  };
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <Row className="mb-5">
            <Col sm={3}>
              <label
                className={`status-card ${
                  tempUserList === true ? "active" : ""
                }`}
                htmlFor="jodTrue"
              >
                <input
                  type="radio"
                  checked={tempUserList}
                  onChange={tempUser}
                  name="jobListing"
                  id="jodTrue"
                  className="opacity-0"
                />
                <span className="status-card__info">
                  <h3>{info.length}</h3>
                  <span className="f-600">Temporary User</span>
                </span>
              </label>
            </Col>
            <Col sm={3}>
              <label
                className={`status-card ${userVerify === true ? "active" : ""}`}
                htmlFor="jobVerification"
              >
                <input
                  type="radio"
                  checked={userVerify}
                  onClick={changeVeryfy}
                  id="jobVerification"
                  name="jobListing"
                  className="opacity-0"
                />
                <span className="status-card__info">
                  <h3>{allInfo.length}</h3>
                  <span className="f-600">All Active Users</span>
                </span>
              </label>
            </Col>
          </Row>
          {tempUserList === true ? (
            <div className="user__activation-page">
              <div className="tq__page-header d-block text-center mb-5">
                <h3 className="page-header">
                  <span className="text-warning">Temporary</span>&nbsp;
                  <span className="text-muted">Users</span>
                </h3>
              </div>
              <Row>
                {console.log("temp User data", info)}
                {info.map((item, index) => (
                  <Col xs={4} key={index} className="mb-5">
                    <UserCardAdmin
                      titleName={item.userName}
                      userType={item.userType}
                      accountStatus={item.status}
                      cOn={moment(new Date(item.createdOn)).fromNow()}
                      contact={item.contact}
                      createdOn={item.createdOn}
                      reject={() => handleClickUser()}
                      approve="no"
                      eotp={item.emailOtp}
                      motp={item.mobileOtp}
                    />
                  </Col>
                ))}
              </Row>
              <div
                className="ag-theme-balham"
                style={{
                  height: "550px",
                  width: "100%",
                  fontSize: "13px",
                }}
              >
                <AgGridReact
                  pagination={true}
                  columnDefs={header}
                  rowData={users}
                ></AgGridReact>
              </div>
              {/* <ActivateUser /> */}
            </div>
          ) : (
            ""
          )}
          {userVerify === true ? (
            <div className="user__activation-page">
              <ActivateUser />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(UserActivation);
