import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import CandidateSummary from "./candidateDetails/CandidateSummary";
import Analytics from "./candidateDetails/Analytics";
import Assignments from "./candidateDetails/Assignments";
import Comments from "./candidateDetails/Comments";
import ResumeView from "./candidateDetails/ResumeView";
import RefreshIcon from "@material-ui/icons/Refresh";
// import Card from "../../common/mataerialKit/components/Card/Card.jsx";
// import { Link } from "@material-ui/core";
import CandidateFilters from "../badge/CandidateFilters";
import FileSaver from "file-saver";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";
import { dEndPoints } from "../../config/end-point";
// import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import CandidateCards from "./candidateDetails/CandidateCards";
import RecommendResume from "./candidateDetails/RecommendResume";
import CandidateRecommendSummary from "./candidateDetails/CandidateRecommendSummary";
import { Button, Modal } from "react-bootstrap";
// import CandidateInterviews from "./candidateDetails/CandidateInterviews";
import InterviewDetails from "../interview/InterviewDetails";
import { useParams } from "react-router";
const style = makeStyles((theme) => ({
  source: {
    fontSize: "10px",
    color: "blue",
    marginBottom: "10px !important",
    marginTop: "-7px !important",
    textAlign: "center",
    paddingLeft: "5px",
  },
}));

//candicate tab component
const CandidateTab = (props) => {
  // const [pageNum, setPageNum] = useState(1);
  const usestyle = style();
  const userId = localStorage.getItem("i");
  var date1 = new Date();
  var date2 = new Date(props.lastUpdated);
  var date3 = new Date(props.createdAt);
  var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
  // d.setDate(d.getDate() - 2);
  // console.log("diffDays", d);
  var diffOld = parseInt((date1 - date3) / (1000 * 60 * 60 * 24), 10);
  var finalDay = diffDays > 7;
  var finalDayTwo = diffOld < 4;
  //  var getDayDiff = props.finalDay;

  const userType = localStorage.getItem("ut");
  return (
    <>
      <div className="candidate__tab-wrapper">
        {userType === "1110" ? (
          <p
            className={usestyle.source}
            title={`Name:  ${props.vName ? props.vName : "NA"},  contact:  ${
              props.vContact ? props.vContact : "NA"
            }`}
          >
            Source : {props.vCName ? props.vCName : "NA"}
            <ContactSupportOutlinedIcon fontSize="small" />
          </p>
        ) : null}
        <div className="candidate__status-list">
          {/* <strong>Experience : </strong> */}
          {props.interviewStatus ? (
            <Tooltip title="Interview Status" placement="bottom">
              <span
                className={`candidate__status ml-0 bg-${props.interviewStatus}`}
              >
                {props.interviewStatus}
              </span>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip title="Candidate Status" placement="bottom">
            <span className={`candidate__status bg-${props.BadgeStatus}`}>
              {props.BadgeStatus}
            </span>
          </Tooltip>
        </div>
        <div className="candidate__name">
          <i className="bx bxs-user-circle"></i>{" "}
          <span className="title">{props.CandidateName}</span>
        </div>
        <div className="candidate__details-tab">
          <strong>Skills : </strong>
          <span>{String(props.Skills).substring(0, 50)}</span>
        </div>
        <div className="candidate__details-tab">
          <strong>Experience : </strong>
          <span>{props.Experience}</span>
        </div>
        {(userType === "1100" || userType === "1110") &&
        props.viewed.includes(userId) === false &&
        finalDayTwo === true ? (
          <div className="new__tag">
            <span className="blink">New </span>
          </div>
        ) : (
          ""
        )}
        {props.Score ? (
          <div className="candidate__details-tab">
            <strong>Overall Score : </strong>

            {props.Score < 40 ? (
              <mark className="red">{props.Score}</mark>
            ) : props.Score <= 60 ? (
              <mark className="yellow">{props.Score}</mark>
            ) : (
              <mark className="green">{props.Score}</mark>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="candidate__details-tab cd__status">
          <strong className="created__by">
            Created :{" "}
            <span className="status__wrapper-cd">
              <Tooltip
                title={
                  // props.createdAt ? moment(new Date(props.createdAt)).fromNow() : ""
                  props.createdAt
                    ? `Uploaded - ${moment(
                        new Date(props.createdAt)
                      ).fromNow()}`
                    : ""
                }
                placement="bottom"
              >
                <span className="badge__cd uploadedOn">
                  <span className="m-0">
                    {props.createdAt
                      ? moment(new Date(props.createdAt)).fromNow()
                      : ""}
                  </span>
                </span>
              </Tooltip>
            </span>
          </strong>
        </div>
        {props.lastUpdated ? (
          <div className="candidate__details-tab cd__status">
            <strong className="updated__by">
              Updated :{" "}
              <span className="status__wrapper-cd">
                {props.lastUpdated ? (
                  <>
                    &nbsp;
                    <Tooltip
                      title={
                        props.lastUpdated
                          ? `Last Update - ${moment(
                              new Date(props.lastUpdated)
                            ).fromNow()}`
                          : ""
                      }
                      placement="bottom"
                    >
                      <span className="badge__cd lastUpdated">
                        <span>
                          {props.lastUpdated
                            ? moment(new Date(props.lastUpdated)).fromNow()
                            : ""}
                        </span>
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
                {(userType === "1100" || userType === "1110") &&
                finalDay === true ? (
                  <Tooltip title="Overdue" placement="bottom">
                    <span className="badge__cd overdue_cd">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/overdue.svg"
                        alt="overdue"
                        className="img-fluid"
                      />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </span>
            </strong>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const CandidateRecommendTab = (props) => {
  // const [pageNum, setPageNum] = useState(1);
  return (
    <div className="candidate__tab-wrapper">
      <div className="candidate__status-list">
        {/* <strong>Experience : </strong> */}
        {props.BadgeStatus ? (
          <span className={`candidate__status bg-${props.BadgeStatus}`}>
            {props.BadgeStatus}
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="candidate__name">
        <i className="bx bxs-user-circle"></i>{" "}
        <span className="title">{props.CandidateName}</span>
      </div>
      <div className="candidate__details-tab">
        <strong>Skills : </strong>
        <span>{String(props.Skills).substring(0, 50)}</span>
      </div>
      <div className="candidate__details-tab">
        <strong>Experience : </strong>
        <span>{props.Experience}</span>
      </div>
      {props.Score ? (
        <div className="candidate__details-tab">
          <strong>Overall Score : </strong>
          <mark>{props.Score}</mark>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
const BrowseCandidate = (props) => {
  const [value, setValue] = useState("candidate_sum");
  // const [commentValue, setCommentValue] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //  const [openFilter, setOpenFilter] = useState(false);
  const [candiId, setCandiId] = useState(props.activeCandidateId);
  const [show, setShow] = useState(false);

  let { jobId } = useParams();
  // const DownloadAllReumes = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClickOpen = () => {
  //   setOpenFilter(!openFilter);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setCandiId(props.activeCandidateId);
  });
  // useEffect(() => {

  // }, []);
  var date = new Date();
  const userType = localStorage.getItem("ut");
  //const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  const downloadResumes = (status) => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      const jobId = props.job._id;
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.downloadCorpAllResumes(corporateId, jobId), {
          responseType: "arraybuffer",
          params: {
            status: status,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          handleClose();
          var blob = new Blob([res.data], { type: "octet/stream" });
          var fileName = `${props.job.desig}-${date.toLocaleDateString()}.zip`;
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      const jobId = props.job._id;
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorDownloadAllResumes(vendorCorporateId, jobId), {
          responseType: "arraybuffer",
          params: {
            status: status,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          handleClose();
          var blob = new Blob([res.data], { type: "octet/stream" });
          var fileName = `${props.job.desig}-${date.toLocaleDateString()}.zip`;
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          console.log("error", error);
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  };
  const getViewCandidateUpdate = (candidateId) => {
    // const jobId = props.job._id;
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      //var jobId = props.candidate.jobId;

      props.showLoader({ show: true });
      axios
        .get(dEndPoints.updateCandidateView(corporateId, jobId, candidateId))
        .then((res) => {
          props.showLoader({ show: false });
          //   let data = res.data;

          // if (data && data.code === 100134) {
          //   setCandidateRecommendInfo(data.data);
          //   setTotalCount(data.data.length);
          //   setRecommendCount(Math.ceil(data.data.length / 20));
          //   const firstCandidateId = data.data[0].candId;

          //   setCandidateId(firstCandidateId);
          // }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const getCommentUpdate = () => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      //var jobId = props.candidate.jobId;

      props.showLoader({ show: true });
      axios
        .get(dEndPoints.updateComment(corporateId, jobId, candiId))
        .then((res) => {
          props.showLoader({ show: false });
          //   let data = res.data;
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      axios
        .get(
          dEndPoints.updateCommentFromVendor(vendorCorporateId, jobId, candiId)
        )
        .then((res) => {
          props.showLoader({ show: false });

          // let data = res.data;
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  if (value === "comments") {
    getCommentUpdate();
  }
  const downloadTrackerApi = (status) => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      const jobId = props.job._id;
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.downloadTracker(corporateId, jobId), {
          params: {
            stat: status,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          handleClose();
          const csv = res.data;
          const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          FileSaver.saveAs(
            csvData,
            `${props.job.desig}-${date.toLocaleDateString()}.csv`
          );
        })
        .catch((error) => {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      const jobId = props.job._id;
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.downloadVendorTracker(vendorCorporateId, jobId), {
          params: {
            stat: status,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          handleClose();
          const csv = res.data;
          const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          FileSaver.saveAs(
            csvData,
            `${props.job.desig}-${date.toLocaleDateString()}.csv`
          );
        })
        .catch((error) => {
          console.log("error", error);
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  };

  const RefreshCandidates = () => {
    props.loadCandidatesDetails();
  };
  const RefreshRecommendCandidate = () => {
    props.loadCandidatesRecommendedDetails();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var candidate = {};
  var canId = "";

  if (props.candidates) {
    props.candidates.forEach(function (can, i) {
      if (props.activeCandidateId === can._id) {
        candidate = can;
        canId = can._id;
      }
    });
  }

  var candidateRecomm = {};

  if (props.Recommcandidates) {
    props.Recommcandidates.forEach(function (can, i) {
      if (props.activeCandidateId === can.candId) {
        candidateRecomm = can;
      }
    });
  }

  function clickDashboardRequirement(cid) {
    props.handlecandidateID(cid);
    // cid.preventDefault();
  }
  const resetResults = (clear) => {
    // statues = [];
    // locations = [];
    // companies = [];
    // score = [];
    // interviewStatus = [];
    props.loadCandidatesDetails("", "", "", "", "", clear);
    props.loadCandidatesRecommendedDetails("", "", "", clear);
  };
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  var getCandId = getParameterByName("candId");
  const userId = localStorage.getItem("i");
  const name = localStorage.getItem("name");
  // comment blink logic

  let checkCommentLength =
    candidate.comment && candidate.comment.filter((item) => item.by !== name);
  let myCommentLength =
    candidate.comment &&
    candidate.comment.filter(
      (item) =>
        item.by !== name && (item.viewed ? !item.viewed.includes(userId) : [])
    );

  // const checkCandTab = (e) => {
  //   setCandTab(e.target.checked);
  //   setRecTab(false);
  // };
  // const checkActTab = (selectCandidate) => {
  //   setActCanTab(selectCandidate);
  // };
  let checkIDTab = props.activeCandidateId;
  let checkIDTabRecom = props.activeRecomCandidateId;

  return (
    <>
      <CandidateCards facets={props.childfacets} className="my-4" />
      <section className="candidate__tabs-section">
        {userType === "1110" || userType === "1100" ? (
          <div
            className={
              userType === "1110"
                ? "interview_availability-wrapper sadmin"
                : "interview_availability-wrapper"
            }
          >
            <Button variant="info text-white" onClick={() => setShow(true)}>
              <i className="bx bxs-pie-chart-alt-2"></i>&nbsp;Available Slot
            </Button>
          </div>
        ) : (
          ""
        )}
        <div
          className={getCandId ? "filter__wrapper w-510" : "filter__wrapper"}
        >
          {getCandId ? (
            <Tooltip title="View All Candidates" placement="bottom">
              <span className="refresh__candidate" onClick={resetResults}>
                <i className="bx bxs-notepad"></i> &nbsp;Show All Candidates
              </span>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip title="Refresh" placement="bottom">
            <span className="refresh__candidate" onClick={RefreshCandidates}>
              <RefreshIcon /> Refresh
            </span>
          </Tooltip>
          <CandidateFilters
            icon="bx bx-slider"
            BtnName="Filter"
            facets={props.childfacets}
            loadCandidatesDetails={props.loadCandidatesDetails}
            loadCandidatesRecommendedDetails={
              props.loadCandidatesRecommendedDetails
            }
          />
          &nbsp;
          <DropdownButton
            variant="success download__dropdown"
            title={
              <>
                <i className="bx bxs-download"></i> &nbsp;
                <span>Download</span>
              </>
            }
          >
            <Dropdown.Item
              href="#"
              onClick={() => downloadTrackerApi("Pending")}
            >
              Download Tracker for pending profiles
            </Dropdown.Item>

            <Dropdown.Item href="#" onClick={() => downloadTrackerApi("")}>
              Download Tracker for all profiles
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#" onClick={() => downloadResumes("Pending")}>
              Download pending resume
            </Dropdown.Item>
            <Dropdown.Item onClick={() => downloadResumes("")} href="#">
              Download all resumes
            </Dropdown.Item>
          </DropdownButton>
        </div>
        {props.candidates.length > 0 ? (
          <Tabs
            defaultActiveKey="home"
            transition={false}
            id="candidate__tab"
            className="mb-3 candidate__tab-wrap"
          >
            <Tab
              eventKey="home"
              title={
                <span
                  className="d-flex align-items-center"
                  onClick={RefreshCandidates}
                >
                  <i className="bx bxs-user-pin"></i>&nbsp;Candidates
                </span>
              }
            >
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={props.activeCandidateId}
              >
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="d-block tab-scroll-y">
                      {/* props.candidates */}
                      {props.candidates.map((candidate, i) => (
                        <Nav.Item key={i} className="candidate__tab-list">
                          <Nav.Link
                            className={
                              checkIDTab === candidate._id ? "active" : ""
                            }
                            eventKey={candidate._id}
                            onClick={() => {
                              clickDashboardRequirement(candidate._id);
                              setCandiId(candidate._id);
                              getViewCandidateUpdate(candidate._id);
                            }}
                          >
                            <CandidateTab
                              BadgeStatus={candidate.stat}
                              CandidateName={candidate.name}
                              Score={
                                candidate.total ? candidate.total.toFixed() : 50
                              }
                              Experience={candidate.expr}
                              Skills={candidate.skills.slice(0, 5)}
                              loadCandidatesDetails={
                                props.loadCandidatesDetails
                              }
                              canCount={props.canCount}
                              interviewStatus={
                                candidate && candidate.candidateSlots.length > 0
                                  ? candidate.candidateSlots[
                                      candidate.candidateSlots.length - 1
                                    ].status
                                  : ""
                              }
                              vContact={
                                candidate.vContact ? candidate.vContact : "NA"
                              }
                              vCName={
                                candidate.vCName ? candidate.vCName : "NA"
                              }
                              vName={candidate.vName ? candidate.vName : "NA"}
                              createdAt={candidate.cOn ? candidate.cOn : ""}
                              lastUpdated={
                                candidate.updatedOn ? candidate.updatedOn : ""
                              }
                              viewed={candidate.viewed ? candidate.viewed : []}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey={candidate._id}
                        className={
                          checkIDTab === candidate._id ? "active show" : ""
                        }
                      >
                        <Tabs
                          value={value}
                          onSelect={(k) => setValue(k)}
                          defaultActiveKey="candidate_sum"
                          transition={false}
                          id="noanim-tab-example"
                          className="mb-3 tab__content-header"
                        >
                          <Tab
                            eventKey="candidate_sum"
                            title={
                              <span className="d-flex align-items-center">
                                <i className="bx bxs-food-menu"></i>{" "}
                                &nbsp;Candidate Summary
                              </span>
                            }
                          >
                            <CandidateSummary
                              canId={canId ? canId : ""}
                              jobId={candidate.jobId ? candidate.jobId : ""}
                              candidate={candidate}
                              loadCandidatesDetails={
                                props.loadCandidatesDetails
                              }
                              job={props.job}
                            />
                          </Tab>
                          <Tab
                            eventKey="resumeview"
                            title={
                              <span className="d-flex align-items-center">
                                <i className="bx bxs-receipt"></i> &nbsp;View
                                Resume{" "}
                              </span>
                            }
                          >
                            <ResumeView resume={candidate.rd} />
                          </Tab>

                          <Tab
                            eventKey="comments"
                            title={
                              <span className="position-relative comments_tab d-flex align-items-center">
                                <i className="bx bxs-message-square-dots"></i>
                                &nbsp;Comments
                                {checkCommentLength !== myCommentLength &&
                                myCommentLength.length ? (
                                  <span className="blinking__notice"></span>
                                ) : (
                                  ""
                                )}
                              </span>
                            }
                          >
                            <Comments
                              comments={candidate.comment}
                              candidate={candidate}
                              loadCandidatesDetails={
                                props.loadCandidatesDetails
                              }
                            />
                          </Tab>
                          <Tab
                            eventKey="analytics"
                            title={
                              <span className="d-flex align-items-center">
                                {" "}
                                <i className="bx bxs-bar-chart-alt-2"></i>
                                &nbsp;Analytics
                              </span>
                            }
                          >
                            <Analytics
                              activeCandidateAnalytics={
                                props.activeCandidateAnalytics
                              }
                              activeCandidateId={props.activeCandidateId}
                              job={props.job}
                            />
                          </Tab>
                          <Tab
                            eventKey="assignment"
                            title={
                              <span className="d-flex align-items-center">
                                <i className="bx bxs-layer"></i>{" "}
                                &nbsp;Assignment
                              </span>
                            }
                          >
                            <Assignments
                              candidate={candidate}
                              loadCandidatesDetails={
                                props.loadCandidatesDetails
                              }
                            />
                          </Tab>
                        </Tabs>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Tab>

            {userType === "1110" || userType === "1000" ? (
              <Tab
                eventKey="profile"
                title={
                  <span
                    className="d-flex align-items-center"
                    onClick={RefreshRecommendCandidate}
                  >
                    <i className="bx bxs-id-card"></i>&nbsp;Recommended
                  </span>
                }
                className="recomended__tab"
              >
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={props.activeRecomCandidateId}
                >
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="tab-scroll-y">
                        {props.Recommcandidates &&
                          props.Recommcandidates.map((candidateRecomm, i) => (
                            <Nav.Item className="candidate__tab-list">
                              <Nav.Link
                                eventKey={candidateRecomm.candId}
                                className={
                                  checkIDTabRecom === candidateRecomm.candId
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  clickDashboardRequirement(
                                    candidateRecomm.candId
                                  );
                                }}
                              >
                                <CandidateRecommendTab
                                  BadgeStatus={candidateRecomm.stat}
                                  CandidateName={candidateRecomm.name}
                                  Score={
                                    candidateRecomm.total
                                      ? candidateRecomm.total.toFixed()
                                      : 50
                                  }
                                  Experience={candidateRecomm.expr}
                                  Skills={candidateRecomm.skills.slice(0, 5)}
                                  loadCandidatesRecommendedDetails={
                                    props.loadCandidatesRecommendedDetails
                                  }
                                  recommendCount={props.recommendCount}
                                />
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      {candidateRecomm.candId ? (
                        <Tab.Content>
                          <Tab.Pane
                            eventKey={candidateRecomm.candId}
                            className={
                              checkIDTabRecom === candidateRecomm.candId
                                ? "active show"
                                : ""
                            }
                          >
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              defaultActiveKey="candidate_sum1"
                              transition={false}
                              id="noanim-tab-example"
                              className="mb-3 tab__content-header justify-content-start"
                            >
                              <Tab
                                eventKey="candidate_sum1"
                                title="Candidate Summary"
                              >
                                <CandidateRecommendSummary
                                  candidate={candidateRecomm}
                                  loadCandidatesRecommendedDetails={
                                    props.loadCandidatesRecommendedDetails
                                  }
                                />
                              </Tab>
                              <Tab
                                eventKey="recommedresume"
                                title="View Resume"
                              >
                                <RecommendResume resume={candidateRecomm.rd} />
                              </Tab>
                            </Tabs>
                          </Tab.Pane>
                        </Tab.Content>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Tab.Container>
              </Tab>
            ) : (
              ""
            )}
          </Tabs>
        ) : (
          " "
        )}
      </section>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal__filter-wrapper"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Create/View Interview Rounds/Slots
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InterviewDetails jobId={props.job._id} />
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(BrowseCandidate);
