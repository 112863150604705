import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import PropTypes from "prop-types";

import axios from "axios";
import { connect } from "react-redux";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { dEndPoints } from "../../config/end-point";
// import PhoneInput from "react-phone-input-2";
const EditUserPanel = (props) => {
  const [editUserInfo, setEditUserInfo] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    status: false,
    contact: "",
    userAccess: false,
  });
  const [validated, setValidated] = useState(false);
  const [Formerrors, setFormError] = useState({
    userAccessError: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    contactError: "",
    // submitClikced: false,
  });
  // const [phone, setPhone] = useState(null);
  // const removeEmptySpaces = (stringVal) => {
  //   var getValue = /\s/g.test(stringVal);

  //   if (getValue === true) {
  //     props.showSnackBar({
  //       message: "Please Remove Spacing.",
  //       open: true,
  //       type: "error",
  //       vertical: "top",
  //       horizontal: "center",
  //     });
  //     return;
  //   }
  // };
  const handleInputChange = (e) => {
    setEditUserInfo({ ...editUserInfo, [e.target.id]: e.target.value });
    if (e.target.id === "userAccess") {
      setEditUserInfo({ ...editUserInfo, [e.target.id]: e.target.checked });
    }
    if (e.target.id === "status") {
      setEditUserInfo({ ...editUserInfo, [e.target.id]: e.target.checked });
    }
    //const isValid = removeEmptySpaces(e.target.value);
  };

  useEffect(() => {
    const dupEditableUser = { ...props.editableUser };

    dupEditableUser.userAccess =
      dupEditableUser.userAccess === "Admin" ? true : false;
    delete dupEditableUser.userId;
    delete dupEditableUser._id;
    setEditUserInfo(dupEditableUser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidateVendor = () => {
    if (!editUserInfo.firstName || editUserInfo.firstName === "") {
      Formerrors.firstNameError = "Please Enter First Name";
    }
    if (!editUserInfo.lastName || editUserInfo.lastName === "") {
      Formerrors.lastNameError = "Please Enter Last Name";
    }
    if (!editUserInfo.userName) {
      Formerrors.emailError = "Please Enter Email ID";
    }

    if (editUserInfo.contact.length < 10) {
      Formerrors.contactError = "Contact No. must be 10 digits";
    }
  };

  const userType = localStorage.getItem("ut");
  const updateUser = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // console.log("event", form.checkValidity());
    checkValidateVendor(event);
    setValidated(true);
    const vendorCorporateId = localStorage.getItem("vci");
    //const corporateId = cacheData ? cacheData.ci : "";
    const corporateId = localStorage.getItem("ci");
    const userId = props.editableUser._id;
    const userData = { ...editUserInfo };
    userData.userAccess = userData.userAccess ? "Admin" : "Recruiter";
    userData.status = userData.status ? "Active" : "Inactive";
    if (userType === "1110" || userType === "1100") {
      if (userData.firstName && userData.lastName && userData.userAccess) {
        axios
          .patch(dEndPoints.updateUser(corporateId, userId), userData)
          .then((response) => {
            const data = response.data;
            if (data.code === 100000) {
              props.showSnackBar({
                message: data.data.message,
                open: true,
                type: "success",
              });
              props.handleClose();
              props.loadUser();
            }
          })
          .catch((error) => {});
      }
    } else {
      if (userData.firstName && userData.lastName && userData.userAccess) {
        axios
          .patch(
            dEndPoints.updateVendorUser(vendorCorporateId, userId),
            userData
          )
          .then((response) => {
            const data = response.data;
            if (data.code === 100000) {
              props.showSnackBar({
                message: data.data.message,
                open: true,
                type: "success",
              });
              props.handleClose();
              props.loadUser();
            }
          })
          .catch((error) => {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          });
      }
    }
  };
  const handleDisable = () => {
    //const corporateId = cacheData ? cacheData.ci : "";
    const vendorCorporateId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    const userId = props.editableUser._id;
    const userData = { ...editUserInfo };
    userData.userAccess = userData.userAccess ? "Admin" : "Recruiter";
    if (userType === "1110" || userType === "1100") {
      axios
        .delete(dEndPoints.disableUser(corporateId, userId))
        .then((res) => {
          var data = res.data;
          if (data && data.code === 100064) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
            });
            props.handleClose();
            props.loadUser();
          }
        })
        .catch((error) => {
          console.log(error, "An error occured");
        });
    } else {
      axios
        .delete(dEndPoints.disableVendorUser(vendorCorporateId, userId))
        .then((res) => {
          var data = res.data;
          if (data && data.code === 100064) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
            });
            props.handleClose();
            props.loadUser();
          }
        })
        .catch((error) => {
          console.log(error, "An error occured");
        });
    }
  };
  const dupEditableUser = { ...props.editableUser };

  return (
    <div>
      <Modal
        dialogClassName="tq__modal-default"
        show={props.open}
        onHide={props.handleClose}
      >
        <Modal.Header className="border-0 justify-content-center" closeButton>
          <Modal.Title className="w-100 text-center">
            Edit Co-Owners
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form text-left">
          <Row>
            <Form.Group className="mb-4" as={Col} xs={6}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                id="firstName"
                type="text"
                onChange={handleInputChange}
                value={editUserInfo.firstName}
                placeholder="First Name"
              />
              <p className="text-danger">
                {!editUserInfo.firstName ? Formerrors.firstNameError : ""}
              </p>
            </Form.Group>
            <Form.Group className="mb-4" as={Col} xs={6}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                id="lastName"
                type="text"
                onChange={handleInputChange}
                value={editUserInfo.lastName}
                placeholder="Last Name"
              />
              <p className="text-danger">
                {!editUserInfo.lastName ? Formerrors.lastNameError : ""}
              </p>
            </Form.Group>
            <Form.Group className="mb-4" as={Col} xs={6}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                id="userName"
                type="email"
                onChange={handleInputChange}
                value={editUserInfo.userName}
                placeholder="example@domain.com"
              />
              <p className="text-danger">
                {!editUserInfo.userName ? Formerrors.emailError : ""}
              </p>
            </Form.Group>
            <Form.Group className="mb-4" as={Col} xs={6}>
              <Form.Label>Contact</Form.Label>
              <Form.Control
                id="contact"
                maxLength="12"
                type="text"
                onChange={handleInputChange}
                value={editUserInfo.contact}
                placeholder="919XXXXX88"
              />
              <p className="text-danger">
                {editUserInfo.contact.length < 12
                  ? Formerrors.contactError
                  : ""}
              </p>
            </Form.Group>
            <Form.Group
              className="mb-4 py-3 text-center bg-light"
              as={Col}
              xs={12}
            >
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={editUserInfo.userAccess}
                  id="userAccess"
                  //name="inlineRadioOptions"
                  value="Admin"
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="adminRdo">
                  Admin
                </label>
              </div>
              <p className="text-danger">
                {!editUserInfo.userAccess ? Formerrors.userAccessError : ""}
              </p>
            </Form.Group>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center mt-5">
              <ButtonGroup>
                <Button
                  // className="tq-reset"
                  variant="secondary"
                  size="sm"
                  onClick={props.handleClose}
                >
                  cancel
                </Button>
                {dupEditableUser.status === "Active" ? (
                  <Button variant="danger" size="sm" onClick={handleDisable}>
                    Disable
                  </Button>
                ) : (
                  ""
                )}
                <Button variant="primary" size="sm" onClick={updateUser}>
                  Update
                </Button>
                {dupEditableUser.status === "Inactive" ? (
                  <Button variant="success" size="sm" onClick={updateUser}>
                    Enable
                  </Button>
                ) : (
                  ""
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
EditUserPanel.propTypes = {
  classes: PropTypes.object,
};
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(EditUserPanel);
