import React, { useState } from "react";

import PropTypes from "prop-types";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Button from "react-bootstrap/Button";
import EditUserPanel from "./EditUserPanel";

const Users = (props) => {
  //const { classes } = props;
  const [open, setOpen] = useState(false);
  const [editableUser, setEditableUser] = useState({});

  const [header, setHeader] = useState([
    {
      headerName: "Full Name",
      field: "Name",
      filter: true,
      sortable: true,
      width: 270,
    },
    { headerName: "User Name", field: "Email", filter: true, width: 300 },
    { headerName: "User", field: "User" },
    { headerName: "Status", field: "Status" },
    { headerName: "Contact", field: "Contact", width: 270 },
    {
      headerName: "Edit",
      field: "Edit",
      width: 240,
      cellRendererFramework: function (params) {
        return (
          <Button
            simple
            size="sm"
            color="info"
            onClick={() => handleClickOpen(params)}
          >
            Edit
          </Button>
        );
      },
    },
  ]);
  var users = props.usersInfo.map((user) => {
    var data = {
      Name:
        user.firstName && user.lastName
          ? user.firstName + " " + user.lastName
          : "" || "",
      Email: user.userName || "",
      User: user.userAccess || "",
      Status: user.status || "",
      Contact: user.contact || "",
      user: user,
    };
    return data;
  });

  const handleClickOpen = (user) => {
    setEditableUser(user.data.user);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className="ag-theme-balham"
        style={{
          height: "550px",
          width: "100%",
          fontSize: "13px",
        }}
      >
        <AgGridReact columnDefs={header} rowData={users}></AgGridReact>
      </div>

      {open ? (
        <EditUserPanel
          open={open}
          handleClose={handleClose}
          editableUser={editableUser}
          loadUser={props.loadUser}
        />
      ) : (
        ""
      )}
    </>
  );
};

Users.propTypes = {
  classes: PropTypes.object,
};
export default Users;
