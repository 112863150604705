import { AgGridReact } from "ag-grid-react";
import React, { useState, useEffect } from "react";
import trackEvent from "../../utils/analytics-manager";
import axios from "axios";
import endPoints, { dEndPoints } from "../../config/end-point";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";
import TopNav from "../topnav/TopNav";
import Tooltip from "@material-ui/core/Tooltip";
import SideNav from "../sidenav/SideNav";
import moment from "moment";
import JobCardAdmin from "./allusers/JobCardAdmin";
import { Form } from "react-bootstrap";
const ApproveJob = (props) => {
  const [allJobsListing, setallJobsListing] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputAll, setSearchInputAll] = useState("");
  const [jodTrue, setjodList] = useState(false);
  const [jobVerification, setjobVerification] = useState(true);
  const [info, setInfo] = useState([]);
  const [openJobs, setOpenJobs] = useState([]);

  var jobSet = 1;
  var jobUnset = 0;
  var uploadTrue = true;
  var uploadFalse = false;
  const [header, setHeader] = useState([
    {
      headerName: "Detailed Job",
      field: "JobID",
      width: 140,
      cellRendererFramework: function (params) {
        return (
          <Button
            variant="secondary table-btn"
            onClick={() => handleJobEditClick(params)}
          >
            Edit{" "}
          </Button>
        );
      },
    },
    { headerName: "Req ID", field: "ReqId", width: 200, filter: true },
    {
      headerName: "Status",
      field: "Status",
      width: 180,
      filter: true,
      valueGetter: fullNameGetter,
    },
    {
      headerName: "Company Name",
      field: "Company",
      width: 170,
      filter: true,
      resizable: true,
    },
    { headerName: "Job Type", field: "JobType", width: 120, filter: true },
    {
      headerName: "Designation",
      field: "Designation",
      filter: true,
      width: 180,
    },
    { headerName: "City", field: "City", filter: true, resizable: true },
    {
      headerName: "Primary Skills",
      field: "Primary",
      filter: true,
      resizable: true,
    },
    {
      headerName: "Activate",
      field: "Job",
      width: 140,
      cellRendererFramework: function (params) {
        if (params.value) {
          return (
            <Tooltip title="Please check the payment term and vendor rate added to the job before approval">
              <Button
                variant="success table-btn"
                onClick={() => handleClick(params)}
              >
                {" "}
                Approve{" "}
              </Button>
            </Tooltip>
          );
        }
      },
    },
    {
      headerName: "Reject",
      field: "RejectJob",
      width: 140,
      cellRendererFramework: function (params) {
        if (params.value) {
          return (
            <Button
              variant="danger table-btn"
              onClick={(e) => {
                handleClickJob(params);
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              {" "}
              Reject{" "}
            </Button>
          );
        }
      },
    },
  ]);
  const [openJobsHeader, setOpenJobsHeader] = useState([
    {
      headerName: "Upload Resume",
      field: "JobId",
      width: 160,
      align: "center",
      cellRendererFramework: function (params) {
        return (
          <div>
            <Button
              variant="danger table-btn"
              onClick={() => disableResumeUpload(params)}
              style={{ padding: "1px" }}
            >
              Disable{" "}
            </Button>
            <Button
              variant="primary table-btn"
              onClick={() => enableResumeUpload(params)}
              style={{ padding: "1px" }}
            >
              Enable{" "}
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "Set Job of day",
      field: "JobId",
      width: 140,
      align: "center",
      cellRendererFramework: function (params) {
        return (
          <div>
            <Button
              variant="secondary table-btn"
              onClick={() => unsetJoboftheday(params)}
            >
              Unset
            </Button>
            /{" "}
            <Button
              variant="success table-btn"
              onClick={() => setJoboftheday(params)}
            >
              Set{" "}
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "Req Id",
      field: "ReqId",
      width: 200,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Job of day",
      field: "jobOfTheDay",
      width: 100,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Posted On",
      field: "PostedOn",
      width: 105,
      filter: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Company",
      field: "Company",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Designation",
      field: "Designation",
      width: 130,
      filter: true,
      resizable: true,
    },
    { headerName: "Experience", field: "Experience", width: 80, filter: true },
    {
      headerName: "City",
      field: "City",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Primary",
      field: "Primary",
      width: 200,
      filter: true,
      resizable: true,
    },
    { headerName: "Job Type", field: "JobType", width: 100, filter: true },
    { headerName: "Set Job", field: "SetJob", width: 100 },
  ]);

  const enableResumeUpload = (jobId) => {
    axios
      .patch(dEndPoints.enableDisableResumeUpload(jobId), null, {
        params: {
          uploadResume: uploadFalse,
        },
      })
      .then((res) => {
        var data = res.data;

        if (data && data.data.code === 100154) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });

          loadOpenJobs();
          return;
        }
      })
      .catch((error) => {
        console.log("Cannot set resume upload", error);
        props.showSnackBar({
          message: error.response.data.message,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };

  const disableResumeUpload = (jobId) => {
    // jobId = e.value ? e.value : "";

    axios
      .patch(dEndPoints.enableDisableResumeUpload(jobId), null, {
        params: {
          uploadResume: uploadTrue,
        },
      })
      .then((res) => {
        var data = res.data;

        if (data && data.data.code === 100154) {
          props.showSnackBar({
            message: data.data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });

          loadOpenJobs();
          return;
        }
      })
      .catch((error) => {
        console.log("Cannot set resume upload", error);
        props.showSnackBar({
          message: error.response.data.message,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };

  const setJoboftheday = (jobId) => {
    // jobId = e.value ? e.value : "";
    axios
      .patch(dEndPoints.joboftheday(jobId), null, {
        params: {
          jobOfDay: jobSet,
        },
      })
      .then((res) => {
        var data = res.data;

        if (data && data.code === 100143) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });

          loadOpenJobs();
          return;
        }
      })
      .catch((error) => {
        console.log("error in setting job of the day", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100146
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100155
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  const unsetJoboftheday = (jobId) => {
    axios
      .patch(dEndPoints.joboftheday(jobId), null, {
        params: {
          jobOfDay: jobUnset,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100144) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          loadOpenJobs();
          return;
        }
      })
      .catch((error) => {
        console.log("error in setting job of the day", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100156
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  const handleClick = (jobId) => {
    props.showLoader({ show: true });
    axios
      .post(dEndPoints.approveJob(jobId))
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;

        if (data && data.code === 100135) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          loadResumes();
          return;
        }
      })
      .catch((error) => {
        console.log("error in approve job", error);
        props.showLoader({ show: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100106
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };
  const handleJobEditClick = (jobId) => {
    props.history.push(`/createjob/${jobId}`);
    trackEvent({
      type: "EDIT_JOB",
      data: {
        jobId: jobId,
      },
    });
  };
  const handleClickJob = (jobId) => {
    axios
      .post(dEndPoints.rejectJob(jobId))
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100136) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          loadResumes();
          return;
        }
      })
      .catch((error) => {
        console.log("error in download file", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100107
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  var jobs = info.map((details) => {
    var data = {
      JobID: details._id || "",
      ReqId: details.reqId || "",
      Status: details.status || "",
      Company: details.cName || "",
      JobType: details.jobType || "",
      Designation: details.desig || "",
      City: details.city || "",
      Primary: details.pSkills || "",
      Job: details._id || "",
      RejectJob: details._id || "",
    };
    return data;
  });

  var allJobsOpen = openJobs.map((details) => {
    var data = {
      JobId: details._id || "",
      ReqId: details.reqId || "",
      jobOfTheDay: details.jobOfTheDay || "",
      Status: details.status || "",
      PostedOn: details.cOn || "",
      Company: details.cName || "",
      Designation: details.desig || "",
      Experience: details.expFrom || "",
      City: details.city || "",
      Primary: details.pSkills || "",
      JobType: details.jobType || "",
      SetJob: details.jobOfTheDay || "false",
      DisRes: details.disRes || "",
    };
    return data;
  });

  useEffect(() => {
    loadResumes();
    loadOpenJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadResumes = () => {
    props.showLoader({ show: true });
    axios
      .get(endPoints.getAllPendingJob)
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;

        if (res.data.code === 100127) {
          const resumes = data.data;
          setInfo(resumes);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching data", error);
      });
  };

  const loadOpenJobs = () => {
    props.showLoader({ show: true });

    axios
      .get(dEndPoints.getAllOpenJobs(), {
        params: {
          status: "Open",
          skip: 0,
          limit: 700,
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        var openJobsData = res.data;

        if (res.data.code === 100151) {
          const allOpenJobs = openJobsData.data;
          setOpenJobs(allOpenJobs);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching all open jobs", error);
      });
  };

  function getRowStyleScheduled(params) {
    if (params.data.DisRes === true) {
      return {
        "background-color": "#DCDCDC",
      };
    } else if (params.data.SetJob === true) {
      return {
        "background-color": "#FFFF99",
      };
    }
    return null;
  }

  function fullNameGetter(params) {
    return params.data.Status + " Job";
  }

  const changeInstantCheck = (e) => {
    setallJobsListing(e.target.checked);
    setjobVerification(false);
    setjodList(false);
  };

  const changeVeryfy = (e) => {
    setjobVerification(e.target.checked);
    setallJobsListing(false);
    setjodList(false);
  };
  const changeJod = (e) => {
    setjodList(e.target.checked);
    setallJobsListing(false);
    setjobVerification(false);
  };
  // const handleChange = (e) => {
  //   e.preventDefault();
  //   setSearchInput(e.target.value);
  // };
  if (searchInput.length > 0) {
    info.filter((jobName) => {
      return jobName.desig.match(searchInput);
    });
  }
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <div className="user__activation-page">
            <div style={{ marginTop: "25px" }}>
              <Row className="mb-5">
                <Col sm={3}>
                  <label
                    className={`status-card ${
                      jodTrue === true ? "active" : ""
                    }`}
                    htmlFor="jodTrue"
                  >
                    <input
                      type="radio"
                      checked={jodTrue}
                      onChange={changeJod}
                      name="jobListing"
                      id="jodTrue"
                      className="opacity-0"
                    />
                    <span className="status-card__info">
                      <h3>
                        {
                          openJobs.filter((item) => item.jobOfTheDay === true)
                            .length
                        }
                      </h3>
                      <span className="f-600">Job Of The Day</span>
                    </span>
                  </label>
                </Col>
                <Col sm={3}>
                  <label
                    className={`status-card ${
                      jobVerification === true ? "active" : ""
                    }`}
                    htmlFor="jobVerification"
                  >
                    <input
                      type="radio"
                      checked={jobVerification}
                      onClick={changeVeryfy}
                      id="jobVerification"
                      name="jobListing"
                      className="opacity-0"
                    />
                    <span className="status-card__info">
                      <h3>{info.length}</h3>
                      <span className="f-600">Job Verification</span>
                    </span>
                  </label>
                </Col>
                <Col sm={3}>
                  <label
                    className={`status-card ${
                      allJobsListing === true ? "active" : ""
                    }`}
                    htmlFor="allJobsListing"
                  >
                    <input
                      type="radio"
                      checked={allJobsListing}
                      onChange={changeInstantCheck}
                      name="jobListing"
                      id="allJobsListing"
                      className="opacity-0"
                    />
                    <span className="status-card__info">
                      <h3>
                        {openJobs.length -
                          openJobs.filter((item) => item.jobOfTheDay === true)
                            .length}
                      </h3>
                      <span className="f-600">All Jobs</span>
                      <small>&nbsp; (Except JOD)</small>
                    </span>
                  </label>
                </Col>
              </Row>
              {jobVerification === true ? (
                <Row>
                  <Col xs={12} className="mb-4 mt-3">
                    <div className="tq__page-header d-block text-center mb-5">
                      <h3 className="page-header">
                        <span className="text-muted">Jobs</span> Verification
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="tq-cards py-4 mb-5">
                      <Row className="tq-form justify-content-around">
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            placeholder="Search jobs here...."
                            onChange={(event) => {
                              setSearchInput(event.target.value);
                            }}
                            // value={searchInput}
                          />
                          <small className="mt-2 d-block text-center">
                            Search Jobs by Typing -{" "}
                            <mark className="bg-info">Designation</mark>{" "}
                            <mark className="bg-info">Location</mark> or{" "}
                            <mark className="bg-info">Company Name</mark>{" "}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {info
                    .filter((val) => {
                      if (searchInput === "") {
                        return val;
                      } else if (
                        val.desig
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.cName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.city
                          .join()
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((item, index) => (
                      <Col xs={4} className="mb-5" key={index}>
                        <JobCardAdmin
                          Title={item.desig}
                          status={item.status}
                          Company={item.cName}
                          req={item.reqId}
                          cOn={moment(new Date(item.cOn)).fromNow()}
                          JobType={item.jobType}
                          City={item.city.join()}
                          reject={(e) => {
                            handleClickJob(item._id);
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          approve={() => handleClick(item._id)}
                          edit={() => handleJobEditClick(item._id)}
                        />
                      </Col>
                    ))}
                </Row>
              ) : jodTrue === true ? (
                <Row>
                  {/*---------- all jobs --------------*/}

                  <Col xs={12} className="mb-4 mt-3">
                    <div className="tq__page-header d-block text-center mb-5">
                      <h3 className="page-header">
                        <span className="text-muted">Job of the day</span>
                      </h3>
                    </div>
                  </Col>
                  {openJobs.map((item, index) =>
                    item.jobOfTheDay === true ? (
                      <Col xs={4} className="mb-5" key={index}>
                        <JobCardAdmin
                          Title={item.desig}
                          req={item.reqId}
                          JobType={item.jobType}
                          City={item.city}
                          status={item.status}
                          jod={item.jobOfTheDay}
                          disabledResume={item.disRes}
                          Company={item.cName}
                          cOn={moment(new Date(item.cOn)).fromNow()}
                          enableResume={() => enableResumeUpload(item._id)}
                          disableResume={() => disableResumeUpload(item._id)}
                          setJod={() => setJoboftheday(item._id)}
                          unsetJod={() => unsetJoboftheday(item._id)}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </Row>
              ) : allJobsListing === true ? (
                <Row>
                  {/*---------- all jobs --------------*/}

                  <Col xs={12} className="mb-4 mt-3">
                    <div className="tq__page-header d-block text-center mb-5">
                      <h3 className="page-header">
                        All <span className="text-success">Open</span>&nbsp;
                        <span className="text-muted">Jobs</span>
                      </h3>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="tq-cards py-4 mb-5">
                      <Row className="tq-form justify-content-around">
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            placeholder="Search jobs here...."
                            onChange={(e) => {
                              setSearchInputAll(e.target.value);
                            }}
                          />
                          <small className="mt-2 d-block text-center">
                            Search Jobs by Typing -{" "}
                            <mark className="bg-info">Designation</mark> or{" "}
                            <mark className="bg-info">Company Name</mark>{" "}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {openJobs
                    .filter((allJobs) => {
                      if (searchInputAll === "") {
                        return allJobs;
                      } else if (
                        allJobs.desig
                          ?.toLowerCase()
                          .includes(searchInputAll?.toLowerCase())
                      ) {
                        return allJobs;
                      } else if (
                        allJobs.cName
                          ?.toLowerCase()
                          .includes(searchInputAll?.toLowerCase())
                      ) {
                        return allJobs;
                      }
                    })
                    .map((item, index) =>
                      item.jobOfTheDay !== true ? (
                        <Col xs={4} className="mb-5" key={index}>
                          <JobCardAdmin
                            Title={item.desig}
                            req={item.reqId}
                            JobType={item.jobType}
                            City={item.city}
                            status={item.status}
                            jod={item.jobOfTheDay}
                            disabledResume={item.disRes}
                            Company={item.cName}
                            cOn={moment(new Date(item.cOn)).fromNow()}
                            enableResume={() => enableResumeUpload(item._id)}
                            disableResume={() => disableResumeUpload(item._id)}
                            setJod={() => setJoboftheday(item._id)}
                            unsetJod={() => unsetJoboftheday(item._id)}
                          />
                        </Col>
                      ) : (
                        ""
                      )
                    )}
                </Row>
              ) : (
                ""
              )}
              <div
                className="ag-theme-balham"
                style={{
                  height: "550px",
                  width: "100%",
                  fontSize: "13px",
                }}
              >
                <AgGridReact
                  pagination={true}
                  columnDefs={header}
                  rowData={jobs}
                ></AgGridReact>
              </div>
            </div>

            <div style={{ marginTop: "25px" }}>
              <div className="tq__page-header mb-3">
                <h3 className="page-header">
                  All Open &nbsp;
                  <span className="text-muted">Jobs</span>
                </h3>
              </div>

              <div>
                <div
                  style={{
                    width: "110px",
                    height: "30px",
                    borderRadius: "5px",
                    textAlign: "center",
                    background: "#FFFF99",
                    display: "inline",
                    marginRight: "15px",
                    padding: "5px",
                  }}
                >
                  <span>Job of the day</span>
                </div>
                <div
                  style={{
                    width: "170px",
                    height: "30px",
                    borderRadius: "5px",
                    textAlign: "center",
                    background: "#DCDCDC",
                    display: "inline",
                    padding: "5px",
                  }}
                >
                  <span>Resume upload disabled</span>
                </div>
              </div>

              <div
                className="ag-theme-balham"
                style={{
                  height: "550px",
                  width: "100%",
                  fontSize: "13px",
                }}
              >
                <AgGridReact
                  pagination={true}
                  columnDefs={openJobsHeader}
                  rowData={allJobsOpen}
                  getRowStyle={getRowStyleScheduled}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
//export default ApproveJob;

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ApproveJob);
