import React, { useState } from "react";
import { Badge, Button, ButtonGroup, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { dEndPoints } from "../../../config/end-point";
import axios from "axios";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../../actions/index";
import { useParams } from "react-router";
// import Button from "common/mataerialKit/components/CustomButtons/Button";
const Comments = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comment, setComment] = useState("");
  var candidateId = props.candidate._id ? props.candidate._id : "";

  // useEffect(() => {
  //   getCommentUpdate(candidateId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [candidateId]);
  // if (!props.comments || !Array.isArray(props.comments)) {
  //   return null;
  // }
  let { jobId } = useParams();

  const userType = localStorage.getItem("ut");
  // useEffect(() => {
  //   getCommentUpdate();
  // }, []);
  const commentChange = (e) => {
    setComment(e.target.value);
  };
  const createNewComment = (comment) => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      //var jobId = props.candidate.jobId;
      var data;
      if (!comment) {
        props.showSnackBar({
          message: "Please Enter Comment",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      } else {
        data = {
          comment: comment,
        };
      }
      props.showLoader({ show: true });
      axios
        .patch(
          dEndPoints.corpCandidateComment(corporateId, jobId, candidateId),
          data
        )
        .then((res) => {
          props.showLoader({ show: false });
          handleClose();
          setComment("");
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          //  let data = res.data;
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      var data;
      if (!comment) {
        props.showSnackBar({
          message: "Please Enter Comment",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      } else {
        data = {
          comment: comment,
        };
      }
      const vendorCorporateId = localStorage.getItem("vci");

      props.showLoader({ show: true });
      axios
        .patch(
          dEndPoints.vendorCandidateComment(
            vendorCorporateId,
            jobId,
            candidateId
          ),
          data
        )
        .then((res) => {
          props.showLoader({ show: false });
          handleClose();
          setComment("");
          //  let data = res.data;
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  var commentBlock = [];
  if (props.comments && Array.isArray(props.comments)) {
    props.comments.forEach(function (cancom, i) {
      commentBlock.push(
        <div key={i}>
          <ul className="p-0">
            <li>
              <span className="comment__wrapper">
                <span className="resume__status">
                  <Badge
                    bg={cancom.status ? cancom.status : ""}
                    className="m-0"
                  >
                    {cancom.status ? cancom.status : ""}
                  </Badge>
                </span>
                <span className="comment__text d-block">
                  {/* Comment :  */}
                  {cancom.com ? cancom.com : ""}
                </span>
                {cancom.by ? (
                  <span style={{ fontSize: "14px", opacity: "0.6" }}>
                    &nbsp; &nbsp; &nbsp; &nbsp; - by{" "}
                    <strong>{cancom.by ? cancom.by : ""}</strong>
                  </span>
                ) : (
                  ""
                )}
                {cancom.on ? (
                  <small style={{ fontSize: "12px" }}>
                    {" "}
                    {new Date(cancom.on).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </small>
                ) : (
                  ""
                )}
              </span>
            </li>
          </ul>
        </div>
      );
    });
  }
  return (
    <div className="Comments-Page">
      <Container fluid>
        <p className="pageHeadings mb-4">
          Comment : ({props.comments ? " " + props.comments.length + " " : 0}){" "}
        </p>
        <Button variant="warning" onClick={handleShow}>
          Add Comment
        </Button>
        {commentBlock}
      </Container>
      {/* --------------- Modal For Add New Comment ----------------- */}
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="tq__modal-default w-80"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            Add Your Comment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="tq-form">
              <Form.Group xs={12} as={Col} className="mb-3">
                <Form.Label htmlFor="comment">
                  Your Comment<small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  id="comment"
                  label="Comments"
                  aria-label="Comments"
                  value={comment}
                  onChange={commentChange}
                  placeholder="Type Your Comment Here...."
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center text-center">
          <ButtonGroup>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => createNewComment(comment)}>
              Add Comment
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
// export default Comments;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(Comments);
