import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { AppProvider } from "./context/appContext";
// import { ThemeProvider } from "@material-ui/styles";
// //import { createMuiTheme } from "@material-ui/core/styles";
// import { createTheme } from "@material-ui/core/styles";
// import { green, lightBlue } from "@material-ui/core/colors";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/index.css";
// import SessionTimeout from "./utils/SessionTimeout";
// import "./assets/boxicons-2.1.1/css/boxicons.min.css";
// import App from "./App";
import configureStore from "./store/configStore";
import CustomizedSnackbars from "./common/Snackbar/Snackbar";
import Loader from "./common/Loader/Loader";
import history from "./utils/history";
import App from "./App";

// import * as serviceWorker from "./serviceWorker";
//import logdna from "@logdna/browser";
// const theme = createTheme({
//   palette: {
//     primary: { main: green[800] }, // Purple and green play nicely together.
//     secondary: { main: lightBlue[700] }, // This is just green.A700 as hex.
//   },
// });
const store = configureStore(window.__REDUX_STATE__ || {});

const AppBundle = (
  <Provider store={store}>
    <div className="App">
      <CustomizedSnackbars />
      <Loader />
      {/* <BrowserRouter> */}
      <AppProvider>
      <Router history={history}>
        <App />
      </Router>
      </AppProvider>
      {/* </BrowserRouter> */}
    </div>
  </Provider>
);

ReactDOM.hydrate(AppBundle, document.getElementById("root"));
// logdna
//   .init(
//     process.env.REACT_APP_LOGDNA_INGEST_KEY ||
//       "fd3052308fec9cab78933d44aba5cd45"
//   )
//   .addContext({
//     host: "ReactWeb",
//     app: "FrontEnd Web",
//     version: "v0.0.1.beta",
//     console: {
//       integrations: ["error", "warn"],
//     },
//     debug: false,
//     sampleRate: 5,

//     /*location: {
//       ancestorOrigins: {},
//       href: "http://localhost:3000",
//     }, */
//   });
