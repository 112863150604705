import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";
import { connect } from "react-redux";
import { showSnackBar, showLoader } from "../../actions/index";
import { bindActionCreators } from "redux";
import AddcoOwnersModal from "../../components/badge/AddcoOwnersModal";
import Users from "./Users";

// import PhoneInput from "react-phone-input-2";
const UserProfile = (props) => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [usersInfo, setUsersInfo] = useState([]);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    userName: "",
  });
  // const [phone, setPhone] = useState("");
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });
  useEffect(() => {
    //setTimeout(() => {
    loadSettingData();
    getAllUsers();
    //loadCompanyInfo();
    //}, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userType = localStorage.getItem("ut");
  const loadSettingData = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    //const corporateId = cacheData ? cacheData.ci : "";
    //const userId = cacheData ? cacheData.i : "";
    const corporateId = localStorage.getItem("ci");
    const userId = localStorage.getItem("i");

    props.showLoader({ show: true });
    if (userType === "1100" || userType === "1110") {
      axios
        .get(dEndPoints.getSetting(corporateId, userId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;

          if (data && data.code === 100000) {
            setAccountInfo(data.data);
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      axios
        .get(dEndPoints.getUserSetting(vendorCorporateId, userId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setAccountInfo(data.data);
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  const getAllUsers = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.getAllCompUsers(corporateId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setUsersInfo(data.data);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.getAllVendorCompUsers(vendorCorporateId))
        .then((res) => {
          props.showLoader({ show: false });
          let data = res.data;
          if (data && data.code === 100000) {
            setUsersInfo(data.data);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  // const removeEmptySpaces = (stringVal) => {
  //   var getValue = /\s/g.test(stringVal);
  //   if (getValue === true) {
  //     props.showSnackBar({
  //       message: "Please Remove Spacing.",
  //       open: true,
  //       type: "error",
  //       vertical: "top",
  //       horizontal: "center",
  //     });
  //     return;
  //   }
  // };
  useEffect(() => {
    var info = { ...userInfo };
    if (accountInfo && accountInfo.firstName) {
      info.firstName = accountInfo.firstName;
      setUserInfo(info);
    }
    if (accountInfo && accountInfo.lastName) {
      info.lastName = accountInfo.lastName;
      setUserInfo(info);
    }
    if (accountInfo && accountInfo.contact) {
      info.contact = accountInfo.contact;
      setUserInfo(info);
    }
    if (accountInfo && accountInfo.userName) {
      info.userName = accountInfo.userName;
      setUserInfo(info);
    }
  }, [accountInfo]);

  const handleInputChange = (e) => {
    var cInfo = { ...userInfo };
    cInfo[e.target.id] = e.target.value;
    setUserInfo(cInfo);
    //const isValid = removeEmptySpaces(e.target.value);
  };

  const passwordInputChange = (e) => {
    var passInfo = { ...password };
    passInfo[e.target.id] = e.target.value;
    setPassword(passInfo);
  };

  const updateUser = (e) => {
    e.preventDefault();
    const vendorCorporateId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    // const corporateId = cacheData ? cacheData.ci : "";
    //const userId = cacheData ? cacheData.i : "";
    const userId = localStorage.getItem("i");

    if (userType === "1100" || userType === "1110") {
      if (userInfo.contact === "") {
        props.showSnackBar({
          message: "Please fill out Contact No.",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.firstName === "") {
        props.showSnackBar({
          message: "Please Enter First Name",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.lastName === "") {
        props.showSnackBar({
          message: "Please Enter Last Name",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.contact.length > 12) {
        props.showSnackBar({
          message: "Contact No. must be 12 digit",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.contact.length < 12) {
        props.showSnackBar({
          message: "Contact No. must be 12 digit",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }

      axios
        .patch(dEndPoints.updateAccountSetting(corporateId, userId), userInfo)
        .then((res) => {
          let data = res.data;

          if (data && data.code === 100000) {
            props.showSnackBar({
              message: "User details updated Successfully!",
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
          }
          // props.loadUser();
          // props.showLoader({ show: false });
        })
        .catch((error) => {
          // props.showLoader({ show: false });
          console.log("error", error);
        });
    } else {
      if (userInfo.contact === "") {
        props.showSnackBar({
          message: "Please fill out Contact No.",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.firstName === "") {
        props.showSnackBar({
          message: "Please Enter First Name",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.lastName === "") {
        props.showSnackBar({
          message: "Please Enter Last Name",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.contact.length > 12) {
        props.showSnackBar({
          message: "Contact No. must be 12 digit",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (userInfo.contact.length < 12) {
        props.showSnackBar({
          message: "Contact No. must be 12 digit",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      axios
        .patch(
          dEndPoints.updateVendorAccountSetting(vendorCorporateId, userId),
          userInfo
        )
        .then((res) => {
          let data = res.data;
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
            });
          }

          //props.loadUser();
          // props.showLoader({ show: false });
        })
        .catch((error) => {
          //props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };
  const changePassword = (e) => {
    e.preventDefault();
    const vendorCorporateId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    const userId = localStorage.getItem("i");
    //const corporateId = cacheData ? cacheData.ci : "";
    //const userId = cacheData ? cacheData.i : "";

    const clonePass = { ...password };

    if ((password.oldPassword = "")) {
      props.showSnackBar({
        message: "Please provide current password",
        open: true,
        type: "error",
      });
      return;
    }
    if (password.newPassword.length < 8) {
      props.showSnackBar({
        message: "Password must have at least 8 characters",
        open: true,
        type: "error",
      });
      return;
    }
    //  else if (password.newPassword !== password.confirmPassword) {
    //   props.showSnackBar({
    //     message: "Confirm Password not matched",
    //     open: true,
    //     type: "error",
    //   });
    //   return;
    // }
    props.showLoader({ show: true });
    if (userType === "1100" || userType === "1110") {
      axios
        .patch(dEndPoints.updatePasswordSetting(corporateId, userId), clonePass)
        .then((res) => {
          let data = res.data;

          setPassword({ oldPassword: "", newPassword: "" });
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
            });
          }
          // props.loadUser();
          props.showLoader({ show: false });
        })
        .catch((error) => {
          props.showLoader({ show: false });
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100121
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else {
            props.showSnackBar({
              message: `Something went wrong. Please contact support team`,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    } else {
      axios
        .patch(
          dEndPoints.updateVendorPasswordSetting(vendorCorporateId, userId),
          clonePass
        )
        .then((res) => {
          let data = res.data;
          setPassword({ oldPassword: "", newPassword: "" });
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
            });
          }

          //props.loadUser();
          props.showLoader({ show: false });
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100121
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <GoBackButton />
          <div className="userProfile-Page tq__panel mb-5">
            <div className="tq__page-header mb-2">
              <h2 className="page-header">User Profile Setting</h2>
            </div>
            {userType === "1100" ? (
              <small> Corporate</small>
            ) : userType === "1110" ? (
              <small> Super Admin</small>
            ) : userType === "1000" ? (
              <small> Vendor</small>
            ) : (
              ""
            )}
            <Form className="tq-form pt-4" autoComplete="off">
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="firstName"
                    type="text"
                    value={userInfo.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="lastName"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    value={userInfo.lastName}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label>Contact</Form.Label>

                  <Form.Control
                    id="contact"
                    onChange={handleInputChange}
                    type="text"
                    maxLength="12"
                    autoComplete="new-contact"
                    placeholder="91XXXXXXX465"
                    value={userInfo.contact}
                  />
                  {/* <PhoneInput
                    id="contact"
                    country={"in"}
                    value={userInfo.contact}
                    onChange={(phone) => setPhone(phone)}
                    // onChange={(e) => handleMobileChange(e)}
                    required
                  /> */}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="userName"
                    type="email"
                    onChange={handleInputChange}
                    placeholder="Email"
                    value={userInfo.userName}
                  />
                </Form.Group>
              </Row>
              <Button
                variant="primary"
                onClick={(e) => updateUser(e)}
                type="submit"
              >
                Update
              </Button>
              <Row className="mb-3 mt-4 pt-4 border__top">
                <Form.Group as={Col}>
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    id="oldPassword"
                    onChange={passwordInputChange}
                    value={password.oldPassword}
                    autoComplete="new-password"
                    placeholder="Current Password"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    id="newPassword"
                    autoComplete="password"
                    onChange={passwordInputChange}
                    value={password.newPassword}
                    placeholder="New Password"
                  />
                </Form.Group>
              </Row>

              <Button
                variant="secondary"
                type="submit"
                onClick={changePassword}
              >
                Change Password
              </Button>
            </Form>
          </div>
          {accountInfo && accountInfo.userAccess === "Admin" ? (
            <>
              <div className="add__user-wrapper">
                <Row>
                  <Col xs={8} className="align-self-center">
                    <p className="instruction">
                      Want to work distribution, you can add user and align the
                      work
                    </p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <AddcoOwnersModal
                      BtnName="Add User"
                      loadUser={getAllUsers}
                    />
                  </Col>
                </Row>
              </div>

              <div className="user__list-wrapper">
                <Users usersInfo={usersInfo} loadUser={getAllUsers} />
              </div>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
};
//export default UserProfile;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(UserProfile);
