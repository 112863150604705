import React, { useState } from "react";
import RequirementDetails from "./RequirementDetails";
import TermsAndContact from "./TermsAndContact";
import SkillsAndQualification from "./SkillsAndQualification";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { bindActionCreators } from "redux";
import { showSnackBar } from "../../../actions/index";
import { connect } from "react-redux";
import JobTypeSelect from "./JobTypeSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
const CustomCreateJob = (props) => {
  const [page, setPage] = useState(0);
  const FormTitles = [
    "Requirement Details",
    "Contract & Terms of Engagement",
    "Required Information from Candidates",
  ];
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const prevPage = () => {
    setPage((currentPage) => currentPage - 1);
  };
  const nextPage = () => {
    setPage((currentPage) => currentPage + 1);
  };
  const [jobError, setJobError] = useState({
    reqId: "",
    qual: [],
    desig: "",
    pSkills: [],
    sSkills: [],
    totpos: "",
    minBudget: "",
    maxBudget: "",
    nPeriod: "",
    validFor: "",
    rate: "",
    vRate: "",
    payment: "",
    expFrom: "",
    expTo: "",
    jobDesc: "",
    cId: "",
    jobType: "",
    cooList: [],
    cName: "",
    ///extraFields: [],
    extraDetails: "",
    conPer: {
      name: "",
      phNum: "",
    },
    city: [],
    pref: {
      skills: 1,
      expr: 1,
      eCtc: 0.75,
      city: 0.75,
      company: 0.75,
      desig: 0.75,
      qual: 0.75,
    },
    coOwners: [],
    cInfo: {
      tenPercent: false,
      twelvePercent: false,
      pgPercent: false,
      ugPercent: false,
      pan: false,
    },
  });

  const [formerrors, setFormError] = useState({
    reqIdError: "",
    totposError: "",
    noticePeriodError: "",
    validForError: "",
    cityError: "",
    companyError: "",
    primarySkillError: [],
    secondaryError: [],
    paymentError: "",
    rateError: "",
    desigError: "",
    qualError: [],
    replacementError: "",
    jobTypeError: "",
    coOwnersError: [],
    jobDescError: "",
    submitClikced: false,
    minBudgetError: "",
    MaxBudgetError: "",
    expFromError: "",
    expToError: "",
    contactPersonError: "",
    walkinVenueError: "",
    walkinTimeError: "",
  });
  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "jobType":
        if (!value) errorMsg = "Please enter JobType.";
        break;
      case "validFor":
        if (!value) errorMsg = "Please enter valid For.";
        break;
      case "city":
        if (!value) errorMsg = "Please enter City.";
        break;
      case "nPeriod":
        if (!value) errorMsg = "Please select Notice Period.";
        break;
      case "jobType":
        if (value.length === 0) errorMsg = "Please select Company Name.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const handleClickOpen = (event) => {
    if (!props.job.reqId || props.job.reqId === " ") {
      formerrors.reqIdError = "Please Enter Req ID";
    }
    if (!props.job.totpos || props.job.totpos === " ") {
      formerrors.totposError = "Please Enter Total Position";
    }
    if (!props.job.walkinD.walkInTime || props.job.walkinD.walkInTime === " ") {
      formerrors.walkinTimeError = "Please Enter Walkin Timing";
    }
    if (
      !props.job.walkinD.walkInVenue ||
      props.job.walkinD.walkInVenue === " "
    ) {
      formerrors.walkinVenueError = "Please Enter Walkin Venue";
    }
    if (!props.job.nPeriod) {
      formerrors.noticePeriodError = "Please Select Notice Period";
    }
    if (
      !props.job.pSkills ||
      (props.job.pSkills && props.job.pSkills.length === 0)
    ) {
      formerrors.primarySkillError = "Please Select Primary Skills";
    }
    if (
      !props.job.sSkills ||
      (props.job.sSkills && props.job.sSkills.length === 0)
    ) {
      formerrors.secondaryError = "Please Select Secondary Skills";
    }
    if (!props.job.desig || props.job.desig === " ") {
      formerrors.desigError = "Please Enter Designation";
    }
    if (!props.job.jobDesc && !props.job.jdFile) {
      formerrors.jobDescError =
        "Job Description must be minimum 100 characters.";
    }
    if (!props.job.jobType) {
      formerrors.jobTypeError = "Please Select Job Type";
    }
    if (!props.job.replacement) {
      formerrors.replacementError = "Please Select Replacement";
    }
    if (!props.job.city || (props.job.city && props.job.city.length === 0)) {
      formerrors.cityError = "Please Enter City Name";
    }
    if (
      !props.job.coOwners ||
      (props.job.coOwners && props.job.coOwners.length === 0)
    ) {
      formerrors.coOwnersError = "Please Select CoOwners";
    }
    if (!props.job.validFor) {
      formerrors.validForError = "Please Select Requirement Valid For";
    }
    if (!props.job.cName) {
      formerrors.companyError = "Please Select Company Name";
    }
    if (!props.job.payment) {
      formerrors.paymentError = "Please Select Payment";
    }
    if (!props.job.qual || (props.job.qual && props.job.qual.length === 0)) {
      formerrors.qualError = "Please Enter Qualification";
    }
    if (!props.job.rate) {
      formerrors.rateError = "Please Enter Rate";
    }
    if (!props.job.minBudget) {
      formerrors.minBudgetError = "Please provide Minimum Budget.";
    }
    if (!props.job.maxBudget) {
      formerrors.MaxBudgetError = "Please provide Maximum Budget.";
    }
    if (!props.job.expFrom) {
      formerrors.expFromError = "Please provide Experience From.";
    }
    if (!props.job.expTo) {
      formerrors.expToError = "Please provide Experience To.";
    }
  };
  //  var job = props.job;
  const validateForm = (job, jobError, validateFunc) => {
    const errorObj = {};
    Object.keys(jobError).map((x) => {
      let refValue = null;
      const msg = validateFunc(x, job[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log("event", form.checkValidity());

    setValidated(true);
    handleClickOpen();
    const errorObj = validateForm(props.job, jobError, validateField);

    if (Object.keys(errorObj).length !== 0) {
      setJobError({ jobError: { ...jobError, ...errorObj } });
      return false;
    }
  };

  const formStepDisplay = (page) => {
    switch (page) {
      case 0:
        return (
          <>
            {props.extractingJd ? (
              <span
                align="center"
                style={{ color: "DodgerBlue", fontSize: "20px" }}
              >
                Automatically extracting content from JD{" "}
                <CircularProgress size={35} color="info" />
              </span>
            ) : (
              ""
            )}
            <JobTypeSelect
              job={props.job}
              setJob={props.setJob}
              formerrors={formerrors}
            />

            <RequirementDetails
              job={props.job}
              validated={validated}
              jdFile={props.jdFile}
              status={props.status}
              errors={errors}
              extraField={props.extraField}
              seteditorState={props.seteditorState}
              formerrors={formerrors}
              setErrors={setErrors}
              setJobError={setJobError}
              jobError={jobError}
              validateField={validateField}
              setJob={props.setJob}
              editorState={props.editorState}
              onEditorStateChange={props.onEditorStateChange}
              handlePastedText={props.handlePastedText}
            />
          </>
        );
      case 1:
        return (
          <TermsAndContact
            validated={validated}
            job={props.job}
            formerrors={formerrors}
            validateField={validateField}
            setJob={props.setJob}
          />
        );
      case 2:
        return (
          <SkillsAndQualification
            status={props.status}
            job={props.job}
            errors={errors}
            formerrors={formerrors}
            setErrors={setErrors}
            validated={validated}
            setJob={props.setJob}
            resumeCount={props.resumeCount}
          />
        );
      default:
        return <span hidden>unknown step</span>;
    }
  };

  return (
    <div className="custom__job-creation text-center my-5">
      <Form className="card__view-tq">
        {formStepDisplay(page)}

        {page === 0 ? (
          <ButtonGroup>
            <Button
              variant="secondary"
              disabled={page === 0}
              onClick={prevPage}
            >
              Back
            </Button>
            {props.job.reqId &&
            props.job.jobType &&
            props.job.validFor &&
            props.job.nPeriod &&
            props.job.sSkills &&
            props.job.pSkills &&
            props.job.city &&
            props.job.maxBudget &&
            props.job.minBudget &&
            props.job.qual &&
            props.job.totpos &&
            props.job.expTo &&
            props.job.expFrom &&
            props.job.desig ? (
              <Button
                variant="primary"
                disabled={page === FormTitles.length - 1}
                onClick={nextPage}
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={page === FormTitles.length - 1}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            )}
          </ButtonGroup>
        ) : page === 1 ? (
          <ButtonGroup>
            <Button
              variant="secondary"
              disabled={page === 0}
              onClick={prevPage}
            >
              Back
            </Button>
            {props.job.conPer &&
            props.job.conPer.name &&
            props.job.conPer &&
            props.job.conPer.phNum &&
            props.job.replacement &&
            props.job.rate &&
            props.job.payment ? (
              <Button
                variant="primary"
                disabled={page === FormTitles.length - 1}
                onClick={nextPage}
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={page === FormTitles.length - 1}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            )}
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button variant="secondary" onClick={prevPage}>
              Back
            </Button>
          </ButtonGroup>
        )}
      </Form>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(CustomCreateJob);
