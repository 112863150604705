import React, { useCallback, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import endPoints, { dEndPoints } from "../../config/end-point";
import skillSet from "../../utils/skills";
// import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import collegeSet from "../../utils/college";
import { showLoader, showSnackBar } from "../../actions/index";
import ExtraMultiple from "./ExtraMultiple";
import EditOtherCandidate from "./EditOtherCandidate.js";
//import AgGridCheckbox from "./AgGridCheckbox";
import SendEmailFunctionality from "./SendEmailFunctionality";

const BulkUploadResumes = (props) => {
  const [findJob, setFindJob] = useState({ jobId: "" });
  const [openOther, setOpenOther] = useState(false);
  const [showCanDetails, setShowCanDetails] = useState(false);
  const [emailsSend, setEmailsSend] = useState(false);
  const [whatsappMsg, setWhatsappMsg] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [candId, setCanId] = useState("");
  const [skills, setSkills] = useState([]);
  const [userId, setUserId] = useState({});
  const [jobOne, setJobOne] = useState({});
  const [text, setText] = useState("");
  const [fileName, setFileName] = useState("");
  const [candidates, setCandidates] = useState([]);
  // const [showEmail, setShowEmail] = useState([]);
  const [getcandidates, setGetCandidates] = useState([]);
  const [companyLookup, SetcompanyLookup] = useState([]);

  // const [inputValueCheck, setValueCheck] = useState("");
  // const [isChecked, setIsChecked] = useState("");
  const [editing, setEditing] = useState(false);
  const [locations, setLocation] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [collegeLookup, SetcollegeLookup] = useState([]);
  const [editableUser, setEditableUser] = useState({});
  // const [selectedValue, setSelectedValue] = useState("");
  const [canInfo, setCanInfo] = useState({
    name: "",
    email: "",
    contact: "",
    college: [],
    city: [],
    cDesig: [],
    expr: "",
    ctc: "",
    ectc: "",
    cName: [],
    skills: [],
    qual: "",
    notes: "",
    nPeriod: null,
    comment: "",
  });

  const initialFormState = {
    name: "",
    email: "",
    contact: "",
    college: "",
    city: "",
    cDesig: "",
    expr: "",
    ctc: "",
    ectc: "",
    cName: "",
    skills: [],
    qual: "",
    nPeriod: null,
  };

  useEffect(() => {
    reloadJobs();
    if (findJob.jobId) {
      loadVendorJobDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findJob.jobId]);
  useEffect(() => {
    // setTimeout(() => {
    loadDatabaseDetails();
    loadDesignationOptions();
    //  loadCollegeOptions();
    loadCompanyOptions();
    loadOptions();
    loadQualOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadOptions = () => {
    axios.get(endPoints.getLocationLookup + "?location=").then((res) => {
      const data = res.data;
      setLocation(data);
    });
  };
  const loadCompanyOptions = () => {
    axios.get(endPoints.getCompanyLookup + "?company=ACC").then((res) => {
      const data = res.data;

      SetcompanyLookup(data);
    });
  };
  const loadDesignationOptions = () => {
    axios
      .get(endPoints.getDesignationLookup + "?desig=software")
      .then((res) => {
        const data = res.data;
        setDesignation(data);
      });
  };

  const loadQualOptions = () => {
    axios.get(endPoints.getQualificationLookup + "?qual=").then((res) => {
      const data = res.data;
      SetcollegeLookup(data);
    });
  };
  const userType = localStorage.getItem("ut");
  const reloadJobs = async (pageNum = 0) => {
    var sort_createdon = -1;
    //const corporateId = cacheData.ci;
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      await axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            limit: 100,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while loadig data v", error);
        });
    } else {
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      await axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 100,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  var candidateOne;

  candidateOne = candidates.filter((candidate) => candidate._id === userId);

  const [users, setUsers] = useState(candidateOne[0]);

  var latestUser = candidateOne[0];

  const handleChangeQuallookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.qual = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };

  const handleChangeLocationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);

    var info = { ...canInfo };
    info.city = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeDesinationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.cDesig = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeCompanylookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.cName = newQual ? newQual.map((el) => el.value) : [];
    setCanInfo(info);
  };
  const handleChangeCollegelookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...canInfo };
    info.college = newQual ? newQual.map((el) => el.value) : "";

    setCanInfo(info);
  };
  const updateUser = (name, updatedUser) => {
    setEditing(false);
    setUsers(users.map((user) => (user.name === name ? updatedUser : user)));
  };
  const editRow = (user) => {
    setEditing(true);
    setCurrentUser(latestUser);
  };
  //  var getFinalCandidate = [];
  const [inNotice, setInNotice] = useState(false);
  const [resumeContent, setResumeContent] = useState(false);
  const [header, setHeader] = useState([
    {
      headerName: "",
      field: "columnName",
      width: 60,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "",
      field: "Candidate",
      width: 100,
      cellRenderer: function (params) {
        //if (params.value.userId) {
        var userSet = params.value;
        setUserId(params.value);
        return (
          <Button
            variant="primary table-btn d-flex align-items-center"
            onClick={handleOpenSchedule(userSet)}
          >
            <i className="bx bxs-edit-alt"></i>&nbsp;Edit
          </Button>
        );
        //}
        //return null;
      },
    },
    { headerName: "Notes", field: "Notes", filter: true, width: 200 },
    { headerName: "Candidate Name", field: "Name", filter: true, width: 160 },
    { headerName: "Contact", field: "Contact", filter: true, width: 120 },
    { headerName: "Email", field: "Email", filter: true, width: 220 },
    { headerName: "Skills", field: "Skills", filter: true, width: 220 },
    {
      headerName: "Qualification",
      field: "Qualification",
      filter: true,
      width: 140,
    },
    { headerName: "Created On", field: "COn", filter: true, width: 140 },
    { headerName: "FileName", field: "FileName", filter: true, width: 600 },
  ]);
  const rowSelectionType = "multiple";
  const QualificationLookup = collegeLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CompanyLookup = companyLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CollegeLookup = collegeSet.map((item) => {
    return { label: item.name, value: item.name };
  });
  const locationLookup = locations.map((item) => {
    return { label: item.name, value: item.name };
  });

  const designationLookup = designation.map((item) => {
    return { label: item.name, value: item.name };
  });
  //function will trigger once selection changed
  const onSelectionChanged = (event) => {
    setGetCandidates(event.api.getSelectedRows());
  };

  //function to allow selection to row
  const isRowSelectable = (node) => {
    return node.data;
  };

  // const onChanged = (e) => {
  //   getFinalCandidate.push(e.target.value);

  //   setValueCheck(isChecked);
  //   setIsChecked(isChecked);
  // };

  useEffect(() => {
    loadDetails();

    loadCandidates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenSchedule = (value) => () => {
    editRow();
    setEditableUser(candidateOne[0]);
    setCurrentUser(candidateOne[0]);
    setUserId(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpenOther(false);
  };
  const handleCancel = () => {
    setShowCanDetails(false);
  };
  // const handleInput = (value) => {

  //   setValue(value);
  // };
  const handleCloseSchedule = () => {
    setOpen(false);
  };
  // handle selection
  // const handleChange = (value) => {
  //   const info = { ...canInfo };
  //   setSelectedValue(value);
  //   //;

  //   info.city = value.name;
  //   setCanInfo(info);
  // };
  // const showEmailPage = () => {
  //   setShowEmail(true);
  // };
  const noEmailPage = () => {
    setEmailsSend(false);
  };
  //let entries;
  //***Drag and select Resume file *** //
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    extractResume(acceptedFiles[0]);
    // setFileData(acceptedFiles[0]);
    uploadFile(acceptedFiles);
    setFileName(acceptedFiles[0].name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  // });
  const { getInputProps } = useDropzone({
    onDrop,
  });
  const uploadFile = async (acceptedFiles) => {
    var form = new FormData();
    form.append("fileData", acceptedFiles[0]);
    form.append("size", acceptedFiles[0].size);
    form.append("fileType", acceptedFiles[0].type);
    // }
    setCanInfo({
      name: "",
      email: "",
      contact: "",
      college: "",
      city: "",
      cDesig: "",
      expr: "",
      ctc: "",
      ectc: "",
      cName: "",
      skills: [],
      qual: "",
      nPeriod: null,
      comment: "",
    });

    setInNotice(false);
    props.showLoader({ show: true });
    axios
      .post(endPoints.uploadResumeOther + "?fileType=Resume", form)

      .then((res) => {
        var data = res.data;

        if (data && data.code === 100000) {
          props.showLoader({ show: false });
          setShowCanDetails(true);
          const candidateId = data && data.data._id ? data.data._id : "";
          setCanId(candidateId);
        }
      })
      .catch((error) => {
        console.log("error in upload file", error);
        props.showLoader({ show: false });
      });
  };

  // create candidates object to display in grid
  var candidate = candidates.map((can) => {
    var data = {
      columnName: "",
      Candidate: can._id || "",
      Notes: can.notes || "",
      Name: can.name || "",
      Contact: can.contact || "",
      Email: can.email || "",
      Skills: can.skills || "",
      FileName: can.rd ? can.rd.fname : "",
      Qualification: can.qual || "",
      COn:
        new Date(can.cOn).toLocaleDateString("en-US", {
          year: "numeric",
          day: "numeric",
          month: "short",
        }) || "",
    };
    return data;
  });

  // var getCandidate = [];
  // let selectCandidate = candidate.map((item, index) => {
  //   if (item.columnName === true) {
  //     getCandidate.push(item.Email);
  //   }
  // });

  //load all candidates
  const loadCandidates = () => {
    props.showLoader({ show: true });
    let query = "";
    axios
      .post(endPoints.searchOtherCandidates, query)
      .then((res) => {
        props.showLoader({ show: false });
        var getCandidates = res.data;

        if (res.data.code === 100160) {
          const allcandidates = getCandidates.data;
          setCandidates(allcandidates);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error while fetching all candidates", error);
      });
  };

  const loadDatabaseDetails = () => {
    const selectInput = {
      metaData: [
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "SKILL",
        },
      ],
    };
    props.showLoader({ show: true });
    axios({
      url: endPoints.lookup,
      data: selectInput,
      method: "POST",
    }).then((res) => {
      props.showLoader({ show: false });
      const data = res.data;

      if (data && data.code === 100000) {
        const skill = data && data.data && data.data.SKILL;
        setSkills(skill);
      }
    });
  };
  const loadDetails = () => {
    props.showLoader({ show: true });
    axios
      .get(endPoints.redisLookup + "?lookup=SKILL")
      .then((res) => {
        props.showLoader({ show: false });
        const data = res.data;

        if (data && data.code === 100000) {
          const skill = data && data.data && data.data.SKILL;

          setSkills(skill);
        }
        if (data.code !== 100000) {
          loadDatabaseDetails();
        }
        if (data.code === 100001) {
          loadDatabaseDetails();
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        loadDatabaseDetails();
      });
  };

  const skill =
    skills &&
    skills.map((item) => {
      return { label: item.name, value: item.name };
    });
  const skillss =
    skillSet &&
    skillSet.map((item) => {
      return { label: item.name, value: item.name };
    });

  var pror = ["label", "value"];

  var skillNew =
    skill &&
    skill
      .filter(function (o1) {
        // filter out (!) items in result2
        return skillss.some(function (o2) {
          return o1.label === o2.label; // assumes unique id
        });
      })
      .map(function (o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        return pror.reduce(function (newo, value) {
          newo[value] = o[value];
          return newo;
        }, {});
      });

  // /////extraction Resume Data  //////////
  const extractResume = (file) => {
    setResumeContent(true);
    var form = new FormData();
    form.append("file[]", file);
    axios
      .post(endPoints.extracOtherJob, form)
      .then((res) => {
        setResumeContent(false);

        var data = res.data;
        // const replaceRegex = /' '/g;
        // const replaceSQuoteRegex = /'/g;
        // data.skills = data.skills;
        setCanInfo(data);
      })
      .catch((error) => {
        console.log("error file", error);
        setResumeContent(false);
        var reader = new FileReader();
        reader.onload = function (e) {};
        reader.readAsArrayBuffer(file);
      });
  };

  const loadVendorJobDetails = (e) => {
    var jobId = findJob.jobId;
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      //const vendorCorporateId = cacheData ? cacheData.vci : "";
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorJobDetails(vendorCorporateId, jobId), {
          params: {
            getFacets: true,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });

          var data = res.data;

          setJobOne(data.data.jobData);
          if (data && data.code === 100000) {
            //if (data.data && data.data.jobData) {
            // }
          }

          handleText();
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("errorr in job details while loading data", error);
        });
    } else {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      // var jobId = findJob.jobId;

      props.showLoader({ show: true });
      axios
        .get(dEndPoints.corporateJobDetails(corporateId, jobId))
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          setJobOne(data.data.jobData);
          handleText();

          if (data && data.code === 100000) {
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const handleInputChange = (e) => {
    const info = { ...canInfo };
    if (
      e.target.id === "ctc" ||
      e.target.id === "ectc" ||
      e.target.id === "expr"
    ) {
      info[e.target.id] = Number(e.target.value);
    } else {
      info[e.target.id] = e.target.value;
    }
    setCanInfo(info);
  };
  const handleChangeSkills = (value) => {
    var info = { ...canInfo };
    info.skills = value ? value.map((el) => el.value) : [];
    setCanInfo(info);
  };
  // const handleOpen = () => {
  //   setOpenOther(true);
  // };
  const handleChangeNoticeP = (e) => {
    const info = { ...canInfo };
    info.nPeriod = e.target.value;
    setCanInfo(info);
  };

  const submitCanDetails = (e) => {
    e.preventDefault();
    const info = { ...canInfo };
    if (canInfo.name === "" || !canInfo.name) {
      props.showSnackBar({
        message: "name is empty",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (canInfo.contact === "" || !canInfo.contact) {
      props.showSnackBar({
        message: " Contact No. is empty.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (canInfo.contact.length < 10) {
      props.showSnackBar({
        message: "Contact number at least 10 digits",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (canInfo.email === " " || !canInfo.email) {
      props.showSnackBar({
        message: " Email is empty.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    // const corporateId = localStorage.getItem("ci");
    // const firstName = localStorage.getItem("name");

    // var body = {
    //   name: canInfo.name || "",
    //   contact: canInfo.contact || "",
    //   email: canInfo.email || "",
    //   cName: canInfo.cName || "",
    //   cDesig: canInfo.cDesig || "",
    //   expr: canInfo.expr || "",
    //   ctc: canInfo.ctc || "",
    //   ectc: canInfo.ectc || "",
    //   nPeriod: canInfo.nPeriod || "",
    //   qual: canInfo.qual || "",
    //   skills: canInfo.skills || "",
    //   comment: canInfo.comment || "",
    //   tags: canInfo.tags || "",
    //   city: canInfo.city || "",
    //   cId: corporateId,
    //   uploadedBy: firstName,
    // };

    setCanInfo({
      name: "",
      email: "",
      contact: "",
      college: "",
      city: "",
      cDesig: "",
      expr: "",
      ctc: "",
      ectc: "",
      cName: "",
      skills: "",
      qual: "",
      nPeriod: null,
      comment: "",
    });
    if (info._links) {
      delete info._links;
    }
    if (info._softSkills) {
      delete info._softSkills;
    }
    if (info.a_all_loc) {
      delete info.a_all_loc;
    }
    if (info.a_dates) {
      delete info.a_dates;
    }
    if (info.address) {
      delete info.address;
    }
    if (info.all_cName) {
      delete info.all_cName;
    }
    if (info.all_exp) {
      delete info.all_exp;
    }
    if (info.certific) {
      delete info.certific;
    }
    if (info.content) {
      delete info.content;
    }
    if (info.driveLsc) {
      delete info.driveLsc;
    }
    if (info.passport) {
      delete info.passport;
    }
    if (info.percentage) {
      delete info.percentage;
    }
    if (info.pinCode) {
      delete info.pinCode;
    }
    if (info.prm_sk) {
      delete info.prm_sk;
    }
    if (info.relFld) {
      delete info.relFld;
    }
    if (info.univ) {
      delete info.univ;
    }

    axios
      .patch(dEndPoints.otherCandidate(candId), info)
      .then((res) => {
        setShowCanDetails(false);
        var data = res.data;

        if (data && data.code === 100000) {
          props.showSnackBar({
            message: "Resume uploaded successfully",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          loadCandidates();
          return;
        }
      })
      .catch((error) => {
        console.log("error file", error);
        if (error.response.data.code === 100159) {
          setShowCanDetails(false);
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else {
          props.showSnackBar({
            message: "Error while uploading resume",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
        }
      });
  };
  const handleChangeJobSelect = (e) => {
    const info = { ...findJob };
    info.jobId = e.target.value;
    setFindJob(info);
  };

  var getNumber;
  for (let i = 0; i < getcandidates.length; i++) {
    getNumber = getcandidates[i].Contact;
  }

  if (getcandidates.length > 1 && getcandidates.length === 2) {
    props.showSnackBar({
      message:
        "Please select one candidate at a time to send WhatsApp messages, you can select multiple candidates to send email",
      open: true,
      type: "success",
      vertical: "top",
      horizontal: "center",
    });
  }
  const getJob = jobs && jobs.filter((item) => item._id === findJob.jobId);

  const handleText = (e) => {
    if (getJob) {
      setText(
        `Hello%2C%20%0AI%20am%20recruiting%20for%20the%20below%20position%20and%20found%20your%20candidature%20suitable%20for%20it%3A%20%0A%0A*Company%3A*%20${
          getJob[0].cName
        }%2C%0A*Role%3A*%20${getJob[0].desig}%2C%0A*Experience%3A*%20${
          getJob[0].expFrom + "-" + getJob[0].expTo
        }Years%2C%0A*Skills%3A*%20_${
          getJob[0].pSkills ? getJob[0].pSkills.join() : ""
        }_%2C%0A%2C%0A*Location%3A*%20${
          getJob[0].city ? getJob[0].city.join() : ""
        } %20%0A%0ALet%20me%20know%20if%20you%20are%20interested%20in%20this%20position.%20%0AIf%20yes%2C%20please%20send%20us%20your%20_CTC%2C%20Expected%20CTC%2C%20and%20Notice%20Period%20Details_%20with%20your%20*updated%20CV%20to%20recruiters%40talenq.com*%0A%0ARegards%2C%20%0A*Team%20TalenQ*`
      );
    }
  };

  const checkEmailSend = (e) => {
    //console.log("e", e.target.checked);
    setEmailsSend(e.target.checked);
    setWhatsappMsg(false);
    // setShowEmail(e.target.checked);
  };
  const checkWhatsappMsg = (e) => {
    setEmailsSend(false);
    setWhatsappMsg(e.target.checked);
  };
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <GoBackButton />
          <div className="BulkUploadResumes-Page text-center mb-5">
            {/* <UploadResumes className="mb-5" /> */}
            <Row className="mb-5">
              <Col
                xs={6}
                className=" d-flex align-items-center justify-content-center"
              >
                <Form className="resume__upload d-flex align-items-center justify-content-center w-100 h-100">
                  <div>
                    <div className="buttons__Group">
                      <div className="browser__Btn">
                        <label htmlFor="resumeUploadLog">Upload Resume</label>
                        <input
                          {...getInputProps()}
                          type="file"
                          id="resumeUploadLog"
                          accept="image/*,.pdf, .docx"
                        />
                      </div>
                    </div>
                    <div className="upload__instruction">
                      <h3>Drag & Drop your resume here to upload</h3>
                      <p>
                        You can upload either doc, docx, pdf file format here
                      </p>
                    </div>
                  </div>
                </Form>
              </Col>
              <Col
                xs={6}
                className="justify-content-start align-items-center d-flex"
              >
                <ExtraMultiple loadCandidates={loadCandidates} />
              </Col>
            </Row>
            <div className="send__candidate-emails tq-cards">
              <Row className="mb-5 justify-content-center">
                <Col sm={3}>
                  <label
                    className={`status-card ${
                      emailsSend === true ? "active" : ""
                    }`}
                    htmlFor="emailsSend"
                  >
                    <input
                      type="radio"
                      checked={emailsSend}
                      onChange={checkEmailSend}
                      name="jobListing"
                      id="emailsSend"
                      className="opacity-0"
                    />
                    <span className="status-card__info">
                      <h3>
                        <i className="bx bxs-envelope-open"></i>
                      </h3>
                      <span className="f-600">Send Emails</span>
                    </span>
                  </label>
                </Col>
                <Col sm={3}>
                  <label
                    className={`status-card ${
                      whatsappMsg === true ? "whatsapp-active" : ""
                    }`}
                    htmlFor="whatsappMsg"
                  >
                    <input
                      type="radio"
                      checked={whatsappMsg}
                      onChange={checkWhatsappMsg}
                      name="jobListing"
                      id="whatsappMsg"
                      className="opacity-0"
                    />
                    <span className="status-card__info">
                      <h3>
                        <i className="bx bxl-whatsapp"></i>
                      </h3>
                      <span className="f-600">Send WhatsApp Messages</span>
                    </span>
                  </label>
                </Col>
              </Row>
              {whatsappMsg === true || emailsSend === true ? (
                <Form.Group className="w-80 m-auto tq-form text-left">
                  <Form.Label htmlFor="name">
                    Choose Job<small className="required__form">*</small>
                  </Form.Label>
                  {whatsappMsg === true ? (
                    <select
                      className="form-select mb-3"
                      value={findJob.jobId}
                      onChange={handleChangeJobSelect}
                    >
                      <option selected>Choose Job</option>
                      {jobs.map((details, index) =>
                        details.status === "Open" ? (
                          <option
                            value={details._id}
                            key={index}
                            style={{ fontSize: "20px" }}
                          >
                            JobId: {details.reqId}, Designation: {details.desig}{" "}
                            ,Company Name: {details.cName}{" "}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                  ) : (
                    ""
                  )}
                  {emailsSend === true ? (
                    <select
                      className="form-select mb-3"
                      value={findJob.jobId}
                      onChange={handleChangeJobSelect}
                    >
                      <option selected>Choose Job</option>
                      {jobs.map((details, index) =>
                        details.status === "Open" && details.jdFile ? (
                          <option
                            value={details._id}
                            key={index}
                            style={{ fontSize: "20px" }}
                          >
                            JobId: {details.reqId}, Designation: {details.desig}{" "}
                            Company Name: {details.cName}{" "}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                  ) : (
                    ""
                  )}
                  <ButtonGroup>
                    {whatsappMsg === true ? (
                      <a
                        className="d-flex align-items-center btn btn-success"
                        href={`https://web.whatsapp.com/send?phone=${getNumber}&text=${text}`}
                        // onClick={(e) => handleSendWhatsApp(userSet, e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-whatsapp"></i> &nbsp; WhatsApp
                      </a>
                    ) : (
                      ""
                    )}
                    {/* {showEmail === true ? ( */}
                  </ButtonGroup>
                  {whatsappMsg === true ? (
                    <small className="mt-4 d-block text-left">
                      <strong>Note :</strong> Please select one candidate
                      checkbox at a time to send WhatsApp messages.
                    </small>
                  ) : (
                    ""
                  )}
                  {emailsSend === true ? (
                    <>
                      <SendEmailFunctionality
                        jobOne={jobOne}
                        getcandidates={getcandidates}
                        cancelBtn={
                          <Button variant="secondary" onClick={noEmailPage}>
                            Close Email
                          </Button>
                        }
                      />
                      {emailsSend === true ? (
                        <>
                          {/* <Button onClick={showEmailPage}>
                            <i className="bx bxs-envelope"></i> &nbsp;Send Email
                            To candidate
                          </Button> */}
                          {/* <Button variant="secondary" onClick={noEmailPage}>
                            Close Email
                          </Button> */}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Form.Group>
              ) : (
                ""
              )}
              {/* <Form.Group className="w-80 m-auto tq-form text-left">
                <Form.Label htmlFor="name">
                  Choose Job<small className="required__form">*</small>
                </Form.Label>
                <select
                  className="form-select mb-3"
                  value={findJob.jobId}
                  onChange={handleChangeJobSelect}
                >
                  <option selected>Choose Job</option>
                  {jobs.map((details, index) =>
                    details.status === "Open" && details.jdFile ? (
                      <option value={details._id}>
                        JobId: {details.reqId}, Designation: {details.desig}{" "}
                        ,Company Name: {details.cName}{" "}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
                <ButtonGroup>
                  <Button onClick={showEmailPage}>
                    <i className="bx bxs-envelope"></i> &nbsp;Send Email To
                    candidate
                  </Button>
                  <a
                    className="d-flex align-items-center btn btn-success"
                    href={`https://web.whatsapp.com/send?phone=${getNumber}&text=${text}`}
                    // onClick={(e) => handleSendWhatsApp(userSet, e)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-whatsapp"></i> &nbsp; WhatsApp
                  </a>
                  {showEmail === true ? (
                    <>
                      <Button variant="secondary" onClick={noEmailPage}>
                        Close Email
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </ButtonGroup>
                <small className="mt-4 d-block text-left">
                  <strong>Note :</strong> Please select one candidate checkbox
                  at a time to send WhatsApp messages.
                </small>
                {showEmail === true ? (
                  <>
                    <SendEmailFunctionality
                      jobOne={jobOne}
                      getcandidates={getcandidates}
                    />
                  </>
                ) : (
                  ""
                )}
              </Form.Group> */}
            </div>
            {/* Bulk Upload Functionality resume edit modal ends here */}
            <Modal
              show={open}
              onHide={handleCloseSchedule}
              dialogClassName="tq__modal-default w-70"
            >
              <Modal.Header className="border-0" closeButton>
                <Modal.Title className="w-100 text-center">
                  Edit Resume Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="tq-form">
                <EditOtherCandidate
                  editing={editing}
                  candidateOne={candidateOne[0]}
                  setEditing={setEditing}
                  currentUser={currentUser}
                  updateUser={updateUser}
                  loadCandidates={loadCandidates}
                  handleCloseSchedule={handleCloseSchedule}
                  userId={userId}
                  editableUser={editableUser}
                  setOpenOther={setOpen}
                />
              </Modal.Body>
            </Modal>
            {showCanDetails ? (
              <>
                <h1 className="pageHeadings">Resume Details</h1>
                <Form className="tq-form mt-4 pt-4">
                  <Row className="text-left mb-4">
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>
                        Candidate Name{" "}
                        <small className="required__form">*</small>
                      </Form.Label>
                      <Form.Control
                        id="name"
                        type="text"
                        placeholder="Candidate Name"
                        onChange={handleInputChange}
                        value={canInfo.name}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>
                        Contact No <small className="required__form">*</small>
                      </Form.Label>
                      <Form.Control
                        id="contact"
                        type="text"
                        placeholder="eg : 98668XXX00 "
                        maxLength="12"
                        value={canInfo.contact}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>
                        Email <small className="required__form">*</small>
                      </Form.Label>
                      <Form.Control
                        id="email"
                        type="email"
                        placeholder="Email eg. example@domain.com"
                        value={canInfo.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="text-left mb-4">
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>Current Company</Form.Label>
                      <CreatableSelect
                        inputId="cName"
                        TextFieldProps={{
                          label: "cName",
                          InputLabelProps: {
                            htmlFor: "cName",
                            shrink: true,
                          },
                        }}
                        options={CompanyLookup}
                        // components={components}
                        value={
                          canInfo.cName
                            ? canInfo.cName.map((ele) => ({
                                label: ele,
                                value: ele,
                              }))
                            : ""
                        }
                        onChange={handleChangeCompanylookUp}
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>Designation</Form.Label>
                      <CreatableSelect
                        {...props}
                        inputId="cDesig"
                        TextFieldProps={{
                          label: "cDesig",
                          InputLabelProps: {
                            htmlFor: "cDesig",
                            shrink: true,
                          },
                        }}
                        options={designationLookup}
                        // components={components}
                        value={
                          canInfo.cDesig
                            ? canInfo.cDesig.map((ele) => ({
                                label: ele,
                                value: ele,
                              }))
                            : []
                        }
                        onChange={handleChangeDesinationlookUp}
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>City</Form.Label>
                      <CreatableSelect
                        inputId="city"
                        TextFieldProps={{
                          label: "city",
                          InputLabelProps: {
                            htmlFor: "city",
                            shrink: true,
                          },
                        }}
                        options={locationLookup}
                        // components={components}
                        value={
                          canInfo.city
                            ? canInfo.city.map((ele) => ({
                                label: ele,
                                value: ele,
                              }))
                            : []
                        }
                        onChange={handleChangeLocationlookUp}
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Row className="text-left mb-4">
                    <Form.Group as={Col} xs={4}>
                      <Form.Label>Degree/Qualification</Form.Label>
                      <CreatableSelect
                        inputId="qual"
                        TextFieldProps={{
                          label: "qual",
                          InputLabelProps: {
                            htmlFor: "qual",
                            shrink: true,
                          },
                        }}
                        options={QualificationLookup}
                        // components={components}
                        value={
                          canInfo.qual
                            ? canInfo.qual.map((ele) => ({
                                label: ele,
                                value: ele,
                              }))
                            : ""
                        }
                        onChange={handleChangeQuallookUp}
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={5}>
                      <Form.Label>College/University</Form.Label>
                      <CreatableSelect
                        inputId="college"
                        TextFieldProps={{
                          label: "college",
                          InputLabelProps: {
                            htmlFor: "college",
                            shrink: true,
                          },
                        }}
                        options={CollegeLookup}
                        // components={components}
                        value={
                          canInfo.college
                            ? canInfo.college.map((ele) => ({
                                label: ele,
                                value: ele,
                              }))
                            : ""
                        }
                        onChange={handleChangeCollegelookUp}
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Row className="text-left mb-4">
                    <Form.Group as={Col} xs={3}>
                      <Form.Label>
                        CTC <small>(In LPA)</small>
                      </Form.Label>
                      <Form.Control
                        id="ctc"
                        type="number"
                        placeholder="0"
                        value={canInfo.ctc}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={3}>
                      <Form.Label>
                        Expected CTC<small>(In LPA)</small>
                      </Form.Label>
                      <Form.Control
                        id="ectc"
                        type="number"
                        placeholder="0"
                        value={canInfo.ectc}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={3}>
                      <Form.Label>Experience</Form.Label>
                      <Form.Control
                        id="expr"
                        type="number"
                        placeholder="eg : 2 or 2.5 "
                        value={canInfo.expr}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={3}>
                      <Form.Label>Notice Period</Form.Label>
                      <select
                        id="nPeriod"
                        className="form-select"
                        aria-label="noticePriod"
                        multiple={false}
                        value={canInfo.nPeriod}
                        onChange={handleChangeNoticeP}
                      >
                        <option value={7}>1 Week</option>
                        <option value={14}>2 Weeks</option>
                        <option value={21}>3 Weeks</option>
                        <option value={30}>30 Days</option>
                        <option value={45}>45 Days</option>
                        <option value={60}>60 Days</option>
                        <option value={90}>90 Days</option>
                      </select>
                    </Form.Group>
                  </Row>
                  <Row className="text-left mb-4">
                    <Form.Group as={Col}>
                      <Form.Label>Skills</Form.Label>
                      <CreatableSelect
                        inputId="skills"
                        TextFieldProps={{
                          label: "Skills",
                          InputLabelProps: {
                            htmlFor: "skills",
                            shrink: true,
                          },
                        }}
                        options={skillNew}
                        value={
                          canInfo.skills
                            ? canInfo.skills.map((ele) => ({
                                label: ele,
                                value: ele,
                              }))
                            : []
                        }
                        onChange={handleChangeSkills}
                        isMulti
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} className="mt-4">
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        id="comment"
                        as="textarea"
                        rows={3}
                        placeholder="Comments"
                        value={canInfo.comment}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Row>
                  <ButtonGroup>
                    <Button variant="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={submitCanDetails}>
                      Submit
                    </Button>
                  </ButtonGroup>
                </Form>
              </>
            ) : (
              ""
            )}{" "}
            <Modal
              open={openOther}
              onClose={handleClose}
              className="tqDialogue"
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Modal.Header className="border-0" closeButton>
                <Modal.Title className="w-100 text-center">
                  Click confirm to submit{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer className="border-0">
                <Button onClick={handleClose} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={submitCanDetails}
                  variant="primary"
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
            <h3 className="my-4 mt-5">All candidates</h3>
            <div
              className="ag-theme-balham"
              style={{
                height: "550px",
                width: "100%",
                fontSize: "13px",
              }}
            >
              <AgGridReact
                pagination={true}
                columnDefs={header}
                rowSelection={rowSelectionType}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                isRowSelectable={isRowSelectable}
                rowData={candidate}
              ></AgGridReact>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(BulkUploadResumes);
