import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import axios from "axios";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoBackButton from "../../components/badge/GoBackButton";
import { showLoader, showSnackBar } from "../../actions/index";
import { dEndPoints } from "../../config/end-point";
// import Pagination from "@material-ui/lab/Pagination";

// import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";

const TableViewPage = (props) => {
  // const styles = useStyles();
  const [jobs, setJobs] = useState([]);
  // const [jobCount, setJobCount] = useState(0);
  const [todaySchedule, setTodaySchedule] = useState([]);
  const [todayProposed, setTodayProposed] = useState([]);
  const [noShow, setNoShow] = useState([]);
  //const [pageNum, setPageNum] = useState(0);
  useEffect(() => {
    bucketdetailsJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const loadMoreJobs = () => {
  //   setPageNum(pageNum + 1);
  //   // handleSearchJob("", pageNum + 1);
  // };
  const clickDashboardRequirement = (jobId, candId) => {
    props.history.push(`/alljobs/${jobId}?candId=${candId}`);
  };
  const clickVendorDashboardRequirement = (jobId, candId) => {
    props.history.push(`/alljobs/${jobId}?candId=${candId}&enroll=${true}`);
  };
  var getQuery = props.location.search
    .replace(/\s/g, "-")
    .replace(/,/g, "")
    .replace(/\?/g, "");
  const userType = localStorage.getItem("ut");
  const bucketdetailsJobs = (pageNum = 0) => {
    //const corporateId = cacheData.ci;
    const userId = localStorage.getItem("i");
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");
    props.showLoader({ show: true });
    //setIsLoading(true);
    if (userType === "1100") {
      axios
        .get(dEndPoints.corpBucketDetails(corporateId, userId), {
          params: { candidateStatus: getQuery },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100178) {
            //setfacets(data.data.facets);
          }
          if (data.data.screenedCandidate) {
            setJobs(data.data.screenedCandidate);
          }
          if (data.data.pendingCandidate) {
            setJobs(data.data.pendingCandidate);
          }
          if (data.data.inProcessCandidate) {
            setJobs(data.data.inProcessCandidate);
          }
          if (data.data.immediateCandidate) {
            setJobs(data.data.immediateCandidate);
          }
          if (data.data.todaysCandidate) {
            setJobs(data.data.todaysCandidate);
          }
          if (data.data.todaysSchedule) {
            setJobs(data.data.todaysSchedule);
            setTodaySchedule(data.data.todaysSchedule);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else if (userType === "1110") {
      axios
        .get(dEndPoints.superAdminBucketDetails(userId), {
          params: { candidateStatus: getQuery },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100178) {
          }
          if (data.data.screenedCandidate) {
            setJobs(data.data.screenedCandidate);
          }
          if (data.data.pendingCandidate) {
            setJobs(data.data.pendingCandidate);
          }
          if (data.data.inProcessCandidate) {
            setJobs(data.data.inProcessCandidate);
          }
          if (data.data.immediateCandidate) {
            setJobs(data.data.immediateCandidate);
          }
          if (data.data.todaysCandidate) {
            setJobs(data.data.todaysCandidate);
          }
          if (data.data.todaysSchedule) {
            setJobs(data.data.todaysSchedule);
            setTodaySchedule(data.data.todaysSchedule);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      axios
        .get(dEndPoints.vendorBucketDetails(vendorCorporateId, userId), {
          params: { status: getQuery },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100178) {
          }
          if (data.data.rejectedCandidate) {
            setJobs(data.data.rejectedCandidate);
          }
          if (data.data.pendingCandidate) {
            setJobs(data.data.pendingCandidate);
          }
          if (data.data.inProcessCandidate) {
            setJobs(data.data.inProcessCandidate);
          }
          if (data.data.screenedCandidate) {
            setJobs(data.data.screenedCandidate);
          }
          if (data.data.allResume) {
            setJobs(data.data.allResume);
          }
          if (data.data.getTodayScheduleCount) {
            setJobs(data.data.getTodayScheduleCount);
            setTodaySchedule(data.data.getTodayScheduleCount);
          }
          if (data.data.getTodayProposedCount) {
            setJobs(data.data.getTodayProposedCount);
            setTodayProposed(data.data.getTodayProposedCount);
          }
          if (data.data.getNoShowCandidate) {
            setJobs(data.data.getNoShowCandidate);
            setNoShow(data.data.getNoShowCandidate);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  // ----------- pagination ------
  // ----------- pagination ------
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2}>
            <SideNav {...props} />
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />
            <GoBackButton />

            {userType === "1100" || userType === "1110" ? (
              todaySchedule.length > 0 ? (
                <>
                  {jobs.map((item, index) => (
                    <h2 className="page-header text-center"> {item.name}</h2>
                  ))}
                  {todaySchedule.length === 1 ? (
                    <h5>No Data Available</h5>
                  ) : (
                    <div className="table__data-component my-5">
                      <>
                        <Table
                          className="border-transparent"
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <tr>
                              <th>Sr.No.</th>
                              <th>Job ID</th>
                              {userType === "1110" ? <th>Company Name</th> : ""}
                              <th>Designation</th>
                              <th>Candidate Name</th>
                              <th>Email ID</th>
                              <th>Contact</th>
                              <th>Interview Date</th>
                              <th>Interview Time</th>
                              <th>Interview Status</th>
                              <th>Round Name</th>
                              <th>Round Mode</th>
                              <th>Round Type</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {jobs &&
                            jobs.map((item, index) =>
                              item.candidateId ? (
                                <tbody>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.jobId.reqId}</td>
                                    {userType === "1110" ? (
                                      <td>{item.jobId.cName}</td>
                                    ) : (
                                      ""
                                    )}
                                    <td>{item.jobId.desig}</td>
                                    <td>{item.candidateId.name}</td>
                                    <td>{item.candidateId.email}</td>
                                    <td>{item.candidateId.contact}</td>

                                    <td>
                                      {new Date(item.date).toLocaleDateString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          day: "numeric",
                                          month: "short",
                                        }
                                      )}
                                    </td>
                                    <td>
                                      {
                                        new Date(item.sTime)
                                          .toTimeString()
                                          .split(":")[0]
                                      }
                                      :
                                      {
                                        new Date(item.sTime)
                                          .toTimeString()
                                          .split(":")[1]
                                      }{" "}
                                      -{" "}
                                      {
                                        new Date(item.eTime)
                                          .toTimeString()
                                          .split(":")[0]
                                      }
                                      :
                                      {
                                        new Date(item.eTime)
                                          .toTimeString()
                                          .split(":")[1]
                                      }
                                    </td>
                                    <td>{item.status}</td>
                                    <td>{item.roundName}</td>
                                    <td>{item.roundMode}</td>
                                    <td>{item.roundType}</td>
                                    <td>
                                      <Button
                                        onClick={() =>
                                          clickDashboardRequirement(
                                            item.jobId._id,
                                            item.candidateId._id
                                          )
                                        }
                                      >
                                        View
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                ""
                              )
                            )}
                        </Table>
                      </>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {jobs.map((item, index) =>
                    !item.email && !item.stat ? (
                      <h2 className="page-header text-center"> {item.name}</h2>
                    ) : (
                      ""
                    )
                  )}
                  {jobs.length === 1 ? (
                    <h5>No Data Available</h5>
                  ) : (
                    <div className="table__data-component my-5">
                      <>
                        <Table
                          className="border-transparent"
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <tr>
                              <th>Sr.No.</th>
                              <th>Job ID</th>
                              {userType === "1110" ? <th>Company Name</th> : ""}
                              <th>Candidate Name</th>
                              <th>Created On</th>
                              <th>Job Title</th>
                              <th>Email ID</th>
                              <th>Current Designation</th>
                              <th>Status</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          {jobs &&
                            jobs.map((item, index) =>
                              item.name && item.email ? (
                                <tbody>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.reqId}</td>
                                    {userType === "1110" ? (
                                      <td>{item.cName}</td>
                                    ) : (
                                      ""
                                    )}
                                    <td>{item.name}</td>
                                    <td>
                                      {" "}
                                      {item.createdOn
                                        ? new Date(
                                            item.createdOn
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            day: "numeric",
                                            month: "short",
                                          })
                                        : ""}
                                    </td>
                                    <td>{item.desig}</td>
                                    <td>{item.email}</td>
                                    <td>{item.cDesig}</td>
                                    <td>{item.stat}</td>

                                    <td>
                                      <Button
                                        onClick={() =>
                                          clickDashboardRequirement(
                                            item.jobId,
                                            item._id
                                          )
                                        }
                                      >
                                        View
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                ""
                              )
                            )}
                        </Table>
                      </>
                    </div>
                  )}
                </>
              )
            ) : todaySchedule.length > 0 ||
              todayProposed.length > 0 ||
              noShow.length > 0 ? (
              <>
                {jobs.map((item, index) => (
                  <h2 className="page-header text-center"> {item.name}</h2>
                ))}
                {todaySchedule.length === 1 ||
                todayProposed.length === 1 ||
                noShow.length === 1 ? (
                  <h5>No Data Available</h5>
                ) : (
                  <div className="table__data-component my-5">
                    <>
                      <Table
                        className="border-transparent"
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Job ID</th>
                            <th>Company Name</th>
                            <th>Designation</th>
                            <th>Candidate Name</th>
                            <th>Email ID</th>
                            <th>Contact</th>
                            <th>Interview Date</th>
                            <th>Interview Time</th>
                            <th>Interview Status</th>
                            <th>Round Name</th>
                            <th>Round Mode</th>
                            <th>Round Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {jobs &&
                          jobs.map((item, index) =>
                            item.candidateId ? (
                              <tbody>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.jobId.reqId}</td>
                                  <td>{item.jobId.cName}</td>
                                  <td>{item.jobId.desig}</td>
                                  <td>{item.candidateId.name}</td>
                                  <td>{item.candidateId.email}</td>
                                  <td>{item.candidateId.contact}</td>
                                  <td>
                                    {new Date(item.date).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        day: "numeric",
                                        month: "short",
                                      }
                                    )}
                                  </td>
                                  <td>
                                    {
                                      new Date(item.sTime)
                                        .toTimeString()
                                        .split(":")[0]
                                    }
                                    :
                                    {
                                      new Date(item.sTime)
                                        .toTimeString()
                                        .split(":")[1]
                                    }{" "}
                                    -{" "}
                                    {
                                      new Date(item.eTime)
                                        .toTimeString()
                                        .split(":")[0]
                                    }
                                    :
                                    {
                                      new Date(item.eTime)
                                        .toTimeString()
                                        .split(":")[1]
                                    }
                                  </td>
                                  <td>{item.status}</td>
                                  <td>{item.roundName}</td>
                                  <td>{item.roundMode}</td>
                                  <td>{item.roundType}</td>

                                  <td>
                                    <Button
                                      onClick={() =>
                                        clickVendorDashboardRequirement(
                                          item.jobId._id,
                                          item.candidateId._id
                                        )
                                      }
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              ""
                            )
                          )}
                      </Table>
                    </>
                  </div>
                )}
              </>
            ) : (
              <>
                {jobs.map((item, index) =>
                  !item.email && !item.stat ? (
                    <h2 className="page-header text-center"> {item.name}</h2>
                  ) : (
                    ""
                  )
                )}
                {jobs.length === 1 ? (
                  <h5>No Data Available</h5>
                ) : (
                  <div className="table__data-component my-5">
                    <>
                      <Table
                        className="border-transparent"
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Job ID</th>
                            <th>Candidate Name</th>
                            <th>Last Updated On</th>
                            <th>Job Title</th>
                            <th>Role</th>
                            <th>Company Name</th>
                            <th>Status</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        {jobs &&
                          jobs.map((item, index) =>
                            item.name && item.email ? (
                              <tbody>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.reqId}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    {item.updatedOn
                                      ? new Date(
                                          item.updatedOn
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          day: "numeric",
                                          month: "short",
                                        })
                                      : ""}
                                  </td>
                                  <td>{item.desig}</td>
                                  <td>{item.cDesig}</td>
                                  <td>{item.cName}</td>
                                  <td>{item.stat}</td>

                                  <td>
                                    <Button
                                      onClick={() =>
                                        clickVendorDashboardRequirement(
                                          item.jobId
                                        )
                                      }
                                    >
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              ""
                            )
                          )}
                      </Table>
                    </>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(TableViewPage);
