import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
//import { useDropzone } from "react-dropzone";
import CloudDownload from "@material-ui/icons/CloudDownload";
import endPoints, { dEndPoints } from "../../../config/end-point";
import { showLoader, showSnackBar } from "../../../actions/index";
const Assignments = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  if (props.candidate && props.candidate.asgmt && props.candidate.asgmt.path) {
    let fileDetails = props.candidate.asgmt.path;
    let pathName = fileDetails ? fileDetails.split("_") : "";
    var fileNameToShow = pathName[pathName.length - 1];
  }

  // const onDrop = (acceptedFiles) => {
  //   //uploadAssignment(acceptedFiles[0]);
  // };

  const uploadAssignment = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    //const vendorCorporateId = cacheData ? cacheData.vci : "";
    const candidateId = props.candidate._id;
    var form = new FormData();
    form.append("fileData", selectedFiles[0]);
    form.append("size", selectedFiles[0].size);
    form.append("fileType", selectedFiles[0].type);
    props.showLoader({ show: true });
    axios
      .put(
        dEndPoints.uploadCandidateAssignment(vendorCorporateId, candidateId) +
          "?fileType=Assignment",
        form,
        {
          mimeType: "multipart/form-data",
        }
      )
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100000) {
          props.showLoader({ show: false });
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          props.showSnackBar({
            message: "File Uploaded Successfully",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        console.log("error in upload file", error);
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };

  // const {
  //   getRootProps,
  //   getInputProps,
  //   isDragActive,
  //   isDragAccept,
  //   isDragReject,
  // } = useDropzone({ onDrop });

  if (props.candidate && props.candidate.asgmt && props.candidate.asgmt.path) {
    let fileDetails = props.candidate.asgmt.path;
    let pathName = fileDetails ? fileDetails.split("_") : "";
    var fileNameToShow = pathName[pathName.length - 1];
  }
  const downloadAssignment = (path) => {
    props.showLoader({ show: true });

    axios
      .get(endPoints.uploadFile, {
        params: {
          fileType: "Assignment",
          fileUrl: path,
        },
        responseType: "blob",
      })
      .then((res) => {
        props.showLoader({ show: false });

        var blob = new Blob([res.data], { type: res.headers["content-type"] });
        const href = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileNameToShow);
        link.click();
        props.showSnackBar({
          message: "File Downloaded Successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        return;
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error in download file", error);
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };
  const userType = localStorage.getItem("ut");

  return (
    <div className="Assignments-Page">
      <Form>
        <>
          {/* {userType === 1000 ? ( */}
          {userType === "1000" && !props.candidate.asgmt ? (
            <div className="resume__upload">
              <div className="buttons__Group">
                <Form.Group className="tq-form multipl__upload text-center">
                  <Form.Control
                    type="file"
                    className="custom-file-input p-0"
                    id="fileInput"
                    label="Select a File"
                    name="file"
                    onChange={(e) => setSelectedFiles(e.target.files)}
                  />
                  <p className="mt-2">
                    {" "}
                    You can upload any file format here (e.g. .docx, .pdf, .zip,
                    .rar, .jpg)
                  </p>
                  <Button
                    variant="primary"
                    className="mt-4"
                    onClick={uploadAssignment}
                  >
                    <i className="bx bxs-file-archive"></i> Upload Assignment
                  </Button>{" "}
                  {/* <p>Select Multiple files to Upload.</p> */}
                </Form.Group>
                {/* {props.candidate && props.candidate.asgmt ? ( */}

                {/* <input {...getInputProps()} />
                {isDragAccept}
                {isDragReject}
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <label className="fileUpload" htmlFor="fileUpload">
                    <i className="bx bxs-file-archive"></i> Browse Zip
                  </label>
                )}
                <div className="upload__instruction">
                  <h3> Drag & Drop assignment here to upload</h3>
                  <p>
                    You can upload any file formats here. Max file size 10 MB
                  </p>
                </div> */}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {props.candidate && props.candidate.asgmt ? ( */}
        </>{" "}
        {props.candidate && props.candidate.asgmt ? (
          <div className="bg-light d-flex justify-content-around align-items-center py-2">
            <span className="d-block">{fileNameToShow} </span>
            <Button
              variant="primary"
              title="download file"
              onClick={() => {
                downloadAssignment(props.candidate.asgmt.path);
              }}
            >
              Download <CloudDownload />
            </Button>
          </div>
        ) : (
          <span className="d-block text-center mt-5">No data available</span>
        )}
      </Form>
    </div>
  );
};
//export default Assignments;
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Assignments);
