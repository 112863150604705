import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
//import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
//import Accordion from "react-bootstrap/Accordion";
import SchaduleRoundModal from "../badge/SchaduleRoundModal";
import "./interview.css";
import { dEndPoints } from "../../config/end-point";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../../actions/index";

// candicate tab component
const InterviewSlotTab = (props) => {
  return (
    <div className="card__tab-wrapper">
      <div className="card__details-tab">
        <strong>Round Name : </strong>
        <span>{props.RoundName}</span>
      </div>
      <div className="card__details-tab">
        <strong>Round Mode : </strong>
        <span>{props.RoundMode}</span>
      </div>
      <div className="card__details-tab">
        <strong>Type : </strong>
        <span>{props.Type}</span>
      </div>
      <div className="card__details-tab">
        <strong>Date : </strong>
        <span>
          {new Date(props.startDate).toLocaleDateString("en-US", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })}{" "}
          -{" "}
          {new Date(props.endDate).toLocaleDateString("en-US", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })}
        </span>
      </div>
      <div className="card__details-tab">
        <strong>Time : </strong>
        <span>
          {new Date(props.startTime).toTimeString().split(":")[0]}:
          {new Date(props.startTime).toTimeString().split(":")[1]} -{" "}
          {new Date(props.endTime).toTimeString().split(":")[0]}:
          {new Date(props.endTime).toTimeString().split(":")[1]}
        </span>
      </div>
      <div className="card__details-tab">
        <strong>Duration : </strong>
        <mark>{props.Slot}</mark>
      </div>
    </div>
  );
};

const InterviewDetails = (props) => {
  const [slots, setSlots] = useState([]);
  const [rounds, setRounds] = useState({});
  const [hightlightRoundId, setHightlightRoundId] = useState("");
  const [rName, setRName] = useState("");
  const [rMode, setRMode] = useState("");

  const jobId = props.jobId;
  useEffect(() => {
    getInterviewRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userType = localStorage.getItem("ut");
  const getInterviewRounds = () => {
    const corporateId = localStorage.getItem("ci");
    if (userType === "1100" || userType === "1110") {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.interviewRounds(corporateId, jobId))
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;

          setRounds(data.data);
          handleroundID(
            data.data.interview[0]._id,
            data.data.interview[0].roundName,
            data.data.interview[0].roundMode
          );
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  // to get slots of rounds w.r.t the round Id
  const handleroundID = (rId, rName, rMode) => {
    setHightlightRoundId(rId);
    setRName(rName);
    setRMode(rMode);
    const corporateId = localStorage.getItem("ci");
    axios
      .get(dEndPoints.interviewSlots(corporateId, rId))
      .then((res) => {
        var data = res.data;

        setSlots(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="interview__details-page mt-3">
      <div className="interview__round-body p-0">
        <Form.Label htmlFor="roundType">
          <strong>Note :</strong>Please create a new interview round/slot by
          clicking the add interview round and slot button on the right.
        </Form.Label>
        <div className="add__interview-round">
          <SchaduleRoundModal
            icon="bx bx-book-add"
            BtnName="Add Interview Round and Slot"
            jobId={jobId}
            getInterviewRounds={getInterviewRounds}
          />
        </div>

        <Tabs
          defaultActiveKey="iv__rounds"
          transition={false}
          id="iv__rounds"
          className="mb-3 candidate__tab-wrap border-0"
        >
          <Tab eventKey="iv__rounds" title="Interview Rounds">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column tab-scroll-y">
                    {rounds ? (
                      <Nav.Item className="candidate__tab-list">
                        {rounds.interview &&
                          rounds.interview !== null &&
                          rounds.interview.map((interv) => (
                            <>
                              <Nav.Link
                                eventKey={interv._id}
                                onClick={() =>
                                  handleroundID(
                                    interv._id,
                                    interv.roundName,
                                    interv.roundMode
                                  )
                                }
                              >
                                <InterviewSlotTab
                                  RoundName={interv.roundName}
                                  RoundMode={interv.roundMode}
                                  startDate={interv.startDate}
                                  endDate={interv.endDate}
                                  startTime={interv.startTime}
                                  endTime={interv.endTime}
                                  Type={interv.roundType}
                                  Slot={interv.duration}
                                />
                              </Nav.Link>
                            </>
                          ))}
                      </Nav.Item>
                    ) : (
                      ""
                    )}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content className="bg-light schadule__content">
                    <Tab.Pane eventKey={hightlightRoundId}>
                      {slots && slots.length > 0 ? (
                        slots &&
                        slots.map((slot) => (
                          <div className="maxSlotLists">
                            {Object.entries(slot).map(([key, value]) => (
                              <div className="slots__list-wrapper">
                                <div className="datesAvl">
                                  {new Date(key).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "short",
                                  })}
                                </div>
                                <div className="slots__listing row">
                                  {value.map((slotdata) => (
                                    <span className="available__slots-card col-2 p-0 mb-2 d-block">
                                      <div className="available__slot-row text-center">
                                        {
                                          new Date(slotdata.sTime)
                                            .toTimeString()
                                            .split(":")[0]
                                        }
                                        :
                                        {
                                          new Date(slotdata.sTime)
                                            .toTimeString()
                                            .split(":")[1]
                                        }{" "}
                                        -
                                        {
                                          new Date(slotdata.eTime)
                                            .toTimeString()
                                            .split(":")[0]
                                        }
                                        :
                                        {
                                          new Date(slotdata.eTime)
                                            .toTimeString()
                                            .split(":")[1]
                                        }
                                      </div>
                                      <div className="available__slot-row">
                                        {slotdata.status ? (
                                          <span
                                          // style={stylingObject.status}
                                          >
                                            Status:{" "}
                                            <mark>{slotdata.status}</mark>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="available__slot-row p-0">
                                        {slotdata.Candidate.length > 0 ? (
                                          slotdata.Candidate.map(
                                            (candidate) => (
                                              <div className="slot__booked-for">
                                                {candidate.name}
                                              </div>
                                            )
                                          )
                                        ) : slotdata.Candidate.length > 0 ? (
                                          <span
                                            className="availability reset"
                                            disabled={true}
                                          >
                                            Slot excluded
                                          </span>
                                        ) : (
                                          <span
                                            // style={stylingObject.canBook}
                                            className="availability true nText"
                                            disabled={false}
                                          >
                                            Available
                                          </span>
                                        )}
                                      </div>
                                    </span>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))
                      ) : (
                        <div>No interview slots</div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(InterviewDetails);
