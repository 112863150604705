import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./badge.css";
import { dEndPoints } from "../../config/end-point";
import { showSnackBar, showLoader } from "../../actions/index";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import AddDuration from "./AddDuration";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ScheduledModal = (props) => {
  const [show, setShow] = useState(false);
  const [roundType, setRoundType] = useState("");
  const [roundName, setRoundName] = useState("");
  const [roundMode, setRoundMode] = useState("");
  const [state, setState] = useState("start");
  const [duration, setDuration] = useState("00:00");
  const [maxDailyLimit, setMaxDailyLimit] = useState(null);
  const [exclusionDays, setExclusionDays] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [startTime, setStartTime] = useState(new Date(Date.now()));
  const [endTime, setEndTime] = useState(
    new Date(Date.now() + 3600 * 1000 * 1)
  );
  var iType = ["Plan", "Fixed"];
  var iName = ["Technical", "Manager", "HR", "Coding"];
  var iMode = ["Online", "Offline"];
  // var dayLimit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  var offDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const skillNew =
    offDays &&
    offDays.map((item) => {
      return { label: item, value: item };
    });
  // function to create interview round & slots
  const submitRound = () => {
    if (!roundType) {
      props.showSnackBar({
        message: "Round type cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (!roundName) {
      props.showSnackBar({
        message: "Round name cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (!roundMode) {
      props.showSnackBar({
        message: "Round mode cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (startDate === null) {
      props.showSnackBar({
        message: "Start date cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (endDate === null) {
      props.showSnackBar({
        message: "End date cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (startTime === null) {
      props.showSnackBar({
        message: "Start time cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (endTime === null) {
      props.showSnackBar({
        message: "End time cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (duration.split(":")[0] === "00" && duration.split(":")[1] === "00") {
      props.showSnackBar({
        message: "Duration cannot be blank",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
      // }
    }

    const corporateId = localStorage.getItem("ci");
    const roundBody = {
      roundType: roundType,
      roundName: roundName,
      roundMode: roundMode,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      duration: duration,
      maxDailyLimit: maxDailyLimit,
      exclusionDays: exclusionDays,
    };

    var timeDifference =
      roundBody.endTime.getTime() - roundBody.startTime.getTime();

    var minutesDifference = Math.floor(timeDifference / 1000 / 60);

    var durationHour = roundBody.duration.split(":")[0] * 60;
    var durationMin = roundBody.duration.split(":")[1] * 1;
    var totalDuration = durationHour + durationMin;

    // var slotsPerDay = minutesDifference / totalDuration;

    // if (moment(startTime).isAfter(endTime)) {
    //   props.showSnackBar({
    //     message: "Start time cannot be greater than end time",
    //     open: true,
    //     type: "error",
    //     vertical: "top",
    //     horizontal: "center",
    //   });
    //   return;
    // }
    // if (moment(startDate).isAfter(endDate)) {
    //   props.showSnackBar({
    //     message: "Start date cannot be greater than end date",
    //     open: true,
    //     type: "error",
    //     vertical: "top",
    //     horizontal: "center",
    //   });
    //   return;
    // }
    if (minutesDifference < totalDuration) {
      props.showSnackBar({
        message:
          "Cannot create slots! start and end time should not be less than duration",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else {
      props.showLoader({ show: true });
      axios
        .post(
          dEndPoints.createInterviewRound(corporateId, props.jobId),
          roundBody
        )
        .then((res) => {
          props.showLoader({ show: false });
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          var apiResponse = res.data;
          setRoundMode("");
          setRoundName("");
          setRoundType("");
          setDuration("");
          setMaxDailyLimit("");
          setExclusionDays([]);
          if (props.setShow()) {
            props.setShow(false);
          }
          if (apiResponse.data.code === 100170) {
            setShow(false);
            props.getInterviewRounds();
            props.showSnackBar({
              message: apiResponse.data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.code === 100168) {
            props.showLoader({ show: false });
            props.showSnackBar({
              message: error.response.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else if (error.response.data.code === 100172) {
            props.showLoader({ show: false });
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };
  const OpenModal = (props) => {
    // setDuration("00:00");
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="roundType">
            {" "}
            Custom Duration (HH:MM format)
          </Form.Label>
          <Form.Control
            type="time"
            format="HH:mm"
            value={duration}
            defaultValue="04:20"
            onChange={(e) => setDuration(e.target.value)}
            minutesStep="5"
          />
        </Form.Group>
      </>
    );
  };

  const handleChangeSkills = (value) => {
    var info = { ...exclusionDays };
    info = value ? value.map((el) => el.value) : [];
    setExclusionDays(info);
  };

  return (
    <>
      <div
        className="filter__btn flex__center border-0"
        onClick={() => setShow(true)}
      >
        <i className={props.icon}></i>
        {props.BtnName}
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal__filter-wrapper schadule__modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="border-0 text-center" closeButton>
          <Modal.Title className="W-100 text-center">
            Schedule Interview Rounds
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="tq-form">
            <Col md={4}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    Round Type <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Select
                    id="roundType"
                    value={roundType}
                    onChange={(e) => setRoundType(e.target.value)}
                  >
                    <option value="0">Select Round Type</option>
                    {iType.length > 0
                      ? iType.map((rType) => (
                          <option value={rType}>{rType}</option>
                        ))
                      : null}
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={4}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    Round Name<small className="required__form">*</small>
                  </Form.Label>
                  <Form.Select
                    id="roundType"
                    value={roundName}
                    onChange={(e) => setRoundName(e.target.value)}
                  >
                    <option value="0">Select Round Name</option>
                    {iName.length > 0
                      ? iName.map((rData) => (
                          <option value={rData}>{rData}</option>
                        ))
                      : null}
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={4}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    Round Mode<small className="required__form">*</small>
                  </Form.Label>
                  <Form.Select
                    id="roundType"
                    value={roundMode}
                    onChange={(e) => setRoundMode(e.target.value)}
                  >
                    <option value="0">Select Round Mode</option>
                    {iMode.length > 0
                      ? iMode.map((rModeData) => (
                          <option value={rModeData}>{rModeData}</option>
                        ))
                      : null}
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    Start Date<small className="required__form">*</small>
                  </Form.Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={true}
                      required="required"
                      className="txtField form-control p-0"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={startDate}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(date) => setStartDate(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    End Date<small className="required__form">*</small>
                  </Form.Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={true}
                      required="required"
                      className="txtField form-control  p-0"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={endDate}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(date) => setEndDate(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    Start Time (HH:MM format)
                    <small className="required__form">*</small>
                  </Form.Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      required="required"
                      className="txtField form-control  p-0"
                      inputVariant="outlined"
                      mask="__:__ _M"
                      InputAdornmentProps={{ position: "end" }}
                      minutesStep={5}
                      value={startTime}
                      onChange={(date) => setStartTime(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    End Time (HH:MM format)
                    <small className="required__form">*</small>
                  </Form.Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      required="required"
                      className="txtField form-control p-0"
                      inputVariant="outlined"
                      mask="__:__ _M"
                      InputAdornmentProps={{ position: "end" }}
                      minutesStep={5}
                      value={endTime}
                      onChange={(date) => setEndTime(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </div>
            </Col>

            <Col md={6}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">
                    Duration
                    <small className="required__form">*</small>
                  </Form.Label>
                  <Form.Select
                    ////id="roundType"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  >
                    <option selected> Select Duration</option>
                    <option value="00:15">15 Minutes</option>
                    <option value="00:30">30 Minutes</option>
                    <option value="00:45">45 Minutes</option>
                    <option value="01:00">1 Hour</option>
                    <option value="02:00">2 Hours</option>
                    <option value="03:00">3 Hours</option>
                    <option value="04:00">4 Hours</option>
                    <option value="05:00">5 Hours</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={6} className="align-self-center">
              <div className="modal__fileds">
                {state === "start" && (
                  <AddDuration addTrip={() => setState("add-trip")} />
                )}

                {state === "add-trip" && <OpenModal />}
              </div>
            </Col>
            <Col md={6}>
              <div className="modal__fileds">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">Max Daily Limit</Form.Label>
                  <Form.Select
                    id="roundType"
                    value={maxDailyLimit}
                    onChange={(e) => setMaxDailyLimit(Number(e.target.value))}
                  >
                    <option selected> Select Max Daily Limit</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="modal__fileds customize__text-field">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="roundType">Exclusion Day</Form.Label>
                  <CreatableSelect
                    inputId="roundType"
                    TextFieldProps={{
                      label: "roundType",
                      InputLabelProps: {
                        htmlFor: "roundType",
                        shrink: true,
                      },
                    }}
                    options={skillNew}
                    value={
                      exclusionDays &&
                      exclusionDays.map((ele) => ({
                        label: ele,
                        value: ele,
                      }))
                    }
                    onChange={handleChangeSkills}
                    isMulti
                    required
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center mt-5">
              <ButtonGroup>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={submitRound}>
                  Save
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(ScheduledModal);
