import axios from "axios";
// import { useHistory } from "react-router-dom";
import history from "./history";

axios.interceptors.request.use(
  async function (config) {
    //   const accessToken = localStorage.getItem("accessToken");
    let cookies;
    var value = "; " + document.cookie;
    //  console.log("value", value);
    var parts = value.split("; " + "info" + "=");
    //console.log("parts", parts);
    var parts1 = value.split("; " + "infoOne" + "=");
    //console.log("parts1", parts1);
    if (parts.length === 2 || parts1.length === 2) {
      cookies = parts.pop().split(";").shift();
      if (cookies === "undefined") {
        cookies = parts1.pop().split(";").shift();
      }
    }
    // if (cookies && parts1.length === 2) {
    //   config.headers["x-access-token"] = cookies;
    // } else if (cookies && parts.length === 2) {
    config.headers["x-access-token"] = cookies;
    // }
    // console.log("cookies", cookies);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    checkForLogout(response);
    return response;
  },
  function (error) {
    checkForLogout(error.response);
    return Promise.reject(error);
  }
);

function checkForLogout(response) {
  if (
    [100005, 100006, 100007, 100008, 100052].indexOf(response.data.code) >= 0
  ) {
    document.cookie = "info=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie =
      "infoOne=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    setTimeout(() => {
      history.push("/");
    }, 200);
    if (response.data.code === 100008) {
      alert("User logged out");
    }
    if (response.data.code === 100006) {
      alert("Session time out login again");
    }
  }
}
