import React, { useState, useEffect } from "react";
import CustomCreateJob from "./CustomCreateJob";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import { setUserName } from "../../../actions/index";
import { showLoader, showSnackBar } from "../../../actions/index";
import endPoints, { dEndPoints } from "../../../config/end-point";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TopNav from "../../topnav/TopNav";
import SideNav from "../../sidenav/SideNav";
const UploadJd = (props) => {
  return (
    <section className="browse__resume separator__tq">
      <Form>
        <div className="resume__upload">
          <div className="buttons__Group">
            <div className="browser__Btn">
              <label htmlFor="uploadJd">Browse JD</label>
              <input type="file" id="uploadJd" onChange={props.uploadJdFile} />
              {props.fileName !== "" ? (
                <span>Uploaded {props.fileName} </span>
              ) : (
                ""
              )}
            </div>
            <a href="/createjob" className="btn btn-success">
              Custom Details
            </a>
          </div>
          <div className="upload__instruction">
            <h3>Drop your Job Description here to upload</h3>
            <p>
              You can upload .doc, .docx, and .pdf file formats here. Once You
              upload we'll populate the most of the fields.
            </p>
          </div>
        </div>
      </Form>
    </section>
  );
};
let Editor, htmlToDraft;
if (typeof window !== "undefined") {
  htmlToDraft = require("html-to-draftjs").default;
}
if (typeof window !== "undefined") {
  Editor = require("react-draft-wysiwyg").Editor;
}
function gettext(pdfUrl) {
  var pdf = window.pdfjsLib.getDocument(pdfUrl);
  return pdf.promise.then(function (pdf) {
    // get all pages text
    var maxPages = pdf._pdfInfo.numPages;
    var countPromises = []; // collecting all page promises
    for (var j = 1; j <= maxPages; j++) {
      var page = pdf.getPage(j);

      //var txt = "";
      countPromises.push(
        page.then(function (page) {
          // add page promise
          var textContent = page.getTextContent();
          return textContent.then(function (text) {
            // return content promise
            return text.items
              .map(function (s) {
                return s.str;
              })
              .join(""); // value page text
          });
        })
      );
    }
    // Wait for all pages and join text
    return Promise.all(countPromises).then(function (texts) {
      return texts.join("");
    });
  });
}
const CreateJob = (props) => {
  const [status, setStatus] = useState("New");
  const [jdFile, setJdFile] = useState("");
  const [extraField, setExtraField] = useState([]);
  const [fileName, setFileName] = useState("");
  const [jdFileType, setjdFileType] = useState("");
  const [jdType, setjdType] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [extractingJd, setExtractingJd] = useState(false);
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [resumeCount, setResumeCount] = useState(0);
  const [job, setJob] = useState({
    reqId: "",
    qual: [],
    desig: "",
    pSkills: [],
    sSkills: [],
    totpos: "",
    minBudget: "",
    maxBudget: "",
    nPeriod: "",
    validFor: "",
    rate: "",
    vRate: "",
    paymentTerm: "",
    payment: "",
    expFrom: "",
    expTo: "",
    jobDesc: "",
    cId: "",
    jobType: "",
    cooList: [],
    cName: "",
    extraDetails: "",
    extraFields: [],
    conPer: {
      name: "",
      phNum: "",
    },
    city: [],
    pref: {
      skills: 1,
      expr: 1,
      eCtc: 0.75,
      city: 0.75,
      company: 0.75,
      desig: 0.75,
      qual: 0.75,
    },
    coOwners: [],
    closedComment: [],
    talenqPayRoll: "",
    cInfo: {
      tenPercent: false,
      twelvePercent: false,
      pgPercent: false,
      ugPercent: false,
      pan: false,
    },

    walkinD: {
      walkInDate: new Date().toISOString().split("T")[0],
      walkInContactPerson: "",
    },
  });
  if (jdFile) {
    job.jdFile = jdFile;
  }
  const path = job.jdFile && job.jdFile.fname;

  useEffect(() => {
    loadCreateJobPanel();

    if (job.jdFile) {
      downloadJdFile(true);
    }

    uploadJdFileAfterExtract(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, jdFile.fname]);

  const handlePastedText = (text, html) => {
    // if they try to paste something they shouldn't let's handle it
    if (text.indexOf("text that should not be pasted") !== -1) {
      // we'll add a message for the offending user to the editor state
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "nice try, chump!"
      );

      // update our state with the new editor content
      onChange(EditorState.push(editorState, newContent, "insert-characters"));
      return true;
    } else {
      return false;
    }
  };
  const onChange = (editorState) => {
    seteditorState("editorState", editorState);
  };
  const onEditorStateChange = (text) => {
    seteditorState(text);
    var cjob = { ...job };
    if (editorState) {
      cjob.jobDesc = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }
    setJob(cjob);
  };
  const downloadJdFile = (preview = false) => {
    const path = job && job.jdFile.fname;
    const resumeComp = path.split("/");

    const fName = resumeComp[resumeComp.length - 1];
    props.showLoader({ show: true });
    axios
      .get(endPoints.uploadFile, {
        params: {
          fileType: "Jd",
          fileUrl: path,
        },
        responseType: "blob",
      })
      .then((res) => {
        props.showLoader({ show: false });
        var blob = new Blob([res.data], { type: res.headers["content-type"] });
        const href = window.URL.createObjectURL(blob);

        if (preview) {
          setFileUrl(href);
        } else {
          var link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fName);
          link.click();
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error in download file", error);
      });
  };
  var jobInfo;
  const loadCreateJobPanel = () => {
    const corporateId = localStorage.getItem("ci");
    const {
      match: { params },
    } = props;

    if (params.jobId) {
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.corporateJobDetails(corporateId, params.jobId))
        .then((res) => {
          props.showLoader({ show: false });

          var data = res.data;

          if (data && data.code === 100000) {
            if (data && data.data && data.data.jobData) {
              jobInfo = data.data.jobData;

              setExtraField(jobInfo.extraFields);
              if (jobInfo.cInfo && jobInfo.cInfo["tenPercent"]) {
                jobInfo.cInfo["tenPercent"] = Boolean(
                  jobInfo.cInfo["tenPercent"]
                );
              }
              if (jobInfo.cInfo && jobInfo.cInfo["twelvePercent"]) {
                jobInfo.cInfo["twelvePercent"] = Boolean(
                  jobInfo.cInfo["twelvePercent"]
                );
              }
              if (jobInfo.cInfo && jobInfo.cInfo["pgPercent"]) {
                jobInfo.cInfo["pgPercent"] = Boolean(
                  jobInfo.cInfo["pgPercent"]
                );
              }
              if (jobInfo.cInfo && jobInfo.cInfo["ugPercent"]) {
                jobInfo.cInfo["ugPercent"] = Boolean(
                  jobInfo.cInfo["ugPercent"]
                );
              }
              if (jobInfo.cInfo && jobInfo.cInfo["pan"]) {
                jobInfo.cInfo["pan"] = Boolean(jobInfo.cInfo["pan"]);
              }
              let totalResumeCount = 0;
              const statusInfo = Object.keys(jobInfo.rCt);
              statusInfo.forEach((status) => {
                totalResumeCount += jobInfo.rCt[status];
              });

              setResumeCount(totalResumeCount);
              setJob(jobInfo);
              setStatus(data.data.jobData.status);
              const jobDesc = data.data.jobData.jobDesc;
              if (!jobDesc) {
                return null;
              }
              const blocksFromHtml = htmlToDraft(jobDesc);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              );
              seteditorState(EditorState.createWithContent(contentState));
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          props.showLoader({ show: false });
        });
    }
  };
  const uploadJdFile = (e) => {
    const fileName = e.target.files[0].name;

    setFileName(fileName);
    if (status === "New") {
      setJob({
        reqId: "",
        qual: [],
        desig: "",
        pSkills: [],
        sSkills: [],
        totpos: "",
        minBudget: "",
        maxBudget: "",
        nPeriod: "",
        validFor: "",
        expFrom: "",
        expTo: "",
        city: [],
        extraFields: [],
        pref: {
          skills: 1,
          expr: 1,
          eCtc: 0.75,
          city: 0.75,
          company: 0.75,
          desig: 0.75,
          qual: 0.75,
        },
        walkinD: {
          walkInDate: new Date().toISOString().split("T")[0],
          walkInContactPerson: "",
        },
      });
      seteditorState(EditorState.createEmpty());
    }

    if (status === "New") {
      extractJd(e.target.files[0]);
    }
    var form = new FormData();
    form.append("fileData", e.target.files[0]);
    form.append("size", e.target.files[0].size);
    form.append("fileType", fileName);
    props.showLoader({ show: true });

    axios
      .post(endPoints.uploadFile + "?fileType=Jd", form)

      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;

        if (data && data.code === 100000) {
          const filePath = data && data.data.filePath;

          setJdFile({ fname: filePath });

          var ext = filePath.split(".");
          var typejd = ext[ext.length - 1];
          setjdFileType(typejd.toLowerCase());
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };

  const uploadJdFileAfterExtract = (preview = false) => {
    const resumeComp = jdFile && jdFile.fname.split("/");

    const fName = resumeComp[resumeComp.length - 1];
    props.showLoader({ show: true });
    axios
      .get(endPoints.uploadFile, {
        params: {
          fileType: "Jd",
          fileUrl: jdFile.fname,
        },
        responseType: "blob",
      })
      .then((res) => {
        props.showLoader({ show: false });
        var blob = new Blob([res.data], { type: res.headers["content-type"] });

        const href = window.URL.createObjectURL(blob);

        if (preview) {
          setjdType(href);
        } else {
          var link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fName);
          link.click();
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error in download file", error);
      });
  };

  const extractJd = (file) => {
    setExtractingJd(true);
    const cId = localStorage.getItem("ci");
    const jobsID = "";
    var form = new FormData();
    form.append("file[]", file);
    var jobId = props.match.params.jobId;
    if (jobId) {
      axios
        .put(dEndPoints.extracJd(cId, jobsID), form)
        .then((res) => {})
        .catch((error) => {
          console.log("error file", error);
          var reader = new FileReader();
          reader.onload = function (e) {
            gettext(e.target.result).then(
              function (text) {
                // alert(text);
                extractTextFromJd(text);
              },
              function (reason) {
                console.error(reason);
              }
            );
          };
          reader.readAsArrayBuffer(file);
        });
    } else {
      axios
        .post(dEndPoints.extracJd(cId, jobsID), form)
        .then((res) => {
          setExtractingJd(false);
          var data = res.data;
          // const replaceRegex = /' '/g;
          // const replaceSQuoteRegex = /'/g;
          data.pSkills = data.pSkills;
          data.sSkills = data.sSkills;
          setJob({
            ...data,
            pref: {
              skills: 1,
              expr: 1,
              eCtc: 0.75,
              city: 0.75,
              company: 0.75,
              desig: 0.75,
              qual: 0.75,
            },
            walkinD: {
              walkInDate: new Date().toISOString().split("T")[0],
            },
          });

          if (!data.jobDesc) {
            return null;
          }
          const blocksFromHtml = htmlToDraft(data.jobDesc);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          seteditorState(EditorState.createWithContent(contentState));
        })
        .catch((error) => {
          console.log("error file", error);
          setExtractingJd(false);
          var reader = new FileReader();
          reader.onload = function (e) {
            gettext(e.target.result).then(
              function (text) {
                extractTextFromJd(text);
              },
              function (reason) {
                console.error(reason);
              }
            );
          };
          reader.readAsArrayBuffer(file);
        });
    }
  };
  const extractTextFromJd = (text) => {
    const cId = localStorage.getItem("ci");
    const jobId = "";
    const data = {
      content: text,
    };
    axios
      .post(dEndPoints.predictionJd(cId, jobId), data)
      .then((res) => {})
      .catch((error) => {});
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <div className="create__job-page">
            <div className="tq__page-header justify-content-center">
              {" "}
              <h2 className="page-header">Create Jobs</h2>
            </div>
            <UploadJd
              uploadJdFile={uploadJdFile}
              fileName={fileName}
              job={job}
              setJob={setJob}
              seteditorState={seteditorState}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              handlePastedText={handlePastedText}
            />
            <CustomCreateJob
              jdFile={job.jdFile}
              job={job}
              extraField={extraField}
              className="mt-5"
              setJob={setJob}
              status={status}
              extractingJd={extractingJd}
              seteditorState={seteditorState}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              handlePastedText={handlePastedText}
              resumeCount={resumeCount}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CreateJob);
