import React, { useEffect, useState } from "react";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import UnderSubscribedJobs from "../jobcards/UnderSubscribedJobs";
import FindJobCard from "../jobcards/FindJobCard";
import Badge from "../badge/Badge";
//import jobData from "../../assets/jsonDatas/findJobs.json";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import CircularRSCounter from "../badge/CircularRSCounter";
// import Pagination from "react-bootstrap/Pagination";
import Pagination from "@material-ui/lab/Pagination";
import TopNav from "../topnav/TopNav";
import SideNav from "../sidenav/SideNav";
import GoBackButton from "../badge/GoBackButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../../actions/index";
import { showSnackBar } from "../../actions/index";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    paginationCenter: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
  })
);
const FindJobs = (props) => {
  const styles = useStyles();
  // const [defaultBucket, setDefaultBucket] = useState([]);
  const [serchInput, setSearchInput] = useState("");
  const [jobData, setJobData] = useState([]);
  const [linkedJobs, setLinkedJobs] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [jobCount, setJobCount] = useState(0);
  const [vendorSliderData, setVendorSliderData] = useState([]);
  const userType = localStorage.getItem("ut");
  useEffect(() => {
    handleSearchJob("", pageNum);
    undersubscribedJobs();
  }, [pageNum]);

  const handleSearchJob = (serchInput, pageNum) => {
    const vendorCorporateId = localStorage.getItem("vci");
    const data = {
      keywords: serchInput,
    };
    if (userType === "1000") {
      props.showLoader({ show: true });
      axios
        .post(dEndPoints.findRequirement(vendorCorporateId), data, {
          params: {
            limit: 12,
            pageNumber: pageNum,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });

          if (res.data.data.jobs) {
            setJobData(res.data.data.jobs);
            setLinkedJobs(res.data.data.linkedJob);
            setJobCount(Math.floor(data.jobCount / 12) + 1);
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error", error);
        });
    }
  };

  var linkjobIds = linkedJobs.map((job) => {
    return job.jobId;
  });
  // Load More Jobs ////
  const loadMoreJobs = () => {
    setPageNum(pageNum + 1);
    handleSearchJob("", pageNum + 1);
  };
  // const loadPrevJobs = (event, value) => {
  //   if (pageNum === 0) {
  //     setPageNum((pageNum = 1));
  //   } else {
  //     setPageNum(pageNum - 1);
  //   }
  //   // handleSearchClick(value - 1);
  // };
  const undersubscribedJobs = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    axios
      .get(dEndPoints.undersubscribedJobs(vendorCorporateId))
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100000) {
          // setJobData(data.data.jobs);
          setVendorSliderData(data.data.jobs);
          //setUndersubscribedJobs(data.data.jobs)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  // const DefaultBucketDashboard = (props) => {
  //   return (
  //     <div className="status-card default__bucket">
  //       <div className="status-card__icon">
  //         <img src={props.icon} alt={props.icon} />
  //       </div>
  //       <div className="status-card__info">
  //         <h4 className="card__Count">{props.count}</h4>
  //         <span className="card__title">{props.title}</span>
  //       </div>
  //     </div>
  //   );
  // };
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <div className="find__jobs-page mb-5">
            <Row>
              <Col xs={1}>
                <GoBackButton />
              </Col>
              <Col xs={11}>
                <div className="find__job-search">
                  <i className="bx bx-search"></i>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    value={serchInput}
                    placeholder="Search By keywords (PHP,.NET,graphic, etc.)..."
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleSearchJob(serchInput)}
                  >
                    Search
                  </Button>
                </div>
              </Col>
              {/* <Col sm={12} className="mb-4 mt-5">
                 <h3 className="dashboard__sub-heading mb-5">Job Summary</h3>
                <Row>
                  {defaultBucket.map((item, index) => (
                    <div className="col-3 mb-3" key={index}>
                      <Link to={`/bucketdetails?${item.query}`}>
                        <DefaultBucketDashboard
                          icon={item.icon}
                          count={item.counts}
                          title={item.title}
                        />
                      </Link>
                    </div>
                  ))}
                </Row> 
              </Col> */}
              {jobData.map((item, index) => (
                <Col md={4} className="mt-5" key={index}>
                  <FindJobCard
                    _id={item._id}
                    badge={<Badge type={item.status} content={item.status} />}
                    ChartDatas={
                      <CircularRSCounter
                        id={item._id}
                        //id={12}
                        resumeStatus={item.jobFillStatus}
                        //resumeStatus={20}
                      />
                    }
                    jobLink={item.jobLink}
                    // enrolled={item.enrolled}
                    jobOfTheDay={item.jobOfTheDay}
                    rate={item.vRate}
                    notice={item.nPeriod}
                    jodstatus={item.jodstatus}
                    jobType={item.jobType}
                    reqId={item.reqId}
                    Heading={item.desig}
                    posted={new Date(item.cOn).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                    company={item.cName}
                    skills={item.pSkills.toString()}
                    location={item.city + "    "}
                    enrolled={linkjobIds.indexOf(item._id) > -1 ? true : false}
                  />
                </Col>
              ))}
              <Col md={12} className="mt-1">
                <Pagination
                  className={styles.paginationCenter}
                  count={jobCount}
                  page={pageNum}
                  onChange={loadMoreJobs}
                  variant="outlined"
                  color="primary"
                />
              </Col>

              <Col md={12} className="mt-3"></Col>
              <>
                <UnderSubscribedJobs
                  className="mt-5 mb-4 separator__tq"
                  sliderData={vendorSliderData}
                  linkjobIds={linkjobIds}
                />
              </>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

// export default FindJobs;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(FindJobs));
