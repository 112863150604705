import React, { useState, useEffect } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import endPoints from "../../../config/end-point";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "../../../common/mataerialKit/components/Card/Card.jsx";
import { showSnackBar, showLoader } from "../../../actions/index";
// import { CountdownCircleTimer } from "react-countdown-circle-timer";

let FileViewer = null;
if (typeof window !== "undefined") {
  FileViewer = require("react-file-viewer");
}

const RecommendResume = (props) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  //if (props.resume && props.resume.path) {
  var path1 = props.resume ? props.resume.path : "";
  //}
  //if (props.resume && props.resume.n_fpath) {
  var path = props.resume ? props.resume.n_fpath : "";
  //}
  //var fname;
  //if (props.resume && props.resume.fname) {
  var fname1 = props.resume ? props.resume.fname : "";
  // }
  // if (props.resume && props.resume.n_ftype) {
  var fname = props.resume ? props.resume.n_fpath : "";
  // }
  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });
  useEffect(() => {
    downloadResume(true);
  }, [path, path1]);
  var ext, type;
  if (fname) {
    ext = fname.split(".");
    type = ext[ext.length - 1];
  }

  var ext1 = fname1 ? fname1.split(".") : "";
  var type1 = ext1[ext1.length - 1];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const downloadResume = (preview = false) => {
    const resumeComp = path1 ? path1.split("/") : "";
    const resumeComp1 = path ? path.split("/") : "";
    const fName = resumeComp[resumeComp.length - 1];
    const fName1 = resumeComp1[resumeComp1.length - 1];
    if (path) {
      props.showLoader({ show: true });
      axios
        .get(endPoints.uploadFile, {
          params: {
            fileType: "Resume",
            fileUrl: path,
          },
          responseType: "blob",
        })
        .then((res) => {
          props.showLoader({ show: false });
          var blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });

          const href = window.URL.createObjectURL(blob);

          if (preview) {
            setFileUrl(href);
          } else {
            var link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fName1);
            link.click();
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error in download file", error);
        });
    }
    if (path1) {
      props.showLoader({ show: true });
      axios
        .get(endPoints.uploadFile, {
          params: {
            fileType: "Resume",
            fileUrl: path1,
          },
          responseType: "blob",
        })
        .then((res) => {
          props.showLoader({ show: false });
          var blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });

          const href = window.URL.createObjectURL(blob);
          if (preview) {
            setFileUrl(href);
          } else {
            var link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fName);
            link.click();
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error in download file", error);
        });
    }
  };

  return (
    <div className="ResumeView-Page position-relative">
      <Card
        className="resume__card"
        style={{
          overflowY: "scroll",
          height: "35rem",
        }}
      >
        {fileUrl &&
        type1 &&
        type1.toLowerCase() === "docx" &&
        type === undefined ? (
          <div>
            <FileViewer
              key={fileUrl}
              fileType={type1 ? type1.toLowerCase() : ""}
              filePath={fileUrl}
            />
          </div>
        ) : (fileUrl && type && type.toLowerCase() === "pdf") ||
          (fileUrl && type1 && type1.toLowerCase() === "pdf") ? (
          <>
            <div>
              <div className="pagec">
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </div>
              <div className="buttonc">
                <Button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className="Pre"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Next
                </Button>
              </div>
            </div>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                width={790}
                height={100}
                style={{ border: "2px" }}
              />
            </Document>
          </>
        ) : type1 && type1.toLowerCase() === "doc" ? (
          <div
            style={{
              height: "28rem",
              overflow: "hidden",
              marginTop: "100px",
              textAlign: "center",
            }}
          >
            .doc file format is not supported, Please download file to view
            Resume
          </div>
        ) : (
          ""
        )}

        <div
          className="download__resume-button"
          onClick={() => downloadResume()}
        >
          <i className="bx bx-file"></i>{" "}
          <span className="text">Download Resume</span>
        </div>
      </Card>
    </div>
  );
};
//export default ResumeView;

function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RecommendResume);
