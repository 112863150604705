import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
const JobTypeSelect = (props) => {
  const [value, setValue] = useState("Permanent");

  function handleInputChange(event) {
    var cjob = { ...props.job };
    if (event.target.name === "jobType") {
      if (event.target.value === "Walkin") {
        cjob.walkin = true;
      } else {
        cjob.walkin = false;
      }
      cjob[event.target.name] = event.target.value;
    } else if (event.target.name === "talenqPayRoll") {
      cjob[event.target.name] = event.target.value;
    } else if (
      cjob.walkin &&
      (event.target.id === "walkInVenue" ||
        event.target.id === "walkinContactPerson" ||
        event.target.id === "walkInTime")
    ) {
      if (cjob.walkinD) {
        cjob.walkinD[event.target.id] = event.target.value;
      } else {
        cjob.walkinD = {};
        cjob.walkinD[event.target.id] = event.target.value;
      }
    }
    props.setJob({ ...props.job, ...cjob });
  }
  // const handleChange = (val) => {
  //   var cjob = { ...props.job };

  //   cjob.jobType = val;
  //   setValue(val);
  //   props.setJob({ ...props.job, ...cjob });
  // };
  return (
    <div className="Job__type_select text-center card__view-tq">
      <div className="mb-4">
        <h2 className="pageHeadings">Select Job Type</h2>
        <small> (Corporate)</small>
      </div>
      <Row className="mb-4">
        <Form noValidate validated={props.validated}>
          <Form.Group as={Col} className="select__job-type" name="jobType">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="jobType"
                id="tbg-permanent"
                value="Permanent"
                checked={props.job && props.job.jobType === "Permanent"}
                onChange={handleInputChange}
              />
              <label className="form-check-label" for="tbg-permanent">
                Permanent
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="jobType"
                value="Contractor"
                id="tbg-contract"
                checked={props.job && props.job.jobType === "Contractor"}
                onChange={handleInputChange}
                // onChange={handleChange}
              />
              <label className="form-check-label" for="tbg-contract">
                Contract
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="jobType"
                value="Walkin"
                id="tbg-walkin"
                checked={props.job && props.job.jobType === "Walkin"}
                onChange={handleInputChange}
                // onChange={handleChange}
              />
              <label className="form-check-label" for="tbg-walkin">
                Walk In
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="jobType"
                value="Executive"
                id="tbg-executive"
                checked={props.job && props.job.jobType === "Executive"}
                onChange={handleInputChange}
                // onChange={handleChange}
              />
              <label className="form-check-label" for="tbg-executive">
                Executive
              </label>
            </div>
            <p className="text-danger" style={{ fontSize: "13px" }}>
              {!props.job.jobType ? props.formerrors.jobTypeError : ""}
            </p>
          </Form.Group>
        </Form>
        <>
          {props.job && props.job.jobType === "Walkin" ? (
            <div className="contract__select tq-form row">
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                className="position-relative text-left"
              >
                <Form.Label>
                  Venue Details
                  <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="walkInVenue"
                  value={
                    props.job && props.job.walkinD
                      ? props.job.walkinD.walkInVenue
                      : ""
                  }
                  placeholder="Venue"
                  onChange={handleInputChange}
                />
                <p className="text-danger" style={{ fontSize: "13px" }}>
                  {!props.job.walkinD.walkInVenue
                    ? props.formerrors.walkinVenueError
                    : ""}
                </p>
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                className="position-relative text-left mt-3"
              >
                <Form.Label>
                  The interview starts at
                  <small className="required__form">*</small>
                </Form.Label>
                <Form.Control
                  type="time"
                  id="walkInTime"
                  placeholder="HH:MM"
                  value={
                    props.job && props.job.walkinD
                      ? props.job.walkinD.walkInTime
                      : ""
                  }
                  onChange={handleInputChange}
                />
                <p className="text-danger" style={{ fontSize: "13px" }}>
                  {!props.job.walkinD.walkInTime
                    ? props.formerrors.walkinTimeError
                    : ""}
                </p>
              </Form.Group>
            </div>
          ) : (
            ""
          )}
          {props.job.jobType === "Contractor" ? (
            <div className="contract__select">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="talenqPayRoll"
                  value="Talenq Payroll"
                  id="tq-payroll"
                  role="switch"
                  checked={
                    props.job && props.job.talenqPayRoll === "Talenq Payroll"
                  }
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="tq-payroll">
                  Talenq Payroll
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  value="Self Payroll"
                  name="talenqPayRoll"
                  role="switch"
                  id="slf-payroll"
                  checked={
                    props.job && props.job.talenqPayRoll === "Self Payroll"
                  }
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="slf-payroll">
                  Self Payroll
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      </Row>
    </div>
  );
};
export default JobTypeSelect;
