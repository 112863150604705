const skillSet = [
  {
    _id: "5f9663fae6cc6977282281c5",
    name: "Data Analysis",
  },
  {
    _id: "5f9663fae6cc6977282281c6",
    name: "Product Development",
  },
  {
    _id: "5f9663fae6cc6977282281c7",
    name: "Software Development",
  },
  {
    _id: "5f9663fae6cc6977282281c8",
    name: "Graphic Design",
  },
  {
    _id: "5f9663fae6cc6977282281c9",
    name: "Digital Marketing",
  },

  {
    _id: "5f9663fae6cc6977282281ce",
    name: "Web Development",
  },
  {
    _id: "5f9663fae6cc6977282281cf",
    name: "Databases",
  },
  {
    _id: "5f9663fae6cc6977282281d0",
    name: "Web Design",
  },

  {
    _id: "5f9663fae6cc6977282281d2",
    name: "Software Development Life Cycle (SDLC)",
  },

  {
    _id: "5f9663fae6cc6977282281d4",
    name: "Oracle Database",
  },
  {
    _id: "5f9663fae6cc6977282281d5",
    name: "Digital Media",
  },
  {
    _id: "5f9663fae6cc6977282281d6",
    name: "Program Development",
  },
  {
    _id: "5f9663fae6cc6977282281d7",
    name: "Adobe Photoshop",
  },
  {
    _id: "5f9663fae6cc6977282281d8",
    name: "Account Management",
  },
  {
    _id: "5f9663fae6cc6977282281d9",
    name: "Analytical Skills",
  },
  {
    _id: "5f9663fae6cc6977282281da",
    name: "AutoCAD",
  },
  {
    _id: "5f9663fae6cc6977282281db",
    name: "Financial Analysis",
  },
  {
    _id: "5f9663fae6cc6977282281dc",
    name: "Business Analysis",
  },
  {
    _id: "5f9663fae6cc6977282281dd",
    name: "Accounting",
  },
  {
    _id: "5f9663fae6cc6977282281de",
    name: "Advertising",
  },

  {
    _id: "5f9663fae6cc6977282281e0",
    name: "Adobe Illustrator",
  },
  {
    _id: "5f9663fae6cc6977282281e1",
    name: "Software as a Service (SaaS)",
  },

  {
    _id: "5f9663fae6cc6977282281e3",
    name: "Adobe InDesign",
  },
  {
    _id: "5f9663fae6cc6977282281e4",
    name: "Agile Methodologies",
  },
  {
    _id: "5f9663fae6cc6977282281e5",
    name: "Online Advertising",
  },
  {
    _id: "5f9663fae6cc6977282281e6",
    name: "Auditing",
  },
  {
    _id: "5f9663fae6cc6977282281e7",
    name: "Financial Accounting",
  },
  {
    _id: "5f9663fae6cc6977282281e8",
    name: "Adobe Creative Suite",
  },
  {
    _id: "5f9663fae6cc6977282281e9",
    name: "Sales",
  },

  {
    _id: "5f9663fae6cc6977282281ee",
    name: "Time Management",
  },

  {
    _id: "5f9663fae6cc6977282281f0",
    name: "Human Resources (HR)",
  },

  {
    _id: "5f9663fae6cc6977282281f2",
    name: "Photography",
  },

  {
    _id: "5f9663fae6cc6977282281fe",
    name: "Microsoft Office",
  },
  {
    _id: "5f9663fae6cc6977282281ff",
    name: "Office Administration",
  },
  {
    _id: "5f9663fae6cc697728228200",
    name: "Administration",
  },
  {
    _id: "5f9663fae6cc697728228201",
    name: "Microsoft Excel",
  },
  {
    _id: "5f9663fae6cc697728228202",
    name: "System Administration",
  },

  {
    _id: "5f9663fae6cc697728228204",
    name: "Microsoft Word",
  },
  {
    _id: "5f9663fae6cc697728228205",
    name: "Network Administration",
  },
  {
    _id: "5f9663fae6cc697728228206",
    name: "Microsoft PowerPoint",
  },
  {
    _id: "5f9663fae6cc697728228207",
    name: "Adobe Premiere Pro",
  },

  {
    _id: "5f9663fae6cc697728228209",
    name: "Marketing",
  },

  {
    _id: "5f9663fae6cc69772822820b",
    name: "Google Ads",
  },
  {
    _id: "5f9663fae6cc69772822820c",
    name: "Adept problem-solver",
  },

  {
    _id: "5f9663fae6cc697728228214",
    name: "Aerospace Engineering",
  },
  {
    _id: "5f9663fae6cc697728228215",
    name: "Automated External Defibrillator (AED)",
  },
  {
    _id: "5f9663fae6cc697728228216",
    name: "Aerodynamics",
  },

  {
    _id: "5f9663fae6cc69772822821a",
    name: "Aerospace Manufacturing",
  },
  {
    _id: "5f9663fae6cc69772822821b",
    name: "Adobe Experience Manager (AEM)",
  },

  {
    _id: "5f9663fae6cc69772822821f",
    name: "Aerospace Structures",
  },

  {
    _id: "5f9663fae6cc697728228222",
    name: "Aerospace Medicine",
  },

  {
    _id: "5f9663fae6cc697728228226",
    name: "Data Warehousing",
  },
  {
    _id: "5f9663fae6cc697728228227",
    name: "Data Mining",
  },
  {
    _id: "5f9663fae6cc697728228228",
    name: "Statistical Data Analysis",
  },
  {
    _id: "5f9663fae6cc697728228229",
    name: "Big Data",
  },
  {
    _id: "5f9663fae6cc69772822822a",
    name: "Data Modeling",
  },
  {
    _id: "5f9663fae6cc69772822822b",
    name: "Data Management",
  },
  {
    _id: "5f9663fae6cc69772822822c",
    name: "Data Migration",
  },
  {
    _id: "5f9663fae6cc69772822822d",
    name: "Data Structures",
  },
  {
    _id: "5f9663fae6cc69772822822e",
    name: "Data Analytics",
  },
  {
    _id: "5f9663fae6cc69772822822f",
    name: "Data Visualization",
  },
  {
    _id: "5f9663fae6cc697728228230",
    name: "Data Science",
  },
  {
    _id: "5f9663fae6cc697728228231",
    name: "Database Administration",
  },
  {
    _id: "5f9663fae6cc697728228232",
    name: "Data Collection",
  },
  {
    _id: "5f9663fae6cc697728228233",
    name: "Database Design",
  },

  {
    _id: "5f9663fae6cc697728228236",
    name: "Business Development",
  },
  {
    _id: "5f9663fae6cc697728228237",
    name: "Budgeting",
  },
  {
    _id: "5f9663fae6cc697728228238",
    name: "Business Planning",
  },

  {
    _id: "5f9663fae6cc69772822823a",
    name: "Banking",
  },
  {
    _id: "5f9663fae6cc69772822823b",
    name: "Business Intelligence (BI)",
  },
  {
    _id: "5f9663fae6cc69772822823c",
    name: "Business-to-Business (B2B)",
  },

  {
    _id: "5f9663fae6cc69772822823e",
    name: "Blogging",
  },
  {
    _id: "5f9663fae6cc69772822823f",
    name: "Business Process",
  },
  {
    _id: "5f9663fae6cc697728228240",
    name: "Management",
  },
  {
    _id: "5f9663fae6cc697728228241",
    name: "Brand Development",
  },

  {
    _id: "5f9663fae6cc697728228243",
    name: "Leadership",
  },
  {
    _id: "5f9663fae6cc697728228244",
    name: "Brand Management",
  },
  {
    _id: "5f9663fae6cc697728228245",
    name: "BCM",
  },

  {
    _id: "5f9663fae6cc697728228247",
    name: "Business Continuity Planning",
  },

  {
    _id: "5f9663fae6cc69772822824f",
    name: "Social Media",
  },
  {
    _id: "5f9663fae6cc697728228250",
    name: "Broadcasting",
  },
  {
    _id: "5f9663fae6cc697728228251",
    name: "Branding & Identity",
  },
  {
    _id: "5f9663fae6cc697728228252",
    name: "Marketing Strategy",
  },
  {
    _id: "5f9663fae6cc697728228253",
    name: "Brochures",
  },
  {
    _id: "5f9663fae6cc697728228254",
    name: "Corporate Branding",
  },
  {
    _id: "5f9663fae6cc697728228255",
    name: "Event Planning",
  },
  {
    _id: "5f9663fae6cc697728228256",
    name: "Branding",
  },

  {
    _id: "5f9663fae6cc697728228258",
    name: "Broadcast Television",
  },

  {
    _id: "5f9663fae6cc69772822825a",
    name: "Brand Awareness",
  },
  {
    _id: "5f9663fae6cc69772822825b",
    name: "Broadcast Journalism",
  },
  {
    _id: "5f9663fae6cc69772822825c",
    name: "Radio Broadcasting",
  },

  {
    _id: "5f9663fae6cc697728228267",
    name: "Journalism",
  },

  {
    _id: "5f9663fae6cc697728228269",
    name: "C#",
  },
  {
    _id: "5f9663fae6cc69772822826a",
    name: "Customer Service",
  },
  {
    _id: "5f9663fae6cc69772822826b",
    name: "C (Programming Language)",
  },

  {
    _id: "5f9663fae6cc69772822826d",
    name: "Communication",
  },
  {
    _id: "5f9663fae6cc69772822826e",
    name: "Coaching",
  },
  {
    _id: "5f9663fae6cc69772822826f",
    name: "Customer Relationship Management (CRM)",
  },

  {
    _id: "5f9663fae6cc697728228271",
    name: "Construction",
  },
  {
    _id: "5f9663fae6cc697728228272",
    name: "Customer Satisfaction",
  },

  {
    _id: "5f9663fae6cc697728228274",
    name: "Cascading Style Sheets (CSS)",
  },

  {
    _id: "5f9663fae6cc697728228276",
    name: "Supply Chain Management",
  },
  {
    _id: "5f9663fae6cc697728228277",
    name: "Cross-functional Team Leadership",
  },

  {
    _id: "5f9663fae6cc69772822827b",
    name: "Closed-Circuit Television (CCTV)",
  },
  {
    _id: "5f9663fae6cc69772822827c",
    name: "Networking",
  },
  {
    _id: "5f9663fae6cc69772822827d",
    name: "IP CCTV",
  },

  {
    _id: "5f9663fae6cc69772822827f",
    name: "Adobe Lightroom",
  },

  {
    _id: "5f9663fae6cc697728228281",
    name: "Cisco Certified Internetwork Expert (CCIE)",
  },

  {
    _id: "5f9663fae6cc697728228283",
    name: "Cisco Certified Entry Networking Technician (CCENT)",
  },
  {
    _id: "5f9663fae6cc697728228284",
    name: "Computer Networking",
  },
  {
    _id: "5f9663fae6cc697728228285",
    name: "Cisco-Certified Design Associate (CCDA)",
  },

  {
    _id: "5f9663fae6cc697728228288",
    name: "CCNP Security",
  },
  {
    _id: "5f9663fae6cc697728228289",
    name: "Cisco Certified Design Professional (CCDP)",
  },
  {
    _id: "5f9663fae6cc69772822828a",
    name: "CCU",
  },
  {
    _id: "5f9663fae6cc69772822828b",
    name: "Computer-Aided Design (CAD)",
  },
  {
    _id: "5f9663fae6cc69772822828c",
    name: "Project Management",
  },

  {
    _id: "5f9663fae6cc69772822828f",
    name: "Root Cause Analysis",
  },
  {
    _id: "5f9663fae6cc697728228290",
    name: "Capital Markets",
  },
  {
    _id: "5f9663fae6cc6977282282a6",
    name: "Continuous Integration and Continuous Delivery (CI/CD)",
  },

  {
    _id: "5f9663fae6cc6977282282bc",
    name: "SQL",
  },
  {
    _id: "5f9663fae6cc6977282282bd",
    name: "Program Management",
  },

  {
    _id: "5f9663fae6cc6977282282d4",
    name: "Certified Financial Planner",
  },
  {
    _id: "5f9663fae6cc6977282282d5",
    name: "cGMP practices",
  },

  {
    _id: "5f9663fae6cc6977282282d8",
    name: "Computer-Generated Imagery (CGI)",
  },
  {
    _id: "5f9663fae6cc6977282282d9",
    name: "CGI/Perl",
  },
  {
    _id: "5f9663fae6cc6977282282da",
    name: "Computer Graphics",
  },
  {
    _id: "5f9663fae6cc6977282282db",
    name: "CG Lighting",
  },
  {
    _id: "5f9663fae6cc6977282282dc",
    name: "CGI programming",
  },

  {
    _id: "5f9663fae6cc6977282282e6",
    name: "Chemical Engineering",
  },
  {
    _id: "5f9663fae6cc6977282282e7",
    name: "High-Performance Liquid Chromatography (HPLC)",
  },

  {
    _id: "5f9663fae6cc6977282282f0",
    name: "Civil Engineering",
  },
  {
    _id: "5f9663fae6cc6977282282f1",
    name: "Civil Litigation",
  },

  {
    _id: "5f9663fae6cc6977282282f4",
    name: "Civil Aviation",
  },

  {
    _id: "5f9663fae6cc6977282282fd",
    name: "Cloud Computing",
  },

  {
    _id: "5f9663fae6cc697728228306",
    name: "WordPress",
  },

  {
    _id: "5f9663fae6cc697728228310",
    name: "Joomla",
  },

  {
    _id: "5f9663fae6cc697728228315",
    name: "Drupal",
  },

  {
    _id: "5f9663fae6cc69772822831f",
    name: "Convolutional Neural Networks (CNN)",
  },

  {
    _id: "5f9663fae6cc697728228328",
    name: "CNN Newsource",
  },

  {
    _id: "5f9663fae6cc697728228346",
    name: "SAP Products",
  },

  {
    _id: "5f9663fae6cc697728228353",
    name: "Agile Project Management",
  },
  {
    _id: "5f9663fae6cc697728228354",
    name: "Agriculture",
  },

  {
    _id: "5f9663fae6cc697728228357",
    name: "Agile & Waterfall Methodologies",
  },

  {
    _id: "5f9663fae6cc69772822835a",
    name: "Agile Testing",
  },

  {
    _id: "5f9663fae6cc69772822835e",
    name: "Scrum",
  },
  {
    _id: "5f9663fae6cc69772822835f",
    name: "Agile Application Development",
  },

  {
    _id: "5f9663fae6cc69772822836d",
    name: "Artificial Intelligence (AI)",
  },

  {
    _id: "5f9663fae6cc697728228379",
    name: "Computer-Aided Engineering (CAE)",
  },

  {
    _id: "5f9663fae6cc69772822837d",
    name: "ASP.NET AJAX",
  },

  {
    _id: "5f9663fae6cc69772822837f",
    name: "AJAX Frameworks",
  },

  {
    _id: "5f9663fae6cc69772822838f",
    name: "Amazon Web Services (AWS)",
  },

  {
    _id: "5f9663fae6cc697728228392",
    name: "Amazon EC2",
  },

  {
    _id: "5f9663fae6cc697728228399",
    name: "Amazon RDS",
  },
  {
    _id: "5f9663fae6cc69772822839a",
    name: "Amadeus GDS",
  },
  {
    _id: "5f9663fae6cc69772822839b",
    name: "American Welding Society (AWS)",
  },
  {
    _id: "5f9663fae6cc69772822839c",
    name: "Amazon Dynamodb",
  },
  {
    _id: "5f9663fae6cc69772822839d",
    name: "Amazon Redshift",
  },

  {
    _id: "5f9663fae6cc6977282283a3",
    name: "Analytics",
  },

  {
    _id: "5f9663fae6cc6977282283a6",
    name: "Google Analytics",
  },

  {
    _id: "5f9663fae6cc6977282283a8",
    name: "AngularJS",
  },

  {
    _id: "5f9663fae6cc6977282283b1",
    name: "Web Applications",
  },

  {
    _id: "5f9663fae6cc6977282283b3",
    name: "Mobile Applications",
  },

  {
    _id: "5f9663fae6cc6977282283b5",
    name: "Applicant Tracking Systems",
  },

  {
    _id: "5f9663fae6cc6977282283b7",
    name: "Apache",
  },

  {
    _id: "5f9663fae6cc6977282283bc",
    name: "Java",
  },
  {
    _id: "5f9663fae6cc6977282283bd",
    name: "Oracle Applications",
  },
  {
    _id: "5f9663fae6cc6977282283be",
    name: "Windows",
  },

  {
    _id: "5f9663fae6cc6977282283c7",
    name: "Data Acquisition",
  },

  {
    _id: "5f9663fae6cc6977282283d4",
    name: "Solution Architecture",
  },

  {
    _id: "5f9663fae6cc6977282283e1",
    name: "System Architecture",
  },

  {
    _id: "5f9663fae6cc6977282283e6",
    name: "ASP.NET",
  },

  {
    _id: "5f9663fae6cc6977282283ea",
    name: "ASP.NET MVC",
  },

  {
    _id: "5f9663fae6cc6977282283f7",
    name: "JIRA",
  },

  {
    _id: "5f9663fae6cc69772822842b",
    name: "SQL Azure",
  },
  {
    _id: "5f9663fae6cc69772822842c",
    name: "Azure DevOps",
  },

  {
    _id: "5f9663fae6cc69772822843b",
    name: "Microsoft SQL Server",
  },
  {
    _id: "5f9663fae6cc69772822843c",
    name: "MySQL",
  },

  {
    _id: "5f9663fae6cc697728228441",
    name: "MongoDB",
  },
  {
    _id: "5f9663fae6cc697728228442",
    name: "NoSQL",
  },

  {
    _id: "5f9663fae6cc697728228445",
    name: "Oracle Database Administration",
  },

  {
    _id: "5f9663fae6cc697728228484",
    name: "DHTML",
  },

  {
    _id: "5f9663fae6cc697728228495",
    name: "Python (Programming Language)",
  },

  {
    _id: "5f9663fae6cc6977282284c4",
    name: "Deep Neural Networks (DNN)",
  },

  {
    _id: "5f9663fae6cc697728228689",
    name: ".NET Framework",
  },

  {
    _id: "5f9663fae6cc6977282286de",
    name: "JavaScript",
  },

  {
    _id: "5f9663fae6cc6977282287d5",
    name: "Gulp.js",
  },

  {
    _id: "5f9663fae6cc697728228841",
    name: "Red Hat Linux",
  },
  {
    _id: "5f9663fae6cc697728228842",
    name: "Hadoop",
  },

  {
    _id: "5f9663fae6cc697728228876",
    name: "HTML",
  },

  {
    _id: "5f9663fae6cc697728228a00",
    name: "jQuery",
  },
  {
    _id: "5f9663fae6cc697728228a01",
    name: "J#",
  },

  {
    _id: "5f9663fae6cc697728228a03",
    name: "Node.js",
  },
  {
    _id: "5f9663fae6cc697728228a04",
    name: "Core Java",
  },
  {
    _id: "5f9663fae6cc697728228a05",
    name: "JSON",
  },
  {
    _id: "5f9663fae6cc697728228a06",
    name: "React.js",
  },
  {
    _id: "5f9663fae6cc697728228a07",
    name: "JavaServer Pages (JSP)",
  },

  {
    _id: "5f9663fae6cc697728228a0a",
    name: "Jenkins",
  },

  {
    _id: "5f9663fae6cc697728228a0e",
    name: "Java Database Connectivity (JDBC)",
  },
  {
    _id: "5f9663fae6cc697728228a0f",
    name: "C++",
  },

  {
    _id: "5f9663fae6cc697728228a11",
    name: "Enterprise JavaBeans (EJB)",
  },

  {
    _id: "5f9663fae6cc697728228a13",
    name: "JavaSE",
  },

  {
    _id: "5f9663fae6cc697728228a15",
    name: "Java Swing",
  },

  {
    _id: "5f9663fae6cc697728228a17",
    name: "AJAX",
  },

  {
    _id: "5f9663fae6cc697728228a32",
    name: "JDK",
  },

  {
    _id: "5f9663fae6cc697728228a71",
    name: "Spring Data",
  },

  {
    _id: "5f9663fae6cc697728228a75",
    name: "HQL",
  },
  {
    _id: "5f9663fae6cc697728228a76",
    name: "jQuery UI",
  },

  {
    _id: "5f9663fae6cc697728228a7e",
    name: "Vue.js",
  },
  {
    _id: "5f9663fae6cc697728228a7f",
    name: "Express.js",
  },
  {
    _id: "5f9663fae6cc697728228a80",
    name: "Redux.js",
  },
  {
    _id: "5f9663fae6cc697728228a81",
    name: "Backbone.js",
  },
  {
    _id: "5f9663fae6cc697728228a82",
    name: "Ext JS",
  },
  {
    _id: "5f9663fae6cc697728228a83",
    name: "D3.js",
  },
  {
    _id: "5f9663fae6cc697728228a84",
    name: "Ember.js",
  },
  {
    _id: "5f9663fae6cc697728228a85",
    name: "Meteor.js",
  },
  {
    _id: "5f9663fae6cc697728228a86",
    name: "Handlebars.js",
  },
  {
    _id: "5f9663fae6cc697728228a87",
    name: "Bootstrap",
  },
  {
    _id: "5f9663fae6cc697728228a88",
    name: "Require.js",
  },
  {
    _id: "5f9663fae6cc697728228a89",
    name: "JTA",
  },
  {
    _id: "5f9663fae6cc697728228a8a",
    name: "JTest",
  },
  {
    _id: "5f9663fae6cc697728228a8b",
    name: "JTAPI",
  },

  {
    _id: "5f9663fae6cc697728228a91",
    name: "Jupyter",
  },

  {
    _id: "5f9663fae6cc697728228aaf",
    name: "Kubernetes",
  },

  {
    _id: "5f9663fae6cc697728228ab4",
    name: "Kali Linux",
  },
  {
    _id: "5f9663fae6cc697728228ab5",
    name: "Karate",
  },

  {
    _id: "5f9663fae6cc697728228b5c",
    name: "Machine Learning",
  },
  {
    _id: "5f9663fae6cc697728228cb1",
    name: "PHP",
  },

  {
    _id: "5f9663fae6cc697728228cc9",
    name: "Natural Language Processing (NLP)",
  },

  {
    _id: "5f9663fae6cc697728228cea",
    name: "Android NDK",
  },

  {
    _id: "5f9663fae6cc697728228d77",
    name: "NumPy",
  },

  {
    _id: "5f9663fae6cc697728228d80",
    name: "CUDA",
  },

  {
    _id: "5f9663fae6cc697728228d9c",
    name: "Object Oriented Design",
  },
  {
    _id: "5f9663fae6cc697728228d9d",
    name: "Objective-C",
  },

  {
    _id: "5f9663fae6cc697728228f64",
    name: "PyTorch",
  },
  {
    _id: "5f9663fae6cc697728228f65",
    name: "Pycharm",
  },
  {
    _id: "5f9663fae6cc697728228f66",
    name: "PySpark",
  },
  {
    _id: "5f9663fae6cc697728228f67",
    name: "Pyxis",
  },
  {
    _id: "5f9663fae6cc697728228f68",
    name: "Pymol",
  },
  {
    _id: "5f9663fae6cc697728228f69",
    name: "PyQt",
  },

  {
    _id: "5f9663fae6cc697728228fc9",
    name: "Selenium",
  },

  {
    _id: "5f9663fae6cc6977282290b5",
    name: "Android SDK",
  },

  {
    _id: "5f9663fae6cc69772822912e",
    name: "SQLite",
  },
  {
    _id: "5f9663fae6cc69772822912f",
    name: "SQL Server Analysis Services (SSAS)",
  },

  {
    _id: "5f9663fae6cc697728229313",
    name: "Visual C++",
  },

  {
    _id: "5f9663fae6cc69772822935c",
    name: "Emc Vplex",
  },
  {
    _id: "5f9663fae6cc69772822935d",
    name: "VPython",
  },
  {
    _id: "5f9663fae6cc69772822935e",
    name: "Private Clouds",
  },
  {
    _id: "5f9663fae6cc69772822935f",
    name: "VPP",
  },

  {
    _id: "5f9663fae6cc6977282293ba",
    name: "Web Analytics",
  },

  {
    _id: "5f9663fae6cc69772822941e",
    name: "Citrix XenApp",
  },
  {
    _id: "5f9663fae6cc69772822941f",
    name: "XAML",
  },
  {
    _id: "5f9663fae6cc697728229420",
    name: "XAMPP",
  },
  {
    _id: "5f9663fae6cc697728229421",
    name: "Xactimate",
  },
  {
    _id: "5f9663fae6cc697728229422",
    name: "Infor XA",
  },
  {
    _id: "5f9663fae6cc697728229423",
    name: "Xactly Incent",
  },
  {
    _id: "5f9663fae6cc697728229424",
    name: "XaaS",
  },
  {
    _id: "5f9663fae6cc697728229425",
    name: "Xara",
  },
  {
    _id: "5f9663fae6cc697728229426",
    name: "Xactly",
  },
  {
    _id: "5f9663fae6cc697728229427",
    name: "XACML",
  },
  {
    _id: "5f9663fae6cc697728229428",
    name: "XACT",
  },
  {
    _id: "5f9663fae6cc697728229429",
    name: "Experience API (xAPI)",
  },
  {
    _id: "5f9663fae6cc69772822942a",
    name: "Xajax",
  },
  {
    _id: "5f9663fae6cc69772822942b",
    name: "XATA",
  },
  {
    _id: "5f9663fae6cc69772822942c",
    name: "XAUI",
  },
  {
    _id: "5f9663fae6cc69772822942d",
    name: "Xalan",
  },
  {
    _id: "5f9663fae6cc69772822942e",
    name: "Xbox",
  },
  {
    _id: "5f9663fae6cc69772822942f",
    name: "Xbox One",
  },
  {
    _id: "5f9663fae6cc697728229430",
    name: "xBase",
  },
  {
    _id: "5f9663fae6cc697728229431",
    name: "XBR",
  },
  {
    _id: "5f9663fae6cc697728229432",
    name: "XBAP",
  },
  {
    _id: "5f9663fae6cc697728229433",
    name: "ApSIC Xbench",
  },
  {
    _id: "5f9663fae6cc697728229434",
    name: "Crystal Xcelsius",
  },
  {
    _id: "5f9663fae6cc697728229435",
    name: "Crystal Xcelsius 2008",
  },
  {
    _id: "5f9663fae6cc697728229436",
    name: "xCP",
  },
  {
    _id: "5f9663fae6cc697728229437",
    name: "XCAL",
  },
  {
    _id: "5f9663fae6cc697728229438",
    name: "Xcalibur",
  },
  {
    _id: "5f9663fae6cc697728229439",
    name: "XCAP",
  },
  {
    _id: "5f9663fae6cc69772822943a",
    name: "xCAT",
  },
  {
    _id: "5f9663fae6cc69772822943b",
    name: "XCOM",
  },
  {
    _id: "5f9663fae6cc69772822943c",
    name: "X-Cart",
  },
  {
    _id: "5f9663fae6cc69772822943d",
    name: "Xceleration",
  },
  {
    _id: "5f9663fae6cc69772822943e",
    name: "Rational XDE",
  },
  {
    _id: "5f9663fae6cc69772822943f",
    name: "XDCAM",
  },
  {
    _id: "5f9663fae6cc697728229440",
    name: "Xdebug",
  },
  {
    _id: "5f9663fae6cc697728229441",
    name: "XDoclet",
  },
  {
    _id: "5f9663fae6cc697728229442",
    name: "Xen",
  },
  {
    _id: "5f9663fae6cc697728229443",
    name: "Citrix XenDesktop",
  },
  {
    _id: "5f9663fae6cc697728229444",
    name: "Xerox Printers",
  },
  {
    _id: "5f9663fae6cc697728229445",
    name: "XenClient",
  },
  {
    _id: "5f9663fae6cc697728229446",
    name: "Oracle XE",
  },
  {
    _id: "5f9663fae6cc697728229447",
    name: "Xenu",
  },
  {
    _id: "5f9663fae6cc697728229448",
    name: "Xerces",
  },
  {
    _id: "5f9663fae6cc697728229449",
    name: "Xeriscaping",
  },
  {
    _id: "5f9663fae6cc69772822944a",
    name: "Xetra",
  },
  {
    _id: "5f9663fae6cc69772822944b",
    name: "Xenix",
  },
  {
    _id: "5f9663fae6cc69772822944c",
    name: "Xeriscape",
  },
  {
    _id: "5f9663fae6cc69772822944d",
    name: "Xemacs",
  },
  {
    _id: "5f9663fae6cc69772822944e",
    name: "XFLR5",
  },
  {
    _id: "5f9663fae6cc69772822944f",
    name: "XForms",
  },
  {
    _id: "5f9663fae6cc697728229450",
    name: "XFS",
  },
  {
    _id: "5f9663fae6cc697728229451",
    name: "XFP",
  },
  {
    _id: "5f9663fae6cc697728229452",
    name: "XFDTD",
  },
  {
    _id: "5f9663fae6cc697728229453",
    name: "XFire",
  },
  {
    _id: "5f9663fae6cc697728229454",
    name: "Xfer Serum",
  },
  {
    _id: "5f9663fae6cc697728229455",
    name: "Sophos",
  },
  {
    _id: "5f9663fae6cc697728229456",
    name: "Canon XH-A1",
  },
  {
    _id: "5f9663fae6cc697728229457",
    name: "Xilinx",
  },
  {
    _id: "5f9663fae6cc697728229458",
    name: "Xilinx ISE",
  },
  {
    _id: "5f9663fae6cc697728229459",
    name: "Crystal Reports",
  },
  {
    _id: "5f9663fae6cc69772822945a",
    name: "IBM XIV",
  },
  {
    _id: "5f9663fae6cc69772822945b",
    name: "Xing",
  },
  {
    _id: "5f9663fae6cc69772822945c",
    name: "Xinet",
  },
  {
    _id: "5f9663fae6cc69772822945d",
    name: "Xign",
  },
  {
    _id: "5f9663fae6cc69772822945e",
    name: "XLSTAT",
  },
  {
    _id: "5f9663fae6cc69772822945f",
    name: "XLMiner",
  },
  {
    _id: "5f9663fae6cc697728229460",
    name: "Cadence Virtuoso",
  },
  {
    _id: "5f9663fae6cc697728229461",
    name: "IRI Xlerate",
  },
  {
    _id: "5f9663fae6cc697728229462",
    name: "Canon XL-1",
  },
  {
    _id: "5f9663fae6cc697728229463",
    name: "Canon XL2",
  },
  {
    _id: "5f9663fae6cc697728229464",
    name: "XML Schema",
  },
  {
    _id: "5f9663fae6cc697728229465",
    name: "XML Schema Definition (XSD)",
  },
  {
    _id: "5f9663fae6cc697728229466",
    name: "Java Architecture for XML Binding (JAXB)",
  },
  {
    _id: "5f9663fae6cc697728229467",
    name: "XML Publisher",
  },
  {
    _id: "5f9663fae6cc697728229468",
    name: "XMind",
  },
  {
    _id: "5f9663fae6cc697728229469",
    name: "XMPP",
  },
  {
    _id: "5f9663fae6cc69772822946a",
    name: "XML Spy",
  },
  {
    _id: "5f9663fae6cc69772822946b",
    name: "DITA XML",
  },
  {
    _id: "5f9663fae6cc69772822946c",
    name: "XMetal",
  },
  {
    _id: "5f9663fae6cc69772822946d",
    name: "XML Databases",
  },
  {
    _id: "5f9663fae6cc69772822946e",
    name: "XMPie",
  },
  {
    _id: "5f9663fae6cc69772822946f",
    name: "Open XML",
  },
  {
    _id: "5f9663fae6cc697728229470",
    name: "XML Programming",
  },
  {
    _id: "5f9663fae6cc697728229471",
    name: "XML Sitemaps",
  },
  {
    _id: "5f9663fae6cc697728229472",
    name: "XMLBeans",
  },
  {
    _id: "5f9663fae6cc697728229473",
    name: "XML Schema Design",
  },
  {
    _id: "5f9663fae6cc697728229474",
    name: "Occlusion",
  },
  {
    _id: "5f9663fae6cc697728229475",
    name: "Xoops",
  },
  {
    _id: "5f9663fae6cc697728229476",
    name: "XOG",
  },
  {
    _id: "5f9663fae6cc697728229477",
    name: "Crossbeam XOS",
  },
  {
    _id: "5f9663fae6cc697728229478",
    name: "XPath",
  },
  {
    _id: "5f9663fae6cc697728229479",
    name: "XPS",
  },
  {
    _id: "5f9663fae6cc69772822947a",
    name: "QuarkXPress",
  },
  {
    _id: "5f9663fae6cc69772822947b",
    name: "Xpediter",
  },
  {
    _id: "5f9663fae6cc69772822947c",
    name: "XP/Vista/7",
  },
  {
    _id: "5f9663fae6cc69772822947d",
    name: "Xpress",
  },
  {
    _id: "5f9663fae6cc69772822947e",
    name: "Xplan",
  },
  {
    _id: "5f9663fae6cc69772822947f",
    name: "XPages",
  },
  {
    _id: "5f9663fae6cc697728229480",
    name: "Avid Xpress",
  },
  {
    _id: "5f9663fae6cc697728229481",
    name: "HP XP",
  },
  {
    _id: "5f9663fae6cc697728229482",
    name: "xPression",
  },
  {
    _id: "5f9663fae6cc697728229483",
    name: "XPAC",
  },
  {
    _id: "5f9663fae6cc697728229484",
    name: "XP Professional",
  },
  {
    _id: "5f9663fae6cc697728229485",
    name: "xPC Target",
  },
  {
    _id: "5f9663fae6cc697728229486",
    name: "xPON",
  },
  {
    _id: "5f9663fae6cc697728229487",
    name: "XPlanner",
  },
  {
    _id: "5f9663fae6cc697728229488",
    name: "XPDL",
  },
  {
    _id: "5f9663fae6cc697728229489",
    name: "IOS-XR",
  },
  {
    _id: "5f9663fae6cc69772822948a",
    name: "XRY",
  },
  {
    _id: "5f9663fae6cc69772822948b",
    name: "X-ray Diffraction Analysis",
  },
  {
    _id: "5f9663fae6cc69772822948c",
    name: "XRR",
  },
  {
    _id: "5f9663fae6cc69772822948d",
    name: "XSL",
  },
  {
    _id: "5f9663fae6cc69772822948e",
    name: "XSI",
  },
  {
    _id: "5f9663fae6cc69772822948f",
    name: "XSL-FO",
  },
  {
    _id: "5f9663fae6cc697728229490",
    name: "XSS",
  },
  {
    _id: "5f9663fae6cc697728229491",
    name: "Xsan",
  },
  {
    _id: "5f9663fae6cc697728229492",
    name: "XStream",
  },
  {
    _id: "5f9663fae6cc697728229493",
    name: "xSeries",
  },
  {
    _id: "5f9663fae6cc697728229494",
    name: "Xserve",
  },
  {
    _id: "5f9663fae6cc697728229495",
    name: "IBM Certified",
  },
  {
    _id: "5f9663fae6cc697728229496",
    name: "XSP",
  },
  {
    _id: "5f9663fae6cc697728229497",
    name: "Xsigo",
  },
  {
    _id: "5f9663fae6cc697728229498",
    name: "Xtext",
  },
  {
    _id: "5f9663fae6cc697728229499",
    name: "XTRACT",
  },
  {
    _id: "5f9663fae6cc69772822949a",
    name: "XTRAC",
  },
  {
    _id: "5f9663fae6cc69772822949b",
    name: "Reuters 3000",
  },
  {
    _id: "5f9663fae6cc69772822949c",
    name: "XUL",
  },
  {
    _id: "5f9663fae6cc69772822949d",
    name: "Yield Management",
  },
  {
    _id: "5f9663fae6cc69772822949e",
    name: "YouTube",
  },
  {
    _id: "5f9663fae6cc69772822949f",
    name: "Youth Development",
  },
  {
    _id: "5f9663fae6cc6977282294a0",
    name: "Youth Mentoring",
  },
  {
    _id: "5f9663fae6cc6977282294a1",
    name: "Youth Ministry",
  },
  {
    _id: "5f9663fae6cc6977282294a2",
    name: "Poka Yoke",
  },
  {
    _id: "5f9663fae6cc6977282294a3",
    name: "Yachting",
  },
  {
    _id: "5f9663fae6cc6977282294a4",
    name: "Youth Work",
  },
  {
    _id: "5f9663fae6cc6977282294a5",
    name: "Youth Leadership",
  },
  {
    _id: "5f9663fae6cc6977282294a6",
    name: "Yardi",
  },
  {
    _id: "5f9663fae6cc6977282294a7",
    name: "Young Adults",
  },
  {
    _id: "5f9663fae6cc6977282294a8",
    name: "Year-end Close",
  },
  {
    _id: "5f9663fae6cc6977282294a9",
    name: "Yii",
  },
  {
    _id: "5f9663fae6cc6977282294aa",
    name: "Yoga Instruction",
  },
  {
    _id: "5f9663fae6cc6977282294ab",
    name: "Hatha Yoga",
  },
  {
    _id: "5f9663fae6cc6977282294ac",
    name: "Youth Empowerment",
  },
  {
    _id: "5f9663fae6cc6977282294ad",
    name: "Year End Accounts",
  },
  {
    _id: "5f9663fae6cc6977282294ae",
    name: "Yardi Voyager",
  },
  {
    _id: "5f9663fae6cc6977282294af",
    name: "Yarn",
  },
  {
    _id: "5f9663fae6cc6977282294b0",
    name: "Yardi Property Management",
  },
  {
    _id: "5f9663fae6cc6977282294b1",
    name: "Yahoo Search Marketing",
  },
  {
    _id: "5f9663fae6cc6977282294b2",
    name: "YAML",
  },
  {
    _id: "5f9663fae6cc6977282294b3",
    name: "Yammer",
  },
  {
    _id: "5f9663fae6cc6977282294b4",
    name: "Yacht Charters",
  },
  {
    _id: "5f9663fae6cc6977282294b5",
    name: "Yacht Deliveries",
  },
  {
    _id: "5f9663fae6cc6977282294b6",
    name: "Yard Work",
  },
  {
    _id: "5f9663fae6cc6977282294b7",
    name: "Yacht Racing",
  },
  {
    _id: "5f9663fae6cc6977282294b8",
    name: "Yard Management",
  },
  {
    _id: "5f9663fae6cc6977282294b9",
    name: "Yacc",
  },
  {
    _id: "5f9663fae6cc6977282294ba",
    name: "Yamaha Digital Consoles",
  },
  {
    _id: "5f9663fae6cc6977282294bb",
    name: "Yacht Clubs",
  },
  {
    _id: "5f9663fae6cc6977282294bc",
    name: "Yahoo Search",
  },
  {
    _id: "5f9663fae6cc6977282294bd",
    name: "Yaskawa",
  },
  {
    _id: "5f9663fae6cc6977282294be",
    name: "Yardi Enterprise",
  },
  {
    _id: "5f9663fae6cc6977282294bf",
    name: "Yamaha M7CL",
  },
  {
    _id: "5f9663fae6cc6977282294c0",
    name: "Yellow Belt",
  },
  {
    _id: "5f9663fae6cc6977282294c1",
    name: "Yeast",
  },
  {
    _id: "5f9663fae6cc6977282294c2",
    name: "Yearbook",
  },
  {
    _id: "5f9663fae6cc6977282294c3",
    name: "Yeoman",
  },
  {
    _id: "5f9663fae6cc6977282294c4",
    name: "First Year Experience",
  },
  {
    _id: "5f9663fae6cc6977282294c5",
    name: "Yelp",
  },
  {
    _id: "5f9663fae6cc6977282294c6",
    name: "Getting to Yes",
  },
  {
    _id: "5f9663fae6cc6977282294c7",
    name: "Yellow Pages",
  },
  {
    _id: "5f9663fae6cc6977282294c8",
    name: "Yellow Book",
  },
  {
    _id: "5f9663fae6cc6977282294c9",
    name: "Internet Yellow Pages",
  },
  {
    _id: "5f9663fae6cc6977282294ca",
    name: "Yesware",
  },
  {
    _id: "5f9663fae6cc6977282294cb",
    name: "Yext",
  },
  {
    _id: "5f9663fae6cc6977282294cc",
    name: "Yield",
  },
  {
    _id: "5f9663fae6cc6977282294cd",
    name: "High Yield",
  },
  {
    _id: "5f9663fae6cc6977282294ce",
    name: "High Yield Bonds",
  },
  {
    _id: "5f9663fae6cc6977282294cf",
    name: "Yin Yoga",
  },
  {
    _id: "5f9663fae6cc6977282294d0",
    name: "Yieldstar",
  },
  {
    _id: "5f9663fae6cc6977282294d1",
    name: "Yield Enhancement",
  },
  {
    _id: "5f9663fae6cc6977282294d2",
    name: "Yiddish",
  },
  {
    _id: "5f9663fae6cc6977282294d3",
    name: "Yields",
  },
  {
    _id: "5f9663fae6cc6977282294d4",
    name: "Youth Programs",
  },
  {
    _id: "5f9663fae6cc6977282294d5",
    name: "Youth Engagement",
  },
  {
    _id: "5f9663fae6cc6977282294d6",
    name: "Fitness",
  },
  {
    _id: "5f9663fae6cc6977282294d7",
    name: "Young People",
  },
  {
    _id: "5f9663fae6cc6977282294d8",
    name: "Youth Entrepreneurship",
  },
  {
    _id: "5f9663fae6cc6977282294d9",
    name: "Young Adult Literature",
  },
  {
    _id: "5f9663fae6cc6977282294da",
    name: "YUM",
  },
  {
    _id: "5f9663fae6cc6977282294db",
    name: "Zoning",
  },
  {
    _id: "5f9663fae6cc6977282294dc",
    name: "Zend Framework",
  },
  {
    _id: "5f9663fae6cc6977282294dd",
    name: "Zendesk",
  },
  {
    _id: "5f9663fae6cc6977282294de",
    name: "Zabbix",
  },
  {
    _id: "5f9663fae6cc6977282294df",
    name: "Zoology",
  },
  {
    _id: "5f9663fae6cc6977282294e0",
    name: "Zoho",
  },
  {
    _id: "5f9663fae6cc6977282294e1",
    name: "Zumba",
  },
  {
    _id: "5f9663fae6cc6977282294e2",
    name: "Apache ZooKeeper",
  },
  {
    _id: "5f9663fae6cc6977282294e3",
    name: "Wicklander-Zulawski Interview & Interrogation",
  },
  {
    _id: "5f9663fae6cc6977282294e4",
    name: "Zimbra",
  },
  {
    _id: "5f9663fae6cc6977282294e5",
    name: "ZigBee",
  },
  {
    _id: "5f9663fae6cc6977282294e6",
    name: "ZFS",
  },
  {
    _id: "5f9663fae6cc6977282294e7",
    name: "Zemax",
  },
  {
    _id: "5f9663fae6cc6977282294e8",
    name: "Printer Support",
  },
  {
    _id: "5f9663fae6cc6977282294e9",
    name: "Solaris Zones",
  },
  {
    _id: "5f9663fae6cc6977282294ea",
    name: "Zoom",
  },
  {
    _id: "5f9663fae6cc6977282294eb",
    name: "ZURB Foundation Framework",
  },
  {
    _id: "5f9663fae6cc6977282294ec",
    name: "Zephyr",
  },
  {
    _id: "5f9663fae6cc6977282294ed",
    name: "Zapier",
  },
  {
    _id: "5f9663fae6cc6977282294ee",
    name: "Zachman",
  },
  {
    _id: "5f9663fae6cc6977282294ef",
    name: "OWASP ZAP",
  },
  {
    _id: "5f9663fae6cc6977282294f0",
    name: "Zainet",
  },
  {
    _id: "5f9663fae6cc6977282294f1",
    name: "ZenCart",
  },
  {
    _id: "5f9663fae6cc6977282294f2",
    name: "Zend",
  },
  {
    _id: "5f9663fae6cc6977282294f3",
    name: "Zenworks",
  },
  {
    _id: "5f9663fae6cc6977282294f4",
    name: "ZeroMQ",
  },
  {
    _id: "5f9663fae6cc6977282294f5",
    name: "Zebrafish",
  },
  {
    _id: "5f9663fae6cc6977282294f6",
    name: "Zenoss",
  },
  {
    _id: "5f9663fae6cc6977282294f7",
    name: "Zero Waste",
  },
  {
    _id: "5f9663fae6cc6977282294f8",
    name: "Zend Studio",
  },
  {
    _id: "5f9663fae6cc6977282294f9",
    name: "Apache Zeppelin",
  },
  {
    _id: "5f9663fae6cc6977282294fa",
    name: "Zero-based Budgeting",
  },
  {
    _id: "5f9663fae6cc6977282294fb",
    name: "Zebra",
  },
  {
    _id: "5f9663fae6cc6977282294fc",
    name: "Zeta Potential",
  },
  {
    _id: "5f9663fae6cc6977282294fd",
    name: "Zen",
  },
  {
    _id: "5f9663fae6cc6977282294fe",
    name: "Zerto",
  },
  {
    _id: "5f9663fae6cc6977282294ff",
    name: "Zen Shiatsu",
  },
  {
    _id: "5f9663fae6cc697728229500",
    name: "Zinc",
  },
  {
    _id: "5f9663fae6cc697728229501",
    name: "Zines",
  },
  {
    _id: "5f9663fae6cc697728229502",
    name: "Zillow",
  },
  {
    _id: "5f9663fae6cc697728229503",
    name: "E-zines",
  },
  {
    _id: "5f9663fae6cc697728229504",
    name: "Zotero",
  },
  {
    _id: "5f9663fae6cc697728229505",
    name: "Invertebrate Zoology",
  },
  {
    _id: "5f9663fae6cc697728229506",
    name: "Coastal Zone Management",
  },
  {
    _id: "5f9663fae6cc697728229507",
    name: "Foreign Trade Zone",
  },
  {
    _id: "5f9663fae6cc697728229508",
    name: "Zoo",
  },
  {
    _id: "5f9663fae6cc697728229509",
    name: "ZoomInfo",
  },
  {
    _id: "5f9663fae6cc69772822950a",
    name: "Zope",
  },
  {
    _id: "5f9663fae6cc69772822950b",
    name: "Zombies",
  },
  {
    _id: "5f9663fae6cc69772822950c",
    name: "Zooarchaeology",
  },
  {
    _id: "5f9663fae6cc69772822950d",
    name: "ZoomText",
  },
  {
    _id: "5f9663fae6cc69772822950e",
    name: "Zone Alarm",
  },
  {
    _id: "5f9663fae6cc69772822950f",
    name: "Zoomerang",
  },
  {
    _id: "5f9663fae6cc697728229510",
    name: "Zscaler Cloud Security",
  },
  {
    _id: "5f9663fae6cc697728229511",
    name: "zSeries",
  },
  {
    _id: "5f9663fae6cc697728229512",
    name: "Zumba Instruction",
  },
  {
    _id: "5f9663fae6cc697728229513",
    name: "Zuora",
  },
  {
    _id: "5f9663fae6cc697728229514",
    name: "Zuken",
  },
  {
    _id: "5f9663fae6cc697728229515",
    name: "Zuul API Gateway",
  },
  {
    _id: "5f9663fae6cc697728229516",
    name: "Zultys",
  },
  {
    _id: "5f9663fae6cc697728229517",
    name: "Zymography",
  },
  {
    _id: "5f9663fae6cc697728229518",
    name: "Zynx",
  },
];
export default skillSet;
