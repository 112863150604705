import React from "react";
import NavPills from "../../common/mataerialKit/components/NavPills/NavPills.jsx";
import PropTypes from "prop-types";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../../actions/index";
import JobOfTheDay from "./JobOfTheDay";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Communication = (props) => {
  // useEffect(() => {}, []);

  return (
    <Container fluid>
      <div className="tq-JobsPage">
        <Row>
          <Col md={2}>
            <SideNav {...props} />
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />
            <NavPills
              color="info"
              tabs={[
                {
                  tabButton: "Send job of day emails",
                  tabContent: <JobOfTheDay />,
                },
                {
                  tabButton: "Vendor communication",
                  tabContent: <div>Vendor communication</div>,
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};
Communication.propTypes = {
  classes: PropTypes.object,
};
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Communication);
