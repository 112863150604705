import React from "react";
import AnalyticsChartMeter from "./AnalyticsChartMeter";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Analytics = (props) => {
  return (
    <div className="Analytics-Page mt-4">
      <Container>
        <Row className="mb-5">
          <Col xs={6} className="text-center">
            {props.activeCandidateAnalytics.total || 50 ? (
              <>
                <AnalyticsChartMeter
                  analyticsPropValue={
                    props.activeCandidateAnalytics.total || 50
                  }
                  maxScore={100}
                  id={`${props.activeCandidateId}total`}
                  width="100%"
                  height="165px"
                />
                <div className="analytics__label">Total Score</div>
              </>
            ) : (
              <Skeleton height={30} />
            )}
          </Col>
          <Col xs={6} className="text-center">
            {props.activeCandidateAnalytics.norm_sddc || 63 ? (
              <>
                <AnalyticsChartMeter
                  analyticsPropValue={
                    props.activeCandidateAnalytics.norm_sddc || 63
                  }
                  maxScore={props.job._sddcWt}
                  id={`${props.activeCandidateId}resumeScore`}
                  fillColor="#ef5350"
                  stroke="#ffffff"
                  width="100%"
                  height="165px"
                />
                <div className="analytics__label">Resume Score</div>
              </>
            ) : (
              <Skeleton height={30} />
            )}
          </Col>
        </Row>
        <Row className="border-top pt-5">
          <Col xs={12} sm={4} className="text-center">
            {props.activeCandidateAnalytics.exp_val || 16 ? (
              <>
                <AnalyticsChartMeter
                  analyticsPropValue={
                    props.activeCandidateAnalytics.exp_val || 16
                  }
                  maxScore={props.job._expWt}
                  id={`${props.activeCandidateId}exp`}
                  fillColor="#9c27b0"
                  stroke="#ffffff"
                  width="100%"
                  height="160px"
                />
                <div className="analytics__label">Experience</div>
              </>
            ) : (
              <Skeleton height={30} />
            )}
          </Col>
          <Col xs={12} sm={4} className="text-center">
            {props.activeCandidateAnalytics.sal_val || 12 ? (
              <>
                <AnalyticsChartMeter
                  analyticsPropValue={
                    props.activeCandidateAnalytics.sal_val || 12
                  }
                  maxScore={props.job._salWt}
                  id={`${props.activeCandidateId}sal`}
                  fillColor="#fbc02d"
                  stroke="#ffffff"
                  width="100%"
                  height="160px"
                />
                <div className="analytics__label">Salary</div>
              </>
            ) : (
              <Skeleton height={30} />
            )}
          </Col>
          <Col xs={12} sm={4} className="text-center">
            {props.activeCandidateAnalytics.dist_val || 9 ? (
              <>
                <AnalyticsChartMeter
                  analyticsPropValue={
                    props.activeCandidateAnalytics.dist_val || 9
                  }
                  maxScore={props.job._locWt}
                  id={`${props.activeCandidateId}distance`}
                  fillColor="#ff9800"
                  stroke="#ffffff"
                  width="100%"
                  height="160px"
                />
                <div className="analytics__label">Distance</div>
              </>
            ) : (
              <Skeleton height={30} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Analytics;
