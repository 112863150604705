import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import CreatableSelect from "react-select/creatable";

import endPoints, { dEndPoints } from "../../../config/end-point";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import Container from "react-bootstrap/Container";
import { showSnackBar, showLoader } from "../../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import skillSet from "../../../utils/skills";
import collegeSet from "../../../utils/college";
import { Modal } from "react-bootstrap";
//import AsyncSelect from "react-select/async";

const UpdateCandidate = (props) => {
  const [user, setUser] = useState(props.currentUser);
  const [skills, setSkills] = useState([]);
  const [extra, setExtra] = useState(props.currentUser.extraFields);

  const [locations, setLocation] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [collegeLookup, SetcollegeLookup] = useState([]);
  const [companyLookup, SetcompanyLookup] = useState([]);

  // let cacheData = {};
  // const getAllCacheData = async () => {
  //   var url = window.location.hostname;

  //   // List of all caches present in browser
  //   var names = await caches.keys();
  //   // Iterating over the list of caches
  //   names.forEach(async (name) => {
  //     // Opening that particular cache
  //     const cacheStorage = await caches.open(name);

  //     // Fetching that particular cache data
  //     const cachedResponse = await cacheStorage.match(url);
  //     var data = await cachedResponse.json();
  //     //setCacheData(data);
  //     cacheData = data;
  //   });
  // };
  //getAllCacheData();
  useEffect(() => {
    loadDatabaseDetails();
    // loadDetails();
    setUser(props.currentUser);
    loadOptions();
    loadDesignationOptions();
    //  loadCollegeOptions();
    loadCompanyOptions();
    loadQualOptions();
  }, [props]);
  const handleChangeNoticeP = (e) => {
    const info = { ...user };
    info.nPeriod = e.target.value;
    setUser(info);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleChangeSkills = (value) => {
    var info = { ...user };
    info.skills = value ? value.map((el) => el.value) : [];
    setUser(info);
  };

  const loadOptions = () => {
    axios.get(endPoints.getLocationLookup + "?location=").then((res) => {
      const data = res.data;
      setLocation(data);
    });
  };

  const loadCompanyOptions = () => {
    axios.get(endPoints.getCompanyLookup + "?company=ACC").then((res) => {
      const data = res.data;
      SetcompanyLookup(data);
    });
  };
  const loadDesignationOptions = () => {
    axios
      .get(endPoints.getDesignationLookup + "?desig=software")
      .then((res) => {
        const data = res.data;

        setDesignation(data);
      });
  };
  // const loadCollegeOptions = () => {
  //   axios.get(endPoints.getCollegeLookup + "?college=").then((res) => {
  //     const data = res.data;
  //     console.log("loadCollegeOptions", data);
  //     SetQualLookup(data);
  //   });
  // };

  const loadQualOptions = () => {
    axios.get(endPoints.getQualificationLookup + "?qual=").then((res) => {
      const data = res.data;
      SetcollegeLookup(data);
    });
  };

  // const loadDesigOptions = () => {
  //   axios.get(endPoints.getDesignationLookup + "?desig=").then((res) => {
  //     const data = res.data;
  //     SetcollegeLookup(data);
  //   });
  // };
  const QualificationLookup = collegeLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CompanyLookup = companyLookup.map((item) => {
    return { label: item.name, value: item.name };
  });
  const CollegeLookup = collegeSet.map((item) => {
    return { label: item.name, value: item.name };
  });
  const locationLookup = locations.map((item) => {
    return { label: item.name, value: item.name };
  });

  const designationLookup = designation.map((item) => {
    return { label: item.name, value: item.name };
  });

  const loadDatabaseDetails = () => {
    const selectInput = {
      metaData: [
        // {
        //   searchType: "ALL",
        //   searchValue: "Na",
        //   entity: "RATE",
        // },
        // {
        //   searchType: "ALL",
        //   searchValue: "Na",
        //   entity: "REPLACEMENT",
        // },
        // {
        //   searchType: "ALL",
        //   searchValue: "Na",
        //   entity: "LOCATION",
        // },

        // {
        //   searchType: "ALL",
        //   searchValue: "Na",
        //   entity: "STATE",
        // },
        // {
        //   searchType: "ALL",
        //   searchValue: "Na",
        //   entity: "DESIGNATION",
        // },
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "SKILL",
        },
      ],
    };
    axios({
      url: endPoints.lookup,
      data: selectInput,
      method: "POST",
    }).then((res) => {
      const data = res.data;

      if (data && data.code === 100000) {
        const skill = data && data.data && data.data.SKILL;
        setSkills(skill);
        // const design = data && data.data && data.data.DESIGNATION;
        // setDesignation(design);
        /*const location = data && data.data && data.data.LOCATION;
        setcityList(location); */
      }
    });
  };
  // const loadDetails = () => {
  //   axios
  //     .get(endPoints.redisLookup + "?lookup=SKILL")
  //     .then((res) => {
  //       const data = res.data;

  //       if (data && data.code === 100000) {
  //         const skill = data && data.data && data.data.SKILL;
  //         setSkills(skill);
  //         /*const location = data && data.data && data.data.LOCATION;
  //         setcityList(location); */
  //       }
  //       if (data.code !== 100000) {
  //         loadDatabaseDetails();
  //       }
  //       if (data.code === 100001) {
  //         loadDatabaseDetails();
  //       }
  //     })
  //     .catch((error) => {
  //       loadDatabaseDetails();
  //     });
  // };
  const skill = skills.map((item) => {
    return { label: item.name, value: item.name };
  });
  const skillss = skillSet.map((item) => {
    return { label: item.name, value: item.name };
  });

  var pror = ["label", "value"];

  var skillNew = skill
    .filter(function (o1) {
      // filter out (!) items in result2
      return skillss.some(function (o2) {
        return o1.label === o2.label; // assumes unique id
      });
    })
    .map(function (o) {
      // use reduce to make objects with only the required properties
      // and map to apply this to the filtered array as a whole
      return pror.reduce(function (newo, value) {
        newo[value] = o[value];
        return newo;
      }, {});
    });

  const editCandidateAnalytics = () => {
    const jobID = props.job._id;
    const vendorId = localStorage.getItem("i");
    //const vendorId = cacheData ? cacheData.i : "";
    var data = { ...user };
    //data.jobId = jobID;
    var candId = props.editableUser._id;
    //data.candId = candId;
    //data.vId = vendorId;

    //data.cId = props.job.cId;
    //data.cmId = cmId;
    axios
      .put(dEndPoints.editCandidate(jobID, vendorId, candId), data)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };
  const handleChangeQuallookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...user };
    info.qual = newQual ? newQual.map((el) => el.value) : [];
    setUser(info);
  };

  const handleChangeLocationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);

    var info = { ...user };
    info.city = newQual ? newQual.map((el) => el.value) : [];
    setUser(info);
  };
  const handleChangeDesinationlookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...user };
    info.cDesig = newQual ? newQual.map((el) => el.value) : [];
    setUser(info);
  };
  const handleChangeCompanylookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...user };
    info.cName = newQual ? newQual.map((el) => el.value) : [];
    setUser(info);
  };
  const handleChangeCollegelookUp = (value) => {
    var newQual = [];
    newQual.push(value);
    var info = { ...user };
    info.college = newQual ? newQual.map((el) => el.value) : "";

    setUser(info);
  };

  const handleChangeNewField = (e, i) => {
    const { value } = e.target;

    //  var info = { ...user };

    const newState = [...extra];

    // info.cName = newState ? newState.map((el) => el.value) : [];
    newState[i] = {
      ...newState[i],
      value,
    };
    setExtra(newState);
    //info.extraFields = newState;
  };
  // var info = { ...user };
  // info.extraFields = extra;
  // console.log("info.extraFields", info.extraFields);
  // const [formerrors, setFormError] = useState({
  //   noticePeriodError: "",
  //   nameError: "",
  //   emailError: "",
  //   contactError: "",
  //   collegeError: "",
  //   cityError: "",
  //   ctcError: "",
  //   ectcError: "",
  //   cDesigError: "",
  //   exprError: "",
  //   cNameError: "",
  //   skillsError: [],
  //   qualError: "",
  //   tenPercentError: "",
  //   twelvePercentError: "",
  //   pgPercentError: "",
  //   ugPercentError: "",
  //   panError: "",
  //   submitClikced: false,
  // });
  const updateProfile = (event) => {
    // const form = event.currentTarget;

    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // console.log("event", form.checkValidity());

    // setValidated(true);
    if (!user.name) {
      props.showSnackBar({
        message: "Name is empty",
        open: true,
        type: "error",
      });
      return;
      //formerrors.nameError = "Please Enter Name";
    }
    if (!user.email) {
      props.showSnackBar({
        message: "Email is empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (!user.contact) {
      props.showSnackBar({
        message: "Contact is empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (!user.city || user.city.length === 0) {
      props.showSnackBar({
        message: " City is empty.",
        open: true,
        type: "error",
      });
      return;
    }
    if (user.contact.length < 10) {
      props.showSnackBar({
        message: "Contact number at least 10 digits",
        open: true,
        type: "error",
      });
      return;
    }
    // if (!user.college) {
    //   props.showSnackBar({
    //     message: "College/University is empty",
    //     open: true,
    //     type: "error",
    //   });
    //   return;
    // }
    if (!user.cDesig || user.cDesig.length === 0) {
      props.showSnackBar({
        message: "Designation is empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (!user.expr) {
      props.showSnackBar({
        message: "Experience is empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (!user.ctc) {
      props.showSnackBar({
        message: "Current CTC is empty",
        open: true,
        type: "error",
      });
      return;
    }

    if (!user.ectc) {
      props.showSnackBar({
        message: "Expected CTC is empty",
        open: true,
        type: "error",
      });
      return;
    }

    if (!user.cName || user.cName.length === 0) {
      props.showSnackBar({
        message: "Company Name is empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (!user.qual || user.qual.length === 0) {
      props.showSnackBar({
        message: "Qualification is empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (user.skills.length === 0) {
      props.showSnackBar({
        message: "Skills are empty",
        open: true,
        type: "error",
      });
      return;
    }
    if (!user.nPeriod) {
      props.showSnackBar({
        message: "Notice Period is empty",
        open: true,
        type: "error",
      });
      return;
    }
    // if (!user.city || user.city.length === 0) {
    //   props.showSnackBar({
    //     message: "City is empty",
    //     open: true,
    //     type: "error",
    //   });
    //   return;
    // }

    if (props.job.cInfo && props.job.cInfo["tenPercent"]) {
      if (!user.tenPercent) {
        props.showSnackBar({
          message: "10Th Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["twelvePercent"]) {
      if (!user.twelvePercent) {
        props.showSnackBar({
          message: "12Th Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["ugPercent"]) {
      if (!user.ugPercent) {
        props.showSnackBar({
          message: "UG Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["pgPercent"]) {
      if (!user.pgPercent) {
        props.showSnackBar({
          message: "PG Percentage is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }
    if (props.job.cInfo && props.job.cInfo["pan"]) {
      if (!user.pan) {
        props.showSnackBar({
          message: "PAN is empty.",
          open: true,
          type: "error",
        });
        return;
      }
    }

    const vendorCorporateId = localStorage.getItem("vci");
    //const vendorCorporateId = cacheData ? cacheData.vci : "";
    const canId = props.editableUser._id;
    // if (
    //   user.name &&
    //   user.email &&
    //   user.contact &&
    //   user.college &&
    //   user.cDesig &&
    //   user.expr &&
    //   user.ctc &&
    //   user.ectc &&
    //   user.cName &&
    //   user.qual &&
    //   user.nPeriod &&
    //   user.city
    // ) {
    var postData = { ...user };

    postData.extraFields = extra;

    axios
      .put(
        dEndPoints.updateCandidateProfile(vendorCorporateId, canId),
        postData
      )
      .then((res) => {
        var data = res.data;

        if (data && data.code === 100000) {
          editCandidateAnalytics();
          props.showLoader({ show: false });
          props.handleClose();
          props.loadCandidatesDetails("", "", "", "", "", "", false, 0, true);
          props.showSnackBar({
            message: "Update Candidate details Successfully!",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          if( props.isMove ){
            props.handleSubmitMove()
           }
        }
        setUser(postData);
      })
      .catch((error) => {
        console.log(error);
      });
    //}
  };

  // const updateUser = (value, index) => {
  //   console.log("value", value);
  //   const newState = extra.map((obj) => {
  //     // 👇️ if id equals 2, update country property
  //     if (obj.i === index) {
  //       return { ...obj, value: value };
  //     }

  //     // 👇️ otherwise return object as is
  //     return obj;
  //   });
  //   console.log("newState", newState);
  //   setExtra(newState);
  // };

  // const updateUser = (key, value, index) => {
  //   console.log("value", value);
  //   var newExtra = [...extra];
  //   console.log("key", key);
  //   const user = newExtra[key];
  //   console.log("user", user);
  //   user[key] = value;
  //   setExtra([
  //     ...extra.slice(0, index),
  //     user,
  //     ...extra.slice(index + 1, extra.length),
  //   ]);
  // };
  //canInfo.extraFields = extraField;
  return (
    <>
      <Form className="tq-form">
        <Row className="text-left mb-4">
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Candidate Name <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="standard-adornment-name"
              name="name"
              type="text"
              label="Name*"
              onChange={handleInputChange}
              value={user.name}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Email <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="standard-adornment-email"
              type="email"
              name="email"
              label="Email*"
              value={user.email}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Contact No <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="standard-adornment-contact"
              type="text"
              name="contact"
              maxLength="12"
              label="Contact*"
              value={user.contact}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-3">
            <Form.Label>
              Current Company <small className="required__form">*</small>
            </Form.Label>
            {/* <Form.Control
              id="standard-adornment-cName"
              type="text"
              name="cName"
              label="Company Name*"
              value={user.cName}
              onChange={handleInputChange}
            /> */}
            <CreatableSelect
              inputId="cName"
              TextFieldProps={{
                label: "cName",
                InputLabelProps: {
                  htmlFor: "cName",
                  shrink: true,
                },
              }}
              options={CompanyLookup}
              // components={components}
              value={
                user.cName
                  ? user.cName.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeCompanylookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-3">
            <Form.Label>
              Designation <small className="required__form">*</small>
            </Form.Label>
            {/* <Form.Control
              id="standard-adornment-cDesig"
              type="text"
              name="cDesig"
              label="Designation*"
              value={user.cDesig}
              onChange={handleInputChange}
            /> */}
            <CreatableSelect
              {...props}
              inputId="cDesig"
              TextFieldProps={{
                label: "cDesig",
                InputLabelProps: {
                  htmlFor: "cDesig",
                  shrink: true,
                },
              }}
              options={designationLookup}
              // components={components}
              value={
                user.cDesig
                  ? user.cDesig.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : []
              }
              onChange={handleChangeDesinationlookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-3">
            <Form.Label>
              Notice Period <small className="required__form">*</small>
            </Form.Label>
            <select
              id="nPeriod"
              className="form-select"
              aria-label="noticePriod"
              multiple={false}
              required="required"
              value={user.nPeriod}
              onChange={handleChangeNoticeP}
            >
              <option value={1}>Immediate Joiner</option>
              <option value={7}>1 Week</option>
              <option value={14}>2 Weeks</option>
              <option value={21}>3 Weeks</option>
              <option value={30}>30 Days</option>
              <option value={45}>45 Days</option>
              <option value={60}>60 Days</option>
              <option value={90}>90 Days</option>
            </select>
          </Form.Group>

          {props.currentUser.lastworkday ? (
            <Form.Group as={Col} xs={3}>
              <Form.Label>Last Work Day</Form.Label>

              <Form.Control
                id="standard-adornment-lastwoorkday"
                type="date"
                name="lastworkday"
                label="Last Work Day"
                value={user.lastworkday}
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : null}
          <Form.Group as={Col} xs={4} className="mt-3">
            <Form.Label>
              Experience <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="standard-adornment-expr"
              type="number"
              name="expr"
              label="Experience*"
              value={user.expr}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-3">
            <Form.Label>
              CTC <small>(In LPA)</small>
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="standard-adornment-ctc"
              type="number"
              name="ctc"
              label="CTC (In LPA)"
              placeholder="5.8 for salary 5,80,000"
              value={user.ctc}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={4} className="mt-3">
            <Form.Label>
              Expected CTC<small>(In LPA)</small>
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              id="standard-adornment-ectc"
              type="number"
              name="ectc"
              label="Expected CTC (In LPA)"
              placeholder="6.8 for salary 6,80,000"
              value={user.ectc}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>

        <Row className="text-left mb-4">
          <Form.Group as={Col}>
            <Form.Label>
              Skills <small className="required__form">*</small>
            </Form.Label>
            <CreatableSelect
              inputId="skills"
              TextFieldProps={{
                label: "Skills*",
                InputLabelProps: {
                  htmlFor: "skills",
                  shrink: true,
                },
              }}
              options={skillNew}
              //components={components}
              value={
                user.skills
                  ? user.skills.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : []
              }
              onChange={handleChangeSkills}
              isMulti
              isOpen={true}
            />
          </Form.Group>
        </Row>

        <Row className="text-left mb-4">
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              Degree/Qualification <small className="required__form">*</small>
            </Form.Label>
            {/* <Form.Control
              id="standard-adornment-qual"
              type="text"
              name="qual"
              label="Degree / Qualification*"
              value={user.qual}
              onChange={handleInputChange}
            /> */}
            <CreatableSelect
              inputId="qual"
              TextFieldProps={{
                label: "qual",
                InputLabelProps: {
                  htmlFor: "qual",
                  shrink: true,
                },
              }}
              options={QualificationLookup}
              // components={components}
              value={
                user.qual
                  ? user.qual.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeQuallookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>College/University</Form.Label>
            {/* <Form.Control
              id="standard-adornment-college"
              type="text"
              name="college"
              label="College / University*"
              value={user.college}
              onChange={handleInputChange}
            /> */}
            <CreatableSelect
              inputId="college"
              TextFieldProps={{
                label: "college",
                InputLabelProps: {
                  htmlFor: "college",
                  shrink: true,
                },
              }}
              options={CollegeLookup}
              // components={components}
              value={
                user.college
                  ? user.college.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : ""
              }
              onChange={handleChangeCollegelookUp}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={4}>
            <Form.Label>
              City<small className="required__form">*</small>
            </Form.Label>
            {/* <Form.Control
              id="standard-adornment-expr"
              type="text"
              name="city"
              label="City"
              required="required"
              value={user.city}
              onChange={handleInputChange}
            /> */}
            <CreatableSelect
              inputId="city"
              TextFieldProps={{
                label: "city",
                InputLabelProps: {
                  htmlFor: "city",
                  shrink: true,
                },
              }}
              options={locationLookup}
              // components={components}
              value={
                user.city
                  ? user.city.map((ele) => ({
                      label: ele,
                      value: ele,
                    }))
                  : []
              }
              onChange={handleChangeLocationlookUp}
              required
            />
          </Form.Group>
        </Row>
        <Row className="text-left mb-2">
          {props.job.cInfo && props.job.cInfo["tenPercent"] ? (
            <Form.Group as={Col} xs={3}>
              <Form.Label>
                10th Percent <small className="required__form">*</small>
              </Form.Label>
              <Form.Control
                id="standard-adornment-tenPercent"
                type="text"
                name="tenPercent"
                label="10Th Percent*"
                value={user.tenPercent}
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : null}
          {props.job.cInfo && props.job.cInfo["twelvePercent"] ? (
            <Form.Group as={Col} xs={3}>
              <Form.Label>
                12th Percent <small className="required__form">*</small>
              </Form.Label>
              <Form.Control
                id="standard-adornment-twelvePercent"
                type="text"
                name="twelvePercent"
                label="12Th Percent*"
                value={user.twelvePercent}
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : null}
          {props.job.cInfo && props.job.cInfo["ugPercent"] ? (
            <Form.Group as={Col} xs={3}>
              <Form.Label>
                UG Percent <small className="required__form">*</small>
              </Form.Label>
              <Form.Control
                id="standard-adornment-ugPercent"
                type="text"
                name="ugPercent"
                label="UG Percent*"
                value={user.ugPercent}
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : null}

          {props.job.cInfo && props.job.cInfo["pgPercent"] ? (
            <Form.Group as={Col} xs={3}>
              <Form.Label>
                PG Percent <small className="required__form">*</small>
              </Form.Label>
              <Form.Control
                id="standard-adornment-pgPercent"
                type="text"
                name="pgPercent"
                label="PG Percent*"
                value={user.pgPercent}
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : null}
          {props.job.cInfo && props.job.cInfo["pan"] ? (
            <Form.Group as={Col} xs={3}>
              <Form.Label>
                PAN <small className="required__form">*</small>
              </Form.Label>
              <Form.Control
                id="standard-adornment-pan"
                type="text"
                name="pan"
                label="PAN*"
                value={user.pan}
                onChange={handleInputChange}
              />
            </Form.Group>
          ) : null}
          <Row>
            {extra && extra.length > 0
              ? extra &&
                extra.map(({ name, value, i }, index) => (
                  <Form.Group as={Col} xs={6} className="mt-3" key={index}>
                    <Form.Label>{name}</Form.Label>
                    <Form.Control
                      type="text"
                      id="value"
                      onChange={(e) => handleChangeNewField(e, index)}
                      value={value}
                    />
                  </Form.Group>
                ))
              : ""}
          </Row>
        </Row>
      </Form>
      {/* </Modal.Body>
      <Modal.Footer> */}
      <Modal.Footer className="border-0 justify-content-center">
        <ButtonGroup>
          <Button onClick={props.handleClose} variant="secondary">
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => updateProfile(e)}>
            Submit
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
};
UpdateCandidate.propTypes = {
  classes: PropTypes.object,
};
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(UpdateCandidate);
