import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { dEndPoints } from "../../../config/end-point";
import { statusDisplayMapping } from "../../../common/candidateStatusMapping/StatusMapping";
import ActionOnCandidates from "./ActionOnCandidates";

const CandidateRecommendSummary = (props) => {
  const [openBlacklist, setOpenBlacklist] = useState(false);
  const [blacklist, setBlacklist] = useState();
  const [blacklistComment, setBlacklistComment] = useState("");
  //  const [interviews, setInterviews] = useState([]);
  var interviews = [];
  // *** blacklist dialog *** //
  const handleBlacklistOpen = (blacklist) => {
    setBlacklist(blacklist);
    setOpenBlacklist(true);
  };
  const handleCloseBlacklist = () => {
    setBlacklistComment("");
    setOpenBlacklist(false);
  };
  const blacklistCommentChange = (e) => {
    setBlacklistComment(e.target.value);
  };
  //  ***** Candidate blacklist Update **** //
  const handleBlacklistUpdate = (
    e,
    jobId,
    candidateProfileId,
    candidateStatus,
    blacklistComment,
    blacklist
  ) => {
    if (blacklistComment === "") {
      return;
    }
    handleCloseBlacklist();
    if (blacklist === 1) {
      const data = {
        status: candidateStatus,
        reason: blacklistComment,
      };

      // axios to blacklist candidate
      axios
        .patch(dEndPoints.blacklist(jobId, candidateProfileId), data, {
          params: {
            blacklist: blacklist,
          },
        })
        .then((res) => {
          setBlacklistComment("");
          var response = res.data;

          if (response.data && response.data.code === 100148) {
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);

            return;
          }
        })
        .catch((error) => {
          console.log(error);

          return;
        });
    }

    // else if to unblock candidate
    else if (blacklist === 0) {
      const data = {
        status: candidateStatus,
        reason: blacklistComment,
      };
      axios
        .patch(dEndPoints.blacklist(jobId, candidateProfileId), data, {
          params: {
            blacklist: blacklist,
          },
        })
        .then((res) => {
          setBlacklistComment("");
          var response = res.data;

          if (response.data && response.data.code === 100149) {
            props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
            return;
          }
        })
        .catch((error) => {
          //props.showLoader({ show: false });
          console.log(error);
          return;
        });
    }
  };
  // Recommendation feedback
  const handleLike = (e, like, dislike) => {
    const corporateId = localStorage.getItem("ci");

    var jobId = props.candidate.jobId;

    var candId = props.candidate.candId;
    axios
      .post(dEndPoints.createRecommendation(corporateId, candId, jobId), {
        like: 1,
        dislike: 0,
      })
      .then((res) => {
        var data = res.data;

        if (data && data.code === 100128) {
          props.loadCandidatesDetails("", "", "", "", "", false, 0, true);
          props.loadCandidatesRecommendedDetails(
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            true
          );
        }
        if (data.code === 100137) {
          props.loadCandidatesRecommendedDetails(
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            true
          );
          return;
        }
        return;
      })
      .catch((error) => {
        console.log("error in candidate recommendation", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100137
        ) {
          return;
        }
      });
  };

  const handleDislike = (e, dislike, like) => {
    const corporateId = localStorage.getItem("ci");
    var jobId = props.candidate.jobId;
    var candId = props.candidate.candId;

    axios
      .post(dEndPoints.createRecommendation(corporateId, candId, jobId), {
        dislike: 1,
        like: 0,
      })
      .then((res) => {
        var data = res.data;

        if (data && data.code === 100128) {
          props.loadCandidatesRecommendedDetails(
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            true
          );
          return;
        }
      })
      .catch((error) => {
        console.log("error in candidate recommendation", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100137
        ) {
          return;
        }
      });
  };
  const userType = localStorage.getItem("ut");

  return (
    <div className="CandidateSummary-Page">
      {props.candidate ? (
        <Row>
          <Col sm={4}>
            <div className="candidate__details">
              <strong>
                {" "}
                {props.candidate.name ? (
                  <span>
                    {props.candidate.name ? (
                      props.candidate.name.substring(0, 30)
                    ) : (
                      <></>
                    )}
                  </span>
                ) : (
                  <></>
                )}{" "}
              </strong>
              {props.candidate.expr ? (
                <small>
                  &nbsp;|&nbsp;
                  {props.candidate.expr}&nbsp; Years
                </small>
              ) : (
                <></>
              )}
              <small className="candidate__designation">
                {props.candidate.cDesig ? (
                  <span>
                    {props.candidate.cDesig} at {props.candidate.cName}
                  </span>
                ) : (
                  <></>
                )}
              </small>
              <span className="flex__center working__in">
                <p>
                  {" "}
                  {props.candidate.city ? (
                    <span>{props.candidate.city}</span>
                  ) : (
                    <></>
                  )}
                </p>
              </span>
            </div>
          </Col>
          <Col sm={4} className="flex__center">
            <div className="contact__details-candidate">
              <div className="contact__details flex__center">
                <i className="bx bx-envelope"></i>{" "}
                <span>
                  {" "}
                  {props.candidate.email ? (
                    <span> {props.candidate ? props.candidate.email : ""}</span>
                  ) : (
                    <span>
                      Data Not found, Please click on working Queue to view
                      candidate details.
                    </span>
                  )}
                </span>
              </div>
              <div className="contact__details flex__center">
                <i className="bx bx-phone"></i>{" "}
                <span>
                  {" "}
                  {props.candidate.contact ? props.candidate.contact : ""}
                </span>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="status__candidate text-right">
              {props.candidate.properties &&
              props.candidate.properties[0] &&
              props.candidate.properties[0].backlisted === true ? (
                <Badge className="red">Blacklisted</Badge>
              ) : (
                ""
              )}
              <div className="profile__status">
                <Badge className={`bg-${props.candidate.stat}`}>
                  {statusDisplayMapping[props.candidate.stat]}
                </Badge>
              </div>
              <div className="profile__status">
                {props.candidate.responding === false &&
                props.candidate.stat === "Screened" ? (
                  <Badge className="red">Not Responding</Badge>
                ) : props.candidate.responding === false &&
                  props.candidate.stat === "Pending" ? (
                  <Badge className="red">Not Responding</Badge>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <h6 align="center" style={{ marginTop: "30px" }}>
            Data Not Found
          </h6>
        </div>
      )}
      <div className="Job__body candidate__details-professional px-0">
        <Row>
          <Col xs={12}>
            <div className="jd__data jd__skills">
              <strong>{props.candidate.skills ? "Skills :" : <></>}</strong>
              <span>
                {props.candidate.skills ? props.candidate.skills.join() : []}
              </span>
            </div>
          </Col>
          <Col sm={12}>
            <div className="jd__data jd__skills">
              <strong>
                {props.candidate.qual ? "Qualification :" : <></>}{" "}
              </strong>
              <span> {props.candidate.qual ? props.candidate.qual : ""}</span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong> {props.candidate.ctc ? "Current CTC :" : <></>} </strong>
              <span>
                {" "}
                <label> ₹ </label>
                {" " + props.candidate.ctc + " LPA"}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>{props.candidate.ectc ? "Expected CTC :" : ""} </strong>
              <span>
                <label> ₹ </label>
                {" " + props.candidate.ectc + " LPA"}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {props.candidate.nPeriod ? "Notice Period :" : ""}
              </strong>
              <span>
                {props.candidate.nPeriod
                  ? props.candidate.nPeriod + " Days"
                  : ""}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong> {props.candidate.cOn ? "Posted On :" : <></>}</strong>
              <span>
                {props.candidate.cOn ? (
                  <Tooltip title="Posted On" placement="bottom">
                    <span>
                      {new Date(props.candidate.cOn).toLocaleDateString()}
                    </span>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {" "}
                {props.candidate.tenPercent ? "10th Percentage :" : <></>}
              </strong>
              <span>
                {props.candidate.tenPercent ? props.candidate.tenPercent : ""}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {props.candidate.twelvePercent ? "12th Percentage :" : <></>}
              </strong>
              <span>
                {props.candidate && props.candidate.twelvePercent
                  ? props.candidate.twelvePercent
                  : ""}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {" "}
                {props.candidate.ugPercent ? "UG Percentage :" : <></>}
              </strong>
              <span>
                {props.candidate && props.candidate.ugPercent
                  ? props.candidate.ugPercent
                  : ""}{" "}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="jd__data">
              <strong>
                {" "}
                {props.candidate.pgPercent ? "UG Percentage :" : <span></span>}
              </strong>
              <span>
                {props.candidate && props.candidate.pgPercent
                  ? props.candidate.pgPercent
                  : ""}{" "}
              </span>
            </div>
          </Col>
          {props.candidate.college ? (
            <Col sm={12}>
              <div className="jd__data jd__skills">
                <strong> {props.candidate.college ? "College :" : ""} </strong>
                <span>
                  {" "}
                  {props.candidate.college ? props.candidate.college : ""}
                </span>
              </div>
            </Col>
          ) : (
            ""
          )}
          {props.candidate.stat ? (
            <Col sm={6}>
              <div className="jd__data">
                <strong>
                  {props.candidate.pan === " " ? <span></span> : "PanCard :"}
                </strong>
                <span>
                  {props.candidate.pan === undefined ? "" : props.candidate.pan}
                </span>
              </div>
            </Col>
          ) : (
            <span></span>
          )}
          {props.candidate._id ? (
            <>
              {props.candidate && props.candidate.candidateSlots ? (
                <Col sm={12}>
                  <div className="jd__data jd__skills"></div>
                </Col>
              ) : (
                "No interviews"
              )}
            </>
          ) : (
            ""
          )}
          {props.candidate && props.candidate.lastworkday ? (
            <Col sm={6}>
              <div className="jd__data">
                <strong>
                  {props.candidate.lastworkday ? (
                    "last work Day :"
                  ) : (
                    <span></span>
                  )}
                </strong>
                <span>
                  {props.candidate.lastworkday
                    ? new Date(props.candidate.lastworkday).toLocaleDateString()
                    : ""}
                </span>
              </div>
            </Col>
          ) : (
            ""
          )}
          <ButtonGroup className="buttons__group justify-content-center mt-3">
            {props.job && props.job.status === "Close" ? null : (
              <>
                <ActionOnCandidates
                  candidate={props.candidate}
                  loadCandidatesDetails={props.loadCandidatesDetails}
                  job={props.job}
                  interviews={interviews ? interviews : ""}
                  BlockStateButton={
                    userType === "1110" ? (
                      <>
                        {props.candidate.properties &&
                        props.candidate.properties[0] &&
                        props.candidate.properties[0].backlisted === true ? (
                          <Button
                            variant="success"
                            onClick={() => handleBlacklistOpen(0)}
                          >
                            Unblock
                          </Button>
                        ) : props.candidate.properties &&
                          props.candidate.properties[0] &&
                          props.candidate.properties[0].backlisted === false ? (
                          <Button
                            variant="block"
                            onClick={() => handleBlacklistOpen(1)}
                          >
                            Blacklist
                          </Button>
                        ) : (
                          <Button
                            variant="block"
                            onClick={() => handleBlacklistOpen(1)}
                          >
                            Blacklist
                          </Button>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  }
                  LikeDislike={
                    props.candidate.candId ? (
                      <>
                        <Button
                          size="sm"
                          variant="danger"
                          value="dislike"
                          onClick={() => {
                            handleDislike();
                          }}
                        >
                          Dislike
                        </Button>
                        <Button
                          size="sm"
                          variant="success"
                          value="like"
                          onClick={() => {
                            handleLike();
                          }}
                        >
                          Like
                        </Button>
                      </>
                    ) : (
                      ""
                    )
                  }
                />
              </>
            )}
          </ButtonGroup>

          {/* ******Dialog for blacklisting candidate ******* */}

          <Modal
            open={openBlacklist}
            dialogClassName="tq__modal-default"
            onClose={handleCloseBlacklist}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title className="w-100 text-center">
                Please specify the reason.{" "}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Control
                id="comment"
                label="Reason"
                value={blacklistComment}
                onChange={blacklistCommentChange}
                placeholder="Reason for Blocking the candidate"
              />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center">
              <Button variant="secondary" onClick={handleCloseBlacklist}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={(event) =>
                  handleBlacklistUpdate(
                    event,
                    props.candidate.jobId,
                    props.candidate._id,
                    props.candidate.stat,
                    blacklistComment,
                    blacklist
                  )
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* ******Dialog for blacklisting candidate ******* */}

          <Modal
            show={openBlacklist}
            dialogClassName="tq__modal-default"
            onHide={handleCloseBlacklist}
          >
            <Modal.Header className="border-0" closeButton>
              <Modal.Title className="w-100 text-center">
                Please specify the reason.
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="tq-form">
              <Form.Control
                id="comment"
                label="Reason"
                value={blacklistComment}
                onChange={blacklistCommentChange}
                placeholder="Reason for Blocking the candidate"
              />
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center">
              <Button variant="secondary" onClick={handleCloseBlacklist}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={(event) =>
                  handleBlacklistUpdate(
                    event,
                    props.candidate.jobId,
                    props.candidate._id,
                    props.candidate.stat,
                    blacklistComment,
                    blacklist
                  )
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </div>
    </div>
  );
};
CandidateRecommendSummary.propTypes = {
  classes: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(null, mapDispatchToProps)(CandidateRecommendSummary);
