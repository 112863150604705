import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TopNav from "../components/topnav/TopNav";
import SideNav from "../components/sidenav/SideNav";
// import { BrowserRouter, Route } from "react-router-dom";
import "../components/jobcards/jobscard.css";
import "../assets/css/settings.css";
import { Link } from "react-router-dom";

const SettingCards = (props) => {
  return (
    <div className="setting-card">
      <div className="setting-card__icon">
        <img src={props.iconUrl} alt={props.heading} className="img-fluid" />
      </div>
      <div className="setting-card__info">
        <h4>{props.heading}</h4>
        {/* <span>{props.title}</span> */}
      </div>
    </div>
  );
};

const Settings = (props) => {
  // };
  const userType = localStorage.getItem("ut");
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <div className="setting__page">
            <Row className="mb-5 border-bottom pb-5">
              <Col md={4} className="mb-5">
                <Link to="/settings/userprofile">
                  <SettingCards
                    iconUrl="https://talenq.r.worldssl.net//talenq/img/dashboard/userProfile.svg"
                    heading="User Profile"
                  />
                </Link>
              </Col>
              <Col md={4} className="mb-5">
                <Link to="/settings/notification-setting">
                  <SettingCards
                    iconUrl="https://talenq.r.worldssl.net//talenq/img/dashboard/notificationSetting.svg"
                    heading="Notifications"
                  />
                </Link>
              </Col>
              <Col md={4} className="mb-5">
                <Link to="/settings/companyprofile">
                  <SettingCards
                    iconUrl="https://talenq.r.worldssl.net//talenq/img/dashboard/companSettings.svg"
                    heading="Company Info"
                  />
                </Link>
              </Col>
              <Col md={4} className="mb-5">
                <Link to="/settings/bucketSettings">
                  <SettingCards
                    iconUrl="https://talenq.r.worldssl.net//talenq/img/dashboard/bucketSetting.svg"
                    heading="Bucket Settings"
                  />
                </Link>
              </Col>
              {/* <Col md={4} className="mb-5">
                <Link to="/settings/job-settings">
                  <SettingCards
                    iconUrl="https://talenq.r.worldssl.net//talenq/img/dashboard/jobSetting.svg"
                    heading="Job Settings"
                  />
                </Link>
              </Col> */}
              {userType === "1110" || userType === "1000" ? (
                <Col md={4} className="mb-5">
                  <Link to="/settings/uploadResumes">
                    <SettingCards
                      iconUrl="https://talenq.r.worldssl.net//talenq/img/dashboard/uploadResume.svg"
                      heading="Upload Resumes"
                    />
                  </Link>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Settings;
