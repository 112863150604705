import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./badge.css";

const CandidateFilters = (props) => {
  const [show, setShow] = useState(false);
  const userType = localStorage.getItem("ut");
  const options = [
    { value: "Proposed" },
    { value: "Scheduled" },
    { value: "Declined" },
    { value: "RoundClear" },
    { value: "Rejected" },
  ];
  var getUsers;

  if (userType === "1000" && props.facets) {
    getUsers = props.facets.users && props.facets.users.map((x) => x.userName);
  }

  var getLocation, getCompany, getStatus, getScore, getInterview;

  if (props.facets.location) {
    getLocation =
      props.facets && props.facets.location.map((x) => Object.keys(x)[0]);
    getCompany =
      props.facets && props.facets.companyName.map((x) => Object.keys(x));

    getStatus = props.facets && props.facets.status.map((x) => Object.keys(x));
    getScore = props.facets && props.facets.score.map((x) => Object.keys(x));
  }
  getInterview = options && options.map((x) => Object.values(x));
  var statues = [];
  const locations = [];
  const users = [];
  // const designations = [];
  // const skills = [];
  const companies = [];
  const score = [];
  const interviewStatus = [];

  const handleInputChangeStatus = (e) => {
    const target = e.target;
    var value = target.value;

    if (target.value) {
      statues.push(value);
    }
  };
  const handleInputChangeLocation = (e) => {
    const target = e.target;
    var value = target.value;

    if (target.value) {
      locations.push(value);
    } else {
      locations.pop(value);
    }
  };
  const handleInputChangeUser = (e) => {
    const target = e.target;
    var value = target.value;

    if (target.value) {
      users.push(value);
    } else {
      users.pop(value);
    }
  };
  const handleInputChangeScore = (e) => {
    const target = e.target;
    var value = target.value;

    if (target.value) {
      score.push(value);
    } else {
      score.pop(value);
    }
  };
  const handleInputChangeInteview = (e) => {
    const target = e.target;
    var value = target.value;

    if (target.value) {
      interviewStatus.push(value);
    } else {
      interviewStatus.pop(value);
    }
  };
  const handleInputChangeCompany = (e) => {
    const target = e.target;
    var value = target.value;

    if (target.value) {
      companies.push(value);
    } else {
      companies.pop(value);
    }
  };

  const searchResults = () => {
    if (userType === "1000") {
      props.loadCandidatesDetails(
        statues.toString(),
        locations.toString(),
        companies.toString(),
        score.toString(),
        interviewStatus.toString(),
        users.toString()
      );
    }
    if (userType === "1110" || userType === "1100") {
      props.loadCandidatesDetails(
        statues.toString(),
        locations.toString(),
        companies.toString(),
        score.toString(),
        interviewStatus.toString()
      );
    }
    // props.loadCandidatesRecommendedDetails(locations, companies, score);
    setShow(false);
  };

  const resetResults = (clear) => {
    props.loadCandidatesDetails("", "", "", "", "", clear);
    props.loadCandidatesRecommendedDetails("", "", "", clear);
  };

  return (
    <>
      <div
        className="filter__btn flex__center border-0"
        onClick={() => setShow(true)}
      >
        <i className={props.icon}></i>
        {props.BtnName}
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal__filter-wrapper"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            {getLocation && getLocation.length > 0 ? (
              <Col md={2}>
                <div className="filter__points">
                  <h3 className="filter__title">Location</h3>

                  {getLocation &&
                    getLocation.map((item, index) => (
                      <Form.Group className="mb-2" key={index}>
                        <Form.Check
                          type="checkbox"
                          value={item}
                          label={item}
                          onChange={handleInputChangeLocation}
                        />
                      </Form.Group>
                    ))}
                </div>
              </Col>
            ) : (
              ""
            )}
            <Col md={2}>
              <div className="filter__points">
                <h3 className="filter__title">Status</h3>

                {getStatus &&
                  getStatus.map((item, index) => (
                    <Form.Group className="mb-2" key={index}>
                      <Form.Check
                        type="checkbox"
                        value={item}
                        label={item}
                        onChange={handleInputChangeStatus}
                      />
                    </Form.Group>
                  ))}
              </div>
            </Col>
            {getCompany && getCompany.length > 0 ? (
              <Col md={3}>
                <div className="filter__points">
                  <h3 className="filter__title">Companies</h3>

                  {getCompany &&
                    getCompany.map((item, index) => (
                      <Form.Group className="mb-2" key={index}>
                        <Form.Check
                          type="checkbox"
                          value={item}
                          label={item}
                          onChange={handleInputChangeCompany}
                        />
                      </Form.Group>
                    ))}
                </div>
              </Col>
            ) : (
              ""
            )}
            {getScore && getScore.length > 0 ? (
              <Col md={4}>
                <div className="filter__points">
                  <h3 className="filter__title">Score</h3>

                  <>
                    {getScore &&
                      getScore.map((item, index) => (
                        <Form.Group className="mb-2" key={index}>
                          <Form.Check
                            type="checkbox"
                            value={item}
                            label={item}
                            onChange={handleInputChangeScore}
                          />
                        </Form.Group>
                      ))}
                  </>
                </div>
              </Col>
            ) : (
              ""
            )}
            {userType === "1000" ? (
              <>
                {getUsers && getUsers.length > 0 ? (
                  <Col md={4}>
                    <div className="filter__points">
                      <h3 className="filter__title">Users</h3>

                      {getUsers &&
                        getUsers.map((item, index) => (
                          <Form.Group className="mb-2" key={index}>
                            <Form.Check
                              type="checkbox"
                              value={item}
                              label={item}
                              onChange={handleInputChangeUser}
                            />
                          </Form.Group>
                        ))}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {getInterview && getInterview.length > 0 ? (
              <Col md={4}>
                <div className="filter__points">
                  <h3 className="filter__title">Interview Status</h3>

                  {getInterview &&
                    getInterview.map((item, index) => (
                      <Form.Group className="mb-2" key={index}>
                        <Form.Check
                          type="checkbox"
                          value={item}
                          label={item}
                          onChange={handleInputChangeInteview}
                        />
                      </Form.Group>
                    ))}
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <ButtonGroup>
                <Button variant="secondary" onClick={resetResults}>
                  Reset
                </Button>
                <Button variant="primary" onClick={searchResults}>
                  Apply
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CandidateFilters;
