import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import { useState, useEffect } from "react";
import TopNav from "../../components/topnav/TopNav";
import SideNav from "../../components/sidenav/SideNav";
import GoBackButton from "../../components/badge/GoBackButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../../actions/index";
import { showSnackBar } from "../../actions/index";
import Tooltip from "@material-ui/core/Tooltip";
const BucketCard = (props) => {
  return (
    <div className="bucket__card status-card">
      <i className={props.icon}></i>
      <div className="status-card__info">
        <h4>{props.title}</h4>
        <span className={`bucket__status-${props.active}`}>{props.status}</span>
      </div>
    </div>
  );
};

const BucketSettings = (props) => {
  const [defaultBucket, setDefaultBucket] = useState([]);
  const [customBucket, setCustomBucket] = useState([]);
  useEffect(() => {
    loadQueryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userType = localStorage.getItem("ut");
  const loadQueryData = () => {
    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    axios
      .get(dEndPoints.bucketSetting(userId))
      .then((res) => {
        props.showLoader({ show: false });
        let data = res.data;

        if (data && data.code === 100185) {
          setDefaultBucket(data.data.defaultBucket);
          setCustomBucket(data.data.customBucket);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          <GoBackButton />
          <div className="bucket__settings-component">
            <div className="tq__page-header justify-content-center mb-3">
              <h2 className="page-header">Bucket Setting & Create</h2>
            </div>
            <Row>
              <Col xs={6} md={3} className="mb-4 position-relative">
                <Link to="/bucketSettings/create-bucket">
                  <Tooltip title="Create Bucket">
                    <span className="info__button">
                      <i className="bx bx-info-circle"></i>
                    </span>
                  </Tooltip>
                  <BucketCard icon="bx bx-add-to-queue" title="Create Bucket" />
                </Link>
              </Col>
              {userType === "1110"
                ? defaultBucket.map((item, i) => (
                    <>
                      <Col xs={6} md={3} className="mb-4">
                        <Link to={`/bucketSettings/update-bucket/${item.id}`}>
                          <BucketCard
                            icon={item.icon}
                            title={item.title}
                            status={
                              item.isActive === true ? "Active" : "In-Active"
                            }
                            active={item.isActive}
                          />
                        </Link>
                      </Col>
                    </>
                  ))
                : ""}
            </Row>
            <Row>
              {customBucket &&
                customBucket.map((item, i) => (
                  <>
                    <Col xs={6} md={3} className="mb-4">
                      <Link to={`/bucketSettings/update-bucket/${item.id}`}>
                        <BucketCard
                          icon={item.icon}
                          title={item.title}
                          status={
                            item.isActive === true ? "Active" : "In-Active"
                          }
                          active={item.isActive}
                        />
                      </Link>
                    </Col>
                  </>
                ))}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
//export default BucketSettings;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(BucketSettings);
